import React from 'react';
import LTDStepsApiParams from '../base/LTDStepsApiParams';
import LTDChapter from '../base/LTDChapter';
import LTDStep from '../base/LTDStep';
import {
  CAT_COLOR,
  CAT_GENDER,
  CUSTOM_ACTION_NAMES,
  LTD_ACTIONS,
  LTD_ACTIONS_STATUS,
  LTD_CONTENT_TYPE,
  ROOT_PATHS,
  TIMER_ID,
} from '../../../../common/constants';
import { INFO_MODAL_POSITION, TRAINING_PLAYER_ID } from '../../base/constants';
import RootStore from '../../../index';
import routes from '../../../../routes';
import generateTrainingDealId from '../base/methods/generateTrainingDealId';
import { LTD_OBJECT_BY_STAGE } from '../base/methods/generateLTDInfo';
import generateLTDActionRowId from '../../../../common/LTD/generateLTDActionRowId';
import generateLTDActionDetailedCatId from '../../../../common/LTD/generateLTDActionDetailedCatId';
import { MESSAGE_TYPE, RECEIVE_MESSAGE_TYPE } from '../../../constants';
import sendMessageByPlayer from '../base/methods/sendMessageByPlayer';
import checkLTDActions from '../base/methods/checkLTDActions';
import acceptEndTurn from '../base/methods/acceptEndTurn';
import { LocalStorage } from '../../../../common/localStorage';
import api from '../../../../api';
import generateLTDChapterName from '../base/methods/generateLTDChapterName';

const steps = () => [
  new LTDStep({
    actionName: 'openMyTasksModal',
    blockedActionsNames: ['setLTDNotificationsModal'],
    title: <div>Мои задачи</div>,
    message: (
      <p className="training-modal__desc">Все входящие и исходящие сделки будут отображаться в окне «Мои задачи»</p>
    ),
    elements: ['.ltd-actions__head-panel'],
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: 'routerGoTo',
    checkParams([route]) {
      return route.rootPath === ROOT_PATHS.LONG_DEAL;
    },
    title: <div>Мои задачи</div>,
    message: (
      <p className="training-modal__desc">
        Сейчас мы видим, что в задачах находится наша исходящая долгосрочная сделка, которую мы направили нашему
        контрагенту, нажмем на нее
      </p>
    ),
    needOverlayClickModal: false,
    elements: ['.modal--team-tasks__modal.my-tasks__modal'],
    disabledElements: ['.modal__body-actions', '.modal__title-exit'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Статус долгосрочной сделки</div>,
    nextButton: true,
    buttonText: 'Дальше',
    message: <p className="training-modal__desc">Мы можем смотреть статус данной сделки</p>,
    elements: ['.ltd__head-info-wrapper'],
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Отмена сделки</div>,
    nextButton: true,
    buttonText: 'Дальше',
    message: (
      <p className="training-modal__desc">Также можем отменить данную сделку, пока её не подтвердил наш контрагент</p>
    ),
    elements: ['.ltd__accept'],
    disabledElements: ['.ltd__accept'],
    position: INFO_MODAL_POSITION.LEFT,
    selectorIntoView: '.ltd__accept',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Просмотр сделки</div>,
    nextButton: true,
    buttonText: 'Дальше',
    after() {
      RootStore.router.goTo(routes.main);
    },
    message: (
      <p className="training-modal__desc">
        Также, мы можем посмотреть полностью условия всей сделки, но редактировать их не получится
      </p>
    ),
  }),
  new LTDStep({
    before() {
      sendMessageByPlayer({
        playerId: TRAINING_PLAYER_ID.BONIFACE,
        messageType: MESSAGE_TYPE.LTD_CONFIRM,
        data: {
          deal_id: generateTrainingDealId(1),
        },
      });
    },
    actionName: RECEIVE_MESSAGE_TYPE.LTD_UPDATE,
    title: <div>Ответ</div>,
    message: <p className="training-modal__desc">Оппонента подтверждает сделку, ожидаем ответ</p>,
  }),
  new LTDStep({
    actionName: 'openMyTasksModal',
    title: <div>Уведомления</div>,
    message: <p className="training-modal__desc">Нам пришло уведомление в мои задачи. Давайте посмотрим его</p>,
    elements: ['.ltd-actions__head-panel'],
    position: INFO_MODAL_POSITION.LEFT,
    elementsForChangeZIndex: {
      '.my-tasks__indicator': {
        newZIndex: 100,
        returnOriginalZIndexIsNeeded: true,
      },
    },
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_LTD_ROW_OPENING_CHANGING,
    checkParams([isOpen]) {
      return isOpen;
    },
    title: <div>Уведомления</div>,
    message: (
      <p className="training-modal__desc">
        Долгосрочная сделка подписана контрагентом, теперь мы можем приступить к выполнению обязательств. Нажмем на
        сделку
      </p>
    ),
    needOverlayClickModal: false,
    elements: ['.modal--team-tasks__modal.my-tasks__modal'],
    disabledElements: ['.modal__body-actions', '.modal__title-exit', '.ltd-action__container-button'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Обязательства</div>,
    message: (
      <p className="training-modal__desc">
        Сейчас мы полностью видим все этапы долгосрочной сделки и временные рамки, когда они должны будут исполниться
      </p>
    ),
    needOverlayClickModal: false,
    nextButton: true,
    buttonText: 'Дальше',
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
  }),
  new LTDStep({
    actionName: RECEIVE_MESSAGE_TYPE.LTD_UPDATE,
    blockedActionsNames: ['setLTDNotificationsModal'],
    checkParams([ltdUpdate]) {
      return checkLTDActions({
        actions: RootStore.appStore.LTDContentByType(ltdUpdate.contents, LTD_CONTENT_TYPE.BUY).actions,
        neededActionType: LTD_ACTIONS.PREPAID,
        neededStatus: LTD_ACTIONS_STATUS.COMPLETED,
      });
    },
    title: <div>Обязательства</div>,
    message: (
      <p className="training-modal__desc">
        каждое действие, которое необходимо выполнить отображается как новая активная задача. Давайте оплатим аванс
      </p>
    ),
    position: INFO_MODAL_POSITION.TOP,
    needOverlayClickModal: false,
    elements: [`#${generateLTDActionRowId({ actionType: LTD_ACTIONS.PREPAID, contentType: LTD_CONTENT_TYPE.BUY })}`],
    elementsForChangeZIndex: {
      [`#${generateLTDActionRowId({ actionType: LTD_ACTIONS.PREPAID, contentType: LTD_CONTENT_TYPE.BUY })}`]: {
        newZIndex: 10,
        returnOriginalZIndexIsNeeded: true,
      },
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Выполненные обязательства</div>,
    blockedActionsNames: ['setLTDNotificationsModal'],
    message: (
      <p className="training-modal__desc">
        После того, как мы оплатили аванс, мы можем видеть печать о выполнении своей части обязательств, либо
        аналогичная печать будет стоять со стороны нашего контрагента
      </p>
    ),
    position: INFO_MODAL_POSITION.TOP,
    nextButton: true,
    buttonText: 'Дальше',
    needOverlayClickModal: false,
    elements: [`#${generateLTDActionRowId({ actionType: LTD_ACTIONS.PREPAID, contentType: LTD_CONTENT_TYPE.BUY })}`],
    elementsForChangeZIndex: {
      [`#${generateLTDActionRowId({ actionType: LTD_ACTIONS.PREPAID, contentType: LTD_CONTENT_TYPE.BUY })}`]: {
        newZIndex: 10,
        returnOriginalZIndexIsNeeded: true,
      },
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Покупка и продажа котиков</div>,
    message: (
      <p className="training-modal__desc">
        Здесь мы можем увидеть по какой цене мы покупаем котиков – цены выделены зеленым цветом, и по какой цене мы
        будем продавать – черный цвет. Рядом с ценами также отображено количество котиков
      </p>
    ),
    position: INFO_MODAL_POSITION.BOTTOM,
    nextButton: true,
    buttonText: 'Дальше',
    needOverlayClickModal: false,
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    elements: [
      `#${generateLTDActionDetailedCatId({ gender: CAT_GENDER.FEMALE, color: CAT_COLOR.WHITE })}`,
      `#${generateLTDActionDetailedCatId({ gender: CAT_GENDER.MALE, color: CAT_COLOR.WHITE })}`,
    ],
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    after() {
      acceptEndTurn();
    },
    blockedActionsNames: ['toggleEndTurnFlag'],
  }),
  new LTDStep({
    actionName: RECEIVE_MESSAGE_TYPE.START_TURN,
    title: <div>Ожидание смены сезона</div>,
    hideModalsOnStartTurn: false,
    message: <p className="training-modal__desc">Ждем, пока остальные игроки завершат сезон</p>,
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    blockedActionsNames: ['toggleSeasonChangeModal'],
    needOverlayClickModal: false,
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Смена роли</div>,
    after() {
      RootStore.appStore.changeMyUuidWithoutReload(TRAINING_PLAYER_ID.BONIFACE);
    },
    hideModalsOnStartTurn: false,
    blockedActionsNames: ['toggleEndTurnFlag', 'toggleSeasonChangeModal'],
    message: <p className="training-modal__desc">для продолжения обучения Перейдем за роль зоомагазина</p>,
    nextButton: true,
    buttonText: 'Дальше',
    needOverlayClickModal: false,
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
  }),
  new LTDStep({
    actionName: RECEIVE_MESSAGE_TYPE.SYNCHRONIZE,
    title: <div>Смена роли</div>,
    blockedActionsNames: ['toggleEndTurnFlag', 'toggleSeasonChangeModal'],
    message: <p className="training-modal__desc">Ожидание смены роли</p>,
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    after() {
      RootStore.appStore.tutorialProgress.chapter++;
    },
  }),
];

const chapter6 = () =>
  new LTDChapter({
    title: 'Мои задачи',
    chapter: generateLTDChapterName(6),
    stepsApiParams: {
      ...LTDStepsApiParams,
      override_deals: [LTD_OBJECT_BY_STAGE.INITIAL_DISPATCH],
    },
    userIdentifierForSocketInitialization: LocalStorage.ORIGINAL_TRAINING_USER_UID,
    defaultStepSettings: {
      hiddenTimersIds: [TIMER_ID.HEADER.SESSION_TIMER],
    },
    initialRouteParams: {
      routeName: 'main',
    },
    callback() {
      RootStore.appStore.hideIntroductionModal();
    },
    beforeStepsInitializationStep: new LTDStep({
      title: <div>Ожидание старта главы</div>,
      message: <p className="training-modal__desc">Ожидание...</p>,
    }),
    seamlessStartOfNextChapter: true,
    disableTimers: true,
    stepsGeneratorInfo: {
      generateOnChapterInitialization: false,
      generator: steps,
    },
  });

export default chapter6;
