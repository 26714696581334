import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarMiniMouseImage from '../../../assets/nurseryAvatars/avatarMiniMouse.png';

function AvatarMiniMouse({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <path
    //     d="M25.4183 15.1445C25.5585 14.8177 25.6562 14.4735 25.6562 14.111C25.6562 12.5794 24.3419 11.3333 22.7266 11.3333C21.4456 11.3333 20.3658 12.1219 19.9687 13.2101C19.0227 12.7123 17.9294 12.4444 16.8281 12.4444C13.2742 12.4444 10.3438 16.0191 10.3438 19.3888V19.9444H9.75781C8.78852 19.9444 8 20.692 8 21.611C8 22.5301 8.78852 23.2777 9.75781 23.2777H18C18.3233 23.2777 18.5859 23.5267 18.5859 23.8333C18.5859 24.1398 18.3233 24.3888 18 24.3888H15.6562C15.3324 24.3888 15.0703 24.6373 15.0703 24.9444C15.0703 25.2514 15.3324 25.4999 15.6562 25.4999H18C18.9693 25.4999 19.7578 24.7523 19.7578 23.8333C19.7578 22.9142 18.9693 22.1666 18 22.1666H9.75781C9.43453 22.1666 9.17188 21.9175 9.17188 21.611C9.17188 21.3045 9.43453 21.0555 9.75781 21.0555H10.9297H27.4141C27.7379 21.0555 28 20.807 28 20.4999V19.9444C28 18.2457 26.984 15.8907 25.4183 15.1445ZM25.0703 17.9999C24.7467 17.9999 24.4844 17.7512 24.4844 17.4444C24.4844 17.1375 24.7467 16.8888 25.0703 16.8888C25.3939 16.8888 25.6562 17.1375 25.6562 17.4444C25.6562 17.7512 25.3939 17.9999 25.0703 17.9999Z"
    //     fill="#02516C"
    //   />
    // </svg>
    <Avatar style={style} avatarImage={AvatarMiniMouseImage} />
  );
}

export default AvatarMiniMouse;
