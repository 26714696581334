import React from 'react';
import _ from 'lodash';
import { CREDIT_STATUS } from '../../constants';
import ChapterCreditIcon from '../../../ui/icons/training/ChapterCreditIcon';
import LikeModalIcon from '../../../ui/icons/modal/LikeModalIcon';
import PlusModalIcon from '../../../ui/icons/modal/PlusModalIcon';
import MultiplyModalIcon from '../../../ui/icons/modal/MultiplyModalIcon';
import EquallyModalIcon from '../../../ui/icons/modal/EquallyModalIcon';
import { ArrowRightIcon, CoinIcon } from '../../../ui/icons';
import BracketModalIcon from '../../../ui/icons/modal/BracketModalIcon';
import MinusModalIcon from '../../../ui/icons/modal/MinusModalIcon';
import AcceptIcon from '../../../ui/icons/AcceptIcon';
import DivisionModalIcon from '../../../ui/icons/modal/DivisionModalIcon';
import CoinModalIcon from '../../../ui/icons/modal/CoinModalIcon';

export const chapter = 8;

export const stepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 0,
  with_credit: true,
  possible_credit: 80,
  credit_percentages: {
    consumer: 5,
    investment: 10,
    special: 15,
  },
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000003',
      name: 'bot 2',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 9,
          sell_price: 12,
        },
        female_black: {
          buy_price: 11,
          sell_price: 14,
        },
        male_ginger: {
          buy_price: 18,
          sell_price: 22,
        },
        female_ginger: {
          buy_price: 21,
          sell_price: 25,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 50,
      default_prices_override: {
        male_black: {
          buy_price: 12,
          sell_price: 9,
        },
        female_black: {
          buy_price: 14,
          sell_price: 11,
        },
        male_ginger: {
          buy_price: 22,
          sell_price: 18,
        },
        female_ginger: {
          buy_price: 25,
          sell_price: 21,
        },
      },
    },
  ],
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
  hunger_enabled: false,
};

export const taskApiParams = {
  game_type: 'tutorial',
  chapter_num: 8,
  initial_money: 1000,
  initial_turn: 0,
  with_credit: true,
  possible_credit: 80,
  credit_percentages: {
    consumer: 5,
    investment: 10,
    special: 15,
  },
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 23,
          sell_price: 26,
        },
        female_black: {
          buy_price: 27,
          sell_price: 30,
        },
        male_ginger: {
          buy_price: 32,
          sell_price: 36,
        },
        female_ginger: {
          buy_price: 37,
          sell_price: 41,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 80,
      default_prices_override: {
        male_black: {
          buy_price: 26,
          sell_price: 23,
        },
        female_black: {
          buy_price: 30,
          sell_price: 27,
        },
        male_ginger: {
          buy_price: 36,
          sell_price: 32,
        },
        female_ginger: {
          buy_price: 41,
          sell_price: 37,
        },
      },
    },
  ],
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
  hunger_enabled: true,
};

export const steps = [
  {
    actionName: 'onNextButton',
    chapter: '8',
    chapterIcon: <ChapterCreditIcon className="training-modal__chapter-icon" />,
    title: <div>ДОБРО ПОЖАЛОВАТЬ В ГЛАВУ «КРЕДИТЫ И ПРИБЫЛЬ»!</div>,
    message: (
      <p className="training-modal__desc">
        Иногда вы видите очень выгодный вариант, но вам не хватает на него денег, в таких случаях бизнес берет кредит. В
        этой главе мы хотим рассказать как оценивать целесообразность получения кредита.
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:last-child'],
    pulseElements: ['.pet-shops-item-wrapper:last-child'],
    message: (
      <p className="training-modal__desc">
        Давайте перейдем в зоомагазин и посмотрим, что мы сможем купить с текущим балансом.
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area--opponent'],
    disabledElements: ['.lot-area--opponent'],
    message: (
      <p className="training-modal__desc">
        С учетом домика, корма и коммунальных услуг У нас хватает монет, чтобы купить только самых дешевых.
      </p>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    title: 'Расчет',
    message: (
      <p className="training-modal__desc">
        Стоимость содержания котиков одинаковая вне зависимости от того каких мы купим котов. Давайте сразу посчитаем
        выручку, затраты и прибыль от пары дешевых, пары дорогих и двух пар дешевых котиков до
        <span className="notranslate"> 5 </span> сезона. так мы поймем сколько сможем заработать и сколько монет нам не
        хватает.
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    title: 'Расчет выручки',
    isMax: true,
    message: (
      <div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">Расчет выручки с одной пары котиков</p>
          <div className="training-modal__blocks training-modal__desc--margin-min">
            <BracketModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">цена продажи девочек</p>
            <MultiplyModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-min notranslate">2</p>
            <BracketModalIcon className="training-modal__blocks-icon-min training-modal__blocks-icon-rotate" />
            <PlusModalIcon className="training-modal__blocks-icon-min" />
            <BracketModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">цена продажи мальчиков</p>
            <MultiplyModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-min notranslate">4</p>
            <BracketModalIcon className="training-modal__blocks-icon-min training-modal__blocks-icon-rotate" />
            <EquallyModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">выручка</p>
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">При покупке пары дешевых котиков</p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text notranslate">
            (<span className="notranslate">11</span> <CoinModalIcon className="training-modal__price-coin" />
            <MultiplyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">2)</span>
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">(9</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MultiplyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">4</span>
            <span>)</span>
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">58</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">При покупке пары дорогих котиков</p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text notranslate">
            <span className="notranslate">(21</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MultiplyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">2)</span>
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">(18</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MultiplyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">4</span>
            <span>)</span>
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">114</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">При покупке двух пар дешевых котиков</p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text notranslate">
            ((<span className="notranslate">11</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MultiplyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">2</span>)
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} /> (
            <span className="notranslate">9</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MultiplyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">4</span>
            ))
            <MultiplyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">2</span>
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">116</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
          </div>
        </div>
      </div>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    title: (
      <div>
        <span>Расчет затрат до</span>
        <span className="notranslate"> 5 </span>
        <span>сезона</span>
      </div>
    ),
    isMax: true,
    message: (
      <div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">Расчет затрат при покупке котиков</p>
          <div className="training-modal__blocks training-modal__desc--margin-min">
            <p className="training-modal__block-text training-modal__block-text-big">покупка котят</p>
            <PlusModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">покупка домика</p>
            <PlusModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">коммунальные услуги</p>
            <PlusModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">корм</p>
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">При покупке пары дешевых котиков</p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text notranslate">
            <span className="notranslate">26</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">3</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">12</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">6</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">47</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">При покупке пары дорогих котиков</p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text notranslate">
            <span className="notranslate">47</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">3</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">12</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">6</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">68</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">При покупке двух пар дешевых котиков</p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text">
            <span className="notranslate">52</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">3</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">12</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">12</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">79</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
          </div>
        </div>
      </div>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    title: 'Расчет прибыли',
    isMax: true,
    message: (
      <div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">Расчет прибыли при покупке котиков</p>
          <div className="training-modal__blocks training-modal__desc--margin-min">
            <p className="training-modal__block-text training-modal__block-text-big">выручка</p>
            <MinusModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">затраты</p>
            <EquallyModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">прибыль</p>
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">При покупке пары дешевых котиков</p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text notranslate">
            <span className="notranslate">58</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MinusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">47</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">11</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">При покупке пары дорогих котиков</p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text notranslate">
            <span className="notranslate">114</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MinusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">68</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">46</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ margin: '0 5px' }} />
            <AcceptIcon className="training-modal__blocks-icon-min" fill={'#5AA122'} />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <span className="training-modal__desc training-modal__desc--margin-min">
            При покупке двух пар дешевых котиков
          </span>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text notranslate">
            <span className="notranslate">116</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MinusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">79</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">37</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
          </div>
        </div>
      </div>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    message: (
      <p className="training-modal__desc">
        Исходя из расчета прибыли, мы видим, что второй вариант самый выгодный, но у нас не хватает денег, чтобы купить
        дорогих котят. Для этого мы можем взять кредит.
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'openCreditModal',
    elements: ['.credit-logo'],
    pulseElements: ['.credit-logo'],
    title: 'Банк',
    message: <p className="training-modal__desc">Давайте перейдем в банк и посмотрим сколько мы можем взять кредит.</p>,
    position: 'left',
  },
  {
    actionName: 'onNextButton',
    title: 'Кредиты',
    message: (
      <span className="training-modal__desc">
        Каждый игрок может взять кредит у бота. Всего кредитов в игре <span className="notranslate"> 3</span>. Они
        отличаются способами выплат и процентной ставкой, она является фиксированной. Игрока не ограничивают в
        количестве самих кредитов, если общая сумма кредитования не превышает сумму лимита. Оплата происходит в конце
        сезона автоматически, но игрок может совершить досрочное погашение, что уменьшит переплату по процентам.
      </span>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    elements: ['.credit-info__input--available-credit'],
    pulseElements: ['.credit-info__input--available-credit'],
    message: <p className="training-modal__desc">Количество монет, которые вы можете взять в кредит.</p>,
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    elements: ['.credit-info__input--season-pay'],
    pulseElements: ['.credit-info__input--season-pay'],
    message: (
      <p className="training-modal__desc">Количество монет, которые вы должны выплатить по кредитам в текущий сезон.</p>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    title: 'Виды кредитов',
    elements: ['.available-credit__item:first-child'],
    disabledElements: ['.available-credit__item:first-child'],
    message: (
      <p className="training-modal__desc">
        <span>
          Потребительский кредит - кредит с минимальным риском для банка, долг выплачивается равными долями вместе с
          процентами на остаток. Процент по данному кредиту минимальный и составляет
        </span>
        <span className="notranslate"> 5%.</span>
      </p>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'showCreditCalculation',
    title: 'Виды кредитов',
    elements: ['.available-credit__item:first-child'],
    pulseElements: [
      '.available-credit__input-block--money',
      '.available-credit__input-block--season',
      '.text_button:last-child',
    ],
    checkParams: (params) => {
      return params.money_quantity === 40 && params.season_quantity === 4;
    },
    disabledShowCreditReceive: true,
    message: (
      <p className="training-modal__desc">
        <span>Нам нужно посчитать потребительский кредит в размере</span> <span className="notranslate"> 40 </span>
        <span>монет на</span>
        <span className="notranslate"> 4 </span>
        <span>сезона. Нажмите рассчитать, чтобы посмотреть выплаты на каждый сезон.</span>
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    title: 'Потребительский кредит',
    elements: ['.available-credit__item:first-child'],
    disabledElements: ['.available-credit__item:first-child'],
    disabledShowCreditReceive: true,
    message: (
      <p className="training-modal__desc">
        При потребительском кредите мы сразу на следующий сезон начинаем выплачивать тело долга и проценты равными
        долями.
      </p>
    ),
    position: 'bottom',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    title: 'Расчет выплат кредита',
    disabledShowCreditReceive: true,
    isWide: true,
    message: (
      <div className="training-modal__blocks training-modal__blocks--column">
        <p className="training-modal__desc training-modal__desc--margin-min">
          При потребительском кредите мы сразу на следующий сезон начинаем выплачивать тело долга и проценты равными
          долями.
        </p>
        <div className="training-modal__blocks training-modal__desc--margin-min">
          <p className="training-modal__block-text training-modal__block-text-big">тело долга</p>
          <PlusModalIcon className="training-modal__blocks-icon-min" />
          <p className="training-modal__block-text training-modal__block-text-big">проценты</p>
          <EquallyModalIcon className="training-modal__blocks-icon-min" />
          <p className="training-modal__block-text training-modal__block-text-big">выплата в сезон</p>
        </div>
      </div>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    elements: ['.available-credit__input-block--money', '.available-credit__input-block--season'],
    disabledElements: ['.available-credit__input-block--money', '.available-credit__input-block--season'],
    pulseElements: ['.available-credit__input-block--money', '.available-credit__input-block--season'],
    title: 'Тело долга',
    disabledShowCreditReceive: true,
    isWide: true,
    message: (
      <div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">
            Тело долга состоит из отношения суммы кредита к количеству сезонов
          </p>
          <div className="training-modal__blocks training-modal__desc--margin-min">
            <p className="training-modal__block-text training-modal__block-text-big">сумма кредита</p>
            <DivisionModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">количество сезонов</p>
            <EquallyModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">тело кредита в сезон</p>
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">формула расчета тела долга</p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text notranslate">
            <span className="notranslate">40</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <DivisionModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">4</span>
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">10</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
          </div>
        </div>
      </div>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    elements: ['.available-credit__input-block--money', '.available-credit__input-block--percentage'],
    disabledElements: ['.available-credit__input-block--money'],
    pulseElements: ['.available-credit__input-block--money', '.available-credit__input-block--percentage'],
    title: (
      <div>
        <span>Расчет процентов на</span> <span className="notranslate"> 2 </span> <span>сезон</span>
      </div>
    ),
    disabledShowCreditReceive: true,
    isWide: true,
    message: (
      <div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">
            Расчет процентов состоит из произведения суммы кредита и процента
          </p>
          <div className="training-modal__blocks training-modal__desc--margin-min">
            <p className="training-modal__block-text training-modal__block-text-big">сумма кредита</p>
            <MultiplyModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">процент</p>
            <EquallyModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">процентов к уплате</p>
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">формула расчета процентов к уплате</p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text">
            <span className="notranslate">40</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MultiplyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate"> 5% </span> <span>(в сезон)</span>
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">2</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <span> (в сезон)</span>
          </div>
        </div>
      </div>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    title: (
      <div>
        <span>Расчет выплаты на</span> <span className="notranslate"> 3 </span> <span>сезон</span>
      </div>
    ),
    isMax: true,
    message: (
      <div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">
            Расчет выплат на <span className="notranslate"> 3 </span> и следующие сезоны считается по формуле
          </p>
          <div className="training-modal__blocks training-modal__desc--margin-min">
            <BracketModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">сумма кредита</p>
            <MinusModalIcon className="training-modal__blocks-icon-min" />
            <BracketModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">возврат тела в сезон</p>
            <MultiplyModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">количество выплаченных</p>
            <BracketModalIcon className="training-modal__blocks-icon-min training-modal__blocks-icon-rotate" />
            <BracketModalIcon className="training-modal__blocks-icon-min training-modal__blocks-icon-rotate" />
            <MultiplyModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">процент</p>
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">Всегда округляем в большую сторону</p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text notranslate">
            <span className="notranslate">(40</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MinusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />(
            <span className="notranslate">10</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MultiplyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">1))</span>
            <MultiplyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">5%</span>
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">1,5</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <ArrowRightIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">2</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
          </div>
        </div>
      </div>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    title: 'Потребительский кредит',
    elements: ['.available-credit__item:first-child'],
    disabledElements: ['.available-credit__item:first-child'],
    disabledShowCreditReceive: true,
    message: (
      <p className="training-modal__desc">
        Из-за того, что выручка только на пятый сезон, брать такой кредит, где мы сразу на второй сезон выплачиваем тело
        и проценты, не разумно.
      </p>
    ),
    position: 'bottom',
    nextButton: true,
  },
  {
    actionName: 'hideCreditCalculation',
    elements: ['.available-credit__item:first-child'],
    disabledElements: ['.available-credit__input-block--money', '.available-credit__input-block--season'],
    pulseElements: ['.text_button:last-child'],
    disabledShowCreditReceive: true,
    message: (
      <p className="training-modal__desc">
        Чтобы очистить рассчитанный нами кредит, необходимо нажать на кнопку очистить.
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'onNextButton',
    title: 'Виды кредитов',
    elements: ['.available-credit__item:nth-child(2)'],
    disabledElements: ['.available-credit__item:nth-child(2)'],
    message: (
      <p className="training-modal__desc">
        <span>
          Инвестиционный кредит - стандартный кредит для бизнеса, проценты по кредиту выплачиваются каждый сезон, а в
          конце срока кредитования выплачивается еще и тело кредита. Процент по кредиту является средним и составляет
        </span>
        <span className="notranslate"> 10%.</span>
      </p>
    ),
    position: 'top',
    nextButton: true,
  },
  {
    actionName: 'showCreditCalculation',
    title: 'Инвестиционный кредит',
    elements: ['.available-credit__item:nth-child(2)'],
    pulseElements: [
      '.available-credit__item:nth-child(2) .available-credit__input-block--money',
      '.available-credit__item:nth-child(2) .available-credit__input-block--season',
      '.available-credit__item:nth-child(2) .text_button:last-child',
    ],
    checkParams: (params) => {
      return params.money_quantity === 40 && params.season_quantity === 4;
    },
    disabledShowCreditReceive: true,
    message: (
      <p className="training-modal__desc">
        <span>Теперь посчитаем инвестиционный кредит в размере</span> <span className="notranslate"> 40 </span>
        <span>монет на</span>
        <span className="notranslate"> 4 </span>
        <span>сезона. Нажмите рассчитать, чтобы посмотреть выплаты на каждый сезон.</span>
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'onNextButton',
    title: 'Инвестиционный кредит',
    elements: ['.available-credit__item:nth-child(2)'],
    disabledElements: ['.available-credit__item:nth-child(2)'],
    disabledShowCreditReceive: true,
    message: (
      <p className="training-modal__desc">
        В расчете мы видим, что мы сразу на второй сезон выплачиваем проценты, из-за того, что выручка только на пятый
        сезон, брать такой кредит, не имеет смысла.
      </p>
    ),
    position: 'top',
    nextButton: true,
  },
  {
    actionName: 'hideCreditCalculation',
    elements: ['.available-credit__item:nth-child(2)'],
    disabledElements: [
      '.available-credit__item:nth-child(2) .available-credit__input-block--money',
      '.available-credit__item:nth-child(2) .available-credit__input-block--season',
    ],
    pulseElements: ['.available-credit__item:nth-child(2) .text_button:last-child'],
    disabledShowCreditReceive: true,
    message: (
      <p className="training-modal__desc">
        Чтобы очистить рассчитанный нами кредит, необходимо нажать на кнопку очистить.
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'onNextButton',
    title: 'Виды кредитов',
    elements: ['.available-credit__item:nth-child(3)'],
    disabledElements: ['.available-credit__item:nth-child(3)'],
    disabledShowCreditReceive: true,
    message: (
      <p className="training-modal__desc">
        <span>
          Кредит со специальными условиями - самый рискованный для банка кредит, тело долга и проценты выплачиваются
          вместе в конце срока кредитования. Самый высокий процент по кредиту, он составляет
        </span>
        <span className="notranslate"> 15%.</span>
      </p>
    ),
    position: 'top',
    nextButton: true,
  },
  {
    actionName: 'showCreditCalculation',
    title: 'Кредит со спец. условиями',
    elements: ['.available-credit__item:nth-child(3)'],
    pulseElements: [
      '.available-credit__item:nth-child(3) .available-credit__input-block--money',
      '.available-credit__item:nth-child(3) .available-credit__input-block--season',
      '.available-credit__item:nth-child(3) .text_button:last-child',
    ],
    checkParams: (params) => {
      return params.money_quantity === 40 && params.season_quantity === 4;
    },
    disabledShowCreditReceive: true,
    message: (
      <p className="training-modal__desc">
        <span>Теперь посчитаем кредит со специальными условиями в размере</span>
        <span className="notranslate"> 40 </span> <span>монет на</span>
        <span className="notranslate"> 4 </span>
        <span>сезона. Нажмите рассчитать, чтобы посмотреть выплаты на каждый сезон.</span>
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'onNextButton',
    title: 'Кредит со спец. условиями',
    elements: ['.available-credit__item:nth-child(3)'],
    disabledElements: [
      '.available-credit__item:nth-child(3) .available-credit__input-block--money',
      '.available-credit__item:nth-child(3) .available-credit__input-block--season',
      '.available-credit__item:nth-child(3) .text_button:last-child',
    ],
    disabledShowCreditReceive: true,
    message: (
      <p className="training-modal__desc">
        <span>В расчете ниже мы видим, что выплаты тела и процентов кредита начинаются только на</span>
        <span className="notranslate"> 5 </span>
        <span>сезон, как раз когда у нас появится выручка. делаем вывод, что Данный кредит нам подходит.</span>
      </p>
    ),
    position: 'top',
    nextButton: true,
  },
  {
    actionName: 'hideCreditCalculation',
    elements: ['.available-credit__item:nth-child(3)'],
    disabledElements: [
      '.available-credit__item:nth-child(3) .available-credit__input-block--money',
      '.available-credit__item:nth-child(3) .available-credit__input-block--season',
    ],
    pulseElements: ['.available-credit__item:nth-child(3) .text_button:last-child'],
    disabledShowCreditReceive: true,
    message: (
      <p className="training-modal__desc">
        Чтобы очистить рассчитанный нами кредит, необходимо нажать на кнопку очистить.
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'showCreditCalculation',
    title: 'Кредит со спец. условиями',
    elements: ['.available-credit__item:nth-child(3)'],
    pulseElements: [
      '.available-credit__item:nth-child(3) .available-credit__input-block--money',
      '.available-credit__item:nth-child(3) .available-credit__input-block--season',
      '.available-credit__item:nth-child(3) .text_button:last-child',
    ],
    checkParams: (params) => {
      return params.money_quantity === 18 && params.season_quantity === 4;
    },
    disabledShowCreditReceive: true,
    message: (
      <p className="training-modal__desc">
        <span>Для покупки пары дорогих котиков нам не хватало</span> <span className="notranslate"> 18 </span>
        <span>монет. Давайте рассчитаем такой кредит на</span> <span className="notranslate"> 4 </span>
        <span>сезона.</span>
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'onNextButton',
    title: 'Кредит со спец. условиями',
    elements: ['.available-credit__item:nth-child(3)'],
    disabledElements: [
      '.available-credit__item:nth-child(3) .available-credit__input-block--money',
      '.available-credit__item:nth-child(3) .available-credit__input-block--season',
      '.available-credit__item:nth-child(3) .text_button',
      '.available-credit__item:nth-child(3) .text_button:last-child',
    ],
    message: (
      <p className="training-modal__desc">
        Давайте посмотрим на расчет прибыли с учетом процентов, чтобы увидеть выгодно ли нам брать кредит на покупку
        пары дорогих котиков или все-таки остановиться на покупке пары дешевых.
      </p>
    ),
    position: 'top',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    title: 'Расчет прибыли',
    isWide: true,
    message: (
      <div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <span className="training-modal__desc training-modal__desc--margin-min">
            Расчет прибыли при покупке котиков
          </span>
          <div className="training-modal__blocks training-modal__desc--margin-min">
            <p className="training-modal__block-text training-modal__block-text-big">выручка</p>
            <MinusModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">затраты</p>
            <EquallyModalIcon className="training-modal__blocks-icon-min" />
            <p className="training-modal__block-text training-modal__block-text-big">прибыль</p>
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">При покупке пары дешевых котиков</p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text notranslate">
            <span className="notranslate">58</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MinusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">47</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">11</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--column">
          <p className="training-modal__desc training-modal__desc--margin-min">
            При покупке пары дорогих котиков с учетом выплаты процентов по кредиту
          </p>
          <div className="training-modal__block-example-wrapper training-modal__block-example-text notranslate">
            <span className="notranslate">114</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <PlusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">18</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MinusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">68</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MinusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">11</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <MinusModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">18</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <EquallyModalIcon className="training-modal__blocks-icon-min" fill={'#7A6652'} />
            <span className="notranslate">35</span>
            <CoinModalIcon className="training-modal__price-coin" style={{ marginLeft: '5px' }} />
            <AcceptIcon className="training-modal__blocks-icon-min" fill={'#5AA122'} />
          </div>
        </div>
      </div>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    title: 'Расчет прибыли',
    message: (
      <p className="training-modal__desc">
        В расчете прибыли было видно, что даже с учетом выплаты процентов по кредиту, второй вариант выгоднее, поэтому
        давайте возьмем в банке кредит.
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'creditUpdate',
    elements: ['.available-credit__item:nth-child(3)'],
    pulseElements: ['.available-credit__item:nth-child(3) .text_button:first-child'],
    disabledElements: [
      '.available-credit__item:nth-child(3) .available-credit__input-block--money',
      '.available-credit__item:nth-child(3) .available-credit__input-block--season',
      '.available-credit__item:nth-child(3) .text_button:last-child',
    ],
    checkParams: (params) => {
      return params[0].money_quantity === 18 && params[0].season_quantity === 4;
    },
    message: (
      <p className="training-modal__desc">
        <span>Так как для покупки пары дорогих котиков нам не хватало</span> <span className="notranslate"> 18 </span>
        <span>монет, мы можем получить кредит с данной суммой на</span> <span className="notranslate"> 4 </span>
        <span>сезона.</span>
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'creditNotificationModalClose',
    elements: ['.modal__credit-notification-button'],
  },
  {
    actionName: 'takenCreditTab',
    elements: ['.credit-tab__taken-credit'],
    message: <p className="training-modal__desc">Перейди во вкладку</p>,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    title: 'Взятые кредиты',
    elements: ['.taken-credit__block'],
    disabledElements: ['.taken-credit__block', '.credit-tab__available-credit'],
    message: (
      <p className="training-modal__desc">
        Во вкладке "Данные по взятым кредитам" вы можете увидеть все активные кредиты и информацию про них: тело,
        проценты, посезонную оплату.
      </p>
    ),
    position: 'bottom',
    nextButton: true,
  },
  {
    actionName: 'openEarlyRepayment',
    title: 'Досрочное погашение',
    elements: ['.taken-credit__repayment-button'],
    pulseElements: ['.taken-credit__repayment-button'],
    disabledElements: ['.credit-tab__available-credit'],
    message: (
      <p className="training-modal__desc">
        В игре реализована возможность досрочного погашения, которое вы можете совершить при нажатии на кнопку
        досрочного погашения. В этом случае вы выплатите только половину от неначисленных процентов на остаток. нельзя
        погасить досрочно в последний ход действия кредита.
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'creditRepay',
    title: 'Досрочное погашение',
    elements: ['.modal__body-repayment__wrapper', '.modal__body-repayment__action .text_button:first-child'],
    disabledElements: ['.credit-tab__available-credit'],
    message: (
      <p className="training-modal__desc">
        Для подтверждения досрочного погашения кредита, нажмите на кнопку погасить.
      </p>
    ),
    position: 'left',
  },
  {
    actionName: 'creditNotificationModalClose',
    elements: ['.modal__credit-notification-button'],
  },
  {
    actionName: 'hideCreditModal',
    elements: ['.credit-back-button'],
  },
  {
    actionName: 'onNextButton',
    colorBg: 'green',
    title: (
      <div>
        <span>ПОЗДРАВЛЯЕМ!</span>
        <br /> <span>ОБУЧЕНИЕ ПО</span> <span className="notranslate"> 8&nbsp;</span>
        <span>ГЛАВЕ ЗАВЕРШЕНО</span>
      </div>
    ),
    message: (
      <div>
        <p className="training-modal__desc">Чтобы перейти на следующий уровень, выполни практическое задание.</p>
        <div className="training-modal__icon-block">
          <LikeModalIcon className="training-modal__icon" />
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
];

export const task = {
  chapter: 8,
  trainingPreventSetOpenedGroupedCat: true,
  data: { confirmedLotCount: 0 },
  watch: [
    {
      actionName: 'onReceiveMessage__start_turn',
      checkParams: (params) => {
        if (params[0].current_season === 5) {
          if (
            params[0].credits.credits_info.every((credit) =>
              [CREDIT_STATUS.INACTIVE, CREDIT_STATUS.EARLY_REPAY].includes(credit.status),
            )
          ) {
            return params[0].balance >= 200;
          } else {
            return false;
          }
        }
        return 'skip';
      },
    },
  ],
  title: (
    <div>
      <span className="notranslate">8 </span> <span>ГЛАВА «КРЕДИТЫ И ПРИБЫЛЬ» ПРАКТИЧЕСКОЕ ЗАДАНИЕ</span>
    </div>
  ),
  message: (
    <p className="training-modal__desc">
      <span>Практическое задание состоит из</span> <span className="notranslate"> 6 </span> <span>сезонов.</span>
      <br /> <span>У тебя</span> <span className="notranslate"> 80</span>
      <CoinIcon style={{ position: 'relative', top: '2px' }} />
      <span> и на</span> <span className="notranslate"> 80</span>
      <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span> ты можешь взять в кредит.</span>
      <br /> <span>Твоя задача – на начало</span> <span className="notranslate"> 6 </span>
      <span>сезона заработать</span>
      <span className="notranslate"> 200</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
      <span> </span>
      <span>
        или больше, с учетом выплаты кредита. Подсчитай возможную прибыль, выбери каких котиков стоит купить и
        воспользуйся самым подходящим предложением от банка.
      </span>
      <p className="training-modal__think-well">
        <span>Будьте аккуратны, в этом задании вам нужно купить котят и получить потомство, также, на начало</span>
        <span className="notranslate"> 6 </span> <span>сезона, у Вас не должно быть активного кредита</span>
      </p>
    </p>
  ),
  wrongMessage: (
    <div className="training-modal__price training-modal__price--single training-modal__price--large">
      <p className="training-modal__price-name">Остался активный кредит или не получилось заработать</p>
      <div className="training-modal__price-value">
        <CoinModalIcon className="training-modal__price-coin" />
        <span className="training-modal__price-cost training-modal__price-cost--white notranslate">200</span>
      </div>
    </div>
  ),
};
