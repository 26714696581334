import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import ParametersModalIcon from '../icons/ParametersModalIcon';
import StartSessionParameters from './StartSessionParameters';
function ParametersModal() {
  const { appStore } = RootStore;

  if (!appStore.me || !appStore.parametersModalFlag) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.parametersModalFlag}
      onClose={appStore.hideParametersModal}
      title={
        <p className="user-list-modal-title">
          <ParametersModalIcon className="modal-title-icon" /> Параметры сессии
        </p>
      }
      size="big"
    >
      <div className="modal__body-actions">
        <dl className="modal__description-list"></dl>
        <StartSessionParameters />
        <TextButton color="blue" title="Понятно" onClick={appStore.hideParametersModal} />
      </div>
    </Modal>
  );
}

export default observer(ParametersModal);
