import React from 'react';

function ExitHrIcon({ style, width = "40" }) {
  return (
    <svg width={width} height="24" viewBox="0 0 24 24" fill="#C6A788" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path 
        d="M0.802625 9.96853C0.933865 9.55194 2.89122 7.30767 5.15291 4.98078C7.41426 2.6539 9.51727 0.75 9.82619 0.75C10.2625 0.75 10.4405 1.29208 10.6256 3.18464L10.8638 5.61933L13.046 5.79769C18.9866 6.28377 22.6589 10.3922 23.0628 17.0038L23.25 20.0727L21.6037 18.5486C20.698 17.7104 19.2593 16.7233 18.4064 16.355C16.7594 15.6434 13.4664 14.9318 11.8212 14.9318C10.861 14.9318 10.8304 14.9896 10.8255 16.7932C10.8195 19.1133 10.5299 20.25 9.94441 20.25C9.32762 20.25 2.75646 13.9132 1.54258 12.1483C0.859634 11.1552 0.636199 10.4968 0.802625 9.96853Z" 
        fill="inherit"
      />
    </svg>
  );
}

export default ExitHrIcon;
