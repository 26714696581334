import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import { CatFaceIcon } from '../icons';

function NotEnoughSpaceHouseModal() {
  const { appStore } = RootStore;

  if (!appStore.notEnoughSpaceHouseModalShowed) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.notEnoughSpaceHouseModalShowed}
      onClose={appStore.notEnoughSpaceHouseModalClose}
      title="Все места в домике заняты"
    >
      <CatFaceIcon className="modal__body-icon not-enough-cats-modal-icon" />
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.notEnoughSpaceHouseModalClose} />
      </div>
    </Modal>
  );
}

export default observer(NotEnoughSpaceHouseModal);
