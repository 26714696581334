import React from 'react';

function FactureModalIcon({ style, stylePath, className }) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.4615 15.0839C68.4615 18.9562 66.9534 22.4818 64.4949 25.1035H11.7257C12.8359 18.1239 18.8809 12.7716 26.1538 12.7716H43.8693C51.6708 12.7716 59.0054 9.72737 64.5223 4.20021C65.1821 3.53852 66.1752 3.34099 67.037 3.69875C67.8994 4.0565 68.4615 4.89982 68.4615 5.83488V15.0839ZM73.0768 34.388V32.0396C73.0768 31.2293 72.9364 30.4515 72.6801 29.7274H7.31995C7.0636 30.4515 6.92322 31.2293 6.92322 32.0396V34.388C3.10547 34.388 0 37.5001 0 41.3247V69.8057C0 73.6309 3.10547 76.7424 6.92322 76.7424H73.0768C76.8945 76.7424 80 73.6309 80 69.8057V41.3247C80 37.5001 76.8945 34.388 73.0768 34.388ZM43.9817 47.2832L44.2364 51.7329L47.3214 51.7469C51.7339 51.7675 52.6531 52.7259 52.3425 56.9807C52.077 60.6173 52.0603 60.6332 48.1546 60.9161L44.2322 61.199L43.9801 65.6L43.7271 70.0001H39.97H36.2129L35.9582 65.5504C35.7086 61.1803 35.6535 61.1007 32.8957 61.1007C28.3329 61.1007 27.4463 60.3381 27.4463 56.4168C27.4463 52.486 28.3304 51.7329 32.9417 51.7329C35.7511 51.7329 35.7954 51.6701 35.7954 47.6738C35.7954 45.4405 36.0634 43.3131 36.3915 42.9459C36.7188 42.5777 38.5039 42.4025 40.3574 42.5552L43.7271 42.8335L43.9817 47.2832Z"
        fill="#C6A788"
        style={stylePath}
      />
    </svg>
  );
}

export default FactureModalIcon;
