import React from 'react';
import Header from '../ui/components/Header/Header';
import MainLayout from '../ui/layouts/MainLayout/MainLayout';
import Airport from '../modules/main/Airport';
import Areas from '../modules/main/Areas';
import Corporation from '../modules/main/Corporation';
import './CEO.scss';

function CEO() {
  return (
    <MainLayout>
      <Header />
      <Airport />
      <div className="ceo-down-platform__wrapper">
        <Corporation />
        <Areas />
      </div>
    </MainLayout>
  );
}

export default CEO;
