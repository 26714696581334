import React from 'react';

function WatcherModalIcon({ style, className, fill = '#000' }) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        d="M39.9999 28.8635C33.9817 28.8635 29.0908 33.7544 29.0908 39.7726C29.0908 45.7908 33.9817 50.6816 39.9999 50.6816C46.0181 50.6816 50.9089 45.7908 50.9089 39.7726C50.9089 33.7544 46.0179 28.8635 39.9999 28.8635Z"
        fill="inherit"
      />
      <path
        d="M40 12.5C21.8182 12.5 6.29096 23.809 0 39.7727C6.29096 55.7362 21.8182 67.0454 40 67.0454C58.1999 67.0454 73.709 55.7362 80 39.7727C73.709 23.809 58.1999 12.5 40 12.5ZM40 57.9544C29.9636 57.9544 21.8181 49.8089 21.8181 39.7725C21.8181 29.7362 29.9636 21.5908 40 21.5908C50.0363 21.5908 58.1818 29.7363 58.1818 39.7727C58.1818 49.809 50.0363 57.9544 40 57.9544Z"
        fill="inherit"
      />
    </svg>
  );
}

export default WatcherModalIcon;
