import React from 'react';

function ExitIcon({ style }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="#FDF6EE" style={style} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.31465 0.685109L0.684634 2.31512C0.084211 2.91554 0.0842106 3.88912 0.684634 4.48954L4.19472 7.99963L0.683785 11.5106C0.0833617 12.111 0.0833607 13.0846 0.683783 13.685L2.3146 15.3158C2.91502 15.9162 3.88859 15.9162 4.48901 15.3158L7.99965 11.8052L11.5095 15.315C12.1099 15.9154 13.0835 15.9154 13.6839 15.315L15.3139 13.685C15.9143 13.0846 15.9143 12.111 15.3139 11.5106L11.8041 8.00073L15.3153 4.48949C15.9158 3.88912 15.9158 2.91554 15.3154 2.31512L13.6846 0.68431C13.0841 0.0838869 12.1106 0.0838869 11.5101 0.68431L7.99925 4.1952L4.48916 0.68511C3.88864 0.084687 2.91507 0.0846867 2.31465 0.685109Z"
        fill="inherit"
      />
    </svg>
  );
}

export default ExitIcon;
