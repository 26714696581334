import React, { useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import './style.scss';
import { LocalStorage } from '../../../common/localStorage';
import corpStore from '../../../store/CorpStore';
import classNames from '../../../common/classNames';

function CorpModeTester() {
  const { appStore } = RootStore;
  const [opened, setOpen] = useState(false);
  const companies = appStore.players.filter((item) => item.ai_type === 'human');

  function clearStoragePlayerInfo() {
    localStorage.removeItem('PLAYER_ROLE');
    localStorage.removeItem('IS_PLAYER_CEO');
  }

  return (
    !location.href.includes('hr.alabuga.ru') &&
    // !location.href.includes('pre-prod') &&
    appStore.isCorpMode &&
    appStore.company_players &&
    appStore.meIsCEO && (
      <div id="corp-mode-tester">
        <button
          className="corp-mode-tester__button"
          onClick={() => {
            setOpen(!opened);
          }}
        >
          {opened ? '>>>>' : '<<<<'}
        </button>
        {opened ? (
          <div className="corp-mode-tester__field">
            <span className="corp-mode-tester__text">Выбранный CEO - {appStore.me.uuid}</span>
            ==================================================
            {companies.map((company, index) => {
              return (
                <div
                  className={classNames({
                    'corp-mode-tester__field-item': true,
                    'corp-mode-tester__field-item--background-me': company.uuid === appStore.me.uuid,
                  })}
                  key={index}
                >
                  <a
                    onClick={clearStoragePlayerInfo}
                    style={{ pointerEvents: company.uuid === appStore.me.uuid ? 'none' : 'auto' }}
                    href={`${location.origin}/play/${LocalStorage.GAME_UID}/${company.uuid}?server=${LocalStorage.JOIN_URL}`}
                  >
                    <div className="corp-mode-tester__field-item">
                      <span>{company.uuid}</span>
                      <span>{company.role}</span>
                    </div>
                  </a>
                </div>
              );
            })}
            <span className="corp-mode-tester__text">Текущие компании СЕО: ({appStore.me.role}):</span>
            ==================================================
            {corpStore.myActiveCompanies.map((company, index) => {
              return (
                <div className="corp-mode-tester__field-item" key={index}>
                  <a
                    onClick={clearStoragePlayerInfo}
                    style={{ pointerEvents: company.uuid === appStore.me.uuid ? 'none' : 'auto' }}
                    href={`${location.origin}/play/${LocalStorage.GAME_UID}/${company.uuid}?server=${LocalStorage.JOIN_URL}`}
                  >
                    <div className="corp-mode-tester__field-item">
                      <span>{company.uuid}</span>
                      <span>city num: {company.areaNum}</span>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    )
  );
}

export default observer(CorpModeTester);
