import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';

function GameOverManagerModal() {
  const { appStore } = RootStore;

  if (!appStore.gameOverManagerModalShow) {
    return null;
  }

  return (
    <Modal
      isOpen={!!appStore.gameOverManagerModalShow}
      shouldCloseOnOverlayClick={false}
      desc="Администратор завершил игру. Данная сессия не влияет на ваш рейтинг"
    >
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={() => appStore.closeSession()} />
      </div>
    </Modal>
  );
}

export default observer(GameOverManagerModal);
