import _ from 'lodash';
import corpStore from '../store/CorpStore';

const importExportTaskToTeamHistoryFormatAdapter = (data) => {
  const formattedTask = corpStore.importExportDataToTeamTaskFormatAdapter(data);
  formattedTask.content = { contents: _.cloneDeep(formattedTask.content) };
  const isImport = !!formattedTask.buyer;
  if (isImport) {
    formattedTask.order_season = data.delivery_season - 2;
  } else {
    formattedTask.order_season = data.delivery_season - 3;
  }
  return {
    server_time: formattedTask.server_time,
    task_update: formattedTask,
  };
};

export default importExportTaskToTeamHistoryFormatAdapter;
