import React, { useMemo } from 'react';
import trainingStore from '../../store/trainingStore';
import { TRAINING_CHAPTER_TYPE } from '../../store/constants';
import BasicTraining from './BasicTraining';
import LTDTraining from './LTDTraining';
import { observer } from 'mobx-react';

const Training = () => {
  const TRAINING_COMPONENT_BY_TRAINING_TYPE = useMemo(
    () => ({
      [TRAINING_CHAPTER_TYPE.BASIC]: <BasicTraining />,
      [TRAINING_CHAPTER_TYPE.LTD]: <LTDTraining />,
    }),
    [],
  );
  return TRAINING_COMPONENT_BY_TRAINING_TYPE[trainingStore.currentTrainingType];
};

export default observer(Training);
