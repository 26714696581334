import React from 'react';

function ReplyIcon({ style }) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M1.16306 10.7713C1.29268 10.3599 3.22587 8.14333 5.45964 5.84518C7.69307 3.54702 9.77011 1.66663 10.0752 1.66663C10.5061 1.66663 10.682 2.20201 10.8648 4.07121L11.1 6.47584L13.2553 6.65199C19.1226 7.13207 22.7495 11.1898 23.1484 17.7197L23.3333 20.7508L21.7073 19.2454C20.8129 18.4176 19.3919 17.4428 18.5495 17.0789C16.9228 16.3762 13.6705 15.6734 12.0456 15.6734C11.0972 15.6734 11.067 15.7304 11.0622 17.5117C11.0562 19.8032 10.7702 20.9259 10.192 20.9259C9.58281 20.9259 3.09278 14.6673 1.89388 12.9242C1.21936 11.9434 0.998688 11.2931 1.16306 10.7713Z"
        fill="#FDF6EE"
      />
    </svg>
  );
}

export default ReplyIcon;
