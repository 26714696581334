import React from 'react';

function LikeIcon({ style }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#FDF6EE" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M9.56014 5.01163C10.8046 3.01303 11.8227 1.24767 11.8227 1.08838C11.8227 0.379393 13.5677 0.869018 14.274 1.7764C14.9576 2.65463 15.0144 3.03437 14.8323 5.50976L14.6294 8.26534H18.512C22.0193 8.26534 22.4725 8.33999 23.2004 9.03831C24.2053 10.0029 24.2753 11.817 23.3407 12.681C22.7225 13.2527 22.7141 13.3644 23.2213 14.2675C23.9592 15.5802 23.5614 17.0085 22.2271 17.8373C21.5954 18.2299 21.3165 18.5947 21.5403 18.7362C22.1944 19.149 21.9629 21.1476 21.1606 22.0205C20.4213 22.824 20.2503 22.8432 13.8507 22.8432H7.29755V15.7442V8.64473L9.56014 5.01163Z"
        fill="inherit"
      />
      <path
        d="M0.132816 17.9173C0.256388 15.4444 0.449215 12.4211 0.5613 11.1987L0.764961 8.97646H2.9868H5.20901V16.1132V23.25L3.2075 23.0174C-0.384095 22.6004 -0.12303 23.0338 0.132816 17.9173Z"
        fill="inherit"
      />
    </svg>
  );
}

export default LikeIcon;
