import RootStore from '../../index';
import React from 'react';
import { MESSAGE_TYPE } from '../../constants';
import { LocalStorage } from '../../../common/localStorage';
import CoinIcon from '../../../ui/icons/CoinIcon';
import LikeModalIcon from '../../../ui/icons/modal/LikeModalIcon';
import _ from 'lodash';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../../../ui/components/Cat/Cat';
import trainingStore from '../../trainingStore';
import { CAT_COLOR, CAT_GENDER, REQUEST_TYPE } from '../../../common/constants';

export const chapter = 12;

export const stepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 4,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'player 2',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 7,
          sell_price: 11,
        },
        female_black: {
          buy_price: 8,
          sell_price: 12,
        },
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000003',
      name: 'player 3',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 8,
          sell_price: 12,
        },
        female_black: {
          buy_price: 9,
          sell_price: 13,
        },
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 46,
      houses: [
        {
          left_cat: {
            color: 'black',
            gender: 'male',
            season_birthdate: 0,
          },
          right_cat: {
            color: 'black',
            gender: 'female',
            season_birthdate: 0,
          },
          children_cats: [
            {
              color: 'black',
              gender: 'male',
            },
            {
              color: 'black',
              gender: 'male',
            },
            {
              color: 'black',
              gender: 'male',
            },
            {
              color: 'black',
              gender: 'male',
            },
            {
              color: 'black',
              gender: 'female',
            },
            {
              color: 'black',
              gender: 'female',
            },
          ],
        },
        {
          left_cat: null,
          right_cat: {
            color: 'black',
            gender: 'female',
            season_birthdate: 3,
          },
          children_cats: [],
        },
      ],
      default_prices_override: {
        male_black: {
          buy_price: 10,
          sell_price: 6,
        },
        female_black: {
          buy_price: 11,
          sell_price: 7,
        },
      },
    },
  ],
  city_quota_override: {
    male_black: {
      buy_price: 8,
      sell_price: 8,
      buy_quantity: null,
    },
    female_black: {
      buy_price: 9,
      sell_price: 9,
      buy_quantity: 13,
    },
    male_gray: {
      buy_price: 11,
      sell_price: 11,
      buy_quantity: 31,
    },
    female_gray: {
      buy_price: 13,
      sell_price: 13,
      buy_quantity: 11,
    },
  },
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  absence_timeout: 100 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
};

export const taskApiParams = null;

export const steps = [
  {
    before() {
      RootStore.appStore.messengerInited = true;
    },
    actionName: 'openExtra',
    elements: ['.training-extra'],
    disabledElements: ['.pet-shops-item-wrapper:first-child'],
    title: 'ЗНАНИЙ МНОГО НЕ БЫВАЕТ',
    message: <p className="training-modal__desc">В верхнем меню расположена кнопка с настройками. Нажми на неё.</p>,
    position: 'bottom',
  },
  {
    actionName: 'openParametersModal',
    elements: ['.header-extra__item:first-child'],
    disabledElements: [
      '.header-extra__item:nth-child(2)',
      '.header-extra__item:nth-child(3)',
      '.header-extra__item:nth-child(4)',
      '.header-extra__item:nth-child(5)',
      '.header-extra__item:nth-child(6)',
    ],
    title: 'ЗНАНИЙ МНОГО НЕ БЫВАЕТ',
    needOverlayClickModal: false,
    // ToDo нужна запретить hideExtra
    message: (
      <p className="training-modal__desc">
        Первым пунктом идут стартовые параметры, здесь можно посмотреть информацию о текущей сессии.
      </p>
    ),
    position: 'left',
  },
  {
    actionName: 'hideParametersModal',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.nurseries-container', '.nursery-item--own'],
    pulseElements: ['.nursery-item--own'],
    title: 'ИДЕМ ДАЛЬШЕ',
    isNotShowedExtra: true,
    message: (
      <p className="training-modal__desc">
        Осталось еще немного функций, которые пригодятся тебе в игре. Зайди в питомник.
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'dropItem',
    checkParams: (params) => {
      return (
        params[0].data.gender === CAT_GENDER.MALE &&
        params[0].from === 'nursery-house-child-left' &&
        params[0].to === 'nursery-house-right-left'
      );
    },
    elements: ['.own-nurseries__wood', '.own-nurseries__houses-slider'],
    title: 'ПЕРЕМЕЩЕНИЕ',
    message: (
      <p className="training-modal__desc">
        Котенка можно высадить из домика или переместить в окно для взрослых кошек. Пересади одного котенка-мальчика в
        соседнее большое окно.
      </p>
    ),
    position: 'bottom',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(JSON.stringify({ msg_type: MESSAGE_TYPE.ENDTURN_FLAG, end_turn: true }));
        }, 100);
      };
      const socket2 = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000003`,
      );
      socket2.onopen = function (event) {
        setTimeout(() => {
          socket2.send(JSON.stringify({ msg_type: MESSAGE_TYPE.ENDTURN_FLAG, end_turn: true }));
        }, 100);
      };
    },
  },
  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    pulseElements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          const cityCat = _.find(RootStore.appStore.cityQuota, { gender: CAT_GENDER.MALE, color: CAT_COLOR.BLACK });
          const femaleCityCat = _.find(RootStore.appStore.cityQuota, {
            gender: CAT_GENDER.FEMALE,
            color: CAT_COLOR.BLACK,
          });
          socket.send(
            JSON.stringify({
              msg_type: MESSAGE_TYPE.TRADING_REQUEST,
              to: null,
              request_type: REQUEST_TYPE.BUY,
              contents: [{ gender: CAT_GENDER.MALE, color: CAT_COLOR.BLACK, count: 1, price: cityCat.sell_price }],
            }),
          );
          socket.send(
            JSON.stringify({
              msg_type: MESSAGE_TYPE.TRADING_REQUEST,
              to: null,
              request_type: REQUEST_TYPE.BUY,
              contents: [{ gender: CAT_GENDER.MALE, color: CAT_COLOR.BLACK, count: 1, price: cityCat.sell_price }],
            }),
          );
          socket.send(
            JSON.stringify({
              msg_type: MESSAGE_TYPE.TRADING_REQUEST,
              to: null,
              request_type: REQUEST_TYPE.BUY,
              contents: [
                { gender: CAT_GENDER.FEMALE, color: CAT_COLOR.BLACK, count: 1, price: femaleCityCat.sell_price },
              ],
            }),
          );
          socket.send(
            JSON.stringify({
              msg_type: MESSAGE_TYPE.TRADING_REQUEST,
              to: null,
              request_type: REQUEST_TYPE.BUY,
              contents: [
                { gender: CAT_GENDER.FEMALE, color: CAT_COLOR.BLACK, count: 1, price: femaleCityCat.sell_price },
              ],
            }),
          );
        }, 100);
      };
    },
  },

  {
    actionName: 'onNextButton',
    elements: ['.own-nurseries__wood', '.own-nurseries__houses-slider'],
    disabledElements: ['.own-nurseries__houses-slider'],
    title: 'КОТИК ГОЛОДЕН',
    message: (
      <p className="training-modal__desc">
        Котят, которые остались с мамой, можно не кормить отдельно, достаточно покормить маму. Кошка будет кормить
        котят, пока они не повзрослеют. А отсаженного котенка теперь придется кормить отдельно.
      </p>
    ),
    position: 'bottom',
    nextButton: true,
  },

  {
    actionName: 'checkHouseInsurance',
    elements: ['.own-nurseries__actions-item--insurance'],
    title: 'МЕДИЦИНСКАЯ СТРАХОВКА',
    message: (
      <p className="training-modal__desc">
        <span>Чтобы не тратить деньги на лечение котят, можно застраховать домик. Страховка домика на</span>
        <span className="notranslate"> 1 </span> <span>сезон стоит</span> <span className="notranslate"> 3</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span>. Попробуй! Нажми на кнопку страховки.</span>
      </p>
    ),
    position: 'right',
  },

  {
    needOverlayClickModal: false,
    actionName: 'onReceiveMessage__house_cats_update_event',
  },

  {
    actionName: 'onNextButton',
    elements: ['.own-nurseries__wood', '.own-nurseries__houses-slider'],
    disabledElements: ['.own-nurseries__houses-slider'],
    title: 'ЗАЩИТА НА СЛЕДУЮЩИЙ СЕЗОН',
    message: (
      <p className="training-modal__desc">
        <span>
          Страховка куплена. Котята, которые родятся в следующем сезоне, будут лечиться бесплатно. А вот котенка,
          который заболел до страховки, придется полечить за
        </span>
        <span className="notranslate"> 2</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    position: 'bottom',
    nextButton: true,
  },

  {
    actionName: 'routerGoTo',
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    title: 'ИССЛЕДУЕМ ДАЛЬШЕ',
    message: (
      <p className="training-modal__desc">
        Теперь давай посмотрим, что можно делать в окне сделок. Вернись на главный экран.
      </p>
    ),
    position: 'right',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
  },
  {
    actionName: 'onCall',
    elements: ['.lot-area__players-slider .slick-list'],
    pulseElements: ['.lot-area__players-slider .slick-current .lot-area__players-slider-icon--call'],
    title: 'ОБЩЕНИЕ С МАГАЗИНОМ',
    message: (
      <p className="training-modal__desc">
        В витрине магазина расположена зеленая кнопка с облачком-сообщения. Нажми на нее, чтобы отправить сообщение
        зоомагазину.
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    title: 'ОБЩЕНИЕ',
    message: (
      <p className="training-modal__desc">
        В игре с настоящими игроками откроется чат и ты сможешь пообщаться с магазином.
      </p>
    ),
    nextButton: true,
  },

  {
    actionName: 'setOpenedGroupedCat',
    checkParams: (params) => {
      return params[0].gender === CAT_GENDER.FEMALE;
    },
    elements: ['.lot-area.lot-area--nursery'],
    step: 27,
    title: <div>ТОНКОСТИ ПРОДАЖИ</div>,
    message: (
      <p className="training-modal__desc">
        <span>Сейчас у тебя</span> <span className="notranslate"> 3 </span>
        <span>черные девочки. Кликни на этого котенка в своей витрине.</span>
      </p>
    ),
    position: 'top',
    isDisabledDoubleClickCat: true,
  },

  {
    actionName: 'onNextButton',
    elements: ['.modal--grouped-cat .swiper-container', '.modal--grouped-cat'],
    disabledElements: ['.magnifier-button'],
    pulseElements: ['.cat__home-icon'],
    isDisabledDoubleClickCat: true,
    needOverlayClickModal: false,
    title: 'Котик в домике',
    message: (
      <p className="training-modal__desc">
        Теперь ты видишь отдельно каждого котенка. То есть котики, которые сидят в большом окне домика отображаются со
        значком домика.
      </p>
    ),
    position: 'top',
    nextButton: true,
  },

  {
    actionName: 'dropItem',
    checkParams: (params) => {
      return params[0].data.season_birthdate === 3;
    },
    elements: ['.modal--grouped-cat .swiper-container', '.modal--grouped-cat'],
    disabledElements: ['.magnifier-button'],
    title: 'ВЫБОР КОТЕНКА',
    needOverlayClickModal: false,
    message: (
      <p className="training-modal__desc">
        <span>Кликни два раза по котенку возрастом</span> <span className="notranslate"> 2 </span>{' '}
        <span>сезона и он переместится в область сделки.</span>
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'setOpenedGroupedCat',
    elements: ['.modal--grouped-cat .text_button--color-blue'],
    title: 'ВЫБОР КОТЕНКА',
    message: <p className="training-modal__desc">Нажми на сохранить, чтобы закрыть это окно.</p>,
    position: 'right',
  },

  {
    actionName: 'dropItem',
    elements: ['.lot-area.lot-area--nursery', '.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area.lot-area--nursery .cat--female-black', '.lot-area__lot-panel'],
    title: 'ВЫБОР КОТЕНКА',
    isDisabledAllDeleteCat: true,
    isDisabledDoubleClickInLotCat: true,
    message: (
      <p className="training-modal__desc">
        Можно не выбирать котенка по возрасту, а просто перетащить котенка-мальчика из своей витрины в зону сделки или
        кликнуть по нему два раза. Перетащи котенка!
      </p>
    ),
    position: 'top',
  },

  {
    actionName: 'acceptLot',
    elements: ['.training-create-lot-button'],
    pulseElements: ['.training-create-lot-button'],
    title: 'ВЫБОР КОТЕНКА',
    message: (
      <p className="training-modal__desc">
        В этом случае в сделку попадет самый младший котенок. Теперь отправь сделку.
      </p>
    ),
    position: 'left',
  },

  {
    actionName: 'hideSellCatFromParentWindowModal',
    title: 'Продажа из домика',
    elements: ['.modal--size-cats'],
    position: 'left',
    message: (
      <p className="training-modal__desc">
        В сделке есть котик, которого мы продаем из домика, на этот раз давай подтвердим сделку, но в обычной игре будь
        внимательней, когда продаешь котиков.
      </p>
    ),
  },

  {
    actionName: 'lotProposalSentModalClose',
  },
  {
    actionName: 'setCurrentPlayer',
    elements: ['.slick-arrow.slick-next'],
    pulseElements: ['.slick-arrow.slick-next'],
    title: 'СДЕЛКА ЕЩЕ НЕ СОСТОЯЛАСЬ',
    message: (
      <p className="training-modal__desc">
        Сделка отправлена, но магазин пока не купил у тебя котенка, а значит ты можешь продать его другому магазину.
        Переключись на витрину другого магазина.
      </p>
    ),
  },

  {
    actionName: 'setOpenedGroupedCat',
    checkParams: (params) => {
      return params[0].gender === CAT_GENDER.FEMALE;
    },
    elements: ['.lot-area.lot-area--nursery'],
    step: 27,
    title: <div>НОВАЯ СДЕЛКА</div>,
    message: (
      <p className="training-modal__desc">
        <span>Давай предложим кошечку возрастом</span> <span className="notranslate"> 2 </span>
        <span>СЕЗОНА зоомагазину “Зоомир”. Кликни на кошечку.</span>
      </p>
    ),
    position: 'top',
    isDisabledDoubleClickCat: true,
  },

  {
    actionName: 'dropItem',
    checkParams: (params) => {
      return params[0].data.season_birthdate === 3;
    },
    elements: ['.modal--grouped-cat .swiper-container', '.modal--grouped-cat'],
    disabledElements: ['.magnifier-button'],
    title: 'ВЫБОР КОТЕНКА',
    message: (
      <p className="training-modal__desc">
        <span>Кликни два раза по котенку возрастом</span> <span className="notranslate"> 2 </span>
        <span>сезона и он переместится в область сделки.</span>
      </p>
    ),
    position: 'top',
    needOverlayClickModal: false,
  },
  {
    actionName: 'setOpenedGroupedCat',
    elements: ['.modal--grouped-cat .text_button--color-blue'],
    title: 'ВЫБОР КОТЕНКА',
    message: <p className="training-modal__desc">Нажми на сохранить, чтобы закрыть это окно. И отправь сделку.</p>,
    position: 'right',
  },

  {
    actionName: 'acceptLot',
    elements: ['.training-create-lot-button'],
    pulseElements: ['.training-create-lot-button'],
  },

  {
    actionName: 'lotProposalSentModalClose',
  },

  {
    actionName: 'onNextButton',
    elements: ['.mail', '.mail:last-child'],
    disabledElements: ['.mail', '.mail:last-child'],
    title: 'ДВЕ СДЕЛКИ',
    message: (
      <p className="training-modal__desc">
        <span>Ты отправил</span> <span className="notranslate"> 2 </span>
        <span>сделки в каждой из которых один и тот же котенок.</span>
      </p>
    ),
    nextButton: true,
    position: 'left',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000003`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({ msg_type: MESSAGE_TYPE.TRADING_CONFIRM, lot_id: RootStore.appStore.lots[1].lot_id }),
          );
        }, 100);
      };
    },
  },

  {
    actionName: 'setCurrentLot',
    elements: ['.mail'],
    title: 'ОРАНЖЕВЫЙ КОНВЕРТ',
    message: (
      <p className="training-modal__desc">
        Магазин “Полосатый” одобрил сделку - котенок продан. Вторая сделка теперь осталась без котенка - она вернулась в
        оранжевом конверте, открой его.
      </p>
    ),
    position: 'left',
  },

  {
    actionName: 'removeCatsFromLot',
    elements: ['.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area.lot-area--lot .cat--male-black', '.lot-area__lot-panel'],
    title: 'МЕНЯЕМ СДЕЛКУ',
    isDisabledDoubleClickCat: true,
    // ToDo задизейблить здесь возможность окртытия сгруппированного котенка
    message: (
      <p className="training-modal__desc">
        Ты можешь заменить проданного котенка в сделке или совсем удалить его. Нажми на крестик над девочкой.
      </p>
    ),
    position: 'top',
  },

  {
    actionName: 'dropItem',
    elements: ['.lot-area.lot-area--nursery', '.lot-area.lot-area--lot'],
    disabledElements: [
      '.lot-area.lot-area--lot .cat--male-black',
      '.lot-area.lot-area--nursery .cat--male-black',
      '.lot-area__lot-panel',
    ],
    title: 'ВЫБОР КОТЕНКА',
    message: <p className="training-modal__desc">Перетащи в сделку черную девочку или кликни по ней два раза.</p>,
    position: 'top',
  },

  {
    actionName: 'acceptLot',
    elements: ['.training-create-lot-button'],
    pulseElements: ['.training-create-lot-button'],
    title: 'ОТПРАВЛЯЕМ ПРЕДЛОЖЕНИЕ',
    message: (
      <p className="training-modal__desc">
        Теперь нажми кнопку “Отправить”. Пока ты не отправишь предложение, зоомагазин не увидит его.
      </p>
    ),
    position: 'left',
  },

  {
    actionName: 'setCurrentLot',
    elements: ['.mail'],
    title: 'ПРЕДЛОЖЕНИЕ ОТПРАВЛЕНО',
    message: (
      <p className="training-modal__desc">
        Пока зоомагазин не согласовал сделку, ты всегда можешь ее отменить! Кликни на конверт.
      </p>
    ),
    position: 'left',
  },

  {
    actionName: 'cancelLot',
    elements: ['.lot-area__lot-panel .text_button--color-red'],
    pulseElements: ['.lot-area__lot-panel .text_button--color-red'],
    title: '',
    message: <p className="training-modal__desc">Нажми на кнопку, чтобы отменить отправленную сделку.</p>,
    position: 'left',
  },
  {
    actionName: 'notificationModalClose',
  },

  {
    actionName: 'getLotPrice',
    checkParams: () => {
      if (!RootStore.appStore.currentPlayer.lot) {
        return 'skip';
      }
      const lotContents = RootStore.appStore.currentPlayer.lot.contents;
      if (lotContents.length !== 2) {
        return 'skip';
      }
      if (
        lotContents.some((cat) => cat.gender === CAT_GENDER.MALE && cat.color === CAT_COLOR.BLACK && cat.count === 2) &&
        lotContents.some((cat) => cat.gender === CAT_GENDER.FEMALE && cat.color === CAT_COLOR.BLACK && cat.count === 2)
      ) {
        return true;
      }
      return 'skip';
    },
    elements: ['.lot-area.lot-area--lot', '.lot-area.lot-area--shop'],
    disabledElements: ['.lot-area__lot-panel'],
    title: 'КУПИ В МАГАЗИНЕ',
    message: (
      <div className="training-modal__cats">
        <Cat
          cat={{ gender: 'male', color: 'black', count: 2 }}
          readonly
          realCount={true}
          state={CAT_STATE.SINGLE}
          bottomText={BOTTOM_TEXT.KIND}
        />
        <Cat
          cat={{ gender: 'female', color: 'black', count: 2 }}
          readonly
          realCount={true}
          state={CAT_STATE.SINGLE}
          bottomText={BOTTOM_TEXT.KIND}
        />
      </div>
    ),
    position: 'top',
  },

  {
    actionName: 'acceptLot',
    elements: ['.training-create-lot-button'],
    pulseElements: ['.training-create-lot-button'],
  },
  {
    actionName: 'lotProposalSentModalClose',
  },

  {
    actionName: 'onNextButton',
    elements: ['.mail:last-child'],
    disabledElements: ['.mail:last-child'],
    title: 'ОТПРАВЛЕНО ПРЕДЛОЖЕНИЕ',
    message: (
      <p className="training-modal__desc">Ты попросил у зоомагазина продать тебе котят. Ждем ответа от зоомагазина.</p>
    ),
    position: 'left',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({
              msg_type: MESSAGE_TYPE.TRADING_CONFIRM,
              lot_id: RootStore.appStore.lots[RootStore.appStore.lots.length - 1].lot_id,
            }),
          );
          setTimeout(() => {
            trainingStore.checkIsCorrectAction('onNextButton');
          }, 150);
        }, 50);
      };
    },
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    elements: ['.mail:last-child'],
    disabledElements: ['.mail:last-child'],
  },
  {
    actionName: 'setCurrentLot',
    elements: ['.mail:last-child'],
    pulseElements: ['.mail:last-child'],
    title: 'ПРОВЕРЬ ПРЕДЛОЖЕНИЕ',
    message: <p className="training-modal__desc">Магазин прислал котиков на продажу, открой конверт.</p>,
    position: 'left',
  },

  {
    actionName: 'setOpenedGroupedCat',
    elements: ['.lot-area.lot-area--lot'],
    disabledElements: [
      '.lot-area__cats-slide:first-child .price-wrapper',
      '.lot-area__cats-slide:last-child .price-wrapper',
      '.lot-area__lot-panel',
      '.cat--lot .cat__delete',
    ],
    title: 'ПРОВЕРКА СДЕЛКИ',
    message: (
      <p className="training-modal__desc">Посмотри здоровы ли котики и какого они возраста. Нажми на любого котенка.</p>
    ),
    position: 'top',
    isDisabledAllDeleteCat: true,
    isDisabledDoubleClickCat: true,
  },

  {
    actionName: 'addShowedSicknessCatId',
    elements: ['.modal--grouped-cat .swiper-container', '.modal--grouped-cat'],
    title: 'ОСМОТР КОТИКОВ',
    message: <p className="training-modal__desc">Перетащи лупу из правого нижнего угла на котят.</p>,
    position: 'top',
    isDisabledDoubleClickCat: true,
    needOverlayClickModal: false,
  },

  {
    actionName: 'addShowedSicknessCatId',
    checkedCatIds: [],
    checkParams: (params) => {
      let checkedCatIds = trainingStore.currentStep.checkedCatIds;
      if (!!params[0].cat_id && !checkedCatIds.find((catId) => catId === params[0].cat_id)) {
        checkedCatIds.push(params[0].cat_id);
      }
      if (checkedCatIds.length === 4) {
        checkedCatIds = [];
        return true;
      }
      return 'skip';
    },
    elements: ['.modal--grouped-cat'],
    title: 'ОСМОТР КОТИКОВ',
    message: (
      <p className="training-modal__desc">
        В сделке 4 котенка. Нажимай на кнопку «влево» и «вправо», чтобы проверить всех.
      </p>
    ),
    position: 'top',
    needOverlayClickModal: false,
  },
  {
    actionName: 'setOpenedGroupedCat',
    elements: ['.modal--grouped-cat'],
    title: '',
    message: (
      <p className="training-modal__desc">
        Все котята здоровы и устраивают нас по возрасту. Закрой лупу и согласуй сделку.
      </p>
    ),
    position: 'top',
  },

  {
    actionName: 'acceptLot',
    elements: ['.lot-area__lot-panel-btn-wrapper .text_button--color-green'],
    pulseElements: ['.lot-area__lot-panel-btn-wrapper .text_button--color-green'],
  },

  {
    actionName: 'notificationModalClose',
  },

  {
    before() {
      RootStore.appStore.messengerInited = false;
    },
    actionName: 'onNextButton',
    colorBg: 'green',
    title: (
      <div>
        <span>ПОЗДРАВЛЯЕМ!</span>
        <br /> <span>ОБУЧЕНИЕ ЗАКОНЧЕНО</span>
      </div>
    ),
    message: (
      <div>
        <p className="training-modal__desc">Ты знаешь все о Business Cats. Играй, выстраивай стратегии, побеждай!</p>
        <div className="training-modal__icon-block">
          <LikeModalIcon className="training-modal__icon" />
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
];

export const task = null;
