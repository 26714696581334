import trainingStore from '../store/trainingStore';

const draggableItemDefaultCanDrag = ({ draggableOptions, type, data, element, dragAround, from }) => {
  if (draggableOptions.canDrag) {
    if (
      (trainingStore.launchedChapter === 12 && from === 'lot') ||
      (trainingStore.launchedChapter === 3 && !trainingStore.currentTask && from === 'nursery-house-left-right') ||
      (trainingStore.launchedChapter === 3 && !trainingStore.currentTask && from === 'nursery-house-left-left')
    ) {
      return false;
    }
    if (trainingStore.launched) {
      const draggableSettingsOverride =
        trainingStore.currentStep && trainingStore.currentStep.draggableSettingsOverride;
      if (
        draggableSettingsOverride &&
        draggableSettingsOverride.hasOwnProperty(type) &&
        draggableSettingsOverride[type].hasOwnProperty(from)
      ) {
        const override = draggableSettingsOverride[type][from];
        if (_.isFunction(override)) {
          return override({ type, from, data, element, draggableOptions, dragAround });
        } else {
          return override;
        }
      }
    }
    return draggableOptions.canDrag(data, from, element);
  }
};

export default draggableItemDefaultCanDrag;
