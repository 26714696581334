import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../../../store';
import routes from '../../../../../routes';
import _ from 'lodash';
import classNames from '../../../../../common/classNames';
import { ROLE_NAME } from '../../../../../store/constants';
import { TriangleIcon, ContractSheet } from '../../../../icons';
import LTDCats from './LTDCats';
import LTDActions from './LTDActions';
import LTDMail from './LTDMail';
import IconButton from '../../../../buttons/IconButton/IconButton';
import BasketIcon from '../../../../icons/BasketIcon';
import {
  CUSTOM_ACTION_NAMES,
  LTD_ACTIONS,
  LTD_STATUS,
  PLAYER_ROLE,
  SPECIALITIES,
} from '../../../../../common/constants';
import createCustomActionForTraining from '../../../../../common/createCustomActionForTraining';
import trainingStore from '../../../../../store/trainingStore';
import LTDStep from '../../../../../store/trainings/LTDTrainings/base/LTDStep';
import app from '../../../../../App';

function LTDRow({ ltd }) {
  const { appStore } = RootStore;
  const ltdInfo = ltd.ltd_info;

  const isTerminated = ltdInfo.status === LTD_STATUS.TERMINATED;

  const pretension =
    ltd.actions.some((action) => {
      if (action.sent_liabilities) {
        return action.sent_liabilities.find((item) => !item.is_closed && !item.is_fine);
      }
    }) && !isTerminated;

  const fine =
    ltd.actions.some((action) => {
      return (
        (action.action_type === LTD_ACTIONS.PAYMENT_OF_FINE && action.fine_detail) ||
        action.action_type === LTD_ACTIONS.TERMINATING
      );
    }) && !isTerminated;

  const [showDetailAction, _setShowDetailAction] = useState(false);

  const setShowDetailAction = (isOpen) => {
    createCustomActionForTraining({
      actionName: CUSTOM_ACTION_NAMES.ON_LTD_ROW_OPENING_CHANGING,
      params: [isOpen],
    });
    _setShowDetailAction(isOpen);
  };

  useEffect(() => {
    if (
      trainingStore.launched &&
      trainingStore.currentStep instanceof LTDStep &&
      trainingStore.currentStep.openedLTDRows?.includes(ltd.ltd_info.deal_id)
    ) {
      _setShowDetailAction(true);
    }
  }, [trainingStore.launched, trainingStore.currentStep, trainingStore.currentStep?.openedLTDRows]);

  useEffect(() => {
    if (ltdInfo.status === LTD_STATUS.COMPLETED || ltdInfo.status === LTD_STATUS.TERMINATED) {
      setShowDetailAction(false);
    }
  }, [ltdInfo.status]);

  const TaskInfo = () => {
    const captainUuid = appStore.playerByRealSpeciality(SPECIALITIES.CAPTAIN, ltdInfo.contents[0].area_num).player_id;

    let myArea = appStore.me.uuid !== ltdInfo.initiator && captainUuid !== ltdInfo.initiator;
    let searchPlayer;

    if (!myArea) {
      searchPlayer = appStore.ltdPartner(ltdInfo.partner, ltdInfo);
    } else {
      searchPlayer = appStore.ltdPartner(ltdInfo.initiator, ltdInfo);
    }
    const areaTitle = appStore.meIsCEO ? `город ${ltdInfo.contents[0].area_num} ` : '';
    return (
      <span className="ltd-action__row-info-text">
        Долгосрочная сделка с {ROLE_NAME[searchPlayer.role]}ом {searchPlayer.name} {areaTitle}
        {ltdInfo.status === LTD_STATUS.TERMINATED
          ? 'расторгнута'
          : ltdInfo.status === LTD_STATUS.CANCELED
          ? 'отозвана'
          : ltdInfo.status === LTD_STATUS.ACTIONS_PHASE && ltdInfo.two_way_termination
          ? 'расторгается'
          : ''}
      </span>
    );
  };

  return (
    <>
      <div
        className={classNames({
          'ltd-action__container': true,
          'ltd-action__container--fine': fine,
          'ltd-action__container--pretension': pretension,
          'ltd-action__container--terminated': isTerminated,
        })}
        onClick={() => {
          if (ltdInfo.status === LTD_STATUS.TERMS_AGREEMENT) {
            RootStore.goTo(routes.longTermsDealsEdit, { deal_id: ltdInfo.deal_id });
            appStore.hideMyTasksModal();
          } else if (ltdInfo.status === LTD_STATUS.ACTIONS_PHASE || ltdInfo.status === LTD_STATUS.TERMINATING) {
            setShowDetailAction(!showDetailAction);
          }
        }}
      >
        {ltdInfo.status === LTD_STATUS.ACTIONS_PHASE || ltdInfo.status === LTD_STATUS.TERMINATING ? (
          <TriangleIcon
            className={classNames({
              'ltd-action__container-triangle': true,
              'ltd-action__container-triangle--liability': fine || pretension,
            })}
            top={showDetailAction}
          />
        ) : (
          <span></span>
        )}
        <LTDMail ltdInfo={ltdInfo} size={'mini'} />
        <TaskInfo />
        {ltdInfo.status === LTD_STATUS.ACTIONS_PHASE ? (
          <IconButton
            customClass="ltd-action__container-button"
            color="blue"
            onClick={() => {
              RootStore.goTo(routes.longTermsDealsEdit, { deal_id: ltdInfo.deal_id });
              appStore.hideMyTasksModal();
            }}
          >
            <ContractSheet />
          </IconButton>
        ) : ltdInfo.status === LTD_STATUS.CANCELED || ltdInfo.status === LTD_STATUS.TERMINATED ? (
          <IconButton
            customClass="ltd-action__container-button"
            color="red"
            onClick={() => {
              appStore.deleteCompletedLTD(ltdInfo.deal_id);
            }}
          >
            <BasketIcon />
          </IconButton>
        ) : (
          <span></span>
        )}
      </div>
      {showDetailAction && (
        <div className="ltd-action__detailed--wrapper">
          <LTDCats cats={ltd.cats} status={ltdInfo.status} actions={ltd.actions} />
          <LTDActions ltd_info={ltd.ltd_info} actions={ltd.actions} cats={ltd.cats} />
        </div>
      )}
    </>
  );
}

export default observer(LTDRow);
