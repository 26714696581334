import React from 'react';

function ListModalIcon({ style, stylePath, className }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <g clipPath="url(#listModalIcon)">
        <path
          d="M17.1699 7.19817H23.0045C24.6293 7.19647 26.2499 6.25 26.2469 2.96832H23.7516C23.3925 2.96832 23.0817 2.71815 23.0045 2.36723C22.7031 0.99556 22.4999 1.69405e-10 19.9879 0C17.4999 -1.67789e-10 17.2723 0.99556 16.9713 2.36766C16.8945 2.71858 16.5833 2.96875 16.2242 2.96875H13.7285C13.7499 6.25 15.5455 7.19647 17.1699 7.19817Z"
          fill="#C6A788"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3828 2.96851H31.5682C33.1931 2.97064 34.5097 4.28681 34.5114 5.91164C35.0523 16.693 34.8806 23.1157 34.6331 32.3787L34.633 32.3799C34.5935 33.857 34.5521 35.4063 34.5114 37.057C34.375 38.7501 33.1931 39.9985 31.5682 40.0002H8.40779C6.78296 39.9985 5.625 38.7501 5.46466 37.057L5.42667 35.5561C5.1416 24.3131 4.97051 17.5655 5.46466 5.91164C5.46679 4.28681 6.78296 2.97064 8.40779 2.96851H11.5637C11.25 6.87506 14.2027 8.72585 16.672 8.72841H23.3041C25.7733 8.72585 28.75 7.50006 28.3828 2.96851ZM11 13.7501C10.4477 13.7501 10 14.1978 10 14.7501V16.5001C10 17.0523 10.4477 17.5001 11 17.5001H29C29.5523 17.5001 30 17.0523 30 16.5001V14.7501C30 14.1978 29.5523 13.7501 29 13.7501H11ZM10 22.2501C10 21.6978 10.4477 21.2501 11 21.2501H29C29.5523 21.2501 30 21.6978 30 22.2501V24.0001C30 24.5523 29.5523 25.0001 29 25.0001H11C10.4477 25.0001 10 24.5523 10 24.0001V22.2501ZM11 28.7501C10.4477 28.7501 10 29.1978 10 29.7501V31.5001C10 32.0523 10.4477 32.5001 11 32.5001H29C29.5523 32.5001 30 32.0523 30 31.5001V29.7501C30 29.1978 29.5523 28.7501 29 28.7501H11Z"
          fill="#C6A788"
        />
      </g>
      <defs>
        <clipPath id="listModalIcon">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ListModalIcon;
