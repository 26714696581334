import RootStore from '../store';
import _ from 'lodash';

/**
 * @param credits - массив активных кредитов
 * @param currentSessionTimeInSeconds - текущее время
 * @returns {object} - возвращает объект, в realtime 2 значения - payment и timestamp, в !realtime - payment
 */

const calcNextPaymentsOnCredits = ({ credits, currentSessionTimeInSeconds }) => {
  const { appStore } = RootStore;
  if (!credits.length) {
    return null;
  }

  let totalSumPayments = 0;
  let arrNextPayments = [];
  credits.forEach((credit) => {
    if (!appStore.isRealTimeMode) {
      credit.credit_bodies
        .filter((body) => appStore.currentSeason === body.season)
        .forEach((body) => {
          totalSumPayments += body.body_payment + body.percent_payment;
        });
    } else {
      const sortedCreditBodies = credit.credit_bodies.filter((body) => {
        return currentSessionTimeInSeconds <= body.time && body.body_payment + body.percent_payment !== 0;
      });
      if (sortedCreditBodies.length) {
        arrNextPayments.push(_.minBy(sortedCreditBodies, 'time'));
      }
    }
  });

  if (!appStore.isRealTimeMode) {
    return { payment: totalSumPayments };
  } else {
    const nextPaymentValues = _.minBy(arrNextPayments, 'time');
    if (!nextPaymentValues) {
      return;
    }
    totalSumPayments = _.sumBy(
      arrNextPayments.filter((payment) => payment.time === nextPaymentValues.time),
      (payment) => {
        return payment.body_payment + payment.percent_payment;
      },
    );
    return { payment: totalSumPayments, timestamp: nextPaymentValues.time };
  }
};

export default calcNextPaymentsOnCredits;
