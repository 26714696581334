import React from 'react';
import { observer } from 'mobx-react';
import { CatFaceIcon, CoinIcon } from '../../../../../ui/icons';
import TimerIcon from '../../../../../ui/icons/TimerIcon';
import { TRANSFER_STATUS, TRANSFER_TYPE } from '../../../../../store/constants';
import corpStore from '../../../../../store/CorpStore';

function TransferContent({ type, title }) {
  const transfers = corpStore.transferContentByType(type).filter((transfer) => {
    return type === TRANSFER_TYPE.IMPORT ? transfer.status !== TRANSFER_STATUS.PAID : true;
  });

  return (
    <div className="transfer-area__content__item">
      <span className="transfer-area__content-title">{title}</span>
      <div className="transfer-area__content__item-elements">
        {transfers.map((transfer, index) => (
          <div
            className="transfer-area__content__item-element-wrapper"
            key={index}
            onClick={() => corpStore.openTransferDetailModal(transfer)}
          >
            <div className="transfer-area__content__item-element">
              <CatFaceIcon className="transfer-area-icon" />
              <span className="transfer-area__content__item-element-text">{transfer.count}</span>
            </div>
            <div className="transfer-area__content__item-element">
              <TimerIcon className="transfer-area-icon" />
              <span className="transfer-area__content__item-element-text">{transfer.season} сезон</span>
            </div>
            <div className="transfer-area__content__item-element">
              <CoinIcon />
              <span className="transfer-area__content__item-element-text">{transfer.residual_value}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default observer(TransferContent);
