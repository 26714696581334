import React from 'react';
import IconButton from '../../buttons/IconButton/IconButton';
import PauseIcon from '../../icons/PauseIcon';
import RootStore from '../../../store';

function PauseButton() {
  const { appStore } = RootStore;

  return (
    <IconButton customClass="header__chat-button" color="blue" onClick={appStore.exactlyAcceptPause}>
      <PauseIcon style={{ width: '24px', height: '24px', display: 'block' }} />
    </IconButton>
  );
}

export default PauseButton;
