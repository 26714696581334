import React from 'react';
import Lottie from 'lottie-react';
import * as animationData from './loaderData.json';
import './style.scss';

function Loader() {
  return (
    <div className="loader_wrap">
      {' '}
      <Lottie animationData={animationData.default} className="loader" />{' '}
    </div>
  );
}

export default Loader;
