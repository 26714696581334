import { CAT_RUNAWAY_TYPE } from '../../store/constants';

const getDefaultDelayForMeowSound = (type, monthDuration) => {
  // Звук воспроизводится за 1/3 времени перед убеганием
  // WITHOUT_HOUSE - сбегает через 1 игровой месяц
  // HUNGRY || SICK - сбегает через 3 игровых месяца
  if (type === CAT_RUNAWAY_TYPE.WITHOUT_HOUSE) {
    // Сбегает через 3 игровых месяца
    return ((monthDuration * 6) / 3) * 1000;
  } else {
    // Сбегает через 1 игровой месяц
    return ((monthDuration * 2) / 3) * 1000;
  }
};

export default getDefaultDelayForMeowSound;
