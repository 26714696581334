import React from 'react';

function TimerIcon({ style, className, fill }) {
  return (
    <svg
      style={style}
      fill="none"
      className={className}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13ZM5.37946 2.24058C5.37946 1.74543 5.78086 1.34403 6.27602 1.34403C6.77117 1.34403 7.17257 1.74543 7.17257 2.24058V5.82547H10.758C11.2532 5.82547 11.6546 6.22687 11.6546 6.72203C11.6546 7.21718 11.2532 7.61858 10.758 7.61858H6.32498C6.30877 7.61945 6.29244 7.61989 6.27602 7.61989C5.9421 7.61989 5.65082 7.43734 5.49653 7.1666C5.42156 7.03557 5.37871 6.8838 5.37871 6.72203C5.37871 6.70962 5.37896 6.69727 5.37946 6.68499V2.24058Z"
        fill={fill ? fill : '#FDF6EE'}
      />
    </svg>
  );
}

export default TimerIcon;
