import React from 'react';

function BowlModalIcon({ style, stylePath, className }) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12469e-10 52.3952C0.000228579 37.2876 7.27273 20.4952 7.27273 20.4952C15.4839 9.73806 65.2273 9.93225 72.5 20.4952C72.5 20.4952 80 38.6608 80 52.3952C80 59.2625 72.5 72.1674 33.75 72.5C13.7499 72.6717 -0.000114289 59.9491 7.12469e-10 52.3952ZM43.75 31.25C65.2118 31.25 70 27.5776 70 23.4355C70 19.2934 56.2398 16.25 39.7882 16.25C23.3367 16.25 10 19.6079 10 23.75C10 27.8921 22.2882 31.25 43.75 31.25ZM62.4999 26.25C62.4999 27.3546 50.8732 28.75 40.0001 28.75C29.127 28.75 17.4999 27.3546 17.4999 26.25C17.4999 25.1454 23.7501 22.5 40.0001 22.5C56.6946 22.5 62.4999 25.1454 62.4999 26.25ZM39.5557 42.3112C39.5557 44.0352 38.1536 45.4372 36.4296 45.4372C34.7056 45.4372 33.3036 44.0352 33.3036 42.3112C33.3036 40.9144 34.3987 37.5 36.4296 37.5C38.4606 37.5 39.5557 40.9144 39.5557 42.3112ZM32.0782 51.1864C33.5685 50.3253 34.0891 48.4173 33.2221 46.9164C32.5231 45.7067 29.8134 43.2995 28.0573 44.3124C26.2989 45.329 27.1097 48.8335 27.8071 50.0424C28.6684 51.532 30.574 52.054 32.0782 51.1864ZM47.3714 42.3112C47.3714 44.0352 45.9694 45.4372 44.2454 45.4372C42.5214 45.4372 41.1193 44.0352 41.1193 42.3112C41.1193 40.9144 42.2145 37.5 44.2454 37.5C46.2763 37.5 47.3714 40.9144 47.3714 42.3112ZM48.5819 51.1582C50.0872 52.0266 52.0454 51.5001 52.9041 50.0134C53.7265 48.5896 54.233 45.2259 52.6026 44.2834C50.8404 43.2684 48.1354 45.6777 47.4378 46.8874C46.5744 48.382 47.0846 50.2927 48.5819 51.1582ZM34.8669 51.3582V50.9078C34.8669 47.0002 38.9843 47.0002 40.3375 47.0002C41.6907 47.0002 45.8081 47.0002 45.8081 50.9078V51.3582C45.8081 51.6779 45.9988 51.9626 46.2951 52.0825C47.8984 52.735 48.9341 54.1148 48.9341 55.5968C48.9341 57.7515 46.8308 59.5044 44.2451 59.5044C43.7283 59.5044 43.2727 59.4525 42.8522 59.3448C41.2053 58.9297 39.4675 58.9274 37.8281 59.3442C37.4038 59.4517 36.9467 59.5044 36.43 59.5044C33.8443 59.5044 31.7409 57.7513 31.7409 55.5968C31.7409 54.1148 32.7766 52.7348 34.3799 52.0831C34.676 51.9626 34.8669 51.6779 34.8669 51.3582Z"
        fill="#FDF6EE"
        style={stylePath}
      />
    </svg>
  );
}

export default BowlModalIcon;
