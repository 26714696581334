import React from 'react';

function RingwormModalIcon({ style, stylePath, className }) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        d="M77.2675 58.7927C77.2675 58.7927 73.3643 54.3949 73.3642 50.9865C73.3642 47.5781 76.9828 46.5767 77.2675 43.1802C77.6108 39.0852 73.3646 36.8167 73.3642 33.4083C73.3639 29.9991 76.8868 28.3972 77.171 24.9999C77.5134 20.9056 73.3646 19.2377 73.3642 15.83C73.3639 12.4216 77.2675 8.02368 77.2675 8.02368C77.6087 7.3434 77.5722 6.53451 77.171 5.88646C76.7697 5.23841 76.0637 4.84363 75.302 4.84363H50.9702C56.1838 7.41371 58.7498 9.99988 59.6282 15.8299C61.5771 34.5234 61.9207 44.5363 60.2316 61.9725H75.302C76.0637 61.9725 76.7697 61.5777 77.171 60.9296C77.5722 60.2816 77.6087 59.473 77.2675 58.7927Z"
        fill="#C6A788"
        style={stylePath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.49983 24.1315C8.17482 28.7967 18.5543 31.211 28.867 31.211C39.1797 31.211 49.5591 28.7967 55.2342 24.1315C56.6806 39.6309 56.4511 48.4175 55.2342 64.1699C53.7495 70 43.4294 75.1561 28.867 75.1561C14.3046 75.1561 3.74948 71.25 2.49983 64.1699C0.59253 53.3622 1.24966 32.5 2.49983 24.1315ZM34.0674 42.2831L34.3225 46.7328L37.4136 46.7469C41.8347 46.7675 42.7558 47.7258 42.4446 51.9807C42.1785 55.6172 42.1618 55.6331 38.2484 55.9161L34.3183 56.199L34.0657 60.6L33.8123 65H30.0478H26.2833L26.0282 60.5503C25.7781 56.1802 25.7228 56.1006 22.9597 56.1006C18.388 56.1006 17.4996 55.3381 17.4996 51.4167C17.4996 47.486 18.3855 46.7328 23.0057 46.7328C25.8207 46.7328 25.8651 46.6701 25.8651 42.6738C25.8651 40.4405 26.1336 38.313 26.4623 37.9458C26.7903 37.5777 28.5788 37.4025 30.4359 37.5552L33.8123 37.8334L34.0674 42.2831Z"
        fill="#C6A788"
        style={stylePath}
      />
      <path
        d="M28.8666 22.4219C21.0838 22.4219 15.9791 23.6751 14.0845 24.6192C14.9795 25.0652 16.6149 25.577 18.8867 25.9963C21.9667 26.5217 25.3326 26.8164 28.8666 26.8164C32.4007 26.8164 35.7666 26.5217 38.8465 25.9963C41.1183 25.577 42.7537 25.0652 43.6487 24.6192C41.7539 23.6751 36.6492 22.4219 28.8666 22.4219Z"
        fill="#C6A788"
        style={stylePath}
      />
      <path
        d="M28.8672 4.84424C14.3048 4.84424 2.5 9.76292 2.5 15.8305C2.5 18.6871 5.13879 21.2805 9.42758 23.2346C11.6832 18.3098 25.8298 18.0278 28.8672 18.0278C31.9046 18.0278 46.0513 18.3096 48.3068 23.2344C52.5959 21.2805 55.2344 18.6871 55.2344 15.8305C55.2343 9.76292 43.4295 4.84424 28.8672 4.84424Z"
        fill="#C6A788"
        style={stylePath}
      />
    </svg>
  );
}

export default RingwormModalIcon;
