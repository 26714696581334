import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarSunImage from '../../../assets/nurseryAvatars/avatarSun.png';

function AvatarSun({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <g clipPath="url(#avatarSun)">
    //     <path
    //       d="M17.9375 12.9907C15.1753 12.9907 12.9282 15.2383 12.9282 18.0005C12.9282 20.7626 15.1753 23.0102 17.9375 23.0102C20.6993 23.0102 22.9468 20.7631 22.9468 18.0005C22.9468 15.2378 20.6993 12.9907 17.9375 12.9907Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M17.9374 11.5182C17.3975 11.5182 16.96 11.0806 16.96 10.5412V8.97741C16.96 8.43756 17.3975 8 17.9374 8C18.4772 8 18.9148 8.43756 18.9148 8.97741V10.5412C18.9148 11.0806 18.4768 11.5182 17.9374 11.5182Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M17.9374 24.4814C17.3975 24.4814 16.96 24.919 16.96 25.4589V27.0222C16.96 27.5625 17.3975 28 17.9374 28C18.4772 28 18.9148 27.5625 18.9148 27.0222V25.4589C18.9148 24.919 18.4768 24.4814 17.9374 24.4814Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M22.5204 13.4168C22.139 13.0351 22.139 12.4164 22.5204 12.0347L23.6262 10.9288C24.0075 10.5475 24.6266 10.5475 25.0084 10.9288C25.3902 11.3106 25.3902 11.9297 25.0084 12.311L23.9026 13.4168C23.5212 13.7986 22.9026 13.7986 22.5204 13.4168Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M13.3543 22.5838C12.9725 22.2016 12.3538 22.2016 11.9721 22.5838L10.8662 23.6892C10.4849 24.0705 10.4845 24.6901 10.8662 25.0714C11.248 25.4527 11.8671 25.4527 12.2484 25.0714L13.3543 23.9651C13.736 23.5838 13.736 22.9646 13.3543 22.5838Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M24.4189 18.0001C24.4189 17.4603 24.8565 17.0227 25.3964 17.0227H26.9601C27.5 17.0227 27.9375 17.4603 27.9375 18.0001C27.9375 18.54 27.5 18.9771 26.9601 18.9771H25.3964C24.8565 18.9771 24.4189 18.54 24.4189 18.0001Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M11.4557 18.0001C11.4557 17.4603 11.0181 17.0227 10.4782 17.0227H8.91491C8.37506 17.0227 7.9375 17.4603 7.9375 18.0001C7.9375 18.54 8.37506 18.9771 8.91491 18.9771H10.4787C11.0181 18.9771 11.4557 18.54 11.4557 18.0001Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M22.5202 22.5836C22.902 22.2023 23.5211 22.2023 23.9024 22.5836L25.0082 23.6894C25.39 24.0703 25.39 24.6899 25.0082 25.0712C24.6265 25.4525 24.0078 25.4525 23.626 25.0712L22.5202 23.9653C22.1384 23.5836 22.1384 22.9649 22.5202 22.5836Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M13.3544 13.4167C13.7362 13.035 13.7362 12.4163 13.3544 12.0345L12.2486 10.9291C11.8668 10.5474 11.2481 10.5474 10.8664 10.9291C10.4846 11.3104 10.4846 11.9296 10.8664 12.3109L11.9722 13.4167C12.354 13.7989 12.9727 13.7989 13.3544 13.4167Z"
    //       fill="#02516C"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="avatarSun">
    //       <rect width="20" height="20" fill="white" transform="translate(7.9375 8)" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <Avatar style={style} avatarImage={AvatarSunImage} />
  );
}

export default AvatarSun;
