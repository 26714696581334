import React from 'react';
import { LOT_STATUS, MESSAGE_TYPE } from '../../constants';
import { LocalStorage } from '../../../common/localStorage';
import CoinIcon from '../../../ui/icons/CoinIcon';
import CoinModalIcon from '../../../ui/icons/modal/CoinModalIcon';
import PlusModalIcon from '../../../ui/icons/modal/PlusModalIcon';
import EquallyModalIcon from '../../../ui/icons/modal/EquallyModalIcon';
import LikeModalIcon from '../../../ui/icons/modal/LikeModalIcon';
import ChapterNineIcon from '../../../ui/icons/training/ChapterNineIcon';
import MinusModalIcon from '../../../ui/icons/modal/MinusModalIcon';
import MultiplyModalIcon from '../../../ui/icons/modal/MultiplyModalIcon';
import trainingStore from '../../trainingStore';
import CatCalcIcon from '../../../ui/icons/calc/CatCalcIcon';
import RootStore from '../../index';
import { CAT_COLOR, CAT_GENDER, MATING_STATUS, SICKNESS } from '../../../common/constants';

export const chapter = 10;

export const stepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'nursery',
      default_prices_override: {
        male_black: {
          buy_price: 6,
          sell_price: 6,
        },
        female_black: {
          buy_price: 8,
          sell_price: 8,
        },
        male_gray: {
          buy_price: 9,
          sell_price: 9,
        },
        female_gray: {
          buy_price: 11,
          sell_price: 11,
        },
      },
      cats: [
        {
          color: 'gray',
          gender: 'male',
          sickness: 'fracture',
        },
        {
          color: 'gray',
          gender: 'male',
          sickness: 'fracture',
        },
        {
          color: 'gray',
          gender: 'female',
          sickness: 'fracture',
        },
        {
          color: 'black',
          gender: 'male',
          sickness: 'fracture',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'female',
        },
      ],
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'shop',
      initial_money: 52,
      default_prices_override: {
        male_black: {
          buy_price: 6,
          sell_price: 6,
        },
        female_black: {
          buy_price: 7,
          sell_price: 7,
        },
        male_gray: {
          buy_price: 8,
          sell_price: 8,
        },
        female_gray: {
          buy_price: 11,
          sell_price: 11,
        },
      },
    },
  ],
  city_quota_override: {
    male_black: {
      buy_price: 8,
      sell_price: 8,
      buy_quantity: null,
    },
    female_black: {
      buy_price: 9,
      sell_price: 9,
      buy_quantity: 13,
    },
    male_gray: {
      buy_price: 11,
      sell_price: 11,
      buy_quantity: 31,
    },
    female_gray: {
      buy_price: 13,
      sell_price: 13,
      buy_quantity: 11,
    },
  },
  turn_durations: [100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
  hunger_enabled: false,
};

export const taskApiParams = {
  game_type: 'tutorial',
  chapter_num: 10,
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'nursery',
      default_prices_override: {
        male_black: {
          buy_price: 6,
          sell_price: 6,
        },
        female_black: {
          buy_price: 8,
          sell_price: 8,
        },
        male_gray: {
          buy_price: 9,
          sell_price: 9,
        },
        female_gray: {
          buy_price: 11,
          sell_price: 11,
        },
      },
      cats: [
        {
          color: 'gray',
          gender: 'male',
        },
        {
          color: 'gray',
          gender: 'male',
          sickness: 'fracture',
        },
        {
          color: 'gray',
          gender: 'male',
        },
        {
          color: 'gray',
          gender: 'male',
          sickness: 'ringworm',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'female',
        },
        {
          color: 'black',
          gender: 'female',
        },
      ],
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'shop',
      initial_money: 52,
      default_prices_override: {
        male_black: {
          buy_price: 6,
          sell_price: 6,
        },
        female_black: {
          buy_price: 7,
          sell_price: 7,
        },
        male_gray: {
          buy_price: 8,
          sell_price: 8,
        },
        female_gray: {
          buy_price: 11,
          sell_price: 11,
        },
      },
    },
  ],
  city_quota_override: {
    male_black: {
      buy_price: 8,
      sell_price: 8,
      buy_quantity: null,
    },
    female_black: {
      buy_price: 11,
      sell_price: 11,
      buy_quantity: 13,
    },
    male_gray: {
      buy_price: 9,
      sell_price: 9,
      buy_quantity: 31,
    },
    female_gray: {
      buy_price: 13,
      sell_price: 13,
      buy_quantity: 11,
    },
  },
  turn_durations: [10 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
  hunger_enabled: false,
};

export const steps = [
  {
    before: () => {
      LocalStorage.SHOWED_BUY_FROM_CITY = true;
      LocalStorage.SHOWED_SELL_TO_CITY = true;
      trainingStore.setCalculateHeader([
        'На счете',
        { val: 'Расход', color: 'red', icon: <CatCalcIcon className="training-calc__icon" /> },
        { val: 'доход', icon: <CatCalcIcon className="training-calc__icon" /> },
        { val: 'убыток от брака', color: 'red' },
        'на счете',
      ]);
      trainingStore.setCalculateExamples([['52', '-', '-', '-', '-']]);
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000002`,
      );

      socket.onmessage = (event) => {
        const reader = new FileReader();
        reader.onload = () => {
          const data = JSON.parse(reader.result);
          if (data.msg_type === 'synchronize') {
            const myCats = data.our_cats;
            const firstLot = [];
            const secondLot = [];
            myCats.map((cat) => {
              if (cat.color === 'gray') {
                if (cat.gender === 'male') {
                  firstLot.push({ cat_id: cat.cat_id, price: 8 });
                } else {
                  firstLot.push({ cat_id: cat.cat_id, price: 11 });
                }
              } else {
                if (cat.gender === 'male') {
                  secondLot.push({ cat_id: cat.cat_id, price: 6 });
                } else {
                  secondLot.push({ cat_id: cat.cat_id, price: 7 });
                }
              }
            });

            socket.send(
              JSON.stringify({
                msg_type: 'trading_request',
                to: LocalStorage.USER_UID,
                request_type: 'sell',
                contents: [...firstLot],
              }),
            );
            socket.send(
              JSON.stringify({
                msg_type: 'trading_request',
                to: LocalStorage.USER_UID,
                request_type: 'sell',
                contents: [...secondLot],
              }),
            );
          }
        };
        reader.readAsText(event.data);
      };
    },
    actionName: 'onNextButton',
    chapter: '10',
    chapterIcon: <ChapterNineIcon className="training-modal__chapter-icon" />,
    title: <div>ДОБРО ПОЖАЛОВАТЬ В ГЛАВУ «БОЛЕЗНИ. ЗООМАГАЗИН»</div>,
    message: (
      <p className="training-modal__desc">
        В этой главе ты играешь за зоомагазин, поэтому покупать котят надо очень внимательно. Играя за зоомагазин, ты
        можешь торговать с городом, который никогда не купит больных котят.
      </p>
    ),
    nextButton: true,
  },

  {
    actionName: 'setCurrentLot',
    elements: ['.mail'],
    pulseElements: ['.mail'],
    step: 185,
    title: 'НОВАЯ СДЕЛКА',
    message: (
      <p className="training-modal__desc">
        Питомник хочет продать тебе котиков. Кликни на конверт, чтобы посмотреть его предложение.
      </p>
    ),
    position: 'left',
  },
  {
    actionName: 'acceptLot',
    elements: ['.lot-area__lot-panel-btn-wrapper .text_button--color-green'],
    pulseElements: ['.lot-area__lot-panel-btn-wrapper .text_button--color-green'],
    title: 'ВЕРЮ НА СЛОВО',
    message: <p className="training-modal__desc">Одобри эту сделку, не проверяя котиков, посмотрим, что получится.</p>,
    position: 'left',
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([['52', { val: '-27', color: 'red' }, '-', '-', '-']]);
    },
    actionName: 'notificationModalClose',
  },
  {
    actionName: 'sellToCity',
    checkParams: (params) => {
      if (params[0].sickness !== SICKNESS.NONE) {
        return true;
      }
      return 'skip';
    },
    elements: ['.lot-area.lot-area--city', '.lot-area.lot-area--shop'],
    // ToDo переделать на inline-стили подсветку для lot-area
    isDisabledDoubleClickCat: true,
    title: 'ПРОДАЕМ',
    message: (
      <p className="training-modal__desc">Теперь перетащи котят из своей витрины в витрину города, чтобы продать их.</p>
    ),
    position: 'bottom',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        ['52', { val: '-27', color: 'red' }, '0', { val: '-27', color: 'red' }, '25'],
      ]);
    },
    actionName: 'hideCatIsSicknessOrHungryModal',
    elements: ['.modal'],
    title: 'НА ОШИБКАХ УЧАТСЯ',
    message: (
      <p className="training-modal__desc">
        В этот раз мы купили у питомника больных котят и убедились, что город не покупает больных котов.
      </p>
    ),
    position: 'bottom',
  },

  {
    actionName: 'setCurrentLot',
    elements: ['.mail'],
    pulseElements: ['.mail'],
    step: 185,
    title: 'Проверь второй конверт.',
    message: <div></div>,
    position: 'left',
  },

  {
    actionName: 'setOpenedGroupedCat',
    checkParams: (params) => {
      return params[0].gender === CAT_GENDER.MALE;
    },
    elements: ['.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area__lot-panel'],
    title: 'ОСМОТР КОТИКОВ',
    isDisabledAllDeleteCat: true,
    isDisabledDoubleClickCat: true,
    message: (
      <p className="training-modal__desc">
        Питомник хочет продать тебе несколько котят. Кликни на черного мальчика, чтобы проверить, здоровы ли они.
      </p>
    ),
    position: 'top',
  },

  {
    actionName: 'addShowedSicknessCatId',
    checkParams: (params) => {
      if (params[0].sickness !== SICKNESS.NONE) {
        return true;
      }
      return 'skip';
    },
    elements: ['.modal--grouped-cat'],
    title: 'ОСМОТР КОТИКОВ',
    needOverlayClickModal: false,
    isDisabledDoubleClickCat: true,
    message: (
      <p className="training-modal__desc">
        Перетащи лупу из правого нижнего угла на котят, чтобы проверить, здоровы ли они.
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'setOpenedGroupedCat',
    elements: ['.modal--grouped-cat'],
    title: 'БОЛЬНОЙ КОТИК',
    isDisabledDoubleClickCat: true,
    message: (
      <p className="training-modal__desc">
        Питомник задумал продать тебе больного котика. Ты не сможешь продать его в город. Закрой лупу и откажись от
        сделки.
      </p>
    ),
    position: 'top',
  },

  {
    actionName: 'cancelLot',
    elements: ['.lot-area__lot-panel-btn-wrapper .text_button--color-red'],
    pulseElements: ['.lot-area__lot-panel-btn-wrapper .text_button--color-red'],
  },
  {
    actionName: 'notificationModalClose',
  },
  {
    actionName: 'onNextButton',
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isVeryWide: true,
    message: (
      <div>
        <p className="training-modal__desc">
          Вывод – зоомагазин никогда не сможет продать больного котенка в город! Будь внимателен перед покупкой. Чтобы
          решить практическое задание, посмотри, как мы считали.
        </p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">Было на счете</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на котят</p>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">ожидаемый заработок на котятах</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">убыток от брака</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">стало на счете</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">52</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">27</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">35</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">27</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">25</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--center">
          <div className="training-modal__price">
            <p className="training-modal__price-name">МАРЖА</p>
            <div className="training-modal__price-value">
              <span className="training-modal__price-cost training-modal__price-cost--red notranslate">-8</span>
              <CoinModalIcon className="training-modal__price-coin" />
            </div>
          </div>
          <div className="training-modal__price">
            <p className="training-modal__price-name">Убыток</p>
            <div className="training-modal__price-value">
              <span className="training-modal__price-cost training-modal__price-cost--red notranslate">-27</span>
              <CoinModalIcon className="training-modal__price-coin" />
            </div>
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--center">
          <p className="training-modal__block-text">Количество котиков</p>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">цена продажи</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">ожидаемый заработок на котятах</p>
        </div>
        <div className="training-modal__blocks training-modal__blocks--center">
          <p className="training-modal__block-text">Количество больных</p>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">цена покупки</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">убыток от брака</p>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    colorBg: 'green',
    title: (
      <div>
        <span>ПОЗДРАВЛЯЕМ!</span>
        <br /> <span>ОБУЧЕНИЕ ПО</span> <span className="notranslate"> 10&nbsp;</span>
        <span>ГЛАВЕ ЗАВЕРШЕНО</span>
      </div>
    ),
    message: (
      <div>
        <p className="training-modal__desc">Чтобы перейти на следующий уровень, выполни практическое задание.</p>
        <div className="training-modal__icon-block">
          <LikeModalIcon className="training-modal__icon" />
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
];

export const task = {
  chapter: 10,
  data: { confirmedLotCount: 0, firstLaunch: true },
  watch: [
    {
      actionName: 'hideTaskModal',
      checkParams: (params, data) => {
        if (data.firstLaunch) {
          data.firstLaunch = false;
          const socket = new WebSocket(
            `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000002`,
          );
          socket.onmessage = (event) => {
            const reader = new FileReader();
            reader.onload = () => {
              const data = JSON.parse(reader.result);
              if (data.msg_type === 'synchronize') {
                const myCats = data.our_cats;
                const firstLot = [];
                const secondLot = [];
                myCats.map((cat) => {
                  if (cat.color === 'gray' && cat.gender === 'male') {
                    firstLot.push({ cat_id: cat.cat_id, price: 11 });
                  } else {
                    if (cat.gender === 'male') {
                      secondLot.push({ cat_id: cat.cat_id, price: 6 });
                    } else {
                      secondLot.push({ cat_id: cat.cat_id, price: 8 });
                    }
                  }
                });

                socket.send(
                  JSON.stringify({
                    msg_type: 'trading_request',
                    to: LocalStorage.USER_UID,
                    request_type: 'sell',
                    contents: [...firstLot],
                  }),
                );
                socket.send(
                  JSON.stringify({
                    msg_type: 'trading_request',
                    to: LocalStorage.USER_UID,
                    request_type: 'sell',
                    contents: [...secondLot],
                  }),
                );
              }
            };
            reader.readAsText(event.data);
          };
        }
        return 'skip';
      },
    },
    {
      actionName: 'hideCatIsSicknessOrHungryModal',
      checkParams: () => {
        return false;
      },
    },
    {
      actionName: 'acceptEndTurn',
      checkParams: () => {
        return false;
      },
    },
    {
      actionName: 'onReceiveMessage__balance',
      checkParams: (params) => {
        if (params[0].current_balance === 62) {
          return true;
        }
        return 'skip';
      },
    },
    {
      actionName: 'onReceiveMessage__game_over',
      checkParams: (params) => {
        return false;
      },
    },
  ],
  title: (
    <div>
      <span className="notranslate">10 </span> <span>ГЛАВА «БОЛЕЗНИ. ЗООМАГАЗИН» ПРАКТИЧЕСКОЕ ЗАДАНИЕ</span>
    </div>
  ),
  message: (
    <p className="training-modal__desc">
      <span>У тебя</span> <span className="notranslate"> 52</span>
      <CoinIcon style={{ position: 'relative', top: '2px' }} />
      <span>.</span>
      <br /> <span>Твоя задача – заработать</span>
      <span className="notranslate"> 62</span>
      <CoinIcon style={{ position: 'relative', top: '2px' }} />
      <span>.</span>
      <br /> <span>Изучи входящие заявки и реши, какую из них стоит принять.</span>
    </p>
  ),
  wrongMessage: (
    <div className="training-modal__price training-modal__price--single">
      <p className="training-modal__price-name">
        <span>Не получилось</span>
        <br />
        <span>заработать</span>
      </p>
      <div className="training-modal__price-value">
        <CoinModalIcon className="training-modal__price-coin" />
        <span className="training-modal__price-cost training-modal__price-cost--white notranslate">62</span>
      </div>
    </div>
  ),
};
