import React from 'react';

function DivisionModalIcon({ style, fill, stylePath, className }) {
  return (
    <svg
      className={className}
      width="18"
      height="23"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0.84511 20.0024L1.32287 18.6422C1.58594 17.8943 3.94687 13.6229 6.56964 9.14971C10.559 2.34682 11.4565 1.01164 12.0606 0.981342C12.4578 0.961469 13.857 1.61928 15.17 2.44367L17.5576 3.94232L17.0481 5.51788C16.7679 6.38448 14.4136 10.6597 11.8162 15.0185C7.89585 21.5967 6.97077 22.9496 6.37136 22.9798C5.97405 23.0001 4.56812 22.3384 3.24713 21.5094L0.84511 20.0024Z"
        fill={fill ? fill : '#C6A788'}
      />
    </svg>
  );
}

export default DivisionModalIcon;
