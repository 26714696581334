import {
  CAT_COLOR,
  CAT_GENDER,
  LTD_ACTIONS,
  LTD_ACTIONS_STATUS,
  LTD_FINES_PAYMENT,
  LTD_FINES_TYPE,
  LTD_STATUS,
  MATING_STATUS,
  SICKNESS,
} from '../../../../../common/constants';
import getNowInUnix from '../../../../../common/getNowInUnix';
import { LocalStorage } from '../../../../../common/localStorage';

const tempDealId = 'b67dc484-e9a4-11ed-9a2a-7a9990bbecec';
const _LTD_OBJECT_BY_STAGE = {
  INITIAL_DISPATCH: () => ({
    // Первичная отправка
    message_owner: LocalStorage.USER_UID,
    initiator: LocalStorage.USER_UID,
    status: LTD_STATUS.TERMS_AGREEMENT,
    partner: '00000000-0000-0000-0000-000000000021',
    contents: [
      {
        seller: '00000000-0000-0000-0000-000000000021',
        buyer: LocalStorage.USER_UID,
        shipping_season: 1,
        storage_timer: 10 * 60,
        shipping_timer: 5 * 60,
        prepaid: {
          season: 0,
          money: 12,
          timer: 15 * 60,
        },
        payment_before_shipment: {
          season: 1,
          money: 0,
          timer: 8 * 60,
        },
        payment_after_shipment: {
          season: 1,
          money: 30,
          timer: 3 * 60,
        },
        abstract_contents: [
          {
            gender: CAT_GENDER.MALE,
            color: CAT_COLOR.WHITE,
            count: 2,
            price: 10,
          },
          {
            gender: CAT_GENDER.FEMALE,
            color: CAT_COLOR.WHITE,
            count: 2,
            price: 11,
          },
        ],
        area_num: 0,
        exact_contents: [],
        old_exact_contents: [],
        examined_cats: [],
        actions: [],
        doctor_requests: [],
        captains: [],
      },
      {
        seller: LocalStorage.USER_UID,
        buyer: '00000000-0000-0000-0000-000000000021',
        shipping_season: 5,
        storage_timer: 15 * 60,
        shipping_timer: 10 * 60,
        prepaid: {
          season: 5,
          money: 19,
          timer: 20 * 60,
        },
        payment_before_shipment: {
          season: 5,
          money: 38,
          timer: 13 * 60,
        },
        payment_after_shipment: {
          season: 5,
          money: 7,
          timer: 5 * 60,
        },
        abstract_contents: [
          {
            gender: CAT_GENDER.MALE,
            color: CAT_COLOR.WHITE,
            count: 8,
            price: 8,
          },
        ],
        area_num: 0,
        exact_contents: [],
        old_exact_contents: [],
        examined_cats: [],
        actions: [],
        doctor_requests: [],
        captains: [],
      },
    ],
    updater: null,
    overdue_payment_liability: {
      payment_type: LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
      value: 120,
    },
    overdue_delivery_liability: {
      payment_type: LTD_FINES_PAYMENT.PCT_OF_MISSED_CATS,
      value: 120,
    },
    low_quality_liability: {
      payment_type: LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
      value: 80,
    },
    initiator_bank_guarantee: {
      amount: 30,
      last_payment_season: 1,
      payments_schedule: {
        1: 2,
      },
    },
    partner_bank_guarantee: {
      amount: 30,
      last_payment_season: 1,
      payments_schedule: {},
    },
    one_way_termination: {
      value: 20,
      payment_type: LTD_FINES_PAYMENT.FIXED,
    },
    two_way_termination: null,
    initiator_confirmed: true,
    partner_confirmed: false,
    old_content: null,
    msg_type: 'longterm_deal_update',
  }),
  PREPAID_PAYMENT_FROM_NURSERY: () => ({
    // Оплата аванса от питомника
    initiator: LocalStorage.USER_UID,
    status: LTD_STATUS.ACTIONS_PHASE,
    partner: '00000000-0000-0000-0000-000000000021',
    contents: [
      {
        seller: '00000000-0000-0000-0000-000000000021',
        buyer: LocalStorage.USER_UID,
        shipping_season: 1,
        storage_timer: 10 * 60,
        shipping_timer: 5 * 60,
        prepaid: {
          season: 0,
          money: 12,
          timer: 15 * 60,
        },
        payment_before_shipment: {
          season: 1,
          money: 0,
          timer: 8 * 60,
        },
        payment_after_shipment: {
          season: 1,
          money: 30,
          timer: 3 * 60,
        },
        abstract_contents: [
          {
            gender: CAT_GENDER.MALE,
            color: CAT_COLOR.WHITE,
            count: 2,
            price: 10,
          },
          {
            gender: CAT_GENDER.FEMALE,
            color: CAT_COLOR.WHITE,
            count: 2,
            price: 11,
          },
        ],
        area_num: 0,
        exact_contents: [],
        old_exact_contents: [],
        examined_cats: [],
        actions: [
          {
            executor: LocalStorage.USER_UID,
            action_type: LTD_ACTIONS.PREPAID,
            status: LTD_ACTIONS_STATUS.COMPLETED,
            season: 0,
            timer: 900,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            sent_liabilities: [],
          },
          {
            executor: '00000000-0000-0000-0000-000000000021',
            action_type: LTD_ACTIONS.TO_STORAGE,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 1,
            timer: 600,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_DELIVERY, LTD_FINES_TYPE.LOW_QUALITY],
            sent_liabilities: [],
          },
          {
            executor: LocalStorage.USER_UID,
            action_type: LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 1,
            timer: 480,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            sent_liabilities: [],
          },
          {
            executor: '00000000-0000-0000-0000-000000000021',
            action_type: LTD_ACTIONS.SHIPPING,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 1,
            timer: 300,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_DELIVERY],
            sent_liabilities: [],
          },
          {
            executor: LocalStorage.USER_UID,
            action_type: LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 1,
            timer: 180,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            sent_liabilities: [],
          },
        ],
        doctor_requests: [],
        captains: [],
      },
      {
        seller: LocalStorage.USER_UID,
        buyer: '00000000-0000-0000-0000-000000000021',
        shipping_season: 5,
        storage_timer: 15 * 60,
        shipping_timer: 10 * 60,
        prepaid: {
          season: 5,
          money: 19,
          timer: 20 * 60,
        },
        payment_before_shipment: {
          season: 5,
          money: 38,
          timer: 13 * 60,
        },
        payment_after_shipment: {
          season: 5,
          money: 7,
          timer: 5 * 60,
        },
        abstract_contents: [
          {
            gender: CAT_GENDER.MALE,
            color: CAT_COLOR.WHITE,
            count: 8,
            price: 8,
          },
        ],
        area_num: 0,
        exact_contents: [],
        old_exact_contents: [],
        examined_cats: [],
        actions: [
          {
            executor: '00000000-0000-0000-0000-000000000021',
            action_type: LTD_ACTIONS.PREPAID,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 5,
            timer: 120000,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            sent_liabilities: [],
          },
          {
            executor: LocalStorage.USER_UID,
            action_type: LTD_ACTIONS.TO_STORAGE,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 5,
            timer: 90000,
            overtime: 0,
            liability: [LTD_FINES_TYPE.LOW_QUALITY, LTD_FINES_TYPE.OVERDUE_DELIVERY],
            sent_liabilities: [],
          },
          {
            executor: '00000000-0000-0000-0000-000000000021',
            action_type: LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 5,
            timer: 78000,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            sent_liabilities: [],
          },
          {
            executor: LocalStorage.USER_UID,
            action_type: LTD_ACTIONS.SHIPPING,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 5,
            timer: 60000,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_DELIVERY],
            sent_liabilities: [],
          },
          {
            executor: '00000000-0000-0000-0000-000000000021',
            action_type: LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 5,
            timer: 30000,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            sent_liabilities: [],
          },
        ],
        doctor_requests: [],
        captains: [],
      },
    ],
    updater: null,
    overdue_payment_liability: {
      payment_type: LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
      value: 120,
    },
    overdue_delivery_liability: {
      payment_type: LTD_FINES_PAYMENT.PCT_OF_MISSED_CATS,
      value: 120,
    },
    low_quality_liability: {
      payment_type: LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
      value: 80,
    },
    initiator_bank_guarantee: {
      amount: 30,
      last_payment_season: 1,
      payments_schedule: {},
    },
    partner_bank_guarantee: {
      amount: 30,
      last_payment_season: 1,
      payments_schedule: {
        1: 1,
        2: 1,
      },
    },
    one_way_termination: {
      value: 20,
      payment_type: LTD_FINES_PAYMENT.FIXED,
    },
    two_way_termination: null,
    initiator_confirmed: true,
    partner_confirmed: true,
    old_content: null,
    msg_type: 'longterm_deal_update',
  }),
  NURSERY_PAYMENT_AFTER_SHIPPING: () => ({
    // Оплата после отгрузки питомником
    message_owner: LocalStorage.USER_UID,
    initiator: LocalStorage.USER_UID,
    status: LTD_STATUS.ACTIONS_PHASE,
    partner: '00000000-0000-0000-0000-000000000021',
    contents: [
      {
        seller: '00000000-0000-0000-0000-000000000021',
        buyer: LocalStorage.USER_UID,
        shipping_season: 2,
        storage_timer: 10 * 60,
        shipping_timer: 5 * 60,
        prepaid: {
          season: 1,
          money: 12,
          timer: 15 * 60,
        },
        payment_before_shipment: {
          season: 2,
          money: 0,
          timer: 8 * 60,
        },
        payment_after_shipment: {
          season: 2,
          money: 30,
          timer: 3 * 60,
        },
        abstract_contents: [
          {
            gender: CAT_GENDER.MALE,
            color: CAT_COLOR.WHITE,
            count: 2,
            price: 10,
          },
          {
            gender: CAT_GENDER.FEMALE,
            color: CAT_COLOR.WHITE,
            count: 2,
            price: 11,
          },
        ],
        area_num: 0,
        exact_contents: [],
        old_exact_contents: [],
        examined_cats: [],
        actions: [
          {
            executor: LocalStorage.USER_UID,
            action_type: LTD_ACTIONS.PREPAID,
            status: LTD_ACTIONS_STATUS.COMPLETED,
            season: 1,
            timer: 900,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            sent_liabilities: [],
          },
          {
            executor: '00000000-0000-0000-0000-000000000021',
            action_type: LTD_ACTIONS.TO_STORAGE,
            status: LTD_ACTIONS_STATUS.COMPLETED,
            season: 2,
            timer: 600,
            overtime: 0,
            liability: [LTD_FINES_TYPE.LOW_QUALITY, LTD_FINES_TYPE.OVERDUE_DELIVERY],
            sent_liabilities: [],
          },
          {
            executor: LocalStorage.USER_UID,
            action_type: LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT,
            status: LTD_ACTIONS_STATUS.COMPLETED,
            season: 2,
            timer: 480,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            sent_liabilities: [],
          },
          {
            executor: '00000000-0000-0000-0000-000000000021',
            action_type: LTD_ACTIONS.SHIPPING,
            status: LTD_ACTIONS_STATUS.COMPLETED,
            season: 2,
            timer: 300,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_DELIVERY],
            sent_liabilities: [],
          },
          {
            executor: LocalStorage.USER_UID,
            action_type: LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT,
            status: LTD_ACTIONS_STATUS.COMPLETED,
            season: 2,
            timer: 180,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            sent_liabilities: [],
          },
        ],
        doctor_requests: [],
        captains: [],
      },
      {
        seller: LocalStorage.USER_UID,
        buyer: '00000000-0000-0000-0000-000000000021',
        shipping_season: 5,
        storage_timer: 15 * 60,
        shipping_timer: 10 * 60,
        prepaid: {
          season: 5,
          money: 19,
          timer: 0,
        },
        payment_before_shipment: {
          season: 5,
          money: 38,
          timer: 0,
        },
        payment_after_shipment: {
          season: 5,
          money: 7,
          timer: 0,
        },
        abstract_contents: [
          {
            gender: CAT_GENDER.MALE,
            color: CAT_COLOR.WHITE,
            count: 8,
            price: 8,
          },
        ],
        area_num: 0,
        exact_contents: [],
        old_exact_contents: [],
        examined_cats: [],
        actions: [
          {
            executor: '00000000-0000-0000-0000-000000000021',
            action_type: LTD_ACTIONS.PREPAID,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 5,
            timer: 30000,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            sent_liabilities: [],
          },
          {
            executor: LocalStorage.USER_UID,
            action_type: LTD_ACTIONS.TO_STORAGE,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 5,
            timer: 30000,
            overtime: 0,
            liability: [LTD_FINES_TYPE.LOW_QUALITY, LTD_FINES_TYPE.OVERDUE_DELIVERY],
            sent_liabilities: [],
          },
          {
            executor: '00000000-0000-0000-0000-000000000021',
            action_type: LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 5,
            timer: 30000,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            sent_liabilities: [],
          },
          {
            executor: LocalStorage.USER_UID,
            action_type: LTD_ACTIONS.SHIPPING,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 5,
            timer: 30000,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_DELIVERY],
            sent_liabilities: [],
          },
          {
            executor: '00000000-0000-0000-0000-000000000021',
            action_type: LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT,
            status: LTD_ACTIONS_STATUS.PENDING,
            season: 5,
            timer: 30000,
            overtime: 0,
            liability: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            sent_liabilities: [],
          },
        ],
        doctor_requests: [],
        captains: [],
      },
    ],
    updater: null,
    overdue_payment_liability: {
      payment_type: LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
      value: 120,
    },
    overdue_delivery_liability: {
      payment_type: LTD_FINES_PAYMENT.PCT_OF_MISSED_CATS,
      value: 120,
    },
    low_quality_liability: {
      payment_type: LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
      value: 80,
    },
    initiator_bank_guarantee: {
      amount: 30,
      last_payment_season: 1,
      payments_schedule: {
        1: 2,
      },
    },
    partner_bank_guarantee: {
      amount: 30,
      last_payment_season: 1,
      payments_schedule: {
        1: 2,
      },
    },
    one_way_termination: {
      value: 20,
      payment_type: LTD_FINES_PAYMENT.FIXED,
    },
    two_way_termination: null,
    initiator_confirmed: true,
    partner_confirmed: true,
    old_content: null,
    msg_type: 'longterm_deal_update',
  }),
};

export const LTD_OBJECT_BY_STAGE = new Proxy(_LTD_OBJECT_BY_STAGE, {
  get(target, key, receiver) {
    const LTDInfoGenerator = target[key];
    if (!LTDInfoGenerator) {
      return null;
    }
    return {
      ...target[key](),
      deal_id: tempDealId,
      server_time: getNowInUnix(),
    };
  },
});
