import trainingStore from '../../trainingStore';

class Step {
  constructor({
    actionName = null,
    timeoutBeforeNextStep = null,
    hideModalsOnStartTurn = true,
    isDisabledDoubleClickCat = false,
    blockedActionsNames = [],
    title = null,
    isWide = null,
    message = null,
    elements = null,
    pulseElements = null,
    position = null,
    menuClickDisabled = null,
    nextButton = null,
    buttonText = null,
    disabledElements,
    isMax = null,
    after = null,
    before = null,
    shadedElements = null,
    needOverlayClickModal = null,
    checkParams = null,
    actionNames = null,
    showingErrorModalIsNeeded = null,
    acceptActionsWithInvalidCheckParams = null,
    removeFocusFromElements = null,
    selectMenuPosition = null,
    elementsForChangeZIndex = null,
    methodsProxy = null,
    headerDisabled = true,
    hiddenTimersIds = null,
    forceTimerValue = null,
    acceptedDropTypesOverride = null,
    draggableSettingsOverride = null,
    observeHealthExaminationsOfCats = false,
    selectorIntoView = null,
  }) {
    this.actionName = actionName;
    this.timeoutBeforeNextStep = timeoutBeforeNextStep;
    this.isDisabledDoubleClickCat = isDisabledDoubleClickCat;
    this.hideModalsOnStartTurn = hideModalsOnStartTurn;
    this.blockedActionsNames = blockedActionsNames;
    this.title = title;
    this.isWide = isWide;
    this.message = message;
    this.elements = elements;
    this.pulseElements = pulseElements;
    this.position = position;
    this.menuClickDisabled = menuClickDisabled;
    this.nextButton = nextButton;
    this.buttonText = buttonText;
    this.disabledElements = disabledElements;
    this.isMax = isMax;
    this.after = after;
    this.before = before;
    this.shadedElements = shadedElements;
    this.needOverlayClickModal = needOverlayClickModal;
    this.checkParams = checkParams;
    this.actionNames = actionNames;
    this.showingErrorModalIsNeeded = showingErrorModalIsNeeded === null ? true : showingErrorModalIsNeeded;
    this.acceptActionsWithInvalidCheckParams = acceptActionsWithInvalidCheckParams;
    this.removeFocusFromElements = removeFocusFromElements;
    this.selectMenuPosition = selectMenuPosition;
    this.elementsForChangeZIndex = elementsForChangeZIndex;
    this.originalElementsZIndex = null;
    this.methodsProxy = methodsProxy;
    this.headerDisabled = headerDisabled;
    this.hiddenTimersIds = hiddenTimersIds;
    this.forceTimerValue = forceTimerValue;
    this.acceptedDropTypesOverride = acceptedDropTypesOverride;
    this.draggableSettingsOverride = draggableSettingsOverride;
    this.observeHealthExaminationsOfCats = observeHealthExaminationsOfCats;
    this.selectorIntoView = selectorIntoView;
    this.examinedCatsIds = [];

    this.nextStep = null;
    this.previousStep = null;
  }

  setNextStep(nextStep) {
    this.nextStep = nextStep;
  }

  setPreviousStep(previousStep) {
    this.previousStep = previousStep;
  }

  actionIsBlocked(actionName) {
    return this.blockedActionsNames.includes(actionName);
  }

  addCatToExaminedCats(catId) {
    if (this.examinedCatsIds.find((_catId) => catId === _catId)) {
      return;
    }
    this.examinedCatsIds.push(catId);
  }

  #changeZIndex(elements) {
    const previousElementsZIndex = {};
    Object.entries(elements).forEach(async ([selector, { newZIndex, returnOriginalZIndexIsNeeded }]) => {
      const element = await trainingStore.checkElementExist(selector);
      if (element) {
        if (returnOriginalZIndexIsNeeded) {
          previousElementsZIndex[selector] = { newZIndex: element.style.zIndex };
        }
        element.style.zIndex = newZIndex;
      }
    });
    return previousElementsZIndex;
  }

  changeElementsZIndex() {
    if (!this.elementsForChangeZIndex) {
      return;
    }
    this.originalElementsZIndex = this.#changeZIndex(this.elementsForChangeZIndex);
  }

  atOnsetOfStep() {
    this.changeElementsZIndex();
  }

  returnOriginalElementsZIndex() {
    if (!this.originalElementsZIndex) {
      return;
    }

    const changeZIndex = () => this.#changeZIndex(this.originalElementsZIndex);

    if (!this.nextStep) {
      trainingStore.addOnSeamlessChangeCallback(changeZIndex);
      return;
    }
    changeZIndex();
  }

  methodsProxyByActionName({ actionName }) {
    return this.methodsProxy?.[actionName];
  }

  async doRemoveFocusFromElements() {
    const focused = await trainingStore.checkElementExist(':focus');
    focused?.blur();
  }

  onNextStep() {
    // ToDo: можно добавить сюда after и сделать функцию-аналог в виде beforeStep
    if (this.removeFocusFromElements) {
      this.doRemoveFocusFromElements();
    }
    this.returnOriginalElementsZIndex();
  }

  scrollIntoView() {
    if (this.selectorIntoView) {
      const elementIntoView = document.querySelector(this.selectorIntoView);
      if (elementIntoView) {
        elementIntoView.scrollIntoView();
        document.querySelector('#appLayout')?.scrollIntoView();
      }
    }
  }
}

export default Step;
