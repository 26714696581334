import React from 'react';

function FeeshModalIcon({ style, stylePath, className }) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        style={stylePath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.6082 4.42096C44.472 5.52101 39.4476 8.0752 36.4431 10.0948C33.4386 12.1156 30.4747 13.7685 29.8558 13.7685C29.238 13.7685 25.2736 15.3872 21.0471 17.3645C12.1337 21.5351 11.4496 23.5033 17.6622 27.1027L21.9462 29.585L19.7057 43.3397L14.4698 44.6157C2.09169 47.6291 -0.9668 52.8769 6.60971 58.1007C9.79087 60.2928 11.5227 60.7017 15.6119 60.2265L20.6757 59.636V65.1762C20.6757 74.7511 27.7582 81.1206 31.8576 75.2331C32.867 73.7835 34.4716 69.8996 35.4225 66.6006C37.3028 60.0814 39.8156 58.7563 42.3835 62.9304C46.7192 69.9773 58.2342 66.6177 58.3366 58.2754C58.3613 56.2581 59.9265 54.0569 63.4813 51.0412C72.6625 43.2494 77.5034 32.3803 77.5001 19.5624C77.4989 10.6557 74.9367 4.50317 70.7506 3.34829C65.3279 1.85414 53.3403 2.40934 47.6082 4.42096ZM60.3982 11.7123C64.2174 15.5894 59.7264 22.0491 54.9969 19.4801C51.0246 17.3222 52.744 10.3415 57.2474 10.3415C58.2377 10.3415 59.6555 10.9584 60.3982 11.7123ZM48.9147 30.6942C51.2856 32.6784 55.2692 35.0384 57.7684 35.9397L62.3112 37.5766L57.2474 37.6623C48.7763 37.8062 42.5783 32.6258 40.9601 24.0493L40.205 20.0512L42.4039 23.5696C43.6135 25.5047 46.5426 28.71 48.9147 30.6942Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default FeeshModalIcon;
