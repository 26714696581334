import React from 'react';
import Coin from '../../assets/coin.png';
import classNames from '../../common/classNames';

function CoinIcon({ style, customClass }) {
  return (
    <span
      className={classNames({
        'coin-icon': true,
        [`${customClass}`]: !!customClass,
      })}
      style={{ backgroundImage: `url(${Coin})`, ...style }}
    ></span>
  );
}

export default CoinIcon;
