import React from 'react';

function CEOIcon({ style }) {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g filter="url(#filter0_di_2264_65950)">
        <rect width="26" height="26" rx="13" fill="#AD12F5" />
      </g>
      <path
        d="M9.69371 13.1395C9.62827 13.7621 9.38732 14.0846 8.56815 13.9985C7.74898 13.9124 7.6295 13.552 7.69235 12.954L7.94979 10.5047C8.01264 9.90669 8.20444 9.579 9.02361 9.6651C9.90012 9.75722 10.0114 10.1168 9.94684 10.7311L11.0773 10.85C11.2245 9.44918 10.6523 8.82585 9.12865 8.66571C7.605 8.50557 6.9657 8.99328 6.82536 10.3285L6.55587 12.8925C6.41898 14.195 6.96403 14.8403 8.46311 14.9979C9.96219 15.1554 10.6787 14.6427 10.8242 13.2583L9.69371 13.1395Z"
        fill="#390081"
      />
      <path
        d="M12.2164 8.99853L11.5526 15.3143L15.2962 15.7078L15.4047 14.6756L12.7916 14.401L12.9663 12.7381L15.0962 12.9619L15.2029 11.9462L13.0731 11.7223L13.2436 10.1004L15.742 10.363L15.8454 9.37995L12.2164 8.99853Z"
        fill="#390081"
      />
      <path
        d="M18.1224 16.0545C19.6215 16.2121 20.338 15.6994 20.4749 14.3969L20.7556 11.7264C20.8959 10.3911 20.3228 9.77601 18.7992 9.61587C17.2755 9.45572 16.6362 9.94344 16.4959 11.2787L16.2152 13.9492C16.0783 15.2517 16.6234 15.897 18.1224 16.0545ZM18.2275 15.0551C17.4083 14.969 17.2888 14.6086 17.3517 14.0106L17.6203 11.4548C17.6806 10.8814 17.875 10.5292 18.6941 10.6153C19.5706 10.7074 19.6793 11.0915 19.6191 11.6649L19.3504 14.2207C19.2876 14.8187 19.0466 15.1412 18.2275 15.0551Z"
        fill="#390081"
      />
      <defs>
        <filter
          id="filter0_di_2264_65950"
          x="0"
          y="0"
          width="27"
          height="27"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2264_65950" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2264_65950" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2264_65950" />
        </filter>
      </defs>
    </svg>
  );
}

export default CEOIcon;
