import generatePlayerSocketUrl from './generatePlayerSocketUrl';
import registerOnSocketMessageEvent from '../../../../../common/registerOnSocketMessageEvent';
import RootStore from '../../../../index';

const sendMessageByPlayer = ({ playerId, messageType, data }) => {
  const socket = new WebSocket(generatePlayerSocketUrl({ playerId: playerId }));
  socket.onopen = () => {
    registerOnSocketMessageEvent({
      socket,
      onmessage() {
        socket.close();
      },
    });
    RootStore.appStore.sendMessageBySocket(socket, messageType, data);
  };
};

export default sendMessageByPlayer;
