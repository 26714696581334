import React from 'react';

const BankIcon = (fill = '#EDB260') => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.59822 0.193305C8.42545 0.0678051 8.21561 0 8 0C7.78439 0 7.57455 0.0678051 7.40178 0.193305L0.315556 5.34025C-0.284444 5.77573 0.0324445 6.70332 0.780444 6.70332H15.22C15.9684 6.70332 16.2849 5.77573 15.6853 5.34025L8.59822 0.193305ZM8 4.32444C7.76425 4.32444 7.53816 4.23332 7.37146 4.07112C7.20476 3.90893 7.11111 3.68894 7.11111 3.45955C7.11111 3.23017 7.20476 3.01019 7.37146 2.84799C7.53816 2.68579 7.76425 2.59467 8 2.59467C8.23575 2.59467 8.46184 2.68579 8.62854 2.84799C8.79524 3.01019 8.88889 3.23017 8.88889 3.45955C8.88889 3.68894 8.79524 3.90893 8.62854 4.07112C8.46184 4.23332 8.23575 4.32444 8 4.32444ZM0 14.8116C0 13.5571 1.04444 12.5409 2.33333 12.5409H13.6667C14.9556 12.5409 16 13.5571 16 14.8112V15.4599C16 15.7583 15.7511 16 15.4444 16H0.555556C0.248889 16.0004 0 15.7583 0 15.4599V14.8112V14.8116ZM3.55556 11.3851C3.55556 11.5457 3.42531 11.676 3.26465 11.676H2.06869C1.90802 11.676 1.77778 11.5457 1.77778 11.3851V7.85868C1.77778 7.69802 1.90802 7.56777 2.06869 7.56777H3.26465C3.42531 7.56777 3.55556 7.69802 3.55556 7.85868V11.3851ZM5.62424 7.56777C5.46358 7.56777 5.33333 7.69802 5.33333 7.85868V11.3851C5.33333 11.5457 5.46358 11.676 5.62424 11.676H6.8202C6.98087 11.676 7.11111 11.5457 7.11111 11.3851V7.85868C7.11111 7.69802 6.98087 7.56777 6.8202 7.56777H5.62424ZM9.1798 7.56777C9.01913 7.56777 8.88889 7.69802 8.88889 7.85868V11.3851C8.88889 11.5457 9.01913 11.676 9.1798 11.676H10.3758C10.5364 11.676 10.6667 11.5457 10.6667 11.3851V7.85868C10.6667 7.69802 10.5364 7.56777 10.3758 7.56777H9.1798ZM12.7354 7.56777C12.5747 7.56777 12.4444 7.69802 12.4444 7.85868V11.3851C12.4444 11.5457 12.5747 11.676 12.7354 11.676H13.9313C14.092 11.676 14.2222 11.5457 14.2222 11.3851V7.85868C14.2222 7.69802 14.092 7.56777 13.9313 7.56777H12.7354Z"
        fill={fill}
      />
    </svg>
  );
};

export default BankIcon;
