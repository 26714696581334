import React from 'react';

function ChapterEightIcon({ style, className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8438 4.5C11.7259 4.5 10.5 5.59885 10.5 7.375V9H21.5V7.375C21.5 5.59885 20.2741 4.5 19.1562 4.5H12.8438ZM7.5 6.84375C7.5 3.89248 9.89248 1.5 12.8438 1.5H19.1562C22.1075 1.5 24.5 3.89248 24.5 6.84375V11H7.5V6.84375Z"
        fill="#C6A788"
      />
      <path
        d="M17.7292 18.3574L17.6271 16.7393L17.5251 15.1212L16.1745 15.0201C15.4317 14.9645 14.7163 15.0282 14.5851 15.1621C14.4536 15.2956 14.3462 16.0693 14.3462 16.8814C14.3462 18.3346 14.3284 18.3574 13.2025 18.3574C11.3544 18.3574 11 18.6313 11 20.0606C11 21.4866 11.3554 21.7639 13.184 21.7639C14.2893 21.7639 14.3114 21.7928 14.4114 23.3819L14.5135 25H16.0193H17.5251L17.6264 23.4L17.7275 21.7996L19.2995 21.6967C20.8649 21.5939 20.8716 21.5881 20.978 20.2657C21.1025 18.7185 20.7341 18.37 18.9656 18.3625L17.7292 18.3574Z"
        fill="#C6A788"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 26.9259V13.0741C0 10.824 1.79086 9 4 9H8.58537H23.1545H28C30.2091 9 32 10.824 32 13.0741V26.9259C32 29.176 30.2091 31 28 31H4C1.79086 31 0 29.176 0 26.9259ZM16 28C20.4183 28 24 24.4183 24 20C24 15.5817 20.4183 12 16 12C11.5817 12 8 15.5817 8 20C8 24.4183 11.5817 28 16 28Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default ChapterEightIcon;
