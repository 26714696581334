import React from 'react';
import Modal from './Modal/Modal';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import FeeshModalIcon from '../icons/modal/FeeshModalIcon';
import CoinModalIcon from '../icons/modal/CoinModalIcon';

function FeedingModal() {
  const { appStore } = RootStore;

  if (!appStore.feedingCatData) {
    return null;
  }

  return (
    <Modal isOpen={!!appStore.feedingCatData} title="Ты хочешь покормить кошечку?">
      <div className="modal__body-price">
        <FeeshModalIcon />
        {!appStore.isTeamMode && (
          <>
            <p className="modal__body-price-text">ЦЕНА корма</p>
            <p className="modal__body-price-coin">
              <CoinModalIcon className="modal__body-price-coin-icon" />
              <span className="modal__body-price-coin-count notranslate"> 1 </span>
            </p>
          </>
        )}
      </div>
      <div className="modal__body-actions">
        <TextButton color="green" title="покормить" onClick={() => appStore.feedCat(appStore.feedingCatData)} />
        <TextButton color="purple" title="отмена" onClick={appStore.hideFeedingModal} />
      </div>
    </Modal>
  );
}

export default observer(FeedingModal);
