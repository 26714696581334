import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import BowlModalIcon from '../icons/modal/BowlModalIcon';

function CantFeedModal() {
  const { appStore } = RootStore;

  if (!appStore.hungryCatModal) {
    return null;
  }

  return (
    <Modal
      colorBg="red"
      isOpen={appStore.hungryCatModal}
      onClose={appStore.hideHungryCatModal}
      title="Вы не можете покормить сытого котика!"
    >
      <BowlModalIcon className="modal__body-icon" />
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.hideHungryCatModal} />
      </div>
    </Modal>
  );
}

export default observer(CantFeedModal);
