import React from 'react';
import Modal from './Modal/Modal';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import FactureModalIcon from '../icons/modal/FactureModalIcon';
import CoinModalIcon from '../icons/modal/CoinModalIcon';

function FractureModal() {
  const { appStore } = RootStore;

  if (!appStore.fractureData) {
    return null;
  }

  return (
    <Modal isOpen={!!appStore.fractureData} title="хочешь забинтовать котику лапку?" style={{ zIndex: 1000 }}>
      <div className="modal__body-price">
        <FactureModalIcon />
        {!appStore.isTeamMode && (
          <>
            <p className="modal__body-price-text">ЦЕНА бинта</p>
            <p className="modal__body-price-coin">
              <CoinModalIcon className="modal__body-price-coin-icon" />
              <span className="modal__body-price-coin-count notranslate">{appStore.fractureData?.insured ? 0 : 2}</span>
            </p>
          </>
        )}
      </div>
      <div className="modal__body-actions">
        <TextButton
          color="green"
          title="забинтовать"
          onClick={() => appStore.catTreatmentSend(appStore.fractureData)}
        />
        <TextButton color="purple" title="отмена" onClick={appStore.hideTreatmentActionModal} />
      </div>
    </Modal>
  );
}

export default observer(FractureModal);
