import React from 'react';
import LTDStepsApiParams from '../base/LTDStepsApiParams';
import LTDChapter from '../base/LTDChapter';
import LTDStep from '../base/LTDStep';
import _ from 'lodash';
import checkCatInfoForMakingLTD from '../base/methods/checkCatInfoForMakingLTD';
import { CAT_COLOR, CAT_GENDER, CUSTOM_ACTION_NAMES, LTD_CONTENT_TYPE, TIMER_ID } from '../../../../common/constants';
import generateSelectorForLTDMakingCats from '../base/methods/generateSelectorForLTDMakingCats';
import routes from '../../../../routes';
import { INFO_MODAL_POSITION } from '../../base/constants';
import RootStore from '../../../index';
import { LocalStorage } from '../../../../common/localStorage';
import api from '../../../../api';
import generateLTDChapterName from '../base/methods/generateLTDChapterName';

const steps = () => [
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Добро пожаловать</div>,
    message: (
      <p className="training-modal__desc">
        Заключение многоступенчатых долгосрочных сделок - это уникальный бизнес-навык, и мы хотим Вас этому обучить
      </p>
    ),
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Добро пожаловать</div>,
    message: (
      <p className="training-modal__desc">
        В этот навык входит: умение формировать предмет сделки, работать со сроками поставок и оплаты, назначать штрафы
        за недобросовестное исполнение обязательств контрагентом, пользоваться банковской гарантией и формировать
        условия расторжения сделки
      </p>
    ),
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: 'routerGoTo',
    checkParams: ([route, params]) => {
      return route === routes.inside && params.uuid === '00000000-0000-0000-0000-000000000021';
    },
    title: <div>Приступим</div>,
    message: (
      <p className="training-modal__desc">
        Посмотрим, что такое долгосрочная сделка и из каких блоков она состоит. Для этого перейдем в витрину контрагента
      </p>
    ),
    elements: ['.pet-shops-item-wrapper--1'],
    pulseElements: ['.pet-shops-item-wrapper--1'],
  }),
  new LTDStep({
    actionName: 'openMakingLTDModal',
    title: <div>новая кнопка</div>,
    message: (
      <p className="training-modal__desc">Для заключения долгосрочной сделки нажмем на соответствующую кнопку</p>
    ),
    position: INFO_MODAL_POSITION.BOTTOM,
    elements: ['.lot-area__players-slider .slick-list'],
    pulseElements: ['.lot-area__players-slider .slick-current .lot-area__players-slider-icon--ltd'],
    disabledElements: ['.lot-area__players-slider .slick-current .lot-area__players-slider-icon--call'],
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Виды долгосрочных сделок</div>,
    needOverlayClickModal: false,
    message: (
      <p className="training-modal__desc">
        Мы можем заключить долгосрочные сделки трех видов: только на покупку, только на продажу и на покупку с продажей.
      </p>
    ),
    disabledElements: [
      '.modal-ltd-making__body-action',
      ..._.range(0, 3, 1).map((index) => {
        return `.modal-ltd-making__body:nth-child(${index + 1})`;
      }),
    ],
    elements: ['.modal--ltd-making'],
    position: INFO_MODAL_POSITION.TOP,
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: 'routerGoTo',
    checkParams: ([route, params]) => {
      return (
        route === routes.longTermsDealsNew &&
        params.uuid === '00000000-0000-0000-0000-000000000021' &&
        params.type_ltd === LTD_CONTENT_TYPE.BUY_SELL
      );
    },
    title: <div>Виды долгосрочных сделок</div>,
    message: <p className="training-modal__desc">выберем третий вид сделки</p>,
    disabledElements: [
      '.modal-ltd-making__body-action',
      ..._.range(0, 2, 1).map((index) => `.modal-ltd-making__body:nth-child(${index + 1})`),
    ],
    shadedElements: ['.modal--ltd-making'],
    needOverlayClickModal: false,
    elements: ['.modal-ltd-making__body:nth-child(3)'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Долгосрочная сделка</div>,
    message: (
      <p className="training-modal__desc">
        Сейчас перед нами открылось окно долгосрочных сделок. Разберем основные аспекты путём заключения сделки
      </p>
    ),
    nextButton: true,
    buttonText: 'Дальше',
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionNames: ['changeLTDContentCatCount', 'changeLTDContentCatPrice'],
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams: ([contentType, catObject, newValue], actionName) => {
      return checkCatInfoForMakingLTD({
        catsNeededParams: {
          [CAT_COLOR.WHITE]: {
            [CAT_GENDER.MALE]: {
              count: 2,
              price: 10,
            },
            [CAT_GENDER.FEMALE]: {
              count: 2,
              price: 11,
            },
          },
        },
        contentType: LTD_CONTENT_TYPE.BUY,
      });
    },
    title: <div>Покупаем котиков</div>,
    message: (
      <p className="training-modal__desc">
        Предметом сделки будут являться 2 белых мальчика по цене в 10 монет и 2 белые девочки по цене 11
      </p>
    ),
    removeFocusFromElements: true,
    elements: [
      ...generateSelectorForLTDMakingCats({
        gendersByColors: { [CAT_COLOR.WHITE]: [CAT_GENDER.FEMALE, CAT_GENDER.MALE] },
        contentType: LTD_CONTENT_TYPE.BUY,
      }),
    ],
    position: INFO_MODAL_POSITION.BOTTOM,
    after() {
      RootStore.appStore.tutorialProgress.chapter++;
    },
  }),
];

const chapter1 = () =>
  new LTDChapter({
    title: 'Создание долгосрочной сделки',
    chapter: generateLTDChapterName(1),
    stepsApiParams: LTDStepsApiParams,
    seamlessStartOfNextChapter: true,
    defaultStepSettings: {
      hiddenTimersIds: [TIMER_ID.HEADER.SESSION_TIMER],
    },
    callback() {
      RootStore.appStore.hideIntroductionModal();
    },
    userIdentifierForSocketInitialization: LocalStorage.ORIGINAL_TRAINING_USER_UID,
    beforeStepsInitializationStep: new LTDStep({
      title: <div>Ожидание старта главы</div>,
      message: <p className="training-modal__desc">Ожидание...</p>,
    }),
    stepsGeneratorInfo: {
      generateOnChapterInitialization: false,
      generator: steps,
    },
  });

export default chapter1;
