import React from 'react';

function SettingIcon({ style }) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="#FFFBE5" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M26.7941 17.0684L24.2204 15.0559C24.2531 14.7455 24.2811 14.3827 24.2811 13.9989C24.2811 13.615 24.2542 13.2522 24.2204 12.9419L26.7964 10.9282C27.2748 10.5502 27.4054 9.87935 27.1009 9.32055L24.4246 4.69C24.1387 4.16735 23.5122 3.899 22.8764 4.1335L19.8396 5.35265C19.2574 4.93265 18.6472 4.578 18.0161 4.2945L17.5541 1.07335C17.4782 0.462 16.9462 0 16.3174 0H10.9508C10.3219 0 9.79113 0.462 9.71642 1.064L9.25327 4.29685C8.64192 4.5722 8.04113 4.9222 7.43327 5.355L4.38827 4.13235C3.81772 3.91185 3.13522 4.15915 2.85172 4.6795L0.171923 9.31585C-0.144226 9.8502 -0.0135772 10.5444 0.475274 10.9317L3.04892 12.9442C3.00807 13.3374 2.98827 13.6792 2.98827 14.0001C2.98827 14.3209 3.00813 14.6627 3.04892 15.0571L0.472923 17.0707C-0.00542872 17.4499 -0.134929 18.1207 0.169571 18.6784L2.84592 23.3089C3.13177 23.8304 3.75242 24.101 4.39407 23.8654L7.43092 22.6462C8.01192 23.0651 8.62207 23.4197 9.25327 23.7044L9.71527 26.9244C9.79107 27.538 10.3219 28 10.9519 28H16.3186C16.9474 28 17.4794 27.538 17.5541 26.936L18.0172 23.7044C18.6286 23.4279 19.2282 23.079 19.8372 22.645L22.8822 23.8676C23.0292 23.9248 23.1821 23.954 23.3396 23.954C23.7922 23.954 24.2087 23.7066 24.4187 23.3216L27.1067 18.6666C27.4054 18.1206 27.2747 17.4499 26.7941 17.0684ZM13.6341 18.6666C11.0604 18.6666 8.96742 16.5736 8.96742 14C8.96742 11.4264 11.0604 9.33335 13.6341 9.33335C16.2077 9.33335 18.3007 11.4264 18.3007 14C18.3007 16.5736 16.2077 18.6666 13.6341 18.6666Z"
        fill="inherit"
      />
    </svg>
  );
}

export default SettingIcon;
