import AppStore from './AppStore';
import { RouterStore } from 'mobx-router';
import { makeAutoObservable } from 'mobx';
import trainingStore from './trainingStore';

class RootStore {
  constructor() {
    this.router = new RouterStore(this);
    this.router.currentRoute = null;
    makeAutoObservable(this.router);
    this.appStore = new AppStore(this.router);
  }

  async goTo(route, params, queryParams) {
    if (trainingStore.checkIsCorrectAction('routerGoTo', [route, params, queryParams])) {
      await this.router.goTo(route, params, queryParams);
    }
  }
}

export default new RootStore();
