import React from 'react';

function MinusIcon() {
  return (
    <svg width="16" height="6" viewBox="0 0 16 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.578438 0.81745L1.523 0.639884C2.04251 0.542459 5.29559 0.481737 8.75242 0.504946C14.0099 0.54057 15.0799 0.613695 15.2988 0.952388C15.4426 1.17503 15.5292 2.20218 15.4909 3.23499L15.4216 5.11305L14.3421 5.34405C13.7483 5.47116 10.4953 5.53701 7.11292 5.49032C2.00824 5.41961 0.918748 5.33651 0.701499 5.00051C0.557386 4.77787 0.470752 3.74559 0.509074 2.70658L0.578438 0.81745Z"
        fill="#FDF6EE"
      />
    </svg>
  );
}

export default MinusIcon;
