import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarCatFaceImage from '../../../assets/nurseryAvatars/avatarCatFace.png';

function AvatarCatFace({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
    //     <circle cx="18" cy="18" r="18" fill="#02516C" />
    //   </mask>
    //   <g mask="url(#mask0)">
    //     <path
    //       fillRule="evenodd"
    //       clipRule="evenodd"
    //       d="M8.93721 10.157C8.8631 10.3722 8.73728 12.8273 8.65814 15.6123C8.57901 18.3973 8.47975 21.2445 8.43723 21.9391C8.36258 23.1661 8.39948 23.2289 9.71627 24.1167C11.5409 25.3472 13.4502 25.9005 16.4982 26.0823C19.7045 26.2733 22.3999 25.8245 24.6565 24.7243C26.6883 23.7335 27.3947 22.8682 27.1008 21.7303C26.9912 21.3052 26.8192 18.4887 26.7188 15.4716L26.5365 9.98598L25.3173 9.89794C24.5721 9.84423 23.1043 10.1064 21.5422 10.5722C17.8978 11.6596 17.5348 11.6742 14.9065 10.8387C11.997 9.91428 9.13379 9.58709 8.93721 10.157ZM23.9317 14.597C25.0446 15.8562 25.1554 15.9169 25.3598 15.3788C25.4817 15.0578 25.5446 14.0563 25.4996 13.1533L25.4179 11.511L22.7251 13.2318L23.9317 14.597ZM12.7019 13.2647L11.9293 12.7201C11.5042 12.4208 10.8734 12.0266 10.5275 11.8446C9.90614 11.5175 9.89858 11.5352 9.91789 13.2816C9.94864 16.048 10.1181 16.1898 11.5098 14.6142L12.7019 13.2647ZM14.0369 17.7784C14.5335 18.278 14.4451 18.9751 13.812 19.5518C13.0011 20.29 11.8557 19.7439 11.8557 18.619C11.8557 18.1773 11.9396 17.7314 12.0423 17.6284C12.3512 17.3176 13.6781 17.4175 14.0369 17.7784ZM23.1996 19.7338C23.7871 19.4646 23.8995 19.2429 23.8277 18.4938C23.7525 17.7095 23.6224 17.5699 22.8998 17.4985C21.4351 17.3539 20.8532 18.5773 21.9237 19.5518C22.3918 19.978 22.6018 20.0078 23.1996 19.7338ZM19.5465 20.0793C20.0482 20.4484 20.0507 20.5027 19.5965 21.2C18.7473 22.5039 16.7779 22.4657 16.0681 21.1314C15.8411 20.7046 15.8743 20.4711 16.2105 20.1327C16.7586 19.5816 18.825 19.5484 19.5465 20.0793Z"
    //       fill="#02516C"
    //     />
    //   </g>
    // </svg>
    <Avatar style={style} avatarImage={AvatarCatFaceImage} />
  );
}

export default AvatarCatFace;
