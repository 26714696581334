import React from 'react';
import Modal from '../Modal/Modal';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import TextButton from '../../buttons/TextButton/TextButton';
import './delegatingModal.scss';
import WarningModalIcon from '../../icons/modal/WarningModalIcon';
import { ArrowLeftIcon } from '../../icons';
import TeamAvatar from '../TeamAvatar/TeamAvatar';
import { Checkbox } from '../Checkbox';
import { Switcher } from '../Switcher';
import CaptainIcon from '../../icons/roles/CaptainIcon';
import { DELEGATING_OPTIONS } from '../../../store/CorpStore.js';
import TextSwitcher from '../TextSwitcher/TextSwitcher';
import { PLAYER_ROLE, SPECIALITIES, SPECIALITIES_RUSSIAN_NAME } from '../../../common/constants';

function TeamDelegatingInfoComponent() {
  const { appStore } = RootStore;
  let captainItems = [
    {
      title: 'Делегирование действий',
    },
    {
      title: 'Утверждение неделегированных действий',
    },
    {
      visibled: !appStore.isCorpMode,
      title: 'Досрочное завершение хода',
    },
    {
      title: 'Распределение функций выбывших игроков',
    },
  ];

  let marketingItems = [
    {
      title: 'Изменение витринных цен',
    },
    {
      title: 'взятие кредита',
    },
    {
      title: 'досрочное погашение кредита',
    },
    {
      icon: <CaptainIcon />,
      title: 'Визирование цен в сделках (дополнительно)',
    },
    {
      visibled: appStore.me.role === PLAYER_ROLE.SHOP,
      title: 'визирование продаж котят городу ',
    },
  ];

  let sellerItems = [
    {
      visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      icon: <CaptainIcon />,
      title: 'продажа котят зоомагазину',
    },
    {
      visibled: appStore.me.role === PLAYER_ROLE.SHOP,
      icon: <CaptainIcon />,
      title: 'продажа котят питомнику',
    },
    {
      visibled: appStore.me.role === PLAYER_ROLE.SHOP,
      title: 'продажа котят городу',
    },
    {
      title: 'Отмена собственной сделки',
    },
  ];

  let purchaserItems = [
    {
      visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      icon: <CaptainIcon />,
      title: 'покупка котят у зоомагазина',
    },
    {
      visibled: appStore.me.role === PLAYER_ROLE.SHOP,
      icon: <CaptainIcon />,
      title: 'покупка котят у питомника',
    },
    {
      visibled: appStore.me.role === PLAYER_ROLE.SHOP,
      title: 'покупка котят у города',
    },
    {
      title: 'Отмена собственной сделки',
    },
  ];

  let doctorItems = [
    {
      visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      title: 'Покупка домиков',
    },
    {
      visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      title: 'Страхование домиков',
    },
    {
      visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      title: 'Покупка корма',
    },
    {
      visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      title: 'Покупка лекарств',
    },
    {
      visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      title: 'Скрещивание котиков',
    },
    {
      visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      title: 'Расселение котиков',
    },
    {
      visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      title: 'Кормление котиков',
    },
    {
      visibled: appStore.me.role === PLAYER_ROLE.NURSERY,
      title: 'Лечение котиков',
    },
    {
      title: 'Осмотр котиков',
    },
  ];

  const DescsRender = (props) =>
    props.descs
      .filter((item) => item.visibled === undefined || !!item.visibled)
      .map((item, ind) => {
        return (
          <div key={ind} className="delegating_actions__item delegating_actions__item--info">
            {item.icon ? item.icon : <span style={{ marginRight: 8, marginLeft: 8 }}>&#183;</span>}
            <span className="delegating_actions__item-title">{item.title}</span>
          </div>
        );
      });

  const RoleDesc = (props) => {
    const Logo = appStore.me.logo;
    const name = SPECIALITIES_RUSSIAN_NAME[props.speciality];
    return (
      <div className="delegating_actions">
        <div className="delegating_actions_title">
          <div className="delegating_actions_title_name">
            <TeamAvatar Logo={Logo} speciality={props.speciality} />
            {name}
          </div>
        </div>
        <DescsRender descs={props.descs} />
      </div>
    );
  };
  return (
    <div>
      <div>
        <div className="delegating_actions__item delegating_actions__item--no-uppercase delegating_actions__item--with-icon">
          <CaptainIcon />
          <span className="delegating_actions__item-title">Сделка осуществляется только с согласованием капитана</span>
        </div>
        <div className="delegating_actions__item delegating_actions__item--no-uppercase">
          <Checkbox value={false} />
          <span className="delegating_actions__item-title">Чекбокс не активирован. Игроку не добавлена роль</span>
        </div>
        <div className="delegating_actions__item delegating_actions__item--no-uppercase">
          <Checkbox value={true} />
          <span className="delegating_actions__item-title">Чекбокс активирован. Игроку добавлены полномочия</span>
        </div>
        <div className="delegating_actions__item delegating_actions__item--no-uppercase">
          <Switcher value={false} />
          <span className="delegating_actions__item-title">
            Переключатель выключен. Игрок выполняет функцию только с согласованием капитана
          </span>
        </div>
        <div className="delegating_actions__item delegating_actions__item--no-uppercase">
          <Switcher value={true} />
          <span className="delegating_actions__item-title">
            Переключатель включен. Игроку делегировали выполнение функции без согласования капитана
          </span>
        </div>
      </div>
      <RoleDesc speciality={SPECIALITIES.CAPTAIN} descs={captainItems} />
      <RoleDesc speciality={SPECIALITIES.MARKETEER} descs={marketingItems} />
      <RoleDesc speciality={SPECIALITIES.SALES} descs={sellerItems} />
      <RoleDesc speciality={SPECIALITIES.SUPPLY} descs={purchaserItems} />
      <RoleDesc speciality={SPECIALITIES.DOCTOR} descs={doctorItems} />
    </div>
  );
}

function CorpDelegatingInfoComponent() {
  return (
    <div className="delegating_actions__items-wrapper">
      <div className="delegating_actions__item delegating_actions__item--no-uppercase">
        <Checkbox value={true} />
        <span className="delegating_actions__item-title">Чекбокс активирован. Лимит на расходы установлен</span>
      </div>

      <div className="delegating_actions__item-dividing-line" />

      <div className="delegating_actions__item delegating_actions__item--no-uppercase">
        <Checkbox value={false} />
        <span className="delegating_actions__item-title">Чекбокс не активирован. Лимит на расходы не установлен</span>
      </div>

      <div className="delegating_actions__item-dividing-line" />

      <div className="delegating_actions__item delegating_actions__item--no-uppercase">
        <TextSwitcher
          options={DELEGATING_OPTIONS.CEO_CAPTAIN_TEAM}
          value={DELEGATING_OPTIONS.CEO_CAPTAIN_TEAM.find((option) => option.value === 'ceo').value}
          readOnly={true}
        />
        <span className="delegating_actions__item-title delegating_actions__item-title--corp">
          СЕО согласует действия команд самостоятельно
        </span>
      </div>

      <div className="delegating_actions__item-dividing-line" />

      <div className="delegating_actions__item delegating_actions__item--no-uppercase">
        <TextSwitcher
          options={DELEGATING_OPTIONS.CEO_CAPTAIN_TEAM}
          value={DELEGATING_OPTIONS.CEO_CAPTAIN_TEAM.find((option) => option.value === 'captain').value}
          readOnly={true}
        />
        <span className="delegating_actions__item-title delegating_actions__item-title--corp">
          Капитан согласует действия своей команды, даже если делегирует согласование игрокам ниже
        </span>
      </div>

      <div className="delegating_actions__item-dividing-line" />

      <div className="delegating_actions__item delegating_actions__item--no-uppercase">
        <TextSwitcher
          options={DELEGATING_OPTIONS.CEO_CAPTAIN_TEAM}
          value={DELEGATING_OPTIONS.CEO_CAPTAIN_TEAM.find((option) => option.value === 'team').value}
          readOnly={true}
        />
        <span className="delegating_actions__item-title delegating_actions__item-title--corp">
          Капитан может делегировать согласование членам своей команды
        </span>
      </div>

      <div className="delegating_actions__item-dividing-line" />

      <div className="delegating_actions__item delegating_actions__item--no-uppercase">
        <TextSwitcher options={DELEGATING_OPTIONS.CEO_CAPTAIN_TEAM} value={null} readOnly={true} />
        <span className="delegating_actions__item-title delegating_actions__item-title--corp">
          Когда делегирование в нескольких городах настроено по разному, переключатель тумблера пропадает, нажмите на
          одно из положений, чтобы настроить делегирование на все города сразу
        </span>
      </div>

      <div className="delegating_actions__item-dividing-line" />

      <div className="delegating_actions__item delegating_actions__item--no-uppercase">
        <Checkbox value={false} />
        <TextSwitcher
          options={DELEGATING_OPTIONS.CEO_CAPTAIN_TEAM}
          value={DELEGATING_OPTIONS.CEO_CAPTAIN_TEAM.find((option) => option.value === 'ceo').value}
          readOnly={true}
        />
        <span className="delegating_actions__item-title delegating_actions__item-title--corp">
          Чекбокс не активирован. Игроку не добавлена роль
        </span>
      </div>

      <div className="delegating_actions__item-dividing-line" />

      <div className="delegating_actions__item delegating_actions__item--no-uppercase">
        <Checkbox value={true} />
        <TextSwitcher
          options={DELEGATING_OPTIONS.CEO_CAPTAIN_TEAM}
          value={DELEGATING_OPTIONS.CEO_CAPTAIN_TEAM.find((option) => option.value === 'ceo').value}
          readOnly={true}
        />
        <span className="delegating_actions__item-title delegating_actions__item-title--corp">
          Чекбокс активирован. Игроку добавлена роль с согласованием
        </span>
      </div>
    </div>
  );
}

function DelegatingInfoModal() {
  const { appStore } = RootStore;
  if (!appStore.me?.role || !appStore.delegatingInfoModal) {
    return null;
  }

  let Content;
  let title;

  if (appStore.isCorpMode && appStore.meIsCEO) {
    Content = CorpDelegatingInfoComponent;
    title = 'Инструкция';
  } else {
    Content = TeamDelegatingInfoComponent;
    title = 'Описание ролей';
  }

  return (
    <Modal
      isOpen={appStore.delegatingInfoModal}
      onClose={appStore.hideDelegatingInfoModal}
      title={title}
      titleIcon={<WarningModalIcon className="modal-title-icon" />}
      size="big"
      head={true}
      headLeft={
        <div className="modal__head_left modal__head_left--arrow" onClick={appStore.hideDelegatingInfoModal}>
          <ArrowLeftIcon />
        </div>
      }
    >
      <Content />
      <div className="modal__body-actions">
        <TextButton color="blue" title="назад" onClick={appStore.hideDelegatingInfoModal} />
      </div>
    </Modal>
  );
}

export default observer(DelegatingInfoModal);
