import React from 'react';

function GameOverIcon({ style, stylePath, className }) {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        d="M34.5625 0C32.6239 0 31.0469 1.57703 31.0469 3.51562C31.0469 4.30195 31.3155 5.02125 31.7534 5.6075C29.1711 7.92377 27.8853 9.27574 26.0197 11.8291C25.7516 11.7634 25.4756 11.7188 25.1875 11.7188C24.4678 11.7188 23.7992 11.9377 23.2414 12.3102C21.8992 10.7223 20.9621 10.1026 19.1527 9.24453C19.2565 8.91336 19.3281 8.56805 19.3281 8.20312C19.3281 6.26453 17.7511 4.6875 15.8125 4.6875C13.8739 4.6875 12.2969 6.26453 12.2969 8.20312C12.2969 8.56805 12.3685 8.91336 12.4723 9.24453C10.7039 10.3685 9.79129 11.0298 8.38359 12.3102C7.82578 11.9377 7.15719 11.7188 6.4375 11.7188C4.49891 11.7188 2.92188 13.2958 2.92188 15.2344C2.92188 17.173 4.49891 18.75 6.4375 18.75C8.37609 18.75 9.95312 17.173 9.95312 15.2344C9.95312 14.8695 9.88148 14.5241 9.77773 14.193C11.6059 12.9717 12.5459 12.3762 13.8664 11.1273C14.4241 11.4998 15.0928 11.7188 15.8125 11.7188C16.5322 11.7188 17.2008 11.4998 17.7586 11.1273C19.0589 12.5219 19.9824 13.1836 21.8473 14.193C21.7435 14.5241 21.6719 14.8695 21.6719 15.2344C21.6719 17.173 23.2489 18.75 25.1875 18.75C27.1261 18.75 28.7031 17.173 28.7031 15.2344C28.7031 14.448 28.4345 13.7288 27.9966 13.1425C30.4659 11.0401 31.7004 9.64346 33.7303 6.92094C33.9984 6.98664 34.2744 7.03125 34.5625 7.03125C36.5011 7.03125 38.0781 5.45422 38.0781 3.51562C38.0781 1.57703 36.5011 0 34.5625 0Z"
        fill="#C6A788"
      />
      <path
        d="M18.1561 20H13.4686C12.8209 20 12.2968 20.5241 12.2968 21.1719C11.982 27.4425 12.016 30.8016 12.2968 36.5625H19.328C19.5882 30.7536 19.5275 27.3641 19.328 21.1719C19.328 20.5241 18.8039 20 18.1561 20Z"
        fill="#C6A788"
      />
      <path
        d="M27.5316 24.6875H22.8441C22.1964 24.6875 21.6722 25.2116 21.6722 25.8594C21.4855 30.2547 21.5149 32.5567 21.6722 36.5625H28.7035C28.8013 32.6289 28.8033 30.3001 28.7035 25.8594C28.7035 25.2116 28.1793 24.6875 27.5316 24.6875Z"
        fill="#C6A788"
      />
      <path
        d="M36.9068 15.3125H32.2193C31.5715 15.3125 31.0474 15.8366 31.0474 16.4844C30.7665 24.3719 30.8659 28.7819 31.0474 36.5625H38.0786C38.2252 28.8455 38.2469 24.4664 38.0786 16.4844C38.0786 15.8366 37.5545 15.3125 36.9068 15.3125Z"
        fill="#C6A788"
      />
      <path
        d="M8.78114 27.0312H4.09364C3.4459 27.0312 2.92176 27.5554 2.92176 28.2031C2.69967 31.4067 2.74992 33.2509 2.92176 36.5625H9.95301C10.05 33.349 10.0526 31.5218 9.95301 28.2031C9.95301 27.5554 9.42887 27.0312 8.78114 27.0312Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default GameOverIcon;
