import {
  CatFaceIcon,
  TeamBuyerIcon,
  TeamCaptainIcon,
  TeamDoctorIcon,
  TeamMarketIcon,
  TeamSellerIcon,
} from '../../icons';
import React, { useState } from 'react';
import * as teamMode from '../../../store/teamMode';
import { getCatTypeText } from '../../../common/texts/catType';
import RootStore from '../../../store';
import Mail from '../Mail/Mail';
import TeamHistoryItem from './TeamHistoryItem';
import { CREDIT_TYPE_NAME, TASK_TYPES } from '../../../store/constants';
import CEOIcon from '../../icons/CEOIcon';
import corpStore from '../../../store/CorpStore';
import BankIcon from '../../icons/BankIcon';
import { catCount } from '../../../common/texts/catCount';
import WalletIcon from '../../icons/modal/WalletIcon';
import { FAKE_LTD_TEAM_TASK_STATUS, FAKE_LTD_TEAM_TASK_STATUS_NAME, TEAM_TASK_STATUS } from '../../../store/constants';
import {
  LTD_ACTIONS,
  LTD_ACTIONS_NAME,
  LTD_CONTENT_NAME_ACCUSATIVE_CASE,
  LTD_FINES_TYPE_NAME,
  SPECIALITIES,
  SPECIALITIES_RUSSIAN_NAME,
} from '../../../common/constants';

function TeamHistoryItemsGenerator({
  isCollapseExist,
  uniqueTeamHistory,
  isPlayingAlone,
  priceVision = true,
  catCountVision,
  areaNum = null,
  time_wait,
}) {
  const [isCollapseOpen, setCollapseOpen] = useState(false);
  const getHistoryPrice = (teamHistoryItem) => {
    if (!priceVision) {
      return 0;
    }
    if (!teamHistoryItem.task_update) {
      return 0;
    }
    const task = teamHistoryItem.task_update;
    if (task.type === TASK_TYPES.CHANGE_SHOWCASE_PRICES) {
      return 0;
    }
    if (task.type === TASK_TYPES.GET_CREDIT) {
      return task.content.money_quantity;
    }
    if (task.type === TASK_TYPES.CREDIT_REPAY) {
      // Так как в данном месте может как кредиты СЕО, так и команды, то мы
      // должны получить абсолютно все кредиты, которые принадлежат команде
      // Наши кредиты мы получаем через appStore.credits.credits_info,
      // Если мы являемся СЕО, то кредиты наших городов мы получаем с помощью corpStore.getInfoFromAllAreasByProperty
      // и добавляем их в список кредитов, по которому ищем информацию о кредите
      const credits = [...appStore.credits.credits_info];
      if (appStore.meIsCEO) {
        corpStore.getInfoFromAllAreasByProperty('credits').forEach((credit) => credits.push(...credit.credits_info));
      }
      const credit = credits.find((credit) => credit.credit_id === task.content.credit_id);
      return credit.money_quantity;
    }

    if (task.type === TASK_TYPES.FAKE_BUDGET_ALLOCATION_TASK) {
      return Math.abs(task.delta);
    }

    if (task.type === TASK_TYPES.FAKE_LTD_ACTION_TASK) {
      return corpStore.LTDHistoryPrice(task);
    }

    const contents = task.content?.contents;
    if (!contents) {
      return 0;
    }
    return contents.reduce((acc, cat) => {
      acc = acc + cat.count * cat.price;
      return acc;
    }, 0);
  };

  const getTaskTypeTitle = (task) => {
    if (task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST) {
      return task.content.request_type === 'buy' ? 'покупка' : 'продажа';
    } else if (task.type === TASK_TYPES.CITY_TRADE) {
      return task.content.request_type === 'buy' ? 'покупка у города' : 'продажа городу';
    } else if (task.type === TASK_TYPES.MATING) {
      return 'скрещивание';
    } else if (task.type === TASK_TYPES.BUY_DRUGS) {
      return 'покупка лекарств';
    } else if (task.type === TASK_TYPES.BUY_FOOD) {
      return 'покупка корма';
    } else if (task.type === TASK_TYPES.BUY_HOUSE) {
      return 'покупка домика';
    } else if (task.type === TASK_TYPES.BUY_HOUSE_INSURANCE) {
      return 'страховка домика';
    } else if (task.type === TASK_TYPES.CHANGE_SHOWCASE_PRICES) {
      return 'витринные цены';
    } else if (task.type === TASK_TYPES.CANCEL_OWN_TRADE_REQUEST) {
      return 'отмена собственной сделки';
    } else if (task.type === TASK_TYPES.GET_CREDIT) {
      return 'взятие кредита';
    } else if (task.type === TASK_TYPES.CREDIT_REPAY) {
      return 'досрочное погашение кредита';
    } else if (task.type === TASK_TYPES.FAKE_IMPORT_TASK) {
      return 'импорт котиков';
    } else if (task.type === TASK_TYPES.FAKE_EXPORT_TASK) {
      return 'экспорт котиков';
    } else if (task.type === TASK_TYPES.FAKE_BUDGET_ALLOCATION_TASK) {
      const allocationType = task.delta > 0 ? 'выделение' : 'изъятие';
      return `${allocationType} бюджета`;
    } else if (task.type === TASK_TYPES.FAKE_LTD_ACTION_TASK) {
      let partnerUuid;
      if (appStore.isCorpMode) {
        let areaNum;
        if (appStore.meIsCEO) {
          partnerUuid = task.partner === appStore.me.uuid ? task.originalLTDUpdate.initiator : task.partner;
          areaNum = task.areaNum;
        } else {
          partnerUuid = corpStore.captainUuidByCEOUuid(task.partner);
          areaNum = appStore.areaNum;
        }
        const partner = appStore.playerByUuid(partnerUuid);
        return `Долгосрочная сделка с СЕО ${partner.name} город ${areaNum}`;
      } else {
        partnerUuid = task.partner === appStore.me.uuid ? task.originalLTDUpdate.initiator : task.partner;
        const partner = appStore.playerByUuid(partnerUuid);
        return `Долгосрочная сделка с капитаном ${partner.name}`;
      }
    } else if (task.type === TASK_TYPES.FAKE_TRANSPORTATION_TASK) {
      return 'транспортировка котиков';
    } else if (task.type === TASK_TYPES.CREATE_CHANGE_LTD_REQUEST) {
      return 'запрос долгосрочной сделки';
    } else if (task.type === TASK_TYPES.FEED_ALL_CATS) {
      return 'покупка корма';
    } else {
      return 'необработанный тип';
    }
  };

  const getHistoryTitle = ({ teamHistoryItem, withMail }) => {
    const task = teamHistoryItem.task_update;
    let titleIcon = <CatFaceIcon />; // дефолтный пока что вариант для необработанных кейсов
    let titleText = 'необработанный тип';

    const mainSpecialityIcon = appStore.isCorpMode ? <CEOIcon /> : <TeamCaptainIcon />;

    let serverTime = task ? task.server_time : teamHistoryItem.server_time;
    if (!serverTime) {
      serverTime = Math.round(new Date().getTime() / 1000).toString();
    }
    const titleTimeSecondsFormat =
      appStore.allSeasonsDurationStart[teamHistoryItem.season] +
      appStore.allSeasonsDurationSec[teamHistoryItem.season] -
      serverTime +
      time_wait;
    const timeFormat = () => {
      const minutes = Math.floor(titleTimeSecondsFormat / 60);
      const seconds = titleTimeSecondsFormat % 60;
      const formatMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formatSeconds = seconds < 10 ? `0${seconds}` : seconds;
      return `${formatMinutes}:${formatSeconds}`;
    };
    const titleTime = timeFormat();
    if (task) {
      titleText = getTaskTypeTitle(task);
      if (
        task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST ||
        task.type === TASK_TYPES.CITY_TRADE ||
        task.type === TASK_TYPES.FAKE_LTD_ACTION_TASK
      ) {
        if (!withMail) {
          if (task.type === TASK_TYPES.FAKE_LTD_ACTION_TASK) {
            if (task.status === FAKE_LTD_TEAM_TASK_STATUS.SENT) {
              titleText = 'сделка отправлена';
              titleIcon = <CatFaceIcon />;
            } else if (task.status === FAKE_LTD_TEAM_TASK_STATUS.RECEIVED) {
              titleText = 'вам отправили сделку';
              titleIcon = <CatFaceIcon />;
            } else if (
              task.status === FAKE_LTD_TEAM_TASK_STATUS.AGREED ||
              (task.status === FAKE_LTD_TEAM_TASK_STATUS.CANCELLED && !task.ltd_actions.length)
            ) {
              titleText = 'согласование сделки';
              titleIcon = mainSpecialityIcon;
            } else if (
              [FAKE_LTD_TEAM_TASK_STATUS.COUNTER_SENT, FAKE_LTD_TEAM_TASK_STATUS.COUNTER_RECEIVED].includes(task.status)
            ) {
              titleText = 'встречные условия сделки';
              titleIcon = <CatFaceIcon />;
            } else if (task.status === FAKE_LTD_TEAM_TASK_STATUS.PRETENSION) {
              let actionType;
              task.ltd_actions.some((action) => {
                if (action.sent_liabilities) {
                  actionType = action.sent_liabilities.find((item) => !item.is_closed && !item.is_fine)
                    ?.liability_type[0];
                  return actionType;
                }
              });
              titleText = `претензия за ${LTD_FINES_TYPE_NAME[actionType]}`;
              titleIcon = mainSpecialityIcon;
            } else if (task.status === FAKE_LTD_TEAM_TASK_STATUS.FINE) {
              let actionType;
              task.ltd_actions.some((action) => {
                if (action.sent_liabilities) {
                  actionType = action.sent_liabilities.find((item) => item.is_closed && item.is_fine)
                    ?.liability_type[0];
                  return actionType;
                }
              });
              titleText = `штраф за ${LTD_FINES_TYPE_NAME[actionType]}`;
              titleIcon = mainSpecialityIcon;
            } else if (task.status === FAKE_LTD_TEAM_TASK_STATUS.TWO_WAY_TERMINATION) {
              titleText = 'расторжение по соглашению сторон';
              titleIcon = mainSpecialityIcon;
            } else if (task.status === FAKE_LTD_TEAM_TASK_STATUS.ACTION_COMPLETED) {
              titleText = LTD_ACTIONS_NAME[task.lastCompletedLTDActionType];
              titleIcon = mainSpecialityIcon;
            } else if (task.status === FAKE_LTD_TEAM_TASK_STATUS.EXAMINED_BY_DOCTOR) {
              titleText = 'доктор';
              titleIcon = <TeamDoctorIcon />;
            } else if (task.status === FAKE_LTD_TEAM_TASK_STATUS.TERMINATED) {
              titleText = 'одностороннее расторжение';
              titleIcon = mainSpecialityIcon;
            }
          } else {
            if (task.specialty_by === SPECIALITIES.CAPTAIN) {
              titleIcon = <TeamCaptainIcon />;
            } else if (task.specialty_by === SPECIALITIES.SUPPLY) {
              titleIcon = <TeamBuyerIcon />;
            } else if (task.specialty_by === SPECIALITIES.SALES) {
              titleIcon = <TeamSellerIcon />;
            } else if (task.specialty_by === SPECIALITIES.MARKETEER) {
              titleIcon = <TeamMarketIcon />;
            } else if (task.specialty_by === SPECIALITIES.DOCTOR) {
              titleIcon = <TeamDoctorIcon />;
            }
            titleText = SPECIALITIES_RUSSIAN_NAME[task.specialty_by];
          }
        } else {
          titleIcon = null;
        }
      } else if (task.type === TASK_TYPES.CHANGE_SHOWCASE_PRICES) {
        titleIcon = <CatFaceIcon />;
      } else if (
        task.type === TASK_TYPES.MATING ||
        task.type === TASK_TYPES.BUY_DRUGS ||
        task.type === TASK_TYPES.BUY_FOOD ||
        task.type === TASK_TYPES.FEED_ALL_CATS ||
        task.type === TASK_TYPES.BUY_HOUSE ||
        task.type === TASK_TYPES.BUY_HOUSE_INSURANCE
      ) {
        titleIcon = <TeamDoctorIcon />;
      } else if (task.type === TASK_TYPES.CANCEL_OWN_TRADE_REQUEST) {
        titleIcon = task.visible_to.find((spec) => spec === SPECIALITIES.SUPPLY) ? (
          <TeamBuyerIcon />
        ) : (
          <TeamSellerIcon />
        );
      } else if ([TASK_TYPES.GET_CREDIT, TASK_TYPES.CREDIT_REPAY].includes(task.type)) {
        titleIcon = <BankIcon />;
      } else if (
        [TASK_TYPES.FAKE_EXPORT_TASK, TASK_TYPES.FAKE_IMPORT_TASK, TASK_TYPES.FAKE_TRANSPORTATION_TASK].includes(
          task.type,
        )
      ) {
        titleIcon = <CEOIcon />;
      } else if (task.type === TASK_TYPES.FAKE_BUDGET_ALLOCATION_TASK) {
        titleIcon = <WalletIcon color="#B3977B" />;
      }
    } else {
      titleIcon = null;
      if (teamHistoryItem.msg_type === 'player_timed_out') {
        const specialty = appStore.companyPlayerById(teamHistoryItem.player_id).specialty;
        titleText = SPECIALITIES_RUSSIAN_NAME[specialty];
      } else if (teamHistoryItem.msg_type === 'privileges_changed') {
        const specialty = appStore.companyPlayerById(
          teamHistoryItem.set_privileges.length > 0
            ? teamHistoryItem.set_privileges[0][0]
            : teamHistoryItem.remove_privileges[0][0],
        ).specialty;
        titleText = SPECIALITIES_RUSSIAN_NAME[specialty];
      } else if (teamHistoryItem.msg_type === 'specialties_changed') {
        const specialty = appStore.companyPlayerById(
          teamHistoryItem.set_specialties.length > 0
            ? teamHistoryItem.set_specialties[0][0]
            : teamHistoryItem.remove_specialties[0][0],
        ).specialty;
        titleText = SPECIALITIES_RUSSIAN_NAME[specialty];
      } else if (teamHistoryItem.section_type === 'running_cats') {
        titleText = 'капитан';
      }
    }

    return (
      <>
        <div className="team-history__title-icon">
          {withMail ? <Mail size={'mini'} task={task} empty={!task} /> : titleIcon}
        </div>
        <div className="team-history__title-info">
          <div className="team-history__title-info--text">{titleText}</div>
          <div className="team-history__title-info--time">{titleTime}</div>
        </div>
      </>
    );
  };

  const getHistoryDesc = ({ teamHistoryItem, prevTeamHistoryItem, withMail }) => {
    const task = teamHistoryItem.task_update;
    const prevTask = prevTeamHistoryItem?.task_update;
    const isItTraderSpecialty = (spec) => {
      return spec === SPECIALITIES.SALES || spec === SPECIALITIES.SUPPLY;
    };
    let desc = 'необработанный тип';
    let sighting = '';
    if (task) {
      if (appStore.isCorpMode && [TASK_TYPES.FAKE_EXPORT_TASK, TASK_TYPES.FAKE_IMPORT_TASK].includes(task.type)) {
        sighting = 'согласовано';
      } else if (task.specialty_by === null) {
        sighting = 'получена';
      } else {
        const getVisa = () => {
          if (isItTraderSpecialty(task.specialty_by)) {
            if (task.visas[SPECIALITIES.SALES] === undefined) {
              return task.visas[SPECIALITIES.SUPPLY];
            } else {
              return task.visas[SPECIALITIES.SALES];
            }
          } else {
            return task.visas[task.specialty_by];
          }
        };
        const visa = getVisa();
        if (visa === undefined) {
          if (
            (task.specialty_by === task.stage ||
              (isItTraderSpecialty(task.specialty_by) && isItTraderSpecialty(task.stage))) &&
            task.visible_to.find((spec) => spec === SPECIALITIES.DOCTOR)
          ) {
            sighting = 'отправлено на осмотр';
          } else {
            sighting = 'доработка';
          }
        } else if (task.type === TASK_TYPES.FAKE_LTD_ACTION_TASK) {
          sighting = FAKE_LTD_TEAM_TASK_STATUS_NAME[task.status];
        } else {
          if (task.status === TEAM_TASK_STATUS.CANCELLED) {
            sighting =
              task.specialty_by === SPECIALITIES.CAPTAIN || task.specialty_by === SPECIALITIES.CEO
                ? 'не согласовано'
                : 'отменена';
          } else if (task.status === TEAM_TASK_STATUS.CONFIRMED) {
            sighting = 'согласовано';
          } else if (task.specialty_by === SPECIALITIES.SUPPLY || task.specialty_by === SPECIALITIES.SALES) {
            sighting = 'отправлено на согласование';
          } else if (task.specialty_by === SPECIALITIES.DOCTOR) {
            if (task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST) {
              sighting = 'осмотрено';
            } else {
              sighting = 'отправлено на согласование';
            }
          } else {
            if (appStore.isCorpMode && task.stage === SPECIALITIES.CEO) {
              sighting = 'отправлено на согласование';
            } else if (
              (!withMail && task.specialty_by !== SPECIALITIES.MARKETEER) ||
              task.status === TEAM_TASK_STATUS.CONFIRMED ||
              task.status === TEAM_TASK_STATUS.CANCELLED
            ) {
              sighting = visa ? 'согласовано' : 'не согласовано';
            } else {
              sighting = 'отправлено на согласование';
            }
          }
        }
      }

      if (task.type === TASK_TYPES.CHANGE_SHOWCASE_PRICES) {
        const contents = task.content.contents[0];
        const defaultShowcasePrices = task.content.showcase_prices[`${contents.color}_${contents.gender}`];
        const isBuyChange = contents.buy_price !== defaultShowcasePrices.buy_price;
        const isSellChange = contents.sell_price !== defaultShowcasePrices.sell_price;
        const kindOfChange = () => {
          if (isBuyChange && isSellChange) {
            return 'покупка и продажа';
          } else {
            return isBuyChange ? 'покупка' : 'продажа';
          }
        };
        const catType = getCatTypeText({ gender: contents.gender, color: contents.color }, ' ');
        desc = `${catType}, ${kindOfChange()}`;
      } else if (
        task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST ||
        task.type === TASK_TYPES.CITY_TRADE ||
        task.type === TASK_TYPES.CANCEL_OWN_TRADE_REQUEST
      ) {
        const quantity = task.content.contents.reduce((acc, cat) => {
          return acc + cat.count;
        }, 0);
        desc = catCount(quantity);
      } else if (task.type === TASK_TYPES.BUY_FOOD) {
        desc = `x${task.content.quantity}`;
      } else if (task.type === TASK_TYPES.FEED_ALL_CATS) {
        desc = `x${task.content.count_purchased_feeds}`;
      } else if (task.type === TASK_TYPES.BUY_HOUSE) {
        desc = 'x1';
      } else if (task.type === TASK_TYPES.BUY_HOUSE_INSURANCE) {
        desc = 'x1';
      } else if (task.type === TASK_TYPES.BUY_DRUGS) {
        const quantity = Object.values(task.content.drugs).reduce((acc, drugQuantity) => {
          return acc + drugQuantity;
        }, 0);
        desc = `x${quantity}`;
      } else if (task.type === TASK_TYPES.MATING) {
        if (task.status === TEAM_TASK_STATUS.IN_PROGRESS) {
          let houses;
          if (appStore.meIsCEO) {
            houses = corpStore.allHousesOfCorporation;
          } else {
            houses = appStore.houses;
          }
          const houseId = task.content.house_id;
          const currentHouse = houses.find((house) => house.house_id === houseId);
          const firstCat = appStore.catById(currentHouse.left_cat_id);
          const secondCat = appStore.catById(currentHouse.right_cat_id);
          const catTypeFirst = getCatTypeText({ gender: firstCat.gender, color: firstCat.color }, ' ');
          const catTypeSecond = getCatTypeText({ gender: secondCat.gender, color: secondCat.color }, ' ');
          desc = `${catTypeFirst} × ${catTypeSecond}`;
        } else {
          desc = '';
        }
      } else if (task.type === TASK_TYPES.GET_CREDIT) {
        desc = CREDIT_TYPE_NAME[task.content.credit_type];
      } else if (task.type === TASK_TYPES.CREDIT_REPAY) {
        let credits = [...appStore.credits.credits_info];
        if (appStore.meIsCEO) {
          corpStore.getInfoFromAllAreasByProperty('credits').forEach((credit) => credits.push(...credit.credits_info));
        }
        const credit = credits.find((credit) => credit.credit_id === task.content.credit_id);
        desc = CREDIT_TYPE_NAME[credit.credit_type];
      } else if ([TASK_TYPES.FAKE_EXPORT_TASK, TASK_TYPES.FAKE_IMPORT_TASK].includes(task.type)) {
        desc = catCount(task.count, true);
      } else if (task.type === TASK_TYPES.FAKE_BUDGET_ALLOCATION_TASK) {
        const allocationType = task.delta > 0 ? 'в город' : 'из города';
        const areaNum = RootStore.appStore.meIsCEO
          ? corpStore.areaNumByPlayerId(task.message_owner)
          : RootStore.appStore.areaNum;
        desc = `${allocationType} ${areaNum}`;
      } else if (task.type === TASK_TYPES.FAKE_LTD_ACTION_TASK) {
        if (withMail) {
          desc = LTD_CONTENT_NAME_ACCUSATIVE_CASE[task.ltd_type];
        } else if (task.status === FAKE_LTD_TEAM_TASK_STATUS.FINE) {
          const action = task.ltd_actions.find((action) => {
            if (action.sent_liabilities) {
              return action.sent_liabilities.find((item) => item.is_closed && item.is_fine)?.liability_type[0];
            }
          });
          desc = action.executor === RootStore.appStore.me.uuid ? 'оплата штрафа' : 'получение оплаты штрафа';
        } else if (task.two_way_termination && task.status === FAKE_LTD_TEAM_TASK_STATUS.TWO_WAY_TERMINATION) {
          desc =
            task.two_way_termination.payer === RootStore.appStore.me.uuid
              ? 'оплата расторжения'
              : 'получение оплаты расторжения';
        } else {
          let count;
          if (
            [
              FAKE_LTD_TEAM_TASK_STATUS.SENT,
              FAKE_LTD_TEAM_TASK_STATUS.AGREED,
              FAKE_LTD_TEAM_TASK_STATUS.RECEIVED,
              FAKE_LTD_TEAM_TASK_STATUS.COUNTER_SENT,
              FAKE_LTD_TEAM_TASK_STATUS.COUNTER_RECEIVED,
            ].includes(task.status)
          ) {
            count = task.catsTotalCount;
          } else {
            count = task.count;
          }
          desc = catCount(count, true);
        }
      } else if (task.type === TASK_TYPES.FAKE_TRANSPORTATION_TASK) {
        desc = catCount(task.count, true);
      } else if (task.type === TASK_TYPES.CREATE_CHANGE_LTD_REQUEST) {
        desc = '';
      }
    } else {
      if (teamHistoryItem.msg_type === 'player_timed_out') {
        desc = 'участник выбыл из игры';
      } else if (teamHistoryItem.msg_type === 'privileges_changed') {
        const isItSetPrivileges = teamHistoryItem.set_privileges.length > 0;
        const setOrRemove = isItSetPrivileges ? 'добавлена' : 'убрана';
        const privilegesMass = isItSetPrivileges ? teamHistoryItem.set_privileges : teamHistoryItem.remove_privileges;
        let specialtyRussianName = '';
        if (isPlayingAlone) {
          desc = 'Вы играете один';
        } else {
          privilegesMass.find((privilegesContent) => {
            const priv = privilegesContent[1];
            if (priv === 'approve_task_by_captain') {
              specialtyRussianName = SPECIALITIES_RUSSIAN_NAME['captain'];
            } else if (priv === 'create_sale_task') {
              specialtyRussianName = SPECIALITIES_RUSSIAN_NAME['sales'];
            } else if (priv === 'create_purchase_task') {
              specialtyRussianName = SPECIALITIES_RUSSIAN_NAME['supply'];
            } else if (priv === 'approve_purchase_task_by_market') {
              specialtyRussianName = SPECIALITIES_RUSSIAN_NAME['marketeer'];
            } else if (priv === 'approve_by_doctor') {
              specialtyRussianName = SPECIALITIES_RUSSIAN_NAME['doctor'];
            }
            return specialtyRussianName;
          });
          desc = `${setOrRemove} роль ${specialtyRussianName}`;
        }
      } else if (teamHistoryItem.msg_type === 'specialties_changed') {
        const isItSetPrivileges = teamHistoryItem.set_specialties.length > 0;
        const setOrRemove = isItSetPrivileges ? 'добавлена' : 'убрана';
        const specialtiesChanged = isItSetPrivileges
          ? teamHistoryItem.set_specialties
          : teamHistoryItem.remove_specialties;
        let specialtyRussianName = '';
        specialtiesChanged.find((specialtyContent) => {
          const specialty = specialtyContent[1];
          specialtyRussianName = SPECIALITIES_RUSSIAN_NAME[specialty];
          return specialtyRussianName;
        });
        desc = `${setOrRemove} роль ${specialtyRussianName}`;
      } else if (teamHistoryItem.section_type === 'running_cats') {
        desc = 'котики сбежали';
      }
    }
    return (
      <>
        <div className="team-history__desc">{desc}</div>
        <div className="team-history__sighting">{sighting}</div>
      </>
    );
  };
  const getGroupedCatsContent = ({ teamHistoryItem, cats }) => {
    const task = teamHistoryItem.task_update;

    if (task.type === TASK_TYPES.FAKE_LTD_ACTION_TASK) {
      return corpStore.fakeLTDTaskAbstractCats(task);
    } else {
      return cats.reduce((acc, cat) => {
        const isItSimilarCats = acc?.find((similarCats) => {
          return similarCats[0].gender === cat.gender && similarCats[0].color === cat.color;
        });
        if (isItSimilarCats) {
          isItSimilarCats.push(cat);
        } else {
          acc.push([cat]);
        }
        return acc;
      }, []);
    }
  };

  const { appStore } = RootStore;

  return (
    <div>
      {isCollapseExist && (
        <TeamHistoryItem
          isCollapseOpen={isCollapseOpen}
          setCollapseOpen={setCollapseOpen}
          teamHistoryTitle={getHistoryTitle({
            teamHistoryItem: uniqueTeamHistory[0],
            withMail: true,
          })}
          teamHistoryDesc={getHistoryDesc({
            teamHistoryItem: uniqueTeamHistory[0],
            prevTeamHistoryItem: uniqueTeamHistory[1],
            withMail: true,
          })}
          teamHistoryPrice={getHistoryPrice(uniqueTeamHistory[0])}
          teamHistoryStyleCollapseTop={isCollapseOpen}
          teamHistoryStyleCollapseBottom={false}
          isTeamHistorySpoilerExist={true}
          catsContents={getGroupedCatsContent({
            teamHistoryItem: uniqueTeamHistory[0],
            cats: uniqueTeamHistory[0].task_update.content.contents,
          })}
          priceVision={priceVision}
          catCountVision={catCountVision}
          areaNum={areaNum}
        />
      )}
      {((isCollapseExist && isCollapseOpen) || !isCollapseExist) &&
        uniqueTeamHistory.map((uniqueTeamHistoryItem, ind) => {
          const task = uniqueTeamHistoryItem.task_update;
          let groupCats = [];
          const isCatsContentNeeded =
            task &&
            ([TASK_TYPES.FAKE_EXPORT_TASK, TASK_TYPES.FAKE_IMPORT_TASK, TASK_TYPES.FAKE_TRANSPORTATION_TASK].includes(
              task.type,
            ) ||
              (!(task.visas[task.specialty_by] && task.status === TEAM_TASK_STATUS.CANCELLED) &&
                (task.specialty_by === SPECIALITIES.SUPPLY || task.specialty_by === SPECIALITIES.SALES)) ||
              (task.type === TASK_TYPES.FAKE_LTD_ACTION_TASK &&
                [FAKE_LTD_TEAM_TASK_STATUS.ACTION_COMPLETED].includes(task.status) &&
                [LTD_ACTIONS.TO_STORAGE].includes(task.lastCompletedLTDActionType)));
          if (isCatsContentNeeded) {
            groupCats = task.content.contents.reduce((acc, cat) => {
              const isItSimilarCats = acc.find((similarCats) => {
                return similarCats[0].gender === cat.gender && similarCats[0].color === cat.color;
              });
              if (isItSimilarCats) {
                isItSimilarCats.push(cat);
              } else {
                acc.push([cat]);
              }
              return acc;
            }, []);
          }
          const prevTeamHistoryItem = isCollapseExist ? uniqueTeamHistory[ind + 1] : uniqueTeamHistory[ind - 1];
          const teamHistoryTitle = getHistoryTitle({
            teamHistoryItem: uniqueTeamHistoryItem,
            withMail: !task,
          });
          const teamHistoryDesc = getHistoryDesc({
            teamHistoryItem: uniqueTeamHistoryItem,
            prevTeamHistoryItem: prevTeamHistoryItem,
            withMail: !task,
          });
          const teamHistoryPrice = getHistoryPrice(uniqueTeamHistoryItem);
          const teamHistoryStyleCollapseBottom =
            isCollapseExist && isCollapseOpen && ind === uniqueTeamHistory.length - 1;

          return (
            (!isCollapseExist || isCollapseOpen) && (
              <TeamHistoryItem
                key={ind}
                teamHistoryTitle={teamHistoryTitle}
                teamHistoryDesc={teamHistoryDesc}
                teamHistoryPrice={teamHistoryPrice}
                teamHistoryStyleCollapseTop={false}
                teamHistoryStyleCollapseBottom={teamHistoryStyleCollapseBottom}
                isTeamHistorySpoilerExist={isCatsContentNeeded}
                catsContents={groupCats}
                priceVision={priceVision}
                catCountVision={catCountVision}
                areaNum={areaNum}
              />
            )
          );
        })}
    </div>
  );
}

export default TeamHistoryItemsGenerator;
