import React from 'react';
import TeamAvatar from '../../ui/components/TeamAvatar/TeamAvatar';
import HeaderBalance from '../../ui/components/Header/HeaderBalance';
import { SliderArrow } from '../../ui/icons';
import RootStore from '../../store';
import './style.scss';
import classNames from '../../common/classNames';

const UserAvatar = ({ onClick, Logo, name, useStyleAdaptation = true, balanceIsVisible = true }) => {
  const { appStore } = RootStore;

  return (
    <div
      onClick={onClick}
      className={classNames({
        avatar_container: true,
        'avatar_container--inactive': !onClick,
        'avatar_container--with-adaptation': useStyleAdaptation,
      })}
    >
      {!appStore.isTeamMode && !appStore.isCorpMode && Logo && <Logo />}
      {(appStore.isTeamMode || appStore.isCorpMode) && Logo && (
        <TeamAvatar
          Logo={Logo}
          speciality={appStore.mySpeciality}
          customClass={classNames({
            'team-avatar__head-section': true,
            'team-avatar__head-section--with-adaptation': useStyleAdaptation,
          })}
        />
      )}
      <div
        className={classNames({
          user_info: true,
          'user_info--only-child': !balanceIsVisible,
        })}
      >
        <p className="user_name">{name}</p>
        {balanceIsVisible && <HeaderBalance />}
      </div>
      <div className="avatar-btn">
        <SliderArrow />
      </div>
    </div>
  );
};

export default UserAvatar;
