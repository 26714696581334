import React from 'react';
import storageImage from '../../assets/storage.png';

function StorageImage({ style }) {
  return (
    <img
      className="storage-image"
      src={storageImage}
      style={{
        ...style
      }}
    />
  );
}

export default StorageImage;
