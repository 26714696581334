import React from 'react';
import { observer } from 'mobx-react';
import RootStore from './../../../store';
import './CreditLogo.scss';
import * as teamMode from '../../../store/teamMode';
import { PRIVILEGES } from '../../../store/teamMode';
import { SPECIALITIES } from '../../../common/constants';

function CreditLogo() {
  const { appStore } = RootStore;
  if (
    !appStore.credits.credit_mechanics ||
    (appStore.isCorpMode
      ? !teamMode.playerHasPrivileges(appStore.myPrivileges, [PRIVILEGES.GET_CREDIT, PRIVILEGES.CREDIT_REPAY], true)
      : appStore.isTeamMode && !appStore.hasSpecialities([SPECIALITIES.MARKETEER]))
  ) {
    return null;
  }

  return <div className="credit-logo" onClick={appStore.openCreditModal} />;
}

export default observer(CreditLogo);
