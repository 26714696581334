import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import ExpectationIcon from '../icons/modal/ExpectationIcon';
import TextButton from '../buttons/TextButton/TextButton';
import { AUDIO_LIST } from '../../common/constants';
import EstimationTimerIcon from '../icons/EstimationTimerIcon';
import formattedEstimationTimer from '../../common/formattedEstimationTimer';

function BankruptcyModal() {
  const { appStore } = RootStore;

  if (!appStore.bankruptcyModalShowed) {
    return null;
  }

  const estimationTimer = appStore.estimationTimer;
  let formattedTimer = '';
  if (appStore.isTeamMode) {
    formattedTimer = formattedEstimationTimer(estimationTimer);
  }
  let title = 'Ваш баланс отрицательный';
  let desc =
    appStore.CEOIsBankrupt || appStore.meIsCEO
      ? 'Ваша корпорация вынуждена объявить себя банкротом'
      : 'Вы вынуждены объявить себя банкротом';

  if (appStore.bankGuaranteeBunkruptcy) {
    title =
      appStore.CEOIsBankrupt || appStore.meIsCEO
        ? 'К сожалению Ваша корпорация вынуждена объявить себя банкротом'
        : 'К сожалению Вы банкрот';
    desc = 'Вы оплатили аванс не поставив банковскую гарантию вашему оппоненту';
  }

  return (
    <Modal
      isOpen={appStore.bankruptcyModalShowed}
      shouldCloseOnOverlayClick={false}
      colorBg="red"
      title={title}
      desc={desc}
      customAudio={AUDIO_LIST.GAME_FAILURE}
    >
      <ExpectationIcon className="modal__body-icon" />
      {appStore.isTeamMode && (
        <>
          <div className="estimation-timer estimation-timer-on-red-background">
            <EstimationTimerIcon />
            <div className="estimation-timer__value notranslate">{estimationTimer ? formattedTimer : '00:00'}</div>
          </div>
          {estimationTimer ? (
            <div className="estimation-timer-desc estimation-timer-on-red-background">
              У Вас есть 5 минут, чтобы оценить игроков своей команды
            </div>
          ) : (
            <div className="estimation-timer-desc estimation-timer-on-red-background">
              Время для оценки игроков своей команды вышло
            </div>
          )}
        </>
      )}
      <div className="modal__body-actions">
        {appStore.isSpectatorModeOn ? (
          <TextButton
            title="Понятно"
            color="purple"
            onClick={() => {
              appStore.bankruptcyModalShowed = false;
              appStore.openUserListModal();
            }}
          />
        ) : (
          <>
            {appStore.isTeamMode && !!estimationTimer && (
              <TextButton title="Оценить игроков" color="green" onClick={() => appStore.openEstimationModal()} />
            )}
            <TextButton title="Выйти из игры" color="blue" onClick={() => appStore.closeSession()} />
          </>
        )}
      </div>
    </Modal>
  );
}

export default observer(BankruptcyModal);
