import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import RootStore from '../../store/index';
import { DRAGGABLE_ITEM_TYPE } from '../../common/constants';
import draggableItemDefaultCanDrag from '../../common/draggableItemDefaultCanDrag';

function DragableItem({ type = DRAGGABLE_ITEM_TYPE.CAT, from, data, element, draggableOptions, dragAround }) {
  const [{ isDragging }, drag] = useDrag({
    // "type" is required. It is used by the "accept" specification of drop targets
    item: {
      type,
      from,
      data,
    },
    canDrag: () =>
      draggableItemDefaultCanDrag({
        type,
        from,
        data,
        element,
        draggableOptions,
        dragAround,
      }),
    begin: (monitor) => {
      if (monitor.internalMonitor.isFakeBeginCall) {
        return {
          type,
          from,
          data,
          dragAround,
        };
      }
      if (draggableOptions.begin) {
        draggableOptions.begin(data, from, element);
      }
      if (type === 'cat') {
        RootStore.appStore.setCurrentDragCat({
          from,
          data,
        });
      }
    },
    end: (item, monitor) => {
      if (draggableOptions.end) {
        draggableOptions.end(item, monitor);
      }
      RootStore.appStore.setCurrentDragCat(null);
    },
    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (isDragging) {
      document.body.classList.add('dragging');
    } else {
      document.body.classList.remove('dragging');
    }
  }, [isDragging]);

  return element(isDragging, drag);
}

export default DragableItem;
