import { observer } from 'mobx-react';
import React from 'react';
import { OPERATION_TYPE } from '../../../../../store/constants';
import corpStore from '../../../../../store/CorpStore';
import { CloseIcon, CoinIcon } from '../../../../../ui/icons';
import CarIcon from '../../../../../ui/icons/CarIcon';
import classNames from '../../../../../common/classNames';
import { declOfNum } from '../../../../../common/declensionOfWords';
import { catCount } from '../../../../../common/texts/catCount';

export function OperationPanelInfo() {
  const { currentOperationType, detailInfoCatsForOperation, restCountPlacesInMyCars } = corpStore;
  const isDisabled = !detailInfoCatsForOperation.count;
  const highlight = detailInfoCatsForOperation.count && restCountPlacesInMyCars === 0;

  return (
    <div className="lot-panel__price">
      <div className="coins-counter-wrap">
        <p className="coins-counter-description">{catCount(detailInfoCatsForOperation.count)}</p>
        {currentOperationType === OPERATION_TYPE.TRANSPORTATION && (
          <div className="coins-counter coins-counter--operation-panel">
            <span
              className={classNames({
                'coins-counter-value': true,
                'coins-counter-value--bold': true,
                'coins-counter-value--highlight': highlight,
              })}
            >
              {restCountPlacesInMyCars}
            </span>
            <CarIcon />
          </div>
        )}
        {currentOperationType === OPERATION_TYPE.TRANSFER && (
          <div className="coins-counter">
            <CoinIcon style={{ marginRight: '4px' }} />
            <span className="coins-counter-value">{detailInfoCatsForOperation.price}</span>
          </div>
        )}
      </div>
      <CloseIcon isDisabled={isDisabled} onClick={() => corpStore.clearContentOperationField()} />
    </div>
  );
}

export default observer(OperationPanelInfo);
