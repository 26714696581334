import React from 'react';

function EatIcon({ style, stylePath, className, fill = '#FDF6EE' }) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52163 0.884192C8.8944 1.1042 7.88953 1.61504 7.28863 2.01896C6.68773 2.42312 6.09493 2.7537 5.97115 2.7537C5.84759 2.7537 5.05472 3.07744 4.20941 3.47291C2.42674 4.30703 2.28991 4.70066 3.53245 5.42055L4.38923 5.91701L3.94114 8.66794L2.89395 8.92313C0.418339 9.52582 -0.19336 10.5754 1.32194 11.6201C1.95817 12.0586 2.30454 12.1403 3.12239 12.0453L4.13514 11.9272V13.0352C4.13514 14.9502 5.55164 16.2241 6.37152 15.0466C6.5734 14.7567 6.89432 13.9799 7.08449 13.3201C7.46056 12.0163 7.96312 11.7513 8.4767 12.5861C9.34384 13.9955 11.6468 13.3235 11.6673 11.6551C11.6723 11.2516 11.9853 10.8114 12.6963 10.2082C14.5325 8.64989 15.5007 6.47606 15.5 3.91247C15.4998 2.13115 14.9873 0.900635 14.1501 0.669659C13.0656 0.370829 10.6681 0.481868 9.52163 0.884192ZM12.0796 2.34247C12.8435 3.11787 11.9453 4.40983 10.9994 3.89601C10.2049 3.46445 10.5488 2.06831 11.4495 2.06831C11.6475 2.06831 11.9311 2.19168 12.0796 2.34247ZM9.78294 6.13884C10.2571 6.53568 11.0538 7.00768 11.5537 7.18794L12.4622 7.51532L11.4495 7.53246C9.75526 7.56125 8.51565 6.52517 8.19202 4.80987L8.04101 4.01025L8.48077 4.71392C8.72271 5.10093 9.30852 5.742 9.78294 6.13884Z"
        fill={fill}
        style={stylePath}
      />
    </svg>
  );
}

export default EatIcon;
