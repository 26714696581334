import React from 'react';

function RevisionIcon({ style }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7531 2.64223C19.4064 4.04218 20.4167 9.1939 18.5292 11.6963C17.9952 12.4041 16.603 13.6177 15.4354 14.3936C14.2673 15.1695 13.312 16.1272 13.312 16.5224C13.312 17.0263 12.7029 17.2407 11.2698 17.2407C9.28174 17.2407 9.22764 17.195 9.22764 15.5111C9.22764 14.2228 9.56157 13.5516 10.5357 12.8814C14.1028 10.4275 14.8436 9.61714 14.8436 8.16956C14.8436 4.99241 9.94746 4.93879 8.99431 8.10493C8.55113 9.57703 8.32042 9.72732 6.50285 9.72732H4.5L4.76599 7.41223C5.34696 2.35522 11.5394 -0.109179 16.7531 2.64223Z"
        fill="inherit"
      />
      <path
        d="M13.5673 20.7469C13.5673 22.4584 13.5188 22.5 11.5251 22.5C9.77701 22.5 9.45892 22.3327 9.31496 21.3364C9.01525 19.2617 9.56867 18.6692 11.6486 18.838C13.4707 18.9857 13.5673 19.0819 13.5673 20.7469Z"
        fill="inherit"
      />
    </svg>
  );
}

export default RevisionIcon;
