import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarFlowerImage from '../../../assets/nurseryAvatars/avatarFlower.png';

function AvatarFlower({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <g clipPath="url(#avatarFlower)">
    //     <path
    //       d="M27.7975 15.4226C27.0983 13.2705 25.0118 12.0923 22.8637 12.7893C22.5799 12.8814 22.2921 13.009 22.0077 13.1675C22.0701 12.8482 22.1027 12.4002 22.1027 12.1016C22.1027 9.8402 20.2625 8 18.0011 8C15.7397 8 14.1306 9.8402 14.1306 12.1016C14.1306 12.4002 14.1632 12.8482 14.2256 13.1675C13.9412 13.009 13.654 12.8814 13.3702 12.7893C11.2175 12.0923 8.90062 13.2711 8.2014 15.4231C7.50175 17.5789 8.67855 19.8905 10.8347 20.5907C11.1191 20.6828 11.427 20.7486 11.7497 20.7881C11.5116 21.0096 11.3011 21.2436 11.1259 21.4851C9.79352 23.3181 10.1982 25.8815 12.0329 27.214C13.878 28.5573 16.2071 28.1295 17.5308 26.307C17.7064 26.0655 17.8644 25.7932 18.0011 25.4979C18.1379 25.7932 18.2958 26.0655 18.4715 26.307C19.8037 28.1414 22.1323 28.5471 23.966 27.214C25.7996 25.8823 26.2062 23.3192 24.873 21.4851C24.6979 21.2436 24.4873 21.0096 24.2492 20.7881C24.572 20.7486 24.8798 20.6828 25.1636 20.5907C27.3155 19.8923 28.4987 17.5844 27.7975 15.4226ZM23.1973 17.6095L20.8979 18.3566C21.0014 19.2769 20.7098 20.0446 20.1556 20.6506L21.5745 22.6037C22.0357 23.238 21.0737 23.9092 20.6269 23.2926L19.2104 21.3428C18.8406 21.5117 18.4334 21.6115 18.0011 21.6115C17.5688 21.6115 17.1615 21.5117 16.7918 21.3428L15.3753 23.2926C14.9234 23.9162 13.967 23.2374 14.4277 22.6037L15.8466 20.6506C15.3699 20.1293 15.0715 19.4421 15.0715 18.6818C15.0715 18.5704 15.0922 18.4647 15.1043 18.3565L12.8049 17.6095C12.0646 17.3687 12.4355 16.2561 13.1666 16.4948L15.4655 17.2418C15.8772 16.5199 16.5683 15.9824 17.4074 15.8114V13.4084C17.4074 12.636 18.5871 12.6328 18.5871 13.4084V15.8114C19.4262 15.9824 20.125 16.5199 20.5368 17.2418L22.8357 16.4948C23.5639 16.2553 23.9383 17.3685 23.1973 17.6095Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M18.0015 16.9241C17.0322 16.9241 16.2437 17.7126 16.2437 18.6819C16.2437 19.6512 17.0322 20.4397 18.0015 20.4397C18.9708 20.4397 19.7593 19.6512 19.7593 18.6819C19.7593 17.7126 18.9708 16.9241 18.0015 16.9241Z"
    //       fill="#02516C"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="avatarFlower">
    //       <rect width="20" height="20" fill="white" transform="translate(8 8)" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <Avatar style={style} avatarImage={AvatarFlowerImage} />
  );
}

export default AvatarFlower;
