import React from 'react';

function TrainingExitMenuIcon({ style, width = "40" }) {
  return (
    <svg width={width} height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path 
        d="M8.74572 1.1495C8.8801 0.932274 9.54014 0.595155 10.212 0.400839C10.8843 0.206522 12.164 0.0320606 13.0566 0.0129726C14.9581 -0.0274941 14.902 -0.293955 14.2725 5.78674C13.6506 11.798 13.8224 11.4315 11.625 11.4315C9.96888 11.4315 9.70777 11.3234 9.54857 10.5725C9.12253 8.56444 8.522 1.51217 8.74572 1.1495Z"
        fill="#C6A788"
      />
      <path
        d="M0.375026 12.75C0.380371 8.95491 1.56363 7.2069 4.10348 4.84609C4.95061 4.05889 5.85466 3.41448 6.11235 3.41448C6.38569 3.41448 6.74948 4.37345 6.98617 5.71763C7.38435 7.9788 7.36985 8.05248 6.17456 9.74826C3.87521 13.0104 4.75364 17.4434 8.11123 19.5183C10.0571 20.7209 13.1928 20.7209 15.1387 19.5183C18.4963 17.4434 19.3747 13.0104 17.0754 9.74826C15.8801 8.05248 15.8656 7.9788 16.2637 5.71763C16.7505 2.95521 17.1639 2.84527 19.2445 4.92587C21.7871 7.4684 22.8697 9.00683 22.875 12.75C22.8792 15.6338 22.38 16.4299 21.3794 18.236C17.125 25.9159 6.12795 25.9227 1.87704 18.2482C0.869189 16.4283 0.370827 15.638 0.375026 12.75Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default TrainingExitMenuIcon;
