import React from 'react';

function EstimationTimerIcon({}) {
  return (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="#C6A788" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.4742 38.8573C19.5878 39.3504 15.6408 38.6452 12.1849 36.8402C12.1849 36.8402 11.8707 36.0807 12.1849 35.1653C12.4991 34.2499 13.4148 34.6381 13.4148 34.6381C29.9989 38.3571 38.0443 25.9317 33.8609 16.2142C32.5048 13.0642 30.1418 10.8164 28.0346 9.60708C25.9274 8.39776 22.4333 7.60919 19.9989 7.82136C17.5645 8.03353 14.7766 8.94533 12.9219 10.4999C3.74914 17.7264 5.82634 27.4516 11.2491 32.9999C11.2491 32.9999 11.569 34.6682 10.9765 34.9516C10.3839 35.235 9.57634 34.9516 9.57634 34.9516C-3.75086 26.7499 2.49915 11.7499 6.24914 7.99994C9.99914 4.24994 15.3811 2.44729 19.2846 2.10708C23.1881 1.76686 27.1987 2.87568 30.5776 4.81483C33.9564 6.75398 36.6304 9.6758 38.2257 13.1719C39.821 16.6679 40.2579 20.5634 39.4754 24.3139C38.6928 28.0643 36.7298 31.4822 33.8609 34.0897C30.992 36.6973 27.3606 38.3641 23.4742 38.8573Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0013 13C21.4469 13 21.771 13.9828 21.771 15.1951V20.6257L27.6164 23.9959C28.8076 24.6827 29.5701 25.751 28.7511 26.75C27.9321 27.749 26.1923 27.4368 25.0011 26.75C25.0011 26.75 20.0599 24.8292 18.7512 23C17.4424 21.1708 17.8663 15.1951 17.8663 15.1951C17.8663 13.9828 18.5557 13 20.0013 13Z"
        fill="inherit"
      />
    </svg>
  );
}

export default EstimationTimerIcon;
