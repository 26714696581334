import React from 'react';
import CEOCircleIcon from '../../icons/roles/CEOCircleIcon';
import CaptainCircleIcon from '../../icons/roles/CaptainCircleIcon';
import SupplyCircleIcon from '../../icons/roles/SupplyCircleIcon';
import SalesCircleIcon from '../../icons/roles/SalesCircleIcon';
import MarketingCircleIcon from '../../icons/roles/MarketingCircleIcon';
import DoctorCircleIcon from '../../icons/roles/DoctorCircleIcon';
import { SPECIALITIES } from '../../../common/constants';

export const CIRCLES = {
  [SPECIALITIES.CEO]: CEOCircleIcon,
  [SPECIALITIES.CAPTAIN]: CaptainCircleIcon,
  [SPECIALITIES.SUPPLY]: SupplyCircleIcon,
  [SPECIALITIES.SALES]: SalesCircleIcon,
  [SPECIALITIES.MARKETEER]: MarketingCircleIcon,
  [SPECIALITIES.DOCTOR]: DoctorCircleIcon,
};

const SpecialityCircle = ({ speciality, style }) => {
  const Circle = CIRCLES[speciality];
  return <Circle style={style} />;
};

export default SpecialityCircle;
