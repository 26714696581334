import React from 'react';
import LTDStepsApiParams from '../base/LTDStepsApiParams';
import LTDChapter from '../base/LTDChapter';
import LTDStep from '../base/LTDStep';
import {
  CAT_COLOR,
  CAT_GENDER,
  CUSTOM_ACTION_NAMES,
  EDITABLE_FIELD,
  LTD_ACTIONS,
  LTD_CONTENT_TYPE,
  LTD_FINES_PAYMENT,
  REACT_SELECT_MENU_POSITION,
  TIMER_ID,
} from '../../../../common/constants';
import { INFO_MODAL_POSITION } from '../../base/constants';
import RootStore from '../../../index';
import generateLTDInputsSelectors from '../base/methods/generateLTDInputsSelectors';
import checkCatInfoForMakingLTD from '../base/methods/checkCatInfoForMakingLTD';
import generateSelectorForLTDMakingCats from '../base/methods/generateSelectorForLTDMakingCats';
import generateLTDContentInputId from '../../../../common/LTD/generateLTDContentInputId';
import generateLTDPayBlocksSelectors from '../base/methods/generateLTDPayBlocksSelectors';
import checkObjectParams from '../../../../common/checkObjectParams';
import { LTD_TERMINATION } from '../../../constants';
import { LocalStorage } from '../../../../common/localStorage';
import api from '../../../../api';
import generateLTDChapterName from '../base/methods/generateLTDChapterName';

const steps = () => [
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Порядок поставки</div>,
    message: (
      <p className="training-modal__desc">
        Мы договариваемся не о мгновенной поставке, поэтому оставим срок поставки котиков на 2 сезон. Это значит, что
        котики будут поставлены не на 1 сезон, а на 2
      </p>
    ),
    nextButton: true,
    elements: ['.ltd__content-timer-select--shipping_season'],
    disabledElements: ['.ltd__content-timer-select--shipping_season'],
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Склад</div>,
    message: (
      <p className="training-modal__desc">
        Разумно проводить осмотр продукции на промежуточном складе. Теперь котики не сразу приходят к тебе, а они
        сначала попадают на склад для осмотра. Далее мы более подробно разберем механику склада
      </p>
    ),
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: 'changeLTDContentTerms',
    checkParams: ([currentTypeTabLTD, fieldName, value]) => {
      return currentTypeTabLTD === LTD_CONTENT_TYPE.BUY && fieldName === EDITABLE_FIELD.STORAGE_TIMER && value === 10;
    },
    title: <div>Порядок поставки</div>,
    message: (
      <p className="training-modal__desc">
        Теперь установим временные рамки, когда во время поставки котики приедут к нам на склад для осмотра. Установим
        временные рамки на 10 минут до конца хода
      </p>
    ),
    elements: ['.ltd__content-timer-select--storage_timer'],
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Порядок поставки</div>,
    message: (
      <p className="training-modal__desc">
        После осмотра, устанавливаем временные рамки необходимые для отгрузки котиков со склада контрагента, после этого
        действия котики появятся у вас
      </p>
    ),
    elements: ['.ltd__content-timer-select--shipping_timer'],
    disabledElements: ['.ltd__content-timer-select--shipping_timer'],
    position: INFO_MODAL_POSITION.BOTTOM,
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: 'changeLTDContentTerms',
    checkParams: ([currentTypeTabLTD, fieldName, value]) => {
      return currentTypeTabLTD === LTD_CONTENT_TYPE.BUY && fieldName === EDITABLE_FIELD.SHIPPING_TIMER && value === 5;
    },
    title: <div>Порядок поставки</div>,
    message: <p className="training-modal__desc">Установим временные рамки 5 минут до конца хода</p>,
    elements: ['.ltd__content-timer-select--shipping_timer'],
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Порядок оплаты</div>,
    message: (
      <p className="training-modal__desc">
        После заполнения предмета сделки и порядка поставки, переходим к заполнению полей оплаты
      </p>
    ),
    elements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.BUY}`],
    disabledElements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.BUY}`],
    position: INFO_MODAL_POSITION.BOTTOM,
    nextButton: true,
    buttonText: 'Дальше',
    selectorIntoView: '.ltd__content-timers',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Порядок оплаты</div>,
    message: (
      <p className="training-modal__desc">
        Как правило в долгосрочных сделках есть 3 вида оплаты: аванс - 30% от суммы сделки, оплата до отгрузки - 60% и
        оплата после отгрузки - 10%
      </p>
    ),
    elements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.BUY}`],
    disabledElements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.BUY}`],
    position: INFO_MODAL_POSITION.RIGHT,
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Порядок оплаты</div>,
    message: (
      <p className="training-modal__desc">
        Чем больше аванс, тем меньше рисков у продавца и больше рисков у покупателя. Соответственно, для покупателя
        идеально, чтобы вся оплата была после отгрузки, а для продавца идеально, чтобы аванс составлял 100% оплаты.
      </p>
    ),
    elements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.BUY}`],
    disabledElements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.BUY}`],
    position: INFO_MODAL_POSITION.RIGHT,
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Порядок оплаты</div>,
    message: (
      <p className="training-modal__desc">
        Так сложилось, что устоявшееся базовое правило оплаты аванса, оплаты перед отгрузкой и после отгрузки являются
        30%, 60% и 10% соответственно, однако в каждом конкретном случае - это является итогом переговоров
      </p>
    ),
    elements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.BUY}`],
    disabledElements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.BUY}`],
    position: INFO_MODAL_POSITION.RIGHT,
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Порядок оплаты</div>,
    message: (
      <p className="training-modal__desc">
        Например, если товар является дефицитным, то, как правило, продавец требует 100% оплаты аванса. Если же у
        продавца хорошая финансовая поддержка, то он может предоставлять отсрочку платежа на поставленную продукцию.
        Таким образом, обходя конкурентов, у которых нет таких финансовых возможностей
      </p>
    ),
    elements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.BUY}`],
    disabledElements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.BUY}`],
    position: INFO_MODAL_POSITION.RIGHT,
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Аванс</div>,
    message: (
      <p className="training-modal__desc">
        Оплачивается перед отправкой котиков на склад, и предустановленный аванс составляет 30% от общей суммы сделки
      </p>
    ),
    elements: generateLTDPayBlocksSelectors({
      payBlocks: [LTD_ACTIONS.PREPAID],
    }),
    disabledElements: generateLTDPayBlocksSelectors({
      payBlocks: [LTD_ACTIONS.PREPAID],
    }),
    nextButton: true,
    position: INFO_MODAL_POSITION.BOTTOM,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionNames: ['changeLTDContentOrder', 'changeLTDContentTerms'],
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams: (params, actionName) => {
      const LTDContent = RootStore.appStore.LTDContentByType(
        RootStore.appStore.currentLTD.contents,
        LTD_CONTENT_TYPE.BUY,
      );
      return checkObjectParams({
        object: LTDContent,
        params: {
          [`${LTD_ACTIONS.PREPAID}.money`]: 12,
          [`${LTD_ACTIONS.PREPAID}.season`]: 1,
          [`${LTD_ACTIONS.PREPAID}.timer`]: 15,
        },
      });
    },
    title: <div>Аванс</div>,
    message: (
      <p className="training-modal__desc">
        Предлагаем изменить сроки оплаты аванса: поставим на 1 сезон, снизу укажем время до конца сезона, когда мы
        обязуемся оплатить аванс. Выберем 15 минут до конца хода
      </p>
    ),
    disabledElements: generateLTDInputsSelectors({
      selectorsInfo: [
        {
          contentType: LTD_CONTENT_TYPE.BUY,
          editableField: EDITABLE_FIELD.MONEY,
          fieldName: LTD_ACTIONS.PREPAID,
        },
      ],
    }),
    elements: generateLTDPayBlocksSelectors({
      payBlocks: [LTD_ACTIONS.PREPAID],
    }),
    position: INFO_MODAL_POSITION.RIGHT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Перед отгрузкой</div>,
    message: (
      <p className="training-modal__desc">
        Данный платеж происходит в сезон поставки после подтверждения котиков на складе и обычно составляет 60% от общей
        суммы сделки
      </p>
    ),
    nextButton: true,
    buttonText: 'Дальше',
    disabledElements: generateLTDPayBlocksSelectors({
      payBlocks: [LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT],
    }),
    elements: generateLTDPayBlocksSelectors({
      payBlocks: [LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT],
    }),
    position: INFO_MODAL_POSITION.BOTTOM,
  }),
  new LTDStep({
    actionNames: ['changeLTDContentOrder', 'changeLTDContentTerms'],
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams: (params, actionName) => {
      return checkObjectParams({
        object: RootStore.appStore.LTDContentByType(RootStore.appStore.currentLTD.contents, LTD_CONTENT_TYPE.BUY),
        params: {
          [`${LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT}.money`]: 25,
          [`${LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT}.season`]: 2,
          [`${LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT}.timer`]: 8,
        },
      });
    },
    title: <div>Перед отгрузкой</div>,
    message: <p className="training-modal__desc">изменим время на 8 минут до конца хода</p>,
    disabledElements: generateLTDInputsSelectors({
      selectorsInfo: [EDITABLE_FIELD.MONEY, EDITABLE_FIELD.SEASON].map((editableField) => {
        return {
          fieldName: LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT,
          editableField,
          contentType: LTD_CONTENT_TYPE.BUY,
        };
      }),
    }),
    elements: generateLTDPayBlocksSelectors({
      payBlocks: [LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT],
    }),
    position: INFO_MODAL_POSITION.RIGHT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>После отгрузки</div>,
    message: (
      <p className="training-modal__desc">
        Последний платеж оплачивается после отгрузки котиков со склада к вам в питомник / зоомагазин и обычно составляет
        10% от общей суммы сделки
      </p>
    ),
    nextButton: true,
    buttonText: 'Дальше',
    disabledElements: generateLTDPayBlocksSelectors({
      payBlocks: [LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT],
    }),
    elements: generateLTDPayBlocksSelectors({
      payBlocks: [LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT],
    }),
    position: INFO_MODAL_POSITION.BOTTOM,
  }),
  new LTDStep({
    actionNames: ['changeLTDContentOrder', 'changeLTDContentTerms'],
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams: (params, actionName) => {
      return checkObjectParams({
        object: RootStore.appStore.LTDContentByType(RootStore.appStore.currentLTD.contents, LTD_CONTENT_TYPE.BUY),
        params: {
          [`${LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT}.money`]: 5,
          [`${LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT}.season`]: 2,
          [`${LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT}.timer`]: 3,
        },
      });
    },
    title: <div>После отгрузки</div>,
    message: (
      <p className="training-modal__desc">
        Теперь перейдем к последнему пункту оплаты: оставим 2 сезон и меняем время на 3 минуты до конца хода. Данный вид
        оплаты не обязательно ставить в сезон поставки
      </p>
    ),
    disabledElements: generateLTDInputsSelectors({
      selectorsInfo: [EDITABLE_FIELD.MONEY, EDITABLE_FIELD.SEASON].map((editableField) => {
        return {
          fieldName: LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT,
          editableField,
          contentType: LTD_CONTENT_TYPE.BUY,
        };
      }),
    }),
    elements: generateLTDPayBlocksSelectors({
      payBlocks: [LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT],
    }),
    position: INFO_MODAL_POSITION.RIGHT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Суммы оплат</div>,
    message: (
      <p className="training-modal__desc">
        Также важно понимать, что мы можем менять суммы в оплатах. Например, можно обойтись без оплаты перед отгрузкой,
        оставив 0 монет, и всю сумму поделить на аванс и оплату после отгрузки. Но для обеспечения гарантий с обеих
        сторон лучше проводить оплату в 3 стадии регулируя суммы оплат
      </p>
    ),
    nextButton: true,
    buttonText: 'Дальше',
    elements: generateLTDInputsSelectors({
      selectorsInfo: [LTD_ACTIONS.PREPAID, LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT, LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT].map(
        (fieldName) => {
          return {
            fieldName,
            editableField: EDITABLE_FIELD.MONEY,
            contentType: LTD_CONTENT_TYPE.BUY,
          };
        },
      ),
    }),
    disabledElements: generateLTDInputsSelectors({
      selectorsInfo: [LTD_ACTIONS.PREPAID, LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT, LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT].map(
        (fieldName) => {
          return {
            fieldName,
            editableField: EDITABLE_FIELD.MONEY,
            contentType: LTD_CONTENT_TYPE.BUY,
          };
        },
      ),
    }),
    position: INFO_MODAL_POSITION.BOTTOM,
  }),
  new LTDStep({
    actionNames: ['changeLTDContentOrder', 'changeLTDContentTerms'],
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams: (params, actionName) => {
      return checkObjectParams({
        object: RootStore.appStore.LTDContentByType(RootStore.appStore.currentLTD.contents, LTD_CONTENT_TYPE.BUY),
        params: {
          [`${LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT}.money`]: 0,
          [`${LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT}.season`]: 2,
          [`${LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT}.timer`]: 8,

          [`${LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT}.money`]: 30,
          [`${LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT}.season`]: 2,
          [`${LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT}.timer`]: 3,
        },
      });
    },
    title: <div>Суммы оплат</div>,
    message: (
      <p className="training-modal__desc">Поставим 0 монет на оплату перед отгрузкой, а в оплату после отгрузки 30</p>
    ),
    elements: generateLTDInputsSelectors({
      selectorsInfo: [LTD_ACTIONS.PREPAID, LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT, LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT].map(
        (fieldName) => {
          return {
            fieldName,
            editableField: EDITABLE_FIELD.MONEY,
            contentType: LTD_CONTENT_TYPE.BUY,
          };
        },
      ),
    }),
    disabledElements: generateLTDInputsSelectors({
      selectorsInfo: [
        {
          fieldName: LTD_ACTIONS.PREPAID,
          editableField: EDITABLE_FIELD.MONEY,
          contentType: LTD_CONTENT_TYPE.BUY,
        },
      ],
    }),
    removeFocusFromElements: true,
    position: INFO_MODAL_POSITION.BOTTOM,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Обязательные поля</div>,
    message: (
      <p className="training-modal__desc">
        Только что мы заполнили обязательные поля долгосрочной сделки на покупку. Они выделены зеленым
      </p>
    ),
    elements: ['.ltd__content'],
    disabledElements: ['.ltd__content'],
    nextButton: true,
    buttonText: 'Дальше',
    position: INFO_MODAL_POSITION.BOTTOM,
  }),
  new LTDStep({
    actionName: 'onLTDTabChange',
    checkParams: (toTab) => {
      return toTab === LTD_CONTENT_TYPE.SELL;
    },
    title: <div>Зона продажи</div>,
    message: <p className="training-modal__desc">Перейдем к заполнению обязательных полей в зоне продажи</p>,
    elements: ['.ltd__content-tab--buy'],
    position: INFO_MODAL_POSITION.BOTTOM,
    selectorIntoView: '.ltd__head',
  }),
  new LTDStep({
    actionNames: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    nextButton: true,
    buttonText: 'Дальше',
    title: <div>Продажа котиков</div>,
    message: (
      <p className="training-modal__desc">
        В рамках одной сделки мы хотим заранее понимать, сколько заработаем от продажи рожденных котиков, поэтому
        договариваемся о количестве и цене в 1 сезоне
      </p>
    ),
  }),
  new LTDStep({
    actionNames: ['changeLTDContentCatCount', 'changeLTDContentCatPrice'],
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams: ([contentType, catObject, newValue], actionName) => {
      return checkCatInfoForMakingLTD({
        catsNeededParams: {
          [CAT_COLOR.WHITE]: {
            [CAT_GENDER.MALE]: {
              count: 8,
              price: 8,
            },
          },
        },
        contentType: LTD_CONTENT_TYPE.SELL,
      });
    },
    removeFocusFromElements: true,
    title: <div>Продажа котиков</div>,
    message: (
      <p className="training-modal__desc">
        Предметом продажи также будут являться котики, которые у нас родятся, укажем, что мы хотим в рамках данной
        сделки продать 8 белых котов по 8 монет, т.к. остальное потомство мы решили оставить
      </p>
    ),
    elements: [
      ...generateSelectorForLTDMakingCats({
        gendersByColors: { [CAT_COLOR.WHITE]: [CAT_GENDER.MALE] },
        contentType: LTD_CONTENT_TYPE.SELL,
      }),
    ],
    position: INFO_MODAL_POSITION.BOTTOM,
  }),
  new LTDStep({
    actionNames: ['changeLTDContentOrder', 'changeLTDContentTerms'],
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    selectMenuPosition: {
      [generateLTDContentInputId({
        fieldName: LTD_ACTIONS.SHIPPING,
        contentType: LTD_CONTENT_TYPE.SELL,
        editableField: EDITABLE_FIELD.SEASON,
      })]: REACT_SELECT_MENU_POSITION.TOP,
    },
    checkParams: (params, actionName) => {
      const LTDContent = RootStore.appStore.LTDContentByType(
        RootStore.appStore.currentLTD.contents,
        LTD_CONTENT_TYPE.SELL,
      );
      return checkObjectParams({
        object: LTDContent,
        params: {
          [EDITABLE_FIELD.SHIPPING_SEASON]: 6,
          [EDITABLE_FIELD.STORAGE_TIMER]: 15,
          [EDITABLE_FIELD.SHIPPING_TIMER]: 10,
        },
      });
    },
    title: <div>Срок поставки котиков</div>,
    message: (
      <p className="training-modal__desc">
        Установим срок поставки новорожденных котиков на 6 сезон. На осмотр поставим 15 минут до конца хода, а на
        отгрузку 10 минут
      </p>
    ),
    elements: [
      {
        fieldName: LTD_ACTIONS.SHIPPING,
        contentType: LTD_CONTENT_TYPE.SELL,
        editableField: EDITABLE_FIELD.SEASON,
      },
      {
        fieldName: 'storage',
        contentType: LTD_CONTENT_TYPE.SELL,
        editableField: EDITABLE_FIELD.TIMER,
      },
      {
        fieldName: LTD_ACTIONS.SHIPPING,
        contentType: LTD_CONTENT_TYPE.SELL,
        editableField: EDITABLE_FIELD.TIMER,
      },
    ].map((selectorInfo) => {
      return `#${generateLTDContentInputId(selectorInfo)}`;
    }),
    position: INFO_MODAL_POSITION.RIGHT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Порядок оплаты</div>,
    message: (
      <p className="training-modal__desc">
        Порядок оплаты в форме продажи ничем не отличается от формы покупки, суммы оставим как есть
      </p>
    ),
    elements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.SELL}`],
    disabledElements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.SELL}`],
    position: INFO_MODAL_POSITION.BOTTOM,
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionNames: ['changeLTDContentOrder', 'changeLTDContentTerms'],
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams: (params, actionName) => {
      return checkObjectParams({
        object: RootStore.appStore.LTDContentByType(RootStore.appStore.currentLTD.contents, LTD_CONTENT_TYPE.SELL),
        params: {
          [`${LTD_ACTIONS.PREPAID}.money`]: 19,
          [`${LTD_ACTIONS.PREPAID}.season`]: 6,
          [`${LTD_ACTIONS.PREPAID}.timer`]: 20,

          [`${LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT}.money`]: 38,
          [`${LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT}.season`]: 6,
          [`${LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT}.timer`]: 13,

          [`${LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT}.money`]: 7,
          [`${LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT}.season`]: 6,
          [`${LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT}.timer`]: 5,
        },
      });
    },
    title: <div>Порядок оплаты</div>,
    message: (
      <p className="training-modal__desc">
        Осталось поменять временные рамки: для аванса 20 минут до конца хода, для оплаты перед отгрузкой - 13 минут и
        для оплаты после отгрузки - 5 минут
      </p>
    ),
    disabledElements: generateLTDInputsSelectors({
      selectorsInfo: [
        LTD_ACTIONS.PREPAID,
        LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT,
        LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT,
      ].reduce((acc, ltdAction) => {
        const result = [EDITABLE_FIELD.MONEY, EDITABLE_FIELD.SEASON].map((editableField) => {
          return {
            contentType: LTD_CONTENT_TYPE.SELL,
            editableField,
            fieldName: ltdAction,
          };
        });
        return acc.concat(result);
      }, []),
    }),
    elements: [`.ltd__content-timers--${LTD_CONTENT_TYPE.SELL}`],
    position: INFO_MODAL_POSITION.RIGHT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Обязательные поля</div>,
    message: (
      <p className="training-modal__desc">
        Только что мы заполнили обязательные поля долгосрочной сделки на продажу. Они выделены коричневым цветом
      </p>
    ),
    elements: ['.ltd__content'],
    disabledElements: ['.ltd__content'],
    nextButton: true,
    buttonText: 'Дальше',
    position: INFO_MODAL_POSITION.BOTTOM,
    after() {
      RootStore.appStore.tutorialProgress.chapter++;
    },
  }),
];

const chapter2 = () =>
  new LTDChapter({
    title: 'Порядок поставки и порядок оплаты',
    chapter: generateLTDChapterName(2),
    stepsApiParams: LTDStepsApiParams,
    callback() {
      RootStore.appStore.hideIntroductionModal();
      RootStore.appStore.forceSetCurrentLTD({
        initiator: LocalStorage.ORIGINAL_TRAINING_USER_UID,
        partner: '00000000-0000-0000-0000-000000000021',
        type_ltd: LTD_CONTENT_TYPE.BUY_SELL,
        status: null,
        updater: null,
        contents: [
          {
            buyer: LocalStorage.ORIGINAL_TRAINING_USER_UID,
            seller: '00000000-0000-0000-0000-000000000021',
            prices_isDefaultValue: true,
            area_num: null,
            shipping_season: 2,
            storage_timer: 30,
            shipping_timer: 28,
            shipping_timer_isDefaultValue: true,
            abstract_contents: [
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.WHITE,
                price: 10,
                count: 2,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.WHITE,
                price: 11,
                count: 2,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
            ],
            prepaid: {
              isDefaultValue: true,
              season: 2,
              money: 12,
              timer: 31,
            },
            payment_before_shipment: {
              season: 2,
              money: 25,
              timer: 29,
              isDefaultValue: true,
            },
            payment_after_shipment: {
              isDefaultValue: true,
              season: 2,
              money: 5,
              timer: 27,
            },
            shipping_season_isDefaultValue: true,
            storage_timer_isDefaultValue: true,
          },
          {
            seller: LocalStorage.ORIGINAL_TRAINING_USER_UID,
            buyer: '00000000-0000-0000-0000-000000000021',
            prices_isDefaultValue: true,
            shipping_season: 2,
            storage_timer: 30,
            shipping_timer: 28,
            shipping_timer_isDefaultValue: true,
            abstract_contents: [
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.WHITE,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.WHITE,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
            ],
            prepaid: {
              isDefaultValue: true,
              season: 2,
              money: 0,
              timer: 31,
            },
            payment_before_shipment: {
              season: 2,
              money: 0,
              timer: 29,
              isDefaultValue: true,
            },
            payment_after_shipment: {
              isDefaultValue: true,
              season: 2,
              money: 0,
              timer: 27,
            },
            shipping_season_isDefaultValue: true,
            storage_timer_isDefaultValue: true,
          },
        ],
        overdue_payment_liability: {
          payment_type: LTD_FINES_PAYMENT.EMPTY,
          value: 0,
        },
        overdue_delivery_liability: {
          payment_type: LTD_FINES_PAYMENT.EMPTY,
          value: 0,
        },
        low_quality_liability: {
          payment_type: LTD_FINES_PAYMENT.EMPTY,
          value: 0,
        },
        initiator_bank_guarantee: {
          amount: 0,
          last_payment_season: 2,
          isPeriodicPayment: false,
        },
        partner_bank_guarantee: {
          amount: 0,
          last_payment_season: 2,
          isPeriodicPayment: false,
        },
        offer_bank_guarantee_amount: {
          amount: 0,
        },
        one_way_termination: {
          payment_type: LTD_TERMINATION.EMPTY,
          value: 0,
        },
        two_way_termination: {
          payment_type: LTD_TERMINATION.EMPTY,
          value: 0,
        },
      });
    },
    initialRouteParams: {
      routeName: 'longTermsDealsNew',
      params: {
        type_ltd: LTD_CONTENT_TYPE.BUY_SELL,
        uuid: '00000000-0000-0000-0000-000000000021',
      },
    },
    userIdentifierForSocketInitialization: LocalStorage.ORIGINAL_TRAINING_USER_UID,
    defaultStepSettings: {
      hiddenTimersIds: [TIMER_ID.HEADER.SESSION_TIMER],
    },
    beforeStepsInitializationStep: new LTDStep({
      title: <div>Ожидание старта главы</div>,
      message: <p className="training-modal__desc">Ожидание...</p>,
    }),
    seamlessStartOfNextChapter: true,
    stepsGeneratorInfo: {
      generateOnChapterInitialization: false,
      generator: steps,
    },
  });

export default chapter2;
