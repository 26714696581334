import React from 'react';

function SicknessPoisoning({ style, className }) {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <g clipPath="url(#clipPoisoning)">
        <path d="M29.5122 2.4668C26.2213 -0.822266 20.8684 -0.822266 17.5775 2.4668L10.6196 9.42467L18.5193 17.3243C20.1451 15.9704 22.2064 15.1226 24.4825 15.1226C25.788 15.1226 27.0303 15.3938 28.1607 15.878L29.5122 14.4015C32.8021 11.1115 32.8021 5.75773 29.5122 2.4668Z" />
        <path d="M17.2046 18.6611L9.29393 10.7505L2.48405 17.5671C-0.805883 20.857 -0.805883 26.2109 2.48405 29.5017C5.77487 32.7906 11.1903 32.8534 14.4812 29.5642L15.8617 28.1771C15.3775 27.0467 15.1063 25.8044 15.1063 24.4989C15.1064 22.2812 15.9126 20.2682 17.2046 18.6611Z" />
        <path d="M16.9814 24.4988C16.9814 28.3203 19.8498 31.4392 23.5449 31.9053V17.0923C19.8498 17.5585 16.9814 20.6772 16.9814 24.4988Z" />
        <path d="M25.4199 17.0923V31.9053C29.115 31.4392 31.9834 28.3203 31.9834 24.4988C31.9834 20.6772 29.1151 17.5585 25.4199 17.0923Z" />
      </g>
      <defs>
        <clipPath id="clipPoisoning">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SicknessPoisoning;
