import _ from 'lodash';
import Modal from './Modal/Modal';
import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import Cat, { BOTTOM_TEXT, CAT_STATE } from './Cat/Cat';
import corpStore from '../../store/CorpStore';
import { useState } from 'react';
import './ShipmentsModal.scss';
import TextButton from '../buttons/TextButton/TextButton';
import ArrowLeftIcon2 from '../icons/ArrowLeftIcon2';
import { MESSAGE_TYPE } from '../../store/constants';
import { SICKNESS } from '../../common/constants';
import Slider from 'react-slick';
import classNames from '../../common/classNames';

function ShipmentsModal() {
  const { appStore } = RootStore;
  const [currentSlide, setCurrentSlide] = useState(0);
  const slider = useRef(null);
  if (!appStore.shipmentsModal) {
    return null;
  }

  const ltdId = appStore.currentLiabilityLTD?.deal_id;
  const storageCats = appStore.catsLTDFromStorage;
  const currentAreaNum = corpStore.areaByLTDId(ltdId)?.area_num;
  const myCats = corpStore.corpCats?.[currentAreaNum];
  const _cats = [];
  if (storageCats && myCats) {
    storageCats.forEach((item) => {
      myCats.forEach((cat) => {
        if (cat.color === item.color && cat.gender === item.gender && !!cat?.contents?.length) {
          _cats.push(...cat.contents);
        }
      });
    });
  } else if (storageCats && appStore.me?.actionLTD.cats) {
    storageCats.forEach((item) => {
      appStore.me?.actionLTD.cats.forEach((cat) => {
        if (cat.color === item.color && cat.gender === item.gender && !!cat?.contents?.length) {
          _cats.push(...cat.contents);
        }
      });
    });
  }

  const catsSlide = _.chunk(appStore.meIsCEO ? _cats : appStore.ourCats, 8);

  if (!_cats.length) {
    appStore.notEnoughCatsModalFlag = true;
    appStore.shipmentsModal = false;
    return;
  }

  const InsideCat = (cat) => {
    const overflowStorage = _.find(storageCats, (c) => c.gender === cat.gender && c.color === cat.color);
    const need = overflowStorage.needCount - overflowStorage.factCount;
    let cnt = 0;

    _cats.forEach((c) => {
      if (c.color === cat.color && c.gender === cat.gender) {
        appStore.shipmentsCats.forEach((cat_id) => {
          if (c.cat_id === cat_id) {
            cnt += 1;
          }
        });
      }
    });

    const isChecked = appStore.shipmentsCats.includes(cat.cat_id);
    const overflow = (overflowStorage.factCount >= overflowStorage.needCount || cnt >= need) && !isChecked;

    return (
      <Cat
        forceViewSickness={true}
        cat={cat}
        state={CAT_STATE.SINGLE}
        bottomText={BOTTOM_TEXT.AGE}
        checkBox
        checkBoxValue={isChecked}
        onClick={() => appStore.stateShipmentsCats(cat.cat_id, !!overflow)}
        fakeReadonly={!!overflow}
        readonly={!!overflow}
        dragAround={false}
        draggableOptions={false}
        pinkBackground={cat.sickness !== SICKNESS.NONE}
      />
    );
  };

  const handleNext = () => {
    if (currentPage < catsSlide.length - 1) {
      setCurrentPage((pr) => pr + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentSlide((pr) => pr - 1);
    }
  };

  const onSend = () => {
    appStore.sendMessage(MESSAGE_TYPE.PUT_ALL_CATS_TO_STORAGE_LTD, {
      deal_id: ltdId,
      cat_ids: appStore.shipmentsCats,
    });
    appStore.hideShipmentsModal();
  };

  const rows = _cats.length > 4 ? 2 : 1;
  const slidesPerRow = _cats.length > 4 ? 4 : _cats.length;

  const sliderSettings = {
    className: 'container-shipments',
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: rows,
    slidesPerRow: slidesPerRow,
    initialSlide: currentSlide,
    arrows: true,
    focusOnSelect: false,
    nextArrow: (
      <div onClick={() => handleNext()}>
        <ArrowLeftIcon2 style={{ transform: 'rotate(180deg) translateY(50%)' }} />
      </div>
    ),
    prevArrow: (
      <div onClick={() => handlePrev()}>
        <ArrowLeftIcon2 style={{ transform: 'translateY(-50%)' }} />
      </div>
    ),
  };

  const slides = _cats.map((slide, index) => {
    return <div key={index}>{InsideCat(slide)}</div>;
  });

  return (
    <Modal
      isOpen={!!appStore.shipmentsModal}
      onClose={appStore.hideShipmentsModal}
      title="КОТЫ"
      size="very-big"
      exitIcon={true}
    >
      <div className="container">
        <div className="container-shipments">
          <Slider
            {...sliderSettings}
            ref={slider}
            className={classNames({
              slider: true,
            })}
          >
            {slides}
          </Slider>
        </div>
        <TextButton title="отгрузить" color={'blue'} onClick={() => onSend()} style={{ maxWidth: '240px' }} />
      </div>
    </Modal>
  );
}

export default observer(ShipmentsModal);
