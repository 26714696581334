import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import ExpectationIcon from '../icons/modal/ExpectationIcon';
import TextButton from '../buttons/TextButton/TextButton';
import HeaderChatButton from './Header/HeaderChatButton';
import trainingStore from '../../store/trainingStore';
import { SPECIALITIES } from '../../common/constants';

function EndTurnModal() {
  const { appStore } = RootStore;

  if (!appStore.endTurnFlag) {
    return null;
  }

  const isCancelEndTurnDisabled = () => {
    if (appStore.isTeamMode) {
      return (
        appStore.endTurnFlag && !appStore.hasSpecialities(appStore.isCorpMode ? SPECIALITIES.CEO : SPECIALITIES.CAPTAIN)
      );
    } else return trainingStore.launched;
  };

  let playersCompletedTurnMessage = '';
  if (
    appStore.activeHumanPlayers.length - appStore.countEndTurnCompaniesActiveHuman.length <= 2 &&
    appStore.activeHumanPlayers.length - appStore.countEndTurnCompaniesActiveHuman.length !== 0
  ) {
    playersCompletedTurnMessage = `${appStore.countEndTurnCompaniesActiveHuman.length}/${
      appStore.activeHumanPlayers.length
    }: ${appStore.notEndTurnCompanies.map((player) => player.name).join(', ')}`;
  } else {
    playersCompletedTurnMessage = `${appStore.countEndTurnCompaniesActiveHuman.length}/${appStore.activeHumanPlayers.length}`;
  }

  let descIsVisible = false;
  if (trainingStore.launched || appStore.isSingleMode) {
    descIsVisible = false;
  } else if (!appStore.isCorpMode || appStore.meIsCEO) {
    descIsVisible = true;
  }

  return (
    <Modal
      isOpen={appStore.endTurnFlag}
      shouldCloseOnOverlayClick={false}
      title="Ожидаем других игроков"
      head={true}
      desc={descIsVisible && playersCompletedTurnMessage}
    >
      <ExpectationIcon className="modal__body-icon" />
      <div className="modal__body-actions modal__body-actions--btn-with-icon">
        <HeaderChatButton />
        <TextButton
          title="ОТМЕНА"
          color="purple"
          onClick={appStore.cancelEndTurn}
          isDisabled={isCancelEndTurnDisabled()}
        />
      </div>
    </Modal>
  );
}

export default observer(EndTurnModal);
