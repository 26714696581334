import React from 'react';

function AlabugaIconMain() {
  return (
    <svg width="113" height="28" viewBox="0 0 113 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34317 13.8582C8.06176 15.2564 6.70222 16.7834 5.43337 18.2922C3.264 16.5405 2.08811 15.7801 0 14.473C1.17582 12.9461 2.47186 11.4021 3.85301 9.886C6.08894 10.9886 7.58977 12.312 9.33806 13.8537L9.34317 13.8582ZM9.61427 13.563C12.4829 10.4464 14.8915 8.04054 14.8915 8.04054C14.8915 8.04054 17.2674 10.4139 20.0408 13.4748C21.8185 11.9112 23.3467 10.7241 25.6138 9.59512C24.1226 7.95068 22.5786 6.37011 20.9261 4.8534C18.7699 6.04697 17.4525 7.07319 15.6326 8.78932L15.3577 8.4978C17.1637 6.79478 18.4921 5.75389 20.6137 4.56863C18.853 2.97445 16.9674 1.45157 14.8915 0C12.9425 1.36999 10.9935 2.94688 9.10955 4.6472C11.164 5.79694 12.6545 6.82804 14.4253 8.4978L14.1504 8.78932C12.3751 7.11533 10.8902 6.0956 8.79652 4.9314C7.17757 6.40991 5.60969 7.97682 4.13463 9.57854C6.36199 10.6948 7.87753 12.0314 9.59316 13.5444L9.61427 13.563ZM20.3095 13.7721C22.0976 12.1996 23.6146 11.0259 25.8927 9.90412C27.2156 11.3764 28.4993 12.8993 29.7829 14.473C27.5037 15.9595 26.3141 16.8392 24.3496 18.4932C23.0719 16.8979 21.6522 15.262 20.3095 13.7721ZM18.9162 23.3176C17.268 24.9202 16.403 25.9148 14.8915 27.7399C13.38 25.9148 12.5149 24.9202 10.8667 23.3176C12.2754 21.3074 13.1251 20.2211 14.8915 18.4932C16.6579 20.2211 17.5075 21.3074 18.9162 23.3176Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M50.3197 17.9623C50.4073 17.9623 50.5827 17.9623 50.6703 17.8742V20.0755C50.232 20.2516 49.5307 20.4277 48.917 20.4277C47.8651 20.4277 46.9884 20.0755 46.5501 19.3711C45.5858 20.0755 44.5338 20.6038 43.1312 20.6038C41.2025 20.6038 39.7122 19.4591 39.7122 17.522C39.7122 15.0566 42.0792 14.2642 44.0078 14.2642C44.7091 14.2642 45.4981 14.3522 46.1118 14.5283V14.1761C46.1118 13.4717 45.4981 12.9434 44.0955 12.9434C42.7805 12.9434 41.6408 13.2956 40.6765 13.7358V10.8302C41.4655 10.566 43.0435 10.2138 44.5338 10.2138C47.6897 10.2138 49.706 11.2704 49.706 14.7925V17.522C49.706 17.7862 50.0567 17.9623 50.3197 17.9623ZM44.6215 18.1384C45.2351 18.1384 45.6734 18.0503 46.1994 17.6101V16.2893C45.8488 16.2013 45.4104 16.1132 44.9721 16.1132C44.1831 16.1132 43.3941 16.3774 43.3941 17.1698C43.3941 17.6101 43.8325 18.1384 44.6215 18.1384Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path d="M54.9659 5.10693V20.1635H51.4593V5.10693H54.9659Z" fill="white" fillOpacity="0.5" />
      <path
        d="M66.5376 17.9623C66.6253 17.9623 66.8006 17.9623 66.8883 17.8742V20.0755C66.45 20.2516 65.7486 20.4277 65.135 20.4277C64.083 20.4277 63.2064 20.0755 62.768 19.3711C61.8037 20.0755 60.7517 20.6038 59.3491 20.6038C57.4205 20.6038 55.9302 19.4591 55.9302 17.522C55.9302 15.0566 58.2971 14.2642 60.2258 14.2642C60.9271 14.2642 61.7161 14.3522 62.3297 14.5283V14.1761C62.3297 13.4717 61.7161 12.9434 60.3134 12.9434C58.9984 12.9434 57.8588 13.2956 56.8945 13.7358V10.8302C57.6835 10.566 59.2614 10.2138 60.7517 10.2138C63.9077 10.2138 65.924 11.2704 65.924 14.7925V17.522C65.924 17.7862 66.187 17.9623 66.5376 17.9623ZM60.8394 18.1384C61.4531 18.1384 61.8914 18.0503 62.4174 17.6101V16.2893C62.0667 16.2013 61.6284 16.1132 61.1901 16.1132C60.4011 16.1132 59.6121 16.3774 59.6121 17.1698C59.6121 17.6101 60.0504 18.1384 60.8394 18.1384Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M71.1838 5.72327V11.0943C71.7975 10.478 72.7618 9.94968 74.0768 9.94968C77.408 9.94968 78.8107 12.6792 78.8107 15.2327C78.8107 17.7862 77.3204 20.5157 74.0768 20.5157C72.3235 20.5157 71.1838 19.7233 70.7455 18.8428V20.1635H67.6772V5.72327H71.1838ZM73.2001 12.8553C72.2358 12.8553 71.5345 13.4717 71.1838 14.088V16.3774C71.5345 16.9937 72.2358 17.6101 73.2001 17.6101C74.6904 17.6101 75.3041 16.3774 75.3041 15.2327C75.3041 14.088 74.6904 12.8553 73.2001 12.8553Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M89.5935 10.3019V20.1635H86.5252V19.0189C85.7362 19.8994 84.6843 20.4277 83.3693 20.4277C80.6517 20.4277 79.4244 18.5786 79.4244 16.3774V10.2138H82.931V15.8491C82.931 16.9057 83.5446 17.434 84.4213 17.434C85.2103 17.434 85.8239 16.9937 86.0869 16.5535V10.2138H89.5935V10.3019Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M95.0287 9.94968C96.782 9.94968 97.9216 10.7421 98.3599 11.6226V10.3019H101.428V19.8113C101.428 23.7736 98.5353 25.0943 95.6423 25.0943C94.0644 25.0943 92.5741 24.7421 91.4344 24.1258V21.1321C92.4864 21.7484 93.889 22.1887 95.204 22.1887C96.782 22.1887 98.0093 21.7484 98.0093 19.9874V19.3711C97.3956 19.9874 96.4313 20.5157 95.1163 20.5157C91.7851 20.5157 90.3824 17.7862 90.3824 15.2327C90.2948 12.6792 91.7851 9.94968 95.0287 9.94968ZM95.993 12.8553C94.5027 12.8553 93.889 14.088 93.889 15.2327C93.889 16.3773 94.5027 17.6101 95.993 17.6101C96.9573 17.6101 97.6586 16.9937 98.0093 16.3773V14.088C97.6586 13.4717 96.9573 12.8553 95.993 12.8553Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M112.649 17.9623C112.737 17.9623 112.912 17.9623 113 17.8742V20.0755C112.562 20.2516 111.86 20.4277 111.247 20.4277C110.195 20.4277 109.318 20.0755 108.88 19.3711C107.915 20.0755 106.863 20.6038 105.461 20.6038C103.532 20.6038 102.042 19.4591 102.042 17.522C102.042 15.0566 104.409 14.2642 106.337 14.2642C107.039 14.2642 107.828 14.3522 108.441 14.5283V14.1761C108.441 13.4717 107.828 12.9434 106.425 12.9434C105.11 12.9434 103.97 13.2956 103.006 13.7358V10.8302C103.795 10.566 105.373 10.2138 106.863 10.2138C110.019 10.2138 112.036 11.2704 112.036 14.7925V17.522C111.948 17.7862 112.299 17.9623 112.649 17.9623ZM106.863 18.1384C107.477 18.1384 107.915 18.0503 108.441 17.6101V16.2893C108.091 16.2013 107.652 16.1132 107.214 16.1132C106.425 16.1132 105.636 16.3774 105.636 17.1698C105.636 17.6101 106.074 18.1384 106.863 18.1384Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
}

export default AlabugaIconMain;
