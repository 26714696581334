import ReactModal from 'react-modal';
import React, { useEffect } from 'react';
import './style.scss';
import classNames from '../../../common/classNames';
import RootStore from '../../../store/';
import { AUDIO_LIST } from '../../../common/constants';
import IconButton from '../../buttons/IconButton/IconButton';
import ExitIcon from '../../icons/modal/ExitIcon';
import CaptainIcon from '../../icons/roles/CaptainIcon';
import { CloseIcon } from '../../icons';
import trainingStore from '../../../store/trainingStore';

function Modal({
  isOpen,
  fullWidth,
  isVisible = true,
  title,
  desc,
  onClose,
  shouldCloseOnOverlayClick,
  children,
  colorBg,
  size,
  logo,
  titleIcon,
  customClass,
  style = {},
  customAudio = null,
  exitIcon = false,
  head,
  headLeft,
  headRight,
  headIcon,
  transparentOverlay = false,
  ...rest
}) {
  useEffect(() => {
    if (isOpen) {
      if (customAudio === null) {
        if (colorBg === 'green') {
          appStore.playAudio(AUDIO_LIST.MODAL_ACCEPTED);
        } else if (colorBg === 'red') {
          appStore.playAudio(AUDIO_LIST.MODAL_DENIED);
        }
      } else {
        appStore.playAudio(customAudio);
      }
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const { appStore } = RootStore;

  return (
    <ReactModal
      isOpen={isOpen}
      parentSelector={() => document.querySelectorAll('#appLayout')[0]}
      style={{
        overlay: { opacity: isVisible ? 1 : 0, pointerEvents: isVisible ? 'all' : 'none', ...style },
      }}
      overlayClassName={classNames({ 'modal-overlay': true, 'modal-overlay--transparent': transparentOverlay })}
      className={classNames({
        modal: true,
        'modal--full-with': fullWidth,
        [`modal--color-${colorBg}`]: colorBg,
        [`modal--size-${size}`]: size,
        [`modal--${customClass}`]: !!customClass,
      })}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={!trainingStore.launched}
      {...rest}
    >
      {head && (
        <div className="modal__head">
          {headLeft && headLeft}
          {headIcon && headIcon}
          {headRight && headRight}
        </div>
      )}
      {(title || desc) && (
        <div className="modal__header">
          {title && (
            <div className="modal__title">
              {titleIcon}
              {title}
              {exitIcon && (
                <IconButton customClass="modal__title-exit" color="blue" onClick={onClose}>
                  <ExitIcon />
                </IconButton>
              )}
            </div>
          )}
          {desc && (
            <div className="modal__desc">
              {logo && (
                <div className="modal__logo">
                  <logo />
                </div>
              )}
              <span>{desc}</span>
            </div>
          )}
        </div>
      )}
      {/* <div className="modal__scroll"></div> */}
      <div className="modal__body">{children}</div>
    </ReactModal>
  );
}

export default Modal;
