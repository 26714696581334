import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import { TEAM_TASK_NOTIFICATIONS } from './TeamNotificationsModal/TeamTaskNotificationsModal';
import { SPECIALITIES, SPECIALITIES_RUSSIAN_NAME } from '../../common/constants';

function TheDealWasTakenAwayModal() {
  const { appStore } = RootStore;

  if (!appStore.taskIsTakenAway) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.taskIsTakenAway}
      onClose={appStore.taskIsTakenAwayModalClose}
      title={SPECIALITIES_RUSSIAN_NAME[SPECIALITIES[appStore.theDealWasTakenAwayBy.toUpperCase()]]}
      desc={TEAM_TASK_NOTIFICATIONS.TASK_INITIATOR}
    >
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.taskIsTakenAwayModalClose} />
      </div>
    </Modal>
  );
}

export default observer(TheDealWasTakenAwayModal);
