import React, { useState } from 'react';
import ReactModal from 'react-modal';
import RootStore from '../../store';
import { observer } from 'mobx-react';
import SettingIcon from '../../ui/icons/SettingIcon';
import SwitchIcon from '../../ui/icons/SwitchIcon';
import ParametersIcon from '../icons/ParametersIcon';
import ExitHrIcon from '../../ui/icons/ExitHrIcon';
import IconButton from '../buttons/IconButton/IconButton';
import trainingStore from '../../store/trainingStore';
import TrainingReloadIcon from '../icons/TrainingReloadIcon';
import TrainingExitMenuIcon from '../icons/TrainingExitMenuIcon';
import CheckMarkIcon from '../icons/CheckMarkIcon';
import { LocalStorage } from '../../common/localStorage';
import { PLATFORM_URLS } from '../../common/platformUrls';
import { ROOT_PATHS } from '../../common/constants';
import api from '../../api';

ReactModal.setAppElement('#root');

const Switcher = ({ value }) => {
  return <SwitchIcon enabled={value} />;
};

function Settings() {
  const { appStore } = RootStore;

  let extraItems = [
    {
      icon: <ParametersIcon />,
      title: 'Параметры сессии',
      name: 'parameters',
      action: () => {
        appStore.hideExtra();
        appStore.openParametersModal();
      },
    },
    // {
    //     icon:  <TrainingReloadIcon />,
    //     title: "начать задание заново",
    //     action: null,
    // },
    // {
    //     icon:  <TrainingExitMenuIcon />,
    //     title: "выйти в меню обучения",
    //     action: null,
    // },
    {
      icon: <ExitHrIcon />,
      title: 'выйти в HR-платформу',
      action: () => {
        location.href = PLATFORM_URLS.CLIENT;
      },
    },
    {
      name: 'modalNotification',
      title: 'модальные оповещения',
      action: appStore.toggleModalNotification,
      value: appStore.modalNotificationOn,
    },
    {
      name: 'sounds',
      title: 'звуки',
      action: appStore.toggleSounds,
      value: appStore.soundsOn,
    },
    {
      name: 'music',
      title: 'музыка',
      action: appStore.toggleMusic,
      value: appStore.musicOn,
    },
    {
      name: 'fullScreen',
      title: 'полноэкранный режим',
      value: appStore.fullScreenOn,
      action: () => {
        appStore.hideExtra();
        appStore.toggleFullscreen();
      },
    },
  ];

  if (appStore.isSingleMode) {
    extraItems = [
      {
        icon: <ParametersIcon />,
        title: 'Параметры сессии',
        name: 'parameters',
        action: () => {
          appStore.hideExtra();
          appStore.openParametersModal();
        },
      },
      {
        icon: <TrainingReloadIcon />,
        title: 'начать заново',
        action: () => appStore.soloGameRestart(),
      },
      {
        icon: <ExitHrIcon />,
        title: 'выйти в HR-платформу',
        action: () => {
          location.href = PLATFORM_URLS.CLIENT;
        },
      },
      {
        name: 'modalNotification',
        title: 'модальные оповещения',
        action: appStore.toggleModalNotification,
        value: appStore.modalNotificationOn,
      },
      {
        name: 'sounds',
        title: 'звуки',
        action: appStore.toggleSounds,
        value: appStore.soundsOn,
      },
      {
        name: 'music',
        title: 'музыка',
        action: appStore.toggleMusic,
        value: appStore.musicOn,
      },
      {
        name: 'fullScreen',
        title: 'полноэкранный режим',
        value: appStore.fullScreenOn,
        action: () => {
          appStore.hideExtra();
          appStore.toggleFullscreen();
        },
      },
    ];
  }

  const currentComponentName = appStore.router.currentRoute.rootPath;

  if (currentComponentName === ROOT_PATHS.TRAINING || currentComponentName === ROOT_PATHS.START_TRAINING) {
    extraItems = [
      {
        name: 'sounds',
        title: 'звуки',
        action: appStore.toggleSounds,
        value: appStore.soundsOn,
      },
      {
        name: 'music',
        title: 'музыка',
        action: appStore.toggleMusic,
        value: appStore.musicOn,
      },
      {
        name: 'fullScreen',
        title: 'полноэкранный режим',
        value: appStore.fullScreenOn,
        action: () => {
          appStore.hideExtra();
          appStore.toggleFullscreen();
        },
      },
    ];
  }

  if (window.runTraining) {
    extraItems = [
      {
        icon: <CheckMarkIcon />,
        title: 'Цель задания',
        action: () => {
          appStore.hideExtra();
          trainingStore.openTask();
        },
      },
      {
        icon: <TrainingReloadIcon />,
        title: 'начать задание заново',
        action: () => trainingStore.relaunchTask(),
      },
      {
        icon: <TrainingExitMenuIcon />,
        title: 'выйти в меню обучения',
        action: async () => {
          if (trainingStore.currentTask) {
            await api.common.setTutorialProgress(trainingStore.currentTask.chapter);
          }
          location.href = trainingStore.mainScreenTrainingHref;
        },
      },
      {
        name: 'sounds',
        title: 'звуки',
        action: appStore.toggleSounds,
        value: appStore.soundsOn,
      },
      {
        name: 'music',
        title: 'музыка',
        action: appStore.toggleMusic,
        value: appStore.musicOn,
      },
      {
        name: 'fullScreen',
        title: 'полноэкранный режим',
        value: appStore.fullScreenOn,
        action: () => {
          appStore.hideExtra();
          appStore.toggleFullscreen();
        },
      },
    ];
  }

  if (
    ((trainingStore.launchedChapter === 1 && !trainingStore.currentTask) || trainingStore.launchedChapter === 12) &&
    window.runTraining
  ) {
    extraItems = [
      {
        icon: <ParametersIcon />,
        title: 'Параметры сессии',
        name: 'parameters',
        action: () => {
          appStore.hideExtra();
          appStore.openParametersModal();
        },
      },
      {
        icon: <CheckMarkIcon />,
        title: 'Цель задания',
        action: () => {
          appStore.hideExtra();
          trainingStore.openTask();
        },
      },
      {
        icon: <TrainingReloadIcon />,
        title: 'начать задание заново',
        action: () => trainingStore.relaunchTask(),
      },
      {
        icon: <TrainingExitMenuIcon />,
        title: 'выйти в меню обучения',
        action: () => {
          location.href = trainingStore.mainScreenTrainingHref;
        },
      },
      {
        name: 'sounds',
        title: 'звуки',
        action: appStore.toggleSounds,
        value: appStore.soundsOn,
      },
      {
        name: 'music',
        title: 'музыка',
        action: appStore.toggleMusic,
        value: appStore.musicOn,
      },
      {
        name: 'fullScreen',
        title: 'полноэкранный режим',
        value: appStore.fullScreenOn,
        action: () => {
          appStore.hideExtra();
          appStore.toggleFullscreen();
        },
      },
    ];
  }

  const extraRender = extraItems.map((item, ind) => {
    return (
      <div
        key={ind}
        className="header-extra__item"
        onClick={() =>
          (!trainingStore.launched || !trainingStore.steps[trainingStore.currentStepIndex]?.menuClickDisabled) &&
          item.action()
        }
      >
        {item.icon ? item.icon : <Switcher value={item.value} />}
        <span className="header-extra__item-title">{item.title}</span>
      </div>
    );
  });
  const openExtraClick = () => {
    appStore.checkFullscreen();
    appStore.openExtra();
  };

  return (
    <>
      <IconButton color="blue" customClass="training-extra" onClick={openExtraClick}>
        <SettingIcon style={{ width: '24px', height: '24px', display: 'block', fill: '#FFFBE5' }} />
      </IconButton>

      {
        <ReactModal
          isOpen={appStore.openExtraFlag}
          onRequestClose={appStore.hideExtra}
          shouldCloseOnOverlayClick={trainingStore.currentStep?.needOverlayClickModal ?? true}
          className={'header-extra__modal'}
          style={{
            overlay: { zIndex: '97', backgroundColor: '' },
          }}
          shouldCloseOnEsc={!trainingStore.launched}
        >
          {extraRender}
        </ReactModal>
      }
    </>
  );
}

export default observer(Settings);
