import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarButterflyImage from '../../../assets/nurseryAvatars/avatarButterfly.png';

function AvatarButterfly({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <g clipPath="url(#avatarButterfly)">
    //     <path
    //       d="M15.4246 13.5902C15.4246 13.5638 15.4323 13.5391 15.4332 13.5127C14.2385 13.2214 12.3304 12.7373 11.3423 12.388C10.1983 11.983 9.47914 11.0718 9.7337 10.3523C9.98821 9.63262 10.7289 9.53083 11.8728 9.93468C12.8552 10.2822 14.5198 11.8071 15.5576 12.8253C15.7015 12.3799 15.9517 11.982 16.2933 11.6764C14.9593 10.2939 12.5311 8.2439 9.84785 8.2439C8.66851 8.2439 8.16445 9.37202 8.16445 9.37202C7.91779 9.92367 7.95274 10.8087 8.24151 11.3392L9.1668 13.0376C9.45566 13.5681 9.43252 14.4225 9.11526 14.9364L8.29296 16.2698C7.97556 16.7838 7.91788 17.6555 8.1645 18.2067C8.1645 18.2067 8.66855 19.3355 9.84789 19.3355C12.1766 19.3355 14.3453 17.7418 15.7499 16.4164C15.5489 16.0495 15.4246 15.6356 15.4246 15.1887V13.5902Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M26.8848 14.9364C26.5673 14.4224 26.5442 13.568 26.833 13.0375L27.7585 11.3392C28.0472 10.8087 28.0823 9.92367 27.8356 9.37202C27.8356 9.37202 27.3314 8.2439 26.1517 8.2439C23.4685 8.2439 21.0408 10.2939 19.7065 11.6764C20.0482 11.982 20.2985 12.3799 20.4423 12.8253C21.4803 11.8071 23.1448 10.2822 24.1271 9.93469C25.2708 9.53088 26.0117 9.63262 26.2663 10.3523C26.5207 11.0718 25.8015 11.983 24.6578 12.388C23.6692 12.7373 21.7611 13.2215 20.5666 13.5127C20.5674 13.5391 20.5753 13.5638 20.5753 13.5903V15.1888C20.5753 15.6357 20.4508 16.0497 20.2502 16.4165C21.6549 17.742 23.8234 19.3356 26.1518 19.3356C27.3317 19.3356 27.8357 18.2068 27.8357 18.2068C28.0824 17.6557 28.0243 16.7839 27.7069 16.2699L26.8848 14.9364Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M19.6668 13.7652C19.6668 12.8838 18.9194 12.1667 18.0002 12.1667C17.0808 12.1667 16.3335 12.8838 16.3335 13.7652V15.1516C16.3335 16.033 17.0808 16.7501 18.0002 16.7501C18.9194 16.7501 19.6668 16.033 19.6668 15.1516V13.7652Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M27.6375 24.1614L25.2294 20.3367C23.0609 20.0049 21.1292 18.5872 19.8145 17.3603C19.3482 17.8236 18.7069 18.111 17.9999 18.111C17.2928 18.111 16.6516 17.8236 16.1853 17.3603C14.8704 18.5872 12.9387 20.0049 10.7702 20.3367L8.36232 24.1614C8.14662 24.5346 8.27801 24.7998 8.64896 24.7998C8.69339 24.7998 8.74131 24.7962 8.79211 24.7884L11.2523 24.411C11.2994 24.4039 11.3481 24.4003 11.3958 24.4003C11.833 24.4003 12.2813 24.6906 12.4399 25.0959L13.3403 27.4139C13.4294 27.6419 13.5514 27.7563 13.6754 27.7563C13.796 27.7563 13.9189 27.6469 14.0156 27.4288L17.6439 19.5811C17.7418 19.3602 17.8703 19.2501 17.9999 19.2501C18.129 19.2501 18.2578 19.3602 18.3559 19.5811L21.9842 27.4288C22.0808 27.6469 22.2038 27.7563 22.3246 27.7563C22.4488 27.7563 22.5705 27.6419 22.6592 27.4139L23.5599 25.0959C23.7187 24.6906 24.167 24.4003 24.6038 24.4003C24.6518 24.4003 24.6996 24.4039 24.7477 24.411L27.2078 24.7884C27.2585 24.7962 27.3062 24.7998 27.3506 24.7998C27.7216 24.7999 27.8532 24.5346 27.6375 24.1614Z"
    //       fill="#02516C"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="avatarButterfly">
    //       <rect width="20" height="20" fill="white" transform="translate(8 8)" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <Avatar style={style} avatarImage={AvatarButterflyImage} />
  );
}

export default AvatarButterfly;
