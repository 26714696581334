import React from 'react';
import Modal from './Modal/Modal';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import PoisoningModalIcon from '../icons/modal/PoisoningModalIcon';
import CoinModalIcon from '../icons/modal/CoinModalIcon';

function PoisoningModal() {
  const { appStore } = RootStore;

  if (!appStore.poisoningData) {
    return null;
  }

  return (
    <Modal isOpen={!!appStore.poisoningData} title="хочешь дать котику таблетки?" style={{ zIndex: 1000 }}>
      <div className="modal__body-price">
        <PoisoningModalIcon />
        {!appStore.isTeamMode && (
          <>
            <p className="modal__body-price-text">ЦЕНА таблеток</p>
            <p className="modal__body-price-coin">
              <CoinModalIcon className="modal__body-price-coin-icon" />
              <span className="modal__body-price-coin-count notranslate">
                {' '}
                {appStore.poisoningData?.insured ? 0 : 2}{' '}
              </span>
            </p>
          </>
        )}
      </div>
      <div className="modal__body-actions">
        <TextButton
          color="green"
          title="Дать таблетку"
          onClick={() => appStore.catTreatmentSend(appStore.poisoningData)}
        />
        <TextButton color="purple" title="отмена" onClick={appStore.hideTreatmentActionModal} />
      </div>
    </Modal>
  );
}

export default observer(PoisoningModal);
