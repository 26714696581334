import React from 'react';
import { observer } from 'mobx-react';
import { CoinIcon } from '../../../../icons';
import classNames from '../../../../../common/classNames';
import _ from 'lodash';
import CatUrlImage from '../../../CatUrlImage';
import { LTD_ACTIONS, LTD_STATUS } from '../../../../../common/constants';
import generateLTDActionDetailedCatId from '../../../../../common/LTD/generateLTDActionDetailedCatId';

function LTDCats({ cats, status, actions }) {
  const DetailedInformation = (props) => {
    return (
      <>
        {props.catItem.buy_price && props.catItem.buy_count && (
          <div className="ltd-action__detailed-cat--info">
            <span className="ltd-action__detailed-cat--info-price">{props.catItem.buy_price}</span>
            <CoinIcon />
            <span className="ltd-action__detailed-cat--info-count">×{props.catItem.buy_count}</span>
          </div>
        )}
        {props.catItem.sell_price && props.catItem.sell_count && (
          <div className="ltd-action__detailed-cat--info">
            <span className="ltd-action__detailed-cat--info-price ltd-action__detailed-cat--info-price-sell">
              {props.catItem.sell_price}
            </span>
            <CoinIcon />
            <span className="ltd-action__detailed-cat--info-count">×{props.catItem.sell_count}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={classNames({
        'ltd-action__detailed-cats': true,
        'ltd-action__detailed--terminating':
          status === LTD_STATUS.TERMINATING || _.find(actions, { action_type: LTD_ACTIONS.TERMINATING }),
      })}
    >
      {cats.map((catItem) => (
        <div
          className="ltd-action__detailed-cat--wrapper"
          id={generateLTDActionDetailedCatId({
            gender: catItem.gender,
            color: catItem.color,
          })}
          key={catItem.gender + catItem.color}
        >
          <CatUrlImage color={catItem.color} gender={catItem.gender} width="40" height="40" />
          <DetailedInformation catItem={catItem} />
        </div>
      ))}
    </div>
  );
}

export default observer(LTDCats);
