import _ from 'lodash';
import { PLATFORM_URLS } from '../common/platformUrls';

const REQUEST_CACHE = {};

class ApiService {
  constructor(urlPrefix, requestPrepare, responseCallback, host) {
    this.requestPrepare = requestPrepare;
    this.responseCallback = responseCallback;
    this.urlPrefix = urlPrefix;
    this.host = host || window.location.origin;
  }

  async request(
    method,
    url,
    params = {},
    body = {},
    headers = {},
    contentType = true,
    host = '',
    cache = false,
    checkCache = false,
  ) {
    let urlPrefix = this.urlPrefix;
    if (this.requestPrepare) {
      const newParams = await this.requestPrepare(method, url, params, body, headers);
      // eslint-disable-next-line no-param-reassign
      method = newParams.method;
      // eslint-disable-next-line no-param-reassign,no-param-reassign
      url = newParams.url;
      // eslint-disable-next-line no-param-reassign
      params = newParams.params;
      // eslint-disable-next-line no-param-reassign
      body = newParams.body;
      // eslint-disable-next-line no-param-reassign
      headers = newParams.headers || {};
    }

    // if (!checkCache && method === 'GET' && url.includes('v1/tutorial')) {
    //   host = PLATFORM_URLS.API_CLIENT;
    // }

    const fullUrl = new URL(`${urlPrefix}/${url ? url + '/' : ''}`, host || this.host);
    // Формируем параметры fetch
    const requestParams = {
      method,
      headers: {
        ...headers,
      },
      credentials: 'include',
      mode: 'cors',
    };
    // Для возможности отключить поле content-type. Pixel не обрабатывает запросы с content-type.
    if (contentType) {
      requestParams.headers['content-type'] = 'application/json';
    }
    // Для методов, предполагающих тело запроса добавляем тело запроса
    if (body && ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
      requestParams.body = JSON.stringify(body);
    }
    if (params) {
      fullUrl.search = new URLSearchParams(_.pickBy(params, _.identity));
    }

    if (cache && REQUEST_CACHE[fullUrl.toString()]) {
      return _.cloneDeep(REQUEST_CACHE[fullUrl.toString()]);
    }
    if (checkCache) {
      return false;
    }

    const response = await fetch(fullUrl, requestParams).catch((error) => {
      // eslint-disable-next-line no-console
      throw error;
      // Raven.captureMessage('Fetch error', {
      //   level: 'error',
      //   extra: {
      //     error,
      //     fullUrl,
      //     requestParams,
      //   },
      // });
    });
    let result = '';
    try {
      result = await response.json();
    } catch (e) {}

    if (this.responseCallback) {
      const res = await this.responseCallback({ status: response.status, result }, method, url, params, body, headers);
      if (res || res === null) {
        return res;
      }
    }

    // Обрабатываем ошибки со статусом 40х, 50х
    if (response.status >= 400) {
      const error = new Error(response.statusText);
      error.code = response.status;
      error.data = response;
      error.result = result;
      throw error;
    }

    if (cache) {
      REQUEST_CACHE[fullUrl.toString()] = _.cloneDeep(result);
    }

    return result;
  }

  get(url, params = {}, headers = {}, contentType = true, host = '', cache = false) {
    return this.request('GET', url, params, {}, headers, contentType, host, cache);
  }

  checkCache(url, params = {}, headers = {}, contentType = true, host = '') {
    return this.request('GET', url, params, {}, headers, contentType, host, true, true);
  }

  post(url, params, body, headers = {}, contentType = true, host) {
    return this.request('POST', url, params, body, headers, contentType, host);
  }

  patch(url, params, body, headers = {}) {
    return this.request('PATCH', url, params, body, headers);
  }

  put(url, params, body, headers = {}) {
    return this.request('PUT', url, params, body, headers);
  }

  delete(url, params, body, headers = {}) {
    return this.request('DELETE', url, params, body, headers);
  }
}

export default ApiService;
