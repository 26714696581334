import React from 'react';

function DividingArrow() {
  return (
    <svg width="1" height="30" viewBox="0 0 1 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="1" height="30" fill="#EEDBC7" />
    </svg>
  );
}

export default DividingArrow;
