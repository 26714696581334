import React from 'react';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import { ROLE_NAME } from '../../../store/constants';
import _ from 'lodash';
import routes from '../../../routes';
import Modal from '../Modal/Modal';
import TextButton from '../../buttons/TextButton/TextButton';
import { CatFaceIcon } from '../../icons';
import './LTDIncompleteStorageModal.scss';
import { LTD_ACTIONS } from '../../../common/constants';

function LTDIncompleteStorageModal() {
  const { appStore } = RootStore;

  if (!appStore.LTDIncompleteStorageModalData) {
    return null;
  }

  const LTDIncompleteStorage = appStore.LTDIncompleteStorageModalData;
  const currentLTD = appStore.ltdById(LTDIncompleteStorage.deal_id);
  const myUuid = appStore.meIsCEO ? appStore.ceo_uuid : appStore.me.uuid;
  const players = appStore.meIsCEO ? appStore._players : appStore.players;
  const partnerUuid =
    myUuid === currentLTD.ltd_info.initiator ? currentLTD.ltd_info.partner : currentLTD.ltd_info.initiator;
  const partner = appStore.ltdPartner(partnerUuid, currentLTD.ltd_info);
  const areaNum = appStore.meIsCEO ? currentLTD.ltd_info.contents[0].area_num : '';
  const desc = appStore.meIsCEO
    ? `В ${ROLE_NAME[partner.role]} ${partner.name} в город ${areaNum}`
    : `В ${ROLE_NAME[partner.role]} ${partner.name}`;

  return (
    <Modal
      isOpen={!!appStore.LTDIncompleteStorageModalData}
      onClose={appStore.hideLTDModalIncompleteStorage}
      title="Хочешь отправить не полный склад?"
      desc={desc}
      customClass="ltd-incomplete-storage"
    >
      <CatFaceIcon fill={'#C6A788'} className="modal--ltd-incomplete-storage--icon" />
      <div className="modal__body-actions">
        <TextButton
          color={'green'}
          title={'Отправить'}
          onClick={() => {
            const actionWasPerformed = appStore.LTDPerfomAction(
              {
                deal_id: LTDIncompleteStorage.deal_id,
                action_type: LTD_ACTIONS.TO_STORAGE,
                action: LTDIncompleteStorage.action,
              },
              routes.main,
            );
            appStore.hideLTDModalIncompleteStorage();
            if (actionWasPerformed) {
              RootStore.goTo(routes.main);
            }
          }}
        />
        <TextButton
          color="blue"
          title="отмена"
          onClick={() => {
            appStore.hideLTDModalIncompleteStorage();
          }}
        />
      </div>
    </Modal>
  );
}

export default observer(LTDIncompleteStorageModal);
