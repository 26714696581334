import React from 'react';
import RootStore from '../../../../../store';
import PlayerObject from '../classes/Player';
import Player from './PlayerComponent';
import { observer } from 'mobx-react';
import { SPECIALITIES } from '../../../../../common/constants';

const MainPlayer = () => {
  const { appStore } = RootStore;
  if (appStore.isCorpMode) {
    const CEO = (() => {
      if (appStore.meIsCEO) {
        return appStore.me;
      } else {
        const CEO = appStore.ourCEO;
        return { ...CEO, active: !CEO.is_timed_out };
      }
    })();
    const player = new PlayerObject({
      speciality: SPECIALITIES.CEO,
      active: CEO.active,
      // ToDo: {BCC-938} сделать прием с платформы
      fullName: CEO.name,
      competences: CEO.competences,
    });
    return (
      <div className="our-company-user-list__main-player">
        <Player player={player} topDividerIsNeeded={true} bottomDividerIsNeeded={true} />
      </div>
    );
  }
  return null;
};

export default observer(MainPlayer);
