import React from 'react';

function EquallyModalIcon({ style, fill, stylePath, className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0.867656 3.47618L2.2845 3.20983C3.06376 3.06369 7.94338 2.97261 13.1286 3.00742C21.0148 3.06085 22.6198 3.17054 22.9481 3.67858C23.1639 4.01255 23.2939 5.55327 23.2364 7.10249L23.1323 9.91957L21.5131 10.2661C20.6225 10.4567 15.7429 10.5555 10.6694 10.4855C3.01236 10.3794 1.37812 10.2548 1.05225 9.75077C0.836078 9.4168 0.706128 7.86839 0.763612 6.30986L0.867656 3.47618Z"
        fill={fill ? fill : '#C6A788'}
      />
      <path
        d="M0.867656 13.9762L2.2845 13.7098C3.06376 13.5637 7.94338 13.4726 13.1286 13.5074C21.0148 13.5609 22.6198 13.6705 22.9481 14.1786C23.1639 14.5126 23.2939 16.0533 23.2364 17.6025L23.1323 20.4196L21.5131 20.7661C20.6225 20.9567 15.7429 21.0555 10.6694 20.9855C3.01236 20.8794 1.37812 20.7548 1.05225 20.2508C0.836078 19.9168 0.706128 18.3684 0.763612 16.8099L0.867656 13.9762Z"
        fill={fill ? fill : '#C6A788'}
      />
    </svg>
  );
}

export default EquallyModalIcon;
