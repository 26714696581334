import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import RootStore from '../store';
import routes from '../routes';
import { observer } from 'mobx-react';
import {
  LTD_ARCHIVE_ACTION_TYPE,
  LTD_NOTIFICATION,
  LTD_TERMINATION,
  LTD_TERMINATION_NAME,
  TASK_TYPES,
  TEAM_TASK_STATUS,
} from '../store/constants';
import {
  DislikeIcon,
  HouseIcon,
  LikeIcon,
  SendIcon,
  TeamBuyerIcon,
  TeamMarketIcon,
  TeamReworkIcon,
  TeamSellerIcon,
} from '../ui/icons';
import TextButton from '../ui/buttons/TextButton/TextButton';
import Header from '../ui/components/Header/Header';
import LTDContent from '../ui/components/LTDForm/LTDContent';
import containerBG from '../assets/basic/trade/background/trade_bg.png';
import './long-terms-deals.scss';
import ReactSelect from '../ui/Select/Select';
import * as PropTypes from 'prop-types';
import NumberInput from '../ui/NumberInput/NumberInput';
import classNames from '../common/classNames';
import StampSignedIcon from '../ui/icons/StampSignedIcon';
import StampNotSignedIcon from '../ui/icons/StampNotSignedIcon';
import CoinIcon from '../ui/icons/CoinIcon';
import Modal from '../ui/components/Modal/Modal';
import AreaIcon from '../ui/icons/AreaIcon';
import LTDShowcasePrices from '../ui/components/LTDShowcasePrices/LTDShowcasePrices';
import {
  EDITABLE_FIELD,
  LOW_QUALITY_CRITERIES,
  LOW_QUALITY_CRITERIES_NAME,
  LTD_CONTENT_TYPE,
  LTD_FINES_PAYMENT,
  LTD_FINES_PAYMENT_NAME,
  LTD_REAL_TIME_FINES_PAYMENT_NAME,
  LTD_STATUS,
  PRIVILEGES,
  SPECIALITIES,
} from '../common/constants';
import trainingStore from '../store/trainingStore';
import generateLTDFineInputId from '../common/LTD/generateLTDFineInputId';
import LTDStep from '../store/trainings/LTDTrainings/base/LTDStep';
import SightConfirmation from '../modules/deal/lot/components/lotPanelSighting/SightConfirmation';

const Fine = observer((props) => {
  const { appStore } = RootStore;
  const IsChooseCriterie =
    appStore.isRealTimeMode &&
    props.fieldName === EDITABLE_FIELD.LOW_QUALITY_LIABILITY &&
    props.conditions?.low_quality_type === LTD_FINES_PAYMENT.EMPTY;
  const criteriesFines = [
    LOW_QUALITY_CRITERIES.SICK_CATS,
    LOW_QUALITY_CRITERIES.HUNGRY_CATS,
    LOW_QUALITY_CRITERIES.SICK_AND_HUNGRY_CATS,
  ];
  const typeFines = [];
  if (props.fieldName === EDITABLE_FIELD.OVERDUE_PAYMENT_LIABILITY) {
    typeFines.push(
      LTD_FINES_PAYMENT.EMPTY,
      LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
      LTD_FINES_PAYMENT.FIXED,
      LTD_FINES_PAYMENT.PCT_OF_OVERDUE_PAYMENT,
    );
  } else if (props.fieldName === EDITABLE_FIELD.OVERDUE_DELIVERY_LIABILITY) {
    typeFines.push(
      LTD_FINES_PAYMENT.EMPTY,
      LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
      LTD_FINES_PAYMENT.FIXED,
      LTD_FINES_PAYMENT.PCT_OF_MISSED_CATS,
    );
  } else if (props.fieldName === EDITABLE_FIELD.LOW_QUALITY_LIABILITY) {
    typeFines.push(
      LTD_FINES_PAYMENT.EMPTY,
      LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
      LTD_FINES_PAYMENT.FIXED,
      LTD_FINES_PAYMENT.PCT_OF_SICK_CATS,
    );
  }

  return (
    <div className="ltd-fine">
      <div className="ltd-fine__value">
        <div className="ltd__content-timer-title ltd-fine__title">{props.title}</div>
        <NumberInput
          isPercent={
            ![LTD_FINES_PAYMENT.FIXED, LTD_FINES_PAYMENT.EMPTY].includes(props.conditions[props.fieldName].payment_type)
          }
          highlight={appStore.getHighlight(`${props.fieldName}.value`)}
          className="ltd-fine__input"
          disabled={props.conditions[props.fieldName].payment_type === LTD_FINES_PAYMENT.EMPTY}
          value={props.conditions[props.fieldName].value || ''}
          placeholder="0"
          onChange={(newValue) => {
            appStore.changeLTDConditions(props.fieldName, EDITABLE_FIELD.VALUE, newValue);
          }}
          id={generateLTDFineInputId({ fineType: props.fieldName, editableField: EDITABLE_FIELD.VALUE })}
          readOnly={props.readOnly}
        />
      </div>
      {appStore.isRealTimeMode && props.fieldName === EDITABLE_FIELD.LOW_QUALITY_LIABILITY && (
        <ReactSelect
          readOnly={props.readOnly}
          highlight={appStore.getHighlight(`${props.fieldName}.payment_type`)}
          disabled={props.conditions.low_quality_type.low_quality_type === LTD_FINES_PAYMENT.EMPTY}
          value={{
            label: LOW_QUALITY_CRITERIES_NAME[props.conditions.low_quality_type.low_quality_type] || 'без штрафа',
            value: props.conditions?.low_quality_type.low_quality_type,
          }}
          onChange={(event) => {
            appStore.changeLTDConditions(EDITABLE_FIELD.LOW_QUALITY_TYPE, EDITABLE_FIELD.LOW_QUALITY_TYPE, event.value);
          }}
          className="ltd__content-timer-select ltd-fine__select"
          options={criteriesFines.map((item) => {
            return {
              value: item,
              key: item,
              label: LOW_QUALITY_CRITERIES_NAME[item] || 'без штрафа',
            };
          })}
          id={generateLTDFineInputId({
            fineType: EDITABLE_FIELD.LOW_QUALITY_TYPE,
            editableField: EDITABLE_FIELD.PAYMENT_TYPE,
          })}
        />
      )}
      <ReactSelect
        readOnly={props.readOnly || IsChooseCriterie}
        highlight={appStore.getHighlight(`${props.fieldName}.payment_type`)}
        value={{
          label:
            (appStore.isRealTimeMode
              ? LTD_REAL_TIME_FINES_PAYMENT_NAME[props.conditions[props.fieldName].payment_type]
              : LTD_FINES_PAYMENT_NAME[props.conditions[props.fieldName].payment_type]) || 'без штрафа',
          value: props.conditions[props.fieldName].payment_type,
        }}
        onChange={(event) => {
          appStore.changeLTDConditions(props.fieldName, EDITABLE_FIELD.PAYMENT_TYPE, event.value);
        }}
        className="ltd__content-timer-select ltd-fine__select"
        options={typeFines.map((item) => {
          return {
            value: item,
            key: item,
            label:
              (appStore.isRealTimeMode ? LTD_REAL_TIME_FINES_PAYMENT_NAME[item] : LTD_FINES_PAYMENT_NAME[item]) ||
              'без штрафа',
          };
        })}
        id={generateLTDFineInputId({ fineType: props.fieldName, editableField: EDITABLE_FIELD.PAYMENT_TYPE })}
      />
    </div>
  );
});

Fine.propTypes = {
  conditions: PropTypes.any,
  fieldName: PropTypes.string,
  title: PropTypes.string,
};

const BankGuarantee = observer((props) => {
  const { appStore } = RootStore;
  const myArea = appStore.me.uuid === appStore.currentLTD.initiator;

  const isTrainingDisabledConditionsButton =
    trainingStore.launched &&
    trainingStore.currentStep &&
    trainingStore.currentStep.disabledElements?.includes('.ltd-bank-guarantee__conditions-button');

  return (
    <div className="ltd-bank-guarantee__wrapper">
      <div className="ltd-bank_guarantee__text">
        <span className="ltd-bank_guarantee__text-bold">Обеспечение </span>
        <span>
          (Стороны могут предоставить банковскую гарантию по договору. Вы можете предоставить банковскую гарантию в
          размере {appStore.LTDMyMaxBankGuarantee}
          <CoinIcon style={{ position: 'relative', top: '2px', marginLeft: '4px' }} />
          ):
        </span>
      </div>
      <div className="ltd-bank_guarantee__block-main">
        <div className="ltd-bank_guarantee__block-name--padding">
          <span className="ltd-bank_guarantee__text ltd-bank_guarantee__text-bold">{appStore.me.name}: </span>
        </div>
        <div className="ltd-bank_guarantee__block-input--padding">
          <NumberInput
            highlight={appStore.getHighlight(
              `${myArea ? EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE : EDITABLE_FIELD.PARTNER_BANK_GUARANTEE}.amount`,
            )}
            value={
              (myArea
                ? props.bankGuarantee[EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE].amount
                : props.bankGuarantee[EDITABLE_FIELD.PARTNER_BANK_GUARANTEE].amount) || ''
            }
            placeholder="0"
            onChange={(newValue) => {
              appStore.changeLTDBankGuarantee(
                myArea ? EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE : EDITABLE_FIELD.PARTNER_BANK_GUARANTEE,
                EDITABLE_FIELD.AMOUNT,
                newValue,
              );
            }}
            readOnly={props.readOnly}
          />
        </div>
        <div
          className={classNames({
            'ltd-bank_guarantee__block-input--padding': true,
            'ltd-bank-guarantee--active': props.readOnly,
          })}
        >
          <TextButton
            isDisabled={
              (myArea
                ? props.bankGuarantee[EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE].amount === 0
                : props.bankGuarantee[EDITABLE_FIELD.PARTNER_BANK_GUARANTEE].amount === 0) ||
              isTrainingDisabledConditionsButton
            }
            customClass={classNames({
              'ltd-bank-guarantee__conditions-button': true,
              'training-disabled': isTrainingDisabledConditionsButton,
            })}
            color={'green'}
            title={'Условия'}
            onClick={() => {
              appStore.openLTDBankGuaranteeModal({
                bankGuarantee: props.bankGuarantee,
                me: appStore.me,
                partner: props.partner,
              });
            }}
          />
        </div>
        <div className="ltd-bank_guarantee__block-name--padding">
          <span className="ltd-bank_guarantee__text ltd-bank_guarantee__text-bold">{props.partner.name}: </span>
        </div>
        <NumberInput
          highlight={appStore.getHighlight(
            `${myArea ? EDITABLE_FIELD.PARTNER_BANK_GUARANTEE : EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE}.amount`,
          )}
          value={
            (myArea
              ? props.bankGuarantee[EDITABLE_FIELD.PARTNER_BANK_GUARANTEE].amount
              : props.bankGuarantee[EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE].amount) || ''
          }
          placeholder="0"
          onChange={(newValue) => {
            appStore.changeLTDBankGuarantee(
              myArea ? EDITABLE_FIELD.PARTNER_BANK_GUARANTEE : EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE,
              EDITABLE_FIELD.AMOUNT,
              newValue,
            );
          }}
          readOnly={props.readOnly}
        />
      </div>
    </div>
  );
});

BankGuarantee.propTypes = {
  myArea: PropTypes.bool,
  conditions: PropTypes.any,
  fieldName: PropTypes.string,
};

const Termination = observer((props) => {
  const { appStore } = RootStore;

  return (
    <div className="ltd-bank-guarantee__wrapper">
      <div className="ltd-bank_guarantee__text">
        <span className="ltd-bank_guarantee__text-bold">
          Расторжение долгосрочной сделки (возможно по соглашению сторон){' '}
        </span>
      </div>
      <div className="ltd-bank_guarantee__text" style={{ paddingBottom: '0' }}>
        <span>одностороннее расторжение без указания причин возможно в случае уплаты другой стороне:</span>
      </div>
      <div className="ltd-bank_guarantee__block-main">
        <div className="ltd-bank_guarantee__block-name--padding">
          <NumberInput
            highlight={appStore.getHighlight('one_way_termination.value')}
            isPercent={
              ![LTD_TERMINATION.FIXED, LTD_TERMINATION.EMPTY].includes(props.termination[props.fieldName].payment_type)
            }
            className="ltd-fine__input"
            disabled={props.termination[props.fieldName].payment_type === LTD_TERMINATION.EMPTY}
            value={props.termination[props.fieldName].value || ''}
            placeholder="0"
            onChange={(newValue) => {
              appStore.changeLTDTermination(props.fieldName, EDITABLE_FIELD.VALUE, newValue);
            }}
            readOnly={props.readOnly}
          />
        </div>
        <ReactSelect
          highlight={appStore.getHighlight('one_way_termination.payment_type')}
          readOnly={props.readOnly}
          value={{
            label: LTD_TERMINATION_NAME[props.termination[props.fieldName].payment_type] || 'без условий расторжения',
            value: props.termination[props.fieldName].payment_type,
          }}
          onChange={(event) => {
            appStore.changeLTDTermination(props.fieldName, EDITABLE_FIELD.PAYMENT_TYPE, event.value);
          }}
          className="ltd__content-timer-select ltd-termination__select"
          options={Object.values(LTD_TERMINATION).map((item) => {
            return {
              value: item,
              key: item,
              label: LTD_TERMINATION_NAME[item] || 'без условий расторжения',
            };
          })}
          id={`${props.fieldName}__${EDITABLE_FIELD.PAYMENT_TYPE}`}
          menuPlacement={'top'}
        />
      </div>
    </div>
  );
});

Termination.propTypes = {
  termination: PropTypes.any,
  fieldName: PropTypes.string,
};

const LTD_ERROR = {
  NOT_CORRECT_DATA: 'Произошла ошибка, попробуйте создать сделку заново',
  BANK_GUARANTEE_SEASON: 'Неверный сезон банковской гарантии',
  BANK_GUARANTEE_AMOUNT: 'Банковская гарантия превышает доступную сумму',
  OFFER_BANK_GUARANTEE_AMOUNT: 'Банковская гарантия для оппонента превышает доступную сумму ',
  CONTENT_AMOUNT: 'Сумма всех платежей не совпадает с общей суммой за котят',
  TIMELINE: 'Неверные сезоны или таймеры для действий',
  TIMELINE_SEASON: 'Неверный сезон для действия',
  TIMELINE_TIMER: 'Неверное время для действия',
  TIMELINE_INTERVAL: 'Неверный интервал между действиями',
  FINE_AMOUNT: 'Неверная сумма для штрафа',
};

function LongTermsDeals() {
  const { appStore } = RootStore;

  const [errorText, setErrorText] = useState('');

  let defaultTypeTab = null;
  if (appStore.currentLTD) {
    if (appStore.currentLTD.type_ltd === LTD_CONTENT_TYPE.SELL || appStore.isSales) {
      defaultTypeTab = LTD_CONTENT_TYPE.SELL;
    } else {
      defaultTypeTab = LTD_CONTENT_TYPE.BUY;
    }
  }

  const [currentTypeTabLTD, setCurrentTypeTabLTD] = useState(defaultTypeTab);
  useEffect(() => {
    if (
      trainingStore.launched &&
      trainingStore.currentStep instanceof LTDStep &&
      trainingStore.currentStep.selectedLTDTab &&
      trainingStore.currentStep.selectedLTDTab !== defaultTypeTab
    ) {
      setCurrentTypeTabLTD(trainingStore.currentStep.selectedLTDTab);
    }
  }, [trainingStore.launched, trainingStore.currentStep, trainingStore.currentStep?.selectedLTDTab]);

  if (!appStore.currentLTD) {
    return null;
  }

  if (
    !currentTypeTabLTD ||
    (appStore.currentLTD.type_ltd === LTD_CONTENT_TYPE.SELL && currentTypeTabLTD === LTD_CONTENT_TYPE.BUY) ||
    (appStore.currentLTD.type_ltd === LTD_CONTENT_TYPE.BUY && currentTypeTabLTD === LTD_CONTENT_TYPE.SELL)
  ) {
    setCurrentTypeTabLTD(
      appStore.currentLTD.type_ltd === LTD_CONTENT_TYPE.SELL ? LTD_CONTENT_TYPE.SELL : LTD_CONTENT_TYPE.BUY,
    );
    return null;
  }
  const currentLTD = appStore.currentLTD;
  const currentTask = appStore.taskById(appStore.currentTaskId);
  const typeLTD = appStore.currentLTD.type_ltd;

  const captainUuid = appStore.playerByRealSpeciality(SPECIALITIES.CAPTAIN, appStore.currentLTDAreaNum).player_id;
  const myArea = appStore.me.uuid === currentLTD.initiator || captainUuid === currentLTD.initiator;
  let partner = '';
  if (myArea) {
    partner = appStore.ltdPartner(currentLTD.partner, currentLTD);
  } else {
    partner = appStore.ltdPartner(currentLTD.initiator, currentLTD);
  }

  if (!partner) {
    partner = appStore.ltdPartner(currentLTD.to, currentLTD);
  }

  const conditions = appStore.currentLTDConditions;
  const bankGuarantee = appStore.currentLTDBankGuarantee;
  const termination = appStore.currentLTDTermination;

  const canSalesUseLtd = appStore
    .playerByRealSpeciality(SPECIALITIES.SALES)
    .privileges.includes(PRIVILEGES.APPROVE_LTD_TASK);

  const canSupplyUseLtd = appStore
    .playerByRealSpeciality(SPECIALITIES.SUPPLY)
    .privileges.includes(PRIVILEGES.APPROVE_LTD_TASK);

  const captainApprovingBuySellRequest =
    !currentLTD?.deal_id &&
    appStore.isCaptain &&
    currentTask &&
    currentTask?.visas.sales === undefined &&
    currentTask?.visas.supply === undefined;

  const editableSellContentForCaptain =
    (!canSalesUseLtd || !currentTask?.specialty_by) &&
    currentTypeTabLTD === LTD_CONTENT_TYPE.SELL &&
    appStore.isCaptain &&
    currentTask?.specialty_by !== SPECIALITIES.MARKETEER;

  const editableBuyContentForCaptain =
    (!canSupplyUseLtd || !currentTask?.specialty_by) &&
    currentTypeTabLTD === LTD_CONTENT_TYPE.BUY &&
    appStore.isCaptain &&
    currentTask?.specialty_by !== SPECIALITIES.MARKETEER;

  const editableLtdBuySellConditions =
    !captainApprovingBuySellRequest &&
    !appStore.isMarketeer &&
    (currentLTD.status === LTD_STATUS.TERMS_AGREEMENT || currentTask?.status === TEAM_TASK_STATUS.IN_PROGRESS) &&
    ((currentTypeTabLTD === LTD_CONTENT_TYPE.SELL && appStore.isSales) ||
      (currentTypeTabLTD === LTD_CONTENT_TYPE.BUY && appStore.isSupply) ||
      editableSellContentForCaptain ||
      editableBuyContentForCaptain) &&
    (!appStore.meIsCEO || !currentTask?.specialty_by);

  const defaultEditableLtd =
    (!appStore.meIsCEO || !currentTask?.specialty_by) &&
    ((!currentLTD.deal_id &&
      ![SPECIALITIES.MARKETEER, SPECIALITIES.CAPTAIN, SPECIALITIES.CEO].includes(currentTask?.stage)) ||
      (currentLTD.status === LTD_STATUS.TERMS_AGREEMENT &&
        !appStore.isMarketeer &&
        (!currentTask?.specialty_by || !appStore.isCaptain) &&
        ((currentLTD.updater && currentLTD.updater !== appStore.me.uuid && currentLTD.updater !== captainUuid) ||
          (!currentLTD.updater && currentLTD.initiator !== appStore.me.uuid && currentLTD.initiator !== captainUuid))));

  const editableLTD =
    (!currentTask || currentTask.stage === appStore.mySpeciality) &&
    (typeLTD === LTD_CONTENT_TYPE.BUY_SELL &&
    appStore.withDelegationLtd &&
    !appStore.meIsCEO &&
    currentTask?.specialty_by
      ? editableLtdBuySellConditions
      : defaultEditableLtd);

  const partnerConfirmed =
    currentLTD.initiator !== appStore.me.uuid ? currentLTD.initiator_confirmed : currentLTD.partner_confirmed;

  const editableFinesBuySell =
    (currentTask ? currentTask?.stage === appStore.mySpeciality : !currentLTD.status) &&
    !captainApprovingBuySellRequest &&
    (!currentLTD.deal_id || currentLTD.status === LTD_STATUS.TERMS_AGREEMENT) &&
    !appStore.isMarketeer &&
    !currentTask?.visas.marketeer &&
    (!currentLTD.deal_id ||
      (currentLTD.updater && currentLTD.updater !== appStore.me.uuid && currentLTD.updater !== captainUuid) ||
      (!currentLTD.updater && currentLTD.initiator !== appStore.me.uuid && currentLTD.initiator !== captainUuid)) &&
    (!appStore.isCaptain || !canSupplyUseLtd || !canSalesUseLtd || !currentTask?.specialty_by);

  const finesReadonly =
    currentLTD.type_ltd === LTD_CONTENT_TYPE.BUY_SELL && appStore.withDelegationLtd
      ? !editableFinesBuySell
      : !editableLTD;

  const submitForm = () => {
    if (appStore.currentLTD.type_ltd === LTD_CONTENT_TYPE.BUY_SELL && !captainApprovingBuySellRequest) {
      if (!appStore.buyTotalPrice && !appStore.sellTotalPrice) {
        setErrorText('Не указаны цена или количество за котиков');
        return;
      } else if (!appStore.buyTotalPrice && !appStore.isSales) {
        setErrorText('Не указаны цена или количество за котиков для покупки');
        return;
      } else if (!appStore.sellTotalPrice && !appStore.isSupply) {
        setErrorText('Не указаны цена или количество за котиков для продажи');
        return;
      }
      if (
        appStore.currentLTDSellContent.prepaid.season < appStore.currentSeason + 1 ||
        appStore.currentLTDBuyContent.prepaid.season < appStore.currentSeason + 1
      ) {
        setErrorText('Для Аванса указан предыдущий сезон');
        return;
      }
    } else if (appStore.currentLTD.type_ltd === LTD_CONTENT_TYPE.BUY) {
      if (!appStore.buyTotalPrice) {
        setErrorText('Не указаны цена или количество за котиков для покупки');
        return;
      }
      if (appStore.currentLTDBuyContent.prepaid.season < appStore.currentSeason + 1) {
        setErrorText('Для Аванса указан предыдущий сезон');
        return;
      }
    } else if (appStore.currentLTD.type_ltd === LTD_CONTENT_TYPE.SELL) {
      if (!appStore.sellTotalPrice) {
        setErrorText('Не указаны цена или количество за котиков для продажи');
        return;
      }
      if (appStore.currentLTDSellContent.prepaid.season < appStore.currentSeason + 1) {
        setErrorText('Для Аванса указан предыдущий сезон');
        return;
      }
    }

    const maxTimerInSeason = 10000;
    const getTimerValue = appStore.isRealTimeMode
      ? (season, timer) => maxTimerInSeason * season + maxTimerInSeason + timer
      : (season, timer) => maxTimerInSeason * season + maxTimerInSeason - timer;

    if (
      _.intersection(
        [
          appStore.currentLTDBuyContent.prepaid.timer,
          appStore.currentLTDBuyContent.storage_timer,
          appStore.currentLTDBuyContent.shipping_timer,
          appStore.currentLTDBuyContent.payment_before_shipment.timer,
          appStore.currentLTDBuyContent.payment_after_shipment.timer,
        ],
        [null, 0],
      ).length &&
      !captainApprovingBuySellRequest &&
      !appStore.isSales
    ) {
      setErrorText('Неверные "сезон/минуты" для Покупки');
      return;
    }

    if (
      _.intersection(
        [
          appStore.currentLTDSellContent.prepaid.timer,
          appStore.currentLTDSellContent.storage_timer,
          appStore.currentLTDSellContent.shipping_timer,
          appStore.currentLTDSellContent.payment_before_shipment.timer,
          appStore.currentLTDSellContent.payment_after_shipment.timer,
        ],
        [null, 0],
      ).length &&
      !captainApprovingBuySellRequest &&
      !appStore.isSupply
    ) {
      setErrorText('Неверные "сезон/минуты" для Продажи');
      return;
    }

    if (
      appStore.currentLTD.type_ltd === LTD_CONTENT_TYPE.BUY_SELL ||
      appStore.currentLTD.type_ltd === LTD_CONTENT_TYPE.BUY
    ) {
      const buyTimerValues = [
        getTimerValue(appStore.currentLTDBuyContent.prepaid.season, appStore.currentLTDBuyContent.prepaid.timer),
        getTimerValue(appStore.currentLTDBuyContent.shipping_season, appStore.currentLTDBuyContent.storage_timer),
        getTimerValue(
          appStore.currentLTDBuyContent.payment_before_shipment.season,
          appStore.currentLTDBuyContent.payment_before_shipment.timer,
        ),
        getTimerValue(appStore.currentLTDBuyContent.shipping_season, appStore.currentLTDBuyContent.shipping_timer),
        getTimerValue(
          appStore.currentLTDBuyContent.payment_after_shipment.season,
          appStore.currentLTDBuyContent.payment_after_shipment.timer,
        ),
      ];
      if (
        !_.isEqual(
          _.clone(buyTimerValues),
          buyTimerValues.sort((a, b) => a - b),
        )
      ) {
        setErrorText('Неверные "сезон/минуты" для Покупки');
        return;
      }

      if (_.uniq(buyTimerValues).length !== buyTimerValues.length) {
        setErrorText('Неверные "сезон/минуты" для Покупки. Не может быть два действия в один сезон и минуту');
        return;
      }
    }
    if (
      appStore.currentLTD.type_ltd === LTD_CONTENT_TYPE.BUY_SELL ||
      appStore.currentLTD.type_ltd === LTD_CONTENT_TYPE.SELL
    ) {
      const sellTimerValues = [
        getTimerValue(appStore.currentLTDSellContent.prepaid.season, appStore.currentLTDSellContent.prepaid.timer),
        getTimerValue(appStore.currentLTDSellContent.shipping_season, appStore.currentLTDSellContent.storage_timer),
        getTimerValue(
          appStore.currentLTDSellContent.payment_before_shipment.season,
          appStore.currentLTDSellContent.payment_before_shipment.timer,
        ),
        getTimerValue(appStore.currentLTDSellContent.shipping_season, appStore.currentLTDSellContent.shipping_timer),
        getTimerValue(
          appStore.currentLTDSellContent.payment_after_shipment.season,
          appStore.currentLTDSellContent.payment_after_shipment.timer,
        ),
      ];
      if (
        !_.isEqual(
          _.clone(sellTimerValues),
          sellTimerValues.sort((a, b) => a - b),
        )
      ) {
        setErrorText('Неверные "сезон/минуты" для Продажи');
        return;
      }
      if (_.uniq(sellTimerValues).length !== sellTimerValues.length) {
        setErrorText('Неверные "сезон/минуты" для Продажи. Не может быть два действия в один сезон и минуту');
        return;
      }
    }

    if (
      appStore.currentLTDBuyContent.payment_before_shipment &&
      appStore.currentLTDBuyContent.payment_before_shipment.money +
        appStore.currentLTDBuyContent.prepaid.money +
        appStore.currentLTDBuyContent.payment_after_shipment.money !==
        appStore.buyTotalPrice
    ) {
      setErrorText('Сумма всех платежей по продаже не совпадает с общей суммой за котят');
      return;
    }

    if (
      appStore.currentLTDSellContent.payment_before_shipment &&
      appStore.currentLTDSellContent.payment_before_shipment.money +
        appStore.currentLTDSellContent.prepaid.money +
        appStore.currentLTDSellContent.payment_after_shipment.money !==
        appStore.sellTotalPrice
    ) {
      setErrorText('Сумма всех платежей по покупке не совпадает с общей суммой за котят');
      return;
    }

    const bankGuaranteeAmount = myArea
      ? currentLTD.initiator_bank_guarantee.amount
      : currentLTD.partner_bank_guarantee.amount;
    if (bankGuaranteeAmount > appStore.LTDMyMaxBankGuarantee) {
      setErrorText(`Банковская гарантия превышает доступную сумму ${appStore.LTDMyMaxBankGuarantee}`);
      return;
    }

    const incorrectQualityLiability =
      currentLTD.low_quality_liability.payment_type === LTD_FINES_PAYMENT.FIXED &&
      currentLTD.low_quality_liability.value > appStore.currentLTDMaxPossibleFine;

    const incorrectOverdueDeliveryLiability =
      currentLTD.overdue_delivery_liability.payment_type === LTD_FINES_PAYMENT.FIXED &&
      currentLTD.overdue_delivery_liability.value > appStore.currentLTDMaxPossibleFine;

    const incorrectOverduePaymentLiability =
      currentLTD.overdue_payment_liability.payment_type === LTD_FINES_PAYMENT.FIXED &&
      currentLTD.overdue_payment_liability.value > appStore.currentLTDMaxPossibleFine;

    const incorrectOneWayTermination =
      currentLTD.one_way_termination.payment_type === LTD_FINES_PAYMENT.FIXED &&
      currentLTD.one_way_termination.value > appStore.currentLTDMaxPossibleFine;

    if (
      incorrectOneWayTermination ||
      incorrectOverdueDeliveryLiability ||
      incorrectOverduePaymentLiability ||
      incorrectQualityLiability
    ) {
      setErrorText(LTD_ERROR.FINE_AMOUNT);
      return;
    }

    appStore.requestLTDFormation({ deal_id: currentLTD.deal_id, partner, task_id: currentTask?.task_id });
  };

  const openLTDArchiveModal = (actionType) => {
    if (
      !appStore.currentLTD.status &&
      !_.isEqual(appStore.currentLTD, appStore.LTDArchive(partner.uuid, typeLTD)) &&
      !appStore.isLTDDefaultValues &&
      !currentTask?.specialty_by
    ) {
      appStore.openLTDArchiveModal({
        partnerUuid: partner.uuid,
        actionType,
        type_ltd: typeLTD,
      });
      return true;
    }
    return false;
  };

  const onTabChange = (toTab) => {
    setCurrentTypeTabLTD(toTab);
    if (trainingStore.launched) {
      trainingStore.checkIsCorrectAction('onLTDTabChange', toTab);
    }
  };

  const disapproveTask = () => {
    if (!appStore.withDelegationLtd || (appStore.meIsCEO && !currentTask?.specialty_by)) {
      appStore.openLTDModalCancel({
        partnerInfo: partner,
        deal_id: currentLTD.deal_id,
        notification: LTD_NOTIFICATION.REJECTION_LTD,
        area_num: appStore.currentLTDAreaNum,
      });
    } else {
      appStore.sendMessageActionsOnTask(
        currentTask?.task_id,
        currentTask?.content,
        false,
        false,
        TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
      );

      RootStore.goTo(routes.main);
    }
  };

  const sendTaskToRevision = () => {
    appStore.sendMessageActionsOnTask(
      currentTask?.task_id,
      currentTask?.content,
      false,
      true,
      TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
    );

    RootStore.goTo(routes.main);
  };

  const canSendToRevision =
    [SPECIALITIES.MARKETEER, SPECIALITIES.CAPTAIN, SPECIALITIES.CEO].includes(appStore.mySpeciality) &&
    appStore.withDelegationLtd &&
    (currentTask?.visas.supply || currentTask?.visas.sales);

  const captainCanTerminate = appStore.isCaptain && appStore.myPrivileges.includes(PRIVILEGES.APPROVE_LTD_TASK);

  const showVisas = [SPECIALITIES.MARKETEER, SPECIALITIES.CAPTAIN].includes(appStore.mySpeciality);

  const visasEntries = Object.entries(currentTask?.visas ?? {}).filter(
    ([speciality]) => speciality !== SPECIALITIES.CAPTAIN && speciality !== appStore.mySpeciality,
  );

  const visas = (
    <div className="ltd__action--visas">
      {showVisas &&
        visasEntries.map(
          ([speciality, confirmed]) =>
            speciality !== appStore.mySpeciality && (
              <SightConfirmation key={speciality} isActive confirmed={confirmed}>
                {speciality === SPECIALITIES.MARKETEER && <TeamMarketIcon isActive />}
                {speciality === SPECIALITIES.SUPPLY && <TeamBuyerIcon isActive />}
                {speciality === SPECIALITIES.SALES && <TeamSellerIcon isActive />}
              </SightConfirmation>
            ),
        )}
    </div>
  );

  return (
    <>
      <Header
        background
        extra={
          appStore.meIsCEO ? (
            <span
              onClick={() => {
                const actionType = appStore.currentLTDLoadedFromArchive
                  ? LTD_ARCHIVE_ACTION_TYPE.UPDATE
                  : LTD_ARCHIVE_ACTION_TYPE.SAVE;
                const LTDArchiveModalWasOpened = openLTDArchiveModal(actionType);
                if (!LTDArchiveModalWasOpened) {
                  RootStore.goTo(routes.ceo);
                }
              }}
            >
              <AreaIcon fill="#C6A788" />
            </span>
          ) : (
            <span
              onClick={() => {
                if (currentTask?.status) {
                  RootStore.goTo(routes.main);
                  return;
                }

                const actionType = appStore.currentLTDLoadedFromArchive
                  ? LTD_ARCHIVE_ACTION_TYPE.UPDATE
                  : LTD_ARCHIVE_ACTION_TYPE.SAVE;
                const LTDArchiveModalWasOpened = openLTDArchiveModal(actionType);
                if (!LTDArchiveModalWasOpened) {
                  RootStore.goTo(routes.main);
                }
              }}
            >
              <HouseIcon style={{ fill: '#C6A788' }} />
            </span>
          )
        }
      />

      <div
        style={{
          background: `url(${containerBG}) center`,
        }}
        className="ltd"
      >
        <div className="ltd__wrap">
          <div className="ltd__head">
            <div className="ltd__head-info">
              <LTDShowcasePrices partner={partner} />
              <div className="ltd__head-info-wrapper">
                <div className="ltd__head-info-text ltd__head-info--right">
                  <div className="ltd__head-info-text-name ltd__head-info-text--justify">
                    <span> {partner.name}: </span>
                    {partnerConfirmed ? (
                      <StampSignedIcon className="ltd__stamp" />
                    ) : (
                      <StampNotSignedIcon className="ltd__stamp" />
                    )}
                  </div>
                </div>
                <div className="ltd__head-info-price--body">
                  {(typeLTD === LTD_CONTENT_TYPE.BUY_SELL || typeLTD === LTD_CONTENT_TYPE.BUY) && (
                    <div className="ltd__head-info-text ltd__head-info-text--indent">
                      <NumberInput
                        readOnly={true}
                        value={appStore.buyTotalPrice}
                        className="ltd__head-info-price-wrapper"
                      />
                      <div>Покупка</div>
                    </div>
                  )}
                  {(typeLTD === LTD_CONTENT_TYPE.BUY_SELL || typeLTD === LTD_CONTENT_TYPE.SELL) && (
                    <div className="ltd__head-info-text ltd__head-info-text--indent">
                      <NumberInput
                        readOnly={true}
                        value={appStore.sellTotalPrice}
                        className="ltd__head-info-price-wrapper"
                      />
                      <div>Продажа</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="ltd__layout">
            <div className="ltd__content">
              <div className="ltd__content-tabs">
                {typeLTD !== LTD_CONTENT_TYPE.SELL && (
                  <div
                    className={classNames({
                      'ltd__content-tab': true,
                      'ltd__content-tab--sell': true,
                      'ltd__content-tab--active': currentTypeTabLTD === LTD_CONTENT_TYPE.BUY,
                      'ltd__content-tab--disabled': appStore.isSales,
                    })}
                    onClick={() => {
                      onTabChange(LTD_CONTENT_TYPE.BUY);
                    }}
                  >
                    Покупка
                  </div>
                )}
                {typeLTD !== LTD_CONTENT_TYPE.BUY && (
                  <div
                    className={classNames({
                      'ltd__content-tab': true,
                      'ltd__content-tab--buy': true,
                      'ltd__content-tab--active': currentTypeTabLTD === LTD_CONTENT_TYPE.SELL,
                      'ltd__content-tab--disabled': appStore.isSupply,
                    })}
                    onClick={() => {
                      onTabChange(LTD_CONTENT_TYPE.SELL);
                    }}
                  >
                    Продажа
                  </div>
                )}
              </div>
              {/* ToDo если два LTDContent не нужно, то один бы и вовсе не рендерить */}
              <LTDContent
                readOnly={!editableLTD}
                currentTypeTabLTD={LTD_CONTENT_TYPE.BUY}
                hidden={currentTypeTabLTD === LTD_CONTENT_TYPE.SELL}
              />
              <LTDContent
                readOnly={!editableLTD}
                currentTypeTabLTD={LTD_CONTENT_TYPE.SELL}
                hidden={currentTypeTabLTD === LTD_CONTENT_TYPE.BUY}
              />
            </div>
            <div className="ltd__bank">
              <BankGuarantee
                readOnly={
                  captainApprovingBuySellRequest ||
                  (currentLTD.deal_id && currentLTD.status && currentLTD.status !== LTD_STATUS.TERMS_AGREEMENT) ||
                  (myArea && appStore.currentLTD.initiator_confirmed) ||
                  ![SPECIALITIES.MARKETEER, SPECIALITIES.CAPTAIN, SPECIALITIES.CEO].includes(appStore.mySpeciality) ||
                  (!appStore.meIsCEO && currentTask?.specialty_by === SPECIALITIES.MARKETEER) ||
                  (appStore.meIsCEO && !!currentTask?.specialty_by)
                }
                partner={partner}
                bankGuarantee={bankGuarantee}
              />
            </div>
            <div className="ltd__terminate">
              <Termination
                readOnly={finesReadonly}
                termination={termination}
                fieldName={EDITABLE_FIELD.ONE_WAY_TERMINATION}
              />
            </div>
            <div className="ltd__payment-fine">
              <Fine
                readOnly={finesReadonly}
                conditions={conditions}
                title="Штраф за просрочку оплаты"
                fieldName={EDITABLE_FIELD.OVERDUE_PAYMENT_LIABILITY}
              />
            </div>
            <div className="ltd__delivery-fine">
              <Fine
                readOnly={finesReadonly}
                conditions={conditions}
                title="штраф за просрочку поставки и недопоставку"
                fieldName={EDITABLE_FIELD.OVERDUE_DELIVERY_LIABILITY}
              />
            </div>
            <div className="ltd__quality-fine">
              <Fine
                readOnly={finesReadonly}
                conditions={conditions}
                title="штраф за качество"
                fieldName={EDITABLE_FIELD.LOW_QUALITY_LIABILITY}
              />
            </div>
            <div className="ltd__accept">
              {(!currentLTD.deal_id || currentLTD.updated) &&
                (!currentTask || currentTask?.stage === appStore.mySpeciality) && (
                  <div className="ltd__action--wrapper">
                    <div className="ltd__action--title">
                      <span>Отправить долгосрочную сделку контрагенту</span>
                      {visas}
                    </div>
                    <div className="ltd__accept__options">
                      {![SPECIALITIES.SUPPLY, SPECIALITIES.SALES].includes(appStore.mySpeciality) &&
                        (!!currentLTD.status || currentLTD.updated !== undefined) && (
                          <TextButton
                            title={<DislikeIcon style={{ fill: '#FDF6EE' }} />}
                            color="red"
                            customClass="ltd__action-btn"
                            onClick={disapproveTask}
                          />
                        )}
                      <TextButton
                        title={
                          !!currentLTD.status || currentLTD.updated !== undefined ? (
                            <LikeIcon style={{ fill: '#FDF6EE' }} />
                          ) : (
                            <SendIcon style={{ fill: '#FDF6EE' }} />
                          )
                        }
                        color="green"
                        customClass="ltd__action-btn"
                        onClick={submitForm}
                        isDisabled={appStore.isCurrentTurnPreliminary}
                      />
                      {canSendToRevision && (
                        <TextButton
                          title={<TeamReworkIcon />}
                          color="yellow"
                          customClass="ltd__action-btn"
                          onClick={sendTaskToRevision}
                        />
                      )}
                    </div>
                  </div>
                )}

              {currentLTD.deal_id &&
                currentLTD.status === LTD_STATUS.TERMS_AGREEMENT &&
                ((currentLTD.updater &&
                  (currentLTD.updater === appStore.me.uuid || currentLTD.updater === captainUuid)) ||
                  (!currentLTD.updater &&
                    (currentLTD.initiator === appStore.me.uuid || currentLTD.initiator === captainUuid))) && (
                  <div className="ltd__action--wrapper">
                    <div className="ltd__action--title">Отмена долгосрочной сделки</div>
                    <TextButton
                      title={'Отменить'}
                      color={'red'}
                      customClass="ltd__action-btn"
                      isDisabled={!appStore.meIsCEO && !appStore.isCaptain}
                      onClick={() =>
                        appStore.openLTDModalCancel({
                          partnerInfo: partner,
                          deal_id: currentLTD.deal_id,
                          notification: LTD_NOTIFICATION.CANCEL_LTD,
                          area_num: appStore.currentLTDAreaNum,
                        })
                      }
                    />
                  </div>
                )}

              {currentLTD.deal_id &&
                currentLTD.status === LTD_STATUS.TERMS_AGREEMENT &&
                ((currentLTD.updater &&
                  currentLTD.updater !== appStore.me.uuid &&
                  currentLTD.updater !== captainUuid) ||
                  (!currentLTD.updater &&
                    currentLTD.initiator !== appStore.me.uuid &&
                    currentLTD.initiator !== captainUuid)) && (
                  <div className="ltd__action--wrapper">
                    <div className="ltd__action--title">
                      <span>Согласовать сделку</span>
                      {visas}
                    </div>
                    <div className="ltd__accept__options">
                      <TextButton
                        title={<DislikeIcon style={{ fill: '#FDF6EE' }} />}
                        color={'red'}
                        customClass="ltd__action-btn"
                        onClick={disapproveTask}
                      />
                      <TextButton
                        title={<LikeIcon style={{ fill: '#FDF6EE' }} />}
                        color={'green'}
                        customClass="ltd__action-btn"
                        onClick={submitForm}
                      />
                      {canSendToRevision && (
                        <TextButton
                          title={<TeamReworkIcon />}
                          color="yellow"
                          customClass="ltd__action-btn"
                          onClick={sendTaskToRevision}
                        />
                      )}
                    </div>
                  </div>
                )}

              {currentLTD.status === LTD_STATUS.ACTIONS_PHASE && (
                <div className="ltd__action--wrapper">
                  <div className="ltd__action--title">
                    {!currentLTD.two_way_termination.payer
                      ? 'Расторжение долгосрочной сделки'
                      : (myArea && currentLTD.two_way_termination.initiator_confirmed) ||
                        (!myArea && currentLTD.two_way_termination.partner_confirmed)
                      ? 'Отменить расторжение долгосрочной сделки'
                      : 'Изменить условия расторжения'}
                  </div>
                  <TextButton
                    title={
                      !currentLTD.two_way_termination.payer
                        ? 'Расторжение'
                        : (myArea && currentLTD.two_way_termination.initiator_confirmed) ||
                          (!myArea && currentLTD.two_way_termination.partner_confirmed)
                        ? 'Отменить'
                        : 'Подробнее'
                    }
                    color={!currentLTD.two_way_termination.payer ? 'red' : 'blue'}
                    customClass="ltd__action-btn"
                    onClick={() => {
                      if (!currentLTD.two_way_termination.payer) {
                        appStore.openLTDTerminationModal({
                          partner: partner,
                          deal_id: currentLTD.deal_id,
                          currentLTD: currentLTD,
                          isConfirmed: false,
                        });
                      } else if (
                        (myArea && currentLTD.two_way_termination.initiator_confirmed) ||
                        (!myArea && currentLTD.two_way_termination.partner_confirmed)
                      ) {
                        appStore.LTDTerminationCancel(currentLTD.deal_id);
                      } else {
                        appStore.openLTDTerminationModal({
                          partner,
                          deal_id: currentLTD.deal_id,
                          currentLTD: currentLTD,
                          isConfirmed: true,
                        });
                      }
                    }}
                    isDisabled={
                      (appStore.isRealTeamMode && !appStore.isCaptain) ||
                      (appStore.isCorpMode && !(appStore.meIsCEO || captainCanTerminate))
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal title={errorText} isOpen={!!errorText}>
        <div className="modal__body-actions">
          <TextButton color="purple" title="понятно" onClick={() => setErrorText('')} />
        </div>
      </Modal>
    </>
  );
}

export default observer(LongTermsDeals);
