import React from 'react';
import Modal from './Modal/Modal';
import RootStore from '../../store';
import { observer } from 'mobx-react';
import { ROLE_NAME } from '../../store/constants';
import { LockIcon } from '../icons';
import TextButton from '../buttons/TextButton/TextButton';

const NotificationAboutBlockingModal = () => {
  const { appStore } = RootStore;

  const data = appStore.notificationAboutBlockingData;
  if (!data) {
    return null;
  }

  const { currentPlayer } = appStore;

  let title;
  let desc;
  if (appStore.currentCompanyBlockingInfo && data.blockedByMe) {
    title = 'Вы добавили в черный список';
    desc = `${ROLE_NAME[currentPlayer?.role]} "${currentPlayer?.name}"`;
  } else if (appStore.meIsBlockedByCurrentCompany && !data.blockedByMe) {
    title = 'Тебя добавили в черный список';
    desc = `В ${ROLE_NAME[currentPlayer?.role]}е "${currentPlayer?.name}"`;
  } else {
    appStore.hideYouAreBlockedModal();
    return;
  }

  return (
    <Modal
      isOpen={!!data}
      onClose={appStore.hideYouAreBlockedModal}
      title={<p className="user-list-modal-title">{title}</p>}
      desc={desc}
      colorBg="red"
    >
      <div className="modal__price modal__price--lock-icon">
        <LockIcon
          style={{
            fill: 'white',
          }}
        />
      </div>
      <div className="modal__body-actions">
        <TextButton color="blue" title="понятно" onClick={appStore.hideYouAreBlockedModal} />
      </div>
    </Modal>
  );
};

export default observer(NotificationAboutBlockingModal);
