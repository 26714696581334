import React from 'react';

function SicknessFleas({ style, className }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <g clipPath="url(#sicknessFleasIcon)">
        <path
          d="M18.9455 19H12.4551C12.3696 19 12.3003 19.0612 12.3003 19.1366V24.8634C12.3003 24.9388 12.3696 25 12.4551 25H18.9455C19.031 25 19.1003 24.9388 19.1003 24.8634V19.1366C19.1003 19.0612 19.031 19 18.9455 19Z"
          fill="inherit"
        />
        <path
          d="M9.24089 13H7.99983C7.52962 19.8086 7.64506 23.1915 7.99983 30C8.03122 30.6024 8.17528 30.8854 8.62036 31.0001C12.5 32 18.5 32 22.7871 31.0001C23.2542 30.8911 23.4827 30.5836 23.5 30C23.6982 23.3134 23.6543 19.9408 23.4076 13H9.24089ZM20.5904 25.2018C20.5904 25.8255 20.0348 26.3311 19.3494 26.3311H12.0581C11.3727 26.3311 10.817 25.8255 10.817 25.2018L10.8295 18.573C10.8295 17.9493 11.3851 17.4437 12.0705 17.4437H19.3494C20.0348 17.4437 20.5904 17.9493 20.5904 18.573V25.2018Z"
          fill="inherit"
        />
        <path
          d="M19.11 8.91872V6.26458C19.11 5.94663 18.8307 5.68884 18.4862 5.68884H17.7875V2.18837C17.7968 1.8705 17.5252 1.60595 17.1807 1.59738C17.1268 1.59602 17.0728 1.6012 17.0203 1.61264L14.0322 2.29776C13.7446 2.36038 13.544 2.60082 13.5519 2.8735V5.70612H12.8532C12.5087 5.70612 12.2294 5.9639 12.2294 6.28185V8.93599C9.11038 9.63839 8.20586 11.1929 8 11.665H23.3332C23.1273 11.1814 22.2103 9.62111 19.11 8.91872Z"
          fill="inherit"
        />
        <path
          d="M24.8236 3.17871H21.5879C21.27 3.17871 21.0122 3.4365 21.0122 3.75445C21.0122 4.0724 21.27 4.33018 21.5879 4.33018H24.8236C25.1415 4.33018 25.3993 4.0724 25.3993 3.75445C25.3993 3.4365 25.1415 3.17871 24.8236 3.17871Z"
          fill="inherit"
        />
        <path
          d="M25.0022 0.363392C24.8845 0.0676797 24.5494 -0.0766858 24.2537 0.0409801L21.375 1.19245C21.0793 1.30932 20.9343 1.64375 21.0512 1.93947C21.1382 2.15968 21.3512 2.30419 21.5881 2.30362C21.661 2.30383 21.7333 2.29009 21.8011 2.26332L24.6798 1.11185C24.9755 0.994253 25.1198 0.659104 25.0022 0.363392Z"
          fill="inherit"
        />
        <path
          d="M24.6681 6.38556L21.7894 5.2341C21.4937 5.11643 21.1586 5.2608 21.041 5.55651C20.9233 5.85222 21.0677 6.1873 21.3634 6.30496L24.2421 7.45643C24.5378 7.5741 24.8729 7.42973 24.9905 7.13402C25.1081 6.83831 24.9638 6.50323 24.6681 6.38556Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="sicknessFleasIcon">
          <rect width="32" height="32" fill="inherit" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SicknessFleas;
