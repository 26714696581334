import React from 'react';

function ChapterTwoIcon({ style, className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#CharterTwoIcon0)">
        <path
          d="M31.9725 14.4221C31.8452 13.8847 31.3064 13.5523 30.769 13.6795L28.0686 14.3191C27.6959 8.66773 22.5863 3.93382 16.0001 3.93382C9.7407 3.93382 4.32376 8.37318 3.93165 14.3191L1.23121 13.6795C0.693737 13.5523 0.155011 13.8847 0.0277042 14.4221C-0.0996026 14.9595 0.232882 15.4983 0.770296 15.6256L4.00184 16.391C4.15108 17.4913 4.47763 18.5535 4.96817 19.5499L2.54622 20.0412C2.00499 20.151 1.65519 20.6787 1.765 21.22C1.86118 21.6941 2.2781 22.0213 2.74396 22.0213C2.88814 22.0213 2.66584 22.0576 6.09824 21.3613C10.9387 27.6478 21.0729 27.6331 25.902 21.3613C29.3392 22.0585 29.1142 22.0213 29.2563 22.0213C29.7221 22.0213 30.139 21.6941 30.2352 21.22C30.345 20.6787 29.9952 20.151 29.454 20.0412L27.0321 19.5499C27.5226 18.5535 27.8492 17.4913 27.9984 16.391L31.2299 15.6256C31.7673 15.4983 32.0998 14.9595 31.9725 14.4221ZM10.5561 13.7622C10.5561 13.0774 11.1113 12.5222 11.7961 12.5222C12.481 12.5222 13.0361 13.0774 13.0361 13.7622C13.0361 14.4471 12.481 15.0023 11.7961 15.0023C11.1113 15.0023 10.5561 14.4471 10.5561 13.7622ZM18.5112 21.3448C17.5176 21.3448 16.815 21.4373 16.0002 20.8055C15.1888 21.4345 14.4762 21.3448 13.4892 21.3448C12.9369 21.3448 12.4892 20.8971 12.4892 20.3448C12.4892 19.7926 12.9369 19.3449 13.4892 19.3449H14.4266C14.7429 19.3449 15.0002 19.0876 15.0002 18.7713V18.6368L14.4337 17.8174C14.0461 17.2567 14.4474 16.4914 15.1291 16.4914H16.8713C17.553 16.4914 17.9543 17.2567 17.5667 17.8174L17.0002 18.6368V18.7713C17.0002 19.0876 17.2575 19.3449 17.5738 19.3449H18.5112C19.0635 19.3449 19.5112 19.7926 19.5112 20.3448C19.5112 20.8971 19.0634 21.3448 18.5112 21.3448ZM20.2042 15.0023C19.5193 15.0023 18.9642 14.4472 18.9642 13.7623C18.9642 13.0775 19.5193 12.5223 20.2042 12.5223C20.889 12.5223 21.4442 13.0775 21.4442 13.7623C21.4442 14.4471 20.889 15.0023 20.2042 15.0023Z"
          fill="#C6A788"
        />
        <path
          d="M11.4914 2.61501L8.61119 0.433669C7.21469 -0.623908 5.20959 0.373297 5.20959 2.12453V6.59146C6.92552 4.70579 9.10573 3.35904 11.4914 2.61501Z"
          fill="#C6A788"
        />
        <path
          d="M26.7902 6.59146V2.12453C26.7902 0.372672 24.7846 -0.62347 23.3886 0.433732L20.5084 2.61501C22.8944 3.35916 25.0745 4.70604 26.7902 6.59146Z"
          fill="#C6A788"
        />
        <path
          d="M6.73658 24.8529C5.00009 27 4.78973 28.7897 6.50006 30.5C8.50004 32.5 23.5002 32.5 25.5002 30.5C27.5002 28.5 26.9999 26.5 25.2638 24.8529C19.9687 29.1437 12.0371 29.1481 6.73658 24.8529Z"
          fill="#C6A788"
        />
      </g>
      <defs>
        <clipPath id="CharterTwoIcon0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ChapterTwoIcon;
