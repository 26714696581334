import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import IconButton from '../../buttons/IconButton/IconButton';
import classNames from '../../../common/classNames';
import DelegatingIcon from '../../icons/DelegatingIcon';

function HeaderUserListButton() {
  const { appStore } = RootStore;

  return (
    <IconButton
      customClass={classNames({
        'header__delegating-button': true,
      })}
      color="blue"
      onClick={!appStore.isCurrentTurnPreliminary && appStore.openDelegatingModal}
    >
      <DelegatingIcon style={{ width: '24px', height: '24px', display: 'block' }} color="#ffffff" />
    </IconButton>
  );
}

export default observer(HeaderUserListButton);
