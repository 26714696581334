import Modal from '../../Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import corpStore from '../../../../store/CorpStore';
import TextButton from '../../../buttons/TextButton/TextButton';
import CarIcon from '../../../icons/CarIcon';

function NotEnoughSeasonForTransportation() {
  const { notEnoughSeasonForTransportationModalData } = corpStore;

  if (!notEnoughSeasonForTransportationModalData) {
    return null;
  }

  return (
    <Modal
      colorBg="red"
      isOpen={!!notEnoughSeasonForTransportationModalData}
      onClose={() => corpStore.hideNotEnoughSeasonForTransportationModal()}
      title="Транспортировка не доступна на текущий сезон"
    >
      <CarIcon className="modal__body-icon" fill="#FDF6EE" />
      <div className="modal__body-actions">
        <TextButton
          color="blue"
          title="Понятно"
          onClick={() => corpStore.hideNotEnoughSeasonForTransportationModal()}
        />
      </div>
    </Modal>
  );
}

export default observer(NotEnoughSeasonForTransportation);
