import React from 'react';

function SecondPedestal({ style, stylePath, className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0648 17.4128L4.47086 21.9923C4.41208 22.1611 4.54886 22.3337 4.72666 22.3151L7.31242 22.0443C7.38466 22.0368 7.45647 22.0618 7.50841 22.1125L9.36723 23.9303C9.49502 24.0553 9.70942 24.0049 9.76816 23.8361L11.4548 18.9903C9.13647 18.9072 7.38616 18.2939 6.0648 17.4128Z"
        fill="#999490"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5449 18.9904L14.2316 23.8362C14.2903 24.005 14.5047 24.0553 14.6325 23.9304L16.4913 22.1126C16.5432 22.0619 16.615 22.0369 16.6872 22.0444L19.273 22.3152C19.4508 22.3338 19.5876 22.1612 19.5288 21.9924L17.9349 17.4131C16.6135 18.2941 14.8632 18.9073 12.5449 18.9904Z"
        fill="#999490"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00042 8.57632C2.99295 8.72711 2.98917 17.5 12 17.5C21.0108 17.5 21.007 8.72716 20.9996 8.57637C20.763 3.79987 16.8242 0 12 0C7.17576 0 3.23697 3.79987 3.00042 8.57632ZM10.1494 13.0686H13.9057C14.3607 13.0686 14.6877 12.3454 14.6148 12.2215C14.4713 11.9778 14.2075 11.8144 13.9058 11.8144H11.2592C11.2686 11.8025 11.2781 11.7908 11.2878 11.7792L13.9308 8.63189C14.5066 7.94622 14.6391 7.00846 14.2764 6.18452C13.9191 5.37272 13.1532 4.84986 12.2772 4.82009C12.2485 4.8191 12.2197 4.8186 12.1907 4.8186C10.7908 4.8186 9.65186 5.96621 9.65186 7.37668C9.65186 7.83515 10.0207 8.20675 10.4757 8.20675C10.9307 8.20675 11.2996 7.8351 11.2996 7.37668C11.2996 6.88155 11.6993 6.47874 12.1907 6.47874C12.2013 6.47874 12.2118 6.47892 12.2219 6.47928C12.5717 6.49119 12.7219 6.74775 12.7702 6.85747C12.81 6.94776 12.9203 7.26507 12.673 7.55955L10.03 10.7068C9.696 11.1046 9.47233 11.5818 9.37731 12.0882C9.34306 12.2706 9.69439 13.0686 10.1494 13.0686Z"
        fill="#999490"
      />
    </svg>
  );
}

export default SecondPedestal;
