import RootStore from '../store';

/**
 * @param quarter - квартал
 * @param reference_time - передается для расчета имеющегося кредита
 * @param gameIsFractionalAndPayoutInLatestSeason - Проверка на то что в игре последний сезон дробный и выплата будет в последний сезон
 * @returns {number} - возвращает UNIX timestamp
 */

export const calcTimeCreditPayment = ({ quarter, reference_time, gameIsFractionalAndPayoutInLatestSeason }) => {
  // UNIX timestamp
  const { appStore } = RootStore;
  let timeReferenceCalcCredit;
  // Время для последнего дробного сезона
  const timeForLastFractionalSeason = gameIsFractionalAndPayoutInLatestSeason ? _.last(appStore.turnDurationsSec) : 0;
  if (!!reference_time) {
    // Время взятие кредита (имеющегося)
    // Расчет времени выплаты = текущее время + время месяца * квартал расчета * 3 (1 квартал === 3 месяца)
    timeReferenceCalcCredit =
      reference_time - appStore.sessionStartTime + appStore.monthDuration * quarter + timeForLastFractionalSeason;
  } else {
    timeReferenceCalcCredit = appStore.monthDuration * quarter;
  }
  return timeReferenceCalcCredit;
};

/**
 * @param time - принимает время в UNIX timestamp
 * @returns {string} - возвращает разбивку времени в конвертации "HH:mm:ss"
 */

export const calcTimeCreditPaymentText = (time) => {
  // Расчет HH:mm:ss
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor(time / 60) - hours * 60;
  const seconds = time % 60;

  return [
    ...(hours ? [hours.toString().padStart(2, '0')] : []),
    ...[minutes.toString().padStart(2, '0')],
    ...[seconds.toString().padStart(2, '0')],
  ].join(':');
};
