import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import routes from '../../routes';
import classNames from '../../common/classNames';
import corpStore from '../../store/CorpStore';

function Airport() {
  return (
    <div className="pet-shops-road">
      <div className="airport__wrapper">
        <div
          className={classNames({
            airport__img: true,
            [`airport__img--${RootStore.appStore.currentSeasonType}`]: true,
            'airport__img--hidden': !corpStore.myActiveCompanies.length,
          })}
          onClick={() => RootStore.goTo(routes.transfer)}
        />
      </div>
    </div>
  );
}

export default observer(Airport);
