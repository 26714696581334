import React from 'react';

function ShowModalIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 0H21C22.654 0 24 1.34601 24 3V21C24 22.654 22.654 24 21 24H11.25V21.9999H21C21.5521 21.9999 21.9999 21.5521 21.9999 21V3C21.9999 2.44794 21.5521 2.00006 21 2.00006H3C2.44794 2.00006 2.00006 2.44794 2.00006 3V12.75H0V3C0 1.34601 1.34601 0 3 0Z"
        fill="#FDF6EE"
      />
      <path d="M3 24C1.34601 24 0 22.654 0 21V15H2.00006H7.5C8.328 15 9 15.672 9 16.5V21.9999V24H3Z" fill="#FDF6EE" />
      <path
        d="M18.9997 3.99994H12.9997C12.5958 3.99994 12.2297 4.24292 12.0757 4.617C11.9208 4.99109 12.0067 5.42102 12.2927 5.70703L14.5858 8.00006L10.2928 12.293C9.90173 12.6841 9.90173 13.316 10.2928 13.7069C10.4877 13.9019 10.7438 14.0001 10.9998 14.0001C11.2558 14.0001 11.5118 13.9019 11.7068 13.7069L15.9997 9.414L18.2927 11.707C18.4837 11.898 18.7397 12 18.9997 12C19.1288 12 19.2588 11.976 19.3828 11.924C19.7568 11.77 19.9998 11.404 19.9998 11.0001V5.00006C19.9998 4.44708 19.5527 3.99994 18.9997 3.99994Z"
        fill="#FDF6EE"
      />
    </svg>
  );
}

export default ShowModalIcon;
