import React from 'react';
import DragableItem from '../../../modules/deal/DragableItem';
import RootStore from '../../../store';
import trainingStore from '../../../store/trainingStore';
import { observer } from 'mobx-react';
import useDoubleClick from 'use-double-click';
import classNames from '../../../common/classNames';
import EatIcon from '../../../ui/icons/EatIcon';
import HelpIcon from '../../icons/HelpIcon';
import MagnifierIcon from '../../icons/MagnifierIcon';
import { DRAG_AREA, DRAG_BUTTON_TYPE, DRAGGABLE_ITEM_TYPE } from '../../../common/constants';

function MagnifierBtn({ checkActions }) {
  const { appStore } = RootStore;
  let drugsCount = 0;
  const drugs = appStore.goods.drugs;
  if (appStore.isTeamMode) {
    for (let key in drugs) {
      drugsCount += drugs[key];
    }
  }
  return (
    <DragableItem
      type={DRAGGABLE_ITEM_TYPE.BUTTON}
      from={DRAG_AREA.BUTTONS}
      data={{ buttonType: DRAG_BUTTON_TYPE.MAGNIFIER }}
      draggableOptions={{
        canDrag: (data, from, element) => {
          return true;
        },
      }}
      element={(isDragging, dragRef) => {
        return (
          <p
            ref={dragRef}
            onClick={() => {
              if (!appStore.isTeamMode) {
                if (appStore.magnifierCats.length) {
                  appStore.setCatUnderMagnifierIndex(0);
                }
              }
            }}
            className={classNames({
              'own-nurseries__actions-item': true,
              'own-nurseries__actions-item--magnifier': true,
              'own-nurseries__actions-item--hidden':
                trainingStore.launchedChapter > 0 && trainingStore.launchedChapter < 8,
              'own-nurseries__actions-item--disabled': !checkActions(),
            })}
          >
            <MagnifierIcon stylePath={checkActions() ? {} : { fill: '#C6A788' }} />
            {appStore.isTeamMode && <div className="treatment-btn__quantity">{drugsCount}</div>}
          </p>
        );
      }}
    />
  );
}

export default observer(MagnifierBtn);
