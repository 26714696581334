import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import CatFaceIcon from '../../icons/CatFaceIcon';
import IconButton from '../../buttons/IconButton/IconButton';
import classNames from '../../../common/classNames';

function HeaderUserListButton() {
  const { appStore } = RootStore;

  const channelsCount = appStore.players.filter((p) => !!p.notReadedMessage).length;
  return (
    <IconButton
      customClass={classNames({
        'header__user-list-button': true,
        'user-list-on-top': appStore.endTurnFlag,
      })}
      color="blue"
      onClick={appStore.openUserList}
    >
      <CatFaceIcon style={{ width: '24px', height: '24px', display: 'block' }} />
      {!!channelsCount && <span className="header__user-list-button-channels-count">{channelsCount}</span>}
    </IconButton>
  );
}

export default observer(HeaderUserListButton);
