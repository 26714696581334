import React, { useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from './../../store';
import trainingStore from '../../store/trainingStore';
import Modal from './Modal/Modal';
import classNames from '../../common/classNames';
import TextButton from '../buttons/TextButton/TextButton';
import TakenCredit from './Credit/TakenCredit';
import CoinIcon from '../icons/CoinIcon';
import DividingArrow from '../icons/modal/DividingArrow';
import CreditField from './Credit/CreditField';
import './CreditModal.scss';
import calcNextPaymentsOnCredits from '../../common/calcNextPaymentsOnCredits';
import { calcTimeCreditPaymentText } from '../../common/calcTimeForCredit';

const textForCreditPayment = (payment) => {
  const { appStore } = RootStore;
  let text = 'выплат в текущий сезон:';
  if (appStore.isRealTimeMode) {
    if (payment) {
      text = `выплата в ${calcTimeCreditPaymentText(payment.timestamp)}:`;
    } else {
      text = 'текущие выплаты:';
    }
  }
  return text;
};

const CreditInfoBlock = observer(({ isLastSeason }) => {
  const { appStore } = RootStore;
  const availableSum = appStore.credits.available_credit;
  let currentSeasonPayment = calcNextPaymentsOnCredits({
    credits: appStore.activeCreditBodies,
    currentSessionTimeInSeconds: !appStore.isRealTimeMode ? '' : appStore.timerValue.$ms / 1000,
  });

  return (
    <div className="credit-info">
      <div className="credit-info__info-block">
        <div className="credit-info__info-text">доступен кредит:</div>
        <div className="credit-info__input credit-info__input--available-credit">
          <CoinIcon customClass="modal__credit-coin" alt="Монетка" />
          <DividingArrow />
          <div className="credit-info__input-value notranslate">{!isLastSeason ? availableSum : 0}</div>
        </div>
      </div>
      <div className="credit-info__info-block">
        <div className="credit-info__info-text">{textForCreditPayment(currentSeasonPayment)}</div>
        <div className="credit-info__input credit-info__input--season-pay">
          <CoinIcon customClass="modal__credit-coin" alt="Монетка" />
          <DividingArrow />
          <div className="credit-info__input-value credit-info__input-value--current-season-pay notranslate">
            {currentSeasonPayment?.payment || 0}
          </div>
        </div>
      </div>
    </div>
  );
});

const FieldCreditBlock = ({ isLastSeason }) => {
  const { appStore } = RootStore;
  const currentSeason = appStore.currentSeason + 1;
  let actualCreditsList = appStore.credits.credit_percentages;

  return (
    <>
      <CreditInfoBlock isLastSeason={isLastSeason} />
      {!isLastSeason ? (
        <div className="available-credit__credit-blocks">
          {actualCreditsList.map((item) => (
            <CreditField key={item.credit_type} item={item} readOnly={false} />
          ))}
        </div>
      ) : (
        <div className="credit-tab__credit-blocks--empty credit-tab__credit-blocks--empty-min">
          <p>
            {currentSeason} {!appStore.isRealTimeMode ? 'сезон' : 'квартал'} является последним, взять кредит нельзя
          </p>
        </div>
      )}
    </>
  );
};

const TakenCreditBlock = observer(({ isLastSeason }) => {
  const { appStore } = RootStore;
  const sortedCredits = appStore.groupedByTypeCreditBodies;
  const isActiveCredits = appStore.activeCreditBodies.length;

  return (
    <>
      {!!isActiveCredits ? (
        <>
          <CreditInfoBlock isLastSeason={isLastSeason} />
          <div className="taken-credit__credit-blocks">
            {Object.keys(sortedCredits).map((creditType) => (
              <TakenCredit key={creditType} creditType={creditType} sortedItems={sortedCredits[creditType]} />
            ))}
          </div>
        </>
      ) : (
        <div className="credit-tab__credit-blocks--empty">
          <p>На данный момент нет активных кредитов</p>
        </div>
      )}
    </>
  );
});

function CreditModal() {
  const { appStore } = RootStore;
  const [availableCreditTabIsOpen, setAvailableCreditTabIsOpen] = useState(true);
  if (!appStore.creditModalFlag) {
    return null;
  }
  const isLastSeason = appStore.currentSeason + 1 === appStore.credits.last_season;

  return (
    <Modal
      isOpen={!!appStore.creditModalFlag}
      onClose={() => {
        appStore.hideCreditModal();
        setAvailableCreditTabIsOpen(true);
      }}
      size="huge"
      customClass="credit"
      shouldCloseOnOverlayClick={!trainingStore.currentStep}
      title={
        <>
          <div
            className={classNames({
              'credit-tab__available-credit': true,
              'credit-tab__available-credit--active': availableCreditTabIsOpen,
            })}
            onClick={() => {
              setAvailableCreditTabIsOpen(true);
            }}
          >
            <div className="credit-tab__text">информация по доступным кредитам</div>
          </div>
          <div
            className={classNames({
              'credit-tab__taken-credit': true,
              'credit-tab__taken-credit--active': !availableCreditTabIsOpen,
            })}
            onClick={() => {
              setAvailableCreditTabIsOpen(false);
              trainingStore.checkIsCorrectAction('takenCreditTab');
            }}
          >
            <div className="credit-tab__text">данные по взятым кредитам</div>
          </div>
        </>
      }
    >
      <div className="credit-modal">
        {availableCreditTabIsOpen ? (
          <FieldCreditBlock isLastSeason={isLastSeason} />
        ) : (
          <TakenCreditBlock isLastSeason={isLastSeason} />
        )}
        <TextButton
          title="ВЕРНУТЬСЯ"
          color="blue"
          customClass="credit-back-button"
          onClick={() => {
            appStore.hideCreditModal();
            setAvailableCreditTabIsOpen(true);
          }}
        />
      </div>
    </Modal>
  );
}

export default observer(CreditModal);
