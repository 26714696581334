import { NURSERY_PLAYERS_NAMES, SHOP_PLAYERS_NAMES } from '../store/playersNames';
import SHOP_AVATARS from '../ui/icons/shopsAvatar';
import NURSERY_AVATARS from '../ui/icons/nurseriesAvatar';
import { getNurseriesImages, getPetShopsImages } from './houseStatic';
import { PLAYER_ROLE } from './constants';

const COORDINATES = {
  TYPE_1: { left: '26%', top: '25%', width: '45%', height: '10%' },
  TYPE_2: { left: '28%', top: '29%', width: '40%', height: '9%' },
  TYPE_3: { left: '29%', top: '24%', width: '40%', height: '9%' },
};

const getCoordinatesForName = (index) => {
  // ↓ распределение координирования ↓
  // type1 для индекса 0, 3, 6, 9, 12, 15, 18
  // type2 для индекса 2, 5, 8, 11, 14, 17, 19
  // type3 для индекса 1, 4, 7, 10, 13, 16
  if (index % 3 === 2 || index === 19) {
    return COORDINATES.TYPE_2;
  } else if (index % 3 === 0) {
    return COORDINATES.TYPE_1;
  } else if (index % 3 === 1) {
    return COORDINATES.TYPE_3;
  }
};

const getStaticsForPlayers = ({ players, areaNum = null }) => {
  const petShopsImages = getPetShopsImages();
  const nurseriesImages = getNurseriesImages();

  const shops = players
    .filter((player) => player.role === PLAYER_ROLE.SHOP)
    .sort((player) => (player.ai_type === '5-th_shop' ? 1 : 0)) // если есть саппорт-бот, то ставлю его в конец массива
    .map((player, index) => {
      if (player.ai_type === '5-th_shop') {
        index = 4;
      }
      return {
        ...player,
        name: SHOP_PLAYERS_NAMES[index],
        logo: SHOP_AVATARS[index],
        src: petShopsImages[index]['1x'],
        srcSet: petShopsImages[index]['2x'] + ' 2x',
        coord: getCoordinatesForName(index),
        areaNum: areaNum,
      };
    });
  const nurseries = players
    .filter((player) => player.role === PLAYER_ROLE.NURSERY)
    .map((player, index) => {
      return {
        ...player,
        name: NURSERY_PLAYERS_NAMES[index],
        logo: NURSERY_AVATARS[index],
        src: nurseriesImages[index]['1x'],
        srcSet: nurseriesImages[index]['2x'] + ' 2x',
        coord: getCoordinatesForName(index),
        areaNum: areaNum,
      };
    });

  return [...shops, ...nurseries];
};

export default getStaticsForPlayers;
