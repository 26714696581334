import React from 'react';

function DoctorIcon({ style }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g filter="url(#filter0_di_30_79)">
        <rect width="16" height="16" rx="8" fill="#1296F5" />
      </g>
      <path
        d="M8.34629 4.70444C8.82924 4.31335 9.44777 4.13013 10.0658 4.19509C10.6838 4.26005 11.2508 4.56786 11.6418 5.05081C12.0329 5.53376 12.2161 6.15229 12.1512 6.77033C12.0862 7.38836 11.7784 7.95528 11.2955 8.34637L7.65354 11.2955C7.41441 11.4892 7.13947 11.6338 6.84444 11.7212C6.5494 11.8086 6.24004 11.8371 5.93402 11.8049C5.31599 11.7399 4.74907 11.4321 4.35798 10.9492C3.9669 10.4662 3.78368 9.84769 3.84864 9.22966C3.9136 8.61162 4.22141 8.0447 4.70436 7.65361L8.34629 4.70444ZM9.31373 9.08988L10.8736 7.82613C11.2164 7.54614 11.4343 7.14177 11.4796 6.70151C11.525 6.26124 11.3941 5.82094 11.1156 5.47694C10.8371 5.13294 10.4337 4.91326 9.99367 4.86597C9.55361 4.81868 9.11273 4.94763 8.76752 5.22461L7.20686 6.48962L9.31366 9.09054L9.31373 9.08988ZM7.703 9.88988C7.77208 9.83394 7.81611 9.75286 7.8254 9.66446C7.83469 9.57606 7.80848 9.48759 7.75255 9.41851C7.69661 9.34944 7.61552 9.30541 7.52712 9.29612C7.43873 9.28683 7.35026 9.31304 7.28118 9.36897L6.17757 10.2627C6.14336 10.2904 6.11495 10.3245 6.09395 10.3632C6.07295 10.4019 6.05977 10.4443 6.05517 10.4881C6.05057 10.5319 6.05464 10.5761 6.06714 10.6183C6.07964 10.6605 6.10033 10.6998 6.12802 10.734C6.18396 10.8031 6.26505 10.8471 6.35345 10.8564C6.39722 10.861 6.44146 10.857 6.48366 10.8445C6.52586 10.832 6.56518 10.8113 6.59939 10.7836L7.703 9.88988Z"
        fill="#074E81"
      />
      <defs>
        <filter
          id="filter0_di_30_79"
          x="0"
          y="0"
          width="17"
          height="17"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_79" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_79" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_30_79" />
        </filter>
      </defs>
    </svg>
  );
}

export default DoctorIcon;
