import React from 'react';

function CheckMarkIcon({ fill = '#C6A788', classname, style, width = '40', height = '24' }) {
  return (
    <svg
      className={classname}
      width={width}
      style={style}
      height={height}
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9511 14.2159C14.3521 13.647 16.7634 10.4404 19.3094 7.09057C21.8554 3.74076 24.3054 1 24.7539 1C25.6973 1 30.9842 6.60833 31 7.62607C31.0213 8.96871 15.1161 31 14.1257 31C13.0868 31 1 14.7456 1 13.3481C1 12.4124 5.40287 7.79245 6.29447 7.79245C6.63278 7.79245 8.33029 9.47076 10.066 11.5215C12.9836 14.9693 13.2766 15.1725 13.9511 14.2159Z"
        fill="inherit"
      />
    </svg>
  );
}

export default CheckMarkIcon;
