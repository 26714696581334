import { SOCKET_READY_STATE, SOCKET_READY_STATE_BY_STATUS } from './constants';

const registerOnSocketMessageEvent = ({
  socket,
  onmessage = () => {},
  onopen = () => {},
  onclose = () => {},
  onerror = () => {},
}) => {
  if (socket || SOCKET_READY_STATE_BY_STATUS[socket.readyState] !== SOCKET_READY_STATE.OPEN) {
    return;
  }

  socket.onopen = (event) => {
    console.log('onopen', event);
    onopen(event);
  };

  socket.onclose = (event) => {
    console.log('onclose', event);
    onclose(event);
  };

  socket.onerror = (event) => {
    console.error('onerror', event);
    onerror(event);
  };

  socket.onmessage = async (event) => {
    const reader = new FileReader();
    reader.onload = () => {
      const data = JSON.parse(reader.result);
      onmessage(data);
    };
    reader.readAsText(event.data);
    onmessage(event.data);
  };
};

export default registerOnSocketMessageEvent;
