import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarBoneImage from '../../../assets/nurseryAvatars/avatarBone.png';

function AvatarBone({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <path
    //     d="M26.4032 20.0519H26.4009C25.5167 19.1606 24.1238 19.0314 23.0917 19.745L16.2789 12.9114C17.0874 11.7207 16.781 10.0977 15.5943 9.28645C14.4076 8.47508 12.7902 8.78264 11.9816 9.97329C11.6778 10.4207 11.5202 10.9518 11.5307 11.493C10.8228 11.4723 10.1378 11.7458 9.63744 12.2486C8.59917 13.2439 8.56163 14.8953 9.55356 15.9371C10.4484 16.8769 11.8962 17.0124 12.9489 16.2549L19.7595 23.0885C18.9509 24.2791 19.2573 25.9021 20.444 26.7134C21.6307 27.5247 23.2482 27.2172 24.0567 26.0265C24.3605 25.5791 24.5181 25.048 24.5076 24.5068C25.9432 24.5396 27.1335 23.3985 27.1662 21.958C27.1824 21.2447 26.9066 20.5559 26.4032 20.0519Z"
    //     fill="#02516C"
    //   />
    // </svg>
    <Avatar style={style} avatarImage={AvatarBoneImage} />
  );
}

export default AvatarBone;
