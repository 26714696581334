import React from 'react';

function ArrowIcon({ style, width = 32, height = 32, color = '#C6A788', top = true }) {
  return !top ? (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M15.9891 31C14.549 31 9.03041 24.8754 4.76879 18.5478C3.41379 16.5357 3.68675 16.2419 6.91025 16.2419H9.87654L10.1319 14.5124C10.2721 13.561 10.6084 10.8114 10.879 8.40165C11.1497 5.99193 11.4791 3.34608 11.6109 2.52147L11.8505 1.0226H15.7249C20.2368 1.0226 19.6845 0.174929 20.835 8.86284C21.9036 16.9291 21.5155 16.2419 25.0018 16.2419C26.7547 16.2419 28 16.4398 28 16.7178C28 18.6959 17.6522 31 15.9891 31Z"
        fill={color}
      />
    </svg>
  ) : (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M15.9891 1C14.549 1 9.03041 7.12461 4.76879 13.4522C3.41379 15.4643 3.68675 15.7581 6.91025 15.7581H9.87654L10.1319 17.4876C10.2721 18.439 10.6084 21.1886 10.879 23.5984C11.1497 26.0081 11.4791 28.6539 11.6109 29.4785L11.8505 30.9774H15.7249C20.2368 30.9774 19.6845 31.8251 20.835 23.1372C21.9036 15.0709 21.5155 15.7581 25.0018 15.7581C26.7547 15.7581 28 15.5602 28 15.2822C28 13.3041 17.6522 1 15.9891 1Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowIcon;
