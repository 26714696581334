import React from 'react';

function AlabugaIcon() {
  return (
    <svg
      className="alabuga-icon"
      width="129"
      height="32"
      viewBox="0 0 129 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#AlabugaIconClip)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6655 15.8386C9.20281 17.4364 7.651 19.1812 6.2027 20.9054C3.72616 18.9034 2.38377 18.0344 0 16.5405C1.34213 14.7958 2.82148 13.0314 4.39797 11.2989C6.95036 12.5589 8.66362 14.0714 10.6595 15.8333L10.6595 15.8333L10.6655 15.8386ZM10.9761 15.5C14.2507 11.9384 17 9.18919 17 9.18919C17 9.18919 19.712 11.9012 22.8779 15.3992C24.9072 13.6122 26.6519 12.2555 29.24 10.9652C27.5381 9.08633 25.7758 7.28036 23.8898 5.54735C21.4283 6.91139 19.9245 8.08416 17.8469 10.0454L17.5315 9.71132C19.5933 7.76499 21.1098 6.57535 23.5319 5.22073C21.5221 3.39901 19.3697 1.65877 17 0C14.7753 1.56555 12.5505 3.36753 10.4 5.31053C12.7454 6.62454 14.447 7.80298 16.4685 9.71132L16.1531 10.0454C14.1266 8.13235 12.4314 6.96699 10.0414 5.6365C8.19362 7.32586 6.40416 9.11621 4.72061 10.9463C7.26344 12.2221 8.99366 13.7497 10.9522 15.4788L10.9761 15.5ZM23.1858 15.7403C25.2272 13.943 26.9588 12.6017 29.5596 11.3197C31.0696 13.002 32.5348 14.7423 34 16.5405C31.3981 18.2394 30.04 19.2448 27.7973 21.1351C26.3389 19.3121 24.7184 17.4428 23.1858 15.7403ZM21.5946 26.6486C19.713 28.4803 18.7255 29.6169 17 31.7027C15.2745 29.6169 14.287 28.4803 12.4054 26.6486C14.0135 24.3513 14.9835 23.1098 17 21.1351C19.0165 23.1098 19.9865 24.3513 21.5946 26.6486Z"
          fill="white"
        />
        <path
          d="M57.4445 20.5283C57.5446 20.5283 57.7448 20.5283 57.8448 20.4277V22.9434C57.3445 23.1447 56.5438 23.3459 55.8433 23.3459C54.6424 23.3459 53.6416 22.9434 53.1412 22.1384C52.0403 22.9434 50.8394 23.5472 49.2382 23.5472C47.0365 23.5472 45.3351 22.239 45.3351 20.0252C45.3351 17.2075 48.0372 16.3019 50.2389 16.3019C51.0396 16.3019 51.9403 16.4025 52.6408 16.6038V16.2013C52.6408 15.3962 51.9403 14.7925 50.339 14.7925C48.8379 14.7925 47.5369 15.195 46.436 15.6981V12.3774C47.3367 12.0755 49.1381 11.673 50.8394 11.673C54.4422 11.673 56.744 12.8805 56.744 16.9057V20.0252C56.744 20.327 57.1443 20.5283 57.4445 20.5283ZM50.9395 20.7296C51.64 20.7296 52.1404 20.6289 52.7409 20.1258V18.6164C52.3406 18.5157 51.8402 18.4151 51.3398 18.4151C50.4391 18.4151 49.5384 18.717 49.5384 19.6226C49.5384 20.1258 50.0388 20.7296 50.9395 20.7296Z"
          fill="white"
        />
        <path d="M62.7486 5.83648V23.044H58.7455V5.83648H62.7486Z" fill="white" />
        <path
          d="M75.9589 20.5283C76.059 20.5283 76.2591 20.5283 76.3592 20.4277V22.9434C75.8588 23.1447 75.0582 23.3459 74.3576 23.3459C73.1567 23.3459 72.1559 22.9434 71.6555 22.1384C70.5547 22.9434 69.3538 23.5472 67.7525 23.5472C65.5508 23.5472 63.8495 22.239 63.8495 20.0252C63.8495 17.2075 66.5516 16.3019 68.7533 16.3019C69.5539 16.3019 70.4546 16.4025 71.1551 16.6038V16.2013C71.1551 15.3962 70.4546 14.7925 68.8534 14.7925C67.3522 14.7925 66.0512 15.195 64.9503 15.6981V12.3774C65.851 12.0755 67.6524 11.673 69.3538 11.673C72.9566 11.673 75.2583 12.8805 75.2583 16.9057V20.0252C75.2583 20.327 75.5586 20.5283 75.9589 20.5283ZM69.4538 20.7296C70.1544 20.7296 70.6548 20.6289 71.2552 20.1258V18.6164C70.8549 18.5157 70.3545 18.4151 69.8541 18.4151C68.9534 18.4151 68.0527 18.717 68.0527 19.6226C68.0527 20.1258 68.5531 20.7296 69.4538 20.7296Z"
          fill="white"
        />
        <path
          d="M81.263 6.54088V12.6792C81.9635 11.9748 83.0644 11.3711 84.5656 11.3711C88.3685 11.3711 89.9697 14.4906 89.9697 17.4088C89.9697 20.327 88.2684 23.4465 84.5656 23.4465C82.564 23.4465 81.263 22.5409 80.7626 21.5346V23.044H77.2599V6.54088H81.263ZM83.5648 14.6918C82.4639 14.6918 81.6633 15.3962 81.263 16.1006V18.717C81.6633 19.4214 82.4639 20.1258 83.5648 20.1258C85.2661 20.1258 85.9666 18.717 85.9666 17.4088C85.9666 16.1006 85.2661 14.6918 83.5648 14.6918Z"
          fill="white"
        />
        <path
          d="M102.279 11.7736V23.044H98.7766V21.7359C97.8759 22.7421 96.6749 23.3459 95.1738 23.3459C92.0714 23.3459 90.6703 21.2327 90.6703 18.717V11.673H94.6734V18.1132C94.6734 19.3208 95.3739 19.9245 96.3747 19.9245C97.2754 19.9245 97.9759 19.4214 98.2762 18.9182V11.673H102.279V11.7736Z"
          fill="white"
        />
        <path
          d="M108.484 11.3711C110.486 11.3711 111.787 12.2767 112.287 13.283V11.7736H115.79V22.6415C115.79 27.1698 112.487 28.6792 109.185 28.6792C107.383 28.6792 105.682 28.2767 104.381 27.5723V24.1509C105.582 24.8553 107.183 25.3585 108.684 25.3585C110.486 25.3585 111.887 24.8553 111.887 22.8428V22.1384C111.186 22.8428 110.085 23.4465 108.584 23.4465C104.781 23.4465 103.18 20.327 103.18 17.4088C103.08 14.4906 104.781 11.3711 108.484 11.3711ZM109.585 14.6918C107.884 14.6918 107.183 16.1006 107.183 17.4088C107.183 18.717 107.884 20.1258 109.585 20.1258C110.686 20.1258 111.486 19.4214 111.887 18.717V16.1006C111.486 15.3962 110.686 14.6918 109.585 14.6918Z"
          fill="white"
        />
        <path
          d="M128.6 20.5283C128.7 20.5283 128.9 20.5283 129 20.4277V22.9434C128.5 23.1447 127.699 23.3459 126.998 23.3459C125.798 23.3459 124.797 22.9434 124.296 22.1384C123.195 22.9434 121.995 23.5472 120.393 23.5472C118.192 23.5472 116.49 22.239 116.49 20.0252C116.49 17.2075 119.192 16.3019 121.394 16.3019C122.195 16.3019 123.095 16.4025 123.796 16.6038V16.2013C123.796 15.3962 123.095 14.7925 121.494 14.7925C119.993 14.7925 118.692 15.195 117.591 15.6981V12.3774C118.492 12.0755 120.293 11.673 121.995 11.673C125.597 11.673 127.899 12.8805 127.899 16.9057V20.0252C127.799 20.327 128.199 20.5283 128.6 20.5283ZM121.995 20.7296C122.695 20.7296 123.195 20.6289 123.796 20.1258V18.6164C123.396 18.5157 122.895 18.4151 122.395 18.4151C121.494 18.4151 120.593 18.717 120.593 19.6226C120.593 20.1258 121.094 20.7296 121.995 20.7296Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="AlabugaIconClip">
          <rect width="129" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AlabugaIcon;
