import React from 'react';

function MultiplyModalIcon({ style, fill, stylePath, className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M8.47891 13.5771C7.31152 12.2444 6.2964 10.8946 6.22282 10.5773C6.1373 10.2065 6.78848 9.30136 8.04479 8.04505C9.30111 6.78873 10.2063 6.13756 10.577 6.22307C10.8944 6.29666 12.2442 7.31178 13.5769 8.47917L15.9997 10.602L18.4226 8.47915C19.7553 7.31176 21.1054 6.29639 21.4225 6.22309C21.7932 6.13758 22.6984 6.78873 23.9547 8.04505C25.211 9.30136 25.8622 10.2065 25.7767 10.5773C25.7034 10.8943 24.688 12.2444 23.5206 13.5772L21.3977 16L23.5285 18.4149C24.7005 19.7431 25.7159 21.0932 25.7846 21.4148C25.865 21.7895 25.2067 22.7029 23.9547 23.955C22.6984 25.2113 21.7932 25.8624 21.4225 25.7769C21.1054 25.7036 19.7553 24.6882 18.4226 23.5208L15.9997 21.398L13.5769 23.5209C12.2442 24.6882 10.8944 25.7033 10.577 25.7769C10.2063 25.8624 9.30111 25.2113 8.04479 23.955C6.78848 22.6986 6.13732 21.7935 6.22284 21.4227C6.29642 21.1054 7.31151 19.7556 8.47889 18.4228L10.6017 16L8.47891 13.5771Z"
        fill={fill ? fill : '#C6A788'}
      />
    </svg>
  );
}

export default MultiplyModalIcon;
