import React from 'react';

function HeartIcon({ style }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="#BD6F47" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M27.3281 4.08488C26.0293 3.39481 24.5425 3 22.9585 3C20.1678 3 17.6603 4.22556 16.0053 6.14384C14.3391 4.22537 11.84 3 9.04224 3C7.45975 3 5.96959 3.39367 4.67208 4.08488C1.88707 5.56076 0 8.40554 0 11.691C0 12.6322 0.158953 13.5259 0.447162 14.3717C1.99386 21.0729 11.8984 29.5257 16.0061 29.5257C20.0003 29.5257 30 21.0729 31.5534 14.3717C31.8416 13.5259 32 12.6322 32 11.691C32.0013 8.40554 30.1126 5.56076 27.3281 4.08488Z"
        fill="inherit"
      />
    </svg>
  );
}

export default HeartIcon;
