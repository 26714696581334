import React from 'react';

function ErrorModalIcon({ style, stylePath, className }) {
  return (
    <svg
      className={className}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#errorModalIcon)">
        <path
          d="M78.3767 60.0942C68.9917 38.176 62.5928 26.9511 50.0851 8.01018C47.9498 4.41549 44.1786 2.26862 39.9973 2.26862C35.816 2.26862 32.0448 4.41549 29.9095 8.01018C29.8934 8.03768 29.8798 8.06518 29.8637 8.09268C17.9288 26.9928 11.4744 37.9353 1.6662 60.0119C-0.550736 64.9999 -0.556143 68.0903 1.55386 71.802C3.6687 75.516 7.50035 76.2499 11.7517 77.7314C33.7602 79.9452 46.0789 79.6364 68.0867 77.7314C73.7504 76.2499 76.3262 75.516 78.4409 71.802C80.5509 68.0905 80.0004 63.8863 78.3767 60.0942ZM35.3051 26.1169C35.3051 23.5253 37.4059 21.4247 39.9973 21.4247C42.5889 21.4247 44.6895 23.5255 44.6895 26.1169V44.8858C44.6895 47.4771 42.5887 49.578 39.9973 49.578C37.4059 49.578 35.3051 47.4769 35.3051 44.8858V26.1169ZM39.9973 68.347C36.1162 68.347 32.9589 65.1899 32.9589 61.3086C32.9589 57.4275 36.116 54.2703 39.9973 54.2703C43.8784 54.2703 47.0356 57.4275 47.0356 61.3086C47.0357 65.1897 43.8786 68.347 39.9973 68.347Z"
          fill="#FDF6EE"
        />
      </g>
      <defs>
        <clipPath id="errorModalIcon">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ErrorModalIcon;
