import React from 'react';
import useWindow from '../../hooks/useWindow';
import './style.scss';
import AlabugaIconMain from '../../icons/AlabugaIconMain';
import classNames from '../../../common/classNames';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import trainingStore from '../../../store/trainingStore';

function MainLayout({ children }) {
  const { appStore } = RootStore;
  const { isMobile } = useWindow();

  return (
    <div
      className={classNames({
        [`season--${appStore.currentSeasonType}`]: !trainingStore.launched,
        'main_layout_container main_layout_container_bg_mobile': isMobile,
        'main_layout_container main_layout_container_bg': !isMobile,
      })}
    >
      <div className={'main-layout-wrap'}>{children}</div>
      <div className="main__alabuga-icon">
        <AlabugaIconMain />
      </div>
    </div>
  );
}

export default observer(MainLayout);
