import React, { useEffect, useState, useRef } from 'react';
import leftFoot from '../../../../assets/paws/colorless_foot_left.png';
import rightFoot from '../../../../assets/paws/colorless_foot_right.png';
import upFoot from '../../../../assets/paws/colorless_foot_up.png';
import downFoot from '../../../../assets/paws/colorless_foot_down.png';
import paintedFoot from '../../../../assets/paws/painted_foot.png';
import RootStore from '../../../../store';

const convertToPaws = (code) => {
  const pawMap = {
    0: upFoot,
    1: leftFoot,
    2: rightFoot,
    3: downFoot,
  };

  return code.split('').map((num) => pawMap[num]);
};

const PawGrid = () => {
  const { appStore } = RootStore;
  const code = !trainingStore.launched ? appStore.playerSecretCode.toString() : localStorage.getItem('SECRET_CODE');
  const [pawPattern, setPawPattern] = useState([]);
  const pawGridRef = useRef(null);

  if (!code || !appStore) {
    return null;
  }

  useEffect(() => {
    const handleResize = () => {
      if (pawGridRef.current) {
        const { clientWidth } = pawGridRef.current;
        const pawSize = 24;
        const gapSize = 5;
        const cols = Math.floor(clientWidth / (pawSize + gapSize));
        const rows = 12;
        const numPaws = cols * rows;
        setPawPattern(generatePawPattern(numPaws));
      }
    };

    const generatePawPattern = (numPaws) => {
      const pattern = convertToPaws(code);
      pattern.push(paintedFoot);
      const repeatedPattern = [];
      for (let i = 0; i < numPaws; i++) {
        const patternIndex = i % pattern.length;
        repeatedPattern.push(pattern[patternIndex]);
      }
      return repeatedPattern;
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [code]);

  return (
    <div className="paw-grid" ref={pawGridRef}>
      {pawPattern.map((paw, index) => (
        <img key={index} src={paw} alt={`paw-${index}`} className="paw-item" />
      ))}
    </div>
  );
};

export default PawGrid;
