import * as chapter1 from './chapter1';
import * as chapter2 from './chapter2';
import * as chapter3 from './chapter3';
import * as chapter4 from './chapter4';
import * as chapter5 from './chapter5';
import * as chapter6 from './chapter6';
import * as chapter7 from './chapter7';
import * as chapter8 from './chapter8';
import * as chapter9 from './chapter9';
import * as chapter10 from './chapter10';
import * as chapter11 from './chapter11';
import * as chapter12 from './chapter12';

export default {
  chapter1,
  chapter2,
  chapter3,
  chapter4,
  chapter5,
  chapter6,
  chapter7,
  chapter8,
  chapter9,
  chapter10,
  chapter11,
  chapter12,
};
