import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import { CatFaceIcon } from '../icons';

function NoSuchCatModal() {
  const { appStore } = RootStore;

  if (!appStore.noSuchCatModalFlag) {
    return null;
  }

  return (
    <Modal
      isOpen={!!appStore.noSuchCatModalFlag}
      onClose={appStore.noSuchCatModalClose}
      title="У тебя нет этого котика"
      desc="Возможно, он был продан"
      colorBg="red"
    >
      <CatFaceIcon className="modal__body-icon" />
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.noSuchCatModalClose} />
      </div>
    </Modal>
  );
}

export default observer(NoSuchCatModal);
