import React from 'react';

function BracketModalIcon({ style, stylePath, className }) {
  return (
    <svg
      className={className}
      width="11"
      height="48"
      viewBox="0 0 11 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.624 36.8698V10.9498C6.624 8.21384 7.632 6.53384 10.656 6.67784V0.00584177C3.12 -0.186159 0 4.37384 0 10.8538V36.9658C0 43.4458 3.12 48.0058 10.656 47.8138V41.1418C7.632 41.2858 6.624 39.6058 6.624 36.8698Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default BracketModalIcon;
