import React from 'react';
import { observer } from 'mobx-react';
import classNames from '../../common/classNames';
import corpStore from '../../store/CorpStore';
import RootStore from '../../store';
import routes from '../../routes';

function Areas() {
  const { allAreas } = corpStore;

  const { appStore } = RootStore;

  if (!allAreas) {
    return null;
  }

  return (
    <div className="areas__container">
      {Object.values(allAreas).map((area) => (
        <div
          key={area.area_num}
          className={classNames({
            areas__item: true,
            'areas__item--bankrupt': !area.active,
          })}
          onClick={() => {
            RootStore.goTo(routes.city, { areaNum: area.area_num });
          }}
        >
          <img src={area.src[appStore.currentSeasonType]} alt={area.name} />
          <span className="areas__name">Город {area.area_num}</span>
        </div>
      ))}
    </div>
  );
}

export default observer(Areas);
