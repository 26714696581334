import React from 'react';
import { observer } from 'mobx-react';
import './ConfirmationActionWithCarModal.scss';
import Modal from '../Modal/Modal';
import corpStore from '../../../store/CorpStore';
import { CAR_SIZE, CAR_ACTION_TYPE_NAME } from '../../../store/constants';
import CarIcon from '../../icons/CarIcon';
import TextButton from '../../buttons/TextButton/TextButton';
import { CoinIcon } from '../../icons';
import { declOfNum } from '../../../common/declensionOfWords';

function ConfirmationActionWithCarModal() {
  if (!corpStore.confirmationActionWithCarData) {
    return null;
  }
  const data = corpStore.confirmationActionWithCarData;
  const totalPrice = data.amount * corpStore.carCost[data.get_type][data.car_size];

  const carWord = declOfNum(data.amount, ['машину', 'машины', 'машин']);
  return (
    <Modal
      isOpen={!!corpStore.confirmationActionWithCarData}
      onClose={() => corpStore.hideConfirmationActionWithCar()}
      title={`Хотите ${CAR_ACTION_TYPE_NAME[data.get_type]} ${data.amount} ${carWord} на ${
        CAR_SIZE[data.car_size]
      } мест?`}
      customClass="confirmation-action-car"
    >
      <div className="modal__body-wrapper">
        <div className="modal__confirmation-action-car">
          <div className="modal__body-icon">
            <CarIcon className="modal__confirmation-action-car-icon" />
          </div>
          <span className="modal__confirmation-action-car-text">итого сумма</span>
          <div className="modal__confirmation-action-car__info">
            <CoinIcon />
            <span className="modal__confirmation-action-car__info-text">{totalPrice}</span>
          </div>
        </div>
      </div>
      <div className="modal__body-actions">
        <TextButton
          color="green"
          title="Подтвердить"
          onClick={() => corpStore.receiveCar(data.get_type, data.car_size, data.amount, totalPrice)}
        />
        <TextButton color="blue" title="Отмена" onClick={() => corpStore.hideConfirmationActionWithCar()} />
      </div>
    </Modal>
  );
}

export default observer(ConfirmationActionWithCarModal);
