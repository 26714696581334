import React from 'react';

function TeamMarketIcon({ isActive = false }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 10 10"
      fill={isActive ? '#FDF6EE' : '#C0A88F'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.07113 0H3.92887C3.73222 0 3.57113 0.159029 3.57113 0.355723V9.64218C3.57113 9.84097 3.73222 10 3.92887 10H6.07113C6.26988 10 6.42887 9.83888 6.42887 9.64218V0.357815C6.42887 0.159029 6.26778 0 6.07113 0Z"
        fill="inherit"
      />
      <path
        d="M2.5 3.9276H0.357741C0.161088 3.9276 0.00209205 4.08663 0 4.28542V9.64218C0 9.84097 0.161088 10 0.357741 10H2.5C2.69874 10 2.85774 9.83888 2.85774 9.64218V4.28542C2.85774 4.08663 2.69665 3.9276 2.5 3.9276Z"
        fill="inherit"
      />
      <path
        d="M7.5 2.85625H9.64226C9.83891 2.85625 10 3.01528 10 3.21406V9.64218C10 9.83888 9.841 10 9.64226 10H7.5C7.30335 10 7.14226 9.84097 7.14226 9.64218V3.21406C7.14226 3.01528 7.30126 2.85625 7.5 2.85625Z"
        fill="inherit"
      />
    </svg>
  );
}

export default TeamMarketIcon;
