import React from 'react';
import './style.scss';
import classNames from '../../../common/classNames';

function IconButton({ children, color, style, onClick, customClass, disabled }) {
  return (
    <div
      className={classNames({
        icon_button: true,
        [`button_bg_${color}`]: color,
        [`${customClass}`]: !!customClass,
        disabled: disabled,
      })}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default IconButton;
