import React from 'react';

function DislikeIcon({ style }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#FDF6EE" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M14.4399 18.9884C13.1954 20.987 12.1773 22.7523 12.1773 22.9116C12.1773 23.6206 10.4323 23.131 9.72603 22.2236C9.04238 21.3454 8.98564 20.9656 9.1677 18.4902L9.37063 15.7347L5.48802 15.7347C1.98067 15.7347 1.52746 15.66 0.799603 14.9617C-0.205334 13.9971 -0.275284 12.183 0.659338 11.319C1.27755 10.7473 1.28591 10.6356 0.778738 9.73249C0.0407887 8.41977 0.438639 6.99148 1.77287 6.16267C2.40465 5.77013 2.68348 5.40535 2.45966 5.26384C1.8056 4.85103 2.03706 2.85242 2.8394 1.97952C3.57874 1.17596 3.74966 1.15677 10.1493 1.15677H16.7024L16.7024 8.25583V15.3553L14.4399 18.9884Z"
        fill="inherit"
      />
      <path
        d="M23.8672 6.08268C23.7436 8.55559 23.5508 11.5789 23.4387 12.8013L23.235 15.0235H21.0132H18.791V7.88677L18.791 0.75L20.7925 0.982553C24.3841 1.39962 24.123 0.966196 23.8672 6.08268Z"
        fill="inherit"
      />
    </svg>
  );
}

export default DislikeIcon;
