import { PLAYER_ROLE } from '../../../../common/constants';

const createAI = (type, id) => {
  if (id < 10) {
    id = `0${id}`;
  }

  const isNursery = type === PLAYER_ROLE.NURSERY;

  return {
    uid: `00000000-0000-0000-0000-0000000000${id}`,
    name: `bot ${id}`,
    role: type,
    default_prices_override: {
      male_black: {
        buy_price: 10,
        sell_price: 10,
      },
      female_black: {
        buy_price: 11,
        sell_price: 11,
      },
    },
    ai_type: isNursery ? 'nursery_bot' : 'normal_shop_bot',
    ai_features: {
      ai_enabled: false,
    },
  };
};

const LTDStepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 0,
  possible_credit: 80,
  credit_percentages: {
    consumer: 5,
    investment: 10,
    special: 15,
  },
  with_ltd: true,
  max_possible_fine: 120,
  bank_guarantee: 100,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 120,
      default_prices_override: {
        male_black: {
          buy_price: 12,
          sell_price: 9,
        },
        female_black: {
          buy_price: 14,
          sell_price: 11,
        },
        male_ginger: {
          buy_price: 22,
          sell_price: 18,
        },
        female_ginger: {
          buy_price: 25,
          sell_price: 21,
        },
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000021',
      name: 'player 1',
      role: 'shop',
      initial_money: 120,
    },
    // ..._.range(2, 20).map((id) => createAI(PLAYER_ROLE.NURSERY, id)),
    // ..._.range(22, 25).map((id) => createAI(PLAYER_ROLE.SHOP, id)),
  ],
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  absence_timeout: 1000 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
  hunger_enabled: false,
};

export default LTDStepsApiParams;
