import React from 'react';

function ChapterFiveIcon({ style, className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M31.1201 17.2856H20.0297C19.545 17.2856 19.1499 17.6807 19.1499 18.1653C19.1499 18.4122 19.2548 18.6345 19.4184 18.7949L27.2277 26.6042C27.3881 26.7771 27.6198 26.8852 27.873 26.8852C28.1785 26.8852 28.447 26.7309 28.6044 26.4963C30.5244 24.2676 31.7591 21.4371 31.9814 18.3227C31.9938 18.2702 31.9999 18.2178 31.9999 18.1652C32 17.6807 31.6078 17.2856 31.1201 17.2856Z"
        fill="#C6A788"
      />
      <path
        d="M16.6803 1.34274C16.1956 1.34571 15.8037 1.73784 15.8037 2.22243V14.5476C15.8037 15.0354 16.1956 15.4305 16.6803 15.4305H29.0087C29.4934 15.4305 29.8884 15.0354 29.8884 14.5476C29.4531 7.45452 23.7766 1.77804 16.6803 1.34274Z"
        fill="#C6A788"
      />
      <path
        d="M23.4559 25.8852L14.3625 16.7918C14.2082 16.6343 14.1157 16.4152 14.1157 16.1775L14.1124 3.33366C14.1124 2.84907 13.7205 2.45703 13.2358 2.45703C5.84933 2.90774 0 9.04401 0 16.5418C0 24.3357 6.31861 30.6573 14.1124 30.6573C17.6683 30.6573 20.9155 29.3424 23.3972 27.1723C23.5887 27.0117 23.7091 26.7711 23.7091 26.5025C23.7091 26.2648 23.6132 26.0456 23.4559 25.8852Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default ChapterFiveIcon;
