import React from 'react';

const QuestionMark = ({ fill = '#93877B' }) => {
  return (
    <svg
      className="icon--question-mark"
      width="40"
      height="56"
      viewBox="0 0 40 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.6748 3.04594C39.7503 6.77913 42.4446 20.5171 37.4113 27.1902C35.9872 29.0775 32.2746 32.3139 29.161 34.383C26.0461 36.4519 23.4987 39.0058 23.4987 40.0597C23.4987 41.4034 21.8745 41.9751 18.0529 41.9751C12.7513 41.9751 12.607 41.8535 12.607 37.3629C12.607 33.9274 13.4975 32.1376 16.0951 30.3505C25.6075 23.8068 27.583 21.6457 27.583 17.7855C27.583 9.31309 14.5266 9.1701 11.9848 17.6131C10.803 21.5387 10.1878 21.9395 5.34093 21.9395H0L0.709311 15.766C2.25856 2.28059 18.7716 -4.29114 32.6748 3.04594Z"
        fill={fill}
      />
      <path
        d="M24.1794 51.325C24.1794 55.8891 24.0501 56 18.7336 56C14.072 56 13.2238 55.5539 12.8399 52.8972C12.0407 47.3645 13.5165 45.7844 19.063 46.2346C23.9219 46.6285 24.1794 46.8852 24.1794 51.325Z"
        fill={fill}
      />
    </svg>
  );
};

export default QuestionMark;
