import React from 'react';
import RootStore from '../../../store';
import './style.scss';
import { observer } from 'mobx-react';
import { CatFaceIcon } from '../../icons';
import Mail from '../Mail/Mail';
import * as teamMode from '../../../store/teamMode';
import { LocalStorage } from '../../../common/localStorage';
import isVisibleLot from '../../../common/lotVisibility';
import { TASK_TYPES } from '../../../store/constants';

export function lotVisibility(lot) {
  const { appStore } = RootStore;
  if (!appStore.isTeamMode) {
    return true;
  }

  const lotAlreadyAck = appStore.tasks.some((task) => {
    return (
      task.content?.lot_id === lot.lot_id &&
      task.created_by === LocalStorage.USER_UID &&
      task.type === TASK_TYPES.TRADING_LOT_ACKNOWLEDGE
    );
  });

  if (lotAlreadyAck) {
    return false;
  }

  return isVisibleLot(lot);
}

function NotificationBoard() {
  const { appStore } = RootStore;
  if (!appStore.me || !appStore.lots?.length) {
    return null;
  }

  const renderLots = appStore.lots.filter((lot) => {
    return lotVisibility(lot);
  });

  if (!renderLots.length) {
    return null;
  }

  return (
    <div className="mail-container">
      <div className="mail-container__cat-face">
        <CatFaceIcon style={{ fill: '#EEDBC7' }} />
      </div>
      {renderLots.map((lot) => {
        return <Mail key={lot.lot_id} lot={lot} />;
      })}
    </div>
  );
}

export default observer(NotificationBoard);
