import React from 'react';
import { observer } from 'mobx-react';
import { AREA_POSITION, OPERATION_TYPE, TRANSFER_TYPE, TRANSFER_TYPE_NAME } from '../../../../../store/constants';
import corpStore from '../../../../../store/CorpStore';
import { ArrowIcon } from '../../../../../ui/icons';
import useWindow from '../../../../../ui/hooks/useWindow';

function OperationAreaInfo() {
  const { isMobile } = useWindow();
  const { currentOperationType } = corpStore;
  const currentContent = corpStore.operationContent || null;
  const areaNum = corpStore.operationContent?.targetAreaNum || null;
  const isTop = corpStore.operationContent?.positionTarget === AREA_POSITION.TOP;
  let transferType = '';
  if (currentOperationType === OPERATION_TYPE.TRANSFER && currentContent) {
    if (currentContent.to) {
      transferType = TRANSFER_TYPE.IMPORT;
    } else {
      transferType = TRANSFER_TYPE.EXPORT;
    }
  }

  if (!currentContent) {
    return (
      <div className="lot-area__info lot-area__info--transaction">
        <div className="lot-area__info__arrows">
          <ArrowIcon top={false} color="#FDF6EE" />
          <ArrowIcon color="#FDF6EE" style={{ transform: 'translateY(-20px)' }} />
        </div>
        <p className="lot-area__info__transaction-text">
          ЗОНА
          <br />
          {currentOperationType === OPERATION_TYPE.TRANSPORTATION ? 'ОТПРАВКИ' : 'ТРАНСФЕРА'}
        </p>
      </div>
    );
  } else {
    return (
      <div className="lot-area__info lot-area__info--transaction">
        {!isMobile && (
          <div className="lot-area__info-text lot-area__info-text--min">
            {currentOperationType === OPERATION_TYPE.TRANSPORTATION ? (
              <span>
                отправь котиков <br />в город {areaNum}
              </span>
            ) : (
              <span>
                {TRANSFER_TYPE_NAME[transferType]} котиков <br />
                {currentContent.to ? 'в город' : 'из города'} номер {areaNum}
              </span>
            )}
          </div>
        )}
        <div className="lot-area__info__arrows lot-area__info__arrows--indent">
          <ArrowIcon color="#FDF6EE" height="6vh" width="6vh" top={isTop} />
        </div>
        {!isMobile && (
          <div className="lot-area__info-text">
            <span>
              {currentOperationType === OPERATION_TYPE.TRANSPORTATION
                ? 'Отправить'
                : `${TRANSFER_TYPE_NAME[transferType]}`}
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default observer(OperationAreaInfo);
