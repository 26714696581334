import React, { useState } from 'react';
import RootStore from '../../../store';
import { CREDIT_TYPE, CREDIT_TYPE_NAME, CREDIT_TYPE_NOTIFICATION } from '../../../store/constants';
import trainingStore from '../../../store/trainingStore';
import { Swiper, SwiperSlide } from 'swiper/react';
import _ from 'lodash';
import TextButton from '../../buttons/TextButton/TextButton';
import CoinIcon from '../../icons/CoinIcon';
import DividingArrow from '../../icons/modal/DividingArrow';
import './CreditField.scss';
import { observer } from 'mobx-react';
import classNames from '../../../common/classNames';
import ReactSelect from '../../Select/Select';
import numberInputKeyDown from '../../../common/numberInputKeyDown';
import { calcTimeCreditPayment, calcTimeCreditPaymentText } from '../../../common/calcTimeForCredit';

const textForCreditPayment = ({ payout }) => {
  const { appStore } = RootStore;
  let payoutInfo = payout.season;
  if (appStore.isRealTimeMode) {
    payoutInfo = calcTimeCreditPaymentText(payout.time);
  }
  return (
    <div className="available-credit__credit-calculation-text">
      <span>выплата</span> <br /> <span>{!appStore.isRealTimeMode ? 'на ' : 'через '}</span>
      <span className="notranslate">{payoutInfo}</span> {!appStore.isRealTimeMode && <span>сезон</span>}
    </div>
  );
};

const CreditField = ({ item, readOnly, customClass }) => {
  const { appStore } = RootStore;
  let currentSeason = appStore.currentSeason;
  let initialStateCredit;
  if (!readOnly) {
    initialStateCredit = {
      credit_type: item.credit_type,
      money_quantity: 0,
      season_quantity: 1,
    };
  } else {
    initialStateCredit = {
      credit_type: item.credit_type,
      money_quantity: item.money_quantity || 0,
      season_quantity: item.season_quantity || 0,
    };
  }

  const [showCreditCalc, setShowCreditCalc] = useState(false);
  const availableSum = appStore.credits.available_credit;
  const [creditData, setCreditData] = useState(initialStateCredit);

  let remainSeasons = appStore.credits.last_season - currentSeason;
  const seasonArr = _.range(1, remainSeasons, 1);

  function onChangeMoneyQuantity(event) {
    if ((!showCreditCalc && event.target.value.length <= 3) || event.target.value <= availableSum) {
      setCreditData({ ...creditData, money_quantity: +event.target.value });
    }
  }

  function handleScroll(event) {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  }

  // РАСЧЕТ КРЕДИТА НА КЛИЕНТЕ
  // https://gamesalabuga.atlassian.net/wiki/spaces/BL/pages/1998959/1.1#%D0%9A%D1%80%D0%B5%D0%B4%D0%B8%D1%82
  // percentage - процент кредита;
  // leftToPay - сумма тела долга, которую осталось заплатить;
  // seasons - выбранное количество сезонов;
  // percent - перевод процента по выбранному типу кредита в десятичную дробь;
  // moneyPerSeason - сумма выплата тела долга за сезон;
  // overPay - сумма переплаты процентов;
  // payArray - массив для тела выплаты в виде: номер сезона и итоговая выплата за сезон;
  let percentage = appStore.credits.credit_percentages.find(
    (credit_percentage) => credit_percentage.credit_type === item.credit_type,
  ).percentage;
  let leftToPay = creditData.money_quantity;
  let seasons = creditData.season_quantity;
  let percent = percentage / 100;
  let moneyPerSeason = Math.ceil(leftToPay / seasons);
  let overPay = 0;
  let payArray = [];

  for (let i = currentSeason + 1; i <= seasons + currentSeason; i++) {
    if (item.credit_type === CREDIT_TYPE.CONSUMER) {
      if (creditData.money_quantity !== 0 && leftToPay === 0) {
        overPay += Math.ceil(percent);
      } else {
        overPay += Math.ceil(leftToPay * percent);
      }
      if (leftToPay <= 0) {
        payArray.push({
          season: i + 1,
          money: Math.ceil(percent),
        });
      } else if (leftToPay <= moneyPerSeason) {
        moneyPerSeason = leftToPay;
        payArray.push({
          season: i + 1,
          money: Math.ceil(leftToPay) + Math.ceil(leftToPay * percent),
        });
        leftToPay -= moneyPerSeason;
      } else if (leftToPay > 0) {
        payArray.push({
          season: i + 1,
          money: Math.ceil(moneyPerSeason) + Math.ceil(leftToPay * percent),
        });
        leftToPay -= moneyPerSeason;
      }
    } else if (item.credit_type === CREDIT_TYPE.INVESTMENT) {
      overPay += Math.ceil(leftToPay * percent);
      if (i === seasons + currentSeason) {
        payArray.push({
          season: i + 1,
          money: leftToPay + Math.ceil(leftToPay * percent),
        });
      } else {
        payArray.push({
          season: i + 1,
          money: Math.ceil(leftToPay * percent),
        });
      }
    } else if (item.credit_type === CREDIT_TYPE.SPECIAL) {
      if (i === seasons + currentSeason) {
        overPay += Math.ceil(leftToPay * seasons * percent);
        payArray.push({
          season: i + 1,
          money: Math.ceil(leftToPay + overPay),
        });
      } else {
        payArray.push({ season: i + 1, money: 0 });
      }
    }
  }

  if (appStore.isRealTimeMode) {
    payArray.forEach((el, index) => {
      el.time = calcTimeCreditPayment({ quarter: (index + 1) * 3 });
    });
  }

  const CreditCalculation = () => {
    return (
      <Swiper threshold={0} touchAngle={40} slidesPerView={'auto'} freeMode={true} grabCursor={true}>
        {payArray.map((item) => (
          <SwiperSlide key={`season - ${item.season}`}>
            <div className="available-credit__credit-calculation-item">
              {textForCreditPayment({ payout: item })}
              <div className="available-credit__credit-calculation-value-block">
                <CoinIcon customClass="modal__credit-coin" alt="Монетка" />
                <DividingArrow />
                <span className="notranslate">{item.money}</span>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  let seasonText = appStore.currentPageLanguageIsRussian ? 'кол-во сезонов' : 'сезоны';
  if (appStore.isRealTimeMode) {
    seasonText = appStore.currentPageLanguageIsRussian ? 'кол-во кварталов' : 'кварталы';
  }

  return (
    <div className="available-credit__item">
      <div className="available-credit__block">
        {!readOnly && <div className="available-credit__credit-name-text">{CREDIT_TYPE_NAME[item.credit_type]}</div>}
        <div
          className={classNames({
            'available-credit__form': true,
            'available-credit__form--readonly': readOnly,
            [`available-credit__form--${customClass}`]: !!customClass,
          })}
        >
          <div
            className={classNames({
              'available-credit__input-block': true,
              'available-credit__input-block--money': true,
              'available-credit__input-block--big': readOnly,
            })}
          >
            <CoinIcon customClass="modal__credit-coin" alt="Монетка" />
            <DividingArrow />
            {!readOnly ? (
              <input
                type="number"
                className={classNames({
                  'available-credit__money-input': true,
                  noselect: readOnly,
                })}
                placeholder={0}
                min={0}
                max={999}
                value={creditData.money_quantity !== 0 && creditData.money_quantity}
                onChange={(event) => {
                  onChangeMoneyQuantity(event);
                }}
                onKeyDown={numberInputKeyDown}
                onWheel={handleScroll}
              />
            ) : (
              <div className="available-credit__money-input notranslate">{creditData.money_quantity}</div>
            )}
          </div>
          <div
            className={classNames({
              'available-credit__input-block': true,
              'available-credit__input-block--season': true,
              'available-credit__input-block--big': readOnly,
            })}
          >
            <div className="available-credit__season-text">{seasonText}</div>
            <DividingArrow />
            {readOnly ? (
              <div className="available-credit__season-input notranslate">{creditData.season_quantity}</div>
            ) : (
              <ReactSelect
                value={{
                  label: creditData.season_quantity,
                  value: creditData.season_quantity,
                }}
                onChange={(event) => {
                  setCreditData({
                    ...creditData,
                    season_quantity: +event.value,
                  });
                }}
                className="available-credit__season-select"
                options={seasonArr.map((item) => {
                  return {
                    value: item,
                    key: item,
                    label: item,
                  };
                })}
              />
            )}
          </div>
          <div
            className={classNames({
              'available-credit__input-block': true,
              'available-credit__input-block--percentage': true,
              'available-credit__input-block--big': readOnly,
            })}
          >
            <div className="available-credit__percentage-text notranslate">%</div>
            <DividingArrow />
            <div className="available-credit__percentage-value notranslate">{percentage}</div>
          </div>

          <div
            className={classNames({
              'available-credit__input-block': true,
              'available-credit__input-block--overpayment': true,
              'available-credit__input-block--big': readOnly,
            })}
          >
            <div className="available-credit__overpayment-text">выплата процентов</div>
            <DividingArrow />
            <div className="available-credit__overpayment-value notranslate">{overPay}</div>
          </div>

          {!readOnly && (
            <>
              <TextButton
                customClass="credit-send-button"
                type="button"
                title="получить"
                color="green"
                isDisabled={
                  creditData.money_quantity <= 0 ||
                  creditData.money_quantity > 999 ||
                  trainingStore.currentStep?.disabledShowCreditReceive ||
                  appStore.isCurrentTurnPreliminary
                }
                onClick={() => {
                  if (creditData.money_quantity <= availableSum) {
                    appStore.creditUpdate(creditData);
                  } else {
                    appStore.creditNotificationModalOpen({
                      ...creditData,
                      status: CREDIT_TYPE_NOTIFICATION.NOT_APPROVED,
                    });
                  }
                  setCreditData(initialStateCredit);
                  setShowCreditCalc(false);
                }}
              />
              <TextButton
                customClass="credit-send-button"
                type="button"
                title={showCreditCalc ? 'очистить' : 'рассчитать'}
                color="blue"
                isDisabled={
                  (showCreditCalc && creditData.money_quantity < 0) ||
                  (!showCreditCalc && (creditData.money_quantity <= 0 || creditData.money_quantity > 999))
                }
                onClick={() => {
                  if (showCreditCalc) {
                    trainingStore.checkIsCorrectAction('hideCreditCalculation');
                    setShowCreditCalc(false);
                  } else if (trainingStore.checkIsCorrectAction('showCreditCalculation', creditData)) {
                    if (creditData.money_quantity <= availableSum) {
                      setShowCreditCalc(true);
                    } else {
                      appStore.creditNotificationModalOpen({
                        ...creditData,
                        status: CREDIT_TYPE_NOTIFICATION.NOT_CANT_CALC,
                      });
                    }
                  }
                }}
              />
            </>
          )}
        </div>
      </div>
      {showCreditCalc && (
        <div className="available-credit__credit-calculation-block">
          <CreditCalculation />
        </div>
      )}
    </div>
  );
};

export default observer(CreditField);
