import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarCloudImage from '../../../assets/nurseryAvatars/avatarCloud.png';

function AvatarCloud({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <path
    //     d="M26.2929 18.3758C25.8579 17.85 25.3278 17.4612 24.7126 17.2156C24.814 16.9321 24.8651 16.6337 24.8651 16.3238C24.8651 15.5667 24.5855 14.9111 24.0338 14.3751C23.482 13.8395 22.807 13.5679 22.0272 13.5679C21.5612 13.5679 21.1316 13.6663 20.7445 13.8612C20.3566 13.198 19.8228 12.6381 19.153 12.1926C18.2953 11.6224 17.3267 11.3333 16.2741 11.3333C14.8621 11.3333 13.6395 11.8252 12.6404 12.7954C11.6413 13.7652 11.1348 14.9524 11.1348 16.3239C11.1348 16.3446 11.135 16.3666 11.1355 16.3901C10.4985 16.7401 9.9687 17.2278 9.55817 17.843C9.07729 18.563 8.8335 19.3677 8.8335 20.2347C8.8335 21.4495 9.28416 22.5031 10.1728 23.3659C11.0614 24.229 12.1464 24.6666 13.3975 24.6666H23.1781C24.2736 24.6666 25.2225 24.2849 25.9982 23.5319C26.7736 22.7789 27.1668 21.8575 27.1668 20.7932C27.1667 19.8893 26.8727 19.076 26.2929 18.3758Z"
    //     fill="#02516C"
    //   />
    // </svg>
    <Avatar style={style} avatarImage={AvatarCloudImage} />
  );
}

export default AvatarCloud;
