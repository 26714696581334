import React from 'react';

function ThirdPedestal({ style, stylePath, className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0648 17.4128L4.47086 21.9923C4.41208 22.1611 4.54886 22.3337 4.72666 22.3151L7.31242 22.0443C7.38466 22.0368 7.45647 22.0618 7.50841 22.1125L9.36723 23.9303C9.49502 24.0553 9.70942 24.0049 9.76816 23.8361L11.4548 18.9903C9.13647 18.9072 7.38616 18.2939 6.0648 17.4128Z"
        fill="#E76C14"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5449 18.9904L14.2316 23.8362C14.2903 24.005 14.5047 24.0553 14.6325 23.9304L16.4913 22.1126C16.5432 22.0619 16.615 22.0369 16.6872 22.0444L19.273 22.3152C19.4508 22.3338 19.5876 22.1612 19.5288 21.9924L17.9349 17.4131C16.6135 18.2941 14.8632 18.9073 12.5449 18.9904Z"
        fill="#E76C14"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00042 8.57632C2.99295 8.72711 2.98917 17.5 12 17.5C21.0108 17.5 21.007 8.72716 20.9996 8.57637C20.763 3.79987 16.8242 0 12 0C7.17576 0 3.23697 3.79987 3.00042 8.57632ZM9.40908 10.8792C9.59011 12.0881 10.5306 13.1071 12.0087 13.1071C13.4868 13.1071 14.4172 12.1034 14.6311 10.8461C14.6562 10.6988 14.6535 10.4095 14.6535 10.4095C14.5663 9.8824 14.3249 9.40671 13.9771 9.03049C14.4191 8.55232 14.6894 7.91351 14.6894 7.21263C14.6894 5.73457 13.4868 4.53204 12.0087 4.53204C10.5307 4.53204 9.32812 5.73457 9.32812 7.21263C9.32812 7.68916 9.71442 8.07541 10.1909 8.07541C10.6674 8.07541 11.0537 7.68916 11.0537 7.21263C11.0537 6.68599 11.4821 6.25756 12.0088 6.25756C12.5354 6.25756 12.9639 6.68599 12.9639 7.21263C12.9639 7.73927 12.5354 8.16771 12.0088 8.16771C11.6928 8.16771 11.4165 8.33754 11.2662 8.5909C11.2662 8.5909 11.3157 9.59533 12.0549 9.59533C12.7919 9.59533 12.8566 10.4089 12.8566 10.4089C12.9251 10.5405 12.9638 10.69 12.9638 10.8483C12.9638 11.3749 12.5354 11.8034 12.0087 11.8034C11.4821 11.8034 11.0536 11.3749 11.0536 10.8483C11.0536 10.3718 10.6673 9.98552 10.1909 9.98552C9.82556 9.98552 9.51328 10.2126 9.38742 10.5333C9.38742 10.5333 9.39263 10.7693 9.40908 10.8792Z"
        fill="#E76C14"
      />
    </svg>
  );
}

export default ThirdPedestal;
