import React from 'react';

function TeamHistoryIcon({ props, style, fill = '#9F795E' }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      {...props}
    >
      <path
        d="M5.06645 7.42171C5.14415 7.29771 4.94507 7.08854 4.49395 6.82037L0.939076 4.70716C0.530992 4.46457 0.22379 4.37717 0.15675 4.48474C-0.159935 4.99289 0.134679 9.02942 0.504748 9.25087C0.928058 9.50418 4.71239 7.98678 5.06645 7.42171Z"
        fill="inherit"
      />
      <path
        d="M9.39002 15.3429C7.83547 15.5402 6.25667 15.2581 4.8743 14.5361L5.36625 13.6553C11.9999 15.1429 15.2181 10.1727 13.5447 6.28571C13.0023 5.02569 12.057 4.12658 11.2142 3.64285C10.3713 3.15913 8.97365 2.8437 7.9999 2.92857C7.02615 3.01344 5.91097 3.48759 5.16912 4.10943C4.42728 4.73128 3.75624 6.14428 3.52269 7.07143L0.714184 5.85714C1.08869 4.37046 2.02463 3.21142 3.21418 2.21428C4.40374 1.21715 6.15278 0.778941 7.71419 0.642855C9.2756 0.506769 10.8798 0.950295 12.2314 1.72596C13.5829 2.50162 14.6525 3.67035 15.2906 5.06877C15.9288 6.46719 16.1035 8.0254 15.7905 9.52557C15.4775 11.0258 14.6923 12.3929 13.5447 13.4359C12.3972 14.4789 10.9446 15.1457 9.39002 15.3429Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00008 5C8.57832 5 8.70797 5.39311 8.70797 5.87804V8.05027L11.0461 9.39834C11.5226 9.67307 11.8276 10.1004 11.5 10.5C11.1724 10.8996 10.4765 10.7747 10 10.5C10 10.5 8.02352 9.7317 7.50003 9C6.97653 8.2683 7.14607 5.87804 7.14607 5.87804C7.14607 5.39311 7.42185 5 8.00008 5Z"
        fill="inherit"
      />
    </svg>
  );
}

export default TeamHistoryIcon;
