import Chapter from '../../base/Chapter';

class LTDChapter extends Chapter {
  constructor({
    title,
    beforeStepsInitializationStep,
    onChapterInitializationActionsNames,
    userIdentifierForSocketInitialization,
    chapter,
    stepsApiParams,
    taskApiParams,
    steps,
    stepsGeneratorInfo,
    initialRouteParams,
    seamlessStartOfNextChapter,
    nextChapter,
    callback,
    defaultStepSettings,
    disableTimers,
  }) {
    super({
      title,
      beforeStepsInitializationStep,
      onChapterInitializationActionsNames,
      userIdentifierForSocketInitialization,
      chapter,
      stepsApiParams,
      taskApiParams,
      steps,
      stepsGeneratorInfo,
      initialRouteParams,
      seamlessStartOfNextChapter,
      nextChapter,
      callback,
      defaultStepSettings,
      disableTimers,
      withLTD: true,
    });
  }
}

export default LTDChapter;
