import React from 'react';
import CatFromHomeIcon from '../../icons/CatFromHomeIcon';
import classNames from '../../../common/classNames';
import RootStore from '../../../store';

function Home({ color, customClass, cat }) {
  const { appStore } = RootStore;

  const houseNumber = appStore.catHouseNumber(cat.detail?.cat_id ?? cat.cat_id);

  return (
    <div
      className={classNames({
        'cat__home-icon': true,
        [`cat__home-icon--${color}`]: !!color,
        [customClass]: !!customClass,
      })}
    >
      <div
        className={classNames({
          'cat__home-icon-outer-circle': true,
          'cat__home-icon-outer-circle--with-house-number': !!houseNumber,
        })}
      >
        <span className="cat__home-icon__svg-wrapper">
          <CatFromHomeIcon />
        </span>
        {!!houseNumber && <span className="cat__home-icon__house-number">{houseNumber}</span>}
      </div>
    </div>
  );
}

export default Home;
