const catDataForContent = (cat, content, price = null) => {
  let catData = null;
  if (cat.cat_id) {
    catData = {
      gender: cat.gender,
      color: cat.color,
      detail: cat,
      price: price,
    };
  } else {
    if (content) {
      content.count++;
    } else {
      catData = {
        gender: cat.gender,
        color: cat.color,
        count: 1,
        price: price,
      };
    }
  }
  return catData;
};

export default catDataForContent;
