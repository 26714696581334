import React, { useState } from 'react';
import RootStore from '../../store';
import { observer } from 'mobx-react';
import trainingStore from '../../store/trainingStore';
import _ from 'lodash';
import classNames from '../../common/classNames';
import financialHistoryObject from '../../common/financialHistoryObject';
import { Swiper, SwiperSlide } from 'swiper/react';
import Modal from './Modal/Modal';
import CatUrlImage from './CatUrlImage';
import TextButton from '../buttons/TextButton/TextButton';
import { TriangleIcon, WalletTitleIcon } from '../icons';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import WatcherModalIcon from '../icons/modal/WatcherModalIcon';
import corpStore from '../../store/CorpStore';
import { CATS_TEMPLATE } from '../../common/constants';

const getCatImage = () => {
  return (
    <div className="body-balance-row-content-el body-balance-row-content-el-cat">
      <div className="body-balance-row-content-el-col"></div>
      {CATS_TEMPLATE.map((catItem) => (
        <div
          key={catItem.gender + catItem.color}
          className="body-balance-row-content-el-col body-balance-row-content-el-col--cat qq"
        >
          <CatUrlImage className="body-balance-cat-image" color={catItem.color} gender={catItem.gender} />
        </div>
      ))}
      <div className="body-balance-row-content-el-col body-balance-row-content-el-col--cat">Итого</div>
    </div>
  );
};

const catsTable = (contents, isItIncome, isShowPrices = true, title = '', totalPrice = 0) => {
  const { appStore } = RootStore;
  const averagePrice = appStore.averagePrice;
  if (!contents.length) {
    return null;
  }

  return contents.map((content) => {
    let currentTitle;
    if (title) {
      currentTitle = title;
    } else {
      if (!content.playerUuid) {
        currentTitle = 'город';
      } else {
        currentTitle = appStore.playerByUuid(content.playerUuid).name;
      }
    }
    return (
      <div key={content.playerUuid} className="body-balance-row-content-el">
        <div className="body-balance-row-content-el-col">{currentTitle}</div>
        {CATS_TEMPLATE.map((catItem) => (
          <div key={catItem.gender + catItem.color} className="body-balance-row-content-el-col">
            {content.cats[catItem.gender + catItem.color]?.map((cat, ind) => {
              let priceDiff = 0;
              let averageType = null;
              const currentBalanceSeasonAveragePrice = averagePrice[appStore.currentBalanceSeason];
              if (content.playerUuid && currentBalanceSeasonAveragePrice) {
                if (appStore.me.role === 'nursery') {
                  averageType = isItIncome ? 'buy' : 'sell';
                } else {
                  averageType = isItIncome ? 'sell' : 'buy';
                }
                const average = currentBalanceSeasonAveragePrice[averageType][catItem.color + '_' + catItem.gender];
                if (!!average && average !== cat.price) {
                  priceDiff = Math.ceil((cat.price - average) * 10) / 10;
                }
              }
              return (
                <div
                  key={catItem.gender + catItem.color + ind + content.playerUuid}
                  className="body-balance-row-content-el-col-data"
                >
                  <span className="body-balance-row-content-el-col-data-count">
                    <span className="notranslate">{cat.count}</span>
                    <span className="notranslate">{isShowPrices && `×`}</span>
                  </span>
                  {isShowPrices && (
                    <>
                      <CoinModalIcon className="body-balance-row-content-el-col-data-icon" />
                      <span className="body-balance-row-content-el-col-data-price notranslate">{cat.price}</span>
                    </>
                  )}
                  {!!priceDiff && (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                      <WatcherModalIcon style={{ width: '20', height: '20' }} />
                      <span className="body-balance-row-content-el-col-data-average">
                        <span
                          className={`average--color-${
                            (priceDiff > 0 && !isItIncome) || (priceDiff < 0 && isItIncome) ? 'red' : 'green'
                          }`}
                        >
                          <span className="notranslate">&nbsp;({priceDiff > 0 && '+'}</span>
                          <span className="notranslate">{priceDiff})</span>
                        </span>
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ))}
        <div className="body-balance-row-content-el-col">
          <p className="body-balance-row-coin">
            <span className="body-balance-coin notranslate">{totalPrice ? totalPrice : content.totalPrice}</span>
            <CoinModalIcon className="body-balance-coin-icon" />
          </p>
        </div>
      </div>
    );
  });
};

const row = (title, price) => {
  return (
    <div
      className={classNames({
        'body-balance-row-content-el': true,
        'body-balance-row-content-el--not-cats': true,
        'body-balance-row-content-el--training-hide': trainingStore.launched && price === 0,
      })}
    >
      <div className="body-balance-row-content-el-col">{title}</div>
      <div className="body-balance-row-content-el-col">
        <p className="body-balance-row-coin">
          <span className="body-balance-coin notranslate">{price}</span>
          <CoinModalIcon className="body-balance-coin-icon" />
        </p>
      </div>
    </div>
  );
};

const LTDRow = (title, detail) => {
  const { appStore } = RootStore;
  return (
    <>
      <div
        className={classNames({
          'body-balance-row-content-el': true,
          'body-balance-row-content-el--not-cats': true,
        })}
      >
        <div className="body-balance-row-content-el-col">{title}</div>
        <div className="body-balance-row-content-el-col">
          <p className="body-balance-row-coin">
            <span className="body-balance-coin notranslate">{detail.total_sum}</span>
            <CoinModalIcon className="body-balance-coin-icon" />
          </p>
        </div>
      </div>
      {detail.contents.map((content, index) => (
        <div
          className={classNames({
            'body-balance-row-content-el': true,
            'body-balance-row-content-el--not-cats': true,
            'body-balance-row-content-el--ltd': true,
          })}
          key={index + content.uuid}
        >
          <div className="body-balance-row-content-el-col">{appStore.playerByUuid(content.uuid).name}</div>
          <div className="body-balance-row-content-el-col">
            <p className="body-balance-row-coin">
              <span className="body-balance-coin notranslate">{content.sum}</span>
              <CoinModalIcon className="body-balance-coin-icon" />
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

function BalanceModal() {
  const { appStore } = RootStore;

  const [incomeVisible, setIncomeVisible] = useState(false);
  const [outcomeVisible, setOutcomeVisible] = useState(false);
  const [creditOutcomeVisible, setCreditOutcomeVisible] = useState(false);
  const [LTDIncomeVisible, setLTDIncomeVisible] = useState(false);
  const [LTDOutcomeVisible, setLTDOutcomeVisible] = useState(false);
  const [budgetAllocationVisible, setBudgetAllocationVisible] = useState(false);
  const [currentTargetBalanceDetails, setCurrentTargetBalanceDetails] = useState(0);

  if (!appStore.balanceModalFlag) {
    return null;
  }

  const { corporationCaptains, balanceDetailContents } = corpStore;
  let currentSeason = appStore.currentSeason;
  let currentBalanceSeason = appStore.currentBalanceSeason;
  let balance = appStore.balance;
  let household = appStore.gameCosts.household;
  let financeHistory = appStore.financeHistory;
  const seasons = _.range(0, appStore.currentSeason + 1, 1);

  // corporateBalanceDetail - true, если роль - СЕО и выбран таб "Корпорация"
  let corporateBalanceDetail = false;

  if (appStore.meIsCEO) {
    if (currentTargetBalanceDetails !== 0) {
      const currentCompany = _.find(corporationCaptains, { areaNum: currentTargetBalanceDetails });
      balance = currentCompany.balance;
      financeHistory = currentCompany.financeHistory;
      corporateBalanceDetail = false;
    } else {
      corporateBalanceDetail = true;
      // Необходимо показывать пользователю информацию за сезон, в котором обанкротился игрок
      corpStore.corporationCaptains.forEach((captain) => {
        if (
          captain.active ||
          (!captain.active && captain.game_over_season >= RootStore.appStore.currentBalanceSeason)
        ) {
          household += appStore.gameCosts.household;
        }
        if (!captain.active && captain.game_over_season <= RootStore.appStore.currentBalanceSeason) {
          return;
        }
        balance += captain.balance;
      });
    }
  }

  const financeHistoryObject = financialHistoryObject(
    corporateBalanceDetail,
    currentSeason,
    currentBalanceSeason,
    financeHistory,
    balance,
    household,
    currentTargetBalanceDetails,
  );

  return (
    <Modal
      isOpen={!!appStore.balanceModalFlag}
      onClose={() => {
        appStore.hideBalanceModal();
        setCurrentTargetBalanceDetails(0);
      }}
      shouldCloseOnOverlayClick={trainingStore.currentStep?.needOverlayClickModal ?? true}
      customClass="balance"
      size="very-big"
      title="Мой баланс"
      titleIcon={<WalletTitleIcon style={{ fill: '#C6A788' }} />}
    >
      <div className="body-balance">
        {/* ToDo feed {BCC-201} рефакторинг Swiper*/}
        <Swiper
          touchStartPreventDefault={false}
          threshold={0}
          touchAngle={40}
          freeModeMomentumRatio={0.2}
          freeModeMomentumVelocityRatio={0.2}
          slidesPerView={'auto'}
          freeMode={true}
          grabCursor={true}
          centerInsufficientSlides={true}
          initialSlide={appStore.currentBalanceSeason}
          onClick={(season) => {
            if (season.clickedIndex !== undefined) {
              appStore.setCurrentBalanceSeason(season.clickedIndex);
            }
          }}
          className="body-balance-seasons"
          refs="swiperSeasons"
        >
          {seasons.map((season) => (
            <SwiperSlide
              key={season}
              className={classNames({
                'body-balance-season': true,
                'body-balance-season--active': appStore.currentBalanceSeason === season,
              })}
            >
              <p className="body-balance-season-text">
                <span className="notranslate">{season + 1}</span>
                <span>{!appStore.isRealTimeMode ? ' сезон' : ' квартал'}</span>
              </p>
            </SwiperSlide>
          ))}
        </Swiper>

        {appStore.meIsCEO && (
          <Swiper
            touchStartPreventDefault={false}
            threshold={0}
            touchAngle={40}
            freeModeMomentumRatio={0.2}
            freeModeMomentumVelocityRatio={0.2}
            slidesPerView={'auto'}
            freeMode={true}
            grabCursor={true}
            centerInsufficientSlides={true}
            initialSlide={currentTargetBalanceDetails}
            onClick={(company) => {
              if (company.clickedIndex !== undefined) {
                setCurrentTargetBalanceDetails(company.clickedIndex);
              }
            }}
            className="body-balance-seasons"
            refs="swiperSeasons"
          >
            {balanceDetailContents.map((content) => (
              <SwiperSlide
                key={content.target}
                className={classNames({
                  'body-balance-season': true,
                  'body-balance-season--active': currentTargetBalanceDetails === content.target,
                  'body-balance-season--bankrupt': content.bankrupt,
                })}
              >
                <p className="body-balance-season-text">{content.title}</p>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        <div className="body-balance-rows">
          <div className="body-balance-row">
            <div className="body-balance-row-header">
              <p className="body-balance-row-title">
                деньги на начало {!appStore.isRealTimeMode ? 'сезона' : 'квартала'}
              </p>
              <p className="body-balance-row-coin">
                <span className="body-balance-coin notranslate">{financeHistoryObject.startBalance}</span>
                <CoinModalIcon className="body-balance-coin-icon" />
              </p>
            </div>
          </div>

          {/* Распределение по бюджету */}
          {appStore.isCorpMode && (
            <div className="body-balance-row training-body-balance-row-income">
              <div className="body-balance-row-header">
                <p
                  className={classNames({
                    'body-balance-row-title': true,
                    'body-balance-row-title--clickable':
                      !!financeHistoryObject.budgetAllocationFH.selection.length ||
                      !!financeHistoryObject.budgetAllocationFH.withdrawal.length,
                  })}
                  onClick={() => {
                    setBudgetAllocationVisible(!budgetAllocationVisible);
                  }}
                >
                  распределение бюджета
                  {(!!financeHistoryObject.budgetAllocationFH.selection.length ||
                    !!financeHistoryObject.budgetAllocationFH.withdrawal.length) && (
                    <TriangleIcon top={budgetAllocationVisible} />
                  )}
                </p>
                {!!financeHistoryObject.budgetAllocationFH.allAllocation && (
                  <p className="body-balance-row-coin">
                    <span className="body-balance-coin body-balance-coin--not notranslate">
                      {financeHistoryObject.budgetAllocationFH.allAllocation}
                    </span>
                    <CoinModalIcon className="body-balance-coin-icon" />
                  </p>
                )}
              </div>
              {budgetAllocationVisible && (
                <div className="body-balance-row-content">
                  {financeHistoryObject.budgetAllocationFH.selection.map((fh, index) => {
                    return <div key={index}>{row(`выделение бюджета ${fh.target}`, fh.delta)}</div>;
                  })}
                  {financeHistoryObject.budgetAllocationFH.withdrawal.map((fh, index) => {
                    return <div key={index}>{row(`изъятие бюджета ${fh.target}`, fh.delta)}</div>;
                  })}
                </div>
              )}
            </div>
          )}

          <div className="body-balance-row training-body-balance-row-income">
            <div className="body-balance-row-header">
              <p
                className={classNames({
                  'body-balance-row-title': true,
                  'body-balance-row-title--clickable': !!financeHistoryObject.income.allCats,
                })}
                onClick={() => {
                  setIncomeVisible(!incomeVisible);
                  trainingStore.checkIsCorrectAction('onChangeIncomeVisible');
                }}
              >
                доход с продажи котиков {!!financeHistoryObject.income.allCats && <TriangleIcon top={incomeVisible} />}
              </p>
              <p className="body-balance-row-coin">
                <span className="body-balance-coin body-balance-coin--not notranslate">
                  {financeHistoryObject.income.allCats}
                </span>
                <CoinModalIcon className="body-balance-coin-icon" />
              </p>
            </div>
            {incomeVisible && !!financeHistoryObject.income.allCats && (
              <div className="body-balance-row-content">
                {!corporateBalanceDetail ? (
                  <>
                    {getCatImage()}
                    {catsTable(financeHistoryObject.income.sellingCats, true)}
                  </>
                ) : (
                  <>
                    {(!!financeHistoryObject.income.exportPrepaidCats.totalPrice ||
                      !!financeHistoryObject.income.exportPostpaidCats.totalPrice) &&
                      getCatImage()}
                    {catsTable(
                      financeHistoryObject.income.exportPrepaidCats.cats,
                      true,
                      false,
                      'аванс за экспорт',
                      financeHistoryObject.income.exportPrepaidCats.totalPrice,
                    )}
                    {catsTable(
                      financeHistoryObject.income.exportPostpaidCats.cats,
                      true,
                      false,
                      'постоплата за экспорт',
                      financeHistoryObject.income.exportPostpaidCats.totalPrice,
                    )}
                    {row('продажа котиков в городах', financeHistoryObject.income.totalSellingCats)}
                  </>
                )}
              </div>
            )}
          </div>
          {!appStore.meIsCEO && (
            <div className="body-balance-row">
              <div className="body-balance-row-header">
                <p className="body-balance-row-title">Число сбежавших котиков</p>
                <p className="body-balance-row-coin">
                  <span className="body-balance-coin notranslate">
                    {appStore.purgedCats[
                      appStore.isCorpMode ? appStore.currentBalanceSeason * 2 + 2 : appStore.currentBalanceSeason + 1
                    ] || 0}
                  </span>
                </p>
              </div>
            </div>
          )}
          {/* Доход по долгосрочным сделкам */}
          {appStore.with_ltd && (
            <div className="body-balance-row training-body-balance-row-outcome">
              <div className="body-balance-row-header">
                <p
                  className="body-balance-row-title body-balance-row-title--clickable"
                  onClick={() => {
                    setLTDIncomeVisible(!LTDIncomeVisible);
                  }}
                >
                  Доход по долгосрочным сделкам
                  {financeHistoryObject.incomeLTD.all !== 0 && <TriangleIcon top={LTDIncomeVisible} />}
                </p>
                <p className="body-balance-row-coin">
                  <span className="body-balance-coin body-balance-coin--not notranslate">
                    {financeHistoryObject.incomeLTD.all}
                  </span>
                  <CoinModalIcon className="body-balance-coin-icon" />
                </p>
              </div>
              {LTDIncomeVisible && (
                <div className="body-balance-row-content">
                  {financeHistoryObject.incomeLTD.prepaid &&
                    LTDRow('получение аванса', financeHistoryObject.incomeLTD.prepaid)}
                  {financeHistoryObject.incomeLTD.payment_before_shipment &&
                    LTDRow('получение оплаты перед отгрузкой', financeHistoryObject.incomeLTD.payment_before_shipment)}
                  {financeHistoryObject.incomeLTD.payment_after_shipment &&
                    LTDRow('получение оплаты после отгрузки', financeHistoryObject.incomeLTD.payment_after_shipment)}
                  {financeHistoryObject.incomeLTD.termination &&
                    LTDRow('получение оплаты за расторжение', financeHistoryObject.incomeLTD.termination)}
                  {financeHistoryObject.incomeLTD.fine_payment &&
                    LTDRow('получение оплаты штрафа', financeHistoryObject.incomeLTD.fine_payment)}
                  {financeHistoryObject.incomeLTD.opponent_bank_guarantee &&
                    LTDRow('Зачисление банковской гарантии', financeHistoryObject.incomeLTD.opponent_bank_guarantee)}
                </div>
              )}
            </div>
          )}
          <div className="body-balance-row training-body-balance-row-outcome">
            <div className="body-balance-row-header">
              <p
                className="body-balance-row-title body-balance-row-title--clickable"
                onClick={() => {
                  setOutcomeVisible(!outcomeVisible);
                  trainingStore.checkIsCorrectAction('onChangeOutcomeVisible');
                }}
              >
                Расходы {financeHistoryObject.expenses ? <TriangleIcon top={outcomeVisible} /> : null}
              </p>
              <p className="body-balance-row-coin">
                <span className="body-balance-coin body-balance-coin--not notranslate">
                  {financeHistoryObject.expenses + financeHistoryObject.credit.paymentPercentCredit}
                </span>
                <CoinModalIcon className="body-balance-coin-icon" />
              </p>
            </div>
            {outcomeVisible && (
              <div className="body-balance-row-content">
                {!corporateBalanceDetail ? (
                  <>
                    {!!financeHistoryObject.outcome.totalBuyingCats && getCatImage()}
                    {catsTable(financeHistoryObject.outcome.buyingCats, true)}
                  </>
                ) : (
                  <>
                    {(!!financeHistoryObject.outcome.importPrepaidCats.totalPrice ||
                      !!financeHistoryObject.outcome.importPostpaidCats.totalPrice) &&
                      getCatImage()}
                    {catsTable(
                      financeHistoryObject.outcome.importPrepaidCats.cats,
                      false,
                      false,
                      'аванс за импорт',
                      financeHistoryObject.outcome.importPrepaidCats.totalPrice,
                    )}
                    {catsTable(
                      financeHistoryObject.outcome.importPostpaidCats.cats,
                      false,
                      false,
                      'постоплата за импорт',
                      financeHistoryObject.outcome.importPostpaidCats.totalPrice,
                    )}
                  </>
                )}
                {row('коммунальные услуги', financeHistoryObject.outcome.household)}
                {appStore.me && appStore.me.role === 'nursery' && (
                  <>
                    {row('покупка корма', financeHistoryObject.outcome.food)}
                    {row('лечение котиков', financeHistoryObject.outcome.treatment)}
                    {row('покупка домов', financeHistoryObject.outcome.houses)}
                    {row('страхование домов', financeHistoryObject.outcome.insurance)}
                  </>
                )}
                {appStore.credits.credit_mechanics &&
                  row('выплата процентов', financeHistoryObject.credit.paymentPercentCredit)}
                {corporateBalanceDetail && (
                  <>
                    {row('покупка котиков в городах', financeHistoryObject.outcome.totalBuyingCats)}
                    {row('покупка машин', financeHistoryObject.outcome.buyCar)}
                    {row('аренда машин', financeHistoryObject.outcome.rentCar)}
                  </>
                )}
              </div>
            )}
          </div>
          {/* Расходы по долгосрочным сделкам */}
          {appStore.with_ltd && (
            <div className="body-balance-row training-body-balance-row-outcome">
              <div className="body-balance-row-header">
                <p
                  className="body-balance-row-title body-balance-row-title--clickable"
                  onClick={() => {
                    setLTDOutcomeVisible(!LTDOutcomeVisible);
                  }}
                >
                  <span>Расходы по долгосрочным сделкам</span>
                  {financeHistoryObject.outcomeLTD.all !== 0 && <TriangleIcon top={LTDOutcomeVisible} />}
                </p>
                <p className="body-balance-row-coin">
                  <span className="body-balance-coin body-balance-coin--not notranslate">
                    {financeHistoryObject.outcomeLTD.all}
                  </span>
                  <CoinModalIcon className="body-balance-coin-icon" />
                </p>
              </div>
              {LTDOutcomeVisible && (
                <div className="body-balance-row-content">
                  {financeHistoryObject.outcomeLTD.prepaid &&
                    LTDRow('Оплата аванса', financeHistoryObject.outcomeLTD.prepaid)}
                  {financeHistoryObject.outcomeLTD.payment_before_shipment &&
                    LTDRow('оплата перед отгрузкой', financeHistoryObject.outcomeLTD.payment_before_shipment)}
                  {financeHistoryObject.outcomeLTD.payment_after_shipment &&
                    LTDRow('оплата после отгрузки', financeHistoryObject.outcomeLTD.payment_after_shipment)}
                  {financeHistoryObject.outcomeLTD.termination &&
                    LTDRow('оплата расторжения', financeHistoryObject.outcomeLTD.termination)}
                  {financeHistoryObject.outcomeLTD.fine_payment &&
                    LTDRow('оплата штрафа', financeHistoryObject.outcomeLTD.fine_payment)}
                  {financeHistoryObject.outcomeLTD.bank_guarantee &&
                    row('Оплата банковской гарантии', financeHistoryObject.outcomeLTD.bank_guarantee)}
                </div>
              )}
            </div>
          )}
          <div className="body-balance-row">
            <div className="body-balance-row-header">
              <p className="body-balance-row-title">Прибыль</p>
              <p className="body-balance-row-coin">
                <span className="body-balance-coin notranslate">{financeHistoryObject.profit}</span>
                <CoinModalIcon className="body-balance-coin-icon" />
              </p>
            </div>
          </div>
          {appStore.credits.credit_mechanics && (
            <div className="body-balance-row training-body-balance-row-outcome">
              <div className="body-balance-row-header">
                <p
                  className="body-balance-row-title body-balance-row-title--clickable"
                  onClick={() => {
                    setCreditOutcomeVisible(!creditOutcomeVisible);
                  }}
                >
                  Кредит <TriangleIcon top={creditOutcomeVisible} />
                </p>
                <p className="body-balance-row-coin">
                  <span className="body-balance-coin body-balance-coin--not notranslate">
                    {financeHistoryObject.credit.takenCredit - financeHistoryObject.credit.paymentBodyCredit}
                  </span>
                  <CoinModalIcon className="body-balance-coin-icon" />
                </p>
              </div>
              {creditOutcomeVisible && (
                <div className="body-balance-row-content">
                  {row('получение кредита', financeHistoryObject.credit.takenCredit)}
                  {row('выплата тела кредита', financeHistoryObject.credit.paymentBodyCredit)}
                </div>
              )}
            </div>
          )}
          <div className="body-balance-row">
            <div className="body-balance-row-header">
              <p className="body-balance-row-title">
                Деньги на конец {!appStore.isRealTimeMode ? 'сезона' : 'квартала'}
              </p>
              <p className="body-balance-row-coin">
                <span className="body-balance-coin notranslate">{financeHistoryObject.endBalance}</span>
                <CoinModalIcon className="body-balance-coin-icon" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="modal__body-actions">
        <TextButton
          color="blue"
          title="Понятно"
          customClass="training-balance-modal-btn"
          onClick={appStore.hideBalanceModal}
        />
      </div>
    </Modal>
  );
}

export default observer(BalanceModal);
