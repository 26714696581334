import React from 'react';
import { observer } from 'mobx-react';
import classNames from '../../../common/classNames';
import './style.scss';

const TextSwitcher = ({ customClass, options, value, onChange, readOnly, disabled }) => {
  return (
    <div
      className={classNames({
        'text-switcher': true,
        'text-switcher--disabled': disabled,
        'text-switcher--readonly': readOnly,
        [`text-switcher--${customClass}`]: !!customClass,
      })}
    >
      {options.map((option) => {
        return (
          <div
            key={option.value}
            onClick={() => {
              if (readOnly || disabled) {
                return;
              }
              onChange(option.value);
            }}
            className={classNames({
              'text-switcher__option': true,
              'text-switcher__option--selected': value === option.value,
            })}
          >
            {option.label}
          </div>
        );
      })}
    </div>
  );
};

export default observer(TextSwitcher);
