import React from 'react';

function SpoilerIcon({ onClick, isOpen = false }) {
  return isOpen ? (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1382_138793)">
        <path
          d="M3.9246 7.05153C2.20824 9.43846 0.403034 12.7114 0.504053 13.2529C0.563896 13.5741 0.827401 13.6685 3.9246 13.7083C4.91854 13.721 6.25 10.2502 7.99066 9.25015C9.5 10.0002 10.6707 13.7412 11.8234 13.7353C13.8694 13.7249 15.2655 13.6972 15.3285 13.6592C15.6767 13.4498 15.499 12.7422 14.7567 11.3831C12.5639 7.36764 8.94114 2.73989 7.99066 2.73989C7.36401 2.73989 5.93637 4.25365 3.9246 7.05153Z"
          fill="#9F795E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1382_138793">
          <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0754 8.94847C13.7918 6.56154 15.597 3.28864 15.4959 2.74709C15.4361 2.4259 15.1726 2.33149 12.0754 2.29175C11.0815 2.27899 9.75 5.74985 8.00934 6.74985C6.5 5.99985 5.32928 2.25879 4.17662 2.26468C2.13063 2.27514 0.734464 2.30283 0.671529 2.34081C0.323337 2.55024 0.501025 3.25784 1.24326 4.61687C3.43606 8.63236 7.05886 13.2601 8.00934 13.2601C8.63599 13.2601 10.0636 11.7464 12.0754 8.94847Z"
        fill="#9F795E"
      />
    </svg>
  );
}

export default SpoilerIcon;
