import React from 'react';
import RootStore from '../../../store/index';
import Cat from '../../../ui/components/Cat/Cat';
import { observer } from 'mobx-react';
import './LTDShowcasePrices.scss';
import corpStore from '../../../store/CorpStore';
import { PLAYER_ROLE } from '../../../common/constants';

function LTDShowcasePrices({ partner }) {
  const { appStore } = RootStore;
  let title;
  let groupedCats;
  let notShowCount = false;
  let notShowPrices = false;

  if (appStore.me.role === PLAYER_ROLE.NURSERY) {
    title = 'зоомагазин';
    notShowCount = true;
    if (!appStore.meIsCEO) {
      groupedCats = appStore.playerCats[partner.uuid];
    } else {
      groupedCats = corpStore.catsCompanyForLTDByUUID(partner.uuid, appStore.currentLTDAreaNum);
    }
  } else {
    title = 'город';

    if (appStore.withHideInformationMechanics) {
      notShowCount = appStore.withHideInformation;
      notShowPrices = appStore.withHideInformation;
    }

    if (!appStore.meIsCEO) {
      groupedCats = RootStore.appStore.sortedCityQuota;
    } else {
      groupedCats = corpStore.cityQuotaByAreaNum(appStore.currentLTDAreaNum);
    }
  }

  return (
    <div className="ltd__showcase-prices__wrap">
      <span className="ltd__showcase-prices__cats-title">{title}</span>
      <div className="ltd__showcase-prices-area__cats">
        {groupedCats.map((item, index) => (
          <Cat
            readonly={true}
            className={{ 'ltd__showcase-prices-area__cat': true }}
            key={index}
            cat={item}
            notShowCount={notShowCount}
            notShowPrices={notShowPrices}
          />
        ))}
      </div>
    </div>
  );
}

export default observer(LTDShowcasePrices);
