import React, { useCallback, useEffect, useState } from 'react';
import { LocalStorage } from '../../common/localStorage';
import * as FileSaver from 'file-saver';

// https://stackoverflow.com/a/48845206
function cleanStringify(object) {
  if (object && typeof object === 'object') {
    object = copyWithoutCircularReferences([object], object);
  }
  return JSON.stringify(object);

  function copyWithoutCircularReferences(references, object) {
    let cleanObject = {};
    if (object.constructor && object.constructor.name !== 'Object' && object.constructor.name !== 'Array') {
      return { info: `Instance of "${object.constructor.name}"` };
    }
    Object.keys(object).forEach(function (key) {
      let value = object[key];
      if (value && typeof value === 'object') {
        if (references.indexOf(value) < 0) {
          references.push(value);
          cleanObject[key] = copyWithoutCircularReferences(references, value);
          references.pop();
        } else {
          cleanObject[key] = '###_Circular_###';
        }
      } else if (typeof value !== 'function') {
        cleanObject[key] = value;
      }
    });
    return cleanObject;
  }
}

function LogDownloaderButton() {
  const [easterEggText, setEasterEggText] = useState('');

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'l') {
        setEasterEggText('l');
      } else if ((easterEggText === 'l' && event.key === 'o') || (easterEggText === 'lo' && event.key === 'g')) {
        setEasterEggText(easterEggText + event.key);
      } else {
        setEasterEggText('');
      }
    },
    [easterEggText],
  );

  useEffect(() => {
    window.document.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  if (easterEggText !== 'log') {
    return null;
  }
  return (
    <div
      className="download-log"
      onClick={() => {
        const blob = new Blob(
          [
            cleanStringify({
              log: console.everything,
              game: LocalStorage.GAME_UID,
              user: LocalStorage.USER_UID,
              token: LocalStorage.USER_TOKEN,
              url: location.href,
            }),
          ],
          { type: 'application/json;charset=utf-8' },
        );
        FileSaver.saveAs(blob, `cats_log_${new Date().toISOString()}.json`);
        setTimeout(() => setEasterEggText(''), 2000);
      }}
    >
      скачать лог
    </div>
  );
}

export default LogDownloaderButton;
