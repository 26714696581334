import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import HouseModalIcon from '../icons/modal/HouseModalIcon';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import trainingStore from '../../store/trainingStore';

function BuyHouseModal() {
  const { appStore } = RootStore;

  if (!appStore.buyHouseFlag) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.buyHouseFlag}
      customClass="buy-house-confirm"
      shouldCloseOnOverlayClick={
        trainingStore.currentStep?.actionName !== 'buyHouse' &&
        !trainingStore.currentStep?.actionNames?.includes('buyHouse')
      }
      onClose={() => appStore.buyHouse(false)}
      title={
        <div>
          хочешь приобрести
          <br />
          домик для котиков?
        </div>
      }
    >
      <HouseModalIcon className="modal__body-icon" />
      <div className="modal__body-price">
        <p className="modal__body-price-text">цена домика</p>
        <p className="modal__body-price-coin">
          <CoinModalIcon className="modal__body-price-coin-icon" />
          <span className="modal__body-price-coin-count notranslate"> 3 </span>
        </p>
      </div>
      <div className="modal__body-actions">
        <TextButton color="green" title="Купить" onClick={() => appStore.buyHouse(true)} />
        <TextButton color="purple" title="отмена" onClick={() => appStore.buyHouse(false)} />
      </div>
    </Modal>
  );
}

export default observer(BuyHouseModal);
