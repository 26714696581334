import React from 'react';

function ChapterOneIcon({ style, className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M31.5082 9H29.5V24H31.5082C31.78 24 32 23.5208 32 22.9286V10.0714C32 9.47921 31.78 9 31.5082 9Z"
        fill="#AD8F70"
      />
      <path
        d="M0.614754 9C0.274959 9 0 9.47921 0 10.0714V22.9286C0 23.5208 0.274959 24 0.614754 24H2.5V9H0.614754Z"
        fill="#AD8F70"
      />
      <path
        d="M15.1469 9.88141C14.0087 10.0659 13.5882 10.3375 13.2214 11.0712L10.4154 16.6849C10.1597 17.1966 10.3859 17.8313 10.9497 17.9275C11.1858 17.9678 11.4253 17.9881 11.6669 17.9881C13.3055 17.9881 14.7801 17.0747 15.5148 15.6033L16.2232 14.1865H18.3063C18.8729 14.1865 19.4278 14.4167 19.8282 14.8171L27.8077 22.7966V9.88141C22.9999 9.28948 17.5 9.5 15.1469 9.88141Z"
        fill="#AD8F70"
      />
      <path
        d="M18.3058 16.339H17.5533L17.4912 16.4634C16.4745 18.4965 14.4919 19.932 12.2262 20.1172C10.5591 20.2536 9.40388 19.774 8.03401 19.1509C7.47694 18.8976 7.23456 18.2321 7.50822 17.6847L11.2949 10.1094C12.0242 8.65044 13.5154 7.7288 15.1464 7.7288H19.7911C20.0892 7.06847 20.2734 6.34759 20.2734 5.57627C20.2734 4.98138 19.792 4.5 19.1971 4.5C19.1971 4.5 14 3.5 12 4C10 4.5 8.5 5.5 7.95279 6.28517C7.40558 7.07033 6.99047 8.20989 6.99047 8.20989C6.80807 8.5746 6.4354 8.80506 6.02757 8.80506H4.12939V23.8728H9.06507L12.1141 26.9218C12.998 27.8057 14 27.7175 15.3692 27.7175C20.192 27.1627 23.5 26 25.9507 23.9838L18.3058 16.339Z"
        fill="#AD8F70"
      />
    </svg>
  );
}

export default ChapterOneIcon;
