import React from 'react';
import classNames from '../../../common/classNames';
import TextButton from '../../buttons/TextButton/TextButton';

const ChapterTemplate = ({ chapter, mobile, customClass, isSingleItemInTwoColumn = false }) => {
  return (
    <>
      <div
        key={chapter.header + mobile}
        className={classNames({
          training__lesson: true,
          'training__lesson--disabled': !chapter.active,
          [customClass]: !!customClass,
        })}
      >
        {chapter.headerIsVisible && (
          <div className="training__lesson-header">
            {chapter.icon}
            {chapter.header}
          </div>
        )}
        <p className="training__lesson-title">{chapter.title}</p>
        <div className="training__lesson-actions">
          {chapter.clickStart && (
            <TextButton onClick={chapter.clickStart} isDisabled={!chapter.active} title="старт" color="green" />
          )}
          {chapter.clickTask && (
            <TextButton onClick={chapter.clickTask} isDisabled={!chapter.active} title="задание" color="blue" />
          )}
        </div>
      </div>
    </>
  );
};

export default ChapterTemplate;
