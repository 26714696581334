import React from 'react';

function CatFaceIcon({ style, className, fill = '#FDF6EE' }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.49934 3.45125C1.38077 3.79559 1.17945 7.72366 1.05282 12.1796C0.926225 16.6357 0.767402 21.1911 0.699378 22.3025C0.579933 24.2658 0.638973 24.3662 2.74583 25.7867C5.66521 27.7555 8.72015 28.6408 13.5969 28.9316C18.727 29.2372 23.0396 28.5193 26.6502 26.7589C29.9011 25.1737 31.0313 23.7892 30.5611 21.9685C30.3857 21.2884 30.1105 16.782 29.9499 11.9546L29.6582 3.17758L27.7075 3.0367C26.5151 2.95077 24.1666 3.37026 21.6674 4.11558C15.8362 5.85528 15.2555 5.87869 11.0503 4.54184C6.39494 3.06285 1.81387 2.53934 1.49934 3.45125ZM25.4905 10.5552C27.2712 12.5698 27.4484 12.6671 27.7755 11.806C27.9705 11.2925 28.0712 9.69011 27.9992 8.24524L27.8685 5.61759L23.5599 8.37085L25.4905 10.5552ZM7.52286 8.42354L6.28666 7.55212C5.60658 7.07323 4.59723 6.44264 4.04378 6.15143C3.04963 5.62792 3.03754 5.65633 3.06842 8.45052C3.11763 12.8769 3.38877 13.1037 5.61554 10.5827L7.52286 8.42354ZM9.65881 15.6455C10.4534 16.4448 10.312 17.5602 9.29908 18.4829C8.00157 19.664 6.16899 18.7903 6.16899 16.9903C6.16899 16.2837 6.30321 15.5702 6.46741 15.4055C6.96179 14.9082 9.08477 15.0679 9.65881 15.6455ZM24.3192 18.7741C25.2592 18.3433 25.4391 17.9887 25.3241 16.7901C25.2037 15.5351 24.9957 15.3119 23.8396 15.1976C21.496 14.9662 20.565 16.9237 22.2777 18.4829C23.0266 19.1648 23.3626 19.2125 24.3192 18.7741ZM18.4742 19.3268C19.2768 19.9174 19.2809 20.0042 18.5543 21.12C17.1955 23.2062 14.0444 23.1451 12.9088 21.0102C12.5455 20.3274 12.5988 19.9538 13.1366 19.4123C14.0135 18.5306 17.3199 18.4775 18.4742 19.3268Z"
      />
    </svg>
  );
}

export default CatFaceIcon;
