import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import IconButton from '../../buttons/IconButton/IconButton';
import HideInformationIcon from '../../icons/HideInformationIcon';

function HeaderChatButton() {
  const { appStore } = RootStore;

  return (
    <IconButton customClass="header__chat-button" color="blue" onClick={appStore.changeHideInformationStatus}>
      <HideInformationIcon style={{ width: '24px', height: '24px', display: 'block' }} />
    </IconButton>
  );
}

export default observer(HeaderChatButton);
