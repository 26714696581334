import React from 'react';

function DelegatingIcon({ style, color }) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37483 8.82353H13.2353V12.7941H7.05886C4.86596 12.7941 3.08827 14.5718 3.08827 16.7647V21.1765H1.73227C0.778547 21.1765 0 21.9485 0 22.9087V28.2677C0 29.2215 0.772059 30 1.73227 30H7.09126C8.04498 30 8.82353 29.2215 8.82353 28.2677V22.9087C8.82353 21.955 8.05147 21.1765 7.09126 21.1765H5.73533V16.7647C5.73533 16.0338 6.32789 15.4412 7.05886 15.4412H13.2353V21.1765H12.3205C11.3668 21.1765 10.5883 21.9485 10.5883 22.9087V28.2677C10.5883 29.2215 11.3603 30 12.3205 30H17.6795C18.6332 30 19.4118 29.2215 19.4118 28.2677V22.9087C19.4118 21.955 18.6397 21.1765 17.6795 21.1765H16.7647V15.4412H22.9412C23.6722 15.4412 24.2647 16.0338 24.2647 16.7647V21.1765H22.9087C21.955 21.1765 21.1764 21.9485 21.1764 22.9087V28.2677C21.1764 29.2215 21.9485 30 22.9087 30H28.2677C29.2214 30 30 29.2215 30 28.2677V22.9087C30 21.955 29.2279 21.1765 28.2677 21.1765H26.9118V16.7647C26.9118 14.5718 25.1341 12.7941 22.9412 12.7941H16.7647V8.82353H20.6252C21.5789 8.82353 22.3575 8.04498 22.3575 7.09126V1.73227C22.3575 0.778548 21.5854 0 20.6252 0H9.37483C8.42111 0 7.64257 0.77206 7.64257 1.73227V7.09126C7.64257 8.04498 8.41462 8.82353 9.37483 8.82353Z"
        fill={color ? color : '#C6A788'}
      />
    </svg>
  );
}

export default DelegatingIcon;
