import React from 'react';
import { observer } from 'mobx-react';
import classNames from '../../../common/classNames';
import './style.scss';
import { CAR_STATE } from '../../../store/constants';
import NumberInput from '../../NumberInput/NumberInput';

/**
 *   type принимает 'small' и 'big'
 *   color принимает 'white' и 'beige'
 *   size принимает 'min'
 *   count принимает int
 *   isAvailable принимает bool
 *   isShowCount принимает bool
 *   onChange func
 *   value int
 */

function Car({
  state = CAR_STATE.DEFAULT,
  type,
  color,
  size,
  count,
  isAvailable = true,
  isShowCount = false,
  onChange,
  value,
}) {
  if (count >= 0) {
    isShowCount = true;
  }

  const Count = () => {
    return (
      <span
        className={classNames({
          'corporation__car-count': true,
          'corporation__car-count--not-available': !isAvailable,
        })}
      >
        {count}
      </span>
    );
  };
  return (
    <>
      <div
        className={classNames({
          car: true,
          [`car--size-${size}`]: size,
          'car--background': color,
          [`car--background-${color}`]: color,
        })}
      >
        {state === CAR_STATE.DEFAULT && isShowCount && !color ? <Count /> : null}
        <span
          className={classNames({
            car__image: true,
            [`car__image-${type}`]: type,
            'car__image--not-available': !isAvailable,
          })}
        />
        {state === CAR_STATE.DEFAULT && isShowCount && color ? <Count /> : null}
        {state === CAR_STATE.INTERACTION && (
          <NumberInput className="car__input" icon={false} value={value} onChange={onChange} />
        )}
      </div>
    </>
  );
}

export default observer(Car);
