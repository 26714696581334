import React from 'react';

function HelpIcon({ style, stylePath, fill }) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.84479 2.68291C9.53214 2.81861 8.95163 3.25542 8.55462 3.65352L7.8329 4.37753L7.11574 3.84727C3.63291 1.27361 -0.81652 5.25776 0.867934 9.44178C1.6922 11.489 4.41875 13.8634 7.22511 14.978L8.53984 15.5L9.81751 14.8187C11.5063 13.9182 14.1023 11.1867 14.9041 9.46656C16.4596 6.12972 14.8354 2.7176 11.5934 2.51112C10.9442 2.46982 10.1574 2.54722 9.84479 2.68291ZM9.11831 6.97456L9.18766 8.09549L10.0278 8.09903C11.2296 8.10423 11.4799 8.34564 11.3953 9.41749C11.323 10.3336 11.3185 10.3376 10.2548 10.4089L9.18653 10.4801L9.11786 11.5888L9.04896 12.6972H8.02574H7.00252L6.93317 11.5763C6.86518 10.4754 6.85016 10.4553 6.09912 10.4553C4.85647 10.4553 4.61499 10.2633 4.61499 9.27542C4.61499 8.28522 4.85578 8.09549 6.11162 8.09549C6.87677 8.09549 6.88882 8.07969 6.88882 7.07297C6.88882 6.51038 6.96181 5.97445 7.05117 5.88195C7.14031 5.7892 7.62645 5.74508 8.13124 5.78354L9.04896 5.85363L9.11831 6.97456Z"
        fill={fill}
        style={stylePath}
      />
    </svg>
  );
}

export default HelpIcon;
