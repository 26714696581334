import React, { useEffect } from 'react';
import { observe } from 'mobx';
import RootStore from '../../../store';
import classNames from '../../../common/classNames';
import CoinIcon from '../../icons/CoinIcon';

function HeaderBalance() {
  const { appStore } = RootStore;
  let [balanceChangingAnimation, setBalanceChangingAnimation] = React.useState(false);
  let [balanceChangingBalance, setBalanceChangingBalance] = React.useState(appStore.balance);
  let [balanceChangingDelta, setBalanceChangingDelta] = React.useState(0);
  let [withHideInformation, setWithHideInformation] = React.useState(appStore.withHideInformation);

  useEffect(() => {
    observe(appStore, 'balance', (change) => {
      setBalanceChangingAnimation(true);
      setBalanceChangingDelta(change.newValue - change.oldValue);
      setTimeout(() => {
        setBalanceChangingBalance(change.newValue);
        setTimeout(() => {
          setBalanceChangingAnimation(false);
        }, 500);
      }, 500);
    });
    observe(appStore, 'withHideInformation', (change) => {
      setWithHideInformation(change.newValue);
    });
  }, []);

  return (
    <p className="coins notranslate">
      <CoinIcon />
      <span
        className={classNames({
          user_balance: true,
          user_balance_changing: balanceChangingAnimation,
        })}
      >
        {!withHideInformation ? balanceChangingBalance : '---'}
      </span>
      {balanceChangingAnimation ? (
        <span
          className={classNames({
            user_balance_delta: true,
            user_balance_delta_plus: balanceChangingDelta > 0,
            user_balance_delta_minus: balanceChangingDelta < 0,
          })}
        >
          <span className="user_balance_delta_value">
            {balanceChangingDelta > 0 ? '+' + balanceChangingDelta : balanceChangingDelta}
          </span>
          <CoinIcon />
        </span>
      ) : null}
    </p>
  );
}

export default React.memo(HeaderBalance, () => true);
