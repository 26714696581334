import React from 'react';
import RootStore from '../../../store';
import trainingStore from '../../../store/trainingStore';
import { CREDIT_TYPE_NAME, CREDIT_TYPE_NOTIFICATION } from '../../../store/constants';
import { observer } from 'mobx-react';
import Modal from './../Modal/Modal';
import TextButton from '../../buttons/TextButton/TextButton';
import { CoinIcon } from '../../icons';
import BankLogo from './../../icons/BankLogo';
import './../Modal/style.scss';
import './CreditNotificationModal.scss';
import * as teamMode from '../../../store/teamMode';

function CreditNotificationModal() {
  const { appStore } = RootStore;

  if (!appStore.creditNotificationInfo) {
    return null;
  }

  let price;

  if (appStore.creditNotificationInfo.status === CREDIT_TYPE_NOTIFICATION.REPAY_EARLY) {
    price =
      appStore.creditNotificationInfo.early_repayment_body + appStore.creditNotificationInfo.early_repayment_percent;
  } else if (appStore.creditNotificationInfo.status === CREDIT_TYPE_NOTIFICATION.PAYOUT) {
    price = appStore.creditNotificationInfo.total_money_quantity;
  } else {
    price = appStore.creditNotificationInfo.money_quantity;
  }

  const creditName = CREDIT_TYPE_NAME[appStore.creditNotificationInfo.credit_type];

  let title = '';
  let colorBG = '';
  let desc = '';
  if (appStore.creditNotificationInfo.status === CREDIT_TYPE_NOTIFICATION.APPROVED) {
    title = `Вам одобрен ${creditName}`;
    colorBG = 'green';
  } else if (appStore.creditNotificationInfo.status === CREDIT_TYPE_NOTIFICATION.NOT_APPROVED) {
    title = `Вам не одобрен ${creditName}`;
    colorBG = 'red';
  } else if (appStore.creditNotificationInfo.status === CREDIT_TYPE_NOTIFICATION.NOT_CANT_CALC) {
    title = `Вы не можете рассчитать ${creditName}`;
    colorBG = 'red';
  } else if (appStore.creditNotificationInfo.status === CREDIT_TYPE_NOTIFICATION.REPAY_EARLY) {
    title = 'Вы погасили кредит досрочно';
    colorBG = 'green';
  } else if (appStore.creditNotificationInfo.status === CREDIT_TYPE_NOTIFICATION.PAYOUT) {
    title = 'Оплата кредита';
    desc = creditName;
    colorBG = 'green';
  } else if (appStore.creditNotificationInfo.status === CREDIT_TYPE_NOTIFICATION.NOT_CANT_REPAY) {
    title = 'Вы не можете погасить кредит досрочно';
    colorBG = 'red';
  } else if (appStore.creditNotificationInfo.status === CREDIT_TYPE_NOTIFICATION.OVER_AVAILABLE_CREDIT_SUM) {
    title = 'Вы превысили доступную сумму кредитования';
    colorBG = 'red';
  } else if (appStore.creditNotificationInfo.status === CREDIT_TYPE_NOTIFICATION.CREDIT_NOT_FOUND) {
    title = 'Такого кредита больше не существует';
    colorBG = 'red';
  }

  return (
    <Modal
      isOpen={!!appStore.creditNotificationInfo}
      onClose={appStore.creditNotificationModalClose}
      title={title}
      desc={desc}
      colorBg={colorBG}
      customClass="credit-notification-modal"
      shouldCloseOnOverlayClick={!trainingStore.currentStep}
    >
      <div className="modal__body_credit-notification-actions">
        <BankLogo color={'#FDF6EE'} size="huge" className="modal__credit-notification__svg" />
        <div className="modal__credit-notification-text">
          в размере
          <div className="modal__credit-notification__money-block">
            <CoinIcon />
            <div className="modal__credit-notification-text notranslate">{price}</div>
          </div>
        </div>
        <TextButton
          color="blue"
          title="Понятно"
          customClass="modal__credit-notification-button"
          onClick={appStore.creditNotificationModalClose}
        />
      </div>
    </Modal>
  );
}

export default observer(CreditNotificationModal);
