import React from 'react';
import { observer } from 'mobx-react';
import corpStore from '../../../store/CorpStore';
import { CAR_STATE, CAR_TYPE, CAR_SIZE, CAR_ACTION_TYPE } from '../../../store/constants';
import Car from '../../../ui/components/Сar/Car';
import NumberInput from '../../../ui/NumberInput/NumberInput';
import TextButton from '../../../ui/buttons/TextButton/TextButton';
import './style.scss';
import { LockIcon } from '../../../ui/icons';
import rootStore from '../../../store/';

function CarsField() {
  if (corpStore.myActiveCompanies.length < 2) {
    return (
      <div className="lot-area lot-area--shop lot-area--shop-disallow">
        <LockIcon />
      </div>
    );
  }
  const myAvailableCars = corpStore.myAvailableCars;
  const CarItem = ({ type, state, count, onChange, value }) => {
    return <Car type={type} color="white" state={state} count={count} onChange={onChange} value={value} />;
  };

  const CarModel = observer(({ type, title, state }) => {
    const currentCars = corpStore.getCurrentCars();

    return (
      <div className="car-area__field">
        <span className="car-area__field-title">{title}</span>
        {state === CAR_STATE.DEFAULT && <CarItem state={state} type={type} count={myAvailableCars[type].length} />}
        {state === CAR_STATE.INTERACTION && (
          <div className="car-area__field-interaction">
            <CarItem
              state={state}
              type={type}
              onChange={(newValue) => corpStore.changeCountCars(type, newValue)}
              value={currentCars[type] || ''}
            />
            <div className="car-area__field-interaction-input">
              <NumberInput readOnly value={corpStore.carCost[CAR_ACTION_TYPE.RENT][type]} />
              <NumberInput readOnly value={corpStore.carCost[CAR_ACTION_TYPE.BUY][type]} />
            </div>
            <div className="car-area__field-actions">
              <TextButton
                customClass="car-area__field-button"
                color="green"
                title="арендовать"
                isDisabled={!currentCars[type] || rootStore.appStore.isCurrentTurnPreliminary}
                onClick={() =>
                  corpStore.openConfirmationActionWithCar({
                    get_type: CAR_ACTION_TYPE.RENT,
                    car_size: type,
                    amount: currentCars[type],
                  })
                }
              />
              <TextButton
                customClass="car-area__field-button"
                color="green"
                title="купить"
                isDisabled={!currentCars[type] || rootStore.appStore.isCurrentTurnPreliminary}
                onClick={() =>
                  corpStore.openConfirmationActionWithCar({
                    get_type: CAR_ACTION_TYPE.BUY,
                    car_size: type,
                    amount: currentCars[type],
                  })
                }
              />
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="car-area">
      <CarModel
        type={CAR_TYPE.SMALL}
        state={CAR_STATE.INTERACTION}
        title={`машина на ${CAR_SIZE[CAR_TYPE.SMALL]} котиков:`}
      />
      <CarModel
        type={CAR_TYPE.BIG}
        state={CAR_STATE.INTERACTION}
        title={`машина на ${CAR_SIZE[CAR_TYPE.BIG]} котиков:`}
      />
      <div className="car-area__wrapper">
        <CarModel type={CAR_TYPE.SMALL} state={CAR_STATE.DEFAULT} title="доступно машин:" />
        <CarModel type={CAR_TYPE.BIG} state={CAR_STATE.DEFAULT} title="доступно машин:" />
      </div>
    </div>
  );
}

export default observer(CarsField);
