import React from 'react';

function ArrowLeftIcon({ style }) {
  return (
    <svg width="31" height="24" viewBox="0 0 31 24" fill="#C6A483" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M0.017334 11.9891C0.017334 10.549 6.1462 5.03041 12.4781 0.768792C14.4917 -0.58621 14.7857 -0.313254 14.7857 2.91025V5.87654L16.5164 6.13191C17.4685 6.27208 20.22 6.6084 22.6314 6.87903C25.0428 7.14966 27.6905 7.47906 28.5157 7.6109L30.0156 7.85051V11.7249C30.0156 16.2368 30.8638 15.6845 22.1699 16.835C14.098 17.9036 14.7857 17.5155 14.7857 21.0018C14.7857 22.7547 14.5877 24 14.3094 24C12.33 24 0.017334 13.6522 0.017334 11.9891Z"
        fill="inherit"
      />
    </svg>
  );
}

export default ArrowLeftIcon;
