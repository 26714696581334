import { useState, useEffect } from 'react';

function useWindow() {
  const [isMobile, setIsMobile] = useState(false);
  const [needToTurnOver, setNeedToTurnOver] = useState(false);

  const checkWidth = (e) => {
    // debugger;
    const width = e?.target?.outerWidth || document.body.clientWidth;
    const height = e?.target?.outerHeight || document.body.clientHeight;

    if (height > width) {
      setNeedToTurnOver(true);
    } else {
      setNeedToTurnOver(false);
    }
    if (width < 1201) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    checkWidth();
    window.addEventListener('resize', checkWidth);
    return () => window.removeEventListener('resize', checkWidth);
  }, []);

  return { isMobile, needToTurnOver };
}

export default useWindow;
