import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import { SPECIALITIES_RUSSIAN_NAME } from '../../common/constants';

function PlayerTimedOutForCaptainModal() {
  const { appStore } = RootStore;

  if (!appStore.playerTimedOutForCaptain) {
    return null;
  }

  const specialty = appStore.companyPlayerById(appStore.playerTimedOutForCaptain)?.specialty;
  return (
    <Modal
      isOpen={!!appStore.playerTimedOutForCaptain}
      onClose={appStore.playerTimedOutForCaptainModalClose}
      title={`${SPECIALITIES_RUSSIAN_NAME[specialty]} ПОКИНУЛ ИГРУ`}
      desc="Назначьте игрока для выполнения его функций"
    >
      <div className="modal__body-actions">
        <TextButton color="purple" title="Понятно" onClick={appStore.playerTimedOutForCaptainModalClose} />
        <TextButton
          color="blue"
          title="Назначить"
          onClick={() => {
            appStore.playerTimedOutForCaptainModalClose();
            appStore.openDelegatingModal();
          }}
        />
      </div>
    </Modal>
  );
}

export default observer(PlayerTimedOutForCaptainModal);
