import React, { useRef } from 'react';
import { DropContainer } from '../DropContainer';
import Cat, { CAT_STATE } from '../../../ui/components/Cat/Cat';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import classNames from '../../../common/classNames';
import TextButton from '../../../ui/buttons/TextButton/TextButton';
import { HeartIcon } from '../../../ui/icons';
import { TASK_TYPES } from '../../../store/constants';
import corpStore from '../../../store/CorpStore';
import { Swiper, SwiperSlide } from 'swiper/react';
import useWindow from '../../../ui/hooks/useWindow';
import { DRAG_AREA, MATING_STATUS, SPECIALITIES } from '../../../common/constants';

const CatMemo = React.memo(Cat, (prevProps, nextProps) => {
  return _.isEqual(_.pick(prevProps, ['cat', 'dragAround']), _.pick(nextProps, ['cat', 'dragAround']));
});

const FamilyBox = observer(({ item, right, catCanDragAround }) => {
  const { appStore } = RootStore;
  let data = !right ? item.left : item.right;
  let fromLeft = !right ? DRAG_AREA.NURSERY_HOUSE_LEFT_LEFT : DRAG_AREA.NURSERY_HOUSE_RIGHT_LEFT;
  let fromRight = !right ? DRAG_AREA.NURSERY_HOUSE_LEFT_RIGHT : DRAG_AREA.NURSERY_HOUSE_RIGHT_RIGHT;
  let childDrag = !right ? DRAG_AREA.NURSERY_HOUSE_CHILD_LEFT : DRAG_AREA.NURSERY_HOUSE_CHILD_RIGHT;

  const draggableOptions = {
    canDrag: (data, from, element) => {
      return data.mating_status !== MATING_STATUS.MATING && !appStore.meIsCEO;
    },
  };

  const myTasks = appStore.myTasks;

  const isMating = (halfHouse) => {
    return (
      halfHouse?.left_cat?.mating_status === MATING_STATUS.MATING ||
      halfHouse?.right_cat?.mating_status === MATING_STATUS.MATING
    );
  };
  const isMatingDisabled = (halfHouse) => {
    return (
      !halfHouse.canMating ||
      (appStore.isTeamMode &&
        (isTaskForMatingAlreadyCreated(halfHouse) || !appStore.hasSpecialities([SPECIALITIES.DOCTOR])))
    );
  };
  const isTaskForMatingAlreadyCreated = (halfHouse) => {
    return myTasks.find((task) => {
      return (
        task.type === TASK_TYPES.MATING &&
        task.status === 'inprogress' &&
        task.content.house_id === halfHouse.data.house_id
      );
    });
  };

  return (
    <div className="nursery-box__family">
      <div className="nursery-box__old-cats">
        <div
          className={classNames({
            'nursery-box__old-cats-window': true,
            'nursery-box__old-cats-window--hearts': isMating(data),
          })}
        >
          <DropContainer
            type={fromLeft}
            element={({ canDrop, drop }) => (
              <div ref={drop} className="nursery-box__old-cat-card">
                {data.left_cat && (
                  <CatMemo
                    dragAround={catCanDragAround}
                    from={fromLeft}
                    cat={data.left_cat}
                    state={CAT_STATE.NURSERY}
                    draggableOptions={draggableOptions}
                    onDoubleClick={() => {
                      appStore.dropItem({
                        from: fromLeft,
                        data: data.left_cat,
                        to: DRAG_AREA.NURSERY_CATS,
                      });
                    }}
                  />
                )}
              </div>
            )}
          />
          <DropContainer
            type={fromRight}
            element={({ canDrop, drop }) => (
              <div ref={drop} className="nursery-box__old-cat-card">
                {data.right_cat && (
                  <CatMemo
                    dragAround={catCanDragAround}
                    from={fromRight}
                    cat={data.right_cat}
                    state={CAT_STATE.NURSERY}
                    draggableOptions={draggableOptions}
                    onDoubleClick={() => {
                      appStore.dropItem({
                        from: fromRight,
                        data: data.right_cat,
                        to: DRAG_AREA.NURSERY_CATS,
                      });
                    }}
                  />
                )}
              </div>
            )}
          />
        </div>
        <TextButton
          isDisabled={isMatingDisabled(data)}
          onClick={() => appStore.catsMating(data.data.house_id)}
          color="green"
          title={
            <>
              <HeartIcon />
              СКРЕСТИТЬ
            </>
          }
          customClass={'nursery-box__cats-action'}
        />
      </div>
      <div className="nursery-box__kittens">
        {data.child_cats.map((item, index) => (
          <div key={item.cat_id} className="nursery-box__kitten">
            <CatMemo
              dragAround={catCanDragAround}
              from={childDrag}
              key={index}
              cat={item}
              state={CAT_STATE.NURSERY}
              draggableOptions={draggableOptions}
              onDoubleClick={() => {
                appStore.dropItem({
                  from: childDrag,
                  data: item,
                  to: DRAG_AREA.NURSERY_CATS,
                });
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
});

function GroupedHouse({ item, catCanDragAround, index }) {
  const housesCount = RootStore.appStore.houses.length;
  return (
    <div
      className={classNames({
        'own-nurseries__houses-item': true,
        'own-nurseries__houses-item--single-house': housesCount === 2,
        'own-nurseries__houses-item--insured': item.insured,
      })}
    >
      <div className="own-nurseries__houses-tablet">
        <div className="own-nurseries__houses-number_box">
          <div className="own-nurseries__houses-number">{index}</div>
        </div>
      </div>
      <div className="own-nurseries__nursery-box nursery-box">
        <FamilyBox item={item} catCanDragAround={catCanDragAround} />
        <FamilyBox item={item} right catCanDragAround={catCanDragAround} />
      </div>
    </div>
  );
}

const GroupedHouseMemo = React.memo(GroupedHouse, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
});

function HouseSlider() {
  const { appStore } = RootStore;
  const swiperRef = useRef(null);
  const { isMobile } = useWindow();
  let groupedHouses;
  if (appStore.meIsCEO) {
    groupedHouses = corpStore.currentAreaGroupedHouses;
  } else {
    groupedHouses = appStore.groupedHouses;
  }
  const housesCount = appStore.houses.length;

  let slidesPerView;
  if (housesCount === 2) {
    slidesPerView = 1;
  } else if (isMobile) {
    slidesPerView = 1.3;
  } else {
    slidesPerView = 1.26;
  }
  return (
    housesCount > 0 && (
      <section className="own-nurseries__houses">
        <div className="own-nurseries__wood"></div>
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={(swiper) => {
            appStore.setCurrentGroupedHousesIndex(swiper.activeIndex);
          }}
          touchStartPreventDefault={false}
          speed={500}
          threshold={0}
          touchAngle={40}
          noSwiping={groupedHouses.length === 1}
          noSwipingSelector=".own-nurseries__houses-item"
          slidesPerView={slidesPerView}
          grabCursor={true}
          className="own-nurseries__houses-slider"
          centerInsufficientSlides={true}
          centeredSlides={true}
          virtual
          lazy
        >
          {groupedHouses.map((item, index) => (
            <SwiperSlide key={item.index} virtualIndex={index}>
              <GroupedHouseMemo item={item} catCanDragAround={groupedHouses.length === 1} index={index + 1} />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    )
  );
}

export default observer(HouseSlider);
