import React from 'react';
import RootStore from '../../../../../store';
import classNames from '../../../../../common/classNames';
import { observer } from 'mobx-react';
import {
  ArrowRightIcon,
  ReplyIcon,
  ArrowLeftIcon,
  CloseIcon,
  AcceptIcon,
  RevisionIcon,
  PendingRevisionIcon,
  CatFaceIcon,
} from '../../../../icons';
import { LTD_STATUS, SPECIALITIES } from '../../../../../common/constants';

function LTDMail({ ltdInfo, size }) {
  const { appStore } = RootStore;
  let type;
  if (ltdInfo.status === LTD_STATUS.ACTIONS_PHASE) {
    type = 'confirmed';
  } else if (ltdInfo.status === LTD_STATUS.TERMS_AGREEMENT) {
    if (!ltdInfo.updater) {
      const captainUuid = appStore.playerByRealSpeciality(SPECIALITIES.CAPTAIN, ltdInfo.contents[0].area_num).player_id;

      if (appStore.me.uuid === ltdInfo.initiator || captainUuid === ltdInfo.initiator) {
        type = 'outcoming';
      } else {
        type = 'incoming';
      }
    } else if (ltdInfo.updater === appStore.me.uuid) {
      type = 'outcoming';
    } else if (ltdInfo.updater !== appStore.me.uuid) {
      type = 'counter';
    }
  } else if (
    ltdInfo.status === LTD_STATUS.TERMINATING ||
    ltdInfo.status === LTD_STATUS.TERMINATED ||
    ltdInfo.status === LTD_STATUS.CANCELED
  ) {
    type = 'cancelled';
  }

  const extraDataActions = appStore.extraDataActionsLTD.indicator.filter((item) => item === ltdInfo.deal_id);

  return (
    <div
      className={classNames({
        mail: true,
        'mail--size-mini': size === 'mini',
      })}
    >
      {extraDataActions.length !== 0 && <div className="ltd-action--indicator">{extraDataActions.length}</div>}
      <span className={`mail__icon mail__icon--${type}`}>
        {type === 'outcoming' && <ArrowRightIcon />}
        {type === 'counter' && <ReplyIcon />}
        {type === 'incoming' && <ArrowLeftIcon />}
        {type === 'cancelled' && <CloseIcon style={{ fill: '#FDF6EE' }} />}
        {type === 'confirmed' && <AcceptIcon />}
        {type === 'pendingRevision' && <PendingRevisionIcon />}
        {type === 'revision' && <RevisionIcon />}
      </span>
      <div className="mail__indicator">
        <CatFaceIcon className="mail__indicator-buy" />
      </div>
    </div>
  );
}

export default observer(LTDMail);
