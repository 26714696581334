import React from 'react';

function AcceptIcon({ style, fill, className }) {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill={fill ? fill : '#FDF6EE'}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10.7045 11.4562C11.0015 11.0348 12.7877 8.6595 14.6736 6.17816C16.5595 3.69682 18.3743 1.66663 18.7066 1.66663C19.4054 1.66663 23.3216 5.82095 23.3333 6.57482C23.3491 7.56937 11.5674 23.8888 10.8338 23.8888C10.0643 23.8888 1.11108 11.8486 1.11108 10.8134C1.11108 10.1203 4.37247 6.69807 5.03291 6.69807C5.28351 6.69807 6.54093 7.94126 7.82665 9.46034C9.98784 12.0142 10.2049 12.1647 10.7045 11.4562Z"
        fill="inherit"
      />
    </svg>
  );
}

export default AcceptIcon;
