import React from 'react';

function BasketIcon({ style }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.93575 1.68097C5.80473 1.59 5.8715 1.56968 5.8715 0.79387V0H9.89767H13.9238V0.806452C13.9238 1.60742 13.945 1.6129 16.9619 1.6129H20L19.8977 3.14516L19.7953 4.67742H9.89767H0V3.22581V1.77419L2.93575 1.68097Z"
        fill="#FDF6EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.73493 19.2742C3.20549 18.4642 1.66516 7.63774 2.00436 7.11032C2.1842 6.83032 4.78811 6.60097 4.90051 6.85484C4.9203 6.89935 5.18435 9.18548 5.48766 11.9355L6.03926 16.9355L7.2283 17.0316L8.41706 17.1277L7.88626 11.951L7.35514 6.77419H9.96848H12.5818L12.5593 7.82258C12.5469 8.39903 12.3526 10.7216 12.1275 12.9839L11.7185 17.0968H12.821C13.6061 17.0968 13.9238 16.9487 13.9238 16.5829C13.9238 15.6571 14.6294 8.24323 14.7881 7.5C14.921 6.87742 15.1572 6.77419 16.4466 6.77419C17.6417 6.77419 17.95 6.88742 17.95 7.32613C17.95 9.17581 16.4436 18.6723 16.055 19.2742C15.5984 19.9806 15.434 20 9.89767 20C4.36303 20 4.1966 19.9803 3.73493 19.2742Z"
        fill="#FDF6EE"
      />
    </svg>
  );
}

export default BasketIcon;
