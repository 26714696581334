import React, { useEffect, useRef } from 'react';
import Modal from '../Modal/Modal';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import TextButton from '../../buttons/TextButton/TextButton';
import './styles.scss';
import EatIcon from '../../icons/EatIcon';
import MinusIcon from '../../icons/MinusIcon';
import PlusIcon from '../../icons/PlusIcon';
import { CoinIcon, SendIcon } from '../../icons';
import Price from '../Cat/Price';
import { TEAM_TASK_NOTIFICATIONS } from '../TeamNotificationsModal/TeamTaskNotificationsModal';
import SicknessRingworm from '../../icons/SicknessRingworm';
import SicknessFracture from '../../icons/SicknessFracture';
import SicknessPoisoning from '../../icons/SicknessPoisoning';
import SicknessFleas from '../../icons/SicknessFleas';

function BuyDrugsModal() {
  const { appStore } = RootStore;
  const drugs = appStore.goods.drugs;
  const [bandageCount, setBandageCount] = React.useState(drugs.bandage);
  const [pillsCount, setPillsCount] = React.useState(drugs.pills);
  const [salveCount, setSalveCount] = React.useState(drugs.salve);
  const [shampooCount, setShampooCount] = React.useState(drugs.shampoo);
  useEffect(() => {
    setBandageCount(0);
    setPillsCount(0);
    setSalveCount(0);
    setShampooCount(0);
  }, [appStore.buyDrugsModalData]);

  if (!appStore.buyDrugsModalData) {
    return null;
  }

  const buyDrugs = () => {
    const newDrugs = {
      bandage: bandageCount,
      pills: pillsCount,
      salve: salveCount,
      shampoo: shampooCount,
    };
    Object.keys(newDrugs).forEach((drug) => {
      if (!newDrugs[drug]) {
        delete newDrugs[drug];
      }
    });
    appStore.buyDrugs(newDrugs);
  };

  const neededDrug = appStore.buyDrugsModalData;
  const totalDrugsCost = appStore.gameCosts.treatment * (bandageCount + pillsCount + salveCount + shampooCount);
  const buyDrugsData = () => {
    return Object.keys(drugs).map((drugKey, index) => {
      // const currentDrugCount = drugs[drugKey];
      let newDrugCount = 0;
      let setNewDrugCount = (num) => num;
      let Icon = null;
      if (drugKey === 'bandage') {
        newDrugCount = bandageCount;
        setNewDrugCount = setBandageCount;
        Icon = SicknessFracture;
      } else if (drugKey === 'pills') {
        newDrugCount = pillsCount;
        setNewDrugCount = setPillsCount;
        Icon = SicknessPoisoning;
      } else if (drugKey === 'salve') {
        newDrugCount = salveCount;
        setNewDrugCount = setSalveCount;
        Icon = SicknessRingworm;
      } else if (drugKey === 'shampoo') {
        newDrugCount = shampooCount;
        setNewDrugCount = setShampooCount;
        Icon = SicknessFleas;
      }
      return (
        <div className="modal__body-quantity-block-item" key={index}>
          <div style={{ background: neededDrug === drugKey ? '#5AA122' : '#F57212' }} className="action-icon__wrapper">
            <Icon style={{ width: 20, height: 20, fill: '#FDF6EE' }} />
          </div>
          <Price
            isPriceCanBeZero={true}
            price={newDrugCount}
            withCoin={false}
            withoutStopPropagation
            onMinus={() => {
              if (newDrugCount > 0) {
                setNewDrugCount(newDrugCount - 1);
              }
            }}
            onPlus={() => {
              setNewDrugCount(newDrugCount + 1);
            }}
          />
        </div>
      );
    });
  };
  return (
    <Modal
      isOpen={!!appStore.buyDrugsModalData}
      onClose={appStore.hideBuyDrugsModal}
      title="Покупка лекарств"
      size="big"
      exitIcon={true}
      style={{ zIndex: 101 }}
    >
      <div className="modal__body-quantity">
        <div className="modal__body-quantity-block">{buyDrugsData()}</div>
        <div className="modal__body-quantity-price">
          <div className="modal__body-quantity-price-item">
            <p className="modal__body-quantity-price-item--title">ЗА ЕДИНИЦУ:</p>
            <p className="modal__body-quantity-price-item--cost">
              <CoinIcon /> {appStore.gameCosts.treatment}
            </p>
          </div>
          <div className="modal__body-quantity-price-item">
            <p className="modal__body-quantity-price-item--title">ВСЕГО:</p>
            <p className="modal__body-quantity-price-item--cost">
              <CoinIcon /> {totalDrugsCost}
            </p>
          </div>
        </div>
      </div>
      <div className="modal__body-actions">
        <TextButton
          color="green"
          title={<SendIcon />}
          onClick={() => {
            if (!appStore.checkBalance(totalDrugsCost)) {
              appStore.catUnderMagnifierModalClose();
              appStore.hideBuyDrugsModal();
              appStore.notEnoughBalanceModalOpen();
              return;
            }
            buyDrugs();
          }}
          isDisabled={bandageCount + pillsCount + salveCount + shampooCount === 0}
        />
      </div>
    </Modal>
  );
}

export default observer(BuyDrugsModal);
