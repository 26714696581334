import React from 'react';
import LTDStepsApiParams from '../base/LTDStepsApiParams';
import LTDChapter from '../base/LTDChapter';
import LTDStep from '../base/LTDStep';
import {
  CAT_COLOR,
  CAT_GENDER,
  CUSTOM_ACTION_NAMES,
  EDITABLE_FIELD,
  LTD_CONTENT_TYPE,
  LTD_FINES_PAYMENT,
  TIMER_ID,
} from '../../../../common/constants';
import { INFO_MODAL_POSITION } from '../../base/constants';
import RootStore from '../../../index';
import checkObjectParams from '../../../../common/checkObjectParams';
import { LTD_TERMINATION } from '../../../constants';
import { LocalStorage } from '../../../../common/localStorage';
import api from '../../../../api';
import generateLTDChapterName from '../base/methods/generateLTDChapterName';

const steps = () => [
  new LTDStep({
    selectedLTDTab: LTD_CONTENT_TYPE.SELL,
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Обеспечение</div>,
    message: (
      <p className="training-modal__desc">
        В игре возможна выдача банковской гарантии. В случае нарушений контракта одна из сторон может обанкротиться и
        часть задолженности будет выплачивать банк
      </p>
    ),
    buttonText: 'Дальше',
    nextButton: true,
    elements: ['.ltd__bank'],
    disabledElements: ['.ltd__bank'],
    position: INFO_MODAL_POSITION.TOP,
    selectorIntoView: '.ltd__accept',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Обеспечение</div>,
    message: (
      <p className="training-modal__desc">
        В реальной жизни для предоставления гарантий банк берет в качестве обеспечения имущество клиента, в данной игре
        в качестве обеспечения может выступать земельный участок
      </p>
    ),
    buttonText: 'Дальше',
    nextButton: true,
    elements: ['.ltd__bank'],
    disabledElements: ['.ltd__bank'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: 'changeLTDBankGuarantee',
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams() {
      return checkObjectParams({
        object: RootStore.appStore.currentLTD,
        params: {
          [`${EDITABLE_FIELD.PARTNER_BANK_GUARANTEE}.${EDITABLE_FIELD.AMOUNT}`]: 30,
          [`${EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE}.${EDITABLE_FIELD.AMOUNT}`]: 30,
        },
      });
    },
    removeFocusFromElements: true,
    title: <div>Обеспечение</div>,
    message: (
      <p className="training-modal__desc">
        Введем в оба поля по 30 монет, тем самым обезопасим сделку с обеих сторон и будем уверены, что банк выплатит до
        30 монет, если это потребуется
      </p>
    ),
    elements: ['.ltd__bank'],
    disabledElements: ['.ltd-bank-guarantee__conditions-button'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: 'openLTDBankGuaranteeModal',
    title: <div>Обеспечение</div>,
    message: <p className="training-modal__desc">Обязательно ознакомимся с условиями банковской гарантии</p>,
    elements: ['.ltd-bank-guarantee__conditions-button'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    shadedElements: ['.modal--bank-guarantee'],
    title: <div>Условия банковской гарантии</div>,
    message: <p className="training-modal__desc">Банковская гарантия действует на протяжении всей сделки</p>,
    elementsForChangeZIndex: {
      '.ReactModal__Overlay.ReactModal__Overlay--after-open.modal-overlay': {
        newZIndex: 99,
        returnOriginalZIndexIsNeeded: false,
      },
    },
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    shadedElements: ['.modal--bank-guarantee'],
    title: <div>Условия банковской гарантии</div>,
    message: <p className="training-modal__desc">Мы можем выбрать один из двух способов оплаты банковской гарантии</p>,
    nextButton: true,
    elements: ['.modal-bank_guarantee__body-element:nth-child(1)', '.modal-bank_guarantee__body-element:nth-child(2)'],
    disabledElements: [
      '.modal-bank_guarantee__body-element:nth-child(1)',
      '.modal-bank_guarantee__body-element:nth-child(2)',
    ],
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    shadedElements: ['.modal--bank-guarantee'],
    title: <div>Условия банковской гарантии</div>,
    message: (
      <p className="training-modal__desc">
        Единоразовый платеж – оплачивается в сезон заключения сделки и составляет 5% от суммы гарантии
      </p>
    ),
    nextButton: true,
    elements: ['.modal-bank_guarantee__body-element:nth-child(1)'],
    disabledElements: ['.modal-bank_guarantee__body-element:nth-child(1)'],
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    shadedElements: ['.modal--bank-guarantee'],
    title: <div>Условия банковской гарантии</div>,
    message: (
      <p className="training-modal__desc">
        Периодический платеж – оплата делится на несколько сезонов. К базовым 5% прибавляется по 1% за каждый сезон
        оплаты, и итоговая сумма делится на количество сезонов оплаты
      </p>
    ),
    nextButton: true,
    elements: ['.modal-bank_guarantee__body-element:nth-child(2)'],
    disabledElements: ['.modal-bank_guarantee__body-element:nth-child(2)'],
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: 'hideLTDBankGuaranteeModal',
    shadedElements: ['.modal--bank-guarantee'],
    title: <div>Банковская гарантия</div>,
    message: (
      <p className="training-modal__desc">
        Принимаем текущие условия банковской гарантии, оставляя единоразовую Выплату
      </p>
    ),
    position: INFO_MODAL_POSITION.TOP,
    elements: ['.modal__body-actions .text_button'],
    selectorIntoView: '.modal__body-actions',
    after() {
      RootStore.appStore.tutorialProgress.chapter++;
    },
  }),
];

const chapter4 = () =>
  new LTDChapter({
    title: 'Обеспечение',
    chapter: generateLTDChapterName(4),
    stepsApiParams: LTDStepsApiParams,
    defaultStepSettings: {
      hiddenTimersIds: [TIMER_ID.HEADER.SESSION_TIMER],
      needOverlayClickModal: false,
    },
    callback() {
      RootStore.appStore.hideIntroductionModal();
      RootStore.appStore.forceSetCurrentLTD({
        initiator: LocalStorage.ORIGINAL_TRAINING_USER_UID,
        partner: '00000000-0000-0000-0000-000000000021',
        type_ltd: LTD_CONTENT_TYPE.BUY_SELL,
        status: null,
        updater: null,
        contents: [
          {
            buyer: LocalStorage.ORIGINAL_TRAINING_USER_UID,
            seller: '00000000-0000-0000-0000-000000000021',
            prices_isDefaultValue: false,
            area_num: null,
            shipping_season: 2,
            storage_timer: 10,
            shipping_timer: 5,
            abstract_contents: [
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.WHITE,
                price: 10,
                count: 2,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.WHITE,
                price: 11,
                count: 2,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
            ],
            prepaid: {
              season: 1,
              money: 12,
              timer: 15,
            },
            payment_before_shipment: {
              season: 2,
              money: 0,
              timer: 8,
            },
            payment_after_shipment: {
              season: 2,
              money: 30,
              timer: 3,
            },
            shipping_season_isDefaultValue: true,
          },
          {
            seller: LocalStorage.ORIGINAL_TRAINING_USER_UID,
            buyer: '00000000-0000-0000-0000-000000000021',
            prices_isDefaultValue: true,
            shipping_season: 6,
            storage_timer: 15,
            shipping_timer: 10,
            abstract_contents: [
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.WHITE,
                price: 8,
                count: 8,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.WHITE,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
            ],
            prepaid: {
              season: 6,
              money: 19,
              timer: 20,
            },
            payment_before_shipment: {
              season: 6,
              money: 38,
              timer: 13,
            },
            payment_after_shipment: {
              season: 6,
              money: 7,
              timer: 5,
            },
          },
        ],
        overdue_payment_liability: {
          payment_type: LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
          value: 120,
        },
        overdue_delivery_liability: {
          payment_type: LTD_FINES_PAYMENT.PCT_OF_MISSED_CATS,
          value: 120,
        },
        low_quality_liability: {
          payment_type: LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
          value: 80,
        },
        initiator_bank_guarantee: {
          amount: 0,
          last_payment_season: 2,
          isPeriodicPayment: false,
        },
        partner_bank_guarantee: {
          amount: 0,
          last_payment_season: 2,
          isPeriodicPayment: false,
        },
        offer_bank_guarantee_amount: {
          amount: 0,
        },
        one_way_termination: {
          payment_type: LTD_TERMINATION.EMPTY,
          value: 0,
        },
        two_way_termination: {
          payment_type: LTD_TERMINATION.EMPTY,
          value: 0,
        },
      });
    },
    initialRouteParams: {
      routeName: 'longTermsDealsNew',
      params: {
        type_ltd: LTD_CONTENT_TYPE.BUY_SELL,
        uuid: '00000000-0000-0000-0000-000000000021',
      },
    },
    userIdentifierForSocketInitialization: LocalStorage.ORIGINAL_TRAINING_USER_UID,
    beforeStepsInitializationStep: new LTDStep({
      title: <div>Ожидание старта главы</div>,
      message: <p className="training-modal__desc">Ожидание...</p>,
    }),
    seamlessStartOfNextChapter: true,
    stepsGeneratorInfo: {
      generateOnChapterInitialization: false,
      generator: steps,
    },
  });

export default chapter4;
