import React from 'react';

function LongDealsIcon({ style }) {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="#FDF6EE" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.4923C0 1.11584 1.11584 0 2.4923 0H6.95926V3.82472C6.95926 5.59823 8.39698 7.03595 10.1705 7.03595H14V15.1455C14 16.522 12.8842 17.6378 11.5077 17.6378H2.4923C1.11584 17.6378 0 16.522 0 15.1455V2.4923ZM6.9976 8.05204C7.54077 8.05204 7.9811 8.49236 7.9811 9.03554V10.3469H9.29243C9.83561 10.3469 10.2759 10.7872 10.2759 11.3304C10.2759 11.8735 9.83561 12.3139 9.29243 12.3139H7.9811V13.6252C7.9811 14.1684 7.54077 14.6087 6.9976 14.6087C6.45443 14.6087 6.01411 14.1684 6.01411 13.6252V12.3139H4.70277C4.1596 12.3139 3.71927 11.8735 3.71927 11.3304C3.71927 10.7872 4.1596 10.3469 4.70277 10.3469H6.01411V9.03554C6.01411 8.49236 6.45443 8.05204 6.9976 8.05204Z"
        fill="#FDF6EE"
      />
      <path
        d="M13.27 5.09337C13.4222 5.24556 13.5527 5.4154 13.6594 5.59808H10.1705C9.19109 5.59808 8.39712 4.80412 8.39712 3.82472V0.33776C8.58163 0.445039 8.75311 0.57646 8.90663 0.729977L13.27 5.09337Z"
        fill="#FDF6EE"
      />
    </svg>
  );
}

export default LongDealsIcon;
