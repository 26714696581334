import React from 'react';

function FirstPedestal({ style, stylePath, className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#firstPedestalIcon)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.0648 18.1628L4.47086 22.7423C4.41208 22.9111 4.54886 23.0837 4.72666 23.0651L7.31242 22.7943C7.38466 22.7868 7.45647 22.8118 7.50841 22.8625L9.36723 24.6803C9.49502 24.8053 9.70942 24.7549 9.76816 24.5861L11.4548 19.7403C9.13647 19.6572 7.38616 19.0439 6.0648 18.1628Z"
          fill="#FFB800"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5449 19.7404L14.2316 24.5862C14.2903 24.755 14.5047 24.8053 14.6325 24.6804L16.4913 22.8626C16.5432 22.8119 16.615 22.7869 16.6872 22.7944L19.273 23.0652C19.4508 23.0838 19.5876 22.9112 19.5288 22.7424L17.9349 18.1631C16.6135 19.0441 14.8632 19.6573 12.5449 19.7404Z"
          fill="#FFB800"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.00042 9.32632C2.99295 9.47711 2.98917 18.25 12 18.25C21.0108 18.25 21.007 9.47716 20.9996 9.32637C20.763 4.54987 16.8242 0.75 12 0.75C7.17576 0.75 3.23697 4.54987 3.00042 9.32632ZM11.5288 12.9257C11.5288 13.4023 11.9151 13.7885 12.3916 13.7885C12.8681 13.7885 13.2544 13.4023 13.2544 12.9257V12.4694V6.07616C13.2544 5.59963 12.8681 5.21338 12.3916 5.21338H11.7194C11.4146 5.21338 11.1324 5.37421 10.9771 5.63643L9.91333 7.43202C9.91333 7.43202 9.91333 8.05541 10.3949 8.29761C11.0126 8.60825 11.5288 8.09052 11.5288 8.09052V12.4694V12.9257Z"
          fill="#FFB800"
        />
      </g>
      <defs>
        <clipPath id="firstPedestalIcon">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FirstPedestal;
