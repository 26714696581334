import React from 'react';
import CoinIcon from '../../icons/CoinIcon';
import MinusIcon from '../../icons/MinusIcon';
import PlusIcon from '../../icons/PlusIcon';
import classNames from '../../../common/classNames';
import useDoubleClick from 'use-double-click';
import trainingStore from '../../../store/trainingStore';
import RootStore from '../../../store';

function Price({
  price,
  oldPrice,
  color,
  readonly,
  bigSize,
  isFake,
  onPlus,
  onMinus,
  withoutStopPropagation,
  customClass,
  isFakeWithPrice,
  withCoin = true,
  rightCoinPosition,
  isPriceCanBeZero = false,
}) {
  const { appStore } = RootStore;

  const priceValue = (
    <div
      className={classNames({
        'price-value': true,
        [`price-value--color-${color}`]: !trainingStore.launched || trainingStore.launchedChapter >= 11,
        [`price-value--fake`]: isFake,
      })}
    >
      {withCoin && !rightCoinPosition && <CoinIcon />}
      <span
        className={classNames({
          'price-value__cost': true,
          'price-value__cost--right-coin-position': rightCoinPosition,
        })}
      >
        <span className="notranslate">{!isFake ? price : -1}</span>
        {oldPrice && <span className="price__old-price notranslate">{oldPrice}</span>}
      </span>
      {withCoin && rightCoinPosition && <CoinIcon />}
    </div>
  );

  if (isFake || isFakeWithPrice) {
    return (
      <div
        className={classNames({
          'price-wrapper': true,
          'price-wrapper--readonly': readonly,
          'price-wrapper--big-size': bigSize,
          'price-wrapper--fake': isFake || isFakeWithPrice,
          [customClass]: !!customClass,
        })}
      >
        <div className="price-minus"></div>
        <div className="price">{priceValue}</div>
        <div className="price-plus"></div>
      </div>
    );
  } else {
    const clickMinusRef = React.useRef();
    useDoubleClick({
      onSingleClick: (event) => {
        if (!withoutStopPropagation) {
          event.stopHandle = true;
        }
        if (price > 1 || (isPriceCanBeZero && price > 0)) {
          onMinus();
        }
      },
      ref: clickMinusRef,
      latency: 0,
    });
    const clickPlusRef = React.useRef();
    useDoubleClick({
      onSingleClick: (event) => {
        if (!withoutStopPropagation) {
          event.stopHandle = true;
        }
        onPlus();
      },
      ref: clickPlusRef,
      latency: 0,
    });

    return (
      <div
        className={classNames({
          'price-wrapper': true,
          'price-wrapper--readonly': readonly,
          'price-wrapper--big-size': bigSize,
          'price-wrapper--fake': isFake,
          [customClass]: !!customClass,
        })}
      >
        <div
          ref={clickMinusRef}
          className="price-minus"
          onDoubleClick={(event) => {
            event.stopPropagation();
          }}
        >
          <MinusIcon />
        </div>
        <div className="price">{priceValue}</div>
        <div
          ref={clickPlusRef}
          className="price-plus"
          onDoubleClick={(event) => {
            event.stopPropagation();
          }}
        >
          <PlusIcon />
        </div>
      </div>
    );
  }
}

export default Price;
