import React from 'react';
import { observer } from 'mobx-react';
import Modal from '../../Modal/Modal';
import { TRANSFER_TYPE, TRANSFER_TYPE_NAME } from '../../../../store/constants';
import corpStore from '../../../../store/CorpStore';
import TextButton from '../../../buttons/TextButton/TextButton';
import { CoinIcon } from '../../../icons';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../../Cat/Cat';
import CoinModalIcon from '../../../icons/modal/CoinModalIcon';
import * as teamMode from '../../../../store/teamMode';
import './style.scss';

function TransferCatsModal() {
  if (!corpStore.transferDetailDataModal) {
    return null;
  }
  const transfer = corpStore.transferDetailDataModal;
  const title = `${TRANSFER_TYPE_NAME[transfer.type]} котиков на ${transfer.season} сезон`;
  const desc =
    transfer.type === TRANSFER_TYPE.IMPORT ? `В город ${transfer.area_num}` : `Из города ${transfer.area_num}`;
  const size = 'cats';
  const groupCats = transfer.contents;

  // ToDo Доделать стили

  return (
    <Modal
      isOpen={!!corpStore.transferDetailDataModal}
      onClose={() => corpStore.hideTransferDetailModal()}
      title={title}
      desc={desc}
      size={size}
    >
      <div className="modal__body-wrapper">
        <div className="modal__body-cats">
          {groupCats.map((cat) => {
            return (
              <Cat
                key={cat.gender + cat.color}
                readonly={true}
                cat={cat}
                bottomText={BOTTOM_TEXT.KIND}
                state={CAT_STATE.SINGLE}
                realCount={true}
                customCount={cat.count}
              />
            );
          })}
        </div>

        <div className="team-notification-price team-notification-price--transfer">
          <div className="modal__body-price-text team-notification-price__title">
            Осталось {transfer.type === TRANSFER_TYPE.IMPORT ? 'заплатить' : 'получить'}
          </div>
          <div className="team-notification-price__cost team-notification-price__cost--transfer">
            <CoinModalIcon className="modal__body-price-coin-icon" />
            <span className="modal__body-price-coin-count">{transfer.residual_value}</span>
          </div>
        </div>
      </div>

      <div className="modal__body-actions">
        <TextButton color="blue" title="понятно" onClick={() => corpStore.hideTransferDetailModal()} />
      </div>
    </Modal>
  );
}

export default observer(TransferCatsModal);
