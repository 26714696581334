import RootStore from '../../../store';
import Modal from '../Modal/Modal';
import React, { useEffect, useState } from 'react';
import { LTDActions, renderTasks } from './MyTasksComponents';
import EmptyTeamTasksIcon from '../../../assets/team-tasks-empty.png';
import TeamTasksIcon from '../../../assets/team-tasks.png';
import { observer } from 'mobx-react';
import classNames from '../../../common/classNames';
import TextButton from '../../buttons/TextButton/TextButton';
import * as teamMode from '../../../store/teamMode';
import trainingStore from '../../../store/trainingStore';
import { SPECIALITIES } from '../../../common/constants';
import { TASK_TYPES, TEAM_TASKS_CATEGORY, TEAM_TASKS_CATEGORY_NAME } from '../../../store/constants';
import { PRIVILEGES } from '../../../store/teamMode';

function MyTasksModal() {
  const { appStore } = RootStore;

  const defaultCategory =
    appStore.isCorpMode || appStore.isTeamMode ? TEAM_TASKS_CATEGORY.FOR_APPROVAL : TEAM_TASKS_CATEGORY.LTD;
  const [selectedSection, setSelectedSection] = useState(defaultCategory);
  useEffect(() => {
    setSelectedSection(defaultCategory);
  }, [appStore.me]);
  if (!appStore.me || !appStore.myTasksOpened) {
    return null;
  }
  const myVisibleTasks = appStore.myVisibleTasks;

  let sections = Object.keys(appStore.sortedBySectionTeamTasks).map((sectionType) => (
    <div
      className={classNames({
        'team-tasks__section-button': true,
        'team-tasks__section-button--small': appStore.longTermsDeals.with_ltd,
        'team-tasks__section-button--active': sectionType === selectedSection,
      })}
      onClick={() => setSelectedSection(sectionType)}
      key={sectionType}
    >
      {sectionType === TEAM_TASKS_CATEGORY.FOR_APPROVAL &&
        appStore.sortedBySectionTeamTasks[sectionType].tasks.length > 0 && (
          <div className="team-tasks__unopened_tasks_count">
            {appStore.sortedBySectionTeamTasks[sectionType].tasks.length}
          </div>
        )}
      {sectionType === TEAM_TASKS_CATEGORY.LTD && appStore.extraDataActionsLTD?.indicator.length > 0 && (
        <div className="team-tasks__unopened_tasks_count">{appStore.extraDataActionsLTD?.indicator.length}</div>
      )}
      {TEAM_TASKS_CATEGORY_NAME[sectionType]}
    </div>
  ));

  let activeTasks = [];
  if (myVisibleTasks.length) {
    activeTasks = myVisibleTasks.filter((task) => {
      const isVisibleForDoctor = task.visible_to.find((spec) => spec === SPECIALITIES.DOCTOR);
      // проверка на то, что я доктор, который получил торговую заявку на осмотр и пока не поставил свою визу
      const indicateForMe =
        (task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST || task.type === TASK_TYPES.CITY_TRADE) &&
        isVisibleForDoctor &&
        task.visas[SPECIALITIES.DOCTOR] === undefined &&
        appStore.hasSpecialities([SPECIALITIES.DOCTOR]);
      return indicateForMe || (appStore.hasSpecialities([task.stage]) && task.status === 'inprogress');
    });
  }

  function close() {
    appStore.hideMyTasksModal();

    if (!appStore.isCorpMode && !appStore.isTeamMode) {
      setSelectedSection(TEAM_TASKS_CATEGORY.LTD);
    } else {
      setSelectedSection(TEAM_TASKS_CATEGORY.FOR_APPROVAL);
    }
  }

  let taskIsClickable = true;
  if (selectedSection === TEAM_TASKS_CATEGORY.REPORT) {
    taskIsClickable = false;
  }

  return (
    <Modal
      isOpen={appStore.myTasksOpened}
      shouldCloseOnOverlayClick={trainingStore.currentStep?.needOverlayClickModal ?? true}
      onClose={close}
      title={
        <p
          className={classNames({
            'team-tasks__title': true,
            'team-tasks__title--with-tasks-sections': appStore.isCorpMode || appStore.isTeamMode,
          })}
        >
          Мои задачи
        </p>
      }
      desc={(appStore.isCorpMode || appStore.isTeamMode) && <div className="team-tasks__sections">{sections}</div>}
      style={{ zIndex: 97 }}
      titleIcon={
        <img
          className="modal--team-tasks__modal-image"
          src={!myVisibleTasks.length ? EmptyTeamTasksIcon : TeamTasksIcon}
          alt="Картинка папки"
        />
      }
      customClass="team-tasks__modal my-tasks__modal"
      size="very-big"
      exitIcon={true}
    >
      <div className="team-tasks__container">
        {selectedSection === TEAM_TASKS_CATEGORY.LTD ? (
          <LTDActions />
        ) : appStore.sortedBySectionTeamTasks[selectedSection].tasks.length > 0 ? (
          renderTasks(appStore.sortedBySectionTeamTasks[selectedSection].tasks, taskIsClickable)
        ) : (
          <div className="team-tasks__null-tasks">Нет активных заявок</div>
        )}
      </div>
      <div className="modal__body-actions">
        <TextButton color="blue" title="Закрыть" onClick={close} />
      </div>
    </Modal>
  );
}

export default observer(MyTasksModal);
