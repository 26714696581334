import React, { useMemo } from 'react';
import Preview, { Context } from 'react-dnd-preview';
import { observer } from 'mobx-react';
import MagnifierIcon from '../ui/icons/MagnifierIcon';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../ui/components/Cat/Cat';
import EatIcon from '../ui/icons/EatIcon';
import RootStore from '../store';
import getCatData from '../common/getCatData';
import { DRAG_AREA, DRAG_BUTTON_TYPE, PLAYER_ROLE } from '../common/constants';

const InternalElement = ({ itemType, item, style }) => {
  const { appStore } = RootStore;
  const MemoContent = useMemo(() => {
    if (itemType === 'button') {
      return (
        <p
          className={
            item.data.buttonType === DRAG_BUTTON_TYPE.EAT || item.data.buttonType === DRAG_BUTTON_TYPE.MAGNIFIER
              ? 'own-nurseries__actions-item'
              : 'magnifier-button'
          }
        >
          {item.data.buttonType === DRAG_BUTTON_TYPE.EAT ? <EatIcon /> : <MagnifierIcon size={58} />}
        </p>
      );
    }

    let cat;
    if (
      appStore.me.role === PLAYER_ROLE.NURSERY &&
      [DRAG_AREA.NURSERY, DRAG_AREA.LOT, DRAG_AREA.STORAGE_MY_CATS, DRAG_AREA.STORAGE].includes(item.from)
    ) {
      cat = getCatData(item);
    } else {
      cat = item.data;
    }
    return <Cat cat={cat} state={CAT_STATE.SINGLE} bottomText={BOTTOM_TEXT.KIND} showHouseIcon={true} />;
  }, [item.data, itemType]);

  return <span style={{ ...style, zIndex: 1000 }}>{MemoContent}</span>;
};

function DragElement() {
  return (
    <Preview>
      <Context.Consumer>
        {({ itemType, item, style }) => {
          return <InternalElement item={item} style={style} itemType={itemType} />;
        }}
      </Context.Consumer>
    </Preview>
  );
}

export default observer(DragElement);
