import Modal from './Modal/Modal';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import ExpectationIcon from '../icons/modal/ExpectationIcon';
import classNames from '../../common/classNames';
import trainingStore from '../../store/trainingStore';

function TutorialTimeOverModal() {
  const { appStore } = RootStore;

  if (!appStore.gameOverTutorialTimeOverModalShowed) {
    return null;
  }

  let title = 'Время на выполнение задания вышло';
  let desc = 'ПРИДЕТСЯ ВЕРНУТЬСЯ И СЫГРАТЬ БЫСТРЕЕ';

  return (
    <Modal
      isOpen={appStore.gameOverTutorialTimeOverModalShowed}
      shouldCloseOnOverlayClick={false}
      colorBg="red"
      title={title}
      desc={desc}
    >
      <ExpectationIcon className="modal__body-icon" />
      <div
        className={classNames({
          'training-modal__actions': true,
          'training-modal__actions--showed': trainingStore.showedNextButton,
        })}
      >
        <TextButton
          color="blue"
          title="ЗАНОВО"
          onClick={() => {
            trainingStore.relaunchTask(false);
          }}
        />
      </div>
    </Modal>
  );
}

export default observer(TutorialTimeOverModal);
