import React from 'react';
import LTDStepsApiParams from '../base/LTDStepsApiParams';
import LTDChapter from '../base/LTDChapter';
import LTDStep from '../base/LTDStep';
import {
  CAT_COLOR,
  CUSTOM_ACTION_NAMES,
  DRAG_AREA,
  DRAGGABLE_ITEM_TYPE,
  LTD_ACTIONS,
  LTD_ACTIONS_STATUS,
  LTD_CONTENT_TYPE,
  ROOT_PATHS,
  TIMER_ID,
} from '../../../../common/constants';
import { INFO_MODAL_POSITION, TRAINING_PLAYER_ID } from '../../base/constants';
import RootStore from '../../../index';
import generateTrainingDealId from '../base/methods/generateTrainingDealId';
import { LTD_OBJECT_BY_STAGE } from '../base/methods/generateLTDInfo';
import generateLTDActionRowId from '../../../../common/LTD/generateLTDActionRowId';
import { RECEIVE_MESSAGE_TYPE } from '../../../constants';
import trainingStore from '../../../trainingStore';
import checkLTDActions from '../base/methods/checkLTDActions';
import acceptEndTurn from '../base/methods/acceptEndTurn';
import api from '../../../../api';
import generateLTDChapterName from '../base/methods/generateLTDChapterName';

const steps = () => [
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    openedLTDRows: [generateTrainingDealId(1)],
    title: <div>Склад</div>,
    message: (
      <p className="training-modal__desc">
        Также одним из важных аспектов игры является склад, куда котиков будут отгружать и осматривать в рамках
        долгосрочной сделки
      </p>
    ),
    nextButton: true,
    buttonText: 'Дальше',
    needOverlayClickModal: false,
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
  }),
  new LTDStep({
    actionName: 'routerGoTo',
    forceTimerValue: 1200000,
    checkParams([route, params]) {
      return route.rootPath === ROOT_PATHS.STORAGE && params.uuid === TRAINING_PLAYER_ID.LEOPOLD;
    },
    title: <div>Склад</div>,
    hideModalsOnStartTurn: false,
    blockedActionsNames: ['toggleSeasonChangeModal'],
    message: (
      <p className="training-modal__desc">
        Посмотрим, как со стороны контрагента происходит загрузка котиков на склад и отправка нам. Нажмем на кнопку
        «Склад»
      </p>
    ),
    needOverlayClickModal: false,
    elements: [
      `#${generateLTDActionRowId({ actionType: LTD_ACTIONS.TO_STORAGE, contentType: LTD_CONTENT_TYPE.SELL })}`,
    ],
    elementsForChangeZIndex: {
      [`#${generateLTDActionRowId({ actionType: LTD_ACTIONS.TO_STORAGE, contentType: LTD_CONTENT_TYPE.SELL })}`]: {
        newZIndex: 10,
        returnOriginalZIndexIsNeeded: true,
      },
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    position: INFO_MODAL_POSITION.TOP,
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    selectorIntoView: '#ltd-action-row--to_storage-sell',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    nextButton: true,
    buttonText: 'Дальше',
    title: <div>Склад</div>,
    message: (
      <p className="training-modal__desc">
        В этом окне мы сразу видим каких котиков и в каком количестве нужно отправить. Обратим ваше внимание на то, что
        можно сразу отправить пустой склад
      </p>
    ),
    position: INFO_MODAL_POSITION.TOP,
    elements: ['.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area.lot-area--lot'],
  }),
  new LTDStep({
    actionName: RECEIVE_MESSAGE_TYPE.TRADING_LOT_UPDATE,
    blockedActionsNames: ['openBuyFromCity'],
    acceptActionsWithInvalidCheckParams: true,
    showingErrorModalIsNeeded: false,
    checkParams() {
      return RootStore.appStore.catsLTDToStorage.every((cat) => {
        if (!cat.needCount) {
          return true;
        }

        console.warn('cat.needCount === cat.count', cat.needCount === cat.count);
        return cat.needCount === cat.count;
      });
    },
    draggableSettingsOverride: {
      [DRAGGABLE_ITEM_TYPE.CAT]: {
        [DRAG_AREA.CITY]: ({ type, from, data, element, draggableOptions, dragAround }) => {
          const { gender, color } = data;
          if (color !== CAT_COLOR.WHITE) {
            return false;
          }

          const storageCat = RootStore.appStore.catsLTDToStorage.find((cat) => {
            return cat.gender === gender && cat.color === color;
          });

          return storageCat.count < storageCat.needCount;
        },
        [DRAG_AREA.STORAGE_MY_CATS]: false,
      },
    },
    title: <div>Склад</div>,
    message: <p className="training-modal__desc">Купим двух белых мальчиков и двух белых девочек с города</p>,
    position: INFO_MODAL_POSITION.BOTTOM,
    elements: ['.lot-area.lot-area--city', '.lot-area.lot-area--shop'],
  }),
  new LTDStep({
    actionName: 'addCatToStorage',
    acceptActionsWithInvalidCheckParams: true,
    showingErrorModalIsNeeded: false,
    blockedActionsNames: ['openBuyFromCity'],
    checkParams() {
      return RootStore.appStore.catsLTDToStorage.every((cat) => {
        if (!cat.needCount) {
          return true;
        }

        return cat.factCount >= cat.needCount;
      });
    },
    acceptedDropTypesOverride: {
      [DRAG_AREA.STORAGE_MY_CATS]: [DRAG_AREA.STORAGE],
    },
    title: <div>Склад</div>,
    message: <p className="training-modal__desc">отправим этих котиков контрагенту на осмотр</p>,
    position: INFO_MODAL_POSITION.TOP,
    elements: ['.lot-area.lot-area--lot', '.lot-area.lot-area--shop'],
    disabledElements: ['.lot-panel__ltd-button'],
  }),
  new LTDStep({
    actionName: 'LTDSendPerformAction',
    acceptActionsWithInvalidCheckParams: true,
    showingErrorModalIsNeeded: false,
    checkParams([dealId, actionParams, actionName]) {
      return actionName === LTD_ACTIONS.TO_STORAGE;
    },
    title: <div>Склад</div>,
    message: <p className="training-modal__desc">отправим этих котиков контрагенту на осмотр</p>,
    position: INFO_MODAL_POSITION.LEFT,
    elements: ['.lot-panel__ltd-button'],
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Смена роли</div>,
    blockedActionsNames: ['setLTDNotificationsModal'],
    after() {
      RootStore.appStore.changeMyUuidWithoutReload(TRAINING_PLAYER_ID.LEOPOLD);
    },
    message: <p className="training-modal__desc">для продолжения обучения Перейдем за роль питомника</p>,
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: RECEIVE_MESSAGE_TYPE.SYNCHRONIZE,
    title: <div>Смена роли</div>,
    after() {
      RootStore.appStore.openMyTasksModal();
    },
    blockedActionsNames: ['setLTDNotificationsModal'],
    message: <p className="training-modal__desc">Ожидание смены роли</p>,
  }),
  new LTDStep({
    openedLTDRows: [generateTrainingDealId(1)],
    actionName: 'routerGoTo',
    title: <div>Склад</div>,
    message: (
      <p className="training-modal__desc">
        От нашего контрагента пришли котики, мы должны осмотреть их. Если бы в оплате перед отгрузкой у нас был не 0, то
        здесь было бы поле оплатить (без осмотра).
      </p>
    ),
    checkParams([route, params]) {
      return route.rootPath === ROOT_PATHS.STORAGE && params.uuid === TRAINING_PLAYER_ID.BONIFACE;
    },
    needOverlayClickModal: false,
    elements: [
      `#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT,
        contentType: LTD_CONTENT_TYPE.BUY,
      })}`,
    ],
    elementsForChangeZIndex: {
      [`#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT,
        contentType: LTD_CONTENT_TYPE.BUY,
      })}`]: {
        newZIndex: 10,
        returnOriginalZIndexIsNeeded: true,
      },
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    position: INFO_MODAL_POSITION.TOP,
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    nextButton: true,
    buttonText: 'Дальше',
    title: <div>Склад</div>,
    message: (
      <p className="training-modal__desc">
        Мы сразу можем видеть какое количество котиков у нас присутствует в сделке и всех ли хватает
      </p>
    ),
    position: INFO_MODAL_POSITION.TOP,
    elements: ['.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area.lot-area--lot'],
  }),
  new LTDStep({
    actionName: 'setOpenedGroupedCat',
    title: <div>Склад</div>,
    message: (
      <p className="training-modal__desc">
        Перед тем как подтвердить котиков на складе обязательно посмотрим их качество
      </p>
    ),
    position: INFO_MODAL_POSITION.TOP,
    elements: ['.lot-area.lot-area--lot '],
    disabledElements: ['.lot-area.lot-area--lot .lot-area__lot-panel'],
  }),
  new LTDStep({
    actionName: 'addShowedSicknessCatId',
    acceptActionsWithInvalidCheckParams: true,
    showingErrorModalIsNeeded: false,
    observeHealthExaminationsOfCats: true,
    checkParams() {
      return (
        RootStore.appStore.catsLTDToStorage.reduce((acc, cat) => acc + cat.factCount, 0) ===
        trainingStore.currentStep.examinedCatsIds.length
      );
    },
    after() {
      RootStore.appStore.catUnderMagnifierModalClose();
    },
    needOverlayClickModal: false,
    title: <div>Склад</div>,
    message: <p className="training-modal__desc">Осмотри всех 4 котиков</p>,
    position: INFO_MODAL_POSITION.TOP,
    elements: ['.modal--grouped-cat'],
  }),
  new LTDStep({
    actionName: 'setOpenedGroupedCat',
    checkParams([open]) {
      return !open;
    },
    title: <div>Склад</div>,
    message: (
      <p className="training-modal__desc">Все котики здоровые и в нужном количестве, поэтому можем подтвердить склад</p>
    ),
    position: INFO_MODAL_POSITION.TOP,
    elements: ['.modal--grouped-cat'],
  }),
  new LTDStep({
    actionName: 'routerGoTo',
    blockedActionsNames: ['setLTDNotificationsModal'],
    checkParams([route]) {
      return route.path === ROOT_PATHS.MAIN;
    },
    title: <div>Склад</div>,
    message: (
      <p className="training-modal__desc">Все котики здоровые и в нужном количестве, поэтому можем подтвердить склад</p>
    ),
    position: INFO_MODAL_POSITION.LEFT,
    elements: ['.text_button.lot-panel__ltd-button'],
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    blockedActionsNames: ['setLTDNotificationsModal'],
    title: <div>Смена роли</div>,
    after() {
      RootStore.appStore.changeMyUuidWithoutReload(TRAINING_PLAYER_ID.BONIFACE);
    },
    message: <p className="training-modal__desc">для продолжения обучения Перейдем за роль зоомагазина</p>,
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: RECEIVE_MESSAGE_TYPE.SYNCHRONIZE,
    title: <div>Смена роли</div>,
    after() {
      RootStore.appStore.openMyTasksModal();
    },
    needOverlayClickModal: false,
    blockedActionsNames: ['setLTDNotificationsModal'],
    message: <p className="training-modal__desc">Ожидание смены роли</p>,
  }),
  new LTDStep({
    actionName: RECEIVE_MESSAGE_TYPE.LTD_UPDATE,
    title: <div>Склад</div>,
    needOverlayClickModal: false,
    blockedActionsNames: ['setLTDNotificationsModal'],
    checkParams([ltdUpdate]) {
      return checkLTDActions({
        actions: RootStore.appStore.LTDContentByType(ltdUpdate.contents, LTD_CONTENT_TYPE.SELL).actions,
        neededActionType: LTD_ACTIONS.SHIPPING,
        neededStatus: LTD_ACTIONS_STATUS.COMPLETED,
      });
    },
    message: (
      <p className="training-modal__desc">
        Мы видим, что уже оплачен аванс и котики на складе осмотрены, значит осталось отправить котиков. Нажимаем на
        «Отгрузить»
      </p>
    ),
    openedLTDRows: [generateTrainingDealId(1)],
    elements: [`#${generateLTDActionRowId({ actionType: LTD_ACTIONS.SHIPPING, contentType: LTD_CONTENT_TYPE.SELL })}`],
    elementsForChangeZIndex: {
      [`#${generateLTDActionRowId({ actionType: LTD_ACTIONS.SHIPPING, contentType: LTD_CONTENT_TYPE.SELL })}`]: {
        newZIndex: 10,
        returnOriginalZIndexIsNeeded: true,
      },
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    position: INFO_MODAL_POSITION.TOP,
    selectorIntoView: '#ltd-action-row--shipping-sell',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Смена роли</div>,
    blockedActionsNames: ['setLTDNotificationsModal'],
    after() {
      RootStore.appStore.changeMyUuidWithoutReload(TRAINING_PLAYER_ID.LEOPOLD);
    },
    message: <p className="training-modal__desc">для продолжения обучения Перейдем за роль питомника</p>,
    nextButton: true,
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    needOverlayClickModal: false,
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: RECEIVE_MESSAGE_TYPE.SYNCHRONIZE,
    timeoutBeforeNextStep: 100,
    title: <div>Смена роли</div>,
    after() {
      RootStore.appStore.openMyTasksModal();
    },
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    needOverlayClickModal: false,
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    message: <p className="training-modal__desc">Ожидание смены роли</p>,
  }),
  new LTDStep({
    actionName: RECEIVE_MESSAGE_TYPE.LTD_UPDATE,
    after() {
      acceptEndTurn();
      RootStore.appStore.tutorialProgress.chapter++;
    },
    blockedActionsNames: ['setLTDNotificationsModal', 'toggleSeasonChangeModal', 'toggleEndTurnFlag'],
    checkParams([ltdUpdate]) {
      return checkLTDActions({
        actions: RootStore.appStore.LTDContentByType(ltdUpdate.contents, LTD_CONTENT_TYPE.BUY).actions,
        neededActionType: LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT,
        neededStatus: LTD_ACTIONS_STATUS.COMPLETED,
      });
    },
    elements: [
      `#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT,
        contentType: LTD_CONTENT_TYPE.BUY,
      })}`,
    ],
    elementsForChangeZIndex: {
      [`#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PAYMENT_AFTER_SHIPMENT,
        contentType: LTD_CONTENT_TYPE.BUY,
      })}`]: {
        newZIndex: 10,
        returnOriginalZIndexIsNeeded: true,
      },
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    message: (
      <p className="training-modal__desc">
        Наш контрагент отгрузил котиков, теперь они появились у нас, осталось оплатить последнюю часть сделки на покупку
      </p>
    ),
    needOverlayClickModal: false,
    openedLTDRows: [generateTrainingDealId(1)],
    position: INFO_MODAL_POSITION.TOP,
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    title: <div>Склад</div>,
  }),
];

const chapter7 = () =>
  new LTDChapter({
    title: 'Взаимодействие со складом',
    chapter: generateLTDChapterName(7),
    userIdentifierForSocketInitialization: '00000000-0000-0000-0000-000000000021',
    stepsApiParams: {
      ...LTDStepsApiParams,
      initial_turn: 1,
      override_deals: [LTD_OBJECT_BY_STAGE.PREPAID_PAYMENT_FROM_NURSERY],
    },
    onChapterInitializationActionsNames: [
      'openIntroductionModal',
      'toggleSeasonChangeModal',
      'toggleEndTurnFlag',
      'setLTDNotificationsModal',
    ],
    defaultStepSettings: {
      hiddenTimersIds: [TIMER_ID.HEADER.SESSION_TIMER],
    },
    beforeStepsInitializationStep: new LTDStep({
      title: <div>Ожидание старта главы</div>,
      message: <p className="training-modal__desc">Ожидание...</p>,
    }),
    callback() {
      RootStore.appStore.hideIntroductionModal();
      RootStore.appStore.openMyTasksModal();
    },
    initialRouteParams: {
      routeName: 'main',
    },
    seamlessStartOfNextChapter: true,
    disableTimers: true,
    stepsGeneratorInfo: {
      generateOnChapterInitialization: false,
      generator: steps,
    },
  });

export default chapter7;
