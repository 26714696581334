import React from 'react';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import './LTDActionsModal/LTDActions.scss';
import EmptyLTDTasksIcon from '../../../assets/team-tasks-empty.png';
import LTDTasksIcon from '../../../assets/team-tasks.png';
import * as teamMode from '../../../store/teamMode';
import './style.scss';
import { PLAYER_ROLE, PRIVILEGES, REQUEST_TYPE, SPECIALITIES } from '../../../common/constants';
import { TASK_TYPES, TEAM_TASK_STATUS } from '../../../store/constants';

function MyTasksLogo() {
  const { appStore } = RootStore;
  const visible = appStore.with_ltd || appStore.isTeamMode || appStore.isCorpMode;
  if (!visible) {
    return null;
  }

  const extraDataActions = appStore.extraDataActionsLTD;
  let activeTasks = [];

  if (appStore.myVisibleTasks.length) {
    activeTasks = appStore.myVisibleTasks.filter((task) => {
      const taskInProgress = task.status === TEAM_TASK_STATUS.IN_PROGRESS;
      const isVisibleForDoctor = task.visible_to.find((spec) => spec === SPECIALITIES.DOCTOR);
      // проверка на то, что я доктор, который получил торговую заявку на осмотр и пока не поставил свою визу
      const indicateForMe =
        (task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST || task.type === TASK_TYPES.CITY_TRADE) &&
        isVisibleForDoctor &&
        task.visas[SPECIALITIES.DOCTOR] === undefined &&
        task.specialty_by === (undefined ?? task.stage) &&
        appStore.hasSpecialities([SPECIALITIES.DOCTOR]) &&
        taskInProgress;
      if (appStore.withPrivilegesChange && ![SPECIALITIES.CAPTAIN, SPECIALITIES.CEO].includes(task.stage)) {
        const canSell =
          task.content.request_type === REQUEST_TYPE.SELL &&
          appStore.myPrivileges.includes(PRIVILEGES.APPROVE_TASK_BY_SALES);
        const canBuy =
          task.content.request_type === REQUEST_TYPE.BUY &&
          appStore.myPrivileges.includes(PRIVILEGES.APPROVE_TASK_BY_SUPPLY);
        // проверка на то, что сделку отправил питомник и я могу ее принять или отклонить для корректного отображения количества задач
        const myTask =
          !task.specialty_by &&
          (canSell || canBuy) &&
          task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST &&
          taskInProgress &&
          appStore.me.role === PLAYER_ROLE.SHOP;

        return (
          myTask ||
          indicateForMe ||
          (teamMode.hasDefaultSpecialtyPrivileges(appStore.myPrivileges, task.stage) && taskInProgress)
        );
      } else {
        return indicateForMe || (appStore.hasSpecialities([task.stage]) && taskInProgress);
      }
    });
  }

  let emptyTeamTaskIcon;

  if (appStore.myVisibleTasks.length) {
    emptyTeamTaskIcon = false;
  } else emptyTeamTaskIcon = extraDataActions.active.length === 0;

  let notificationCount = 0;
  if (activeTasks) {
    notificationCount += activeTasks.length;
  }
  if (extraDataActions?.indicator) {
    notificationCount += extraDataActions.indicator.length;
  }
  return (
    <>
      {notificationCount > 0 && <div className="my-tasks__indicator">{notificationCount}</div>}
      <div
        className="ltd-actions__head-panel"
        style={{
          backgroundImage: emptyTeamTaskIcon ? `url(${EmptyLTDTasksIcon})` : `url(${LTDTasksIcon})`,
        }}
        onClick={appStore.openMyTasksModal}
      />
    </>
  );
}

export default observer(MyTasksLogo);
