import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarCatPawImage from '../../../assets/nurseryAvatars/avatarCatPaw.png';

function AvatarCatPaw({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <g clipPath="url(#avatarCatPaw)">
    //     <path
    //       d="M15.0078 15.7087C16.3004 15.7087 17.3516 14.6576 17.3516 13.365C17.3516 12.3178 16.5305 9.75781 15.0078 9.75781C13.4851 9.75781 12.6641 12.3178 12.6641 13.365C12.6641 14.6576 13.7152 15.7087 15.0078 15.7087Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M11.7451 20.0192C12.8625 19.3736 13.2528 17.9431 12.6028 16.8178C12.0786 15.9108 10.0471 14.106 8.7304 14.8654C7.41204 15.6276 8.01995 18.2551 8.54287 19.1615C9.18862 20.2783 10.6173 20.6697 11.7451 20.0192Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M20.8672 15.7087C22.1598 15.7087 23.2109 14.6576 23.2109 13.365C23.2109 12.3178 22.3899 9.75781 20.8672 9.75781C19.3445 9.75781 18.5234 12.3178 18.5234 13.365C18.5234 14.6576 19.5746 15.7087 20.8672 15.7087Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M24.1189 19.9979C25.2475 20.649 26.7157 20.2542 27.3594 19.1396C27.976 18.0721 28.3558 15.5501 27.1334 14.8435C25.8122 14.0825 23.7841 15.8889 23.2611 16.7958C22.6138 17.9164 22.9963 19.3489 24.1189 19.9979Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M13.8359 19.8103V20.148C13.8359 20.3877 13.6928 20.6012 13.4708 20.6915C12.2687 21.1801 11.4922 22.2148 11.4922 23.3259C11.4922 24.9412 13.0692 26.2556 15.0078 26.2556C15.3952 26.2556 15.7379 26.2161 16.0561 26.1355C17.2852 25.823 18.5881 25.8247 19.8229 26.136C20.1381 26.2167 20.4798 26.2556 20.8672 26.2556C22.8058 26.2556 24.3828 24.9414 24.3828 23.3259C24.3828 22.2148 23.6063 21.1802 22.4042 20.691C22.182 20.6012 22.0391 20.3877 22.0391 20.148V19.8103C22.0391 16.8806 18.9521 16.8806 17.9375 16.8806C16.9229 16.8806 13.8359 16.8806 13.8359 19.8103Z"
    //       fill="#02516C"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="avatarCatPaw">
    //       <rect width="20" height="20" fill="white" transform="translate(7.9375 8)" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <Avatar style={style} avatarImage={AvatarCatPawImage} />
  );
}

export default AvatarCatPaw;
