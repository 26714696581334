import React from 'react';
import { observer } from 'mobx-react';
import './style.scss';
import { TRANSFER_TYPE } from '../../../../store/constants';
import corpStore from '../../../../store/CorpStore';
import TransferContent from './components/TransferContent';
import { LockIcon } from '../../../../ui/icons';
import useWindow from '../../../../ui/hooks/useWindow';

function TransferField() {
  const { isMobile } = useWindow();

  if (!corpStore.myActiveCompanies.length) {
    return (
      <div className="lot-area lot-area--shop lot-area--shop-disallow">
        <LockIcon />
      </div>
    );
  }

  return (
    <div className="transfer-area">
      <div className="transfer-area__info">
        <span className="transfer-area__info-image" />
        {!isMobile && <span className="transfer-area__info-text">ТРАНСФЕРЫ</span>}
      </div>
      <div className="transfer-area__content">
        <TransferContent type={TRANSFER_TYPE.IMPORT} title="заказан импорт" />
        <span className="transfer-area__content-delimiter" />
        <TransferContent type={TRANSFER_TYPE.EXPORT} title="отправлено на экспорт" />
      </div>
    </div>
  );
}

export default observer(TransferField);
