import { TASK_TYPES } from '../store/constants';
import RootStore from '../store';
import _ from 'lodash';

const formatTeamHistory = (teamHistory, seasonNum) => {
  const _teamHistory = [];
  // TODO: история игры по сезонам {https://gamesalabuga.atlassian.net/browse/BCC-752}
  // const seasonKeys = Object.keys(teamHistory);
  // Object.values(teamHistory).forEach((teamHistorySeason, ind) => {
  //   teamHistorySeason.forEach((teamHistorySeasonItem) => {
  //     if (
  //       [TASK_TYPES.FAKE_IMPORT_TASK, TASK_TYPES.FAKE_EXPORT_TASK].includes(teamHistorySeasonItem.task_update?.type)
  //     ) {
  //       teamHistorySeasonItem.season = teamHistorySeasonItem.task_update.order_season;
  //     } else {
  //       teamHistorySeasonItem.season = +seasonKeys[ind];
  //     }
  //     _teamHistory.push(teamHistorySeasonItem);
  //   });
  // });

  teamHistory.forEach((teamHistorySeasonItem) => {
    if (TASK_TYPES.FAKE_EXPORT_TASK === teamHistorySeasonItem.task_update?.type) {
      teamHistorySeasonItem.season = teamHistorySeasonItem.task_update.order_season;
    } else if (TASK_TYPES.FAKE_IMPORT_TASK === teamHistorySeasonItem.task_update?.type) {
      teamHistorySeasonItem.season = teamHistorySeasonItem.task_update.delivery_season;
    } else if (_.isNumber(seasonNum)) {
      teamHistorySeasonItem.season = seasonNum;
    } else {
      teamHistorySeasonItem.season = RootStore.appStore.currentSeason;
    }
    _teamHistory.push(teamHistorySeasonItem);
  });
  const joinTeamHistory = _teamHistory.reduce((calculatedTeamHistory, currentTeamHistory) => {
    const currentUniqueTeamHistoryItem = calculatedTeamHistory.find((uniqueTeamHistoryMass) => {
      return uniqueTeamHistoryMass.find((teamHistoryItem) => {
        if (!currentTeamHistory.task_update) {
          return false;
        } else {
          return (
            teamHistoryItem.task_update &&
            (RootStore.appStore.isCorpMode &&
            [TASK_TYPES.FAKE_IMPORT_TASK, TASK_TYPES.FAKE_EXPORT_TASK].includes(teamHistoryItem.task_update.type)
              ? teamHistoryItem.task_update.lot_id === currentTeamHistory.task_update.lot_id
              : teamHistoryItem.task_update.task_id === currentTeamHistory.task_update.task_id)
          );
        }
      });
    });
    if (currentUniqueTeamHistoryItem) {
      currentUniqueTeamHistoryItem.push(currentTeamHistory);
    } else {
      calculatedTeamHistory.push([currentTeamHistory]);
    }
    return calculatedTeamHistory;
  }, []);
  return joinTeamHistory.reverse();
};

export default formatTeamHistory;
