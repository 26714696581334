import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import Img from '../../../assets/shopAvatars/9.png';

function AvatarFire({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="#FDF6EE" stroke="#02516C" strokeWidth="3" />
    //   <g clipPath="url(#avatarFire)">
    //     <path
    //       d="M25.3948 19.7097C25.1657 16.727 23.7769 14.8578 22.5518 13.2083C21.4173 11.6813 20.4375 10.3626 20.4375 8.41738C20.4375 8.26113 20.35 8.11832 20.2113 8.04672C20.0721 7.97469 19.9048 7.98648 19.7783 8.07844C17.9383 9.39508 16.4031 11.6142 15.8668 13.7315C15.4945 15.2056 15.4452 16.8628 15.4383 17.9573C13.7391 17.5944 13.3542 15.0527 13.3501 15.025C13.331 14.8931 13.2504 14.7784 13.1332 14.7157C13.0148 14.6539 12.8757 14.6494 12.7556 14.7088C12.6665 14.752 10.5685 15.818 10.4464 20.0743C10.4379 20.2159 10.4375 20.3579 10.4375 20.4999C10.4375 24.6349 13.8021 27.9994 17.9375 27.9994C17.9432 27.9998 17.9493 28.0006 17.9542 27.9994C17.9558 27.9994 17.9574 27.9994 17.9595 27.9994C22.0846 27.9875 25.4375 24.6276 25.4375 20.4999C25.4375 20.292 25.3948 19.7097 25.3948 19.7097ZM17.9375 27.1661C16.5589 27.1661 15.4375 25.9715 15.4375 24.5031C15.4375 24.4531 15.4371 24.4026 15.4407 24.3408C15.4574 23.7215 15.575 23.2988 15.704 23.0176C15.9457 23.5368 16.3779 24.0141 17.0798 24.0141C17.3101 24.0141 17.4964 23.8277 17.4964 23.5974C17.4964 23.0042 17.5087 22.3198 17.6564 21.7022C17.7878 21.1545 18.1019 20.5719 18.4999 20.1048C18.6769 20.7111 19.022 21.2017 19.3589 21.6806C19.8411 22.3658 20.3395 23.0742 20.427 24.2822C20.4323 24.3538 20.4376 24.4258 20.4376 24.5031C20.4375 25.9715 19.3161 27.1661 17.9375 27.1661Z"
    //       fill="#02516C"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="avatarFire">
    //       <rect width="20" height="20" fill="white" transform="translate(7.9375 8)" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <Avatar style={style} avatarImage={Img} />
  );
}

export default AvatarFire;
