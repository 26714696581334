import React from 'react';

function SliderArrow({ style, position = 'right', props }) {
  return position === 'right' ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#FDF6EE"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      {...props}
    >
      <path
        d="M5.25686 11.8371C5.23062 5.83078 5.28199 0.795261 5.37106 0.647674C5.68519 0.125386 6.74659 0.391919 8.78514 1.50527C14.8084 4.79447 21.75 10.2287 21.75 11.6544C21.75 12.5944 19.4794 14.7358 15.2825 17.7535C11.7021 20.328 6.79279 23.0358 5.98048 22.8843C5.34408 22.7657 5.30121 22.1141 5.25686 11.8371Z"
        fill="inherit"
      />
    </svg>
  ) : (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#FDF6EE"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      {...props}
    >
      <path
        d="M18.7431 11.8371C18.7694 5.83078 18.718 0.795261 18.6289 0.647674C18.3148 0.125386 17.2534 0.391919 15.2149 1.50527C9.19162 4.79447 2.25 10.2287 2.25 11.6544C2.25 12.5944 4.52063 14.7358 8.71746 17.7535C12.2979 20.328 17.2072 23.0358 18.0195 22.8843C18.6559 22.7657 18.6988 22.1141 18.7431 11.8371Z"
        fill="inherit"
      />
    </svg>
  );
}

export default SliderArrow;
