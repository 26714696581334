import React, { useMemo } from 'react';
import RootStore from '../../store';
import routes from '../../routes';
import classNames from '../../common/classNames';
import { observer } from 'mobx-react';
import { PLAYER_ROLE, SPECIALITIES } from '../../common/constants';
import getStaticsForPlayers from '../../common/getStaticsForPlayers';

function Nurseries() {
  const { appStore } = RootStore;
  const nurseries = useMemo(
    () => getStaticsForPlayers({ players: appStore.nurseries }),
    [appStore.nurseries, appStore.currentSeasonType],
  );

  return (
    <div className={'nurseries-container'}>
      {nurseries.map((nursery, index) => {
        return (
          <div key={index} className={'nursery-item-wrapper'}>
            <span
              onClick={() => {
                if (appStore.isActiveOnNurseryClick) {
                  RootStore.goTo(routes.inside, { uuid: nursery.uuid });
                }
              }}
              className={classNames({
                'nursery-item--own': nursery.uuid === appStore.me.uuid,
                'nursery-item': true,
                'nursery-item--bankrupt': !appStore.playerByUuid(nursery.uuid).active,
                [`nursery-item--relation-${nursery.relation_to_me}`]:
                  appStore.me.role === PLAYER_ROLE.SHOP &&
                  nursery.ai_type !== 'human' &&
                  (!appStore.isTeamMode ||
                    appStore.hasSpecialities([SPECIALITIES.CAPTAIN, SPECIALITIES.SUPPLY, SPECIALITIES.SALES])),
              })}
            >
              <img src={nursery.src} srcSet={nursery.srcSet} width={'180'} height={'130'} alt={nursery.name} />
              <span
                className="nursery-font"
                style={{
                  width: nursery.coord.width,
                  top: nursery.coord.top,
                  left: nursery.coord.left,
                }}
              >
                {nursery.name}
              </span>
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default observer(Nurseries);
