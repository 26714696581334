import React from 'react';
import Modal from '../Modal/Modal';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import TextButton from '../../buttons/TextButton/TextButton';
import ListModalIcon from '../../icons/modal/ListModalIcon';
import UserListComponent from '../UserListComponent';
import { AI_TYPE, PLAYER_ROLE } from '../../../common/constants';
import './style.scss';
import WatcherModalIcon from '../../icons/modal/WatcherModalIcon';

function UserListModal() {
  const { appStore } = RootStore;

  if (!appStore.userListModalFlag) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.userListModalFlag}
      onClose={appStore.hideUserListModal}
      title={
        <p className="user-list-modal-title">
          <ListModalIcon className="modal-title-icon" /> Список игроков в сессии
        </p>
      }
      size="big"
    >
      <div className="modal_body-user-list">
        <p className="user-list_title">Зоомагазины</p>
        {appStore.players
          .filter((item) => item.role === PLAYER_ROLE.SHOP)
          .map((player) => (
            <UserListComponent key={player.uuid} player={player} />
          ))}
      </div>
      <div className="modal_body-user-list">
        <p className="user-list_title">Питомники</p>
        {appStore.players
          .filter((item) => item.role === PLAYER_ROLE.NURSERY)
          .map((player) => (
            <UserListComponent key={player.uuid} player={player} />
          ))}
      </div>

      <div className="modal__body-actions">
        <TextButton color="blue" title="назад" onClick={appStore.hideUserListModal} />
      </div>
    </Modal>
  );
}

export default observer(UserListModal);
