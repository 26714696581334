import React from 'react';

function FleasModalIcon({ style, stylePath, className }) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <g clipPath="url(#fleasModalIcon)">
        <path
          d="M47.3635 47.5H31.1375C30.9238 47.5 30.7505 47.6529 30.7505 47.8415V62.1585C30.7505 62.3471 30.9238 62.5 31.1375 62.5H47.3635C47.5772 62.5 47.7505 62.3471 47.7505 62.1585V47.8415C47.7505 47.6529 47.5772 47.5 47.3635 47.5Z"
          fill="#C6A788"
        />
        <path
          d="M23.1025 32.5H19.9998C18.8243 49.5215 19.1129 57.9787 19.9998 75C20.0783 76.506 20.4384 77.2134 21.5511 77.5002C31.2502 80 46.2502 80 56.968 77.5002C58.1357 77.2278 58.707 76.4589 58.7502 75C59.2457 58.2835 59.1359 49.8519 58.5193 32.5H23.1025ZM51.4763 63.0046C51.4763 64.5639 50.0873 65.8278 48.3736 65.8278H30.1455C28.4319 65.8278 27.0428 64.5639 27.0428 63.0046L27.0739 46.4324C27.0739 44.8732 28.4629 43.6093 30.1765 43.6093H48.3736C50.0873 43.6093 51.4763 44.8732 51.4763 46.4324V63.0046Z"
          fill="#C6A788"
        />
        <path
          d="M47.7751 22.297V15.6617C47.7751 14.8668 47.0768 14.2224 46.2155 14.2224H44.4689V5.47118C44.4921 4.67649 43.8129 4.01511 42.9518 3.9937C42.8169 3.99028 42.6821 4.00324 42.5507 4.03184L35.0806 5.74465C34.3614 5.90118 33.86 6.50229 33.8797 7.18399V14.2655H32.1331C31.2718 14.2655 30.5735 14.91 30.5735 15.7049V22.3402C22.7759 24.0962 20.5146 27.9824 20 29.1627H58.333C57.8184 27.9536 55.5259 24.053 47.7751 22.297Z"
          fill="#C6A788"
        />
        <path
          d="M62.0587 7.94678H53.9696C53.1747 7.94678 52.5303 8.59124 52.5303 9.38612C52.5303 10.181 53.1747 10.8255 53.9696 10.8255H62.0587C62.8536 10.8255 63.498 10.181 63.498 9.38612C63.498 8.59124 62.8536 7.94678 62.0587 7.94678Z"
          fill="#C6A788"
        />
        <path
          d="M62.5054 0.908479C62.2113 0.169199 61.3736 -0.191714 60.6343 0.10245L53.4376 2.98113C52.6983 3.27331 52.3358 4.10939 52.628 4.84867C52.8455 5.39921 53.3781 5.76049 53.9702 5.75905C54.1524 5.75959 54.3332 5.72522 54.5027 5.65829L61.6994 2.77962C62.4387 2.48563 62.7994 1.64776 62.5054 0.908479Z"
          fill="#C6A788"
        />
        <path
          d="M61.6703 15.9639L54.4736 13.0852C53.7343 12.7911 52.8966 13.152 52.6025 13.8913C52.3083 14.6305 52.6692 15.4682 53.4085 15.7624L60.6052 18.6411C61.3445 18.9352 62.1822 18.5743 62.4764 17.835C62.7703 17.0958 62.4096 16.2581 61.6703 15.9639Z"
          fill="#C6A788"
        />
      </g>
      <defs>
        <clipPath id="fleasModalIcon">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FleasModalIcon;
