import classNames from '../../common/classNames';
import CoinIcon from '../icons/CoinIcon';
import * as PropTypes from 'prop-types';
import React from 'react';
import numberInputKeyDown from '../../common/numberInputKeyDown';
import _ from 'lodash';

function handlePaste(event) {
  event.preventDefault();
}

function handleScroll(event) {
  if (document.activeElement.type === 'number') {
    document.activeElement.blur();
  }
}

function NumberInput({
  className,
  readOnly,
  disabled,
  value,
  preValueSymbol,
  onChange,
  placeholder = '0',
  isPercent,
  oldValue,
  highlight,
  id,
  icon = true,
}) {
  return (
    <div
      className={classNames({
        'number-input-wrapper': true,
        [className]: !!className,
        'number-input-wrapper--disabled': disabled,
        'number-input-wrapper--readonly': readOnly,
      })}
      id={id}
    >
      {icon && !isPercent && <CoinIcon />}
      {icon && isPercent && (
        <svg width="16" height="16" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.93054 11C9.24354 11 9.85454 10.402 9.85454 9.076V7.958C9.85454 6.632 9.24354 6.034 7.93054 6.034C6.64354 6.034 6.03254 6.658 6.03254 7.958V9.076C6.03254 10.402 6.64354 11 7.93054 11ZM7.93054 9.609C7.48854 9.609 7.37154 9.349 7.37154 8.881V8.14C7.37154 7.672 7.48854 7.425 7.93054 7.425C8.38554 7.425 8.50254 7.672 8.50254 8.14V8.881C8.50254 9.349 8.38554 9.609 7.93054 9.609ZM1.91154 5.865C3.22454 5.865 3.83554 5.267 3.83554 3.941V2.823C3.83554 1.497 3.22454 0.899 1.91154 0.899C0.624535 0.899 0.0135352 1.523 0.0135352 2.823V3.941C0.0135352 5.267 0.624535 5.865 1.91154 5.865ZM1.91154 4.474C1.46954 4.474 1.35254 4.214 1.35254 3.746V3.005C1.35254 2.537 1.46954 2.29 1.91154 2.29C2.36654 2.29 2.48354 2.537 2.48354 3.005V3.746C2.48354 4.214 2.36654 4.474 1.91154 4.474ZM5.49954 0.807999L2.60054 11L4.34254 11.182L7.25454 0.977L5.49954 0.807999Z"
            fill="#7A6652"
          />
        </svg>
      )}
      {icon && <span className="number-input-wrapper__delimiter" />}
      <input
        className={classNames({ 'number-input': true, 'number-input--highlight': highlight })}
        type={!!preValueSymbol ? 'text' : 'number'}
        readOnly={readOnly || disabled}
        value={!!preValueSymbol ? preValueSymbol + value : value}
        placeholder={placeholder}
        onPaste={handlePaste}
        onWheel={handleScroll}
        onChange={(event) => {
          let newValue;
          if (!!preValueSymbol) {
            newValue = +_.last(event.target.value.split(preValueSymbol));
          } else {
            newValue = +event.target.value;
          }
          if (newValue < 0) {
            newValue = 0;
          }
          event.target.value = newValue; // чтобы не печатался 0 перед числом
          onChange(newValue);
        }}
        onKeyDown={numberInputKeyDown}
      />
      {(oldValue || oldValue === 0) && <span className="number-input__old-price">{oldValue}</span>}
    </div>
  );
}

NumberInput.propTypes = {
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.number,
  preValueSymbol: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default NumberInput;
