import React from 'react';

const CEOIcon = (fill = '#B3977B', style) => {
  return (
    <svg width="28" height="13" viewBox="0 0 28 13" fill={fill} style={style} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.29292 8.94784C6.29292 10.2048 5.88108 10.8995 4.23372 10.8995C2.58636 10.8995 2.27336 10.2048 2.27336 8.99746V4.05216C2.27336 2.84478 2.58636 2.15013 4.23372 2.15013C5.9964 2.15013 6.29292 2.84478 6.29292 4.08524H8.56628C8.56628 1.257 7.29781 0.132316 4.23372 0.132316C1.16963 0.132316 0 1.24046 0 3.93639L0 9.11323C0 11.743 1.21905 12.9173 4.23372 12.9173C7.24839 12.9173 8.56628 11.743 8.56628 8.94784H6.29292Z"
        fill={fill}
      />
      <path
        d="M10.445 0.148855V12.9008H17.9735V10.8168H12.7184V7.45929H17.0015V5.4084H12.7184V2.13359L17.7429 2.13359V0.148855L10.445 0.148855Z"
        fill={fill}
      />
      <path
        d="M23.6674 13C26.6821 13 28 11.8257 28 9.19593V3.80407C28 1.10814 26.7315 0 23.6674 0C20.6033 0 19.4337 1.10814 19.4337 3.80407V9.19593C19.4337 11.8257 20.6528 13 23.6674 13ZM23.6674 10.9822C22.0201 10.9822 21.7071 10.2875 21.7071 9.08015V3.91985C21.7071 2.76209 22.0201 2.01781 23.6674 2.01781C25.4301 2.01781 25.7266 2.76209 25.7266 3.91985V9.08015C25.7266 10.2875 25.3148 10.9822 23.6674 10.9822Z"
        fill={fill}
      />
    </svg>
  );
};

export default CEOIcon;
