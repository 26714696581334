import React from 'react';

// Руководство пользователя, иконки
// навигация
function UserGuideIntroIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.90943 6.6553C10.4779 5.4592 11.5115 3.66929 12.2072 2.67763C12.9162 1.66731 13.8068 0.875 14.2338 0.875C15.1505 0.875 16.6835 2.88844 18.1609 6.0335L19.2482 8.34765L22.8412 9.1056C24.8176 9.52254 26.659 10.1465 26.9336 10.4917C27.604 11.3349 26.4838 13.5125 24.0309 16.1342L22.2025 18.0882L22.9221 21.0394C23.3174 22.6631 23.5917 24.4683 23.5316 25.0515C23.4279 26.0474 23.2857 26.1084 21.1867 26.0592C19.7557 26.0257 18.1146 25.616 16.6226 24.9195L14.2932 23.832L12.0137 25.2652C7.0766 28.3683 5.99215 27.791 5.86497 21.9912C5.78655 18.4199 5.6992 18.0428 4.7602 17.2344C3.22433 15.9115 0.879482 12.6366 0.875013 11.8078C0.869325 10.7865 2.73553 9.88901 6.05228 9.31726L8.87658 8.83033L9.90943 6.6553Z"
        fill="#C6A788"
      />
    </svg>
  );
}

function UserGuideGameModesIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 14C0 6.27667 6.27667 0 14 0C21.7233 0 28 6.27667 28 14C28 21.7233 21.7233 28 14 28C6.27667 28 0 21.7233 0 14ZM10.577 6.7326C10.5204 6.83017 10.4877 10.1592 10.5044 14.13C10.5326 20.9243 10.5599 21.3551 10.9648 21.4335C11.4818 21.5337 14.6059 19.7435 16.8843 18.0414C19.555 16.0464 21 14.6307 21 14.0093C21 13.0667 16.5826 9.47409 12.7496 7.29957C11.4524 6.56352 10.7769 6.38731 10.577 6.7326Z"
        fill="#C6A788"
      />
    </svg>
  );
}

function UserGuidePlayersIcon() {
  return (
    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.31216 1.01985C1.20841 1.32114 1.03226 4.7582 0.921465 8.65718C0.810691 12.5562 0.671721 16.5423 0.6122 17.5147C0.507686 19.2326 0.559345 19.3204 2.40285 20.5634C4.95731 22.2861 7.63038 23.0607 11.8976 23.3152C16.3863 23.5826 20.1599 22.9544 23.3192 21.4141C26.1637 20.027 27.1526 18.8155 26.7412 17.2224C26.5877 16.6273 26.3469 12.6842 26.2064 8.46026L25.9511 0.780378L24.2443 0.657111C23.201 0.58192 21.146 0.948982 18.9592 1.60114C13.857 3.12337 13.3488 3.14385 9.66922 1.97411C5.59581 0.679995 1.58738 0.221925 1.31216 1.01985ZM22.3044 7.23581C23.8626 8.99862 24.0176 9.08371 24.3038 8.33026C24.4744 7.8809 24.5625 6.47885 24.4995 5.21459L24.3852 2.91539L20.6152 5.32449L22.3044 7.23581ZM6.58275 5.3706L5.50107 4.60811C4.906 4.18908 4.02282 3.63731 3.53855 3.3825C2.66867 2.92443 2.65809 2.94929 2.68511 5.3942C2.72817 9.26727 2.96542 9.46573 4.91384 7.25985L6.58275 5.3706ZM8.4517 11.6898C9.14698 12.3892 9.02325 13.3651 8.13694 14.1725C7.00162 15.206 5.39811 14.4415 5.39811 12.8665C5.39811 12.2482 5.51555 11.624 5.65923 11.4798C6.09181 11.0446 7.94942 11.1845 8.4517 11.6898ZM21.2795 14.4273C22.1021 14.0504 22.2594 13.7401 22.1588 12.6913C22.0535 11.5932 21.8715 11.3979 20.8599 11.2979C18.8093 11.0955 17.9946 12.8083 19.4932 14.1725C20.1486 14.7692 20.4425 14.8109 21.2795 14.4273ZM16.1652 14.911C16.8675 15.4277 16.871 15.5037 16.2352 16.48C15.0463 18.3054 12.2891 18.2519 11.2955 16.3839C10.9776 15.7865 11.0241 15.4596 11.4947 14.9858C12.262 14.2143 15.1551 14.1678 16.1652 14.911Z"
        fill="#C6A788"
      />
    </svg>
  );
}

function UserGuideMainScreenIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.7961 13.6825C27.272 13.1419 27.226 12.3134 26.6935 11.8324L14.9621 1.22923C14.4296 0.748236 13.5759 0.758312 13.0546 1.25195L1.28326 12.3977C0.761982 12.8913 0.736397 13.7188 1.22662 14.2451L1.52174 14.5626C2.01136 15.089 2.80278 15.1517 3.28829 14.7024L4.16802 13.8886V25.8023C4.16802 26.5333 4.74141 27.125 5.44854 27.125H10.037C10.7442 27.125 11.3176 26.5333 11.3176 25.8023V17.4675H17.1702V25.8023C17.1601 26.5327 17.666 27.1245 18.3732 27.1245H23.2358C23.9429 27.1245 24.5163 26.5327 24.5163 25.8018V14.0563C24.5163 14.0563 24.7594 14.2762 25.0591 14.5484C25.3583 14.82 25.9868 14.6022 26.4627 14.0611L26.7961 13.6825Z"
        fill="#C6A788"
      />
    </svg>
  );
}

function UserGuideNurseryIcon() {
  return (
    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.11781 1.9836C5.78432 1.313 6.75131 0.579638 7.26646 0.353897C9.08242 -0.44263 12.4708 0.127374 16.1203 1.84246C19.8403 3.59108 21.7451 3.8449 23.9507 2.88579L25.25 2.32084V3.83515C25.25 6.53001 24.0114 8.72501 21.8211 9.91103C21.2745 10.207 18.7909 10.7114 16.3017 11.0323C11.4885 11.6522 9.20944 12.5194 8.20685 14.1116C7.67671 14.9534 7.76698 15.3982 9.523 20.5922C11.2929 25.8287 11.3727 26.2248 10.8272 27.0907C10.076 28.2838 8.58224 28.3068 7.88575 27.136C7.59877 26.6537 5.80379 20.9182 3.89679 14.3904C1.3716 5.74749 0.529382 2.35711 0.797637 1.91421C1.31766 1.05686 2.79755 1.15861 3.29172 2.08614C3.52026 2.51501 3.75181 2.94154 3.80651 3.03433C3.86121 3.12673 4.45129 2.6538 5.11781 1.9836Z"
        fill="#C6A788"
      />
    </svg>
  );
}

function UserGuidePetshopIcon() {
  return (
    <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.15934 3.41734C0.497074 6.40239 0.203979 7.14964 1.78318 9.47833C2.86488 11.0728 4.17723 11.8007 5.9851 11.8086C7.28433 11.8145 9.62549 10.7543 9.62549 10.1608C9.62549 9.9968 10.2769 10.3019 11.0731 10.8385C12.8523 12.0386 14.9143 12.101 16.7158 11.0096L17.5176 10.5238C17.838 10.3298 18.2399 10.331 18.559 10.5269L19.3476 11.0112C21.7886 12.5104 24.5835 11.8864 26.2168 9.47833C27.796 7.14964 27.5029 6.40239 23.8407 3.41734L22.4998 2.32429C21.2507 1.30605 19.6885 0.75 18.0769 0.75H14H9.92308C8.31153 0.75 6.74933 1.30605 5.50022 2.32429L4.15934 3.41734ZM8.43244 13.6382C7.59571 13.9638 6.29847 14.0982 5.18018 13.9753L4.47285 13.8975C3.86542 13.8306 3.34065 14.3195 3.36426 14.9301L3.52225 19.0163C3.6336 21.9014 3.79426 24.4846 3.87897 24.756C3.99668 25.1342 6.39908 25.25 14.128 25.25H23.3046C23.8241 25.25 24.257 24.8523 24.301 24.3347L24.4801 22.2266C24.6217 20.5638 24.7374 17.9747 24.7374 16.4727V14.9018C24.7374 14.2908 24.1944 13.8227 23.59 13.9127L23.0433 13.9942C21.9664 14.1547 20.7387 14.0381 19.6745 13.6738C18.2007 13.1692 17.7812 13.1723 16.1634 13.7014C14.4036 14.2772 13.0293 14.1807 10.4209 13.2987C10.0928 13.1877 9.19798 13.3406 8.43244 13.6382ZM12.2104 19.3227V21.8912H9.62546H7.04053L6.91843 19.7649C6.73749 16.6071 6.83333 16.5001 9.71972 16.6364L12.2104 16.7541V19.3227Z"
        fill="#C6A788"
      />
    </svg>
  );
}

function UserGuideCreditIcon() {
  return (
    <svg width="50" height="52" viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.8694 0.620843C26.3295 0.217771 25.6738 0 25 0C24.3262 0 23.6705 0.217771 23.1306 0.620843L0.986111 17.1514C-0.888889 18.55 0.101389 21.5292 2.43889 21.5292H47.5625C49.9014 21.5292 50.8903 18.55 49.0167 17.1514L26.8694 0.620843ZM25 13.8889C24.2633 13.8889 23.5567 13.5962 23.0358 13.0753C22.5149 12.5544 22.2222 11.8478 22.2222 11.1111C22.2222 10.3744 22.5149 9.66787 23.0358 9.14694C23.5567 8.626 24.2633 8.33334 25 8.33334C25.7367 8.33334 26.4433 8.626 26.9642 9.14694C27.4851 9.66787 27.7778 10.3744 27.7778 11.1111C27.7778 11.8478 27.4851 12.5544 26.9642 13.0753C26.4433 13.5962 25.7367 13.8889 25 13.8889ZM0 47.5708C0 43.5417 3.26389 40.2778 7.29167 40.2778H42.7083C46.7361 40.2778 50 43.5417 50 47.5695V49.6528C50 50.6111 49.2222 51.3875 48.2639 51.3875H1.73611C0.777778 51.3889 0 50.6111 0 49.6528V47.5695V47.5708ZM11.1111 36.3889C11.1111 37.0026 10.6137 37.5 10 37.5H6.66667C6.05302 37.5 5.55556 37.0025 5.55556 36.3889V25.4167C5.55556 24.803 6.05302 24.3056 6.66667 24.3056H10C10.6137 24.3056 11.1111 24.803 11.1111 25.4167V36.3889ZM17.7778 24.3056C17.1641 24.3056 16.6667 24.803 16.6667 25.4167V36.3889C16.6667 37.0026 17.1641 37.5 17.7778 37.5H21.1111C21.7248 37.5 22.2222 37.0025 22.2222 36.3889V25.4167C22.2222 24.803 21.7248 24.3056 21.1111 24.3056H17.7778ZM28.8889 24.3056C28.2752 24.3056 27.7778 24.803 27.7778 25.4167V36.3889C27.7778 37.0026 28.2752 37.5 28.8889 37.5H32.2222C32.8359 37.5 33.3333 37.0025 33.3333 36.3889V25.4167C33.3333 24.803 32.8359 24.3056 32.2222 24.3056H28.8889ZM40 24.3056C39.3864 24.3056 38.8889 24.803 38.8889 25.4167V36.3889C38.8889 37.0026 39.3864 37.5 40 37.5H43.3333C43.947 37.5 44.4444 37.0025 44.4444 36.3889V25.4167C44.4444 24.803 43.947 24.3056 43.3333 24.3056H40Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export function UserGuideLTD() {
  return (
    <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.95652C0 1.7714 1.78534 0 3.98768 0H11.1348V6.07174C11.1348 8.88719 13.4352 11.1696 16.2728 11.1696H22.4V24.0435C22.4 26.2286 20.6147 28 18.4123 28H3.98768C1.78534 28 0 26.2286 0 24.0435V3.95652ZM4.16 11.5313C3.62981 11.5313 3.2 11.9738 3.2 12.5197C3.2 13.0655 3.62981 13.508 4.16 13.508H8.64C9.17019 13.508 9.6 13.0655 9.6 12.5197C9.6 11.9738 9.17019 11.5313 8.64 11.5313H4.16ZM4.16 16.4732C3.62981 16.4732 3.2 16.9157 3.2 17.4616C3.2 18.0075 3.62981 18.45 4.16 18.45H15.04C15.5702 18.45 16 18.0075 16 17.4616C16 16.9157 15.5702 16.4732 15.04 16.4732H4.16ZM3.2 22.4036C3.2 21.8577 3.62981 21.4152 4.16 21.4152H18.24C18.7702 21.4152 19.2 21.8577 19.2 22.4036C19.2 22.9495 18.7702 23.392 18.24 23.392H4.16C3.62981 23.392 3.2 22.9495 3.2 22.4036Z"
        fill="#C6A788"
      />
      <path
        d="M21.232 8.08572C21.4755 8.32732 21.6843 8.59695 21.8551 8.88696H16.2728C14.7057 8.88696 13.4354 7.62654 13.4354 6.07174V0.536194C13.7306 0.706499 14.005 0.91513 14.2506 1.15884L21.232 8.08572Z"
        fill="#C6A788"
      />
    </svg>
  );
}

function UserGuideTradeIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4531 3.44531C13.9062 3.44531 14.2734 3.07803 14.2734 2.625V0.820312C14.2734 0.367281 13.9062 0 13.4531 0C13.0001 0 12.6328 0.367281 12.6328 0.820312V2.625C12.6328 3.07803 13.0001 3.44531 13.4531 3.44531Z"
        fill="#C6A788"
      />
      <path
        d="M7.84855 3.73986L9.23099 4.89989C9.58028 5.19296 10.0973 5.14358 10.3867 4.79878C10.6779 4.45173 10.6326 3.93433 10.2855 3.64312L8.90309 2.48309C8.55593 2.19182 8.03859 2.23716 7.74743 2.58421C7.45622 2.9312 7.50144 3.44865 7.84855 3.73986Z"
        fill="#C6A788"
      />
      <path
        d="M17.6752 4.89993L19.0577 3.7399C19.4048 3.44869 19.45 2.93124 19.1588 2.58425C18.8677 2.2372 18.3501 2.19192 18.0032 2.48313L16.6207 3.64316C16.2736 3.93437 16.2284 4.45182 16.5196 4.79881C16.8099 5.14482 17.3272 5.19196 17.6752 4.89993Z"
        fill="#C6A788"
      />
      <path
        d="M6.125 21.5001C6.125 21.2239 6.34886 21.0001 6.625 21.0001H9.125C9.40114 21.0001 9.625 21.2239 9.625 21.5001V22.25C9.625 22.5261 9.40114 22.75 9.125 22.75H6.625C6.34886 22.75 6.125 22.5261 6.125 22.25V21.5001Z"
        fill="#C6A788"
      />
      <path
        d="M20.8905 22.5858C19.3691 22.1293 17.8495 19.8846 17.8495 19.8846C17.5292 19.5642 17.5292 19.0449 17.8495 18.7245C17.8495 18.7245 19.3599 16.4482 20.8905 16.0233C21.6753 15.8055 23.9937 15.8811 25.8124 16.0233V13.6171C25.8124 12.2601 24.7084 11.1561 23.3514 11.1561H2.98032C2.32976 11.1561 1.55908 11.1561 1.20298 10.6566C0.437514 13.5625 5.57601e-05 19.25 1.20298 24.2811C1.58112 25.8627 2.69162 27.2968 4.48423 27.5624C7.43756 28 15.9827 28.1764 23.3514 27.5624C24.5001 27.4667 25.8124 26.4328 25.8124 25.1182V22.5858C23.8993 22.7089 21.4376 22.75 20.8905 22.5858ZM11.0467 23.4061C11.0467 23.8592 10.6795 24.2265 10.2264 24.2265H5.30454C4.85151 24.2265 4.48423 23.8592 4.48423 23.4061V20.1249C4.48423 19.6719 4.85151 19.3046 5.30454 19.3046H10.2264C10.6795 19.3046 11.0467 19.6719 11.0467 20.1249V23.4061Z"
        fill="#C6A788"
      />
      <path
        d="M20.8906 17.675C20.669 17.8523 19.9982 18.5386 19.5862 18.9654C19.3894 19.1693 19.3643 19.4824 19.536 19.7079C19.8983 20.1837 20.52 20.9266 20.8906 20.9562C21.4034 20.9972 24.0498 21.2423 26.1542 21.0093C26.4028 20.9818 26.6032 20.7945 26.6481 20.5486C26.8017 19.7088 26.8134 19.0687 26.6445 18.0899C26.6015 17.8405 26.3998 17.6504 26.1478 17.6273C24.6129 17.4864 21.1873 17.4377 20.8906 17.675Z"
        fill="#C6A788"
      />
      <path
        d="M2.92578 9.95312H24.1172V8.85937C24.1172 7.50022 22.9912 6.65365 21.6562 6.39844C17.9375 5.6875 6.125 5.68752 2.92578 6.39844C1.96756 6.61137 1.14844 7.19419 1.14844 8.17578C1.14844 9.15736 1.9442 9.95312 2.92578 9.95312Z"
        fill="#C6A788"
      />
    </svg>
  );
}

function UserGuideBotsIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.39336 9.44923C6.48438 7.62636 7.13778 4.58604 8.86041 2.62544C9.28338 2.14414 9.93564 1.75 10.3094 1.75C11.3809 1.75 12.8183 4.38994 13.0987 6.87308C13.3276 8.89905 13.2691 9.16012 12.3785 10.083C10.7913 11.7289 8.3774 11.4219 7.39336 9.44923Z"
        fill="#C6A788"
      />
      <path
        d="M15.1283 7.54386C15.1283 5.36888 15.6234 3.839 16.72 2.62544C18.1341 1.06015 19.3992 1.70254 20.5559 4.57243C21.9513 8.03529 20.8397 11.088 18.1833 11.088C16.1938 11.088 15.1283 9.85192 15.1283 7.54386Z"
        fill="#C6A788"
      />
      <path
        d="M0.875102 14.3155C0.884859 12.5432 2.02843 9.77332 2.9892 9.19437C3.94134 8.62085 4.01113 8.63681 4.88296 9.62897C6.12483 11.0425 6.49638 12.0829 6.49638 14.147C6.49638 15.7824 6.33988 16.2174 5.50746 16.8959C4.19578 17.9659 2.91376 17.9145 1.78823 16.7473C1.12882 16.064 0.869097 15.3723 0.875102 14.3155Z"
        fill="#C6A788"
      />
      <path
        d="M21.4304 14.3073C21.4976 12.6385 22.0538 10.9383 22.855 9.95076C23.3898 9.29243 23.9074 8.75353 24.0061 8.75353C24.7398 8.75353 25.8143 9.85425 26.411 11.2168C27.427 13.5366 27.3556 15.5668 26.2166 16.7473C24.2658 18.7701 21.311 17.2636 21.4304 14.3073Z"
        fill="#C6A788"
      />
      <path
        d="M5.54124 22.5672C6.20778 18.1908 7.96342 15.5034 11.1666 13.9572C13.51 12.8261 14.8986 12.8 17.1763 13.8443C20.6813 15.4516 23.016 19.1359 23.0115 23.0524C23.0085 25.62 22.0095 26.4273 19.2105 26.1242C17.9998 25.9931 16.4404 25.6391 15.7446 25.3379C14.6183 24.85 14.2576 24.8694 12.4584 25.5134C11.3468 25.9114 9.64704 26.2425 8.68176 26.2495C7.20645 26.2604 6.79663 26.0919 6.11508 25.1935C5.46506 24.3364 5.35134 23.8162 5.54124 22.5672Z"
        fill="#C6A788"
      />
    </svg>
  );
}

function UserGuideReport() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#userGuideReport)">
        <path
          d="M12.019 5.03872H16.1032C17.2406 5.03753 18.375 4.375 18.3729 2.07783H16.6262C16.3748 2.07783 16.1573 1.90271 16.1032 1.65706C15.8922 0.696892 15.75 1.18584e-10 13.9916 0C12.25 -1.17453e-10 12.0907 0.696892 11.88 1.65736C11.8262 1.90301 11.6084 2.07812 11.3571 2.07812H9.61005C9.625 4.375 10.882 5.03753 12.019 5.03872Z"
          fill="#C6A788"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8679 2.07788H22.0978C23.2351 2.07938 24.1568 3.0007 24.158 4.13808C24.5366 11.685 24.4164 16.1809 24.2431 22.665L24.2431 22.6655C24.2155 23.6996 24.1865 24.7842 24.158 25.9399C24.0625 27.125 23.2351 27.9989 22.0978 28.0001H5.88546C4.74807 27.9989 3.9375 27.125 3.82526 25.9399L3.79867 24.8892C3.59912 17.0191 3.47936 12.2958 3.82526 4.13808C3.82675 3.0007 4.74807 2.07938 5.88546 2.07788H8.09461C7.875 4.81247 9.94189 6.10802 11.6704 6.10981H16.3128C18.0413 6.10802 20.125 5.24997 19.8679 2.07788ZM8 9.62497C7.44772 9.62497 7 10.0727 7 10.625V11.25C7 11.8023 7.44772 12.25 8 12.25H20C20.5523 12.25 21 11.8023 21 11.25V10.625C21 10.0727 20.5523 9.62497 20 9.62497H8ZM7 15.875C7 15.3227 7.44772 14.875 8 14.875H20C20.5523 14.875 21 15.3227 21 15.875V16.5C21 17.0523 20.5523 17.5 20 17.5H8C7.44772 17.5 7 17.0523 7 16.5V15.875ZM8 20.125C7.44772 20.125 7 20.5727 7 21.125V21.75C7 22.3023 7.44772 22.75 8 22.75H20C20.5523 22.75 21 22.3023 21 21.75V21.125C21 20.5727 20.5523 20.125 20 20.125H8Z"
          fill="#C6A788"
        />
      </g>
      <defs>
        <clipPath id="userGuideReport">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

// шапка
function UserGuideCloseIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.47916 9.57714C3.31177 8.24441 2.29665 6.89463 2.22306 6.57729C2.13755 6.20653 2.78872 5.30136 4.04504 4.04505C5.30135 2.78873 6.20652 2.13756 6.57728 2.22307C6.89462 2.29666 8.2444 3.31178 9.57713 4.47917L12 6.602L14.4228 4.47915C15.7556 3.31176 17.1057 2.29639 17.4227 2.22309C17.7935 2.13758 18.6986 2.78873 19.9549 4.04505C21.2113 5.30136 21.8624 6.20651 21.7769 6.57727C21.7036 6.89433 20.6882 8.24442 19.5208 9.57716L17.398 12L19.5288 14.4149C20.7007 15.7431 21.7161 17.0932 21.7849 17.4148C21.8653 17.7895 21.207 18.7029 19.9549 19.955C18.6986 21.2113 17.7935 21.8624 17.4227 21.7769C17.1056 21.7036 15.7556 20.6882 14.4228 19.5208L12 17.398L9.57715 19.5209C8.24441 20.6882 6.89461 21.7033 6.57726 21.7769C6.2065 21.8624 5.30135 21.2113 4.04504 19.955C2.78872 18.6986 2.13757 17.7935 2.22308 17.4227C2.29666 17.1054 3.31175 15.7556 4.47914 14.4228L6.60199 12L4.47916 9.57714Z"
        fill="#BE8433"
      />
    </svg>
  );
}

function UserGuideQuestionIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5836 1.6428C0.772826 2.51595 0.696558 4.85798 1.15816 14.6917C1.46733 21.2829 1.85529 22.2845 4.10039 22.2845C5.11873 22.2845 5.17686 22.4096 5.17686 24.6064C5.17686 27.9069 5.76808 27.9354 10.3319 24.8575L14.146 22.2845H19.565C23.0325 22.2845 24.7639 22.3537 25.6684 21.5445C26.6424 20.6732 26.6577 18.7833 26.8498 14.6917C27.2941 5.22279 27.224 2.86231 26.4665 1.80821C25.8017 0.882584 25.7005 0.875 14.0462 0.875C3.20993 0.875 2.24068 0.934597 1.5836 1.6428ZM18.4132 3.33289C20.651 4.50618 21.5031 8.82384 19.9112 10.9211C19.4608 11.5143 18.2866 12.5314 17.3019 13.1817C16.3167 13.832 15.511 14.6346 15.511 14.9659C15.511 15.3882 14.9973 15.5678 13.7887 15.5678C12.1119 15.5678 12.0663 15.5296 12.0663 14.1183C12.0663 13.0385 12.3479 12.476 13.1695 11.9143C16.178 9.85775 16.8028 9.17856 16.8028 7.96534C16.8028 5.30258 12.6734 5.25763 11.8695 7.91118C11.4957 9.14494 11.3012 9.2709 9.76823 9.2709H8.07904L8.30337 7.33063C8.79336 3.09235 14.016 1.02694 18.4132 3.33289ZM15.7263 18.5064C15.7263 19.9408 15.6854 19.9757 14.004 19.9757C12.5296 19.9757 12.2614 19.8355 12.1399 19.0005C11.8872 17.2617 12.3539 16.765 14.1081 16.9065C15.6449 17.0303 15.7263 17.111 15.7263 18.5064Z"
        fill="#9D6311"
      />
    </svg>
  );
}

// иконка вызова руководства
function UserGuideHelpIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.35737 1.40811C0.662422 2.15653 0.59705 4.16398 0.992709 12.5929C1.25771 18.2425 1.59025 19.101 3.51462 19.101C4.38748 19.101 4.43731 19.2083 4.43731 21.0912C4.43731 23.9202 4.94407 23.9446 8.85592 21.3064L12.1252 19.101H16.77C19.7422 19.101 21.2262 19.1603 22.0015 18.4667C22.8363 17.7198 22.8495 16.0999 23.0141 12.5929C23.395 4.47667 23.3348 2.45341 22.6856 1.5499C22.1158 0.756501 22.029 0.75 12.0396 0.75C2.75137 0.75 1.92058 0.801083 1.35737 1.40811ZM15.7827 2.85676C17.7008 3.86244 18.4312 7.56329 17.0668 9.36096C16.6807 9.86938 15.6743 10.7412 14.8302 11.2986C13.9857 11.856 13.2952 12.5439 13.2952 12.8279C13.2952 13.1898 12.8549 13.3438 11.8189 13.3438C10.3817 13.3438 10.3425 13.3111 10.3425 12.1014C10.3425 11.1759 10.5839 10.6937 11.2881 10.2123C13.8668 8.4495 14.4024 7.86733 14.4024 6.82744C14.4024 4.54506 10.8629 4.50654 10.1739 6.78101C9.85349 7.83852 9.6867 7.94648 8.37277 7.94648H6.92489L7.11718 6.28339C7.53716 2.65059 12.0137 0.880234 15.7827 2.85676ZM13.4797 15.8626C13.4797 17.0921 13.4447 17.122 12.0034 17.122C10.7397 17.122 10.5097 17.0018 10.4057 16.2861C10.189 14.7957 10.5891 14.37 12.0927 14.4913C13.4099 14.5974 13.4797 14.6666 13.4797 15.8626Z"
          fill="#FFFBE5"
        />
      </g>
    </svg>
  );
}

function UserGuideCorpIcon() {
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.94237 3.82454L13.3435 0.0416874C13.6523 -0.097388 14.0018 0.12853 14.0018 0.467262V27.0658H10.268V7.74571C10.268 7.42789 9.95728 7.20303 9.65539 7.30236L4.66727 8.94371V4.25011C4.66727 4.06649 4.77494 3.89993 4.94237 3.82454Z"
        fill="#C6A788"
      />
      <path
        d="M0 27.5333C0 27.791 0.208961 28 0.466727 28H27.5369C27.7947 28 28.0036 27.791 28.0036 27.5333C28.0036 27.2755 27.7947 27.0665 27.5369 27.0665H26.1367V12.598C26.1367 12.3402 25.9278 12.1313 25.67 12.1313H18.2024C17.9446 12.1313 17.7356 12.3402 17.7356 12.598V27.0665H16.8022V11.6221C16.8022 11.3643 17.0111 11.1554 17.2689 11.1554H22.4029V4.1969C22.4029 3.93913 22.194 3.73017 21.9362 3.73017H14.9353V27.0665H9.33455V9.05026C9.33455 8.73019 9.0197 8.50509 8.71684 8.60863L1.2492 11.1617C1.06035 11.2262 0.933455 11.4037 0.933455 11.6033V27.0665H0.466727C0.208961 27.0665 0 27.2755 0 27.5333Z"
        fill="#C6A788"
      />
    </svg>
  );
}

function UserGuideRealtimeIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4327 26.8503C13.7123 27.1955 10.9493 26.7018 8.53021 25.4384C8.53021 25.4384 8.31024 24.9067 8.53021 24.2659C8.75018 23.6252 9.39112 23.8969 9.39112 23.8969C21 26.5001 26.6318 17.8024 23.7034 11.0001C22.7542 8.79512 21.1 7.22167 19.625 6.37515C18.15 5.52863 15.7041 4.97663 14 5.12515C12.2959 5.27367 10.3444 5.91193 9.04614 7.00015C2.62518 12.0586 4.07922 18.8663 7.87518 22.7502C7.87518 22.7502 8.0991 23.9179 7.6843 24.1163C7.2695 24.3147 6.70422 24.1163 6.70422 24.1163C-2.62482 18.3752 1.75018 7.87515 4.37518 5.25015C7.00018 2.62515 10.7675 1.3633 13.5 1.12515C16.2325 0.886997 19.0399 1.66317 21.4051 3.02057C23.7703 4.37798 25.6421 6.42326 26.7588 8.87049C27.8755 11.3177 28.1813 14.0446 27.6335 16.6699C27.0857 19.2952 25.7117 21.6877 23.7034 23.513C21.6952 25.3383 19.1532 26.5051 16.4327 26.8503Z"
        fill="#C6A788"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9993 8.75C15.0113 8.75 15.2382 9.43794 15.2382 10.2866V14.088L19.3299 16.4471C20.1637 16.9279 20.6975 17.6757 20.1242 18.375C19.5509 19.0743 18.3331 18.8558 17.4992 18.375C17.4992 18.375 14.0404 17.0305 13.1243 15.75C12.2081 14.4695 12.5048 10.2866 12.5048 10.2866C12.5048 9.43794 12.9874 8.75 13.9993 8.75Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export {
  UserGuideIntroIcon,
  UserGuideGameModesIcon,
  UserGuidePlayersIcon,
  UserGuideMainScreenIcon,
  UserGuideNurseryIcon,
  UserGuidePetshopIcon,
  UserGuideCreditIcon,
  UserGuideTradeIcon,
  UserGuideBotsIcon,
  UserGuideReport,
  UserGuideCloseIcon,
  UserGuideQuestionIcon,
  UserGuideHelpIcon,
  UserGuideCorpIcon,
  UserGuideRealtimeIcon,
};
