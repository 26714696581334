import React from 'react';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import _ from 'lodash';
import classNames from '../../../common/classNames';
import * as PropTypes from 'prop-types';
import ReactSelect from '../../Select/Select';
import TimerIcon from '../../icons/TimerIcon';
import NumberInput from '../../NumberInput/NumberInput';
import numberInputKeyDown from '../../../common/numberInputKeyDown';
import { CatFaceIcon, CoinIcon } from '../../icons';
import CatUrlImage from '../CatUrlImage';
import { EDITABLE_FIELD, LTD_ACTIONS, LTD_CONTENT_TYPE } from '../../../common/constants';
import generateLTDContentInputId from '../../../common/LTD/generateLTDContentInputId';

function handlePaste(event) {
  event.preventDefault();
}

function handleScroll(event) {
  if (document.activeElement.type === 'number') {
    document.activeElement.blur();
  }
}

function LTDContentCat(props) {
  return (
    <div
      className={classNames({
        'ltd-form-cat': true,
        'ltd-form-cat--first': props.isIcon,
        [`ltd-form-cat-gender--${props.gender}`]: props.gender,
        [`ltd-form-cat--${props.contentType}`]: props.contentType,
        [`ltd-form-cat-color--${props.color}`]: props.color,
        'ltd-form-cat--readonly': props.readOnly,
      })}
    >
      <div
        className={classNames({
          'ltd-form-cat__img-count': true,
          'ltd-form-cat__img-count--icon': props.isIcon,
        })}
      >
        {props.isIcon && <span></span>}
        <div className="ltd-form-cat__img-count-image">
          <CatUrlImage color={props.color} gender={props.gender} />
        </div>
        {props.isIcon && (
          <div className="ltd-form-cat__icons">
            <CatFaceIcon className="cat-icon" />
            <span className="number-input-wrapper__delimiter" />
          </div>
        )}
        <input
          className={classNames({ 'number-input': true, 'number-input--highlight': props.countHighlight })}
          type="number"
          placeholder="0"
          max="500"
          min="0"
          value={props.count || ''}
          onChange={props.onChangeCount}
          onKeyDown={numberInputKeyDown}
          onPaste={handlePaste}
          onWheel={handleScroll}
        />
      </div>

      <div className={classNames({ 'ltd-form-cat__price': true, 'ltd-form-cat__price--icon': props.isIcon })}>
        {props.isIcon && (
          <div className="ltd-form-cat__icons">
            <CoinIcon />
            <span className="number-input-wrapper__delimiter" />
          </div>
        )}
        <input
          className={classNames({ 'number-input': true, 'number-input--highlight': props.priceHighlight })}
          type="number"
          placeholder="0"
          max="99"
          min="1"
          value={props.price || ''}
          onChange={props.onChangePrice}
          onKeyDown={numberInputKeyDown}
          onPaste={handlePaste}
          onWheel={handleScroll}
        />
      </div>
    </div>
  );
}

LTDContentCat.propTypes = {
  count: PropTypes.number,
  price: PropTypes.number,
  isIcon: PropTypes.bool,
  onChangeCount: PropTypes.func,
  onChangePrice: PropTypes.func,
  contentType: PropTypes.string,
};

const PayBlock = observer((props) => {
  const { appStore } = RootStore;
  const content =
    props.currentTypeTabLTD === LTD_CONTENT_TYPE.BUY ? appStore.currentLTDBuyContent : appStore.currentLTDSellContent;

  const contentIndex = appStore.currentLTD.contents.findIndex((c) => c.buyer === content.buyer);
  return (
    <div
      className={classNames({
        'ltd-content-pay-block': true,
        [`ltd-content-pay-block--${props.fieldName}`]: props.fieldName,
      })}
    >
      <div className="ltd__content-timer-title ltd-content-pay-block__title">{props.title}</div>
      <NumberInput
        className="ltd-content-pay-block__input-wrapper"
        highlight={appStore.getHighlight(`contents[${contentIndex}]${props.fieldName}.money`)}
        value={content[props.fieldName]?.money || ''}
        id={generateLTDContentInputId({
          fieldName: props.fieldName,
          editableField: EDITABLE_FIELD.MONEY,
          contentType: props.currentTypeTabLTD,
        })}
        placeholder="0"
        onChange={(newValue) => {
          appStore.changeLTDContentOrder(props.currentTypeTabLTD, props.fieldName, EDITABLE_FIELD.MONEY, newValue);
        }}
        readOnly={props.readOnly}
      />
      <ReactSelect
        readOnly={props.readOnly}
        highlight={appStore.getHighlight(`contents[${contentIndex}]${props.fieldName}.season`)}
        value={{
          label: appStore.isRealTimeMode
            ? `в ${content[props.fieldName]?.season} квартале`
            : `на ${content[props.fieldName]?.season} сезон`,
          value: content[props.fieldName]?.season,
        }}
        onChange={(event) => {
          appStore.changeLTDContentOrder(props.currentTypeTabLTD, props.fieldName, EDITABLE_FIELD.SEASON, +event.value);
        }}
        className="ltd__content-timer-select"
        id={generateLTDContentInputId({
          fieldName: props.fieldName,
          editableField: EDITABLE_FIELD.SEASON,
          contentType: props.currentTypeTabLTD,
        })}
        withoutDropdown={!props.seasonOptions}
        options={
          props.seasonOptions || [
            {
              label: appStore.isRealTimeMode
                ? `в ${content[props.fieldName]?.season} квартале`
                : `на ${content[props.fieldName]?.season} сезон`,
              value: content[props.fieldName]?.season,
            },
          ]
        }
      />
      <div className="ltd__content-timer-title ltd-content-pay-block__desc">
        {appStore.isRealTimeMode ? 'оплата до' : 'за время до конца хода:'}
      </div>
      <ReactSelect
        readOnly={props.readOnly}
        highlight={appStore.getHighlight(`contents[${contentIndex}]${props.fieldName}.timer`)}
        value={
          content[props.fieldName]?.timer
            ? {
                label: appStore.getLabelSelectLTD(content[props.fieldName]?.timer),
                value: content[props.fieldName]?.timer,
              }
            : null
        }
        onChange={(event) => {
          appStore.changeLTDContentOrder(props.currentTypeTabLTD, props.fieldName, EDITABLE_FIELD.TIMER, +event.value);
        }}
        className="ltd__content-timer-select"
        id={generateLTDContentInputId({
          fieldName: props.fieldName,
          editableField: EDITABLE_FIELD.TIMER,
          contentType: props.currentTypeTabLTD,
        })}
        options={props.getMinutesForSeason(content[props.fieldName]?.season)}
        withoutDropdown={true}
      />
    </div>
  );
});

PayBlock.propTypes = {
  title: PropTypes.string,
  fieldName: PropTypes.string,
  currentTypeTabLTD: PropTypes.string,
  seasonOptions: PropTypes.any,
  getMinutesForSeason: PropTypes.func,
};

function LTDContent({ currentTypeTabLTD, hidden, readOnly }) {
  const { appStore } = RootStore;

  const content =
    currentTypeTabLTD === LTD_CONTENT_TYPE.BUY ? appStore.currentLTDBuyContent : appStore.currentLTDSellContent;
  // Время для сезонов
  const timeSeasons = appStore.turnDurationsSec;
  // Для select по выбору сезона
  let currentSeason = appStore.currentSeason + 1;
  let last_season = timeSeasons.length;
  const seasonArr = _.range(currentSeason, last_season + 1, 1);

  const shippingSeasonOptions = seasonArr.map((item) => {
    return {
      value: item,
      key: item,
      label: `в ${item} ${appStore.isRealTimeMode ? 'квартале' : 'сезон'}`,
    };
  });

  const prepaidSeasonOptions = shippingSeasonOptions.filter((item) => item.value <= content.shipping_season);

  const paymentAfterShipmentSeasonOptions = shippingSeasonOptions.filter(
    (item) => item.value >= content.shipping_season,
  );

  const contentIndex = appStore.currentLTD.contents.findIndex((c) => c.buyer === content.buyer);

  return (
    <>
      <div
        className={classNames({
          'ltd__content-storage': true,
          'ltd__content-storage--readonly': readOnly,
          'ltd__content-storage--background': currentTypeTabLTD === LTD_CONTENT_TYPE.SELL,
        })}
        style={{ display: hidden ? 'none' : null }}
      >
        <div className="ltd__content-storage-cats">
          {content.abstract_contents.map((cat, index) => (
            <LTDContentCat
              readOnly={readOnly}
              key={cat.color + cat.gender}
              contentType={currentTypeTabLTD}
              priceHighlight={appStore.getHighlight(`contents[${contentIndex}].abstract_contents[${index}].price`)}
              countHighlight={appStore.getHighlight(`contents[${contentIndex}].abstract_contents[${index}].count`)}
              isIcon={index === 0}
              color={cat.color}
              gender={cat.gender}
              count={cat.count}
              price={cat.price}
              onChangeCount={(event) => appStore.changeLTDContentCatCount(currentTypeTabLTD, cat, +event.target.value)}
              onChangePrice={(event) => appStore.changeLTDContentCatPrice(currentTypeTabLTD, cat, +event.target.value)}
            />
          ))}
        </div>
        <div className="ltd__content-storage-timer">
          <div className="ltd__content-storage-timer-season-wrapper">
            <div className="ltd__content-timer-title">срок поставки</div>
            <ReactSelect
              highlight={appStore.getHighlight(`contents[${contentIndex}].shipping_season`)}
              readOnly={readOnly}
              value={{
                label: appStore.isRealTimeMode
                  ? `на ${content.shipping_season} квартал`
                  : `на ${content.shipping_season} сезон`,
                value: content.shipping_season,
              }}
              onChange={(event) => {
                appStore.changeLTDContentTerms(currentTypeTabLTD, EDITABLE_FIELD.SHIPPING_SEASON, +event.value);
              }}
              className="ltd__content-timer-select ltd__content-timer-select--shipping_season"
              id={generateLTDContentInputId({
                fieldName: LTD_ACTIONS.SHIPPING,
                contentType: currentTypeTabLTD,
                editableField: EDITABLE_FIELD.SEASON,
              })}
              options={seasonArr.map((item) => {
                return {
                  value: item,
                  key: item,
                  label: `на ${item} ${appStore.isRealTimeMode ? 'квартал' : 'сезон'}`,
                };
              })}
            />
          </div>
          <div className="ltd__content-storage-timer-minute-wrapper">
            <div className="ltd__content-storage-timer-minute-titles">
              <div className="ltd__content-timer-title">на осмотр</div>
              <div className="ltd__content-timer-title">отгрузка</div>
            </div>
            <div className="ltd__content-storage-timer-minute-selects">
              <ReactSelect
                highlight={appStore.getHighlight(`contents[${contentIndex}].storage_timer`)}
                controlClxassName="ltd__content-timer-select-control"
                readOnly={readOnly}
                value={
                  content.storage_timer
                    ? {
                        label: appStore.getLabelSelectLTD(content.storage_timer),
                        value: content.storage_timer,
                      }
                    : null
                }
                onChange={(event) => {
                  appStore.changeLTDContentTerms(currentTypeTabLTD, EDITABLE_FIELD.STORAGE_TIMER, +event.value);
                }}
                className="ltd__content-timer-select ltd__content-timer-select--storage_timer"
                id={generateLTDContentInputId({
                  fieldName: 'storage',
                  contentType: currentTypeTabLTD,
                  editableField: EDITABLE_FIELD.TIMER,
                })}
                options={appStore[`${EDITABLE_FIELD.STORAGE_TIMER}Options`][currentTypeTabLTD]}
                withoutDropdown={true}
              />
              <TimerIcon />

              <ReactSelect
                highlight={appStore.getHighlight(`contents[${contentIndex}].shipping_timer`)}
                readOnly={readOnly}
                value={
                  content.shipping_timer
                    ? {
                        label: appStore.getLabelSelectLTD(content.shipping_timer),
                        value: content.shipping_timer,
                      }
                    : null
                }
                onChange={(event) => {
                  appStore.changeLTDContentTerms(currentTypeTabLTD, EDITABLE_FIELD.SHIPPING_TIMER, +event.value);
                }}
                className="ltd__content-timer-select ltd__content-timer-select--shipping_timer"
                id={generateLTDContentInputId({
                  fieldName: LTD_ACTIONS.SHIPPING,
                  contentType: currentTypeTabLTD,
                  editableField: EDITABLE_FIELD.TIMER,
                })}
                options={appStore[`${EDITABLE_FIELD.SHIPPING_TIMER}Options`][currentTypeTabLTD]}
                withoutDropdown={true}
              />
            </div>
            <div>
              <div className="ltd__content-timer-title">{appStore.isRealTimeMode ? 'до' : 'до конца хода'}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames({
          'ltd__content-timers': true,
          'ltd__content-timers--readonly': readOnly,
          'ltd__content-timers--background': currentTypeTabLTD === LTD_CONTENT_TYPE.SELL,
          [`ltd__content-timers--${currentTypeTabLTD}`]: currentTypeTabLTD,
        })}
        style={{ display: hidden ? 'none' : null }}
      >
        <PayBlock
          title="Аванс"
          readOnly={readOnly}
          currentTypeTabLTD={currentTypeTabLTD}
          content={content}
          fieldName={EDITABLE_FIELD.PREPAID}
          seasonOptions={prepaidSeasonOptions}
          getMinutesForSeason={() => appStore[`${EDITABLE_FIELD.PREPAID}TimerOptions`][currentTypeTabLTD]}
        />
        <PayBlock
          title="перед отгрузкой"
          readOnly={readOnly}
          currentTypeTabLTD={currentTypeTabLTD}
          content={content}
          fieldName={EDITABLE_FIELD.PAYMENT_BEFORE_SHIPMENT}
          getMinutesForSeason={() =>
            appStore[`${EDITABLE_FIELD.PAYMENT_BEFORE_SHIPMENT}TimerOptions`][currentTypeTabLTD]
          }
        />
        <PayBlock
          title="после отгрузки"
          readOnly={readOnly}
          currentTypeTabLTD={currentTypeTabLTD}
          content={content}
          fieldName={EDITABLE_FIELD.PAYMENT_AFTER_SHIPMENT}
          seasonOptions={paymentAfterShipmentSeasonOptions}
          getMinutesForSeason={() =>
            appStore[`${EDITABLE_FIELD.PAYMENT_AFTER_SHIPMENT}TimerOptions`][currentTypeTabLTD]
          }
        />
      </div>
    </>
  );
}

export default observer(LTDContent);
