import React from 'react';

function WarningModalIcon({ style, stylePath, className }) {
  return (
    <svg
      className={className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#C6A788" />
      <path
        d="M27.7467 24.7079C25.4523 22.4104 25.4162 19.5438 27.6505 17.1477C28.8704 15.8395 29.7001 15.4483 31.2538 15.4483C36.7635 15.4483 39.2065 22.5324 34.6549 25.312C32.0339 26.913 29.7482 26.7127 27.7467 24.7079Z"
        fill="#FDF6EE"
      />
      <path
        d="M26.5318 40.0707C26.6383 35.4061 26.954 31.159 27.2343 30.6321C27.638 29.8721 28.4683 29.6745 31.2554 29.6745C34.0425 29.6745 34.8729 29.8721 35.2766 30.6321C35.5568 31.159 35.8726 35.4061 35.9791 40.0707L36.1719 48.5517H31.2554H26.3389L26.5318 40.0707Z"
        fill="#FDF6EE"
      />
    </svg>
  );
}

export default WarningModalIcon;
