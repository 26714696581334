import React from 'react';
import CoinIcon from '../../icons/CoinIcon';
import classNames from '../../../common/classNames';

function Info({ buy_price, sell_price, buyVision, sellVision }) {
  return (
    <div className="cat__price-wrapper">
      <div
        className={classNames({
          cat__price: true,
          'cat__price--vision-off': !buyVision,
        })}
      >
        <div className="cat__price-name">Покупка</div>
        <div className="price-value">
          <CoinIcon />
          <span className="price-value__cost notranslate"> {buyVision ? buy_price : ''}</span>
        </div>
      </div>
      <div
        className={classNames({
          cat__price: true,
          'cat__price--vision-off': !sellVision,
        })}
      >
        <div className="cat__price-name">Продажа</div>
        <div className="price-value">
          <CoinIcon />
          <span className="price-value__cost notranslate">{sellVision ? sell_price : ''}</span>
        </div>
      </div>
    </div>
  );
}

export default Info;
