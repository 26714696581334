import React from 'react';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import './LTDBankGuaranteeModal.scss';
import Modal from '../Modal/Modal';
import TextButton from '../../buttons/TextButton/TextButton';
import NumberInput from '../../NumberInput/NumberInput';
import ReactSelect from '../../Select/Select';
import { RadioButton } from '../RadioButton';
import _ from 'lodash';
import classNames from '../../../common/classNames';
import { EDITABLE_FIELD, LTD_STATUS, PRIVILEGES, SPECIALITIES } from '../../../common/constants';
import trainingStore from '../../../store/trainingStore';

function LTDBankGuaranteeModal() {
  const { appStore } = RootStore;

  if (!appStore.LTDBankGuaranteeModalData) {
    return null;
  }

  const bankGuarantee = appStore.LTDBankGuaranteeModalData;
  const myArea = appStore.me.uuid === appStore.currentLTD.initiator || !appStore.currentLTD.deal_id;
  const isConfirmedInitiator = appStore.currentLTD.initiator_confirmed;
  const currentLTDFromBack = appStore.longTermsDeals.ltd_info.filter(
    (ltd) => appStore.currentLTD.deal_id === ltd.deal_id,
  );
  const currentPlayer = myArea
    ? currentLTDFromBack[0]?.initiator_bank_guarantee
    : currentLTDFromBack[0]?.partner_bank_guarantee;
  const bankGuaranteeInfo = myArea
    ? appStore.currentLTDBankGuarantee.initiator_bank_guarantee
    : appStore.currentLTDBankGuarantee.partner_bank_guarantee;

  const seasonArr = _.range(appStore.currentSeason + 2, appStore.maxSeasonAction + 1, 1);
  const seasonOptions = seasonArr.map((item) => {
    return {
      value: item,
      key: item,
      label: `${item} ${appStore.isRealTimeMode ? 'кварталов' : 'сезона'}`,
    };
  });
  const oneTimePayment = Math.ceil(bankGuaranteeInfo.amount * 0.05);

  // Формула для расчета периодического платежа банковской гарантии (periodicPayment)
  // Длинна массива сезонов оплаты = диапазон(текущему сезону, сезон последнего платежа по БГ + 1)
  // Сумма Платежа БГ = ((Сумма БГ * (Процентная ставка + (Длина массива сезонов оплаты / 100))) / Длина массива сезонов оплаты);
  // остаток = Сумма Платежа БГ % Длина массива сезонов оплаты
  // Сумма Платежа БГ += Длина массива сезонов оплаты - остаток
  // Периодический платеж = Сумма Платежа БГ / Длина массива сезонов оплаты

  const paymentSeasons = Array.from(
    { length: bankGuaranteeInfo.last_payment_season - appStore.currentSeason },
    (_, i) => appStore.currentSeason + i + 1,
  );
  const seasonsCounter = paymentSeasons.length;
  const interestRate = 5 + seasonsCounter - 1;
  let bankGuaranteePayment = Math.ceil((bankGuaranteeInfo.amount * interestRate) / 100);
  const remainder = bankGuaranteePayment % seasonsCounter;
  if (remainder !== 0) {
    bankGuaranteePayment += seasonsCounter - remainder;
  }
  const periodicPayment = Math.floor(bankGuaranteePayment / seasonsCounter);

  const canMarketeerReviewLtd =
    appStore.withDelegationLtd &&
    !appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY).privileges.includes(PRIVILEGES.PUSH_LTD_TO_CAPTAIN);

  const currentTask = appStore.taskById(appStore.currentTaskId);

  const captainApprovingBuySellRequest =
    !appStore.currentLTD?.deal_id &&
    appStore.mySpeciality === SPECIALITIES.CAPTAIN &&
    currentTask &&
    currentTask.visas.sales === undefined &&
    currentTask.visas.supply === undefined;

  const readonly =
    captainApprovingBuySellRequest ||
    (appStore.currentLTD.deal_id &&
      appStore.currentLTD.status &&
      appStore.currentLTD.status !== LTD_STATUS.TERMS_AGREEMENT) ||
    (myArea && isConfirmedInitiator) ||
    !(
      appStore.meIsCEO ||
      (canMarketeerReviewLtd && currentTask?.specialty_by
        ? appStore.mySpeciality === SPECIALITIES.MARKETEER
        : appStore.mySpeciality === SPECIALITIES.CAPTAIN)
    );

  if (!seasonOptions.length) {
    appStore.changeTypeLTDBankGuarantee(
      myArea ? EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE : EDITABLE_FIELD.PARTNER_BANK_GUARANTEE,
      false,
    );
  }

  return (
    <>
      <Modal
        isOpen={!!appStore.LTDBankGuaranteeModalData}
        onClose={appStore.hideLTDBankGuaranteeModal}
        shouldCloseOnOverlayClick={trainingStore.currentStep?.needOverlayClickModal ?? true}
        title="Условия банковской гарантии"
        style={{ zIndex: 101 }}
        customClass="bank-guarantee"
        size="huge"
      >
        <div className="modal-bank_guarantee__text">
          Банк (Гарант) настоящим гарантирует исполнение обязательств по Договору с{' '}
          <span className="modal-bank_guarantee__text-bold">{bankGuarantee.me.name} </span>
          (Принципал) перед <span className="modal-bank_guarantee__text-bold">{bankGuarantee.partner.name} </span>
          (бенифициар).
        </div>
        <div
          className={classNames({
            'modal-bank_guarantee__body': true,
            'modal-bank_guarantee__body--readonly': readonly,
          })}
        >
          <div className="modal-bank_guarantee__body-element">
            <div className="modal-bank_guarantee__body-element-title">
              Разовый платеж {appStore.isRealTimeMode ? 'в момент' : 'в сезон'} заключения сделки
            </div>
            <div className="modal-bank_guarantee__body-element-info">
              <RadioButton
                className="modal-bank_guarantee__body-element-info--margin"
                value={!bankGuaranteeInfo.isPeriodicPayment}
                onClick={() =>
                  appStore.changeTypeLTDBankGuarantee(
                    myArea ? EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE : EDITABLE_FIELD.PARTNER_BANK_GUARANTEE,
                    false,
                  )
                }
              />
              <NumberInput value={oneTimePayment} readOnly={true} />
            </div>
          </div>

          <div
            className={classNames({
              'modal-bank_guarantee__body-element': true,
              'modal-bank_guarantee__body-element--empty': !seasonOptions.length,
            })}
          >
            {(!!seasonOptions.length || bankGuaranteeInfo.isPeriodicPayment) && (
              <>
                <div className="modal-bank_guarantee__body-element-title modal-bank_guarantee__body-element-title--display">
                  Периодические платежи {appStore.isRealTimeMode ? 'в течении' : 'до'} :{' '}
                  <ReactSelect
                    value={
                      seasonOptions.find((option) => option.value === bankGuaranteeInfo.last_payment_season)
                        ? {
                            label: `${bankGuaranteeInfo.last_payment_season} ${
                              appStore.isRealTimeMode ? 'кварталов' : 'сезона'
                            }`,
                            value: bankGuaranteeInfo.last_payment_season,
                          }
                        : seasonOptions.at(-1)
                    }
                    onChange={(event) =>
                      appStore.changeLTDBankGuarantee(
                        myArea ? EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE : EDITABLE_FIELD.PARTNER_BANK_GUARANTEE,
                        EDITABLE_FIELD.LAST_PAYMENT_SEASON,
                        event.value,
                      )
                    }
                    options={seasonOptions}
                    className="modal-bank_guarantee__select"
                  />
                </div>
                <div className="modal-bank_guarantee__body-element-info">
                  <RadioButton
                    className="modal-bank_guarantee__body-element-info--margin"
                    value={bankGuaranteeInfo.isPeriodicPayment}
                    onClick={() => {
                      appStore.changeTypeLTDBankGuarantee(
                        myArea ? EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE : EDITABLE_FIELD.PARTNER_BANK_GUARANTEE,
                        true,
                      );
                    }}
                  />
                  <NumberInput
                    className="modal-bank_guarantee__body-element-info--margin"
                    value={periodicPayment}
                    readOnly={true}
                  />
                  <span className="modal-bank_guarantee__text">в {appStore.isRealTimeMode ? 'квартал' : 'сезон'}</span>
                </div>
              </>
            )}
          </div>
          <div className="modal-bank_guarantee__body-element">
            <div className="modal-bank_guarantee__body-element-title">Банковская гарантия</div>
            <div className="modal-bank_guarantee__body-element-info">
              <div className="modal-bank_guarantee__body-element-info--column">
                <span className="modal-bank_guarantee__text">
                  Банк настоящим гарантирует исполнение обязательств по Договору и при нарушении обязательств, обязуется
                  выплатить сумму не превышающую:
                </span>
                <NumberInput
                  value={bankGuaranteeInfo.amount}
                  onChange={(newValue) => {
                    appStore.changeLTDBankGuarantee(
                      myArea ? EDITABLE_FIELD.INITIATOR_BANK_GUARANTEE : EDITABLE_FIELD.PARTNER_BANK_GUARANTEE,
                      EDITABLE_FIELD.AMOUNT,
                      newValue,
                    );
                  }}
                />
                <span className="modal-bank_guarantee__text">
                  Настоящая гарантия является безотзывной, вступает в силу с{' '}
                  {appStore.isRealTimeMode ? 'момента' : 'сезона'} выдачи и действует до завершения договора.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__body-actions">
          <TextButton
            isDisabled={bankGuaranteeInfo.amount === 0}
            color="green"
            title="Принять условия"
            onClick={() => appStore.hideLTDBankGuaranteeModal()}
          />
        </div>
      </Modal>
    </>
  );
}

export default observer(LTDBankGuaranteeModal);
