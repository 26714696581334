import React from 'react';

function ChapterFourIcon({ style, className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10.3468 16.1133C14.7934 16.1133 18.4001 12.5067 18.4001 8.06C18.4001 3.61333 14.7934 0 10.3468 0C5.90009 0 2.29343 3.60667 2.29343 8.05333C2.29343 12.5 5.90009 16.1133 10.3468 16.1133ZM7.53343 11.24C7.60676 10.9733 7.68009 10.7133 7.76009 10.4467C7.85343 10.14 7.94009 10.1067 8.22009 10.2533C8.70009 10.5 9.20676 10.64 9.74009 10.7067C10.0801 10.7467 10.4201 10.7133 10.7334 10.5733C11.3268 10.3133 11.4201 9.62667 10.9201 9.21333C10.7468 9.07333 10.5534 8.96667 10.3534 8.88C9.83343 8.65333 9.29343 8.48 8.80009 8.18667C8.00676 7.71333 7.50009 7.05333 7.56009 6.08667C7.62676 4.99333 8.24676 4.30667 9.24676 3.94667C9.66009 3.79333 9.66009 3.8 9.66676 3.36667C9.66676 3.22 9.66676 3.07333 9.66676 2.92667C9.67343 2.6 9.73343 2.54667 10.0534 2.53333C10.1534 2.53333 10.2534 2.53333 10.3534 2.53333C11.0468 2.53333 11.0468 2.53333 11.0468 3.22667C11.0468 3.71333 11.0468 3.72 11.5334 3.79333C11.9068 3.85333 12.2601 3.96 12.6068 4.11333C12.8001 4.2 12.8668 4.33333 12.8134 4.53333C12.7268 4.83333 12.6468 5.13333 12.5468 5.42667C12.4534 5.70667 12.3668 5.74667 12.1001 5.62C11.5601 5.36 10.9934 5.24667 10.3934 5.28C10.2334 5.28667 10.0868 5.30667 9.94009 5.37333C9.42676 5.6 9.34009 6.16667 9.78009 6.52C10.0001 6.7 10.2534 6.82667 10.5201 6.93333C10.9734 7.12 11.4334 7.30667 11.8734 7.54667C13.2534 8.31333 13.6268 10.0467 12.6534 11.2267C12.3001 11.6533 11.8468 11.94 11.3068 12.0867C11.0734 12.1533 10.9668 12.2733 10.9801 12.5133C10.9934 12.7533 10.9801 12.9867 10.9801 13.2267C10.9801 13.44 10.8734 13.5533 10.6601 13.56C10.4068 13.5667 10.1468 13.5667 9.89343 13.56C9.66676 13.5533 9.56009 13.4267 9.56009 13.2067C9.56009 13.0333 9.56009 12.86 9.55343 12.6867C9.54676 12.3067 9.54009 12.2867 9.16676 12.2267C8.69343 12.1533 8.23343 12.0467 7.80009 11.8333C7.47343 11.68 7.44009 11.5933 7.53343 11.24Z"
        fill="#C6A788"
      />
      <path
        d="M28.4136 16.7867H27.4736C26.8536 14.8867 25.6403 13.2 24.0203 11.8933C24.0203 11.8333 24.0069 11.7667 23.9936 11.7067C23.6536 10.5133 24.0736 9.42666 24.5603 8.64666C24.9003 8.09999 24.4603 7.4 23.8203 7.48C22.3869 7.65999 21.4003 8.18666 20.7203 8.77999C20.2536 9.18666 19.9203 9.73333 19.7736 10.34C18.7469 14.5933 14.9136 17.76 10.3469 17.76C8.12693 17.76 6.08693 17.0133 4.45359 15.76C3.88693 16.9867 3.58026 18.3267 3.58026 19.7333C3.58026 20.4933 3.67359 21.2333 3.84693 21.9467C3.84693 21.9467 3.84693 21.96 3.85359 21.98C4.03359 22.72 4.30693 23.4333 4.65359 24.1067C5.11359 25.1 5.78026 26.26 6.70026 27.2C8.02693 28.5667 8.25359 30.26 8.28693 30.9933V31.3067C8.28693 31.32 8.28693 31.3333 8.28693 31.3467C8.28693 31.3533 8.28693 31.3533 8.28693 31.3533C8.31359 31.7133 8.61359 32 8.98026 32H13.1936C13.5736 32 13.8869 31.6933 13.8869 31.3067V30.2667C14.5003 30.3467 15.1269 30.3933 15.7603 30.3933C16.2603 30.3933 16.7469 30.3667 17.2336 30.3133V31.3067C17.2336 31.6867 17.5403 32 17.9269 32H22.1536C22.5336 32 22.8469 31.6933 22.8469 31.3067V30.2667C22.8536 29.92 22.9736 28.54 24.2803 27.36C24.3269 27.32 24.3736 27.28 24.4203 27.24C24.4336 27.2267 24.4469 27.22 24.4603 27.2067C25.9136 25.9133 26.9869 24.3 27.5403 22.5H28.4269C29.1336 22.5 29.7069 21.9267 29.7069 21.22V18.0667C29.6936 17.36 29.1203 16.7867 28.4136 16.7867ZM23.1403 17.0133C22.5603 17.0133 22.0936 16.5467 22.0936 15.9667C22.0936 15.3867 22.5603 14.92 23.1403 14.92C23.7203 14.92 24.1869 15.3867 24.1869 15.9667C24.1869 16.5467 23.7203 17.0133 23.1403 17.0133Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default ChapterFourIcon;
