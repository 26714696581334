import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarStarImage from '../../../assets/nurseryAvatars/avatarStar.png';

function AvatarStar({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <g clipPath="url(#avatarStar)">
    //     <path
    //       d="M27.9088 15.7053C27.8399 15.4931 27.6566 15.3385 27.4359 15.3065L21.2358 14.4055L18.4629 8.78729C18.3643 8.58729 18.1605 8.46069 17.9375 8.46069C17.7145 8.46069 17.5108 8.58729 17.4121 8.78729L14.6391 14.4055L8.43917 15.3065C8.21851 15.3385 8.03511 15.4931 7.96621 15.7052C7.89726 15.9174 7.95476 16.1502 8.11449 16.3059L12.6007 20.6791L11.5418 26.8542C11.5041 27.074 11.5945 27.2961 11.7749 27.4273C11.877 27.5014 11.9979 27.5391 12.1194 27.5391C12.2126 27.5391 12.3062 27.5169 12.3919 27.4718L17.9375 24.5563L23.4828 27.4718C23.6803 27.5756 23.9195 27.5583 24.0999 27.4272C24.2803 27.2961 24.3708 27.0739 24.3331 26.8541L23.2738 20.6791L27.7605 16.3059C27.9202 16.1502 27.9778 15.9174 27.9088 15.7053Z"
    //       fill="#02516C"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="avatarStar">
    //       <rect width="20" height="20" fill="white" transform="translate(7.9375 8)" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <Avatar style={style} avatarImage={AvatarStarImage} />
  );
}

export default AvatarStar;
