import React from 'react';
import RootStore from '../../../store';
import { Swiper, SwiperSlide } from 'swiper/react';
import _ from 'lodash';
import { CREDIT_TYPE_NAME, CREDIT_TYPE_NOTIFICATION } from '../../../store/constants';
import classNames from '../../../common/classNames';
import CoinIcon from '../../icons/CoinIcon';
import IconButton from '../../buttons/IconButton/IconButton';
import CreditRepaymentIcon from '../../icons/modal/CreditRepaymentIcon';
import DividingArrow from '../../icons/modal/DividingArrow';
import './TakenCredit.scss';
import { calcTimeCreditPaymentText } from '../../../common/calcTimeForCredit';

const textForCreditPayment = (payout) => {
  const { appStore } = RootStore;
  let payoutInfo = payout.season + 1;
  if (appStore.isRealTimeMode) {
    payoutInfo = calcTimeCreditPaymentText(payout.time);
  }
  return (
    <div className="taken-credit__season">
      <span>Выплата</span> <br /> <span>{!appStore.isRealTimeMode ? 'на ' : 'в '}</span>
      <span className="notranslate">{payoutInfo}</span> {!appStore.isRealTimeMode && <span>сезон</span>}
    </div>
  );
};

function TakenCredit({ creditType, sortedItems }) {
  const { appStore } = RootStore;
  const creditPercent = (creditType) => {
    return _.find(appStore.credits.credit_percentages, { credit_type: creditType }).percentage;
  };

  return (
    <>
      {sortedItems.length !== 0 && (
        <div className="taken-credit__item">
          <div className="taken-credit__block">
            <div className="taken-credit__credit-name-text">{CREDIT_TYPE_NAME[creditType]}</div>
            {sortedItems.map((item) => {
              return (
                <div className="taken-credit__info-block" key={item.credit_id}>
                  <IconButton
                    color="blue"
                    onClick={() => {
                      if (appStore.balance >= item.early_repayment_body + item.early_repayment_percent) {
                        appStore.openEarlyRepayment({
                          credit_id: item.credit_id,
                          repaymentAmount: item.full_overpayment,
                          prePaymentAmount: item.early_repayment_body + item.early_repayment_percent,
                        });
                      } else {
                        appStore.creditNotificationModalOpen({
                          money_quantity: item.early_repayment_body + item.early_repayment_percent,
                          status: CREDIT_TYPE_NOTIFICATION.NOT_CANT_REPAY,
                        });
                      }
                    }}
                    customClass={classNames({
                      'taken-credit__repayment-button': true,
                      'taken-credit__repayment-button--disabled': item.disabled,
                    })}
                  >
                    <CreditRepaymentIcon className="taken-credit__repayment-icon" />
                  </IconButton>
                  <div className="taken-credit__percent-block">
                    <div className="taken-credit__percent-item">
                      <div className="taken-credit__percent-text">тело кредита</div>
                      <div className="taken-credit__percent">
                        <CoinIcon customClass="modal__credit-coin" alt="Монетка" />
                        <DividingArrow />
                        <div className="taken-credit__percent-value notranslate">{item.money_quantity}</div>
                      </div>
                    </div>
                  </div>
                  <div className="taken-credit__percent-block">
                    <div className="taken-credit__percent-item">
                      <div className="taken-credit__percent-text">процент</div>
                      <div className="taken-credit__percent">
                        <div className="taken-credit__percent-text--symbol notranslate">%</div>
                        <DividingArrow />
                        <div className="taken-credit__percent-value notranslate">{creditPercent(item.credit_type)}</div>
                      </div>
                    </div>
                  </div>
                  <div className="taken-credit__money-season-block">
                    <Swiper threshold={0} touchAngle={40} slidesPerView={'auto'} freeMode={true} grabCursor={true}>
                      {item.credit_bodies.map((item_body) => (
                        <SwiperSlide key={item_body.season + Math.random()}>
                          <div className="taken-credit__money-season">
                            {textForCreditPayment(item_body)}
                            <div className="taken-credit__money-block">
                              <CoinIcon customClass="modal__credit-coin" alt="Монетка" />
                              <DividingArrow />
                              <div className="taken-credit__money-value notranslate">
                                {item_body.body_payment + item_body.percent_payment}
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default TakenCredit;
