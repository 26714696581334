import sendMessageByPlayer from './sendMessageByPlayer';
import { TRAINING_PLAYER_ID } from '../../../base/constants';
import { MESSAGE_TYPE } from '../../../../constants';
import RootStore from '../../../../index';
import { LocalStorage } from '../../../../../common/localStorage';

const acceptEndTurn = () => {
  const opponentPlayerId =
    LocalStorage.USER_UID === TRAINING_PLAYER_ID.BONIFACE ? TRAINING_PLAYER_ID.LEOPOLD : TRAINING_PLAYER_ID.BONIFACE;
  sendMessageByPlayer({
    playerId: opponentPlayerId,
    messageType: MESSAGE_TYPE.ENDTURN_FLAG,
    data: {
      end_turn: true,
    },
  });
  RootStore.appStore.acceptEndTurn();
};

export default acceptEndTurn;
