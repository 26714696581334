import generateLTDContentInputId from '../../../../../common/LTD/generateLTDContentInputId';

const generateLTDInputsSelectors = ({ selectorsInfo }) => {
  const result = [];
  selectorsInfo?.forEach((selectorInfo) => {
    const selector = generateLTDContentInputId({
      fieldName: selectorInfo.fieldName,
      editableField: selectorInfo.editableField,
      contentType: selectorInfo.contentType,
    });
    result.push(`#${selector}`);
  });
  return result;
};

export default generateLTDInputsSelectors;
