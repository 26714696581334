import React from 'react';

function MarketingIcon({ style }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g filter="url(#filter0_di_30_80)">
        <rect width="16" height="16" rx="8" fill="#F51264" />
      </g>
      <path
        d="M9.27024 4.11148L7.56582 3.93233C7.40936 3.91589 7.2679 4.02894 7.25145 4.18544L6.47489 11.5739C6.45827 11.7321 6.57313 11.8721 6.72959 11.8885L8.43401 12.0677C8.59214 12.0843 8.73211 11.9694 8.74856 11.8129L9.52494 4.42607C9.54157 4.26792 9.4267 4.12792 9.27024 4.11148Z"
        fill="#980339"
      />
      <path
        d="M6.10055 6.93771L4.39613 6.75857C4.23967 6.74213 4.09987 6.85536 4.08158 7.01334L3.63364 11.2753C3.61701 11.4334 3.73188 11.5734 3.88834 11.5899L5.59276 11.769C5.75088 11.7856 5.89086 11.6707 5.9073 11.5143L6.35525 7.25231C6.37187 7.09416 6.25701 6.95416 6.10055 6.93771Z"
        fill="#980339"
      />
      <path
        d="M10.1682 6.50344L11.8726 6.68258C12.0291 6.69903 12.144 6.83902 12.1273 6.99718L11.5898 12.1115C11.5734 12.268 11.4334 12.3829 11.2753 12.3663L9.57085 12.1871C9.41438 12.1707 9.29952 12.0307 9.31614 11.8725L9.85368 6.75821C9.8703 6.60005 10.0101 6.48682 10.1682 6.50344Z"
        fill="#980339"
      />
      <defs>
        <filter
          id="filter0_di_30_80"
          x="0"
          y="0"
          width="17"
          height="17"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_80" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_80" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_30_80" />
        </filter>
      </defs>
    </svg>
  );
}

export default MarketingIcon;
