import _ from 'lodash';
import { useState, useEffect } from 'react';

const filterForOrder1 = (imgModule) => {
  return (
    imgModule.includes('nursery-') ||
    imgModule.includes('pet-shop-') ||
    imgModule.includes('mainBg') ||
    (document.body.clientWidth > 1200 ? imgModule.includes('main-layout-bg') : imgModule.includes('mobile_main_bg')) ||
    imgModule.includes('airport') ||
    imgModule.includes('corporation.png') ||
    imgModule.includes('city_1') ||
    imgModule.includes('city_2') ||
    imgModule.includes('city_3') ||
    imgModule.includes('city_4') ||
    imgModule.includes('city_5')
  );
};

const filterForOrder2 = (imgModule) => {
  return (
    (imgModule.includes('_small_') && imgModule.includes('_01')) ||
    imgModule.includes('bush.png') ||
    imgModule.includes('house.png') ||
    imgModule.includes('nurseriesBg.png') ||
    imgModule.includes('nurseriesWood.png') ||
    imgModule.includes('wood.png') ||
    imgModule.includes('wood.png') ||
    imgModule.includes('shop_bg.jpg')
  );
};

const filterForOrder3 = (imgModule) => {
  return (
    imgModule.includes('house-small-window.png') ||
    imgModule.includes('house-chapter11.png') ||
    imgModule.includes('house-chapter3.png') ||
    imgModule.includes('house-chapter2.png') ||
    imgModule.includes('house-chapter2-one-window.png') ||
    imgModule.includes('house-big-window.png')
  );
};

function preloader() {
  const [loading, setLoading] = useState(true);

  function preloadImg(order) {
    const imgContext = require.context('./assets', true, /\.(png|jpg)$/);
    function importAllImg(imgContext, isRetina = false) {
      let filteredContext = imgContext.keys().filter((imgModule) => {
        return (
          !imgModule.includes('UserGuideModal') &&
          !imgModule.includes('UserGuideModal') &&
          !imgModule.includes('motions') && // чтобы выбрались все Emotions и emotions
          !imgModule.includes('chat')
        );
      });
      if (order === 1) {
        filteredContext = filteredContext.filter(filterForOrder1);
      } else if (order === 2) {
        filteredContext = filteredContext.filter(filterForOrder2);
      } else if (order === 3) {
        filteredContext = filteredContext.filter(filterForOrder3);
      } else {
        filteredContext = filteredContext.filter(
          (imgModule) => !filterForOrder1(imgModule) && !filterForOrder2(imgModule),
        );
      }
      if (isRetina) {
        filteredContext = filteredContext.filter((imgModule) => {
          const extensionIndex = imgModule.lastIndexOf('.');
          const extension = imgModule.substring(extensionIndex);
          if (imgModule.includes('-1x.')) {
            const imgRetina = imgModule.replace(`-1x${extension}`, `-2x${extension}`);
            return !imgContext.keys().includes(imgRetina);
          }
          const imgRetina = imgModule.replace(extension, `@2x${extension}`);
          return !imgContext.keys().includes(imgRetina);
        });
      } else {
        filteredContext = filteredContext.filter((imgModule) => {
          return !imgModule.includes('@2x') && !imgModule.includes('-2x');
        });
      }
      filteredContext = _.sortBy(filteredContext, [
        function (o) {
          return (
            !o.includes('mainBg') && !o.includes('main-layout') && !o.includes('nurseries') && !o.includes('pet-shops')
          );
        },
      ]);

      const contextLength = filteredContext.length;
      let imgCountOnloaded = 0;
      setTimeout(() => {
        setLoading(false);
      }, 20 * 1000);

      const loadingDelta = 70;
      let loadingOffset = 0;
      const _load = () => {
        setTimeout(() => {
          filteredContext.slice(loadingOffset, loadingOffset + loadingDelta).forEach(loadFunc);
        }, 20 + Math.random() * 100);
        // filteredContext.forEach(loadFunc);
      };
      const loadFunc = (path, index) => {
        let realPath = path.match(/[^\.\/].+/);
        let img = new Image();
        import(
          /* webpackMode: "eager" */
          /* webpackPrefetch: true */
          `./assets/${realPath}`
        )
          .then((src) => {
            img.src = src.default;
            img.onload = () => {
              imgCountOnloaded++;
              if (loading && imgCountOnloaded >= contextLength - 2) {
                setLoading(false);
              }
              if (imgCountOnloaded >= contextLength - 2) {
                loadingOffset += loadingDelta;
                _load();
              }
            };
            img.onerror = () => {
              imgCountOnloaded++;
              if (imgCountOnloaded >= contextLength - 2) {
                loadingOffset += loadingDelta;
                _load();
              }
            };
          })
          .catch((err) => console.error(err));
      };

      _load();
    }
    const isRetina = window?.devicePixelRatio > 1.4;
    importAllImg(imgContext, isRetina);
  }

  function preloadSounds() {
    const audioContext = require.context('./assets/audio', true, /\.(wav|mp3)$/);
    function importAllSounds(audioContext) {
      audioContext.keys().forEach((path) => {
        let realPath = path.match(/[^\.\/].+/);
        let audio = new Audio();
        import(
          /* webpackMode: "eager" */
          /* webpackPrefetch: true */
          `./assets/audio/${realPath}`
        )
          .then((src) => (audio.src = src.default))
          .catch((err) => console.error(err));
      });
    }
    importAllSounds(audioContext);
  }

  useEffect(() => {
    preloadImg(1);
    setTimeout(() => {
      preloadImg(2);
    }, 10000);
    setTimeout(() => {
      preloadImg(3);
      preloadSounds();
    }, 30000);
  }, []);

  return { preloadImg, preloadSounds, loading };
}

export default preloader;
