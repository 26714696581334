import { REQUEST_TYPE, SPECIALITIES, SPECIALITIES_DEFAULT_PRIVILEGES } from '../common/constants';
import { LocalStorage } from '../common/localStorage';
import RootStore from './index';
import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import { LOT_STATUS, LTD_DELEGATED_ACTION_TYPES, TASK_TYPES, TEAM_TASK_STATUS } from './constants';
import getDifferenceOfObjects from '../common/getDifferenceOfObjects';

export const PRIVILEGES_DATA = {
  CREATE_PURCHASE_TASK: {
    taskType: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
    isMainForSpeciality: SPECIALITIES.SUPPLY,
    isCreateAction: true,
    validate: (task) => !task.task_id && task.content.request_type === REQUEST_TYPE.BUY,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.stage = SPECIALITIES.SUPPLY;
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
    },
  },
  APPROVE_TASK_BY_SUPPLY: {
    taskType: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
    validate: (task) =>
      (task.task_id && task.content.request_type === REQUEST_TYPE.BUY && task.stage === SPECIALITIES.SUPPLY) ||
      (RootStore.appStore.withPrivilegesChange &&
        task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST &&
        (task.specialty_by === SPECIALITIES.MARKETEER || task.specialty_by === SPECIALITIES.DOCTOR) &&
        task.stage === RootStore.appStore.mySpeciality),
    afterAction: (task) => {
      task.stage = SPECIALITIES.MARKETEER;
    },
  },
  CREATE_SALE_TASK: {
    taskType: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
    isMainForSpeciality: SPECIALITIES.SALES,
    isCreateAction: true,
    validate: (task) => !task.task_id && task.content.request_type === REQUEST_TYPE.SELL,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.stage = SPECIALITIES.SALES;
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
    },
  },
  APPROVE_TASK_BY_SALES: {
    taskType: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
    validate: (task) =>
      (task.task_id && task.content.request_type === REQUEST_TYPE.SELL && task.stage === SPECIALITIES.SALES) ||
      (RootStore.appStore.withPrivilegesChange &&
        task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST &&
        (task.specialty_by === SPECIALITIES.MARKETEER || task.specialty_by === SPECIALITIES.DOCTOR) &&
        task.stage === RootStore.appStore.mySpeciality),
    afterAction: (task) => {
      task.stage = SPECIALITIES.MARKETEER;
    },
  },
  APPROVE_PURCHASE_TASK_BY_MARKET: {
    taskType: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
    isMainForSpeciality: SPECIALITIES.MARKETEER,
    canSendToRevision: 'CAN_DO_TO_REVISION',
    validate: (task) =>
      task.task_id && task.content.request_type === REQUEST_TYPE.BUY && task.stage === SPECIALITIES.MARKETEER,
    afterAction: (task, to_revision) => {
      if (to_revision !== undefined) {
        if (to_revision) {
          const tradeSpecialty = task.visas[SPECIALITIES.SALES] ? SPECIALITIES.SALES : SPECIALITIES.SUPPLY;
          task.stage = tradeSpecialty;
        } else {
          task.stage = SPECIALITIES.CAPTAIN;
        }
      }
    },
  },
  APPROVE_SALE_TASK_BY_MARKET: {
    taskType: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
    isMainForSpeciality: SPECIALITIES.MARKETEER,
    canSendToRevision: 'CAN_DO_TO_REVISION',
    validate: (task) =>
      task.task_id && task.content.request_type === REQUEST_TYPE.SELL && task.stage === SPECIALITIES.MARKETEER,
    afterAction: (task, to_revision) => {
      if (to_revision !== undefined) {
        if (to_revision) {
          const tradeSpecialty = task.visas[SPECIALITIES.SALES] ? SPECIALITIES.SALES : SPECIALITIES.SUPPLY;
          task.stage = tradeSpecialty;
        } else {
          task.stage = SPECIALITIES.CAPTAIN;
        }
      }
    },
  },
  CAN_DO_TO_REVISION: {
    taskType: TASK_TYPES._NONE,
  },
  APPROVE_TASK_BY_CAPTAIN: {
    taskType: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
    isMainForSpeciality: SPECIALITIES.CAPTAIN,
    canSendToRevision: true,
    validate: (task) =>
      task.task_id && task.stage === SPECIALITIES.CAPTAIN && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task, to_revision, visa) => {
      if (to_revision !== undefined) {
        if (to_revision) {
          const tradeSpecialty = task.visas[SPECIALITIES.SALES] ? SPECIALITIES.SALES : SPECIALITIES.SUPPLY;
          task.stage = tradeSpecialty;
        } else {
          if (RootStore.appStore.isCorpMode) {
            const neededPrivileges =
              task.content.request_type === 'buy'
                ? [PRIVILEGES.APPROVE_CATS_PURCHASE_BY_CEO]
                : [PRIVILEGES.APPROVE_CATS_SELL_BY_CEO];
            const playerHasNeededPrivileges = playerHasPrivileges(
              RootStore.appStore.myPrivileges,
              neededPrivileges,
              true,
            );
            if (!visa && !playerHasNeededPrivileges) {
              task.status = TEAM_TASK_STATUS.CONFIRMED;
              return;
            }
            task.status = TEAM_TASK_STATUS.IN_PROGRESS;
            task.stage = SPECIALITIES.CEO;
          } else {
            task.status = TEAM_TASK_STATUS.CONFIRMED;
          }
        }
      }
    },
  },
  APPROVE_CATS_SELL_BY_CEO: {
    taskType: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
    canSendToRevision: true,
    validate: (task) => {
      return (
        task.task_id &&
        task.content.request_type === REQUEST_TYPE.SELL &&
        task.stage === SPECIALITIES.CEO &&
        task.status === TEAM_TASK_STATUS.IN_PROGRESS
      );
    },
    afterAction: (task, to_revision) => {
      if (to_revision !== undefined) {
        if (to_revision) {
          task.stage = SPECIALITIES.SALES;
        } else {
          task.status = TEAM_TASK_STATUS.CONFIRMED;
        }
      }
    },
  },
  APPROVE_CATS_PURCHASE_BY_CEO: {
    taskType: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
    canSendToRevision: true,
    validate: (task) => {
      return (
        task.task_id &&
        task.content.request_type === REQUEST_TYPE.BUY &&
        task.stage === SPECIALITIES.CEO &&
        task.status === TEAM_TASK_STATUS.IN_PROGRESS
      );
    },
    afterAction: (task, to_revision) => {
      if (to_revision !== undefined) {
        if (to_revision) {
          task.stage = SPECIALITIES.SUPPLY;
        } else {
          task.status = TEAM_TASK_STATUS.CONFIRMED;
        }
      }
    },
  },
  CREATE_SHOWCASE_PRICES_TASK: {
    taskType: TASK_TYPES.CHANGE_SHOWCASE_PRICES,
    isCreateAction: true,
    isMainForSpeciality: SPECIALITIES.MARKETEER,
    validate: (task) => !task.task_id && task.content.request_type === REQUEST_TYPE.SHOWCASE,
    afterAction: (task) => {
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      task.task_id = 'some_id';
      task.stage = SPECIALITIES.MARKETEER;
    },
  },
  APPROVE_SHOWCASE_PRICES_BY_MARKET: {
    taskType: TASK_TYPES.CHANGE_SHOWCASE_PRICES,
    isOnlyApproveAction: true,
    validate: (task) => task.task_id && task.stage === SPECIALITIES.MARKETEER,
    afterAction: (task) => {
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  APPROVE_SHOWCASE_PRICES_BY_CAPTAIN: {
    taskType: TASK_TYPES.CHANGE_SHOWCASE_PRICES,
    canSendToRevision: true,
    validate: (task) =>
      task.task_id && task.stage === SPECIALITIES.CAPTAIN && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task, to_revision) => {
      if (to_revision !== undefined) {
        if (to_revision) {
          task.stage = SPECIALITIES.MARKETEER;
        } else {
          if (RootStore.appStore.isCorpMode) {
            task.status = TEAM_TASK_STATUS.IN_PROGRESS;
            task.stage = SPECIALITIES.CEO;
          } else {
            task.status = TEAM_TASK_STATUS.CONFIRMED;
          }
        }
      }
    },
  },
  APPROVE_SHOWCASE_PRICES_BY_CEO: {
    taskType: TASK_TYPES.CHANGE_SHOWCASE_PRICES,
    canSendToRevision: true,
    validate: (task) => task.task_id && task.stage === SPECIALITIES.CEO && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task, to_revision) => {
      if (to_revision !== undefined) {
        if (to_revision) {
          task.stage = SPECIALITIES.MARKETEER;
        } else {
          task.status = TEAM_TASK_STATUS.CONFIRMED;
        }
      }
    },
  },
  APPROVE_BY_DOCTOR: {
    taskType: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
    isMainForSpeciality: SPECIALITIES.DOCTOR,
    validate: (task) =>
      task.task_id &&
      task.visible_to.find((spec) => spec === SPECIALITIES.DOCTOR) &&
      !task.visas.hasOwnProperty(SPECIALITIES.DOCTOR),
    afterAction: (task) => {
      task.stage = task.visible_to.find((spec) => spec === SPECIALITIES.CAPTAIN)
        ? SPECIALITIES.CAPTAIN
        : task.content.request_type === 'buy'
        ? SPECIALITIES.SUPPLY
        : SPECIALITIES.SALES;
    },
  },
  CANCEL_OWN_TASK: {
    taskType: TASK_TYPES._ANY_TASK,
    isOnlyCancelAction: true,
    validate: (task) => {
      const result =
        task.task_id &&
        task.status === TEAM_TASK_STATUS.IN_PROGRESS &&
        RootStore.appStore.companyPlayerBySpeciality(task.stage)?.uuid !== LocalStorage.USER_UID &&
        task.created_by === LocalStorage.USER_UID;
      if (
        (result && task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST && task.content?.lot_id) ||
        task.type === TASK_TYPES.CREATE_CHANGE_LTD_REQUEST
      ) {
        return false;
      }
      return result;
    },
    // TODO нужна ли проверка на то, что капитан ещё не поставил свою визу?
    afterAction: (task) => {
      task.status = TEAM_TASK_STATUS.CANCELLED;
    },
  },
  CREATE_CANCEL_BUY_REQUEST_TASK: {
    taskType: TASK_TYPES.CANCEL_OWN_TRADE_REQUEST,
    isOnlyCancelAction: true,
    validate: (task) =>
      !task.task_id &&
      task.content.request_type === REQUEST_TYPE.BUY &&
      RootStore.appStore.currentPlayer.lot.initiator === RootStore.appStore.me.uuid,
    afterAction: (task) => {
      if (!task.task_id) {
        task.task_id = 'some_id';
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      }
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  CREATE_CANCEL_SELL_REQUEST_TASK: {
    taskType: TASK_TYPES.CANCEL_OWN_TRADE_REQUEST,
    isOnlyCancelAction: true,
    validate: (task) =>
      !task.task_id &&
      task.content.request_type === REQUEST_TYPE.SELL &&
      RootStore.appStore.currentPlayer.lot.initiator === RootStore.appStore.me.uuid,
    afterAction: (task) => {
      if (!task.task_id) {
        task.task_id = 'some_id';
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      }
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  CANCEL_BUY_REQUEST_BY_CAPTAIN: {
    taskType: TASK_TYPES.CANCEL_OWN_TRADE_REQUEST,
    isCancelActionByCaptain: true,
    validate: (task) =>
      task.task_id &&
      task.status === TEAM_TASK_STATUS.IN_PROGRESS &&
      task.content.request_type === REQUEST_TYPE.BUY &&
      task.stage === SPECIALITIES.CAPTAIN,
    afterAction: (task) => {
      if (RootStore.appStore.isCorpMode) {
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      } else {
        task.status = TEAM_TASK_STATUS.CANCELLED;
      }
    },
  },
  CANCEL_SELL_REQUEST_BY_CAPTAIN: {
    taskType: TASK_TYPES.CANCEL_OWN_TRADE_REQUEST,
    isCancelActionByCaptain: true,
    validate: (task) =>
      task.task_id &&
      task.status === TEAM_TASK_STATUS.IN_PROGRESS &&
      task.content.request_type === REQUEST_TYPE.SELL &&
      task.stage === SPECIALITIES.CAPTAIN,
    afterAction: (task) => {
      if (RootStore.appStore.isCorpMode) {
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      } else {
        task.status = TEAM_TASK_STATUS.CANCELLED;
      }
    },
  },
  DOCTOR_REQUEST: {
    taskType: TASK_TYPES._NONE,
    validate: (task) => task.task_id,
    afterAction: (task) => {}, // TODO оставили до делегирования
  },
  FOOD_PURCHASE_REQUEST: {
    taskType: TASK_TYPES.BUY_FOOD,
    isMainForSpeciality: SPECIALITIES.DOCTOR,
    validate: (task) => !task.task_id,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.stage = SPECIALITIES.CAPTAIN;
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
    },
  },
  APPROVE_FOOD_PURCHASE_BY_CAPTAIN: {
    taskType: TASK_TYPES.BUY_FOOD,
    validate: (task) =>
      task.task_id && task.status === TEAM_TASK_STATUS.IN_PROGRESS && task.stage === SPECIALITIES.CAPTAIN,
    afterAction: (task) => {
      if (RootStore.appStore.isCorpMode) {
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
        task.stage = SPECIALITIES.CEO;
      } else {
        task.status = TEAM_TASK_STATUS.CONFIRMED;
      }
    },
  },
  APPROVE_FOOD_PURCHASE_BY_CEO: {
    taskType: TASK_TYPES.BUY_FOOD,
    validate: (task) => task.task_id && task.status === TEAM_TASK_STATUS.IN_PROGRESS && task.stage === SPECIALITIES.CEO,
    afterAction: (task) => {
      task.status = TEAM_TASK_STATUS.CONFIRMED;
    },
  },
  FEED_ALL_CATS_REQUEST: {
    taskType: TASK_TYPES.FEED_ALL_CATS,
    isMainForSpeciality: SPECIALITIES.DOCTOR,
    validate: (task) => !task.task_id,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.stage = SPECIALITIES.CAPTAIN;
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
    },
  },
  APPROVE_FEED_ALL_CATS_BY_CAPTAIN: {
    taskType: TASK_TYPES.FEED_ALL_CATS,
    validate: (task) =>
      task.task_id && task.status === TEAM_TASK_STATUS.IN_PROGRESS && task.stage === SPECIALITIES.CAPTAIN,
    afterAction: (task) => {
      if (RootStore.appStore.isCorpMode) {
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
        task.stage = SPECIALITIES.CEO;
      } else {
        task.status = TEAM_TASK_STATUS.CONFIRMED;
      }
    },
  },
  APPROVE_FEED_ALL_CATS_BY_CEO: {
    taskType: TASK_TYPES.FEED_ALL_CATS,
    validate: (task) => task.task_id && task.status === TEAM_TASK_STATUS.IN_PROGRESS && task.stage === SPECIALITIES.CEO,
    afterAction: (task) => {
      task.status = TEAM_TASK_STATUS.CONFIRMED;
    },
  },
  BUY_DRUGS: {
    taskType: TASK_TYPES.BUY_DRUGS,
    validate: (task) => !task.task_id,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  BUY_HOUSE: {
    taskType: TASK_TYPES.BUY_HOUSE,
    validate: (task) => !task.task_id,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  APPROVE_HOUSE_PURCHASE_BY_CAPTAIN: {
    taskType: TASK_TYPES.BUY_HOUSE,
    validate: (task) =>
      task.task_id && task.stage === SPECIALITIES.CAPTAIN && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task) => {
      if (RootStore.appStore.isCorpMode) {
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
        task.stage = SPECIALITIES.CEO;
      } else {
        task.status = TEAM_TASK_STATUS.CONFIRMED;
      }
    },
  },
  APPROVE_HOUSE_PURCHASE_BY_CEO: {
    taskType: TASK_TYPES.BUY_HOUSE,
    validate: (task) => task.task_id && task.stage === SPECIALITIES.CEO && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task) => {
      task.status = TEAM_TASK_STATUS.CONFIRMED;
    },
  },
  GET_CREDIT: {
    taskType: TASK_TYPES.GET_CREDIT,
    validate: (task) => !task.task_id,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  APPROVE_GET_CREDIT_BY_CAPTAIN: {
    taskType: TASK_TYPES.GET_CREDIT,
    validate: (task) =>
      task.task_id && task.stage === SPECIALITIES.CAPTAIN && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task) => {
      if (RootStore.appStore.isCorpMode) {
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
        task.stage = SPECIALITIES.CEO;
      } else {
        task.status = TEAM_TASK_STATUS.CONFIRMED;
      }
    },
  },
  APPROVE_GET_CREDIT_BY_CEO: {
    taskType: TASK_TYPES.GET_CREDIT,
    validate: (task) => task.task_id && task.stage === SPECIALITIES.CEO && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task) => {
      task.status = TEAM_TASK_STATUS.CONFIRMED;
    },
  },
  CREDIT_REPAY: {
    taskType: TASK_TYPES.CREDIT_REPAY,
    validate: (task) => !task.task_id,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  APPROVE_CREDIT_REPAY_BY_CAPTAIN: {
    taskType: TASK_TYPES.CREDIT_REPAY,
    validate: (task) =>
      task.task_id && task.stage === SPECIALITIES.CAPTAIN && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task) => {
      if (RootStore.appStore.isCorpMode) {
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
        task.stage = SPECIALITIES.CEO;
      } else {
        task.status = TEAM_TASK_STATUS.CONFIRMED;
      }
    },
  },
  APPROVE_CREDIT_REPAY_BY_CEO: {
    taskType: TASK_TYPES.CREDIT_REPAY,
    validate: (task) => task.task_id && task.stage === SPECIALITIES.CEO && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task) => {
      task.status = TEAM_TASK_STATUS.CONFIRMED;
    },
  },
  BUY_INSURANCE: {
    taskType: TASK_TYPES.BUY_HOUSE_INSURANCE,
    validate: (task) => !task.task_id,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  APPROVE_INSURANCE_PURCHASE_BY_CAPTAIN: {
    taskType: TASK_TYPES.BUY_HOUSE_INSURANCE,
    validate: (task) =>
      task.task_id && task.stage === SPECIALITIES.CAPTAIN && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task) => {
      task.status = TEAM_TASK_STATUS.CONFIRMED;
    },
  },
  ACKNOWLEDGE_TRADING_LOT: {
    taskType: TASK_TYPES.TRADING_LOT_ACKNOWLEDGE,
    validate: (task) => !task.task_id,
    afterAction: () => {},
  },
  CREATE_CITY_PURCHASE_TASK: {
    taskType: TASK_TYPES.CITY_TRADE,
    isCreateAction: true,
    validate: (task) => !task.task_id && task.content.request_type === REQUEST_TYPE.BUY,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  APPROVE_CITY_TRADE_BY_SUPPLY: {
    taskType: TASK_TYPES.CITY_TRADE,
    isOnlyApproveAction: true,
    validate: (task) =>
      task.task_id && task.status === TEAM_TASK_STATUS.IN_PROGRESS && task.content.request_type === REQUEST_TYPE.BUY,
    afterAction: (task) => {
      task.stage = SPECIALITIES.MARKETEER;
    },
  },
  CREATE_CITY_SELL_TASK: {
    taskType: TASK_TYPES.CITY_TRADE,
    isCreateAction: true,
    validate: (task) => !task.task_id && task.content.request_type === REQUEST_TYPE.SELL,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
    },
  },
  APPROVE_CITY_TRADE_BY_SALES: {
    taskType: TASK_TYPES.CITY_TRADE,
    isOnlyApproveAction: true,
    validate: (task) =>
      task.task_id && task.status === TEAM_TASK_STATUS.IN_PROGRESS && task.content.request_type === REQUEST_TYPE.SELL,
    afterAction: (task) => {
      task.stage = SPECIALITIES.MARKETEER;
    },
  },
  APPROVE_CITY_SELL_TASK_BY_CAPTAIN: {
    taskType: TASK_TYPES.CITY_TRADE,
    validate: (task) =>
      task.task_id &&
      task.status === TEAM_TASK_STATUS.IN_PROGRESS &&
      task.content.request_type === REQUEST_TYPE.SELL &&
      task.stage === SPECIALITIES.CAPTAIN,
    afterAction: (task) => {
      if (RootStore.appStore.isCorpMode) {
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
        task.stage = SPECIALITIES.CEO;
      } else {
        task.status = TEAM_TASK_STATUS.CONFIRMED;
      }
    },
  },
  APPROVE_CITY_SELL_TASK_BY_CEO: {
    taskType: TASK_TYPES.CITY_TRADE,
    validate: (task) => {
      return (
        task.task_id &&
        task.stage === SPECIALITIES.CEO &&
        task.content.request_type === REQUEST_TYPE.SELL &&
        task.status === TEAM_TASK_STATUS.IN_PROGRESS
      );
    },
    afterAction: (task) => {
      task.status = TEAM_TASK_STATUS.CONFIRMED;
    },
  },
  APPROVE_CITY_BUY_TASK_BY_CAPTAIN: {
    taskType: TASK_TYPES.CITY_TRADE,
    validate: (task) =>
      task.task_id &&
      task.status === TEAM_TASK_STATUS.IN_PROGRESS &&
      task.content.request_type === REQUEST_TYPE.BUY &&
      task.stage === SPECIALITIES.CAPTAIN,
    afterAction: (task) => {
      if (RootStore.appStore.isCorpMode) {
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
        task.stage = SPECIALITIES.CEO;
      } else {
        task.status = TEAM_TASK_STATUS.CONFIRMED;
      }
    },
  },
  APPROVE_CITY_BUY_TASK_BY_CEO: {
    taskType: TASK_TYPES.CITY_TRADE,
    validate: (task) => {
      return (
        task.task_id &&
        task.stage === SPECIALITIES.CEO &&
        task.content.request_type === REQUEST_TYPE.BUY &&
        task.status === TEAM_TASK_STATUS.IN_PROGRESS
      );
    },
    afterAction: (task) => {
      task.status = TEAM_TASK_STATUS.CONFIRMED;
    },
  },
  APPROVE_CITY_SALE_TASK_BY_MARKET: {
    taskType: TASK_TYPES.CITY_TRADE,
    isBranchingPrivilege: true,
    validate: (task) =>
      task.task_id && task.status === TEAM_TASK_STATUS.IN_PROGRESS && task.stage === SPECIALITIES.MARKETEER,
    afterAction: (task) => {
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  DRUGS_PURCHASE_REQUEST: {
    taskType: TASK_TYPES.BUY_DRUGS,
    validate: (task) => !task.task_id,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  APPROVE_DRUGS_PURCHASE_BY_CAPTAIN: {
    taskType: TASK_TYPES.BUY_DRUGS,
    validate: (task) =>
      task.task_id && task.stage === SPECIALITIES.CAPTAIN && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task) => {
      if (RootStore.appStore.isCorpMode) {
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
        task.stage = SPECIALITIES.CEO;
      } else {
        task.status = TEAM_TASK_STATUS.CONFIRMED;
      }
    },
  },
  APPROVE_DRUGS_PURCHASE_BY_CEO: {
    taskType: TASK_TYPES.BUY_DRUGS,
    validate: (task) => task.task_id && task.stage === SPECIALITIES.CEO && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task) => {
      task.status = TEAM_TASK_STATUS.CONFIRMED;
    },
  },
  MATING_REQUEST: {
    taskType: TASK_TYPES.MATING,
    validate: (task) => !task.task_id,
    afterAction: (task) => {
      task.task_id = 'some_id';
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  APPROVE_MATING_BY_CAPTAIN: {
    taskType: TASK_TYPES.MATING,
    validate: (task) =>
      task.task_id && task.stage === SPECIALITIES.CAPTAIN && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task) => {
      if (RootStore.appStore.isCorpMode) {
        task.status = TEAM_TASK_STATUS.IN_PROGRESS;
        task.stage = SPECIALITIES.CEO;
      } else {
        task.status = TEAM_TASK_STATUS.CONFIRMED;
      }
    },
  },
  APPROVE_MATING_BY_CEO: {
    taskType: TASK_TYPES.MATING,
    validate: (task) => task.task_id && task.stage === SPECIALITIES.CEO && task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: (task) => {
      task.status = TEAM_TASK_STATUS.CONFIRMED;
    },
  },
  PUSH_TO_CAPTAIN: {
    taskType: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
    validate: (task) => task.task_id && task.stage === SPECIALITIES.MARKETEER,
    afterAction: (task) => {
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  CITY_SELL_PUSH_TO_CAPTAIN: {
    taskType: TASK_TYPES.CITY_TRADE,
    isBranchingPrivilege: true,
    validate: (task) => task.status === TEAM_TASK_STATUS.IN_PROGRESS && REQUEST_TYPE.SELL,
    afterAction: (task) => {
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  CITY_BUY_PUSH_TO_CAPTAIN: {
    taskType: TASK_TYPES.CITY_TRADE,
    validate: (task) => task.status === TEAM_TASK_STATUS.IN_PROGRESS && REQUEST_TYPE.BUY,
    afterAction: (task) => {
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  CREATE_LTD_TASK: {
    taskType: TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
    isCreateAction: true,
    validate: (task) => !task.task_id && !task.content?.deal_id,
    afterAction: (task) => {
      task.task_id = 'id-placeholder';
      task.status = TEAM_TASK_STATUS.IN_PROGRESS;
      task.stage = RootStore.appStore.mySpeciality;
    },
  },
  APPROVE_LTD_TASK: {
    taskType: TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
    validate: (task) => {
      if (RootStore.appStore.meIsCEO) {
        return false;
      }

      if (RootStore.appStore.mySpeciality === SPECIALITIES.CAPTAIN) {
        if (task.content.contents.length === 2) {
          return (
            !(task.visas.supply === undefined && task.visas.sales === undefined && task.specialty_by) &&
            (task.visas.supply === undefined || task.visas.sales === undefined) &&
            task.specialty_by !== SPECIALITIES.MARKETEER
          );
        }

        return (
          (task.visas.supply === undefined && task.visas.sales === undefined) || !RootStore.appStore.withDelegationLtd
        );
      }

      return task.stage === RootStore.appStore.mySpeciality;
    },
    afterAction: (task) => {
      if (RootStore.appStore.mySpeciality === SPECIALITIES.CAPTAIN) {
        return;
      }

      task.stage = SPECIALITIES.MARKETEER;
    },
  },
  PUSH_LTD_TO_CAPTAIN: {
    taskType: TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
    validate: (task) =>
      task.task_id && task.content.contents.length === 2 ? task.visas.supply && task.visas.sales : true,
    afterAction: (task) => {
      task.stage = SPECIALITIES.CAPTAIN;
    },
  },
  PUSH_LTD_TO_CEO: {
    taskType: TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
    validate: (task) =>
      !RootStore.appStore.myPrivileges.includes(LTD_DELEGATED_ACTION_TYPES.APPROVE_LTD_TASK_BY_CAPTAIN) &&
      task.task_id &&
      task.content.contents.length === 2
        ? task.visas.supply && task.visas.sales
        : true,
    afterAction: (task) => {
      task.stage = SPECIALITIES.CEO;
    },
  },
  APPROVE_LTD_TASK_BY_MARKET: {
    taskType: TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
    validate: (task) => {
      return task.task_id && task.stage === SPECIALITIES.MARKETEER;
    },
    canSendToRevision: true,
    afterAction: (task, toRevision) => {
      if (toRevision) {
        task.stage = task.visas[SPECIALITIES.SALES] ? SPECIALITIES.SALES : SPECIALITIES.SUPPLY;
      } else {
        task.stage = SPECIALITIES.CAPTAIN;
      }
    },
  },
  APPROVE_LTD_TASK_BY_CAPTAIN: {
    taskType: TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
    validate: (task) => task.task_id && task.stage === SPECIALITIES.CAPTAIN,
    canSendToRevision: true,
    afterAction: (task, toRevision, visa) => {
      if (toRevision) {
        task.stage = task.visas[SPECIALITIES.SALES] ? SPECIALITIES.SALES : SPECIALITIES.SUPPLY;
        return;
      }

      if (!visa) {
        task.status = TEAM_TASK_STATUS.CANCELLED;
        return;
      }

      task.status = TEAM_TASK_STATUS.CONFIRMED;
    },
  },
  APPROVE_LTD_TASK_BY_CEO: {
    taskType: TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
    validate: (task) => task.task_id && task.stage === SPECIALITIES.CEO,
    canSendToRevision: true,
    afterAction: (task, toRevision, visa) => {
      if (toRevision) {
        task.stage = task.visas[SPECIALITIES.SALES] ? SPECIALITIES.SALES : SPECIALITIES.SUPPLY;
        return;
      }

      if (!visa) {
        task.status = TEAM_TASK_STATUS.CANCELLED;
        return;
      }

      task.status = TEAM_TASK_STATUS.CONFIRMED;
    },
  },
  LTD_PAYMENT_ACTION_BY_MARKET: {
    taskType: TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
    validate: (task) => task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: () => {},
  },
  LTD_PAYMENT_ACTION_BY_CAPTAIN: {
    taskType: TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
    validate: (task) => task.status === TEAM_TASK_STATUS.IN_PROGRESS,
    afterAction: () => {},
  },
};

///////////

export const PRIVILEGES = { ...PRIVILEGES_DATA };

// присваиваем, чтобы IDE думала, что здесь те же ключи, что и в PRIVILEGES и подсказывала нам
const PRIVILEGE_VALIDATES = { ...PRIVILEGES_DATA };

// Типы задач, которые можно отправлять на доработку
export const TYPES_OF_TASKS_CAN_BE_SENT_FOR_REVISION = [];
Object.entries(PRIVILEGES).forEach(([privilegeName, privilegeInfo]) => {
  if (privilegeInfo.canSendToRevision && !TYPES_OF_TASKS_CAN_BE_SENT_FOR_REVISION.includes(privilegeInfo.taskType)) {
    TYPES_OF_TASKS_CAN_BE_SENT_FOR_REVISION.push(privilegeInfo.taskType);
  }
});

// Изменяем значения, на сами ключи, чтобы получился привычный ENUM и можно было
// использовать PRIVILEGES.create_purchase_task, в этом случае вернется "create_purchase_task"
Object.keys(PRIVILEGES).forEach((priv) => {
  // const value = PRIVILEGES[priv];
  PRIVILEGES[priv] = priv.toLowerCase();
  // PRIVILEGES['__' + priv] = value; // раскоментить, если понадобится что-то из внутренностей
});

// Изменяем значения, на валидацию, чтобы получился привычный ENUM и можно было
// использовать PRIVILEGE_VALIDATES.create_purchase_task, в этом случае вернется функция валидации
Object.keys(PRIVILEGE_VALIDATES).forEach((privName) => {
  const priv = PRIVILEGES_DATA[privName];
  PRIVILEGE_VALIDATES[privName] = (task, privileges) => {
    return (
      privileges.includes(PRIVILEGES[privName]) &&
      (priv.taskType === task.type || priv.taskType === TASK_TYPES._ANY_TASK) &&
      (!priv.validate || priv.validate(task))
    );
  };
});

export const GRAPH = {
  [PRIVILEGES.APPROVE_TASK_BY_SUPPLY]: [PRIVILEGES.APPROVE_PURCHASE_TASK_BY_MARKET, PRIVILEGES.PUSH_TO_CAPTAIN],
  [PRIVILEGES.APPROVE_TASK_BY_SALES]: [PRIVILEGES.APPROVE_SALE_TASK_BY_MARKET, PRIVILEGES.PUSH_TO_CAPTAIN],
  [PRIVILEGES.PUSH_TO_CAPTAIN]: [PRIVILEGES.APPROVE_TASK_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_TASK_BY_CAPTAIN]: [PRIVILEGES.APPROVE_CATS_PURCHASE_BY_CEO, PRIVILEGES.APPROVE_CATS_SELL_BY_CEO],

  [PRIVILEGES.APPROVE_CITY_TRADE_BY_SALES]: [
    PRIVILEGES.APPROVE_CITY_SALE_TASK_BY_MARKET,
    PRIVILEGES.CITY_SELL_PUSH_TO_CAPTAIN,
  ],
  [PRIVILEGES.APPROVE_CITY_TRADE_BY_SUPPLY]: [
    PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN,
    PRIVILEGES.CITY_BUY_PUSH_TO_CAPTAIN,
  ],
  [PRIVILEGES.CITY_SELL_PUSH_TO_CAPTAIN]: [PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN]: [PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CEO],
  //
  [PRIVILEGES.CITY_BUY_PUSH_TO_CAPTAIN]: [PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN]: [PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CEO],

  [PRIVILEGES.CREATE_PURCHASE_TASK]: [PRIVILEGES.APPROVE_TASK_BY_SUPPLY],
  [PRIVILEGES.CREATE_SALE_TASK]: [PRIVILEGES.APPROVE_TASK_BY_SALES],
  [PRIVILEGES.APPROVE_PURCHASE_TASK_BY_MARKET]: [PRIVILEGES.APPROVE_TASK_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_SALE_TASK_BY_MARKET]: [PRIVILEGES.APPROVE_TASK_BY_CAPTAIN],
  [PRIVILEGES.CREATE_SHOWCASE_PRICES_TASK]: [PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_MARKET],
  [PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_MARKET]: [PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CAPTAIN]: [PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CEO],
  [PRIVILEGES.CREATE_CANCEL_BUY_REQUEST_TASK]: [PRIVILEGES.CANCEL_BUY_REQUEST_BY_CAPTAIN],
  [PRIVILEGES.CREATE_CANCEL_SELL_REQUEST_TASK]: [PRIVILEGES.CANCEL_SELL_REQUEST_BY_CAPTAIN],
  // Покупка домика
  [PRIVILEGES.BUY_HOUSE]: [PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CAPTAIN]: [PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CEO],
  // Покупка корма
  [PRIVILEGES.FOOD_PURCHASE_REQUEST]: [PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CAPTAIN]: [PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CEO],
  // Покормить всех котов
  [PRIVILEGES.FEED_ALL_CATS_REQUEST]: [PRIVILEGES.APPROVE_FEED_ALL_CATS_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_FEED_ALL_CATS_BY_CAPTAIN]: [PRIVILEGES.APPROVE_FEED_ALL_CATS_BY_CEO],
  // Взятие кредита
  [PRIVILEGES.GET_CREDIT]: [PRIVILEGES.APPROVE_GET_CREDIT_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_GET_CREDIT_BY_CAPTAIN]: [PRIVILEGES.APPROVE_GET_CREDIT_BY_CEO],
  [PRIVILEGES.CREDIT_REPAY]: [PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CAPTAIN]: [PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CEO],
  [PRIVILEGES.BUY_INSURANCE]: [PRIVILEGES.APPROVE_INSURANCE_PURCHASE_BY_CAPTAIN],
  // Покупка лекарств
  [PRIVILEGES.DRUGS_PURCHASE_REQUEST]: [PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CAPTAIN]: [PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CEO],
  //
  [PRIVILEGES.MATING_REQUEST]: [PRIVILEGES.APPROVE_MATING_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_MATING_BY_CAPTAIN]: [PRIVILEGES.APPROVE_MATING_BY_CEO],
  [PRIVILEGES.CREATE_CITY_PURCHASE_TASK]: [PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN],
  [PRIVILEGES.CREATE_CITY_SELL_TASK]: [PRIVILEGES.APPROVE_CITY_TRADE_BY_SALES],
  [PRIVILEGES.APPROVE_CITY_SALE_TASK_BY_MARKET]: [PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN],
  // Лтд делегирование
  [PRIVILEGES.CREATE_LTD_TASK]: [PRIVILEGES.APPROVE_LTD_TASK_BY_CAPTAIN],
  [PRIVILEGES.APPROVE_LTD_TASK]: [PRIVILEGES.APPROVE_LTD_TASK_BY_CAPTAIN],
  [PRIVILEGES.PUSH_LTD_TO_CAPTAIN]: [],
  [PRIVILEGES.PUSH_LTD_TO_CEO]: [],
  [PRIVILEGES.APPROVE_LTD_TASK_BY_MARKET]: [],
  [PRIVILEGES.APPROVE_LTD_TASK_BY_CAPTAIN]: [],
  [PRIVILEGES.LTD_PAYMENT_ACTION_BY_MARKET]: [],
};

export const isSendingToRevisionTaskType = (taskType) => {
  return TYPES_OF_TASKS_CAN_BE_SENT_FOR_REVISION.includes(taskType);
};

export const getActionSequence = (task, privileges, privilege, to_revision, afterActionIsNeeded = true) => {
  if (!task.type) {
    console.error('Задачи обязательно должна содержать тип');
  }
  const result = [];
  if (!task.isClone) {
    task = { ...task, isClone: true };
  }
  if (!privilege) {
    privilege = Object.keys(PRIVILEGES).find((priv) => PRIVILEGE_VALIDATES[priv](task, privileges));
    if (!privilege) {
      return [];
    }
    if (afterActionIsNeeded && PRIVILEGES_DATA[privilege].afterAction && to_revision !== undefined) {
      PRIVILEGES_DATA[privilege].afterAction(task, to_revision);
    }
    result.push(privilege.toLowerCase());
  }
  const graphItem = GRAPH[privilege.toLowerCase()];
  if (graphItem) {
    let branchingEnabler = false;
    graphItem.forEach((priv) => {
      if (PRIVILEGE_VALIDATES[priv.toUpperCase()](task, privileges) && !branchingEnabler) {
        result.push(priv.toLowerCase());
        PRIVILEGES_DATA[priv.toUpperCase()].afterAction(task, to_revision);
        result.push(...getActionSequence(task, privileges, priv, to_revision));
        if (PRIVILEGES_DATA[priv.toUpperCase()].isBranchingPrivilege) {
          branchingEnabler = true;
        }
      }
    });
  }
  return result;
};

export const playerHasPrivileges = (playerPrivileges, needPrivileges, all) => {
  if (!_.isArray(needPrivileges)) {
    needPrivileges = [needPrivileges];
  }
  if (!all) {
    return _.difference(needPrivileges, playerPrivileges).length < needPrivileges.length;
  }
  return _.difference(needPrivileges, playerPrivileges).length === 0;
};

/**
 * Возвращает принадлежность игрока к специальностям
 * @param privileges - массив строк-привилегий
 * @param specialities - массив специальностей для поиска
 * @param all - если true, то в результате будет true когда все указанные specialities есть у игрока. Если false, то хотя бы одно
 * @returns {*}
 */
export const playerHasSpecialities = (privileges, specialities, all) => {
  const includes = (spec) => {
    return privileges.includes(
      privileges.find((priv) => {
        if (!PRIVILEGES_DATA[priv.toUpperCase()]) {
          return false;
        }
        // временное решение с meIsCEO
        return (
          PRIVILEGES_DATA[priv.toUpperCase()].isMainForSpeciality === spec ||
          (RootStore.appStore.meIsCEO && spec === SPECIALITIES.CEO)
        );
      }),
    );
  };
  if (!all) {
    return specialities.some(includes);
  }
  return specialities.every(includes);
};

export function hasDefaultSpecialtyPrivileges(privileges, specialty) {
  return SPECIALITIES_DEFAULT_PRIVILEGES[specialty].every((privilege) => privileges.includes(privilege));
}

export const isVisibleFor = (task, myPrivileges) => {
  if (task?.visible_to.length) {
    return playerHasSpecialities(myPrivileges, task.visible_to);
  } else {
    return false;
  }
};

export class TaskUpdateInfo {
  constructor(taskUpdate, oldTaskInfo) {
    this.taskUpdate = taskUpdate;
    this.oldTaskInfo = oldTaskInfo;

    makeAutoObservable(this);
  }

  get taskType() {
    return this.taskUpdate.type || this.oldTaskInfo.type;
  }

  get taskId() {
    return this.taskUpdate.task_id;
  }

  get isTaskNewForMe() {
    return !RootStore.appStore.myTasks.find((task) => task.task_id === this.taskId);
  }

  get isNewTask() {
    return !this.oldTaskInfo;
  }

  get meSendTaskToRevision() {
    const canBeSentToRevision = isSendingToRevisionTaskType(this.taskType);
    if (
      !canBeSentToRevision ||
      RootStore.appStore.hasSpecialities([SPECIALITIES.SALES, SPECIALITIES.SUPPLY, SPECIALITIES.DOCTOR])
    ) {
      return false;
    }

    return (
      [SPECIALITIES.SALES, SPECIALITIES.SUPPLY, SPECIALITIES.MARKETEER].includes(this.taskUpdate.stage) &&
      RootStore.appStore.hasSpecialities(this.taskUpdate.specialty_by)
    );
  }

  get isChangeTradeRequestTaskAndIsForRevision() {
    return (
      this.taskType === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST &&
      [SPECIALITIES.SALES, SPECIALITIES.SUPPLY].includes(this.taskUpdate.stage) &&
      RootStore.appStore.hasSpecialities(this.taskUpdate.stage) &&
      [SPECIALITIES.MARKETEER, SPECIALITIES.CAPTAIN, SPECIALITIES.CEO].includes(this.taskUpdate.specialty_by)
    );
  }

  get myVisa() {
    return Object.keys(this.taskUpdate.visas)
      .filter((speciality) => RootStore.appStore.hasSpecialities(speciality))
      .every((speciality) => this.taskUpdate.visas[speciality]);
  }

  // Проверка на то, что в сделке различаются только болезни котиков.
  // Причина добавления: https://gamesalabuga.atlassian.net/browse/BCC-897
  get isCatHealingUpdate() {
    if (this.taskType !== TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST) {
      return false;
    }
    const pickNeededTaskInfo = (taskInfo) => {
      return _.omit(taskInfo, ['server_time', 'message_owner']);
    };

    const neededOldTaskInfo = pickNeededTaskInfo(this.oldTaskInfo);
    const neededTaskUpdateInfo = pickNeededTaskInfo(this.taskUpdate);
    const taskInfoDifference = getDifferenceOfObjects(neededOldTaskInfo, neededTaskUpdateInfo);
    if (taskInfoDifference.length === 1 && _.first(taskInfoDifference) === 'content') {
      const oldTaskInfoContent = neededOldTaskInfo.content;
      const taskUpdateContent = neededTaskUpdateInfo.content;

      const contentDifference = getDifferenceOfObjects(oldTaskInfoContent, taskUpdateContent);
      if (contentDifference.length === 1 && _.first(contentDifference) === 'contents') {
        const oldTaskInfoCatContents = neededOldTaskInfo.content.contents;
        const taskUpdateCatContents = neededTaskUpdateInfo.content.contents;

        const catContentsDifference = getDifferenceOfObjects(oldTaskInfoCatContents, taskUpdateCatContents);
        if (catContentsDifference.length) {
          return catContentsDifference.every((catIndex) => {
            const oldContentsCat = oldTaskInfoCatContents[catIndex];
            const taskUpdateContentsCat = taskUpdateCatContents[catIndex];

            const catDifference = getDifferenceOfObjects(oldContentsCat, taskUpdateContentsCat);

            if (catDifference.length === 1 && _.first(catDifference) === 'detail') {
              const oldContentsCatDetail = oldContentsCat.detail;
              const taskUpdateContentsCatDetail = taskUpdateContentsCat.detail;

              const detailDifference = getDifferenceOfObjects(oldContentsCatDetail, taskUpdateContentsCatDetail);
              return detailDifference.length === 1 && _.first(detailDifference) === 'sickness';
            }
            return false;
          });
        }
      }
    }
    return false;
  }

  get actionsFromActionSequence() {
    let task;
    if (this.isNewTask || this.isChangeTradeRequestTaskAndIsForRevision) {
      task = this.taskUpdate;
    } else {
      task = this.oldTaskInfo;
    }

    return getActionSequence(
      task,
      RootStore.appStore.myPrivileges,
      null,
      this.meSendTaskToRevision,
      !this.isNewTask,
    ).map((act_name) => {
      return {
        act_name,
        params: { ...this.taskUpdate.content, visa: this.myVisa, to_revision: this.meSendTaskToRevision },
      };
    });
  }

  get actionsForNotificationModal() {
    if (this.isDoctorRequest) {
      return [
        {
          act_name: PRIVILEGES.DOCTOR_REQUEST,
          params: {},
        },
      ];
    } else {
      return this.actionsFromActionSequence;
    }
  }

  get modalInfo() {
    const defaultModalInfo = {
      latestAction: _.last(this.actionsFromActionSequence),
      taskType: this.taskUpdate.type,
      task_id: this.taskUpdate.task_id,
      existTask: this.taskUpdate,
    };

    if (this.isDoctorRequest) {
      return {
        ...defaultModalInfo,
        taskType: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
        latestAction: this.actionsForNotificationModal[0],
      };
    }

    if (RootStore.appStore.hasSpecialities(SPECIALITIES.MARKETEER) && this.isFinishedChangeShowCasePrices) {
      return { existTask: this.taskUpdate, taskType: this.taskType };
    }

    if (this.isFinishedBuyingOrSellingCats) {
      if (RootStore.appStore.hasSpecialities([SPECIALITIES.SALES, SPECIALITIES.SUPPLY])) {
        return {
          existTask: this.taskUpdate,
        };
      } else {
        return {
          existTask: this.taskUpdate,
          latestAction: _.last(this.actionsFromActionSequence),
          taskType: this.taskType,
          task_id: this.taskId,
        };
      }
    }

    if (
      !this.isTaskNewForMe &&
      (this.meIsExecutorOfThisAction || (this.isFinishedTask && this.meHasSpecialityOfTaskCreatedBy))
    ) {
      return {
        existTask: this.taskUpdate,
      };
    }

    return defaultModalInfo;
  }

  get isBuyOrSellingCatsTask() {
    return [TASK_TYPES.CITY_TRADE, TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST].includes(this.taskType);
  }

  get isFinishedTask() {
    return [TEAM_TASK_STATUS.CONFIRMED, TEAM_TASK_STATUS.CANCELLED].includes(this.taskUpdate.status);
  }

  get isFinishedBuyingOrSellingCats() {
    return this.isFinishedTask && this.isBuyOrSellingCatsTask;
  }

  get isFinishedChangeShowCasePrices() {
    return this.taskType === TASK_TYPES.CHANGE_SHOWCASE_PRICES && this.isFinishedTask;
  }

  get isFinishedCityTrade() {
    return this.taskType === TASK_TYPES.CITY_TRADE && this.isFinishedTask;
  }

  get lotIdOfTask() {
    const content = this.taskUpdate.content;
    if (!content || !content.lot_id) {
      return null;
    }

    return content.lot_id;
  }

  get lotOfTask() {
    const lotId = this.lotIdOfTask;
    if (!lotId) {
      return null;
    }
    return RootStore.appStore.lotById(lotId);
  }

  get taskAfterAction() {
    const actions = this.actionsFromActionSequence;
    if (!actions.length) {
      return null;
    }

    const dataPrivilege = PRIVILEGES_DATA[_.last(actions).act_name.toUpperCase()];
    const taskAfterAction = _.cloneDeep(this.oldTaskInfo);
    dataPrivilege.afterAction(taskAfterAction, this.meSendTaskToRevision, this.myVisa);

    return taskAfterAction;
  }

  get meIsExecutorOfAction() {
    return (previousAction) => {
      let taskInfo = this.taskUpdate;
      if (previousAction) {
        taskInfo = this.oldTaskInfo;
      }

      return RootStore.appStore.hasSpecialities(taskInfo.stage);
    };
  }

  get meIsExecutorOfThisAction() {
    return this.meIsExecutorOfAction(false);
  }

  get meIsExecutorOfPreviousAction() {
    return this.meIsExecutorOfAction(true);
  }

  get meHasSpecialityOfTaskCreatedBy() {
    const createdById = this.taskUpdate.created_by;
    return RootStore.appStore.hasSpecialities(RootStore.appStore.companyPlayerById(createdById).specialty);
  }

  get lotInTaskIsFinished() {
    const lot = this.lotOfTask;
    if (!lot) {
      return false;
    }
    return [LOT_STATUS.CANCELLED, LOT_STATUS.CONFIRMED].includes(lot.status);
  }

  get showNotification() {
    if (this.isDoctorRequest) {
      return true;
    }

    if (this.isChangeTradeRequestTaskAndIsForRevision) {
      return true;
    }

    if (
      RootStore.appStore.hasSpecialities(SPECIALITIES.MARKETEER) &&
      this.isFinishedChangeShowCasePrices &&
      RootStore.appStore.playerHasAllPrivilegesForFinishChangeShowcasePrices(RootStore.appStore.myPrivileges)
    ) {
      return true;
    }

    if (this.oldTaskInfo && this.isCatHealingUpdate) {
      return false;
    }

    if (this.oldTaskInfo && this.actionsFromActionSequence.length) {
      if (this.isFinishedCityTrade) {
        return true;
      }

      if (
        this.taskUpdate.stage === this.taskAfterAction.stage &&
        this.taskUpdate.status === this.taskAfterAction.status
      ) {
        if (this.isBuyOrSellingCatsTask) {
          return !this.lotInTaskIsFinished;
        }
        return true;
      }

      if (
        !this.isTaskNewForMe &&
        !this.isFinishedBuyingOrSellingCats &&
        (this.meIsExecutorOfThisAction || (this.isFinishedTask && this.meHasSpecialityOfTaskCreatedBy))
      ) {
        return true;
      }

      if (!this.isTaskNewForMe && this.meIsExecutorOfThisAction && this.isFinishedBuyingOrSellingCats) {
        const lot = this.lotOfTask;
        const taskHasLot = !!lot;
        return (
          RootStore.appStore.hasSpecialities([
            SPECIALITIES.SALES,
            SPECIALITIES.SUPPLY,
            SPECIALITIES.CAPTAIN,
            SPECIALITIES.CEO,
          ]) &&
          (!taskHasLot ||
            (lot && !['confirmed', 'cancelled'].includes(lot.status) && corpStore.captainById(lot.updater)))
        );
      }
    }
    const specialityBy = this.taskUpdate.specialty_by ?? RootStore.appStore.mySpeciality;

    return RootStore.appStore.hasSpecialities(specialityBy);
  }

  get isDoctorRequest() {
    let isDoctorRequest = false;
    if (this.taskUpdate.visible_to.includes(SPECIALITIES.DOCTOR) && !this.isFinishedTask) {
      let speciality;
      if (RootStore.appStore.hasSpecialities(SPECIALITIES.SALES)) {
        speciality = SPECIALITIES.SALES;
      } else if (RootStore.appStore.hasSpecialities(SPECIALITIES.SUPPLY)) {
        speciality = SPECIALITIES.SUPPLY;
      } else if (RootStore.appStore.hasSpecialities(SPECIALITIES.CAPTAIN)) {
        speciality = SPECIALITIES.CAPTAIN;
      }
      isDoctorRequest = this.taskUpdate.stage === speciality && this.taskUpdate.specialty_by === speciality;
    }
    return isDoctorRequest;
  }
}
