// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/credit_logo.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".credit-logo{display:block;width:100%;padding-bottom:100%;background-color:#fdf6ee;box-shadow:0px 4px 10px rgba(0,0,0,.9);border-radius:8px;z-index:7;background-repeat:no-repeat;background-position:center;background-size:82%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");cursor:pointer}", "",{"version":3,"sources":["webpack://./CreditLogo.scss"],"names":[],"mappings":"AAAA,aACE,aAAA,CACA,UAAA,CACA,mBAAA,CACA,wBAAA,CACA,sCAAA,CACA,iBAAA,CACA,SAAA,CACA,2BAAA,CACA,0BAAA,CACA,mBAAA,CACA,wDAAA,CACA,cAAA","sourcesContent":[".credit-logo{display:block;width:100%;padding-bottom:100%;background-color:#fdf6ee;box-shadow:0px 4px 10px rgba(0,0,0,.9);border-radius:8px;z-index:7;background-repeat:no-repeat;background-position:center;background-size:82%;background-image:url(\"../../../assets/credit_logo.png\");cursor:pointer}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
