import React from 'react';
import trainingStore from '../../store/trainingStore';
import { observer } from 'mobx-react';
import Modal from './Modal/Modal';
import TextButton from '../buttons/TextButton/TextButton';
import './EarlyRepaymentModal.scss';
import corpStore from '../../store/CorpStore';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import WalletIcon from '../icons/modal/WalletIcon';

function ImportPaymentModal() {
  const price = corpStore.importPaymentModalData?.price;

  if (!corpStore.importPaymentModalData) {
    return null;
  }

  return (
    <Modal
      isOpen={!!corpStore.importPaymentModalData}
      onClose={() => corpStore.hideImportPaymentModal()}
      title="Вы оплатили постоплату импорта"
      colorBg="green"
      customClass="credit-notification-modal"
      shouldCloseOnOverlayClick={!trainingStore.currentStep}
    >
      <WalletIcon fill="white" className="modal__body-icon" />
      <div className="modal__body-price">
        <p className="modal__body-price-text">итого сумма</p>
        <p className="modal__body-price-coin">
          <CoinModalIcon className="modal__body-price-coin-icon" />
          <span className="modal__body-price-coin-count">{price}</span>
        </p>
      </div>
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={() => corpStore.hideImportPaymentModal()} />
      </div>
    </Modal>
  );
}

export default observer(ImportPaymentModal);
