import React from 'react';
import _ from 'lodash';
import { makeAutoObservable, when } from 'mobx';
import RootStore from './index';
import { LocalStorage } from '../common/localStorage';
import api from '../api';
import routes from '../routes';
import trainings from './trainings/basicTrainings';
import { PLATFORM_URLS } from '../common/platformUrls';
import Step from './trainings/base/Step';
import ltdTraining from './trainings/LTDTrainings';
import Chapter from './trainings/base/Chapter';
import { TRAINING_CHAPTER_TYPE, TRAINING_TASK_STATUS } from './constants';

// добавил переменную в window для тестировщиков
window.TIMING_SHOWED_ACTIONS_BUTTON = 3000;

class TrainingStore {
  currentTrainingType = TRAINING_CHAPTER_TYPE.BASIC;
  steps = [];
  currentTask = null;
  launched = false;
  launchedChapter = 0;
  currentStepIndex = -1;
  isRolledCalc = window.innerWidth <= 1200;
  showErrorModal = false;
  isShowWaitingModal = false;
  showedNextButton = false;
  isChangingStepClass = false;
  timeoutChangingStepClass = null;
  calculator = {
    headers: [],
    examples: [],
  };
  showedStepModal = true;
  showExtra = false;
  game_uuid = '';
  chapter = null;
  originalInstanceMethods = [];
  onSeamlessChapterChangeCallbacks = [];
  isAllowExit = false;

  constructor() {
    makeAutoObservable(this);
  }

  addOnSeamlessChangeCallback(callback) {
    this.onSeamlessChapterChangeCallbacks.push(callback);
  }

  resetOnSeamlessChangeCallbacks() {
    this.onSeamlessChapterChangeCallbacks = [];
  }

  startOnChapterChangeCallbacks() {
    this.onSeamlessChapterChangeCallbacks.forEach((callback) => {
      callback();
    });
    this.resetOnSeamlessChangeCallbacks();
  }

  setOriginalInstanceMethods(originalInstanceMethods) {
    this.originalInstanceMethods = originalInstanceMethods;
  }

  returnOriginalInstanceMethods() {
    this.originalInstanceMethods.forEach(({ instance, methodName, method }) => {
      instance[methodName] = method;
    });
  }

  changeCurrentTrainingType(trainingType) {
    this.currentTrainingType = trainingType || TRAINING_CHAPTER_TYPE.BASIC;
  }

  showStepModal() {
    this.showedStepModal = true;
  }

  hideStepModal() {
    this.showedStepModal = false;
  }

  hideExtraMenu() {
    RootStore.appStore.hideExtra();
  }

  get currentStep() {
    const beforeStepsInitializationStep = this.chapter?.beforeStepsInitializationStep ?? null;
    const isLastStepAndHasNextChapter = this.isLastStep && this.chapter.nextChapter;
    if ((this.currentStepIndex === -1 || isLastStepAndHasNextChapter) && beforeStepsInitializationStep) {
      return beforeStepsInitializationStep;
    }

    return this.steps?.[this.currentStepIndex] ?? null;
  }

  get prevStep() {
    return this.steps?.[this.currentStepIndex - 1];
  }

  async startSteps({ chapter, isTask }) {
    const appLayout = document.getElementById('appLayout');
    appLayout.style.pointerEvents = 'none';
    if (chapter instanceof Chapter) {
      chapter.generateSteps();
    }
    this.resetCalculator();
    window.trainingPreventSetOpenedGroupedCat = false;
    window.runTraining = true;
    this.launchedChapter = chapter.chapter;
    this.chapter = chapter;
    if (chapter.onLaunch) {
      await chapter.onLaunch();
    }
    setTimeout(() => {
      if (isTask) {
        document.querySelectorAll('body')[0].className =
          'run-training training--task training--chapter-' + chapter.chapter;
        this.launchTrainingTask(chapter.task);
      } else {
        document.querySelectorAll('body')[0].className =
          'run-training training--steps training--chapter-' + chapter.chapter;
        this.launchTraining(chapter.steps);
      }
    });

    setTimeout(() => {
      LocalStorage.WAS_TRAINING = true;
      LocalStorage.TRAINING_TYPE = this.currentTrainingType;
    });
  }

  checkChapterProgress({ type, chapter }) {
    if (type === TRAINING_CHAPTER_TYPE.LTD) {
      return ltdTraining.checkTrainingProgress(chapter);
    } else {
      if (type === TRAINING_CHAPTER_TYPE.BASIC && chapter.chapter > RootStore.appStore.tutorialProgress.chapter + 1) {
        RootStore.goTo(LocalStorage.USER_TOKEN_TRAINING ? routes.training : routes.startTraining, {
          training_type: TRAINING_CHAPTER_TYPE.BASIC,
        });
        return false;
      }

      return true;
    }
  }

  async launchChapter({ chapter, isTask = false, type = TRAINING_CHAPTER_TYPE.BASIC, isSeamlessStart = false }) {
    if (!this.checkChapterProgress({ type, chapter })) {
      return;
    }
    const isLTDTraining = type === TRAINING_CHAPTER_TYPE.LTD;

    let startStepIsInitiated = false;
    if (isLTDTraining && !isSeamlessStart) {
      startStepIsInitiated = true;
      LocalStorage.ORIGINAL_TRAINING_USER_UID = LocalStorage.USER_UID;

      const initialTrainingUserIdentifier = chapter.getUserIdentifierForSocketInitialization(true);
      if (initialTrainingUserIdentifier) {
        LocalStorage.USER_UID = initialTrainingUserIdentifier;
      }

      this.chapter = chapter;

      RootStore.appStore.addOnAppInitializationCallbacks(() => {
        this.startSteps({ isTask, chapter });
      });
    }

    const result = await api.common.createTutorial(
      isLTDTraining ? chapter.stepsApiParams : chapter.chapter,
      isLTDTraining,
      isTask,
    );
    const joinUrl = new URL(result.join_url).host.replace('develop', 'dev');
    const gameUid = result.game_uuid;
    const userUid = LocalStorage.USER_UID;

    if (isSeamlessStart) {
      await RootStore.appStore.seamlessEnterToTutorialSession({ gameUid, userUid, joinUrl });
      this.chapter = chapter;
      this.currentStepIndex = -1;
      this.startOnChapterChangeCallbacks();
      when(
        () => this.currentStepIndex === 0,
        () => {
          this.chapter.previousChapter?.resetOnChapterInitializationBlockedActionsNames();
        },
      );
      RootStore.appStore.addOnAppInitializationCallbacks(() => {
        this.startSteps({ isTask, chapter });
      });
    } else {
      LocalStorage.JOIN_URL = joinUrl;
      this.game_uuid = gameUid;
      const initRouteName = (isTask && chapter.task?.initRouteName) || 'main';
      await RootStore.goTo(routes.init, { gameUid: gameUid, userUid: userUid, initRouteName }, { server: joinUrl });
    }

    if (!startStepIsInitiated) {
      await this.startSteps({ chapter, isTask });
    }
  }
  /**
   * calculator это объект с headers и examples.
   * Метод установки заголовков в калькуляторе(headers)
   * @param headers это массив который может содержать как строки так и объекты(полное описание в примере)
   * Пример: ['На счете',{ val: 'Расход', color: 'red', icon: <CatCalcIcon className="training-calc__icon" /> },,{ val: 'КОММУНАЛЬНЫЕ УСЛУГИ', color: 'red', col: 2 },'остаток','доход','на счете']
   */
  setCalculateHeader(headers) {
    this.calculator.headers = headers;
  }

  /**
   * Метод установки примеров с данными в калькуляторе(examples)
   * @param examples это массив который содержит другие массивы, внутренние массивы содержат как строки так и объекты(полное описание в примере)
   * Пример: [
     ['34',{ val: '-22', color: 'red' },{ val: '-3', color: 'red' },{ val: '-3', color: 'red' },'0',{ val: '+40', color: 'green' },'40'],
     ['34', { val: '-11', color: 'red' },{ val: '-3', color: 'red' },{ val: '-3', color: 'red' },'11',{ val: '+22', color: 'green' },'31',],
   ]
   */
  setCalculateExamples(examples) {
    this.calculator.examples = examples;
  }

  resetCalculator() {
    this.calculator = {
      headers: [],
      examples: [],
    };
  }

  setRolledCalc(val) {
    this.isRolledCalc = val;
  }

  timerIsHidden(timerId) {
    if (this.currentStep instanceof Step) {
      return this.currentStep.hiddenTimersIds?.includes(timerId);
    }
    return false;
  }

  launchTraining(steps) {
    this.steps = steps || this.chapter.steps;
    this.launched = true;
    const startSteps = () => {
      setTimeout(() => {
        this.nextStep();
      }, 1000);
    };
    if (RootStore.appStore.appIsInitiated) {
      startSteps();
    } else {
      RootStore.appStore.addOnAppInitializationCallbacks(() => {
        setTimeout(() => {
          startSteps();
        }, 100);
      });
    }
    window.onpopstate = () => {
      if (this.history) {
        this.history.forward();
      }
    };
  }

  launchTrainingTask(task) {
    const appLayout = document.getElementById('appLayout');
    this.launched = true;
    setTimeout(() => {
      this.currentTask = task;
      this.showedNextButton = false;
      setTimeout(() => {
        this.showedNextButton = true;
        this.isAllowExit = true;
        appLayout.style.pointerEvents = 'auto';
      }, TIMING_SHOWED_ACTIONS_BUTTON);
      this.currentTask.data = _.cloneDeep(task.data);
      this.openTask();
      this.currentTask.status = TRAINING_TASK_STATUS.IN_PROGRESS;
    }, 1000);
  }

  async relaunchTask(shouldSendProgress = true) {
    if (shouldSendProgress) {
      api.common.setTutorialProgress(this.currentTask.chapter);
    }

    this.launched = false;
    this.currentStepIndex = -1;
    RootStore.appStore.closeSessionWithoutReload(true);
    this.launchChapter({
      type: this.currentTrainingType,
      chapter: trainings[`chapter${this.launchedChapter}`],
      isTask: true,
    });
  }

  get mainScreenTrainingHref() {
    return LocalStorage.USER_TOKEN_TRAINING
      ? `/play/training/${this.currentTrainingType}`
      : `/start-training/${this.currentTrainingType}`;
  }

  async stopTraining(exit = false) {
    this.resetOnSeamlessChangeCallbacks();
    this.isAllowExit = false;

    const originalTrainingUserUid =
      LocalStorage.ORIGINAL_TRAINING_USER_UID !== 'undefined' ? LocalStorage.ORIGINAL_TRAINING_USER_UID : null;
    if (originalTrainingUserUid) {
      LocalStorage.USER_UID = originalTrainingUserUid;
      LocalStorage.ORIGINAL_TRAINING_USER_UID = '';
    }

    if (exit) {
      this.removeClassesOfPrevStep(this.currentStep);
      this.returnOriginalInstanceMethods();
      if (this.currentStep instanceof Step) {
        this.currentStep.onNextStep();
      }
    }

    RootStore.appStore.stopHandleMessages = false;
    RootStore.appStore.handleMessages();
    window.runTraining = false;
    this.launched = false;
    this.currentStepIndex = -1;
    window.onpopstate = null;
    RootStore.appStore.closeSessionWithoutReload(true);
    const steps = this.steps;
    const launchedChapter = this.launchedChapter;
    this.currentTask = null;
    this.steps = [];
    if (exit) {
      this.chapter = null;
      RootStore.goTo(LocalStorage.USER_TOKEN_TRAINING ? routes.training : routes.startTraining, {
        training_type: this.currentTrainingType,
      });
      if (this.currentTrainingType === TRAINING_CHAPTER_TYPE.LTD) {
        this.game_uuid = '';
        this.launchedChapter = 0;
        this.timeoutChangingStepClass = null;
      }
    } else if (this.currentTrainingType === TRAINING_CHAPTER_TYPE.BASIC) {
      if (steps?.length && trainings[`chapter${launchedChapter}`]?.task) {
        this.launchChapter({
          type: this.currentTrainingType,
          chapter: trainings[`chapter${launchedChapter}`],
          isTask: true,
        });
      } else {
        if (this.launchedChapter === 12) {
          location.href = PLATFORM_URLS.CLIENT;
        } else {
          await RootStore.appStore.getTutorialProgress({});
          this.launchChapter({
            type: this.currentTrainingType,
            chapter: trainings[`chapter${launchedChapter + 1}`],
          });
        }
      }
    } else if (this.currentTrainingType === TRAINING_CHAPTER_TYPE.LTD) {
      if (this.chapter === _.last(ltdTraining.chapters)) {
        location.href = PLATFORM_URLS.CLIENT;
      } else {
        await RootStore.appStore.getTutorialProgress({});
      }
    }
  }

  openTask() {
    if (this.currentTask) {
      this.currentTask.openedTask = true;
    }
  }

  hideTask() {
    if (this.currentTask) {
      this.checkIsCorrectAction('hideTaskModal');
      this.currentTask.openedTask = false;
    }
  }

  hideErrorModal() {
    this.showErrorModal = false;
  }

  showWaitingModal() {
    this.isShowWaitingModal = true;
  }

  hideWaitingModal() {
    this.isShowWaitingModal = false;
  }

  checkIsCorrectAction(name, params) {
    console.log('Action', name, params);
    if (!this.launched) {
      return true;
    }

    let toNextStep = true;
    if (this.currentStep?.actionName === name || this.currentStep?.actionNames?.includes(name)) {
      if (this.currentStep.checkParams) {
        const result = this.currentStep.checkParams(params, name);
        if (result === false) {
          this.showErrorModal = (() => {
            // Сделано так, потому что все шаги в стандартном обучении не используют class Step
            if (this.currentStep instanceof Step) {
              return this.currentStep.showingErrorModalIsNeeded;
            } else {
              return true;
            }
          })();
          // Сделано так, потому что все шаги в стандартном обучении не используют class Step
          if (this.currentStep instanceof Step && this.currentStep.acceptActionsWithInvalidCheckParams) {
            toNextStep = false;
          } else {
            return false;
          }
        } else if (result === 'skip') {
          return true;
        }
        // а если любое другое значение, т.е. true или 'success', то вызовется  nextStep
      }

      const nextStep = () => {
        if (toNextStep) {
          if (this.currentStep.after) {
            this.currentStep.after();
          }

          this.nextStep();
        }
      };

      if (
        toNextStep &&
        this.currentTrainingType === TRAINING_CHAPTER_TYPE.LTD &&
        this.currentStepIndex === _.findLastIndex(this.steps)
      ) {
        this.showWaitingModal();
        const setProgress = async () => {
          const currentChapter = this.launchedChapter.split('__')[1];
          return await api.common.setTutorialLTDProgress(+currentChapter);
        };
        setProgress()
          .then((result) => {
            if (result) {
              this.hideWaitingModal();
              nextStep();
            }
          })
          .catch(() => {
            location.href = PLATFORM_URLS.CLIENT;
          });
      } else {
        nextStep();
      }
    }

    if (this.currentTask?.watch) {
      this.currentTask.watch.forEach(async (watch) => {
        if (watch.actionName === name) {
          const result = watch.checkParams(params, this.currentTask.data);
          if (result === 'skip') {
            return false;
          } else if (result) {
            try {
              const validation = await api.common.setTutorialProgress(this.currentTask.chapter);
              this.currentTask.status = TRAINING_TASK_STATUS.COMPLETED;
              RootStore.appStore.closeSocketWithoutCloseHandle();
            } catch (e) {
              this.currentTask.status = TRAINING_TASK_STATUS.WRONG;
            }
          } else {
            await api.common.setTutorialProgress(this.currentTask.chapter);
            this.currentTask.status = TRAINING_TASK_STATUS.WRONG;
          }
        }
      });
    }
    return true;
  }

  async checkElementExist(selector, attemptCount = 0) {
    let element = document.querySelectorAll(selector)[0];
    attemptCount++;
    if (element) {
      return element;
    } else {
      if (attemptCount > 10) {
        console.log('элемент НЕ найден');
        return null;
      } else {
        const promise = new Promise((res, rej) => {
          setTimeout(() => {
            res(null);
          }, 200);
        });
        return promise.then(async () => {
          return await this.checkElementExist(selector, attemptCount);
        });
      }
    }
  }

  get isLastStep() {
    return this.chapter?.steps?.length === this.currentStepIndex;
  }

  get isATransitionalStep() {
    return this.currentStepIndex === -1 || this.currentStepIndex === this.steps?.length;
  }

  removeClassesOfPrevStep(prevStep) {
    if (!prevStep) {
      return;
    }
    const rightPanelElement = document.querySelectorAll('.right-panel')[0];
    prevStep.elements?.forEach((selector) => {
      const element = document.querySelectorAll(selector)[0];
      if (element) {
        element.classList.remove('training-z-index');
        element.classList.remove('training-disabled');
        if (rightPanelElement?.contains(element)) {
          rightPanelElement.classList.remove('training-z-index');
        }
      }
    });

    prevStep.pulseElements?.forEach((selector) => {
      const element = document.querySelectorAll(selector)[0];
      if (element) {
        element.classList.remove('training-pulse');
      }
    });

    prevStep.disabledElements?.forEach((selector) => {
      const element = document.querySelectorAll(selector)[0];
      if (element) {
        element.classList.remove('training-disabled');
      }
    });

    prevStep.shadedElements?.forEach((selector) => {
      this.changeTrainingShadedClass({ selector, add: false });
    });

    document.body.classList.remove('training--step-' + (this.currentStepIndex - 1));
  }

  changeTrainingShadedClass({ selector, add }) {
    const element = document.querySelector(selector);
    if (!element) {
      return;
    }
    if (add) {
      element.classList.add('training-shaded');
    } else {
      element.classList.remove('training-shaded');
    }
  }

  changeClassStep() {
    window.trainingStore = this;

    clearTimeout(this.timeoutChangingStepClass);
    const isSeamlessStartOfNextChapterAndIsLastStep = this.chapter.seamlessStartOfNextChapter && this.isLastStep;

    if (isSeamlessStartOfNextChapterAndIsLastStep) {
      this.currentStepIndex = -1;
      when(
        () => this.currentStepIndex === 0,
        () => {
          this.removeClassesOfPrevStep(this.prevStep);
        },
      );
    } else {
      this.removeClassesOfPrevStep(this.prevStep);
    }
    if (this.currentStep) {
      const rightPanelElement = document.querySelectorAll('.right-panel')[0];
      let element = null;

      if (this.currentStep.before) {
        this.currentStep.before();
      }

      this.currentStep.scrollIntoView?.();

      this.currentStep.disabledElements?.forEach(async (selector) => {
        element = await this.checkElementExist(selector);
        if (element) {
          element.classList.add('training-disabled');
        }
      });

      this.currentStep.pulseElements?.forEach(async (selector) => {
        element = await this.checkElementExist(selector);
        if (element) {
          element.classList.add('training-pulse');
        }
      });

      this.currentStep.elements?.forEach(async (selector) => {
        element = await this.checkElementExist(selector);
        if (element) {
          element.classList.add('training-z-index');
          if (rightPanelElement?.contains(element)) {
            rightPanelElement.classList.add('training-z-index');
          }
        }
      });

      document.body.classList.add('training--step-' + this.currentStepIndex);

      setTimeout(() => {
        if (!element) {
          const trainingModalElement = document.querySelectorAll('.training-modal')[0];
          if (trainingModalElement) {
            trainingModalElement.style.transform = 'translate(-50%, -50%)';
            trainingModalElement.style.top = '50%';
            trainingModalElement.style.left = '50%';
          }
        } else {
          if (document.querySelectorAll('.training-modal')[0]) {
            const rect = element.getBoundingClientRect();
            const modalRect = document.querySelectorAll('.training-modal')[0].getBoundingClientRect();
            const screenRect = document.body.getBoundingClientRect();
            let left = 0;
            let top = 0;
            const offset = 25;
            if (this.currentStep.position === 'bottom') {
              top = rect.top + rect.height + offset;
              left = rect.left + rect.width / 2 - modalRect.width / 2;
            } else if (this.currentStep.position === 'top') {
              top = rect.top - modalRect.height - offset;
              left = rect.left + rect.width / 2 - modalRect.width / 2;
            } else if (this.currentStep.position === 'left') {
              top = rect.top + element.offsetHeight / 2 - modalRect.height / 2;
              left = rect.left - modalRect.width - offset;
            } else if (this.currentStep.position === 'left-margin') {
              top = rect.top + element.offsetHeight / 2 - modalRect.height / 2;
              left = rect.left - modalRect.width - offset - 20;
            } else {
              top = rect.top + element.offsetHeight / 2 - modalRect.height / 2;
              left = rect.left + rect.width + offset;
            }
            if (left < 10) {
              left = 10;
            } else if (left + modalRect.width > screenRect.width - 10) {
              left = screenRect.width - 10 - modalRect.width;
            }
            document.querySelectorAll('.training-modal')[0].style.left = left;

            if (top < 10) {
              top = 10;
            } else if (top + modalRect.height > screenRect.height - 10) {
              top = screenRect.height - 10 - modalRect.height;
            }
            document.querySelectorAll('.training-modal')[0].style.top = top;
            document.querySelectorAll('.training-modal')[0].style.transform = 'translate(0, 0)';
          }
        }
      });

      this.currentStep.shadedElements?.forEach((selector) => {
        this.changeTrainingShadedClass({ selector, add: true });
      });
    }

    if (this.currentStep && this.chapter.seamlessStartOfNextChapter && this.currentStepIndex === -1) {
      this.launchChapter({ type: this.currentTrainingType, chapter: this.chapter.nextChapter, isSeamlessStart: true });
    } else if (!this.currentStep) {
      this.stopTraining();
    }
  }

  nextStep(forceWithoutTimeout = false) {
    const appLayout = document.getElementById('appLayout');
    const timeout = this.currentStep instanceof Step && !forceWithoutTimeout && this.currentStep.timeoutBeforeNextStep;
    if (timeout) {
      setTimeout(() => {
        this.nextStep(true);
      }, timeout);
      return;
    }
    if (this.isChangingStepClass) {
      this.changeClassStep();
    }
    if (this.currentStep instanceof Step) {
      this.currentStep.onNextStep();
      this.returnOriginalInstanceMethods();
    }
    this.currentStepIndex++;
    if (this.currentStep instanceof Step) {
      this.currentStep.atOnsetOfStep();
    }
    this.isChangingStepClass = true;
    this.showedNextButton = false;
    setTimeout(
      () => {
        this.showedNextButton = true;
        this.isAllowExit = true;
        appLayout.style.pointerEvents = 'auto';
      },
      this.currentStep?.withoutTimeout ? 0 : TIMING_SHOWED_ACTIONS_BUTTON,
    );
    if (this.currentStep?.stopHandleMessages) {
      RootStore.appStore.stopHandleMessages = true;
    }
    if (this.currentStep?.startHandleMessages) {
      RootStore.appStore.stopHandleMessages = false;
      RootStore.appStore.handleMessages();
    }
    if (this.currentStepIndex > 0) {
      this.steps[this.currentStepIndex - 1].elements?.forEach((selector) => {
        const element = document.querySelectorAll(selector)[0];
        if (element) {
          element.classList.add('training-disabled');
        }
      });
    }
    this.showStepModal();
    this.timeoutChangingStepClass = setTimeout(() => {
      this.changeClassStep();
      this.isChangingStepClass = false;
    }, 500);
  }
}

export default new TrainingStore();
