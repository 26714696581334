import React from 'react';
import { observer } from 'mobx-react';
import Modal from '../../Modal/Modal';
import RootStore from '../../../../store';
import { CatFaceIcon } from '../../../icons';
import './style.scss';
import UserList from './components/UserList';
import MainPlayer from './components/MainPlayer';
import TextButton from '../../../buttons/TextButton/TextButton';
import corpStore from '../../../../store/CorpStore';

const Content = observer(() => {
  const { appStore } = RootStore;
  if (appStore.isCorpMode) {
    if (appStore.meIsCEO) {
      const { allActiveAreaNums } = corpStore;
      return Object.entries(corpStore.corporationCompanyPlayersWithoutCEOByAreaNums).map(
        ([areaNum, players], index) => {
          const isBankrupt = !allActiveAreaNums.includes(parseInt(areaNum));
          return (
            <UserList
              key={`our_company_user_list_modal--${index}`}
              players={players}
              areaNum={areaNum}
              isBankrupt={isBankrupt}
            />
          );
        },
      );
    } else {
      return <UserList players={corpStore.companyPlayersWithoutCEO} areaNum={appStore.areaNum} />;
    }
  } else {
    return <UserList players={appStore.company_players} />;
  }
});

const OurCompanyUserListModal = () => {
  const { appStore } = RootStore;

  return (
    <Modal
      isOpen={appStore.ourCompanyUserListModalFlag}
      title="Участники команды"
      onClose={appStore.hideOurCompanyUserListModal}
      size="big"
      titleIcon={<CatFaceIcon style={{ fill: '#C6A788' }} />}
      customClass="our-company-user-list"
    >
      <MainPlayer />
      <div className="our-company-user-list__content">
        <Content />
      </div>

      <div className="modal__body-actions">
        <TextButton color="blue" title="понятно" onClick={appStore.hideOurCompanyUserListModal} />
      </div>
    </Modal>
  );
};

export default observer(OurCompanyUserListModal);
