import React from 'react';

function ContractSheet({ style, className, fill = '#FDF6EE' }) {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.82609C0 1.26528 1.27525 0 2.84834 0H7.95344V4.33696C7.95344 6.34799 9.59655 7.97826 11.6234 7.97826H16V17.1739C16 18.7347 14.7248 20 13.1517 20H2.84834C1.27525 20 0 18.7347 0 17.1739V2.82609ZM2.97143 8.23661C2.59272 8.23661 2.28571 8.5527 2.28571 8.94261C2.28571 9.33252 2.59272 9.64861 2.97143 9.64861H6.17143C6.55014 9.64861 6.85714 9.33252 6.85714 8.94261C6.85714 8.5527 6.55014 8.23661 6.17143 8.23661H2.97143ZM2.97143 11.7666C2.59272 11.7666 2.28571 12.0827 2.28571 12.4726C2.28571 12.8625 2.59272 13.1786 2.97143 13.1786H10.7429C11.1216 13.1786 11.4286 12.8625 11.4286 12.4726C11.4286 12.0827 11.1216 11.7666 10.7429 11.7666H2.97143ZM2.28571 16.0026C2.28571 15.6127 2.59272 15.2966 2.97143 15.2966H13.0286C13.4073 15.2966 13.7143 15.6127 13.7143 16.0026C13.7143 16.3925 13.4073 16.7086 13.0286 16.7086H2.97143C2.59272 16.7086 2.28571 16.3925 2.28571 16.0026Z"
        fill={fill}
      />
      <path
        d="M15.1657 5.77552C15.3397 5.94809 15.4888 6.14068 15.6108 6.34783H11.6234C10.5041 6.34783 9.59671 5.44753 9.59671 4.33696V0.382996C9.80758 0.504642 10.0036 0.653665 10.179 0.827741L15.1657 5.77552Z"
        fill={fill}
      />
    </svg>
  );
}

export default ContractSheet;
