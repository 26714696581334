import React from 'react';
import { observer } from 'mobx-react';
import routes from '../routes';
import RootStore from '../store';

import Header from '../ui/components/Header/Header';
import './inside.scss';
import Cat, { CAT_STATE } from '../ui/components/Cat/Cat';
import MagnifierIcon from '../ui/icons/MagnifierIcon';
import classNames from '../common/classNames';
import DragableItem from '../modules/deal/DragableItem';
import './shop.scss';
import { HouseIcon } from '../ui/icons';
import DragElement from '../modules/DragElement';
import corpStore from '../store/CorpStore';
import Loader from '../ui/components/Loader/Loader';
import { DRAG_AREA, DRAG_BUTTON_TYPE, DRAGGABLE_ITEM_TYPE, SPECIALITIES } from '../common/constants';
import trainingStore from '../store/trainingStore';

function Shop() {
  const { appStore } = RootStore;

  if (!appStore.me) {
    return <Loader />;
  }

  const groupedCats = appStore.playerCats[appStore.me.uuid];
  const openedGroupedCat = appStore.openedGroupedCat;
  const magnifierVision = appStore.hasSpecialities([SPECIALITIES.DOCTOR]);

  if (!groupedCats) {
    return <Loader />;
  }

  const openCat = (gender, color) => {
    if (groupedCats.find((cat) => cat.gender === gender && cat.color === color).contents?.length) {
      appStore.setOpenedGroupedCat({
        gender: gender,
        color: color,
        fromType: DRAG_AREA.SHOP_CATS,
      });
    }
  };

  return (
    <>
      <div
        className={classNames({
          'shop-page': true,
          [`season--${appStore.currentSeasonType}`]: !trainingStore.launched,
        })}
      >
        <Header
          extra={
            <span
              onClick={() => {
                if (appStore.meIsCEO) {
                  RootStore.goTo(routes.city, { areaNum: corpStore.currentAreaNum });
                } else {
                  RootStore.goTo(routes.main);
                }
              }}
            >
              <span className="nurseries-header-extra">
                <HouseIcon style={{ fill: '#FDF6EE' }} />
              </span>
            </span>
          }
        />

        <div className="shop-page__wrapper">
          {groupedCats.map((cat, id) => {
            return (
              <Cat
                key={id}
                onClick={() => {
                  openCat(cat.gender, cat.color);
                }}
                cat={cat}
                state={CAT_STATE.SINGLE}
                from={DRAG_AREA.SHOP_CATS}
                realCount
                className={{ 'shop-page__cat': true, [`shop-page__cat--${cat.gender}-${cat.color}`]: true }}
              />
            );
          })}
        </div>
      </div>

      <DragElement />
      {magnifierVision && openedGroupedCat && openedGroupedCat.contents?.some((c) => c.detail?.cat_id || c.cat_id) && (
        <DragableItem
          type={DRAGGABLE_ITEM_TYPE.BUTTON}
          from={DRAG_AREA.BUTTONS}
          data={{ buttonType: DRAG_BUTTON_TYPE.MAGNIFIER }}
          draggableOptions={{
            canDrag: (data, from, element) => {
              return true;
            },
          }}
          element={(isDragging, dragRef) => {
            return (
              <p
                ref={dragRef}
                onClick={() => {
                  if (appStore.magnifierCats.length) {
                    appStore.setCatUnderMagnifierIndex(0);
                  }
                }}
                className={classNames({
                  'magnifier-button': true,
                })}
              >
                <MagnifierIcon size={58} />
              </p>
            );
          }}
        />
      )}
    </>
  );
}

export default observer(Shop);
