import React from 'react';

function MagnifierIcon({ style, stylePath, size = 16 }) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.13069 1.07822C-0.824107 3.42461 -0.675683 10.2207 4.38056 12.5298C5.52123 13.0507 7.54018 13.133 8.75636 12.7082C9.57468 12.4224 9.59792 12.4357 10.9412 13.9598C11.6881 14.8071 12.429 15.5002 12.5877 15.5C13.0027 15.4994 15.5 13.0439 15.5 12.6361C15.5 12.4479 14.8081 11.6817 13.9623 10.9336C12.4407 9.58795 12.4275 9.5646 12.7129 8.74487C13.4395 6.65685 12.7104 3.69863 11.0962 2.18603C9.2516 0.457215 6.39976 0.0036549 4.13069 1.07822ZM8.0492 2.77782C9.22635 3.13103 10.3718 4.3673 10.7771 5.72199C11.6424 8.61556 8.63442 11.6382 5.75061 10.7726C2.55945 9.81503 1.54971 6.15172 3.82634 3.79078C4.99348 2.58055 6.32655 2.2607 8.0492 2.77782Z"
        fill="white"
        style={stylePath}
      />
    </svg>
  );
}

export default MagnifierIcon;
