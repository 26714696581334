import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import WalletIcon from '../icons/modal/WalletIcon';

function NotEnoughFoodModal() {
  const { appStore } = RootStore;

  if (!appStore.notEnoughFoodFlag) {
    return null;
  }

  return (
    <Modal
      colorBg="red"
      isOpen={appStore.notEnoughFoodFlag}
      onClose={appStore.notEnoughFoodModalClose}
      title="Недостаточно корма"
    >
      <WalletIcon className="modal__body-icon" />
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.notEnoughFoodModalClose} />
      </div>
    </Modal>
  );
}

export default observer(NotEnoughFoodModal);
