import React from 'react';

function CaptainIcon({ style }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g filter="url(#filter0_di_30_76)">
        <rect width="16" height="16" rx="8" fill="#F5C812" />
      </g>
      <path
        d="M12.0724 7.46064L11.0776 10.9832C11.0209 11.1834 10.8279 11.3138 10.6205 11.292L4.75222 10.6752C4.54479 10.6534 4.38314 10.4858 4.36934 10.2781L4.12865 6.62572C4.11786 6.46594 4.19825 6.31346 4.33617 6.23116C4.4741 6.14887 4.64683 6.15098 4.78329 6.23577L6.34406 7.20679L7.94612 4.93134C8.12455 4.6769 8.56953 4.72367 8.69116 5.00964L9.78512 7.56846L11.5137 6.94316C11.6648 6.8886 11.8336 6.92238 11.952 7.03161C12.0692 7.14073 12.1161 7.3066 12.0724 7.46064Z"
        fill="#ED8204"
      />
      <defs>
        <filter
          id="filter0_di_30_76"
          x="0"
          y="0"
          width="17"
          height="17"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_76" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_76" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_30_76" />
        </filter>
      </defs>
    </svg>
  );
}

export default CaptainIcon;
