import React from 'react';

function ChatIcon({ style, fill = '#FFFBE5' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 10.2123C0.74999 12.938 1.12318 17.0793 4.26454 18.351C5.25 18.75 5.18723 18.4583 5.18723 20.3412C5.24999 24.375 5.69399 23.9447 9.60584 21.3064L12.8751 19.101C12.8751 19.101 15.9686 19.101 17.25 18.75C20.647 17.8194 22.3354 14.7571 22.5 11.25C22.6646 7.74294 22.029 0.75 12.0395 0.75C3.74999 0.75 0.750016 5.7123 0.75 10.2123Z"
        fill="inherit"
      />
    </svg>
  );
}

export default ChatIcon;
