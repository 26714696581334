import ReactModal from 'react-modal';
import React, { useMemo } from 'react';
import { useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import './style.scss';
import { Link } from 'mobx-router';
import bigCats from '../../../assets/UserGuideModal/big_cats.png';
import insuranceIcons from '../../../assets/UserGuideModal/insurance_icons.png';

import smallCats from '../../../assets/UserGuideModal/small_cats.png';
import nurseryMainImg from '../../../assets/UserGuideModal/nursery-main.png';
import petshopMainImg from '../../../assets/UserGuideModal/petshop-main.png';
import mainScreenImg from '../../../assets/UserGuideModal/main-screen.png';
import seasonImg from '../../../assets/UserGuideModal/season.png';
import endImg from '../../../assets/UserGuideModal/end-a-turn.png';
import endModalImg from '../../../assets/UserGuideModal/modal-end.png';
import alertBalance from '../../../assets/UserGuideModal/Alert_balance.png';
import alertStart from '../../../assets/UserGuideModal/Alert_start.png';
import hungryCatBig from '../../../assets/UserGuideModal/hungry_cat_big.png';
import hungryCat from '../../../assets/UserGuideModal/hungry_cat.png';
import eatForCatModal from '../../../assets/UserGuideModal/eat_for_cat_modal.png';
import cantEatModal from '../../../assets/UserGuideModal/cant_eat_modal.png';
import creditCalculation from '../../../assets/UserGuideModal/credit_calculation.png';
import consumerCreditCalculation from '../../../assets/UserGuideModal/consumer_credit_calculation.png';
import takenCreditPage from '../../../assets/UserGuideModal/taken_credit_page.png';
import alertResults from '../../../assets/UserGuideModal/Alert_results.png';
import playerImg from '../../../assets/UserGuideModal/player.png';
import nurseryImg from '../../../assets/UserGuideModal/nursery.png';
import crossbreedingImg from '../../../assets/UserGuideModal/crossbreeding.png';
import catsInsuredHouseImg from '../../../assets/UserGuideModal/cats_insured_house.png';
import sickImg from '../../../assets/UserGuideModal/sick.png';
import sickFleasImg from '../../../assets/UserGuideModal/sick-fleas.png';
import sickFractureImg from '../../../assets/UserGuideModal/sick-fracture.png';
import sickLichenImg from '../../../assets/UserGuideModal/sick-lichen.png';
import sickPoisoningImg from '../../../assets/UserGuideModal/sick-poisoning.png';
import insuranceImg from '../../../assets/UserGuideModal/insurance.png';
import petshopImg from '../../../assets/UserGuideModal/petshop.png';
import tradePetshopImg from '../../../assets/UserGuideModal/trade-petshop.png';
import tradeNurseryImg from '../../../assets/UserGuideModal/trade-nursery.png';
import tradeModalImg from '../../../assets/UserGuideModal/trade-modal.png';
import notificationsImg from '../../../assets/UserGuideModal/notifications.png';
import botImg from '../../../assets/UserGuideModal/bot.png';
import reportImg from '../../../assets/UserGuideModal/report.png';
import listOutgoingImg from '../../../assets/UserGuideModal/list-outgoing.png';
import listIncomingImg from '../../../assets/UserGuideModal/list-incoming.png';
import listRejectedImg from '../../../assets/UserGuideModal/list-rejected.png';
import listAcceptedImg from '../../../assets/UserGuideModal/list-accepted.png';
import listCounterofferImg from '../../../assets/UserGuideModal/list-counteroffer.png';
import listQuestionImg from '../../../assets/UserGuideModal/list-question.png';
import listWaitImg from '../../../assets/UserGuideModal/list-wait.png';
import aiConfusedImg from '../../../assets/UserGuideModal/ai-confused.png';
import aiSadImg from '../../../assets/UserGuideModal/ai-sad.png';
import aiPuzzledImg from '../../../assets/UserGuideModal/ai-puzzled.png';
import aiSmileImg from '../../../assets/UserGuideModal/ai-smile.png';
import aiTrustImg from '../../../assets/UserGuideModal/ai-trust.png';
import aiWinkImg from '../../../assets/UserGuideModal/ai-wink.png';
import creatingClosure from '../../../assets/UserGuideModal/creatingClosure.png';
import classNames from '../../../common/classNames';
import longTermChoice from '../../../assets/UserGuideModal/longTermChoice.png';
import catsSail from '../../../assets/UserGuideModal/catsSail.png';
import offerZone from '../../../assets/UserGuideModal/offerZone.png';
import deliveryTime from '../../../assets/UserGuideModal/deliveryTime.png';
import dropOrder from '../../../assets/UserGuideModal/dropOrder.png';
import delayPenalty from '../../../assets/UserGuideModal/delayPenalty.png';
import latePayment from '../../../assets/UserGuideModal/latePayment.png';
import bankGuaranteeTerms from '../../../assets/UserGuideModal/bankGuaranteeTerms.png';
import bankGuarantee from '../../../assets/UserGuideModal/bankGuarantee.png';
import termination from '../../../assets/UserGuideModal/termination.png';
import headerDesktop from '../../../assets/UserGuideModal/headerDesktop.png';
import allTasks from '../../../assets/UserGuideModal/allTasks.png';
import catsTwo from '../../../assets/UserGuideModal/catsTwo.png';
import myTasks from '../../../assets/UserGuideModal/myTasks.png';
import advancePayment from '../../../assets/UserGuideModal/advancePayment.png';
import advancePaymentTrue from '../../../assets/UserGuideModal/advancePaymentTrue.png';
import qualityPenalty from '../../../assets/UserGuideModal/qualityPenalty.png';
import shipmentOfCats from '../../../assets/UserGuideModal/shipmentOfCats.png';
import stock from '../../../assets/UserGuideModal/stock.png';
import inspection from '../../../assets/UserGuideModal/inspection.png';
import deals from '../../../assets/UserGuideModal/deals.png';
import sickCat from '../../../assets/UserGuideModal/sickCat.png';
import dealsTrue from '../../../assets/UserGuideModal/dealsTrue.png';
import dealsRedTimer from '../../../assets/UserGuideModal/dealsRedTimer.png';
import claimOrPenalty from '../../../assets/UserGuideModal/claimOrPenalty.png';
import claim from '../../../assets/UserGuideModal/claim.png';
import penalty from '../../../assets/UserGuideModal/penalty.png';
import confirmation from '../../../assets/UserGuideModal/confirmation.png';
import penaltyTrue from '../../../assets/UserGuideModal/penaltyTrue.png';
import sentClaim from '../../../assets/UserGuideModal/sentClaim.png';
import termDealsOne from '../../../assets/UserGuideModal/termDealsOne.png';
import termDealsTwo from '../../../assets/UserGuideModal/termDealsTwo.png';
import mapOfCities from '../../../assets/UserGuideModal/mapOfCities.png';
import ceoCart from '../../../assets/UserGuideModal/ceoCart.png';
import ceoMainPage from '../../../assets/UserGuideModal/ceoMainPage.png';
import ceoProfile from '../../../assets/UserGuideModal/ceoProfile.png';
import ceoDelegationIcon from '../../../assets/UserGuideModal/ceoDelegationIcon.png';
import numberOfCities from '../../../assets/UserGuideModal/numberOfCities.png';
import ceoAirport from '../../../assets/UserGuideModal/ceoAirport.png';
import ceoOffice from '../../../assets/UserGuideModal/ceoOffice.png';
import ceoDelegation from '../../../assets/UserGuideModal/ceoDelegation.png';
import ceoLimits from '../../../assets/UserGuideModal/ceoLimits.png';
import budgetAllocation from '../../../assets/UserGuideModal/budgetAllocation.png';
import officeLTD from '../../../assets/UserGuideModal/officeLTD.png';
import officeTransportation from '../../../assets/UserGuideModal/officeTransportation.png';
import transportationCart from '../../../assets/UserGuideModal/transportationCart.png';
import importExport from '../../../assets/UserGuideModal/importExport.png';
import importCart from '../../../assets/UserGuideModal/importCart.png';
import exportCart from '../../../assets/UserGuideModal/exportCart.png';
import cityMarket from '../../../assets/UserGuideModal/cityMarket.png';
import demandPyramid from '../../../assets/UserGuideModal/demandPyramid.png';
import ceoBalance from '../../../assets/UserGuideModal/ceoBalance.png';
import growingUpByMonthRT from '../../../assets/UserGuideModal/growingUpByMonthRT.png';
import quarterTimersRT from '../../../assets/UserGuideModal/quarterTimersRT.png';
import growingUpRT from '../../../assets/UserGuideModal/growingUpRT.png';
import matingCatsRT from '../../../assets/UserGuideModal/matingCatsRT.png';
import restCatsRT from '../../../assets/UserGuideModal/restCatsRT.png';
import houseInsuranceRT from '../../../assets/UserGuideModal/houseInsuranceRT.png';
import runawayCatsRT from '../../../assets/UserGuideModal/runawayCatsRT.png';
import hungryCatsRT from '../../../assets/UserGuideModal/hungryCatsRT.png';
import cityDemandRT from '../../../assets/UserGuideModal/cityDemandRT.png';
import seasonChangeRT from '../../../assets/UserGuideModal/seasonChangeRT.png';
import creditsRT from '../../../assets/UserGuideModal/creditsRT.png';
import balanceDetailRT from '../../../assets/UserGuideModal/balanceDetailRT.png';

import {
  UserGuideIntroIcon,
  UserGuideGameModesIcon,
  UserGuidePlayersIcon,
  UserGuideMainScreenIcon,
  UserGuideNurseryIcon,
  UserGuidePetshopIcon,
  UserGuideCreditIcon,
  UserGuideLTD,
  UserGuideTradeIcon,
  UserGuideBotsIcon,
  UserGuideReport,
  UserGuideCloseIcon,
  UserGuideQuestionIcon,
  UserGuideHelpIcon,
  UserGuideCorpIcon,
  UserGuideRealtimeIcon,
} from '../UserGuideModal/icons';
import { useEffect } from 'react';

ReactModal.setAppElement('#root');

function UserGuideModal() {
  const [part, setPart] = useState('INTRO');
  const { appStore } = RootStore;
  const [subPart, setSubPart] = useState('');
  const wrapper = document.querySelector('div[class="user-guide-modal__main"]');
  const element = document.querySelectorAll(`[data-subpart="${subPart}"]`);

  const setSubsection = (section, subSection) => {
    if (section) {
      setPart(section);
    }

    if (subPart === subSection) {
      // перезапись делаем для того чтобы скролилл срабатывал на уже активный субПартКей
      setSubPart((origin) => '');
    }
    // обертка в сет тайм аут нужна для того чтобы картинки успели загрузится без нее и с меньшей задержкой работает не корректно
    setTimeout(() => setSubPart((origin) => subSection), 400);
  };

  useEffect(() => {
    if (!element[0]) {
      return;
    }
    element[0].scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, [subPart]);

  if (!appStore.userGuideModalFlag) {
    return null;
  }

  const PARTS = {
    INTRO: {
      title: 'введение',
      icon: <UserGuideIntroIcon />,
      subParts: {
        GLOSSARY: {
          title: 'глоссарий',
        },
      },
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="INTRO">Введение</h2>
          <p>
            Business Cats - многопользовательская сетевая экономическая стратегия (бизнес-симуляция) игрового формата.
            Цель игры — получить максимально возможную прибыль.
          </p>
          <p>Игра делится на сезоны. Сезон завершается с окончанием игрового хода.</p>
          <h3 data-subpart="GLOSSARY">Глоссарий</h3>
          <p>
            <b>Баланс</b> - Подробные данные об имуществе организации, о её доходах и расходах.
          </p>
          <p>
            <b>Достаток</b> - Материальное благополучие, доходы человеках.
          </p>
          <p>
            <b>Коммунальные услуги</b> - Доставка в здания воды, тепла, газа и других, необходимых для комфортной жизни
            услуг.
          </p>
          <p>
            <b>Питомник</b> - Место или заведение для выращивания и разведения растений или животных.
          </p>
          <p>
            <b>Маржа</b> - Разница между ценой продажи и себестоимостью покупки в среднем на каждого котика.
          </p>
          <p>
            <b>Прибыль</b> - Сумма, на которую доход превышает затраты.
          </p>
          <p>
            <b>Сделка</b> - Договор двух сторон о выполнении каких-либо обязательств.
          </p>
          <p>
            <b>Сегментация рынка</b> - Деление рынка на четкие группы покупателей, чтобы каждой группе можно было
            предложить подходящий товар.
          </p>
          <p>
            <b>Сезон игры</b> - Игровой ход. Один сезон условно равен трем месяцам.
          </p>
          <p>
            <b>Спрос</b> - Это желание потребителя купить конкретный товар или услугу по конкретной цене в течение
            определённого периода времени.
          </p>
          <p>
            <b>Операционные затраты</b> - Затраты на содержание чего-либо, которые вынужден нести владелец.
          </p>
          <p>
            <b>Скрещивание</b> - Один из методов разведения растений и животных.
          </p>
          <p>
            <b>Эффект масштаба</b> - Снижение затрат на изготовление чего-либо за счет ростов объема производства.
          </p>
          <p>
            <b>Ценообразование</b> - Процесс формирования цены на товар.
          </p>
        </section>
      ),
    },
    GAME_MODES: {
      title: 'режимы игры',
      icon: <UserGuideGameModesIcon />,
      subParts: {
        SINGLE: {
          title: 'сингл игра',
        },
        SOLO: {
          title: 'соло игра',
        },
        TEAM_PLAY: {
          title: 'командная игра',
        },
        CORPORATE: {
          title: 'корпоративная игра',
        },
        REALTIME: {
          title: 'realtime игра',
        },
      },
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="GAME_MODES">Режимы игры</h2>
          <p>
            Игра имеет <span className="notranslate"> 4 </span> режима:
          </p>
          <ul>
            <li>Сингл игра</li>
            <li>Соло игра</li>
            <li>Командная игра</li>
            <li>Корпоративная игра</li>
            <li>Realtime игра</li>
          </ul>
          <p>Количество сезонов на каждый тип игры указывается в стартовых параметрах сессии</p>
          <h3 data-subpart="SINGLE">Сингл игра</h3>
          <p>
            Режим «Сингл» — это одиночная игра с ботами (
            <span onClick={() => setPart('BOTS')} className="user-guide-modal__main-section-link">
              см. Боты
            </span>
            ) . Задача участника на данном этапе — пройти обучение и одиночные игры в роли питомника и зоомагазина. И в
            в роли питомника, и в роли зоомагазина необходимо одержать победу. После этого участник переходит к режиму
            “Соло”.
          </p>
          <h3 data-subpart="SOLO">Соло игра</h3>
          <p>
            Режим «Соло» — это одиночная многопользовательская игра, в которой игроки соревнуются между собой. В данном
            данном режиме всего один бот — зоомагазин.
          </p>
          <h3 data-subpart="TEAM_PLAY">Командная игра</h3>
          <p>
            Командная игра — это многопользовательская командная бизнес-симуляция. Здесь игроки объединяются в команды,
            играя за одну из доступных ролей. Функционал игры аналогичен версии “Соло”, но распределен по ролям в
            команде.
          </p>
          <h3 data-subpart="CORPORATE">Корпоративная игра</h3>
          <p>
            В отличие от режима «Командная» капитан команды теряет самостоятельность и подчиняется СЕО (директору). СЕО
            управляет пятью капитанами в разных городах. В каждом городе свой спрос.
          </p>
          <h3 data-subpart="REALTIME">Realtime игра</h3>
          <p>
            Режим «Realtime» – новый вид симуляции, который работает на основе Business Cats Solo. Основное отличие –
            это полный уход от таймеров в сезонах, тем самым все действия в симуляции будут осуществляться на основе
            реальных минут.
          </p>
        </section>
      ),
    },
    PLAYERS: {
      title: 'игроки',
      icon: <UserGuidePlayersIcon />,
      subParts: false,
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="PLAYERS">Игроки</h2>
          <p>В симуляции играют зоомагазины и питомники.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={nurseryMainImg} alt="Питомник" className="user-guide-modal__main-image" />
            <img src={petshopMainImg} alt="Зоомагазин" className="user-guide-modal__main-image" />
          </div>
          <p>
            Максимальное количество игроков в игре — <span className="notranslate"> 20 </span> питомников и
            <span className="notranslate"> 5 </span> зоомагазинов, один из которых — бот.
          </p>
          <p>
            Если Ваша роль в игре — зоомагазин, то питомники — ваши партнеры (контрагенты) по бизнесу, а другие
            зоомагазины — конкуренты. В игре за питомник — наоборот.
          </p>
        </section>
      ),
    },
    REALTIME: {
      isVisible: () => {
        return appStore.isRealTimeMode;
      },
      title: 'realtime игра',
      icon: <UserGuideRealtimeIcon />,
      subParts: {
        TIMERS: {
          title: 'таймеры',
        },
        GROWING_UP_CATS: {
          title: 'взросление котиков',
        },
        MATING_CATS: {
          title: 'скрещивание котиков',
        },
        HOUSE_INSURANCE: {
          title: 'страховка',
        },
        RUNAWAY_CATS: {
          title: 'условия, при которых котик убегает',
        },
        HUNGRY_CATS: {
          title: 'условия, при которых котик становится голодным',
        },
        UTILITIES: {
          title: 'коммунальные услуги',
        },
        CITY_DEMAND: {
          title: 'спрос города',
        },
        CREDITS: {
          title: 'кредиты',
        },
        BALANCE_DETAIL: {
          title: 'детализация баланса',
        },
        SEASON_CHANGE: {
          title: 'смена сезона',
        },
      },
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="REALTIME">Business Cats Realtime</h2>
          <p>
            <b>Business Cats Realtime</b> – новый режим симуляции Business Cats. Основная механика, которая будет
            отличает ее от других версий, – это полный уход от таймеров в сезонах, тем самым все действия в симуляции
            будут осуществляться на основе реальных минут. Данная версия симуляции делается на основе Business Cats Solo
            и повторяет большинство игровых механик Solo версии.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={growingUpByMonthRT}
              alt="Взросление по месяцам"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="TIMERS">Таймеры</h3>
          <p>
            В Realtime “Сезон” из Solo версии заменяется понятием “квартал” и разбиваться на три игровых месяца. Отсчёт
            в игре начинается с 00:00, в отличии от остальных версий с обратным отсчётом.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={quarterTimersRT}
              alt="Таймеры квартала"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Помимо таймера на месяцы в симуляции будут присутствовать таймеры на следующие игровые механики, где будет
            присутствовать таймер, который будет устанавливаться на один игровой месяц и настраивается перед стартом
            сессии в администраторской панели.
          </p>
          <h3 data-subpart="GROWING_UP_CATS">Взросление котиков</h3>
          <p>
            Взрослым котик будет становится через 9 игровых месяцев. Отсчёт времени начинается с момента рождения котика
            или с момента покупки котика у города / бота зоомагазина, то есть, когда зоомагазин покупает кота у города,
            тогда начинается отсчёт по времени роста. Соответственно один месяц роста котика будет длиться 1 игровой
            месяц.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={growingUpRT}
              alt="Взросление котиков"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="MATING_CATS">Скрещивание котиков</h3>
          <p>
            Скрещивание котиков будет длиться 3 игровых месяца. Отсчёт времени начинается с момента, когда пользователь
            нажмет на кнопку “Скрестить”. Скрестить нельзя котиков, возраст которых составляет меньше 9 месяцев.
          </p>
          <p>
            Если во время скрещивания папа сбежит от голода, но мама всё ещё остаётся в домике, то скрещивание не
            прервётся, и котики всё равно родятся.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={matingCatsRT}
              alt="Скрещивание котиков"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Отдых котиков будет длиться 3 игровых месяца. Отсчет по времени начинается с момента, когда котики у данной
            пары только родились.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={restCatsRT}
              alt="Отдых котиков"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="HOUSE_INSURANCE">Страховка</h3>
          <p>
            Страховка на домик будет длиться 3 игровых месяца. Отсчет по времени начинается с момента покупки страховки
            на домик. При повторном нажатии на кнопку страхование домика, страховка продлится и начнётся отсчёт на 3
            игровых месяца. При истечении времени страховка на домик автоматически исчезает и домик считается
            незастрахованным. Котята рожденные в момент действия страховки – лечатся бесплатно, если игрок сначала купил
            страховку, а потом скрестил котят, то котята будут лечится платно, т.к. страховка выйдет по времени.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={houseInsuranceRT}
              alt="Страховка домика"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="RUNAWAY_CATS">Условия, при которых котик убегает</h3>
          <p>Убегание котиков будет делиться на два разных вида:</p>
          <ol>
            <li>
              Без домика – в данном случае, котики будут убегать через 3 игровых месяца. Отсчёт времени начинается с
              момента, когда зоомагазин покупает с города, при этом, когда зоомагазин продает кота питомнику, то данный
              счетчик обновляется и будет отчитываться пока котик не окажется в домике. Также, счетчик начинает свой
              отсчет, когда котик был перемещен из домика в буфер (за забором) для котика.
            </li>
            <li>
              От болезней / голода – в данном случае, котики убегают через 1 игровой месяц. Отсчёт времени начинается с
              момента, когда котик получил как минимум одно состояние (голод / болезнь).
            </li>
          </ol>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={runawayCatsRT}
              alt="Убегание котиков"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="HUNGRY_CATS">Условия, при которых котик становится голодным</h3>
          <p>
            Голодным котик будет становиться через 3 игровых месяца. Отсчет времени будет начинаться с момента рождения,
            либо покупки его с города или предыдущего кормления. Также в окне сделок будет отображаться статус голодного
            кота без лупы и с лупой.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={hungryCatsRT}
              alt="Голодание котиков"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="UTILITIES">Коммунальные услуги</h3>
          <p>
            Коммунальные услуги будут списываться раз в 3 игровых месяца. Отсчёт начинается с момента запуска симуляции
            и после оплаты коммунальных услуг.
          </p>
          <h3 data-subpart="CITY_DEMAND">Спрос города</h3>
          <p>
            Спрос в городе восстанавливается ежеквартально, но с учетом месяцев и минут, когда были котята проданы.
            Спрос будет восстанавливаться определенное количество минут.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={cityDemandRT}
              alt="Спрос города"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            При восстановлении спроса будет учитываться количество, гендер, цвет кота и время, когда его продали.
            Например, если 3 рыжих котов продали с разницей в 10 секунд, то и восстанавливаться они будут с разницей в
            10 секунд, но через определенное время, которое рассчитывается по следующей формуле:
          </p>
          <p>
            <b>Восстановление кота</b> = изначально заданное время на месяц * 3, где 3 – константа, обозначающая 3
            игровых месяца.
          </p>
          <p>
            Также данная реализация внесет изменения в логику возвращения цены к стартовой, теперь цена на котиков будет
            возвращаться к стартовой путём «ступеней». Под ступенью подразумевается, что цена опускается на следующего
            по цене котика, в случае покрытия спроса на него. Повышение цены будет происходить в обратном порядке, когда
            спрос на кота восстанавливается и переходит на ступень выше. Ниже будет приведен пример с возвращением цены
            на СД и СМ.
          </p>
          <p>Изначальные цены и спрос на котов и пример восстановление цен на СД с учетом изменения спроса на нее.</p>
          <p>
            В данном примере цена на СД упала на 2 минуте <b>Х</b> месяца, а восстановилась по цене только к 3 минуте{' '}
            <b>Х</b> + 3 месяца. Другими словами, данная механика тесно связана в временем на восстановление спроса по
            котам.
          </p>
          <h3 data-subpart="CREDITS">Кредиты</h3>
          <p>
            Кредиты будут автоматически списываться раз в 3 игровых месяца. Отсчет по времени начинается с момента
            взятия кредита и в случае, если кредит взят на несколько кварталов, то отсчёт обновляется после оплаты одной
            из частей кредита. В случае, если у человека на время оплаты нет денег, то он сразу же признается банкротом.
            В окне по взятым кредитам будут отображаться все действующие кредиты и сроки по выплате каждого кредита, при
            этом сверху будет указываться ближайшая выплата среди всех кредитов в случае, если время выплат по
            нескольким кредитам совпадает, то будет указана общая сумма выплат по 2 и более кредитам.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={creditsRT}
              alt="Кредиты"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="BALANCE_DETAIL">Детализация баланса</h3>
          <p>
            Отчеты будут формироваться раз в 3 игровых месяца. Отсчет по времени будет начинаться с момента, когда игра
            только запустилась и до конца сессии. При получении отчета, начинает отсчёт времени на следующий отчёт. В
            квартальные отчёты будут входить все те же данные, что и в сезонах в соло версии.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={balanceDetailRT}
              alt="Детализация баланса"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="SEASON_CHANGE">Смена сезона</h3>
          <p>
            Сменный бэкграунд будет меняться раз в 3 игровых месяца. Отсчет по времени начинается с момента запуска
            сессии. Изначальный бэкграунд – лето, далее осень, зима и весна.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={seasonChangeRT}
              alt="Смена сезона"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
        </section>
      ),
    },

    MAIN_SCREEN: {
      title: 'главный экран',
      icon: <UserGuideMainScreenIcon />,
      subParts: {
        BALANCE: {
          title: 'детальный баланс',
        },
        SESSION_PLAYERS: {
          title: 'игроки сессии',
        },
        VOICE_CHAT: {
          title: 'голосовой чат',
        },
      },
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="MAIN_SCREEN">Главный экран</h2>
          <p>В центре экрана расположены домики всех игроков. Ваш домик выделен дополнительно.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={mainScreenImg}
              alt="Главный экран"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>Игра ограничена по времени. Текущий сезон и счетчик времени указаны вверху игрового экрана.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={seasonImg}
              alt="Таймер"
              className="user-guide-modal__main-image user-guide-modal__main-image--no-radius"
            />
          </div>
          <p>
            По желанию игрок может досрочно завершить сезон, нажав на кнопку “Завершить сезон” в правом верхнем углу
            экрана. После дается возможность отказаться от завершения хода, если игрок передумает.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={endImg}
              alt="Кнопка 'Завершить сезон'"
              className="user-guide-modal__main-image user-guide-modal__main-image--no-radius"
            />
            <img
              src={endModalImg}
              alt="Окно с подтверждением"
              className="user-guide-modal__main-image image-boxShadow"
            />
          </div>
          <p>
            Рядом с кнопкой завершения сезона расположены кнопки настроек, голосового чата и просмотра участников
            сессии.
          </p>
          <p>
            Кнопка настроек позволяет управлять модальными сообщениями, звуками, музыкой, а также вернуться на HR-
            платформу.
          </p>
          <p>Голосовой чат дает возможность игрокам общаться между собой, если это предусмотрено их ролью.</p>
          <p>
            Список всех участников сессии и их компетенции доступны при нажатии на кнопку с соответствующей иконкой.
          </p>
          <p>Также на главном экране располагаются иконки игрока и количество монет на его счете.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={playerImg} alt="Иконка игрока" className="user-guide-modal__main-image" />
          </div>
          <h3 data-subpart="BALANCE">Детальный баланс</h3>
          <p>При нажатии на монетку рядом со своей иконкой открывается детальный баланс.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={alertBalance} alt="Окно баланса" className="user-guide-modal__main-image" />
          </div>
          <p>
            В балансе отмечено стартовое количество монет на начало сезона, расходы и доходы игрока и информация о том,
            с кем заключались сделки в текущем сезоне. Далее находится получение и возврат тела кредита. Внизу карточки
            баланса указана прибыль и количество монет на текущий момент.
          </p>
          <p>В бизнес-симуляции у каждой роли свои расходы.</p>
          <p>Расходы зоомагазина = коммунальные услуги + покупка котиков + выплата процентов.</p>
          <p>
            Расходы питомника = покупка котиков + коммунальные услуги + кормление котиков + покупка домика + страхование
            домика + лечение котиков + выплата процентов.
          </p>
          <p>
            Доход зоомагазины и питомники получают с продажи котят. Зоомагазин торгует с городом и с питомниками.
            Питомник может торговать только с зоомагазинами.
          </p>
          <h3 data-subpart="SESSION_PLAYERS">Игроки сессии</h3>
          <p>
            Во время игры можно нажать на кнопку вызова списка игроков в правом верхнем углу экрана. Откроется окно со
            списком участников игровой сессии и уровнем компетенций по каждому участнику.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={alertStart} alt="Список игроков" className="user-guide-modal__main-image" />
          </div>
          <h3 data-subpart="VOICE_CHAT">Голосовой чат</h3>
          <p>
            В игре существует голосовой и текстовый чат. Голосовой чат доступен во всех режимах, кроме режимов “Сингл” и
            “Обучение”.
          </p>
        </section>
      ),
    },
    NURSERY: {
      title: 'питомник',
      icon: <UserGuideNurseryIcon />,
      subParts: {
        PERSONAL_AREA_NURSERY: {
          title: 'личный кабинет',
        },
        HOUSES: {
          title: 'домики для котят',
        },
        CATS: {
          title: 'котики',
        },
        FEEDING: {
          title: 'кормление',
        },
        CROSSBREEDING: {
          title: 'скрещивание',
        },
        DISEASES: {
          title: 'болезни и их лечение',
        },
        INSURANCE: {
          title: 'страховка',
        },
        UTILITIES_NURSERY: {
          title: 'коммунальные услуги',
        },
      },
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="NURSERY">Питомник</h2>
          <img
            src={nurseryImg}
            alt="Главный экран"
            className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
          />
          <h3 data-subpart="PERSONAL_AREA_NURSERY">Личный кабинет</h3>
          <p>
            В своем личном кабинете игрок-питомник видит всех котиков, которые у него есть на площадке у заборчика и в
            домиках для питомцев.
          </p>
          <p>
            Справа в питомнике отображается лента заявок (см.
            <span
              onClick={() => {
                setSubsection('TRADE', 'APPLICATIONS');
              }}
              className="user-guide-modal__main-section-link"
            >
              Заявки
            </span>
            ).
          </p>
          <p>
            Слева располагается панель с <span className="notranslate"> 4 </span> кнопками
          </p>
          <ul>
            <li>покупка домика для котиков</li>
            <li>покупка корма для котиков</li>
            <li>страхование домика</li>
            <li>осмотр котиков</li>
          </ul>
          <h3 data-subpart="HOUSES">Домики для котят</h3>
          <p>
            Домик для питомцев питомник покупает за <span className="notranslate"> 3 </span> монетки.
          </p>
          <p>
            В домике есть <span className="notranslate"> 4 </span> окна для выращивания и скрещивания взрослых пар и
            <span className="notranslate"> 12 </span> небольших окошек для рожденных котят. Под окнами родителей
            расположены кнопки с функцией “Скрестить”.
          </p>
          <p>Ячейки для рожденных котят нельзя занимать купленными котиками.</p>
          <p>Без домиков котики не дают потомства и сбегают при завершении сезона.</p>
          <p>
            Сбегают также голодные и больные котята, независимо от того, посажены они в домик или оставлены на площадке.
          </p>
          <h3 data-subpart="CATS">Котики</h3>
          <p>
            В игре можно продавать и покупать разнополых котят четырех разных цветов. С каждым сезоном котята в
            питомнике взрослеют. Отсчет возраста котенка начинается с <span className="notranslate"> 0</span>. Котята
            считаются маленькими до возраста <span className="notranslate"> 3 </span> сезона.
          </p>
          <p>Маленькие котята:</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={smallCats} alt="Котята" className="user-guide-modal__main-image" />
          </div>
          <p>Взрослые кошки:</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={bigCats} alt="Взрослые коты" className="user-guide-modal__main-image" />
          </div>
          <p>
            Будьте внимательны: больные и голодные котики сбегут при завершении хода! Также из питомника уйдут котята,
            оставшиеся без домика.
          </p>
          <h3 data-subpart="FEEDING">Кормление</h3>
          <p>Когда котики голодные, они мяукают и рядом с ними появляется пустая миска.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={hungryCatBig} alt="Голодная кошка" className="user-guide-modal__main-image" />
            <img src={hungryCat} alt="Голодный котенок" className="user-guide-modal__main-image" />
          </div>
          <p>Питомник может покормить котиков двумя способами:</p>
          <ol>
            <li>перетащить корм на каждого котика</li>
            <li>двойным кликом по иконке корма накормить котят по очереди, слева направо.</li>
          </ol>
          <p>При кормлении котика игрок видит модальное окно для подтверждения своих действий.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={eatForCatModal}
              alt="Окно подтверждения"
              className="user-guide-modal__main-image image-boxShadow"
            />
          </div>
          <p>Покормить одного котика дважды за сезон не получится.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={cantEatModal} alt="Предупреждение" className="user-guide-modal__main-image image-boxShadow" />
          </div>
          <h3 data-subpart="CROSSBREEDING">Скрещивание</h3>
          <p>
            В окошках домика для питомцев доступно скрещивание и выведение потомства. Кнопка “Скрестить” активна, когда
            разнополые котята живут вместе в одной половине домика, они повзрослели и сыты.
          </p>
          <img
            src={crossbreedingImg}
            alt="Котиков можно скрестить"
            className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
          />
          <p>При скрещивании котиков нельзя перемещать.</p>
          <p>
            Потомство появляется на следующий сезон после скрещивания. У одной пары рождается
            <span className="notranslate"> 6 </span> котят (<span className="notranslate"> 2 </span> мальчика и
            <span className="notranslate"> 1 </span>
            девочка расцветки мамы, <span className="notranslate"> 2 </span> мальчика и
            <span className="notranslate"> 1 </span> девочка расцветки папы).
          </p>
          <p>Котята располагаются рядом с мамой в ячейках для рожденных котят.</p>
          <p>
            Нет нужды кормить котят, пока они находятся с мамой. Но если их отсадить от мамы-кошки, котят придется
            кормить каждый ход.
          </p>
          <p>
            Котики не скрещиваются, если с ними сидят котята или если не прошел <span className="notranslate"> 1 </span>{' '}
            сезон с момента последнего скрещивания.
          </p>
          <p>Как только рожденные котята взрослеют, они перемещаются из домика на площадку.</p>
          <p>
            Иногда котята рождаются больными (см.
            <span
              onClick={() => {
                setSubsection('NURSERY', 'DISEASES');
              }}
              className="user-guide-modal__main-section-link"
            >
              Болезни
            </span>
            ).
          </p>
          <h3 data-subpart="DISEASES">Болезни и их лечение</h3>
          <p>Если навести лупу на котенка, можно отыскать заболевшего питомца.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={sickImg} alt="Больной котик" className="user-guide-modal__main-image" />
          </div>
          <p>
            Средства лечения (бинт, мазь, шампунь, таблетки) доступны в момент осмотра котика. Если домик на момент
            рождения котят не застрахован, за лечение придется заплатить <span className="notranslate"> 2 </span>
            монетки (см.
            <span onClick={() => setSubsection('NURSERY', 'INSURANCE')} className="user-guide-modal__main-section-link">
              Страховка
            </span>
            ).
          </p>
          <p>Если котика не лечить, он сбежит в начале следующего сезона.</p>
          <p>Котик может болеть следующими болезнями:</p>
          <ul>
            <li>
              лишай
              <div className="user-guide-modal__main-image-wrapper">
                <img src={sickLichenImg} alt="Котик болеет лишаем" className="user-guide-modal__main-image" />
              </div>
            </li>
            <li>
              блошки
              <div className="user-guide-modal__main-image-wrapper">
                <img src={sickFleasImg} alt="У котика блошки" className="user-guide-modal__main-image" />
              </div>
            </li>
            <li>
              отравление
              <div className="user-guide-modal__main-image-wrapper">
                <img src={sickPoisoningImg} alt="Котик отравился" className="user-guide-modal__main-image" />
              </div>
            </li>
            <li>
              сломанная лапка
              <div className="user-guide-modal__main-image-wrapper">
                <img src={sickFractureImg} alt="У котика сломана лапка" className="user-guide-modal__main-image" />
              </div>
            </li>
          </ul>
          <h3 data-subpart="INSURANCE">Страховка</h3>
          <p>Каждый домик в питомнике можно застраховать на один сезон. Для этого нужно нажать на кнопку страховки.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={insuranceIcons} alt="Таймер" className="user-guide-modal__main-image" />
          </div>
          <p>Страховка покрывает затраты на лечение котят в застрахованном домике в следующем сезоне.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={insuranceImg} alt="Таймер" className="user-guide-modal__main-image" />
          </div>
          <p>После страхования над домиком появляется значок страховки.</p>
          <img
            src={catsInsuredHouseImg}
            alt="Застрахованный домик"
            className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
          />
          <h3 data-subpart="UTILITIES_NURSERY">Коммунальные услуги</h3>
          <p>Каждый сезон игрок оплачивает коммунальные услуги.</p>
          <p>
            Коммунальные услуги для питомника стоят <span className="notranslate"> 3 </span> монетки. Деньги списываются
            со счета игрока к концу сезона. Этот расход отражается в детальном балансе в начале сезона.
          </p>
        </section>
      ),
    },
    PETSHOP: {
      title: 'зоомагазин',
      icon: <UserGuidePetshopIcon />,
      subParts: {
        PERSONAL_AREA_PETSHOP: {
          title: 'личный кабинет',
        },
        DEMAND: {
          title: 'спрос',
        },
        UTILITIES_PETSHOP: {
          title: 'коммунальные услуги',
        },
      },
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="PETSHOP">Зоомагазин</h2>
          <h3 data-subpart="PERSONAL_AREA_PETSHOP">Личный кабинет</h3>
          <img
            src={petshopImg}
            alt="Зоомагазин"
            className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
          />
          <p>
            В домике зоомагазина отображаются все котики, существующие в симуляторе (см.
            <span
              onClick={() => {
                setSubsection('NURSERY', 'CATS');
              }}
              className="user-guide-modal__main-section-link"
            >
              Котики
            </span>
            ), с указанием количества котят у данного игрока. В домике игрока-зоомагазина есть лупа для осмотра котят.
          </p>
          <h3 data-subpart="DEMAND">Спрос</h3>
          <p>
            В городе существует спрос на покупку каждого вида котиков. Спрос города видят только зоомагазины, поскольку
            от спроса зависят цены, по которым магазины продают котят городу.
          </p>
          <p>
            В течение сезона спрос среди горожан на котиков меняется. К примеру, как только обеспеченные горожане
            раскупили дорогих котят, город начинает покупать таких котят по более низкой цене (по цене предыдущего по
            стоимости вида) — покупают жители среднего достатка. Когда и среди среднего класса спрос на данный вид котят
            будет удовлетворен, цена снова упадет, и изначально “элитные” котята станут доступны малообеспеченным
            жителям.
          </p>
          <p>При этом цена города на продажу котиков зоомагазину не меняется.</p>
          <h3 data-subpart="UTILITIES_PETSHOP">Коммунальные услуги</h3>
          <p>Каждый сезон игрок оплачивает коммунальные услуги.</p>
          <p>
            Коммунальные услуги для зоомагазина стоят <span className="notranslate"> 1 </span> монету. Деньги
            списываются со счета игрока к концу сезона. Этот расход отражается в детальном балансе в начале сезона.
          </p>
        </section>
      ),
    },
    TRADE: {
      title: 'торговля',
      icon: <UserGuideTradeIcon />,
      subParts: {
        PRESETS: {
          title: 'предустановленные цены',
        },
        APPLICATIONS: {
          title: 'заявки',
        },
      },
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="TRADE">Торговля</h2>
          <p>
            Торговля осуществляется только с оппонентами. Торговля с конкурентами невозможна. Чтобы предложить сделку
            питомнику/зоомагазину, нужно зайти в домик этого игрока. Продаются только котята. (см.
            <span
              onClick={() => {
                setSubsection('NURSERY', 'CATS');
              }}
              className="user-guide-modal__main-section-link"
            >
              Котики
            </span>
            ). Взрослых котиков продавать нельзя.
          </p>
          <p>Если Вы играете за зоомагазин, то в окне торговли вам доступна торговля с городом и с питомником.</p>
          <img
            src={tradePetshopImg}
            alt="Экран торговли для зоомагазина"
            className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
          />
          <h3>Окно торговли для игрока-зоомагазина.</h3>
          <p>
            Справа в ленте отображаются заявки (см.
            <span
              onClick={() => setSubsection('TRADE', 'APPLICATIONS')}
              className="user-guide-modal__main-section-link"
            >
              Заявки
            </span>
            ).
          </p>
          <p>
            В верхнем ряду размещены котики, доступные для сделок с городом. Здесь же указаны цены на продажу котят
            городу и на покупку котят у города. Чтобы продать котенка в город, игроку надо перетащить котенка из своей
            витрины в витрину города.
          </p>
          <p>Чтобы купить котенка у города, игрок перетаскивает котенка из витрины города в свою зону.</p>
          <p>
            Под котиками города расположены котики игрока, указано количество котиков каждого вида и цены на них. Нажав
            на своего котика, можно изменить цены на покупку и продажу котенка.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={tradeModalImg}
              alt="Изменение цены на котика"
              className="user-guide-modal__main-image user-guide-modal__main-image--normal-size"
            />
          </div>
          <p>
            Под витриной игрока-зоомагазина находится зона совершения сделок. Заявки зоомагазина на торговлю с
            питомниками формируются в этой зоне. Также здесь отображаются детали входящих сделок и встречных предложений
            при клике на соответствующий конверт в ленте заявок.
          </p>
          <h3>Окно торговли для игрока-питомника.</h3>
          <p>
            При игре в роли питомника в окне торговли доступна торговля только с зоомагазином, с городом питомники не
            торгуют.
          </p>
          <img
            src={tradeNurseryImg}
            alt="Экран торговли для питомника"
            className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
          />
          <h3 data-subpart="PRESETS">Предустановленные цены</h3>
          <p>Предустановленные цены – это изначально выставленные цены на каждый из видов котиков у всех игроков.</p>
          <p>
            Предустановленные цены могут быть изменены игроками в любое время в течение игры. Для этого нужно зайти в
            домик оппонента или конкурента и нажать на котика в своей витрине, цену которого нужно изменить. Изменив
            цену продажи или покупки, сохранить изменение.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={tradeModalImg}
              alt="Изменение цены на котика"
              className="user-guide-modal__main-image user-guide-modal__main-image--normal-size"
            />
          </div>
          <h3 data-subpart="APPLICATIONS">Заявки</h3>
          <p>Все заявки отображаются на всех экранах справа в выделенном блоке для заявок.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={notificationsImg} alt="Заявки" className="user-guide-modal__main-image" />
          </div>
          <p>Заявки отмечены иконками отправителей и значком вида заявки.</p>
          <p>
            Заявки бывают <span className="notranslate"> 7 </span> видов:
          </p>
          <ul className="user-guide-modal__main-image-list">
            <li>
              <img src={listOutgoingImg} alt="Исходящая заявка" className="user-guide-modal__main-image-list-icon" />
              <div>
                <span className="user-guide-modal__main-image-list-title">исходящая заявка</span>
                <p>Все заявки на покупку или продажу, отправленные Вами.</p>
              </div>
            </li>
            <li>
              <img src={listIncomingImg} alt="Входящая заявка" className="user-guide-modal__main-image-list-icon" />
              <div>
                <span className="user-guide-modal__main-image-list-title">входящая заявка</span>
                <p>Все входящие заявки от других игроков.</p>
              </div>
            </li>
            <li>
              <img src={listRejectedImg} alt="Отклоненная заявка" className="user-guide-modal__main-image-list-icon" />
              <div>
                <span className="user-guide-modal__main-image-list-title">отклоненная заявка (другим игроком)</span>
                <p>Если оппонент отклоняет Вашу заявку, то она будет помечена данным конвертом.</p>
              </div>
            </li>
            <li>
              <img src={listAcceptedImg} alt="Принятая заявка" className="user-guide-modal__main-image-list-icon" />
              <div>
                <span className="user-guide-modal__main-image-list-title">принятая заявка (другим игроком)</span>
                <p>
                  Если оппонент принимает Вашу входящую или встречную заявку, то заявка будет помечена данным конвертом.
                </p>
              </div>
            </li>
            <li>
              <img
                src={listCounterofferImg}
                alt="Встречная заявка"
                className="user-guide-modal__main-image-list-icon"
              />
              <div>
                <span className="user-guide-modal__main-image-list-title">встречная заявка</span>
                <p>
                  Если оппонент меняет цены, количество или вид котиков в сделке от Вас и возвращает Вам сделку с новыми
                  данными, то заявка помечается как встречная. Вам придется ее изучить, после чего отклонить либо
                  одобрить.
                </p>
              </div>
            </li>
            <li>
              <img
                src={listQuestionImg}
                alt="Заявка, требующая уточнения"
                className="user-guide-modal__main-image-list-icon"
              />
              <div>
                <span className="user-guide-modal__main-image-list-title">заявка, требующая уточнения</span>
                <p>
                  Данный тип заявки требует внимания. Так помечаются заявки с котятами, которые были проданы кому-то
                  другому, а не данному оппоненту. Такие заявки нужно пересмотреть: отменить или изменить.
                </p>
              </div>
            </li>
            <li>
              <img
                src={listWaitImg}
                alt="Заявка на уточнении (у другого игрока)"
                className="user-guide-modal__main-image-list-icon"
              />
              <div>
                <span className="user-guide-modal__main-image-list-title">заявка на уточнении (у другого игрока)</span>
                <p>
                  Если у продавца изменилась ситуация с котятами и он переуточняет заявку, то покупатель в этот момент
                  находится в ожидании.
                </p>
              </div>
            </li>
          </ul>
          <p>Данные по всем заявкам отображаются в зоне сделки, при нажатии на соответствующие заявки</p>
        </section>
      ),
    },

    CORPORATE: {
      isVisible: () => {
        return appStore.isCorpMode;
      },
      title: 'корпоративная игра',
      icon: <UserGuideCorpIcon />,
      subParts: {
        CORPORATION: {
          title: 'Корпорация',
        },
        CEO: {
          title: 'СЕО',
        },
        CEO_POWERS: {
          title: 'Полномочия СЕО',
        },
        CEO_MAINSCREEN: {
          title: 'Главный экран СЕО',
        },
        AGREEMENT: {
          title: 'Согласование действий корпорации',
        },
        LIMITS: {
          title: 'Лимиты на операции',
        },
        OFFICE: {
          title: 'Офис СЕО',
        },
        BUDGET: {
          title: 'Бюджет',
        },
        LONG_TERM_DEALS_CORP: {
          title: 'Долгосрочные сделки',
        },
        TRANSPORTATION: {
          title: 'Транспортировка котиков между городами',
        },
        EXPORT_IMPORT: {
          title: 'Экспорт/импорт',
        },
        IMPORT: {
          title: 'Импорт',
        },
        EXPORT: {
          title: 'Экспорт',
        },
        MARKET: {
          title: 'Рынок',
        },
        BALANCE: {
          title: 'Мой баланс',
        },
      },
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="CORPORATE">КОРПОРАТИВНАЯ ИГРА</h2>
          <h3 data-subpart="CORPORATION">Корпорация</h3>
          <p>
            В корпоративной версии симуляции появился СЕО (директор), который управляет пятью капитанами в разных
            городах. Если в режимах Сингл, Соло, Командная игра проходила в рамках одного города, то в корпоративной
            версии симуляции есть 5 независимых друг от друга городов, где в каждом городе свой спрос и цены на котиков.
          </p>
          <p>
            В игре 24 СЕО, у каждого 5 команд, по одной в каждом городе. Все пять команд относятся к одной роли или
            зоомагазину, или питомнику.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={mapOfCities}
              alt="Схема городов"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="CEO">CEO</h3>
          <p>
            СЕО (директор) определяет стратегию развития компании. В корпоративной версии симуляции появилось много
            новых инструментов, которые помогут достичь максимальной прибыли.
          </p>
          <h3 data-subpart="CEO_POWERS">Полномочия CEO</h3>
          <p>
            В полномочия СЕО входят делегирование действий, утверждение и выставление лимитов на те действия, которые он
            не согласовывает лично, распределение бюджета между счётом СЕО и городами, транспортировка котиков между
            городами, заключение долгосрочных сделок, взятие кредита, продажа котиков на экспортном рынке, покупка
            котиков на импортном рынке, досрочное завершение хода.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={ceoCart} alt="Полномочия CEO" className="user-guide-modal__main-image" />
          </div>
          <h3 data-subpart="CEO_MAINSCREEN">Главный экран CEO</h3>
          <p>Рассмотрим все игровые элементы на главном экране СЕО</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={ceoMainPage}
              alt="Главный экран CEO"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            В левом верхнем углу мы видим иконку игрока, название корпорации и баланс счёта СЕО, при нажатии можем
            ознакомится с отчётами по всем финансовым операциям корпорации.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={ceoProfile}
              alt="Баланс счета CEO"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>Кнопку для настройки делегирования и выставления лимитов.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={ceoDelegationIcon}
              alt="Кнопка делегирования"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>5 городов, куда мы можем перейти, чтобы зайти к одной из своих команд, конкурентам или контрагентам.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={numberOfCities}
              alt="Города"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Аэропорт, где мы можем заключать сделки на импорт, экспорт, а так же отслеживать уже сформированные сделки.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={ceoAirport} alt="Аэропорт" className="user-guide-modal__main-image" />
          </div>
          <p>
            Здание “Твой офис”, где мы можем заключать долгосрочные сделки, распределять бюджет между городами и
            перевозить котиков между городами в рамках своей корпорации.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={ceoOffice} alt="Офис" className="user-guide-modal__main-image" />
          </div>
          <h3 data-subpart="AGREEMENT">Согласование действий корпорации</h3>
          <p>
            На прямую СЕО не делегирует свои обязанности, но может контролировать все операции, которые происходят в
            городах.
          </p>
          <p>Для контроля ему доступно переключение тумблера в трёх режимах:</p>
          <ol>
            <li>СЕО - в данном режиме СЕО сам согласовывает все операции по выбранным пунктам.</li>
            <li>Капитан - СЕО сам заявки не согласовывает, но обязует капитана проверять все операции.</li>
            <li>
              Команда - всё происходит аналогично Командной версии: капитан сам выбирает что согласовывать, а что
              делегировать своей команде.
            </li>
          </ol>
          <p>
            Чекбокс перед тумблером позволяет добавить команде новые полномочия. Например взятие кредита командой
            откроет маркетологу возможность работать с банком.
          </p>
          <p>
            Делегирование и лимиты можно настраивать на все города сразу, или переходить по городам и настраивать всё
            отдельно.
          </p>
          <p>При изменении статуса согласования капитану приходит модальное оповещение.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={ceoDelegation}
              alt="Делегирование"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="LIMITS">Лимиты на операции</h3>
          <p>
            Если СЕО сам не согласовывает пункты, подразумевающие расходы, он может ограничивать их каждый сезон. Если
            он поставит галочку в чекбоксе в конце окна согласования, то сможет выставить лимиты на текущий сезон. Если
            у команды появится необходимость выйти за рамки лимита, СЕО придёт согласование. Помимо лимитов на расходы
            СЕО может задать отклонение от витринных цен.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={ceoLimits}
              alt="Лимиты"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="OFFICE">Офис СЕО</h3>
          <p>При нажатии на “Твой офис” откроется окно с тремя вкладками: “Бюджет”, “Сделки”, “Транспортировка”</p>
          <p>
            Коммунальные услуги на содержание офиса СЕО для питомника составляют 3 монетки, а для СЕО зоомагазина 1
            монетка.
          </p>
          <h3 data-subpart="BUDGET">Бюджет</h3>
          <p>
            Доступный бюджет - это бюджет СЕО. Он нужен для оплаты импорта, коммунальных услуг офиса СЕО, выплаты по
            кредиту, если СЕО брал кредит на корпорацию, покупки машин и выплатам по долгосрочным сделкам.{' '}
          </p>
          <p>
            Для выделения денег из бюджета СЕО необходимо в окне желаемого города ввести сумму в поле денег и нажать на
            зеленую кнопку “Выделить”. Выделение бюджета требует утверждения. До утверждения действия сумма не выделится
            и ее можно изменить.
          </p>
          <p>
            Для того, чтобы забрать у одного из городов деньги необходимо произвести ту же последовательность действий,
            но вместо зеленой кнопки нажать красную кнопку “Изъять”.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={budgetAllocation}
              alt="Бюджет"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="LONG_TERM_DEALS_CORP">Долгосрочные сделки</h3>
          <p>Долгосрочные сделки заключаются на уровне СЕО - СЕО. </p>
          <p>
            Чтобы перейти к самой сделке нужно выбрать контрагента, город, в который придут котики при покупке, или из
            какого города вы собираетесь их отправить, указать тип сделки (покупка, продажа или покупка и продажа в
            одной сделке) и нажать на кнопку создать.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={officeLTD}
              alt="Долгосрочные сделки"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="TRANSPORTATION">Транспортировка котиков между городами</h3>
          <p>
            В версиях Сингл, Соло, Командная симуляция проходила в рамках одного города. Теперь у каждой корпорации есть
            по одному филиалу в каждом из 5 городов.{' '}
          </p>
          <p>
            В корпоративной версии симуляции есть возможность перевозить котиков между городами своей корпорации.
            Транспортировкой котиков занимается Сео корпорации как в питомнике, так и в зоомагазине.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={officeTransportation}
              alt="Транспортировка"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Для перевозки котиков требуются машины. Они бывают двух видов: на 6 котиков и на 12 котиков. Автомобиль
            можно купить на всю игру или арендовать на 1 сезон.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={transportationCart}
              alt="Автомобили"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            За один сезон каждый автомобиль может перевезти только одну партию котиков, а приедут в место назначения
            котики только на следующий сезон после отправки. Котики должны быть обязательно сытыми и здоровыми, иначе
            они сбегут во время транспортировки.
          </p>
          <h3 data-subpart="EXPORT_IMPORT">Экспорт/импорт</h3>
          <p>
            В версиях Сингл, Соло, Командная в игре можно было торговать с ботом зоомагазином самыми дешевыми котиками,
            в Корп версии появились импортный и экспортный рынки. Торговать котиками со внешним миром может
            исключительно СЕО.
          </p>
          <p>
            Цены на экспорт и импорт устанавливаются в самом начале сессии и не меняются по ходу игры. Важными
            особенностями торговли на этих двух рынка являются срок поставки котиков и отложенная оплата.{' '}
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={importExport}
              alt="Экспорт/импорт"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="IMPORT">Импорт</h3>
          <p>
            Задержка при покупке через импорт составляет 2 сезона. Котики поступают в указанный заранее город ,
            возрастом в 1 сезон. 30% от суммы сделки нужно будет заплатить при оформлении самой сделки, оставшиеся 70%
            нужно выплатить в сезон поставки.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={importCart}
              alt="Импорт"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Пример: мы покупаем двух котиков на 1 сезон за 40 монет в сумме. Сразу выберем город поставки и заплатим 12
            монет при оформлении сделки. Котики придут на 3 сезон, тогда же мы и выплатим оставшиеся 28 монет. На 3
            сезоне у нас будет пара котят возрастом в 1 сезон, которая даст потомство на 6 сезон
          </p>
          <h3 data-subpart="EXPORT">Экспорт</h3>
          <p>
            Задержка при продаже через экспорт составляет 3 сезона. 30% от суммы сделки игрок получает при оформлении
            сделки , оставшиеся 70% поступят на счёт через 3 сезона.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={exportCart}
              alt="Экспорт"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Для продажи через экспортный рынок котики должны быть сытыми и здоровыми, иначе сделку оформить не
            получится.
          </p>
          <p>
            Пример: мы продаём четырёх котиков на 6 сезон за 90 монет в сумме. Сначала выбираем город отправки,
            переносим котиков в зону сделки и отправляем сделку. 27 монет мы получаем сразу, оставшиеся 63 монеты
            поступят на счёт в начале 9 сезона Важно помнить, что из-за задержки в получении оплаты продавать через
            экспортный рынок можно за 3 сезона до конца игры. В сессии на 14 сезонов последним сезоном для продажи через
            экспорт будет 11 сезон.
          </p>
          <h3 data-subpart="MARKET">Рынок</h3>
          <p>
            В игре 5 независимых друг от друга городов, где в каждом свой спрос и цены на котиков. Котики в 5 городах
            остаются в одной ценовой категории, если рыжий котик самый дорогой в городе 1, то во всех остальных городах
            рыжий котик так же самый дорогой.{' '}
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={cityMarket}
              alt="Рынок"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Цена на котиков и спрос меняются по пирамиде спроса. Если мы много покупаем из города, то цена растёт, спрос
            уменьшается, а если мы будет много продавать в город, то цена будет падать, а спрос расти.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={demandPyramid} alt="Спрос" className="user-guide-modal__main-image" />
          </div>
          <h3 data-subpart="BALANCE">Мой Баланс</h3>
          <p>
            В окне “Мой баланс” можно ознакомится со всеми финансовыми операциями корпорации. Окно делится по вкладкам
            на сезоны, и на города.
          </p>
          <p>
            Во вкладке “Корпорация” предоставлена отчётность по всей корпорации. Здесь собрана информация обо всех
            расходах и доходах СЕО, а так же суммы расходов и доходов со всех 5 городов. Деньги на начало и деньги на
            конец сезона считаются как сумма счёта СЕО и счетов команд со всех 5 городов.
          </p>
          <p>
            Во вкладках городов подробно собрана информация о расходах и доходах конкретного города, без общих статей
            расходов\доходов и информации по операциям СЕО.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={ceoBalance} alt="Баланс" className="user-guide-modal__main-image" />
          </div>
        </section>
      ),
    },
    BOTS: {
      title: 'боты',
      icon: <UserGuideBotsIcon />,
      subParts: {
        RELATIONS: {
          title: 'отношения',
        },
      },
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="BOTS">Боты</h2>
          <p>
            В любом режиме игры есть один зоомагазин-бот, за который играет искусственный интеллект, а не живой игрок.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={botImg} alt="Игрок-бот" className="user-guide-modal__main-image" />
          </div>
          <p>В сингл-режиме все питомники и зоомагазины, кроме игрока, — боты.</p>
          <h3 data-subpart="RELATIONS">Отношения</h3>
          <p>
            Если игрок - питомник, то все зоомагазины-боты помечаются смайликами — показателями уровня отношений между
            игроком и зоомагазином.
          </p>
          <p>
            Если игрок - зоомагазин, то смайликами помечаются все питомники-боты (зависит от режима игры, см.
            <span onClick={() => setPart('GAME_MODES')} className="user-guide-modal__main-section-link">
              Режимы игры
            </span>
            ).
          </p>
          <p>
            Смайлики отражают отношение бота к игроку. Изначально каждый бот отлично относится к игроку, на
            <span className="notranslate"> +5 </span>, — зеленый довольный смайлик.
          </p>
          <p>Смайлик отношений отражен на главном экране рядом с домиком бота, а также в окне сделки с ботом.</p>
          <p>Виды отношений к игроку:</p>
          <ul className="user-guide-modal__main-image-list">
            <li>
              <img src={aiConfusedImg} alt="Недоверие" className="user-guide-modal__main-image-list-icon" />
              <p>
                Красный – бот недоволен вашей политикой ведения бизнеса. Красным смайлик становится, когда уровень
                отношений с ботом опускается до <span className="notranslate"> 0</span>. При этом бот перестаёт
                контактировать и торговать с игроком.
              </p>
            </li>
            <li>
              <img src={aiSadImg} alt="Плохие отношения" className="user-guide-modal__main-image-list-icon" />
              <p>
                Светло-красный – бот недоволен вашей политикой ведения бизнеса, договориться с ним будет довольно
                сложно.
              </p>
            </li>
            <li>
              <img src={aiPuzzledImg} alt="Озадаченность" className="user-guide-modal__main-image-list-icon" />
              <p>Рыжий – бот озадачен вашей политикой ведения бизнеса, но договориться с ним пока можно.</p>
            </li>
            <li>
              <img src={aiWinkImg} alt="Нейтралитет" className="user-guide-modal__main-image-list-icon" />
              <p>Желтый – бот нейтрален к вашей политике переговоров, немного сложно будет договориться с ним.</p>
            </li>
            <li>
              <img src={aiSmileImg} alt="Доверие" className="user-guide-modal__main-image-list-icon" />
              <p>Светло-зеленый – боту нравится ваша политика ведения бизнеса, вам проще будет договориться с ним.</p>
            </li>
            <li>
              <img src={aiTrustImg} alt="Дружба" className="user-guide-modal__main-image-list-icon" />
              <p>
                Зеленый – отношения с ботом налажены, вам проще будет договориться с ним. Устанавливается изначально.
              </p>
            </li>
          </ul>
        </section>
      ),
    },
    CREDITS: {
      isVisible: () => {
        return appStore.credits.credit_mechanics;
      },
      title: 'кредиты',
      icon: <UserGuideCreditIcon />,
      subParts: {
        CREDIT_TYPES: {
          title: 'виды кредитов',
        },
        CREDIT_CALCULATE: {
          title: 'расчет кредита',
        },
        GET_CREDIT: {
          title: 'получение кредита',
        },
        ACTIVE: {
          title: 'взятые кредиты',
        },
        CREDIT_REPAYMENT: {
          title: 'досрочное погашение кредита',
        },
      },
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="CREDITS">кредиты</h2>
          <p>
            Каждый игрок может взять кредит у бота. Всего кредитов в игре <span className="notranslate"> 3</span>. Они
            отличаются способами выплат и процентной ставкой, она является фиксированной. Игрока не ограничивают в
            количестве самих кредитов, если общая сумма кредитования не превышает сумму лимита. Оплата происходит в
            конце сезона автоматически, но игрок может совершить досрочное погашение, что уменьшит переплату по
            процентам.
          </p>
          <h3 data-subpart="CREDIT_TYPES">виды кредитов</h3>
          <p>
            <i>Потребительский кредит</i> - кредит с минимальным риском для банка, долг выплачивается равными долями
            вместе с процентами на остаток. Процент по данному кредиту и средства на реализацию <i>котиков</i>
            минимальные.
          </p>
          <img
            src={consumerCreditCalculation}
            alt="Расчет потребительского кредита"
            className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
          />
          <p>
            <i>Инвестиционный кредит</i> - стандартный кредит для бизнеса, проценты по кредиту выплачиваются каждый
            сезон, а в конце срока кредитования выплачивается тело кредита. Средний процент по кредиту.
          </p>
          <p>
            <i>Кредит со специальными условиями</i> - самый рискованный для банка кредит, тело долга и проценты
            выплачиваются вместе в конце срока кредитования. Самый высокий процент по кредиту.
          </p>
          <h3 data-subpart="CREDIT_CALCULATE">расчет</h3>
          <p>
            При вводе количества монет и сезонов вы увидите переплату по конкретному кредиту, а при нажатии на кнопку
            "Рассчитать" вы можете произвести расчет каждого из кредитов, увидеть посезонную оплату.
          </p>
          <img
            src={creditCalculation}
            alt="Расчет кредита"
            className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
          />
          <h3 data-subpart="GET_CREDIT">получение</h3>
          <p>
            При нажатии на кнопку "Получить" банку приходит заявка, от его решения будет зависеть ваше получение
            кредита. Вы можете увидеть решение банка в соответствующем уведомлении.
          </p>
          <h3 data-subpart="ACTIVE">взятые кредиты</h3>
          <p>
            Во вкладке "Данные по взятым кредитам" вы можете увидеть все активные кредиты и информацию о них: тело,
            проценты, посезонную оплату.
          </p>
          <img
            src={takenCreditPage}
            alt="Расчет кредита"
            className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
          />
          <h3 data-subpart="CREDIT_REPAYMENT">досрочное погашение</h3>
          <p>
            В игре реализована возможность досрочного погашения, которое вы можете совершить при нажатии на синюю кнопку
            досрочного погашения. В этом случае вы выплатите только половину от неначисленных процентов на остаток.
            Кредит нельзя погасить досрочно в последний ход действия кредита.
          </p>
        </section>
      ),
    },
    LONG_TERM_DEALS: {
      isVisible: () => {
        return appStore.with_ltd;
      },
      title: 'долгосрочные сделки',
      icon: <UserGuideLTD />,
      subParts: {
        CREATING_CLOSURE: {
          title: 'создание сделки',
        },
        RESPONSIBILITY: {
          title: 'ответственность',
        },
        BANK_GUARANTEE: {
          title: 'банковская гарантия',
        },
        TERMINATION: {
          title: 'расторжение',
        },
        EXECUTION_OF_OBLIGATIONS: {
          title: 'исполнение обязательств',
        },
        PAYMENT: {
          title: 'оплата',
        },
        WORK_WITH_WAREHOUSE: {
          title: 'работа со складом',
        },
        CLAIMS_AND_FINES: {
          title: 'претензии и штрафы',
        },
        TERMINATION_TRANSACTION: {
          title: 'расторжение сделки',
        },
      },
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="LONG_TERM_DEALS">Долгосрочные сделки</h2>
          <p>
            Долгосрочные сделки могут заключаться на несколько сезонов. Возможность выставлять штрафы за
            недобросовестное исполнение обязательств делает сделку безопасной, а банковская гарантия помогает вернуть
            свои вложения в критических ситуациях.
          </p>
          <h3 data-subpart="CREATING_CLOSURE">Создание сделки</h3>
          <p>
            В игре долгосрочную сделку можно заключить, если зайти к питомнику/зоомагазину и нажать на кнопку
            долгосрочной сделки.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={creatingClosure}
              alt="создание сделки"
              className="user-guide-modal__main-image user-guide-modal__main-image--normal-size"
            />
          </div>
          <p>Долгосрочная сделка заключается на покупку или продажу отдельно, или покупку и продажу в одной сделке.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={longTermChoice}
              alt="выбор долгосрочной сделки"
              className="user-guide-modal__main-image user-guide-modal__main-image--normal-size"
            />
          </div>
          <p>
            Долгосрочная сделка состоит из <span className="notranslate"> 6 </span> блоков: предмет сделки, порядок
            поставки, порядок оплаты, обеспечение, ответственность, расторжение.
          </p>
          <p>Поля обозначенные зелёным/коричневым цветом обязательны к заполнению.</p>
          <p>Поля обозначенные фиолетовым цветом - необязательны.</p>
          <p>
            Поставка котиков и их оплата проходят по стандартному сценарию: оплата аванса, поставка на склад, оплата
            перед отгрузкой, отгрузка со склада, оплата после отгрузки. Все эти пункты нужны, чтобы максимально
            обезопасить сделку, и каждая сторона могла планировать когда получит котиков или деньги. Оплаты “аванс” и
            “после отгрузки” могут не совпадать с сезоном поставки.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={offerZone}
              alt="зона предложений"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>Выбираем нужных котиков, выставляем количество и цену за каждого.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={catsSail}
              alt="коты"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            После того как выбрали котиков выставляем срок поставки - в какой сезон мы хотим получить или отправить
            котиков. Когда мы выбираем нужный нам сезон поставки, все таймеры подставляются автоматически, но при
            необходимости мы можем их редактировать.
          </p>
          <p>
            В пунктах порядок поставки/оплаты есть обязательные значения сезонов проведения операций и временные рамки.
            Время в них отсчитывается от конца сезона (за <span className="notranslate"> 10 </span> минут до окончания
            сезона). Временные ограничения важны для своевременного исполнения обязательств. От временных ограничений
            зависит возможность выставлять претензии и штрафы. Если один этап сделки просрочен, остальные сдвигаются на
            время задержки.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={deliveryTime} alt="срок поставки" className="user-guide-modal__main-image" />
          </div>
          <p>
            В игре существует склад. Продавец временно помещает туда котиков и сообщает покупателю, что их можно
            проверить. До тех пор пока покупатель не проверит котиков, продавец не сможет ими распоряжаться. После того
            как покупатель проводит платёж «перед отгрузкой» он может получить котиков с согласования продавца.
          </p>
          <p>
            Оплата разделена на <span className="notranslate"> 3 </span> части: аванс, перед отгрузкой, после отгрузки.
            Автоматически деньги распределяются в соотношении: “аванс” - <span className="notranslate"> 30</span>%,
            “перед отгрузкой” - <span className="notranslate"> 60</span>%, “после отгрузки” -
            <span className="notranslate"> 10</span>%, но их можно изменить, главное чтобы сумма платежей равнялась
            сумме сделки. В любом из этих полей можно поставить сумму равную нулю.
          </p>
          <p>
            “Аванс” должен быть выплачен до того как произойдет отправка котиков на склад. Платеж “перед отгрузкой”
            обязательно оплачивается в сезон поставки перед подтверждением котиков на складе. Платеж “после отгрузки”
            оплачивается после отгрузки котиков.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={dropOrder}
              alt="порядок упалты"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="RESPONSIBILITY">Ответственность</h3>
          <p>
            При несоблюдении договоренностей второй стороной игрок может выслать претензию контрагенту и ждать
            устранения проблемы, или назначить штраф, после чего сделка расторгается автоматически. Назначить штраф
            можно минуя претензию, если с контрагентом не получилось договориться об урегулировании конфликта.
          </p>
          <p>
            Поводы для претензии и штрафа включают в себя: просрочку оплаты, просрочку поставки и недопоставку, и
            наличие больных котиков в сделке. Размер штрафа, как и форма, в которой он будет выплачиваться, задается при
            формировании долгосрочной сделки.
          </p>
          <p>
            Штраф за просрочку оплаты может быть фиксированного размера и задаваться сразу в монетах, может выражаться в
            процентах от общей суммы сделки, или в процентах от суммы просроченной операции. Максимальный размер штрафа
            равен {appStore.longTermsDeals.max_possible_fine}% от суммы сделки.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={latePayment} alt="Окно результатов игры" className="user-guide-modal__main-image" />
          </div>
          <p>
            Штраф за просрочку поставки и недопоставку выплачивает в двух случаях: сторона поставляющая котиков не
            успела отправить их на склад или отгрузить со склада контрагенту в заданное время или на склад поступили не
            все котики.
          </p>
          <p>
            Штраф за просрочку поставки и недопоставку может быть фиксированного размера и задаваться сразу в монетах,
            может выражаться в процентах от общей суммы сделки, или в процентах от суммы недопоставленных котиков.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={delayPenalty} alt="штраф за просрочку" className="user-guide-modal__main-image" />
          </div>
          <p>
            Штраф за качество назначается при наличии в сделке больных котиков. Он может быть фиксированного размера и
            задаваться сразу в монетах, может выражаться в процентах от общей суммы сделки, или в процентах от общей
            суммы всех больных котиков.
          </p>
          <p>
            Котиков можно заменить на здоровых в случае если сторона, которая принимает котиков, высылает претензию
            вместо штрафа.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={qualityPenalty} alt="штраф за качество" className="user-guide-modal__main-image" />
          </div>
          <h3 data-subpart="BANK_GUARANTEE">Банковская гарантия</h3>
          <p>
            У вас есть возможность воспользоваться банковской гарантией, чтобы контрагент был уверен, что она покроет
            ваши обязательства в случае вашего банкротства. Также вы можете указать ее размер контрагенту, для того,
            чтобы у вас была гарантия.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={bankGuarantee}
              alt="БГ"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Банковская гарантия берется на время всей сделки, а оплатить ее можно в сезон заключения сделки (
            <span className="notranslate">5</span>% от суммы) или растянуть на несколько сезонов, тогда оплата будет
            считаться по формуле:
            <br />
            <i>
              Сумма БГ* (<span className="notranslate">0.05 </span> + ((количество сезонов -
              <span className="notranslate"> 1 </span>) / <span className="notranslate"> 100</span>)) / количество
              сезонов)
            </i>
            , т.е добавляется по <span className="notranslate"> 1</span>% за каждый сезон платежей.
          </p>
          <div className="user-guide-modal__main-image--full-screen">
            <img
              src={bankGuaranteeTerms}
              alt="расторжение БГ"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="TERMINATION">Расторжение</h3>
          <p>
            Расторжение долгосрочной сделки возможно в четырех случаях: одностороннее расторжение при оплате
            зафиксированной в сделке суммы, расторжение по соглашению сторон, расторжение вследствие назначения штрафа
            или авторасторжение в конце сезона.
          </p>
          <p>
            Если в условии расторжения при формировании сделки вы поставите “без условий расторжения”, то одностороннего
            расторжения не будет. Также можно выбрать фиксированную выплату или процент от общей суммы сделки.
          </p>
          <div className="user-guide-modal__main-image--full-screen">
            <img
              src={termination}
              alt="расторжение"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Если при нарушении обязательств предусмотрена ответственность и за невыполнение был назначен штраф, то
            автоматически сделка будет расторгнута как только кто-то выставил штраф. Аналогично сделка расторгается,
            если на момент конца сезона в сделке осталось не выполненное действие. Тот на ком остановилась сделка должен
            будет выплатить штраф.
          </p>
          <p>
            Если ответственность не предусмотрена, но банковская гарантия есть, то штраф нужно будет выплатить по общим
            правилам действующего законодательства в зависимости от нарушения: просрочка оплаты -
            <span className="notranslate"> 50</span>% от неоплаченной суммы, просрочка поставки на склад
            <span className="notranslate"> 50</span>% от цены недопоставленных котов, просрочка отгрузки со склада -
            <span className="notranslate"> 50</span>% от цены имеющихся на складе котиков. За больных котиков выплата по
            банковской гарантии отсутствует.
          </p>
          <h3 data-subpart="EXECUTION_OF_OBLIGATIONS">Исполнение обязательств</h3>
          <p>
            После того как вторая сторона согласовывает сделку у обоих игроков появляются печати “подписано” и сделка
            вступает в силу.
          </p>
          <div className="user-guide-modal__main-image--full-screen">
            <img
              src={headerDesktop}
              alt="долгосрочная сделка хейдер"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Как только две стороны согласовали и сформировали долгосрочную сделку, она появляется в папке “мои задачи”.
            Нажав на название сделки раскроется подробная информация об операциях по ней. В этом окне мы можем следить
            за нашими обязанностями по сделке, временными лимитами и оплачивать/отгружать котиков. Каждое действие,
            которое нам необходимо сделать, выделяется как новая активная задача.
          </p>
          <div className="user-guide-modal__main-image--full-screen">
            <img src={allTasks} alt="все задачи LTD" className="user-guide-modal__main-image--full-screen" />
          </div>
          <p>Зеленые цифры обозначают, что мы покупаем котиков. Черные цифры обозначают, что мы продаем котиков.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={catsTwo} alt="продажа котов" className="user-guide-modal__main-image-wrapper" />
          </div>
          <p>При нажатии на данную кнопку у нас откроются условия долгосрочной сделки.</p>
          <div className="user-guide-modal__main-image--full-screen">
            <img
              src={myTasks}
              alt="мои задачи"
              className="user-guide-modal__main-image-wrapper user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="PAYMENT">Оплата</h3>
          <p>Оплата производится вручную через раскрытую информацию о долгосрочной сделке в окне “мои задачи”.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={advancePayment}
              alt="оплата аванса"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            При успешной оплате, напротив пункта оплаты ставится печать о выполненном действии. Оплатить можно, если
            имеется достаточное количество денежных средств.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={advancePaymentTrue}
              alt="оплата аванса готовая"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="WORK_WITH_WAREHOUSE">Работа со складом</h3>
          <p>
            Отгрузка котиков на склад осуществляется через раскрытую информацию о долгосрочной сделке в окне “мои
            задачи”.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={shipmentOfCats}
              alt="отгрузка котов"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            На склад можно загрузить только нужных по цвету котиков, но их количество и здоровье остаются на усмотрение
            отправляющей стороне. Загрузить котиков больше чем нужно на склад нельзя.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={stock}
              alt="склад"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Осмотр котиков и оплата “перед отгрузкой” осуществляется в окне “мои задачи”. Вы можете оплатить сразу,
            тогда склад подтвердится автоматически, или перед тем как совершить платеж “перед отгрузкой” вы можете
            проверить котиков на качество и количество на складе.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={inspection}
              alt="осмотр"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            На складе количество котиков выделяется сразу в нескольких местах, но для проверки здоровья следует
            осмотреть их лупой. Если все котики здоровы и в нужном количестве тогда вы подтверждаете их и производите
            оплату “перед отгрузкой”.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={deals}
              alt="сделка"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Но если вы обнаружили больного котика или контрагент отправил вам не полный склад, вы можете подтвердить
            склад или отправить ему претензию или штраф. Не забудьте отметить галочкой каждого больного котика.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={sickCat}
              alt="больной кот"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="CLAIMS_AND_FINES">Претензии и штрафы</h3>
          <p>
            Если вам отправили не полную партию котиков или отправили больных, но таймер на его действие еще не вышел,
            можете отправить ему только претензию или полностью одобрить сделку.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={dealsTrue}
              alt="сделка истина"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Когда таймер выйдет у вас появится возможность отправить штраф, даже если претензия уже была отправлена.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={dealsRedTimer}
              alt="сделка красный таймер"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={claimOrPenalty}
              alt="больной кот"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Претензия - это мирное урегулирование вопроса. Таким образом вы напоминаете второй стороне о её
            обязанностях.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={claim}
              alt="претензия"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            В случае назначения штрафа, другой стороне придётся выплатить неустойку, оговорённую при заключении
            долгосрочной сделки. Штраф так же означает, что сделка будет расторгнута и оставшиеся обязательства по ней
            не будут выполнены.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={penalty}
              alt="штраф"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>Подтверждение склада.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={confirmation}
              alt="подтверждение"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>Вам отправили претензию. У вас есть возможность доделать действие, чтобы не получить штраф.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={sentClaim}
              alt="отправление претензии"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            Вам отправили штраф, сделка автоматически расторгнута. Вы должны оплатить его, или в конце сезона сумма
            штрафа будет списана с вашего баланса. Если денежных средств будет недостаточно, то будет вскрыта банковская
            гарантия и вы будете объявлены банкротом.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={penaltyTrue}
              alt="штраф истина"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <h3 data-subpart="TERMINATION_TRANSACTION">Расторжение сделки</h3>
          <p>Одностороннее расторжение без указания причины оплачивается заранее обозначенной суммой.</p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={termDealsOne}
              alt="расторжение сделки"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
          <p>
            В расторжении по соглашению сторон указывается сумма, которую вы заплатите, чтобы расторгнуть сделку, и
            отправляется на подтверждение контрагенту.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={termDealsTwo}
              alt="расторжение сделки"
              className="user-guide-modal__main-image user-guide-modal__main-image--full-screen"
            />
          </div>
        </section>
      ),
    },
    REPORT: {
      title: 'финансовый отчет',
      icon: <UserGuideReport />,
      subParts: {
        SEASON_RESULTS: {
          title: 'итоги сезона',
        },
        GAME_RESULTS: {
          title: 'итоги игры',
        },
      },
      content: (
        <section className="user-guide-modal__main-section">
          <h2 data-part="REPORT">Финансовый отчет</h2>
          <h3 data-subpart="SEASON_RESULTS">Итоги сезона</h3>
          <p>
            Перед каждым новым сезоном игрок видит модальное окно с итогами предыдущего сезона. Здесь отражены все
            денежные операции предыдущего сезона.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img
              src={reportImg}
              alt="Окно итогов сезона"
              className="user-guide-modal__main-image user-guide-modal__main-image--normal-size"
            />
          </div>
          <h3 data-subpart="GAME_RESULTS">Итоги игры</h3>
          <p>При завершении игры появляется модальное окно с результатами игры.</p>
          <p>
            Оно содержит список игроков, распределение игроков по местам и количество монет, которое имел каждый из
            игроков к концу игры.
          </p>
          <p>
            Рейтинг по результатам игры формируется по денежным средствам игроков на конец игры. Котята, которых
            питомник или зоомагазин не успели продать, не учитываются.
          </p>
          <div className="user-guide-modal__main-image-wrapper">
            <img src={alertResults} alt="Окно результатов игры" className="user-guide-modal__main-image" />
          </div>
        </section>
      ),
    },
  };

  return (
    <ReactModal
      isOpen={appStore.userGuideModalFlag}
      onRequestClose={appStore.closeUserGuideFlag}
      overlayClassName="modal-overlay"
      className="user-guide-modal"
    >
      <div className="user-guide-modal">
        <header className="user-guide-modal__header">
          <span className="user-guide-modal__header-title-icon">
            <UserGuideQuestionIcon />
          </span>
          <h2 className="user-guide-modal__header-title">Руководство пользователя</h2>
          <span className="user-guide-modal__close" onClick={appStore.closeUserGuideFlag}>
            <UserGuideCloseIcon className="user-guide-modal__close" />
          </span>
        </header>
        <div className="user-guide-modal__block">
          <div className="user-guide-modal__nav">
            <ul className="user-guide-modal__nav-list">
              {Object.keys(PARTS)
                .filter((partKey) => {
                  return !PARTS[partKey].isVisible || PARTS[partKey].isVisible();
                })
                .map((partKey) => {
                  return (
                    <li key={partKey}>
                      <span
                        key={partKey}
                        className={classNames({
                          'user-guide-modal__nav-list-item': true,
                          'user-guide-modal__nav-list-item--active': part === partKey,
                          'user-guide-modal__test': PARTS[partKey] === PARTS.LONG_TERM_DEALS_DECOR,
                        })}
                        onClick={() => {
                          setPart(partKey);
                          if (!wrapper) {
                            return;
                          }
                          wrapper.scrollTop = 0;
                        }}
                      >
                        <span className="user-guide-modal__nav-list-item-icon">{PARTS[partKey].icon}</span>
                        {PARTS[partKey].title}
                      </span>

                      <ul className="user-guide-modal__nav-list user-guide-modal__nav-list--sub">
                        {Object.keys(PARTS[partKey].subParts).map((subPartKey) => {
                          return (
                            <li
                              key={subPartKey}
                              className={classNames({
                                'user-guide-modal__nav-list-item': true,
                                'user-guide-modal__nav-list-item--sub': true,
                              })}
                              onClick={() => {
                                setSubsection(partKey, subPartKey);
                              }}
                            >
                              {PARTS[partKey].subParts[subPartKey].title}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="user-guide-modal__main" key={part}>
            {PARTS[part].content}
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

function UserGuideModalBtn() {
  const { appStore } = RootStore;

  return (
    <div
      className={classNames({
        'user-guide-open-button': true,
        'user-guide-open-button--floating': appStore.endTurnFlag,
      })}
      onClick={appStore.openUserGuideFlag}
    >
      <UserGuideHelpIcon />
    </div>
  );
}

const UserGuideModalButton = observer(UserGuideModalBtn);

export { UserGuideModalButton };
export default observer(UserGuideModal);
