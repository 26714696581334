import React from 'react';
import { CoinIcon } from '../../icons';
import { observer } from 'mobx-react';

const CurrentTaskModalContentPrice = ({ text, price }) => {
  return (
    <div className="task-constructor__content-price">
      <span>{text}</span>
      <span className="task-constructor__content-price--coin">
        <CoinIcon
          style={{
            verticalAlign: 'middle',
            marginRight: '4px',
            width: '24px',
            height: '24px',
          }}
        />
        {price}
      </span>
    </div>
  );
};

export default observer(CurrentTaskModalContentPrice);
