import React from 'react';

function SalesIcon({ style }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g filter="url(#filter0_di_30_77)">
        <rect width="16" height="16" rx="8" fill="#F57212" />
      </g>
      <path
        d="M11.203 6.22473L4.98727 5.57143L5.01383 5.31864L10.5305 5.44864L10.4837 5.89355L11.2296 5.97194L11.3093 5.21358C11.3678 4.65744 10.9657 4.22057 10.4165 4.24207L5.34727 4.44228C4.79746 4.46424 4.29983 4.9369 4.24137 5.49303L3.70999 10.5488C3.6818 10.817 3.75955 11.0852 3.92613 11.2944C4.09271 11.5037 4.33447 11.6368 4.59824 11.6645L10.5654 12.2917C10.8291 12.3194 11.0933 12.2394 11.2997 12.0694C11.5062 11.8994 11.638 11.6532 11.6662 11.385L12.0913 7.34042C12.1195 7.07224 12.0417 6.80404 11.8751 6.59481C11.7086 6.38558 11.4668 6.25246 11.203 6.22473ZM10.0848 9.68841C9.98687 9.67808 9.89191 9.64823 9.80541 9.60056C9.71891 9.55289 9.64255 9.48834 9.58069 9.4106C9.51884 9.33285 9.4727 9.24343 9.4449 9.14744C9.41711 9.05144 9.40821 8.95077 9.41872 8.85115C9.42922 8.75153 9.45892 8.65492 9.50612 8.56683C9.55332 8.47875 9.6171 8.40092 9.69381 8.33778C9.77052 8.27465 9.85866 8.22744 9.9532 8.19886C10.0477 8.17029 10.1468 8.1609 10.2448 8.17123C10.4427 8.19209 10.624 8.29203 10.749 8.44905C10.8739 8.60606 10.9322 8.8073 10.9109 9.0085C10.8897 9.20969 10.7908 9.39435 10.6359 9.52186C10.4809 9.64937 10.2827 9.70928 10.0848 9.68841Z"
        fill="#A94C09"
      />
      <defs>
        <filter
          id="filter0_di_30_77"
          x="0"
          y="0"
          width="17"
          height="17"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_77" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_77" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_30_77" />
        </filter>
      </defs>
    </svg>
  );
}

export default SalesIcon;
