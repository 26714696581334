import React from 'react';
import CEOIcon from '../../icons/roles/CEOIcon';
import CaptainIcon from '../../icons/roles/CaptainIcon';
import SupplyIcon from '../../icons/roles/SupplyIcon';
import SalesIcon from '../../icons/roles/SalesIcon';
import MarketingIcon from '../../icons/roles/MarketingIcon';
import DoctorIcon from '../../icons/roles/DoctorIcon';
import { SPECIALITIES } from '../../../common/constants';

const ICONS = {
  [SPECIALITIES.CEO]: CEOIcon,
  [SPECIALITIES.CAPTAIN]: CaptainIcon,
  [SPECIALITIES.SUPPLY]: SupplyIcon,
  [SPECIALITIES.SALES]: SalesIcon,
  [SPECIALITIES.MARKETEER]: MarketingIcon,
  [SPECIALITIES.DOCTOR]: DoctorIcon,
};

const SpecialityIcon = ({ speciality, style }) => {
  const Icon = ICONS[speciality];
  return <Icon style={style} />;
};

export default SpecialityIcon;
