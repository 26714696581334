import trainingStore from '../store/trainingStore';
import Step from '../store/trainings/base/Step';
import Chapter from '../store/trainings/base/Chapter';

const Binder = {
  getAllMethods: (instance, cls) => {
    return Object.getOwnPropertyNames(Object.getPrototypeOf(instance)).filter((name) => {
      const method = instance[name];
      return !(!(method instanceof Function) || method === cls);
    });
  },
  bind: (instance, cls) => {
    Binder.getAllMethods(instance, cls).forEach((mtd) => {
      try {
        // eslint-disable-next-line no-param-reassign
        let mtdFunc = instance[mtd].bind(instance);
        instance[mtd] = function setInstance(...args) {
          if (mtd !== 'handleMessages') {
            console.log('Action', mtd, args);
          }

          if (trainingStore.launched || trainingStore.chapter instanceof Chapter) {
            const currentStep = trainingStore.currentStep;

            if (
              trainingStore.chapter instanceof Chapter &&
              (!currentStep || trainingStore.isATransitionalStep) &&
              trainingStore.chapter.actionIsBlocked(mtd)
            ) {
              console.log('Action is blocked', mtd, args);
              return;
            }

            if (currentStep instanceof Step) {
              if (currentStep.actionIsBlocked(mtd)) {
                console.log('Action is blocked', mtd, args);
                return;
              }
              const methodProxy = currentStep.methodsProxyByActionName({ actionName: mtd });
              if (methodProxy) {
                const originalMtd = mtdFunc;
                trainingStore.setOriginalInstanceMethods([
                  ...trainingStore.originalInstanceMethods,
                  {
                    instance,
                    methodName: mtd,
                    method: () => originalMtd(...args),
                  },
                ]);
                mtdFunc = methodProxy;
                trainingStore.checkIsCorrectAction(mtd, args);
              }
              if (currentStep.acceptActionsWithInvalidCheckParams) {
                const result = mtdFunc(...args);
                trainingStore.checkIsCorrectAction(mtd, args);
                return result;
              } else {
                if (!trainingStore.checkIsCorrectAction(mtd, args)) {
                  return;
                }
              }
            } else {
              if (!trainingStore.checkIsCorrectAction(mtd, args)) {
                return;
              }
            }
          }
          return mtdFunc(...args);
        };
      } catch (e) {}
    });
  },
};

export default Binder;
