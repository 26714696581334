import RootStore from '../store';

const getHeaderTitle = (name) => {
  const { appStore } = RootStore;

  if (appStore.isCorpMode && !appStore.meIsCEO) {
    return `${name} - город ${appStore.areaNum}`;
  } else {
    return name;
  }
};

export default getHeaderTitle;
