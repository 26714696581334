import React, { useEffect, useRef } from 'react';
import RootStore from '../../store';
import { observer } from 'mobx-react';
import classNames from '../../common/classNames';
import { PLAYER_ROLE, SPECIALITIES } from '../../common/constants';

function LotAreaInfo({ player, color }) {
  const { appStore } = RootStore;
  const myArea = appStore.me.uuid === player.uuid;
  const refWrapper = useRef(null);
  const refHouseName = useRef(null);

  const checkHouseNamePosition = () => {
    const wrapperWidth = refWrapper.current.offsetWidth;
    const wrapperHeight = refWrapper.current.offsetHeight;

    const wrapperRatio = wrapperWidth / wrapperHeight;

    let imageWidth;
    let imageHeight;
    let percentTop;
    let percentLeft;
    let fontSizeCoef;
    let percentWidth;

    if (player.role === PLAYER_ROLE.SHOP) {
      imageWidth = 320;
      imageHeight = 280;
      percentTop = 0.13;
      percentLeft = 0.36;
      fontSizeCoef = 0.11;
      percentWidth = 0;
    } else {
      imageWidth = 180;
      imageHeight = 130;
      percentTop = parseInt(player.coord.top) / 100;
      percentLeft = parseInt(player.coord.left) / 100;
      fontSizeCoef = 0.06;
      percentWidth = parseInt(player.coord.width) / 100;
    }

    const imageRatio = imageWidth / imageHeight; // 2 = 10 / 5

    let houseNameTop = 0;
    let houseNameLeft = 0;

    let realImageWidth;
    let realImageHeight;
    let lineHeightCoeff = window.innerWidth < 1200 ? 3 : 5;

    if (imageRatio > wrapperRatio) {
      houseNameLeft = wrapperWidth * percentLeft;
      realImageWidth = wrapperWidth;
      realImageHeight = wrapperWidth / imageRatio;
      houseNameTop = (wrapperHeight - realImageHeight) / 2 + realImageHeight * percentTop;
    } else {
      houseNameTop = wrapperHeight * percentTop;
      realImageWidth = wrapperHeight * imageRatio;
      houseNameLeft = (wrapperWidth - realImageWidth) / 2 + realImageWidth * percentLeft;
    }

    refHouseName.current.style.top = `${houseNameTop}px`;
    refHouseName.current.style.left = `${houseNameLeft}px`;
    refHouseName.current.style['font-size'] = `${realImageWidth * fontSizeCoef}px`;
    if (lineHeightCoeff) {
      refHouseName.current.style['line-height'] = `${realImageWidth * fontSizeCoef + lineHeightCoeff}px`;
    }
    if (percentWidth) {
      refHouseName.current.style['width'] = `${realImageWidth * percentWidth}px`;
    }
  };
  useEffect(checkHouseNamePosition, [refWrapper, player]);

  useEffect(() => {
    window.addEventListener('resize', checkHouseNamePosition);
    return () => window.removeEventListener('resize', checkHouseNamePosition);
  }, []);

  const catsInflectedWord = appStore.currentPageLanguageIsRussian ? 'котики' : 'коты';

  return (
    <div className="lot-area__info">
      <span
        ref={refWrapper}
        style={{
          backgroundImage: `url(${player.src})`,
          backgroundSize: 'contain',
        }}
        className={classNames({
          'lot-area__image': true,
          [`pet-shops-item--relation-${player.relation_to_me}`]:
            !myArea &&
            player.ai_type !== 'human' &&
            player.role !== appStore.me.role &&
            (!appStore.isTeamMode ||
              appStore.hasSpecialities([SPECIALITIES.CAPTAIN, SPECIALITIES.SUPPLY, SPECIALITIES.SALES])),
        })}
      >
        <span
          ref={refHouseName}
          className={classNames({ 'lot-area__name': true, 'lot-area__name--shop': player.role === PLAYER_ROLE.SHOP })}
        >
          {player.name}
        </span>
      </span>

      {myArea && player.role === PLAYER_ROLE.SHOP ? (
        <p style={{ color }}>МОЯ ВИТРИНА</p>
      ) : myArea && player.role === PLAYER_ROLE.NURSERY ? (
        <p style={{ color }}>МОИ {catsInflectedWord.toUpperCase()}</p>
      ) : null}
      {!myArea && player.role === PLAYER_ROLE.NURSERY && <p style={{ color }}>ПИТОМНИК</p>}
      {!myArea && player.role === PLAYER_ROLE.SHOP && <p style={{ color }}>ЗООМАГАЗИН</p>}
    </div>
  );
}

export default observer(LotAreaInfo);
