import React from 'react';

function ChapterThreeIcon({ style, className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#CharterThreeIcon0)">
        <path
          d="M26.4626 3.948C26.4626 2.04663 25.0293 1 23.0146 0.500001C21 2.98023e-08 11 -2.08616e-07 8.9563 0.500001C6.9126 1 5.5083 2.04663 5.5083 3.948V5.521H26.4626V3.948Z"
          fill="#C6A788"
        />
        <path
          d="M28.4707 12.9875C27.9942 10.5638 26.7678 6.896 26.7678 6.896H5.2029C5.2029 6.896 3.97659 10.5638 3.50013 12.9875C2.32766 18.9518 3.50002 27.5522 3.50002 27.5522C4 29.5 5 30.7661 6.94778 31C14.0018 31.847 17.969 31.7767 25.023 31C27 30.7823 28 29.5 28.4707 27.5522C28.4707 27.5522 29.6433 18.9518 28.4707 12.9875ZM15.9854 27.4812C11.0388 27.4812 7.01467 23.457 7.01467 18.5105C7.01467 13.564 11.0388 9.53955 15.9854 9.53955C20.9319 9.53955 24.9563 13.564 24.9563 18.5105C24.9563 23.457 20.9319 27.4812 15.9854 27.4812Z"
          fill="#C6A788"
        />
        <path
          d="M12.4765 15.6063C11.9917 14.6341 12.3401 13.0126 13.2589 11.9669C13.4845 11.7102 13.8323 11.5 14.0317 11.5C14.6032 11.5 15.3698 12.908 15.5193 14.2323C15.6414 15.3128 15.6102 15.4521 15.1352 15.9443C14.2887 16.8221 13.0013 16.6583 12.4765 15.6063Z"
          fill="#C6A788"
        />
        <path
          d="M16.6018 14.5901C16.6018 13.4301 16.8658 12.6141 17.4507 11.9669C18.2049 11.1321 18.8796 11.4747 19.4965 13.0053C20.2407 14.8522 19.6478 16.4803 18.2311 16.4803C17.17 16.4803 16.6018 15.821 16.6018 14.5901Z"
          fill="#C6A788"
        />
        <path
          d="M9.00005 18.2016C9.00526 17.2564 9.61516 15.7791 10.1276 15.4703C10.6354 15.1645 10.6726 15.173 11.1376 15.7021C11.7999 16.456 11.9981 17.0109 11.9981 18.1117C11.9981 18.9839 11.9146 19.2159 11.4706 19.5778C10.7711 20.1485 10.0873 20.1211 9.48705 19.4985C9.13537 19.1342 8.99685 18.7652 9.00005 18.2016Z"
          fill="#C6A788"
        />
        <path
          d="M19.9629 18.1972C19.9987 17.3072 20.2953 16.4004 20.7227 15.8737C21.0079 15.5226 21.2839 15.2352 21.3366 15.2352C21.7279 15.2352 22.301 15.8223 22.6192 16.549C23.161 17.7862 23.123 18.869 22.5155 19.4985C21.4751 20.5774 19.8992 19.7739 19.9629 18.1972Z"
          fill="#C6A788"
        />
        <path
          d="M11.4887 22.6025C11.8441 20.2684 12.7805 18.8351 14.4889 18.0105C15.7387 17.4073 16.4793 17.3933 17.694 17.9503C19.5633 18.8075 20.8086 20.7725 20.8062 22.8613C20.8046 24.2307 20.2717 24.6613 18.7789 24.4996C18.1332 24.4297 17.3015 24.2408 16.9304 24.0802C16.3298 23.82 16.1374 23.8304 15.1778 24.1738C14.5849 24.3861 13.6784 24.5627 13.1636 24.5664C12.3768 24.5722 12.1582 24.4824 11.7947 24.0032C11.448 23.5461 11.3874 23.2686 11.4887 22.6025Z"
          fill="#C6A788"
        />
      </g>
      <defs>
        <clipPath id="CharterThreeIcon0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ChapterThreeIcon;
