const colorsOrder = ['black', 'white', 'gray', 'ginger'];
const genderOrder = ['male', 'female'];

const catsSort = (a, b) => {
  if (colorsOrder.indexOf(a.color) > colorsOrder.indexOf(b.color)) return 1;
  if (colorsOrder.indexOf(a.color) < colorsOrder.indexOf(b.color)) return -1;
  if (colorsOrder.indexOf(a.color) === colorsOrder.indexOf(b.color)) {
    if (genderOrder.indexOf(a.gender) > genderOrder.indexOf(b.gender)) return 1;
  }
  return -1;
};

export default catsSort;
