import React from 'react';
import { observer } from 'mobx-react';
import routes from '../routes';
import RootStore from '../store';
import { AREA_POSITION } from '../store/constants';
import DragElement from '../modules/DragElement';

import containerBG from '../assets/basic/trade/background/trade_bg.png';
import AreaIcon from '../ui/icons/AreaIcon';
import Header from '../ui/components/Header/Header';
import CarsField from '../modules/corp/transportation/CarsField';
import CompanyField from '../modules/corp/company/CompanyField';
import OperationField from '../modules/corp/operation/OperationField';
import './inside.scss';
import Loader from "../ui/components/Loader/Loader";

function Transportation() {
  const { appStore } = RootStore;
  if (!appStore.me) {
    return <Loader />;
  }

  if (!appStore.meIsCEO) {
    return;
  }

  return (
    <>
      <Header
        background
        extra={
          <span onClick={() => RootStore.goTo(routes.ceo)}>
            <AreaIcon fill="#C6A788" />
          </span>
        }
      />
      <div
        style={{
          background: `url(${containerBG}) center`,
        }}
        className="inside__container"
      >
        <div className="inside__wrap">
          <CarsField />
          <CompanyField position={AREA_POSITION.TOP} />
          <OperationField />
          <CompanyField position={AREA_POSITION.BOTTOM} />
        </div>
      </div>

      <DragElement />
    </>
  );
}

export default observer(Transportation);
