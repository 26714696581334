export const NURSERY_PLAYERS_NAMES = [
  'Леопольд',
  'Том',
  'Британец',
  'Матильда',
  'Гарфилд',
  'Сиамский',
  'Мейн-кун',
  'Котенок Гав',
  'Феликс',
  'Симба',
  'Сфинкс',
  'Матроскин',
  'Друг',
  'Верность',
  'Котофей',
  'Мышелов',
  'Премиум',
  'Фортуна',
  'Китти',
  'Мау',
];

export const SHOP_PLAYERS_NAMES = ['Бонифаций', 'Полосатый', 'Любимец', 'Мурзик', 'Зооцентр'];
