import React from 'react';
import Modal from '../Modal/Modal';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import TextButton from '../../buttons/TextButton/TextButton';
import ListModalIcon from '../../icons/modal/ListModalIcon';
import { PLAYER_ROLE } from '../../../common/constants';
import AllCEOsAndCaptains from './components/AllCEOsAndCaptains';
import Divider from '../Divider/Divider';

function SessionUsersList() {
  const { appStore } = RootStore;

  if (!appStore.sessionUsersListModalFlag) {
    return null;
  }

  const isNotSpectator = !appStore.isSpectatorModeOn;

  const shopsCEO = appStore.spectatorCorpPlayers.filter((player) => player.role === PLAYER_ROLE.SHOP);
  const nurseriesCEO = appStore.spectatorCorpPlayers.filter((player) => player.role === PLAYER_ROLE.NURSERY);

  return (
    <Modal
      isOpen={appStore.sessionUsersListModalFlag}
      onClose={appStore.hideSessionUsersListModal}
      title={
        <p className="user-list-modal-title">
          <ListModalIcon className="modal-title-icon" /> Список всех игроков
        </p>
      }
      size="big"
    >
      <div className="modal_body-user-list">
        <p className="user-list_title">Зоомагазины</p>
        {shopsCEO.map((player) => (
          <AllCEOsAndCaptains key={player.ceo_id} player={player} isNotSpectator={isNotSpectator} />
        ))}
      </div>
      <div className="modal_body-user-list">
        <p className="user-list_title">Питомники</p>
        {nurseriesCEO.map((player) => (
          <AllCEOsAndCaptains key={player.ceo_id} player={player} isNotSpectator={isNotSpectator} />
        ))}
      </div>

      <div className="modal__body-actions">
        <TextButton color="blue" title="назад" onClick={appStore.hideSessionUsersListModal} />
      </div>
    </Modal>
  );
}

export default observer(SessionUsersList);
