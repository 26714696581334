import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarHatImage from '../../../assets/nurseryAvatars/avatarHat.png';

function AvatarHat({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
    //     <circle cx="18" cy="18" r="18" fill="#ECF4EF" />
    //   </mask>
    //   <g mask="url(#mask0)">
    //     <path
    //       d="M13.655 20.7424C15.0865 21.1083 16.5483 21.2819 18.0127 21.2599C19.4772 21.2819 20.939 21.1083 22.3705 20.7424C23.6303 20.3818 24.4087 19.8057 24.4087 19.122C24.4087 12.1766 22.5606 10.5 20.7106 10.5C19.4785 10.5023 18.2881 11.2704 17.6404 11.6872C17.4779 11.7926 17.3551 11.8722 17.2977 11.8956C16.9174 12.0642 16.3766 11.9659 15.8101 11.8628C15.4067 11.7763 14.9979 11.7285 14.588 11.72C12.9895 11.7364 11.5712 12.837 11.6168 19.122C11.6168 19.8244 12.3952 20.3841 13.655 20.7424Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M26.865 20.12C26.2438 19.774 25.8369 19.5101 25.1667 19.3333V20.1294C25.1667 21.4922 23.3828 22.1011 22.4795 22.3587C21.0109 22.7391 19.5103 22.919 18.0069 22.8949C16.501 22.9198 14.9976 22.7398 13.5264 22.3587C12.6232 22.1011 10.75 21.4922 10.75 20.1294C10.75 20.1154 10.75 20.1013 10.75 20.0896C10.75 19.8554 10.75 19.5932 10.75 19.3356C10.0811 19.5127 9.75493 19.7765 9.13499 20.1224C8.40804 20.5556 8 21.0801 8 21.6796C8 22.656 8.41667 24.0955 11.092 24.8546C12.8747 25.3604 15.3051 25.6741 17.9931 25.6741C20.681 25.6741 23.1352 25.3627 24.908 24.8639C27.5833 24.1112 28 22.6584 28 21.6889C28 21.0801 27.5939 20.5532 26.865 20.12Z"
    //       fill="#02516C"
    //     />
    //   </g>
    // </svg>
    <Avatar style={style} avatarImage={AvatarHatImage} />
  );
}

export default AvatarHat;
