import RootStore from '../../index';
import React from 'react';
import { LOT_STATUS, MESSAGE_TYPE, REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT } from '../../constants';
import { LocalStorage } from '../../../common/localStorage';
import CoinIcon from '../../../ui/icons/CoinIcon';
import CoinModalIcon from '../../../ui/icons/modal/CoinModalIcon';
import PlusModalIcon from '../../../ui/icons/modal/PlusModalIcon';
import LikeModalIcon from '../../../ui/icons/modal/LikeModalIcon';
import _ from 'lodash';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../../../ui/components/Cat/Cat';
import ChapterTenIcon from '../../../ui/icons/training/ChapterTenIcon';
import EmotionsMax from '../../../assets/emotions/training/EmotionsMax.png';
import EmotionsHigh from '../../../assets/emotions/training/EmotionsHigh.png';
import EmotionsMiddle from '../../../assets/emotions/training/EmotionsMiddle.png';
import EmotionsLowMiddle from '../../../assets/emotions/training/EmotionsLowMiddle.png';
import EmotionsLow from '../../../assets/emotions/training/EmotionsLow.png';
import EmotionsMin from '../../../assets/emotions/training/EmotionsMin.png';
import EmotionsAll from '../../../assets/emotions/training/EmotionsAll.png';
import MinusModalIcon from '../../../ui/icons/modal/MinusModalIcon';
import { CAT_COLOR, CAT_GENDER, MATING_STATUS, SICKNESS } from '../../../common/constants';

export const chapter = 11;

export const stepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 6,
          sell_price: 6,
        },
        female_black: {
          buy_price: 6,
          sell_price: 6,
        },
        male_gray: {
          buy_price: 6,
          sell_price: 6,
        },
        female_gray: {
          buy_price: 6,
          sell_price: 6,
        },
      },
      cats: [
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'female',
        },
        {
          color: 'black',
          gender: 'female',
        },
        {
          color: 'black',
          gender: 'female',
        },
        {
          color: 'gray',
          gender: 'male',
        },
      ],
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: false,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 46,
      default_prices_override: {
        male_black: {
          buy_price: 6,
          sell_price: 6,
        },
        female_black: {
          buy_price: 6,
          sell_price: 6,
        },
        male_gray: {
          buy_price: 6,
          sell_price: 6,
        },
        female_gray: {
          buy_price: 6,
          sell_price: 6,
        },
      },
    },
  ],
  city_quota_override: {
    male_black: {
      buy_price: 8,
      sell_price: 8,
      buy_quantity: null,
    },
    female_black: {
      buy_price: 9,
      sell_price: 9,
      buy_quantity: 13,
    },
    male_gray: {
      buy_price: 11,
      sell_price: 11,
      buy_quantity: 31,
    },
    female_gray: {
      buy_price: 13,
      sell_price: 13,
      buy_quantity: 11,
    },
  },
  turn_durations: [100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
  hunger_enabled: false,
};

export const taskApiParams = {
  game_type: 'tutorial',
  chapter_num: 11,
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 4,
          sell_price: 6,
        },
        female_black: {
          buy_price: 5,
          sell_price: 7,
        },
        male_gray: {
          buy_price: 4,
          sell_price: 7,
        },
        female_gray: {
          buy_price: 5,
          sell_price: 8,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 47,
      default_prices_override: {
        male_black: {
          buy_price: 10,
          sell_price: 10,
        },
        female_black: {
          buy_price: 12,
          sell_price: 12,
        },
        male_gray: {
          buy_price: 12,
          sell_price: 12,
        },
        female_gray: {
          buy_price: 14,
          sell_price: 14,
        },
      },
    },
  ],
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
  only_one_kitten_sick: true,
  hunger_enabled: true,
};

export const steps = [
  {
    actionName: 'onNextButton',
    chapter: '11',
    chapterIcon: <ChapterTenIcon className="training-modal__chapter-icon" />,
    title: <div>ДОБРО ПОЖАЛОВАТЬ В ГЛАВУ «ПЕРЕГОВОРЫ О ЦЕНЕ»!</div>,
    message: (
      <p className="training-modal__desc">
        В современном мире важно уметь общаться. Общение помогает получить взаимную выгоду. В Business Cats ты видишь,
        как к тебе относятся другие игроки. Об этом говорят смайлики на главном экране.
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    elements: ['.pet-shops-item-wrapper:first-child'],
    disabledElements: ['.pet-shops-item-wrapper:first-child'],
    step: 185,
    title: 'ЭМОЦИИ ВАЖНЫ',
    message: (
      <p className="training-modal__desc">
        Чем радостнее смайлик, тем лучше ваши отношения. Смайлик грустнеет, если магазин дает тебе скидку или ты
        отклоняешь его предложение. Если смайлик погрустнел, значит отношения ухудшились. Зоомагазин неохотно будет с
        тобой торговать.
      </p>
    ),
    position: 'right',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    title: 'ИНДИКАТОРЫ ВЗАИМООТНОШЕНИЙ',
    emotion: <img src={EmotionsMax} className="training-modal__emotion-icon" />,
    message: <p className="training-modal__desc">смайлики бывают шести видов.</p>,
    nextButton: true,
    withoutTimeout: true,
  },
  {
    actionName: 'onNextButton',
    title: 'ИНДИКАТОРЫ ВЗАИМООТНОШЕНИЙ',
    emotion: <img src={EmotionsHigh} className="training-modal__emotion-icon" />,
    message: <p className="training-modal__desc">смайлики бывают шести видов.</p>,
    nextButton: true,
    withoutTimeout: true,
  },
  {
    actionName: 'onNextButton',
    title: 'ИНДИКАТОРЫ ВЗАИМООТНОШЕНИЙ',
    emotion: <img src={EmotionsMiddle} className="training-modal__emotion-icon" />,
    message: <p className="training-modal__desc">смайлики бывают шести видов.</p>,
    nextButton: true,
    withoutTimeout: true,
  },
  {
    actionName: 'onNextButton',
    title: 'ИНДИКАТОРЫ ВЗАИМООТНОШЕНИЙ',
    emotion: <img src={EmotionsLowMiddle} className="training-modal__emotion-icon" />,
    message: <p className="training-modal__desc">смайлики бывают шести видов.</p>,
    nextButton: true,
    withoutTimeout: true,
  },
  {
    actionName: 'onNextButton',
    title: 'ИНДИКАТОРЫ ВЗАИМООТНОШЕНИЙ',
    emotion: <img src={EmotionsLow} className="training-modal__emotion-icon" />,
    message: <p className="training-modal__desc">смайлики бывают шести видов.</p>,
    nextButton: true,
    withoutTimeout: true,
  },
  {
    actionName: 'onNextButton',
    title: 'ИНДИКАТОРЫ ВЗАИМООТНОШЕНИЙ',
    emotion: <img src={EmotionsMin} className="training-modal__emotion-icon" />,
    message: <p className="training-modal__desc">смайлики бывают шести видов.</p>,
    nextButton: true,
    withoutTimeout: true,
  },
  {
    actionName: 'onNextButton',
    title: 'ИНДИКАТОРЫ ВЗАИМООТНОШЕНИЙ',
    isEmotionsAll: true,
    emotion: <img src={EmotionsAll} />,
    message: (
      <p className="training-modal__desc">
        <span>Скидка на</span> <span className="notranslate"> 1</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span> ухудшает отношение с зоомагазином на</span> <span className="notranslate"> 1 </span>
        <span>
          смайлик. Если настроение зоомагазина упадет до злого красного смайлика, он перестанет с тобой торговать.
        </span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
    step: 10,
    title: 'НАЧИНАЕМ',
    message: (
      <p className="training-modal__desc">
        Пока с зоомагазином у тебя отличные отношения! Давай купим у него котят со скидкой. Зайди в зоомагазин.
      </p>
    ),
  },
  {
    actionName: 'getLotPrice',
    checkParams: () => {
      if (!RootStore.appStore.currentPlayer.lot) {
        return 'skip';
      }
      const lotContents = RootStore.appStore.currentPlayer.lot.contents;
      if (lotContents.length !== 3) {
        return 'skip';
      }
      if (
        lotContents.some((cat) => cat.gender === CAT_GENDER.MALE && cat.color === CAT_COLOR.BLACK && cat.count === 4) &&
        lotContents.some(
          (cat) => cat.gender === CAT_GENDER.FEMALE && cat.color === CAT_COLOR.BLACK && cat.count === 2,
        ) &&
        lotContents.some((cat) => cat.gender === CAT_GENDER.MALE && cat.color === CAT_COLOR.GRAY && cat.count === 1)
      ) {
        return true;
      }
      return 'skip';
    },
    elements: ['.lot-area.lot-area--lot', '.lot-area.lot-area--shop'],
    disabledElements: ['.lot-area__lot-panel'],
    step: 19,
    isWide: true,
    title: 'КУПИ В МАГАЗИНЕ',
    message: (
      <div className="training-modal__cats">
        <Cat
          cat={{ gender: 'male', color: 'black', count: 4 }}
          readonly
          realCount={true}
          state={CAT_STATE.SINGLE}
          bottomText={BOTTOM_TEXT.KIND}
        />
        <Cat
          cat={{ gender: 'female', color: 'black', count: 2 }}
          readonly
          realCount={true}
          state={CAT_STATE.SINGLE}
          bottomText={BOTTOM_TEXT.KIND}
        />
        <Cat
          cat={{ gender: 'male', color: 'gray', count: 1 }}
          readonly
          realCount={true}
          state={CAT_STATE.SINGLE}
          bottomText={BOTTOM_TEXT.KIND}
        />
      </div>
    ),
    position: 'top',
  },

  {
    actionName: 'getLotPrice',
    checkParams: () => {
      if (!RootStore.appStore.currentPlayer.lot) {
        return 'skip';
      }
      const lotContents = RootStore.appStore.currentPlayer.lot.contents;
      if (lotContents.length !== 3) {
        return 'skip';
      }
      if (
        lotContents.some(
          (cat) =>
            cat.gender === CAT_GENDER.MALE && cat.color === CAT_COLOR.BLACK && cat.count === 4 && cat.price === 6,
        ) &&
        lotContents.some(
          (cat) =>
            cat.gender === CAT_GENDER.FEMALE && cat.color === CAT_COLOR.BLACK && cat.count === 2 && cat.price === 5,
        ) &&
        lotContents.some(
          (cat) => cat.gender === CAT_GENDER.MALE && cat.color === CAT_COLOR.GRAY && cat.count === 1 && cat.price === 4,
        )
      ) {
        return true;
      }
      return 'skip';
    },
    elements: ['.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area__lot-panel'],
    step: 19,
    changePrice: true,
    isVeryWide: true,
    isDisabledAllDeleteCat: true,
    isDisabledDoubleClickCat: true,
    title: 'ДАВАЙ ПОТОРГУЕМСЯ',
    message: (
      <div className="training-modal__blocks training-modal__blocks--not-margin">
        <div className="training-modal__blocks">
          <div className="training-modal__price training-modal__price--vmax">
            <p className="training-modal__price-name">ЦЕНА продажи</p>
            <div className="training-modal__price-value">
              <CoinModalIcon className="training-modal__price-coin training-modal__price-coin--right" />
              <span className="training-modal__price-cost notranslate">6</span>
            </div>
          </div>
          <div className="training-modal__cats">
            <Cat
              cat={{ gender: 'male', color: 'black' }}
              readonly
              state={CAT_STATE.SINGLE}
              bottomText={BOTTOM_TEXT.KIND}
              notShowCount={true}
            />
          </div>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price training-modal__price--vmax">
            <p className="training-modal__price-name">ЦЕНА продажи</p>
            <div className="training-modal__price-value">
              <CoinModalIcon className="training-modal__price-coin training-modal__price-coin--right" />
              <span className="training-modal__price-cost notranslate">5</span>
            </div>
          </div>
          <div className="training-modal__cats">
            <Cat
              cat={{ gender: 'female', color: 'black' }}
              readonly
              state={CAT_STATE.SINGLE}
              bottomText={BOTTOM_TEXT.KIND}
              notShowCount={true}
            />
          </div>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price training-modal__price--vmax">
            <p className="training-modal__price-name">ЦЕНА продажи</p>
            <div className="training-modal__price-value">
              <CoinModalIcon className="training-modal__price-coin training-modal__price-coin--right" />
              <span className="training-modal__price-cost notranslate">4</span>
            </div>
          </div>
          <div className="training-modal__cats">
            <Cat
              cat={{ gender: 'male', color: 'gray' }}
              readonly
              state={CAT_STATE.SINGLE}
              bottomText={BOTTOM_TEXT.KIND}
              notShowCount={true}
            />
          </div>
        </div>
      </div>
    ),
    position: 'top',
  },

  {
    actionName: 'acceptLot',
    elements: ['.training-create-lot-button'],
    pulseElements: ['.training-create-lot-button'],
  },
  {
    actionName: 'lotProposalSentModalClose',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({ msg_type: MESSAGE_TYPE.TRADING_CONFIRM, lot_id: RootStore.appStore.lots[0].lot_id }),
          );
        }, 100);
        setTimeout(() => {
          socket.send(
            JSON.stringify({
              msg_type: 'relation_changed',
              with_player: LocalStorage.USER_UID,
              changed_to: 3,
            }),
          );
        }, 200);
      };
    },
  },

  {
    actionName: 'notificationModalOpen',
    elements: ['.mail'],
    pulseElements: ['.mail'],
    step: 24,
    title: 'УСПЕШНЫЕ ПЕРЕГОВОРЫ',
    message: (
      <p className="training-modal__desc">
        <span>Сделка состоялась! Ты попросил максимальную скидку в</span> <span className="notranslate"> 2</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>, а также скидку в</span>
        <span className="notranslate"> 1</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    position: 'left',
  },
  {
    step: 25,
    actionName: 'notificationModalClose',
  },

  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--shop .lot-area__image'],
    step: 19,
    title: 'НАСТРОЕНИЕ ИЗМЕНИЛОСЬ',
    message: (
      <p className="training-modal__desc">
        <span>Настроение зоомагазина упало на</span> <span className="notranslate"> 2 </span>
        <span>смайлика, потому что самая большая скидка, которую ты просил, составила</span>
        <span className="notranslate"> 2</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Отношение ухудшается на размер самой большой скидки на котенка в одной сделке.</span>
      </p>
    ),
    nextButton: true,
    position: 'right',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({
              msg_type: 'relation_changed',
              with_player: LocalStorage.USER_UID,
              changed_to: 0,
            }),
          );
        }, 100);
      };
    },
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--shop .lot-area__image'],
    step: 19,
    title: 'ПРЕДСТАВИМ ХУДШЕЕ',
    message: (
      <p className="training-modal__desc">
        Если ты будешь часто просить большие скидки у партнеров по бизнесу, отношения между вами испортятся и торговля
        пойдет хуже. Представим, что у магазина совсем испортилось настроение.
      </p>
    ),
    position: 'right',
    nextButton: true,
  },
  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      if (contents.length !== 1) {
        return false;
      }
      if (contents[0].gender === CAT_GENDER.MALE && contents[0].color === CAT_COLOR.BLACK && contents[0].price === 6) {
        return true;
      }
      return false;
    },
    elements: ['.lot-area.lot-area--nursery', '.lot-area.lot-area--lot'],
    step: 83,
    title: 'ПРОДАЙ ЗООМАГАЗИНУ',
    message: (
      <div className="training-modal__blocks training-modal__blocks--not-margin">
        <div className="training-modal__price">
          <p className="training-modal__price-name">ЦЕНА продажи</p>
          <div className="training-modal__price-value">
            <CoinModalIcon className="training-modal__price-coin training-modal__price-coin--right" />
            <span className="training-modal__price-cost notranslate">6</span>
          </div>
        </div>
        <div className="training-modal__cats">
          <Cat
            cat={{ gender: 'male', color: 'black', count: 1 }}
            readonly
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
        </div>
      </div>
    ),
    position: 'top',
  },

  {
    actionName: 'lotProposalSentModalClose',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({
              msg_type: MESSAGE_TYPE.TRADING_CANCEL,
              reason: REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT.BAD_RELATIONSHIP,
              lot_id: RootStore.appStore.lots[0].lot_id,
            }),
          );
        }, 100);
      };
    },
  },

  {
    actionName: 'notificationModalOpen',
    elements: ['.mail'],
    pulseElements: ['.mail'],
    step: 24,
    position: 'left',
  },
  {
    step: 25,
    actionName: 'notificationModalClose',
  },

  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--shop .lot-area__image'],
    step: 19,
    title: 'ДОРОЖИМ ОТНОШЕНИЯМИ',
    message: (
      <p className="training-modal__desc">
        Злой зоомагазин с нами не работает. Если ты совсем не будешь просить скидок, твой бизнес-партнер будет доволен,
        но ты можешь проиграть. Ведь твои конкуренты будут торговаться! Не бойся просить скидку, но чувствуй меру.
        Успешный бизнес – это баланс интересов продавца и покупателя!
      </p>
    ),
    nextButton: true,
    position: 'right',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({
              msg_type: 'relation_changed',
              with_player: LocalStorage.USER_UID,
              changed_to: 5,
            }),
          );
        }, 100);
      };
    },
  },

  {
    actionName: 'getLotPrice',
    checkParams: () => {
      if (!RootStore.appStore.currentPlayer.lot) {
        return 'skip';
      }
      const lotContents = RootStore.appStore.currentPlayer.lot.contents;
      if (lotContents.length !== 2) {
        return 'skip';
      }
      if (
        lotContents.some(
          (cat) =>
            cat.gender === CAT_GENDER.MALE && cat.color === CAT_COLOR.BLACK && cat.count === 1 && cat.price === 3,
        ) &&
        lotContents.some(
          (cat) =>
            cat.gender === CAT_GENDER.FEMALE && cat.color === CAT_COLOR.BLACK && cat.count === 1 && cat.price === 5,
        )
      ) {
        return true;
      }
      return 'skip';
    },
    elements: ['.lot-area.lot-area--lot', '.lot-area.lot-area--shop'],
    disabledElements: ['.lot-area__lot-panel'],
    step: 19,
    isWide: true,
    changePrice: true,
    title: 'ПОКУПКА КОТЯТ',
    message: (
      <div>
        <p className="training-modal__desc">Сейчас у тебя хорошие отношения с зоомагазином. Попробуй купить:</p>
        <div className="training-modal__blocks training-modal__blocks--not-margin">
          <div className="training-modal__blocks">
            <div className="training-modal__price">
              <p className="training-modal__price-name">ЦЕНА продажи</p>
              <div className="training-modal__price-value">
                <CoinModalIcon className="training-modal__price-coin training-modal__price-coin--right" />
                <span className="training-modal__price-cost notranslate">3</span>
              </div>
            </div>
            <div className="training-modal__cats">
              <Cat
                cat={{ gender: 'male', color: 'black', count: 1 }}
                readonly
                state={CAT_STATE.SINGLE}
                bottomText={BOTTOM_TEXT.KIND}
              />
            </div>
          </div>
          <div className="training-modal__blocks">
            <div className="training-modal__price">
              <p className="training-modal__price-name">ЦЕНА продажи</p>
              <div className="training-modal__price-value">
                <CoinModalIcon className="training-modal__price-coin training-modal__price-coin--right" />
                <span className="training-modal__price-cost notranslate">5</span>
              </div>
            </div>
            <div className="training-modal__cats">
              <Cat
                cat={{ gender: 'female', color: 'black', count: 1 }}
                readonly
                state={CAT_STATE.SINGLE}
                bottomText={BOTTOM_TEXT.KIND}
              />
            </div>
          </div>
        </div>
      </div>
    ),
    position: 'top',
  },
  {
    actionName: 'acceptLot',
    elements: ['.training-create-lot-button'],
    pulseElements: ['.training-create-lot-button'],
  },
  {
    actionName: 'lotProposalSentModalClose',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          const contents = _.cloneDeep(RootStore.appStore.lots[0].contents);
          contents.find((cat) => cat.gender === CAT_GENDER.MALE).price = 4;
          socket.send(
            JSON.stringify({
              msg_type: MESSAGE_TYPE.TRADING_CONFIRM,
              contents,
              lot_id: RootStore.appStore.lots[0].lot_id,
            }),
          );
        }, 100);
      };
    },
  },

  {
    actionName: 'setCurrentLot',
    elements: ['.mail'],
    pulseElements: ['.mail'],
    step: 24,
    title: 'ВСТРЕЧНАЯ СДЕЛКА',
    message: (
      <p className="training-modal__desc">
        Зоомагазин прислал тебе встречную сделку. В желтом конверте приходят все контрсделки.
      </p>
    ),
    position: 'left',
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area.lot-area--lot'],
    step: 19,
    title: 'ЦЕНА ОТМЕЧЕНА',
    message: (
      <p className="training-modal__desc">
        Ценник под черным мальчиком имеет зеленый цвет. Это подсказка: цена для тебя выгодна. Однако она стала больше,
        чем мы запросили изначально. Изначальная цена отображена правее в сером квадрате.
      </p>
    ),
    nextButton: true,
    position: 'top',
  },
  {
    actionName: 'getLotPrice',
    checkParams: () => {
      const lotContents = RootStore.appStore.currentPlayer.lot.contents;
      if (lotContents.length !== 1) {
        return 'skip';
      }
      return true;
    },
    elements: ['.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area__lot-panel', '.lot-area.lot-area--lot .cat--female-black'],
    step: 19,
    title: 'ВСТРЕЧНАЯ СДЕЛКА',
    message: (
      <p className="training-modal__desc">
        <span>Мы просили скидку на черного мальчика</span> <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Магазин готов уступить нам только</span> <span className="notranslate"> 2</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Но мы хотели купить дешевле. Нажми на крестик, чтобы убрать черного мальчика из сделки.</span>
      </p>
    ),
    position: 'top',
  },

  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      if (contents.length !== 1) {
        return false;
      }
      if (
        contents[0].gender === CAT_GENDER.FEMALE &&
        contents[0].color === CAT_COLOR.BLACK &&
        contents[0].price === 4
      ) {
        return true;
      }
      return false;
    },
    elements: ['.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area.lot-area--lot .text_button--color-red'],
    isDisabledAllDeleteCat: true,
    isDisabledDoubleClickCat: true,
    step: 83,
    title: 'СНИЗИМ ЦЕНУ',
    changePrice: true,
    message: (
      <p className="training-modal__desc">
        <span>Давай попробуем снизить цену девочки еще на</span> <span className="notranslate"> 1</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span> и отправим нашу контрсделку.</span>
      </p>
    ),
    position: 'top',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({ msg_type: MESSAGE_TYPE.TRADING_CONFIRM, lot_id: RootStore.appStore.lots[0].lot_id }),
          );
        }, 100);
        setTimeout(() => {
          socket.send(
            JSON.stringify({
              msg_type: 'relation_changed',
              with_player: '00000000-0000-0000-0000-000000000001',
              changed_to: 3,
            }),
          );
        }, 200);
      };
    },
  },
  {
    actionName: 'onNextButton',
    title: 'ТЫ БЛИЗОК К ФИНИШУ',
    message: (
      <p className="training-modal__desc">
        Твои отношения с магазином изменились, но все еще остались хорошими. Если совершать покупки с небольшими
        скидками и давать скидки взамен, сформируются выгодные для продавца и покупателя партнерские отношения.
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isWide: true,
    message: (
      <div>
        <p className="training-modal__desc">
          В игре, как и в жизни, надо договариваться со своим клиентом, строить доверительные отношения. Чтобы решить
          практическое задание, посмотри, как мы считали:
        </p>
        <div className="training-modal__blocks training-modal__blocks--not-margin training-modal__blocks--wrap training-modal__blocks--center">
          <div className="training-modal__blocks">
            <p className="training-modal__block-text">Было на счете</p>
            <MinusModalIcon className="training-modal__blocks-icon" />
            <p className="training-modal__block-text">потрачено на домик</p>
            <MinusModalIcon className="training-modal__blocks-icon" />
            <p className="training-modal__block-text">потрачено на котиков</p>
            <MinusModalIcon className="training-modal__blocks-icon" />
            <p className="training-modal__block-text">потрачено на корм</p>
            <MinusModalIcon className="training-modal__blocks-icon" />
          </div>
          <div className="training-modal__blocks training-modal__blocks--min-margin">
            <MinusModalIcon className="training-modal__blocks-icon" />
            <p className="training-modal__block-text">коммунальные услуги</p>
            <PlusModalIcon className="training-modal__blocks-icon" />
            <p className="training-modal__block-text">заработано на котятах</p>
            <MinusModalIcon className="training-modal__blocks-icon" />
            <p className="training-modal__block-text">потрачено на лечение</p>
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    colorBg: 'green',
    title: (
      <div>
        <span>ПОЗДРАВЛЯЕМ!</span>
        <br /> <span>ОБУЧЕНИЕ ПО</span> <span className="notranslate"> 11&nbsp;</span>
        <span>ГЛАВЕ ЗАВЕРШЕНО</span>
      </div>
    ),
    message: (
      <div>
        <p className="training-modal__desc">Чтобы перейти на следующий уровень, выполни практическое задание.</p>
        <div className="training-modal__icon-block">
          <LikeModalIcon className="training-modal__icon" />
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
];

export const task = {
  chapter: 11,
  data: { confirmedLotCount: 0 },
  watch: [
    {
      actionName: 'onReceiveMessage__balance',
      checkParams: (params) => {
        if (params[0].current_balance >= 72) {
          return true;
        }
        return 'skip';
      },
    },
    {
      actionName: 'onReceiveMessage__game_over',
      checkParams: (params) => {
        return false;
      },
    },
    {
      actionName: 'onReceiveMessage__trading_lot_update',
      checkParams: (params) => {
        if (
          !RootStore.appStore.ourCats.filter((item) => !RootStore.appStore.isAdultCat(item)).length &&
          RootStore.appStore.balance < 72 &&
          RootStore.appStore.currentSeason === 4
        ) {
          return false;
        }
        return 'skip';
      },
    },
  ],
  title: (
    <div>
      <span className="notranslate">11 </span> <span>ГЛАВА «ПЕРЕГОВОРЫ О ЦЕНЕ» ПРАКТИЧЕСКОЕ ЗАДАНИЕ</span>
    </div>
  ),
  message: (
    <p className="training-modal__desc">
      <span>Практическое задание состоит из</span> <span className="notranslate"> 5 </span> <span>сезонов.</span>
      <br />
      <span>У тебя</span> <span className="notranslate"> 47</span>
      <CoinIcon style={{ position: 'relative', top: '2px' }} />
      <span>. Твоя задача – на конец</span>
      <span className="notranslate"> 5 </span> <span>сезона заработать</span>
      <span className="notranslate"> 72 </span>
      <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span> или больше.</span>
      <br />
      <span>
        Не забывайте просить скидки, но будьте аккуратны! В данном задании придется купить котят и получить потомство.
      </span>
    </p>
  ),
  wrongMessage: (
    <div className="training-modal__price training-modal__price--single">
      <p className="training-modal__price-name">
        <span>Не получилось</span>
        <br />
        <span>заработать</span>
      </p>
      <div className="training-modal__price-value">
        <CoinModalIcon className="training-modal__price-coin" />
        <span className="training-modal__price-cost training-modal__price-cost--white notranslate">72</span>
      </div>
    </div>
  ),
};
