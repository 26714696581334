import Price from './Cat/Price';
import Cat, { BOTTOM_TEXT, CAT_STATE } from './Cat/Cat';
import Modal from './Modal/Modal';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import trainingStore from '../../store/trainingStore';
import { getCatTypeText } from '../../common/texts/catType';
import classNames from '../../common/classNames';
import { draggableOptionsDefault } from './Cat/draggableOptionsDefault';
import corpStore from '../../store/CorpStore';
import { AI_TYPE, DRAG_AREA, PLAYER_ROLE, SPECIALITIES } from '../../common/constants';

function GroupedCatModal() {
  const { appStore } = RootStore;
  const openedGroupedCat = appStore.openedGroupedCat;

  const [sell_price, setSellPrice] = React.useState(0);
  const [buy_price, setBuyPrice] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  useEffect(() => {
    setSellPrice(0);
    setBuyPrice(0);
    setPrice(0);
  }, [!!openedGroupedCat]);

  if (!openedGroupedCat) {
    return null;
  }

  let readonly = openedGroupedCat && openedGroupedCat.price && appStore.currentLotIsOutcoming;
  let disabled = false;
  const fromType = appStore.openedGroupedCatObject?.fromType;
  let catsNotReadOnly = false;
  let sellPriceVision = false;
  let buyPriceVision = false;

  let isChangePriceEnabled = true;
  if (appStore.isTeamMode) {
    isChangePriceEnabled = appStore.hasSpecialities([SPECIALITIES.MARKETEER, SPECIALITIES.CAPTAIN]);
    if (fromType === DRAG_AREA.LOT && appStore.currentPlayer?.lot) {
      const isSupplySpecialist = appStore.hasSpecialities([SPECIALITIES.SUPPLY]);
      const isSalesSpecialist = appStore.hasSpecialities([SPECIALITIES.SALES]);
      if (
        !isChangePriceEnabled &&
        ((isSupplySpecialist && appStore.me.uuid === appStore.currentPlayer.lot.buyer) ||
          (isSalesSpecialist && appStore.me.uuid !== appStore.currentPlayer.lot.buyer))
      ) {
        isChangePriceEnabled = true;
      }
      if (!readonly) {
        readonly = !(
          (isSupplySpecialist && appStore.currentPlayer.lot.buyer === appStore.me.uuid) ||
          (isSalesSpecialist && appStore.currentPlayer.lot.seller === appStore.me.uuid)
        );
      }
    } else {
      if (appStore.hasSpecialities([SPECIALITIES.SUPPLY])) {
        buyPriceVision = true;
      }
      if (appStore.hasSpecialities([SPECIALITIES.SALES])) {
        sellPriceVision = true;
      }
      if (appStore.hasSpecialities([SPECIALITIES.MARKETEER, SPECIALITIES.CAPTAIN])) {
        buyPriceVision = true;
        sellPriceVision = true;
      }
      if (!readonly) {
        readonly = !appStore.hasSpecialities([SPECIALITIES.MARKETEER]);
        catsNotReadOnly = true;
      }
    }
  } else if (appStore.meIsCEO) {
    if (
      fromType === DRAG_AREA.TRANSPORTATION_TOP ||
      fromType === DRAG_AREA.TRANSPORTATION_BOTTOM ||
      fromType === DRAG_AREA.TRANSPORTATION_ZONE
    ) {
      readonly = true;
      disabled = true;
    }
  } else {
    catsNotReadOnly = true;
    sellPriceVision = true;
    buyPriceVision = true;
  }

  if (openedGroupedCat && sell_price === 0 && openedGroupedCat.sell_price) {
    setSellPrice(openedGroupedCat.sell_price);
    setBuyPrice(openedGroupedCat.buy_price);
  }
  if (price === 0 && openedGroupedCat && openedGroupedCat.price) {
    setPrice(openedGroupedCat.price);
  }
  const openedGroupedCatColor =
    openedGroupedCat &&
    appStore.getPriceLotColor({ price, gender: openedGroupedCat.gender, color: openedGroupedCat.color });

  function handleRemoveCatClick(cat) {
    if (fromType === DRAG_AREA.LOT) {
      appStore.removeCatFromLot(cat.contents ? cat.contents[0] : cat);
    } else if (fromType === DRAG_AREA.TRANSPORTATION_ZONE) {
      corpStore.removeCatFromOperationField(cat.contents ? cat.contents[0] : cat);
    } else {
      appStore.dropItem({
        from: fromType,
        data: cat,
        to:
          fromType === DRAG_AREA.TRANSPORTATION_TOP || fromType === DRAG_AREA.TRANSPORTATION_BOTTOM
            ? DRAG_AREA.TRANSPORTATION_ZONE
            : fromType === DRAG_AREA.COMPANY_ZONE
            ? DRAG_AREA.TRANSFER_ZONE
            : DRAG_AREA.LOT,
      });
    }
  }

  function changePrice(price, setState, action, isIncrement = true) {
    let delta = 1;

    if (!isIncrement) {
      delta = -1;
    }

    setState(price + delta);
    trainingStore.checkIsCorrectAction(action);
  }

  function handleSaveLotPriceClick() {
    appStore.changePriceInLot(openedGroupedCat.color, openedGroupedCat.gender, price);
    appStore.setOpenedGroupedCat(null);
  }

  function handleSaveOurPriceClick() {
    appStore.changeOurPrice(openedGroupedCat.color, openedGroupedCat.gender, buy_price, sell_price);
    appStore.setOpenedGroupedCat(null);
  }

  const Content = () => {
    if (!openedGroupedCat || (!openedGroupedCat.contents && !openedGroupedCat.storageContents)) {
      return null;
    }

    const renderedCats = [];
    const addToRenderedCats = (renderedCatsForAdd) => {
      renderedCats.push(...renderedCatsForAdd);
    };

    const contents = openedGroupedCat.contents;
    const existCats = contents.filter((cat) => cat.detail?.cat_id || cat.cat_id);
    const abstractCats = contents.filter((cat) => !cat.detail?.cat_id && !cat.cat_id);
    const hasAbstractCats = !!abstractCats.length;
    const isStorageCat = fromType === DRAG_AREA.STORAGE;
    const isMyStorageCat = fromType === DRAG_AREA.STORAGE_MY_CATS;
    const isNotStorageCat = !isStorageCat && !isMyStorageCat;
    const isDoctorLTDStorageReviewing =
      (appStore.isCorpMode || appStore.isRealTeamMode) &&
      appStore.hasSpecialities(SPECIALITIES.DOCTOR) &&
      appStore.currentDoctorReviewingLTD;

    if (isNotStorageCat) {
      addToRenderedCats(
        existCats.map((cat, index) => {
          return (
            <SwiperSlide key={cat.detail?.cat_id || cat.cat_id || 'index' + index}>
              <Cat
                readonly={readonly && !catsNotReadOnly}
                cat={cat}
                showHouseIcon={appStore.me.role === PLAYER_ROLE.NURSERY}
                state={CAT_STATE.SINGLE}
                bottomText={BOTTOM_TEXT.AGE}
                from={appStore.openedGroupedCatObject.fromType}
                onDoubleClick={() => {
                  if (
                    (appStore.openedGroupedCatObject.fromType === DRAG_AREA.NURSERY ||
                      appStore.openedGroupedCatObject.fromType === DRAG_AREA.SHOP) &&
                    appStore.with_ltd &&
                    appStore.currentPlayer?.ai_type !== AI_TYPE.FIFTH_SHOP &&
                    !appStore.isCorpMode
                  ) {
                    return;
                  }
                  handleRemoveCatClick(cat);
                }}
              />
            </SwiperSlide>
          );
        }),
      );
    }

    if (isMyStorageCat) {
      addToRenderedCats(
        existCats.map((cat, index) => {
          return (
            <SwiperSlide key={cat.cat_id || 'index' + index}>
              <Cat
                cat={cat}
                showHouseIcon={appStore.me.role === PLAYER_ROLE.NURSERY}
                state={CAT_STATE.SINGLE}
                bottomText={BOTTOM_TEXT.AGE}
                from={fromType}
                pinkBackground={appStore.catForceViewSicknessInLTD(cat)}
                readonly={false}
                forceViewSickness={appStore.catForceViewSicknessInLTD(cat)}
                onDoubleClick={() => {
                  if (draggableOptionsDefault.canDrag(cat, DRAG_AREA.STORAGE_MY_CATS)) {
                    appStore.dropItem({ from: fromType, data: cat, to: DRAG_AREA.STORAGE });
                  }
                }}
              />
            </SwiperSlide>
          );
        }),
      );
    }

    if (isStorageCat && (openedGroupedCat.storageContents || isDoctorLTDStorageReviewing)) {
      let openedGroupedCatContent;
      if (appStore.currentDoctorReviewingLTD) {
        openedGroupedCatContent = existCats;
      } else {
        openedGroupedCatContent = openedGroupedCat.storageContents.filter((cat) => cat.cat_id);
      }
      addToRenderedCats(
        openedGroupedCatContent.map((cat, index) => {
          return (
            <SwiperSlide key={cat.cat_id || 'index' + index}>
              <Cat
                cat={cat}
                showHouseIcon={appStore.me.role === PLAYER_ROLE.NURSERY}
                state={CAT_STATE.SINGLE}
                bottomText={BOTTOM_TEXT.AGE}
                from={fromType}
                pinkBackground={appStore.catForceViewSicknessInLTD(cat)}
                readonly={false}
                forceViewSickness={appStore.catForceViewSicknessInLTD(cat)}
                onDoubleClick={() => {
                  if (draggableOptionsDefault.canDrag(cat, DRAG_AREA.STORAGE)) {
                    appStore.dropItem({ from: fromType, data: cat, to: DRAG_AREA.STORAGE_MY_CATS });
                  }
                }}
              />
            </SwiperSlide>
          );
        }),
      );
    }

    if (hasAbstractCats) {
      addToRenderedCats(
        abstractCats.map((cat, index) => {
          return (
            <SwiperSlide key={cat.detail?.cat_id || cat.cat_id || 'index' + index}>
              <Cat
                readonly={readonly}
                cat={cat}
                state={CAT_STATE.ABSTRACT}
                from={appStore.openedGroupedCatObject.fromType}
                onDoubleClick={() => {
                  if (fromType === DRAG_AREA.LOT) {
                    appStore.removeCatFromLot(cat.contents ? cat.contents[0] : cat);
                  }
                }}
              />
            </SwiperSlide>
          );
        }),
      );
    }

    return renderedCats;
  };

  return (
    <Modal
      customClass="grouped-cat"
      fullWidth
      isOpen={!!openedGroupedCat}
      isVisible={!appStore.currentDragCat}
      onClose={() => {
        appStore.setOpenedGroupedCat(null);
      }}
      shouldCloseOnOverlayClick={trainingStore.currentStep?.needOverlayClickModal ?? true}
      title={getCatTypeText(openedGroupedCat)}
    >
      {!!openedGroupedCat && (
        <div
          className={classNames({
            'modal__inner modal__inner--grouped-cat': true,
            'modal__inner--grouped-cat-lot': openedGroupedCat.price,
            'training-z-index': trainingStore.currentStep?.changePrice,
          })}
        >
          {!appStore.currentDoctorReviewingLTD && (
            <div className="modal__price">
              {!appStore.meIsCEO &&
                fromType !== DRAG_AREA.STORAGE_MY_CATS &&
                fromType !== DRAG_AREA.STORAGE &&
                openedGroupedCat.price && (
                  <>
                    {appStore.me.uuid !== appStore.currentPlayer.lot.buyer && (
                      <Price isFake={true} bigSize={true} withoutStopPropagation />
                    )}
                    <div className="modal__price-title">
                      {appStore.me.uuid === appStore.currentPlayer.lot.buyer ? 'Цена покупки' : 'Цена продажи'}
                    </div>
                    <Price
                      readonly={readonly}
                      withoutStopPropagation
                      price={price}
                      bigSize={true}
                      color={openedGroupedCatColor}
                      onMinus={() => setPrice(price - 1)}
                      onPlus={() => setPrice(price + 1)}
                      isFake={!isChangePriceEnabled}
                    />
                    {appStore.me.uuid === appStore.currentPlayer.lot.buyer && (
                      <>
                        <div className="modal__price-title" />
                        <Price isFake={true} bigSize={true} withoutStopPropagation />
                      </>
                    )}
                    <TextButton
                      color="blue"
                      title="сохранить"
                      isDisabled={disabled}
                      onClick={handleSaveLotPriceClick}
                    />
                  </>
                )}
              {(fromType === DRAG_AREA.STORAGE_MY_CATS || fromType === DRAG_AREA.STORAGE) && openedGroupedCat.price && (
                <>
                  <div className="modal__price-title">Цена продажи</div>
                  <Price readonly={true} withoutStopPropagation price={price} bigSize={true} />
                </>
              )}
              {(openedGroupedCat.sell_price ||
                fromType === DRAG_AREA.TRANSPORTATION_ZONE ||
                fromType === DRAG_AREA.TRANSFER_ZONE) && (
                <>
                  <div className="modal__price-title">Цена покупки</div>
                  <Price
                    readonly={readonly}
                    customClass="training-price-buy"
                    withoutStopPropagation
                    price={buy_price}
                    bigSize={true}
                    isFake={!buyPriceVision}
                    onMinus={() => {
                      changePrice(buy_price, setBuyPrice, 'buyPriceMinus', false);
                    }}
                    onPlus={() => {
                      changePrice(buy_price, setBuyPrice, 'buyPricePlus');
                    }}
                  />
                  <div className="modal__price-title">Цена продажи</div>
                  <Price
                    readonly={readonly}
                    customClass="training-price-sell"
                    withoutStopPropagation
                    price={sell_price}
                    bigSize={true}
                    isFake={!sellPriceVision}
                    onMinus={() => {
                      changePrice(sell_price, setSellPrice, 'sellPriceMinus', false);
                    }}
                    onPlus={() => {
                      changePrice(sell_price, setSellPrice, 'sellPricePlus');
                    }}
                  />
                  <TextButton color="blue" title="сохранить" isDisabled={disabled} onClick={handleSaveOurPriceClick} />
                </>
              )}
            </div>
          )}
          {(!!openedGroupedCat.contents?.length || !!openedGroupedCat.storageContents?.length) && (
            <Swiper
              touchStartPreventDefault={false}
              threshold={0}
              touchAngle={40}
              freeModeMomentumRatio={0.2}
              freeModeMomentumVelocityRatio={0.2}
              slidesPerView={'auto'}
              freeMode={true}
              grabCursor={true}
            >
              {Content()}
            </Swiper>
          )}
        </div>
      )}
    </Modal>
  );
}

export default observer(GroupedCatModal);
