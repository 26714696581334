import React from 'react';
import { observer } from 'mobx-react';
import classNames from '../../common/classNames';
import trainingStore from '../../store/trainingStore';

const Timer = ({ customClassName, children, id, ...otherProps }) => {
  return (
    <span
      className={classNames({
        [customClassName]: !!customClassName,
        hidden: trainingStore.launched && trainingStore.currentStep && trainingStore.timerIsHidden(id),
      })}
      id={id}
      {...otherProps}
    >
      {children}
    </span>
  );
};

export default observer(Timer);
