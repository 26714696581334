import { CREDIT_TYPE_NAME, LIMITS_TYPE } from '../../../store/constants';

export function teamLimitTypeText(limitInfo) {
  const teamLimitType = limitInfo.limitType;
  if ([LIMITS_TYPE.GET_CREDIT_BY_TEAM, LIMITS_TYPE.GET_CREDIT].includes(teamLimitType)) {
    return CREDIT_TYPE_NAME[limitInfo.creditType];
  } else if (teamLimitType === LIMITS_TYPE.SPENDING_ON_FOOD) {
    return 'покупка корма';
  } else if (teamLimitType === LIMITS_TYPE.SPENDING_ON_MEDICINES) {
    return 'покупка лекарства';
  } else if (teamLimitType === LIMITS_TYPE.SPENDING_ON_HOUSE) {
    return 'покупка домика';
  } else if (teamLimitType === LIMITS_TYPE.SPENDING_ON_CAT_BUY) {
    return 'траты на покупку котиков';
  } else if (
    [LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_BUY, LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_SELL].includes(
      teamLimitType,
    )
  ) {
    const showCasePriceTypeText =
      teamLimitType === LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_BUY ? 'покупке' : 'продаже';
    return `отклонение от витринных цен при ${showCasePriceTypeText}`;
  }

  return 'необработанный тип';
}

export function currentTaskModalPriceLimitTextsByLimitType(limitType) {
  const texts = ['лимит', 'потрачено', 'итого цена'];
  if (
    [LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_SELL, LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_BUY].includes(
      limitType,
    )
  ) {
    const deviationType = limitType === LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_SELL ? 'продажи' : 'покупки';
    texts[0] = `Витринная цена ${deviationType}`;
    texts[1] = 'Отклонение от витрины';
  } else if ([LIMITS_TYPE.GET_CREDIT, LIMITS_TYPE.GET_CREDIT_BY_TEAM].includes(limitType)) {
    texts[1] = 'Взято кредитов';
    texts[2] = 'Сумма кредита';
  }

  return texts;
}
