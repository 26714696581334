import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import WalletIcon from '../icons/modal/WalletIcon';

function AutoTerminationLTDModal() {
  const { appStore } = RootStore;
  if (!appStore.longTermsDeals.ltd_info) {
    return null;
  }
  return (
    <Modal
      isOpen={!!appStore.autoTerminationLTD}
      onClose={appStore.hideAutoTerminationLTDModal}
      title="Aвторасторжение"
      colorBg="red"
    >
      <div className="modal__body-actions">
        <WalletIcon className="modal__body-icon" />
        <div className="modal__body-price">
          <p className="modal__body-price-text">
            У вас расторглась сделка, не забудьте проверить свой баланс за прошлый сезон
            {appStore.longTermsDeals.ltd_info.length !== 0 && ' и оставшиеся сделки'}
          </p>
        </div>
        <TextButton color="blue" title="Понятно" onClick={appStore.hideAutoTerminationLTDModal} />
      </div>
    </Modal>
  );
}

export default observer(AutoTerminationLTDModal);
