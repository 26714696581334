import React from 'react';

function ArrowLeftIcon2({ style }) {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M25.9917 16.2552C26.0235 23.7297 25.9612 29.9961 25.8533 30.1798C25.4725 30.8298 24.1859 30.4981 21.715 29.1126C14.4141 25.0194 6 18.2568 6 16.4826C6 15.3128 8.75228 12.6479 13.8394 8.89258C18.1792 5.6887 24.13 2.319 25.1146 2.50756C25.886 2.65512 25.9379 3.466 25.9917 16.2552Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default ArrowLeftIcon2;
