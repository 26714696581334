import Modal from '../../Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import { LIMITS_TITLE, LIMITS_TYPE } from '../../../../store/constants';
import RootStore from '../../../../store';
import corpStore from '../../../../store/CorpStore';
import TextButton from '../../../buttons/TextButton/TextButton';
import NumberInput from '../../../NumberInput/NumberInput';
import WalletIcon from '../../../icons/modal/WalletIcon';
import './CurrentSeasonLimitsModal.scss';

function CurrentSeasonLimitsModal() {
  const { appStore } = RootStore;

  if (!appStore.isCorpMode || !corpStore.currentSeasonLimitsModalFlag?.isOpen) {
    return null;
  }

  const modalData = corpStore.currentSeasonLimitsModalFlag;
  if (modalData.onOpen) {
    modalData.onOpen();
  }
  const myLimits = corpStore.teamLimits[appStore.currentSeason];
  const { activeLimitsTypes } = corpStore;
  const onClose = () => {
    corpStore.hideCurrentSeasonLimitsModal();
    if (modalData.onClose) {
      modalData.onClose();
    }
  };
  return (
    <Modal
      isOpen={modalData.isOpen}
      onClose={() => onClose()}
      title="лимиты на текущий сезон"
      titleIcon={<WalletIcon color="#C6A788" />}
      size="small"
    >
      <div className="modal__body-wrapper">
        {activeLimitsTypes.length ? (
          activeLimitsTypes.map((limitType) => {
            let preValueSymbol;
            if (LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_BUY === limitType) {
              preValueSymbol = '+';
            } else if (LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_SELL === limitType) {
              preValueSymbol = '-';
            }
            return (
              <div key={limitType} className="current-season-limit-block">
                <div className="current-season-limit-name">{LIMITS_TITLE[limitType]}</div>
                <NumberInput preValueSymbol={preValueSymbol} value={myLimits[limitType]} readOnly={true} icon={true} />
              </div>
            );
          })
        ) : (
          <div className="current-limits-none">Нет текущих лимитов</div>
        )}
      </div>
      <div className="modal__body-actions">
        <TextButton color="blue" title={modalData.closeButtonText || 'Понятно'} onClick={() => onClose()} />
      </div>
    </Modal>
  );
}

export default observer(CurrentSeasonLimitsModal);
