/**
 * Функция генерирует селекторы для котов, находящихся в контенте LTD
 * @param gendersByColors: { [CAT_COLOR.WHITE]: [CAT_GENDER.FEMALE, CAT_GENDER.MALE] }
 * @param price - boolean, нужен для того, чтобы подсвечивать или не подсвечивать цены
 * @param count - boolean, нужен для того, чтобы подсвечивать или не подсвечивать количество
 * @param contentType - string, тип контента долгосрочной сделки, в которой находиться необходимый кот
 * @return {*[]}
 */
const generateSelectorForLTDMakingCats = ({ gendersByColors, price = true, count = true, contentType }) => {
  const result = [];
  Object.entries(gendersByColors).forEach(([color, genders]) => {
    genders.forEach((gender) => {
      const base = `.ltd-form-cat.ltd-form-cat-gender--${gender}.ltd-form-cat-color--${color}.ltd-form-cat--${contentType}`;
      if (count) {
        result.push(base + ' .ltd-form-cat__img-count');
      }
      if (price) {
        result.push(base + ' .ltd-form-cat__price');
      }
    });
  });
  return result;
};

export default generateSelectorForLTDMakingCats;
