import React from 'react';

function LikeModalIcon({ style, stylePath, className }) {
  return (
    <svg
      className={className}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.8671 16.7054C36.0152 10.0434 39.4091 4.15889 39.4091 3.62792C39.4091 1.26464 45.2256 2.89673 47.5799 5.92134C49.8587 8.84877 50.0479 10.1146 49.441 18.3659L48.7646 27.5511H61.7066C73.3978 27.5511 74.9085 27.8 77.3347 30.1277C80.6844 33.3431 80.9176 39.39 77.8022 42.27C75.7415 44.1758 75.7137 44.548 77.4042 47.5584C79.864 51.9341 78.5379 56.6951 74.0904 59.4578C71.9845 60.7662 71.0551 61.9822 71.8011 62.4539C73.9814 63.8299 73.2098 70.4919 70.5353 73.4016C68.0709 76.0801 67.5011 76.1441 46.169 76.1441H24.3252V52.4806V28.8158L31.8671 16.7054Z"
        fill="#FDF6EE"
      />
      <path
        d="M0.44272 59.7244C0.854626 51.4814 1.49738 41.4037 1.871 37.329L2.54987 29.9215H9.95599H17.3634V53.7108V77.5L10.6917 76.7248C-1.28032 75.3346 -0.4101 76.7793 0.44272 59.7244Z"
        fill="#FDF6EE"
      />
    </svg>
  );
}

export default LikeModalIcon;
