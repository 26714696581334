import React from 'react';

const AttentionIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.0666 18.0213C21.2452 11.1905 19.3214 7.69228 15.5612 1.78936C14.9192 0.669073 13.7854 0 12.5284 0C11.2714 0 10.1376 0.669073 9.49566 1.78936C9.49082 1.79793 9.48673 1.8065 9.48189 1.81507C5.89384 7.70528 3.95344 11.1155 1.00474 17.9957C0.338246 19.5502 0.33662 20.5133 0.970961 21.6701C1.60676 22.8275 2.75869 23.0563 4.03678 23.518C10.6533 24.2079 14.3568 24.1117 20.9731 23.518C22.6758 23.0563 23.4502 22.8275 24.0859 21.6701C24.7202 20.5134 24.5547 19.2031 24.0666 18.0213ZM11.1178 7.43231C11.1178 6.62465 11.7493 5.96999 12.5284 5.96999C13.3075 5.96999 13.939 6.6247 13.939 7.43231V13.2816C13.939 14.0892 13.3075 14.744 12.5284 14.744C11.7493 14.744 11.1178 14.0892 11.1178 13.2816V7.43231ZM12.5284 20.5933C11.3616 20.5933 10.4124 19.6094 10.4124 18.3998C10.4124 17.1903 11.3616 16.2063 12.5284 16.2063C13.6952 16.2063 14.6444 17.1903 14.6444 18.3998C14.6444 19.6093 13.6952 20.5933 12.5284 20.5933Z"
        fill="#C70000"
      />
    </svg>
  );
};

export default AttentionIcon;
