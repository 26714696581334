import React from 'react';
import { observer } from 'mobx-react';
import Modal from '../Modal/Modal';
import RootStore from '../../../store';
import TextButton from '../../buttons/TextButton/TextButton';
import * as teamMode from '../../../store/teamMode';
import { LocalStorage } from '../../../common/localStorage';
import { CORP_DELEGATING_PRIVILEGES, TASK_TYPES } from '../../../store/constants';
import _ from 'lodash';
import { SPECIALITIES, SPECIALITIES_RUSSIAN_NAME } from '../../../common/constants';

const TEAM_DELEGATING_NOTIFICATION_TITLES = {
  YOU_HAVE_BEEN_DELEGATED: 'Вам делегировано согласование',
  YOU_HAVE_BEEN_REVOKED: 'У вас отозвано согласование',
  YOU_ARE_GIVEN_THE_ABILITY_TO_DELEGATE_TO_TEAM: 'Вам дана возможность делегировать на команду',
  YOU_ARE_REVOKED_THE_ABILITY_TO_DELEGATE_TO_TEAM: 'У вас отозвана возможность делегировать на команду',
};

function TeamDelegationNotificationsModal({}) {
  const { appStore } = RootStore;
  const teamDelegationNotificationsModalData = appStore.teamDelegationNotificationsModalData;
  if (!teamDelegationNotificationsModalData) {
    return null;
  }
  const isSetPrivileges = teamDelegationNotificationsModalData.set_privileges.length > 0;
  const changedPrivilegesMass = isSetPrivileges
    ? teamDelegationNotificationsModalData.set_privileges
    : teamDelegationNotificationsModalData.remove_privileges;
  const isItFullSpecialty =
    changedPrivilegesMass.length > 2 &&
    changedPrivilegesMass.find((privilegesContent) => {
      const priv = privilegesContent[1];
      return (
        priv === 'approve_task_by_captain' ||
        priv === 'create_sale_task' ||
        priv === 'create_purchase_task' ||
        priv === 'approve_purchase_task_by_market' ||
        priv === 'approve_by_doctor'
      );
    });
  let title = '';
  if (isItFullSpecialty) {
    const specialtyDelegationName = teamMode.PRIVILEGES_DATA[isItFullSpecialty[1].toUpperCase()].isMainForSpeciality;
    if (
      appStore.playerByRealSpeciality(SPECIALITIES.CAPTAIN)?.player_id === LocalStorage.USER_UID &&
      specialtyDelegationName === SPECIALITIES.CAPTAIN
    ) {
      title = 'Вам добавлена роль капитана';
    } else {
      title = `${isSetPrivileges ? 'Вам добавлена роль' : 'У вас убрана роль'} ${
        SPECIALITIES_RUSSIAN_NAME[specialtyDelegationName]
      }а`;
    }
  } else {
    let changedPrivilege;
    if (appStore.isCorpMode && appStore.hasSpecialities(SPECIALITIES.CAPTAIN) && isSetPrivileges) {
      changedPrivilege = _.last(changedPrivilegesMass)[1];
    } else {
      changedPrivilege = changedPrivilegesMass[0][1];
    }
    const multiplePrivileges = changedPrivilegesMass.length > 1;
    if (changedPrivilege === teamMode.PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CAPTAIN) {
      title = isSetPrivileges ? 'Вам делегировано изменение витринных цен' : 'у Вас отозвано изменение витринных цен';
    } else if (
      changedPrivilege === teamMode.PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN &&
      appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER)?.player_id === LocalStorage.USER_UID
    ) {
      title = isSetPrivileges
        ? 'Вам делегировано визирование продаж котят городу без согласования с капитаном'
        : 'у Вас отозвано визирование продаж котят городу без согласования с капитаном';
    } else if (changedPrivilege === teamMode.PRIVILEGES.CAN_DO_TO_REVISION) {
      title = isSetPrivileges
        ? 'Вам делегирована отправка сделок на доработку'
        : 'у Вас отозвана отправка сделок на доработку';
    } else if (
      multiplePrivileges &&
      appStore.playerByRealSpeciality(SPECIALITIES.SALES)?.player_id === LocalStorage.USER_UID &&
      [
        teamMode.PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN,
        teamMode.PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CEO,
      ].includes(changedPrivilegesMass[1][1])
    ) {
      title = isSetPrivileges ? 'Вам делегирована продажа котят городу' : 'у Вас отозвана продажа котят городу';
    } else if (
      !multiplePrivileges &&
      changedPrivilege === teamMode.PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CAPTAIN &&
      appStore.playerByRealSpeciality(SPECIALITIES.SALES)?.player_id === LocalStorage.USER_UID
    ) {
      title = !isSetPrivileges ? 'у Вас отозвана продажа котят городу' : '';
    } else if (changedPrivilege === teamMode.PRIVILEGES.CANCEL_SELL_REQUEST_BY_CAPTAIN) {
      title = isSetPrivileges
        ? 'Вам делегирована отмена собственной заявки'
        : 'у Вас отозвана отмена собственной заявки';
    } else if (
      changedPrivilege === teamMode.PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CAPTAIN &&
      appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY)?.player_id === LocalStorage.USER_UID
    ) {
      title = isSetPrivileges ? 'Вам делегирована покупка котят у города' : 'у Вас отозвана покупка котят у города';
    } else if (changedPrivilege === teamMode.PRIVILEGES.CANCEL_BUY_REQUEST_BY_CAPTAIN) {
      title = isSetPrivileges
        ? 'Вам делегирована отмена собственной заявки'
        : 'у Вас отозвана отмена собственной заявки';
    } else if (changedPrivilege === teamMode.PRIVILEGES.APPROVE_INSURANCE_PURCHASE_BY_CAPTAIN) {
      title = isSetPrivileges ? 'Вам делегировано страхование домиков' : 'у Вас отозвано страхование домиков';
    } else if (changedPrivilege === teamMode.PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CAPTAIN) {
      title = isSetPrivileges ? 'Вам делегирована покупка корма' : 'у Вас отозвана покупка корма';
    } else if (changedPrivilege === teamMode.PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CAPTAIN) {
      title = isSetPrivileges ? 'Вам делегирована покупка домиков' : 'у Вас отозвана покупка домиков';
    } else if (changedPrivilege === teamMode.PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CAPTAIN) {
      title = isSetPrivileges ? 'Вам делегирована покупка лекарств' : 'у Вас отозвана покупка лекарств';
    } else if (changedPrivilege === teamMode.PRIVILEGES.APPROVE_MATING_BY_CAPTAIN) {
      title = isSetPrivileges ? 'Вам делегировано скрещивание котиков' : 'у Вас отозвано скрещивание котиков';
    } else if (
      changedPrivilegesMass.some((privilegeMass) =>
        [teamMode.PRIVILEGES.GET_CREDIT, teamMode.PRIVILEGES.CREDIT_REPAY].includes(privilegeMass[1]),
      )
    ) {
      title =
        (isSetPrivileges ? 'Вам делегирована возможность' : 'У вас отозвана возможность') +
        ' брать и досрочно погашать кредит';
    } else if (
      multiplePrivileges &&
      appStore.isCorpMode &&
      changedPrivilegesMass.find((privilegeInfo) => {
        return teamMode.PRIVILEGES.APPROVE_GET_CREDIT_BY_CEO === privilegeInfo[1];
      }) &&
      changedPrivilegesMass.find((privilegeInfo) => {
        return teamMode.PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CEO === privilegeInfo[1];
      })
    ) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_DELEGATED
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_REVOKED) + ' взятия и досрочного погашения кредита';
    } else if (appStore.isCorpMode && changedPrivilege === teamMode.PRIVILEGES.APPROVE_GET_CREDIT_BY_CEO) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_DELEGATED
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_REVOKED) + ' взятия кредита';
    } else if (appStore.isCorpMode && changedPrivilege === teamMode.PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CEO) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_DELEGATED
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_REVOKED) + ' досрочного погашения кредита';
    } else if (appStore.isCorpMode && changedPrivilege === teamMode.PRIVILEGES.APPROVE_MATING_BY_CEO) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_DELEGATED
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_REVOKED) + ' скрещивания котиков';
    } else if (appStore.isCorpMode && changedPrivilege === teamMode.PRIVILEGES.APPROVE_SHOWCASE_PRICES_BY_CEO) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_DELEGATED
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_REVOKED) + ' изменения цен на витрине';
    } else if (appStore.isCorpMode && changedPrivilege === teamMode.PRIVILEGES.APPROVE_HOUSE_PURCHASE_BY_CEO) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_DELEGATED
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_REVOKED) + ' покупки домиков';
    } else if (appStore.isCorpMode && changedPrivilege === teamMode.PRIVILEGES.APPROVE_DRUGS_PURCHASE_BY_CEO) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_DELEGATED
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_REVOKED) + ' покупки лекарств';
    } else if (appStore.isCorpMode && changedPrivilege === teamMode.PRIVILEGES.APPROVE_FOOD_PURCHASE_BY_CEO) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_DELEGATED
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_REVOKED) + ' покупки корма';
    } else if (appStore.isCorpMode && teamMode.PRIVILEGES.APPROVE_CATS_PURCHASE_BY_CEO === changedPrivilege) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_DELEGATED
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_REVOKED) + ' покупки котиков';
    } else if (appStore.isCorpMode && teamMode.PRIVILEGES.APPROVE_CATS_SELL_BY_CEO === changedPrivilege) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_DELEGATED
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_REVOKED) + ' продажи котиков';
    } else if (appStore.isCorpMode && teamMode.PRIVILEGES.APPROVE_CITY_BUY_TASK_BY_CEO === changedPrivilege) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_DELEGATED
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_REVOKED) + ' покупки котиков из города';
    } else if (appStore.isCorpMode && teamMode.PRIVILEGES.APPROVE_CITY_SELL_TASK_BY_CEO === changedPrivilege) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_DELEGATED
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_HAVE_BEEN_REVOKED) + ' продажи котиков в город';
    } else if (appStore.isCorpMode && changedPrivilege === CORP_DELEGATING_PRIVILEGES.CATS_SELL_TO_CITY) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_GIVEN_THE_ABILITY_TO_DELEGATE_TO_TEAM
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_REVOKED_THE_ABILITY_TO_DELEGATE_TO_TEAM) +
        ' продажу котиков в город';
    } else if (appStore.isCorpMode && changedPrivilege === CORP_DELEGATING_PRIVILEGES.CATS_BUY_FROM_CITY) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_GIVEN_THE_ABILITY_TO_DELEGATE_TO_TEAM
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_REVOKED_THE_ABILITY_TO_DELEGATE_TO_TEAM) +
        ' покупку котиков из города';
    } else if (
      multiplePrivileges &&
      appStore.isCorpMode &&
      changedPrivilegesMass.find((privilegeInfo) => {
        return [
          CORP_DELEGATING_PRIVILEGES[TASK_TYPES.GET_CREDIT],
          teamMode.PRIVILEGES.APPROVE_GET_CREDIT_BY_CEO,
        ].includes(privilegeInfo[1]);
      }) &&
      changedPrivilegesMass.find((privilegeInfo) => {
        return [
          CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREDIT_REPAY],
          teamMode.PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CEO,
        ].includes(privilegeInfo[1]);
      })
    ) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_GIVEN_THE_ABILITY_TO_DELEGATE_TO_TEAM
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_REVOKED_THE_ABILITY_TO_DELEGATE_TO_TEAM) +
        ' взятие и досрочное погашение кредита';
    } else if (appStore.isCorpMode && changedPrivilege === CORP_DELEGATING_PRIVILEGES[TASK_TYPES.GET_CREDIT]) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_GIVEN_THE_ABILITY_TO_DELEGATE_TO_TEAM
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_REVOKED_THE_ABILITY_TO_DELEGATE_TO_TEAM) + ' взятие кредита';
    } else if (appStore.isCorpMode && changedPrivilege === CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREDIT_REPAY]) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_GIVEN_THE_ABILITY_TO_DELEGATE_TO_TEAM
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_REVOKED_THE_ABILITY_TO_DELEGATE_TO_TEAM) +
        ' досрочное погашение кредита';
    } else if (appStore.isCorpMode && changedPrivilege === CORP_DELEGATING_PRIVILEGES[TASK_TYPES.MATING]) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_GIVEN_THE_ABILITY_TO_DELEGATE_TO_TEAM
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_REVOKED_THE_ABILITY_TO_DELEGATE_TO_TEAM) +
        ' скрещивание котиков';
    } else if (
      appStore.isCorpMode &&
      changedPrivilege === CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CHANGE_SHOWCASE_PRICES]
    ) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_GIVEN_THE_ABILITY_TO_DELEGATE_TO_TEAM
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_REVOKED_THE_ABILITY_TO_DELEGATE_TO_TEAM) +
        ' изменение цен на витринах';
    } else if (appStore.isCorpMode && changedPrivilege === CORP_DELEGATING_PRIVILEGES[TASK_TYPES.BUY_HOUSE]) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_GIVEN_THE_ABILITY_TO_DELEGATE_TO_TEAM
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_REVOKED_THE_ABILITY_TO_DELEGATE_TO_TEAM) + ' покупку домиков';
    } else if (appStore.isCorpMode && changedPrivilege === CORP_DELEGATING_PRIVILEGES[TASK_TYPES.BUY_DRUGS]) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_GIVEN_THE_ABILITY_TO_DELEGATE_TO_TEAM
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_REVOKED_THE_ABILITY_TO_DELEGATE_TO_TEAM) + ' покупку лекарств';
    } else if (appStore.isCorpMode && changedPrivilege === CORP_DELEGATING_PRIVILEGES[TASK_TYPES.BUY_FOOD]) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_GIVEN_THE_ABILITY_TO_DELEGATE_TO_TEAM
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_REVOKED_THE_ABILITY_TO_DELEGATE_TO_TEAM) + ' покупку корма';
    } else if (
      changedPrivilege === teamMode.PRIVILEGES.PUSH_TO_CAPTAIN &&
      appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER)?.player_id === LocalStorage.USER_UID
    ) {
      title = !isSetPrivileges
        ? 'Вам делегировано визирование цен в сделках'
        : 'у Вас отозвано визирование цен в сделках';
    } else if (
      changedPrivilege === teamMode.PRIVILEGES.CITY_SELL_PUSH_TO_CAPTAIN &&
      !multiplePrivileges &&
      appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER)?.player_id === LocalStorage.USER_UID
    ) {
      title = !isSetPrivileges
        ? 'Вам делегировано визирование продаж котят городу'
        : 'у Вас отозвано визирование продаж котят городу';
    } else if (
      changedPrivilege === teamMode.PRIVILEGES.APPROVE_GET_CREDIT_BY_CAPTAIN &&
      appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER)?.player_id === LocalStorage.USER_UID
    ) {
      title = !isSetPrivileges ? 'У вас отозвано взятие кредита' : 'Вам делегировано взятие кредита';
    } else if (
      changedPrivilege === teamMode.PRIVILEGES.APPROVE_CREDIT_REPAY_BY_CAPTAIN &&
      appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER)?.player_id === LocalStorage.USER_UID
    ) {
      title = !isSetPrivileges
        ? 'У вас отозвано досрочное погашение кредита'
        : 'Вам делегировано досрочное погашение кредита';
    } else if (changedPrivilege === teamMode.PRIVILEGES.CREATE_SALE_TASK) {
      title = !isSetPrivileges
        ? 'У вас отозвано право продажи котят питомникам'
        : 'Вам делегировано право продажи котят питомникам';
    } else if (changedPrivilege === teamMode.PRIVILEGES.CREATE_PURCHASE_TASK) {
      title = !isSetPrivileges
        ? 'У вас отозвано право покупки котят у питомников'
        : 'Вам делегировано право покупки котят у питомников';
    } else if (
      changedPrivilege === teamMode.PRIVILEGES.CREATE_LTD_TASK &&
      appStore.playerByRealSpeciality(SPECIALITIES.SUPPLY)?.player_id === LocalStorage.USER_UID
    ) {
      title = !isSetPrivileges
        ? `У вас отозвано право заключения долгосрочной сделки на покупку`
        : 'Вам делегировано право заключения долгосрочной сделки на покупку';
    } else if (
      changedPrivilege === teamMode.PRIVILEGES.CREATE_LTD_TASK &&
      appStore.playerByRealSpeciality(SPECIALITIES.SALES)?.player_id === LocalStorage.USER_UID
    ) {
      title = !isSetPrivileges
        ? `У вас отозвано право заключения долгосрочной сделки на продажу`
        : 'Вам делегировано право заключения долгосрочной сделки на продажу';
    } else if (
      (changedPrivilege === teamMode.PRIVILEGES.APPROVE_LTD_TASK_BY_CAPTAIN ||
        changedPrivilege === teamMode.PRIVILEGES.APPROVE_LTD_TASK) &&
      appStore.playerByRealSpeciality(SPECIALITIES.CAPTAIN)?.player_id === LocalStorage.USER_UID
    ) {
      title = !isSetPrivileges
        ? 'У вас отозвано право на заключение долгосрочной сделки'
        : 'Вам делегировано право на заключение долгосрочных сделок';
    } else if (
      changedPrivilege === CORP_DELEGATING_PRIVILEGES[TASK_TYPES.CREATE_CHANGE_LTD_REQUEST] &&
      appStore.playerByRealSpeciality(SPECIALITIES.CAPTAIN)?.player_id === LocalStorage.USER_UID
    ) {
      title =
        (isSetPrivileges
          ? TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_GIVEN_THE_ABILITY_TO_DELEGATE_TO_TEAM
          : TEAM_DELEGATING_NOTIFICATION_TITLES.YOU_ARE_REVOKED_THE_ABILITY_TO_DELEGATE_TO_TEAM) +
        ' создание долгосрочных сделок';
    } else if (
      changedPrivilege === teamMode.PRIVILEGES.LTD_PAYMENT_ACTION_BY_MARKET &&
      appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER)?.player_id === LocalStorage.USER_UID
    ) {
      title = !isSetPrivileges
        ? `У вас отозвано право на оплату долгосрочных сделок`
        : 'Вам делегировано право на оплату долгосрочных сделок';
    } else if (
      changedPrivilege === teamMode.PRIVILEGES.PUSH_LTD_TO_CAPTAIN &&
      appStore.playerByRealSpeciality(SPECIALITIES.MARKETEER)?.player_id === LocalStorage.USER_UID
    ) {
      title = isSetPrivileges
        ? `У вас отозвано право на взаимодействие с долгосрочными сделками`
        : 'Вам делегировано право на взаимодействие с долгосрочными сделками';
    } else {
      appStore.hideTeamDelegationNotificationsModal();
    }
  }
  return (
    <Modal
      isOpen={!!appStore.teamDelegationNotificationsModalData}
      onClose={appStore.hideTeamDelegationNotificationsModal}
      title={title}
    >
      <div className="modal__body-actions">
        <TextButton
          color="purple"
          title="Посмотреть"
          onClick={() => {
            appStore.hideTeamDelegationNotificationsModal();
            appStore.openDelegatingModal();
          }}
        />
        <TextButton color="blue" title="Понятно" onClick={appStore.hideTeamDelegationNotificationsModal} />
      </div>
    </Modal>
  );
}

export default observer(TeamDelegationNotificationsModal);
