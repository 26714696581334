import React from 'react';

function HideModalIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 0H21C22.654 0 24 1.34601 24 3V21C24 22.654 22.654 24 21 24H11.25V21.9999H21C21.5521 21.9999 21.9999 21.5521 21.9999 21V3C21.9999 2.44794 21.5521 2.00006 21 2.00006H3C2.44794 2.00006 2.00006 2.44794 2.00006 3V12.75H0V3C0 1.34601 1.34601 0 3 0Z"
        fill="#C6A788"
      />
      <path d="M3 24C1.34601 24 0 22.654 0 21V15H2.00006H7.5C8.328 15 9 15.672 9 16.5V21.9999V24H3Z" fill="#C6A788" />
      <path
        d="M10.9998 14H16.9998C17.4037 14 17.7698 13.757 17.9238 13.3829C18.0787 13.0089 17.9928 12.5789 17.7068 12.2929L15.4138 9.99988L19.7067 5.70697C20.0978 5.31586 20.0978 4.68396 19.7067 4.29303C19.5118 4.09802 19.2557 3.99988 18.9997 3.99988C18.7437 3.99988 18.4877 4.09802 18.2927 4.29303L13.9998 8.58594L11.7068 6.29291C11.5158 6.10193 11.2598 5.99994 10.9998 5.99994C10.8707 5.99994 10.7407 6.02393 10.6168 6.07593C10.2427 6.22992 9.99969 6.59595 9.99969 6.99988V12.9999C9.99969 13.5529 10.4468 14 10.9998 14Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default HideModalIcon;
