import React from 'react';
import { observer } from 'mobx-react';
import corpStore from '../../store/CorpStore';
import useWindow from '../../ui/hooks/useWindow';
import RootStore from '../../store';

function Corporation() {
  const { isMobile } = useWindow();

  return (
    <div className="corporation__wrapper">
      <div
        className={`corporation__img corporation__img--${RootStore.appStore.currentSeasonType}`}
        onClick={() => corpStore.openCorporationModal()}
      />
      {isMobile && <span className="corporation__name">Корпорация</span>}
    </div>
  );
}

export default observer(Corporation);
