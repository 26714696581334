import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarLeafImage from '../../../assets/nurseryAvatars/avatarLeaf.png';

function AvatarLeaf({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <path
    //     d="M23.0056 8C23.0056 8 21.3601 9.59647 19.5805 10.2401C6.40166 15.0069 11.9597 24.2961 12.1411 24.3267C12.1411 24.3267 12.8351 22.9007 13.8333 22.1667C20.168 17.5098 20.6591 11.9161 21.5005 12.2885C22.1667 12.5833 20.079 18.7387 14.4434 22.7522C13.1987 23.6382 12.3559 25.8197 11.9991 28C11.9991 28 12.8814 27.6435 13.2608 27.547C13.4088 26.5815 13.7181 25.657 14.2411 24.8311C22.1175 25.7689 24.6972 19.4221 25.0342 17.2091C25.8297 11.9823 23.0056 8 23.0056 8Z"
    //     fill="#02516C"
    //   />
    // </svg>
    <Avatar style={style} avatarImage={AvatarLeafImage} />
  );
}

export default AvatarLeaf;
