import { observer } from 'mobx-react';
import React from 'react';
import Modal from '../Modal/Modal';
import RootStore from '../../../store';
import TextButton from '../../buttons/TextButton/TextButton';

export const OnlyLtdAllowedModal = observer(() => {
  const { appStore } = RootStore;

  return (
    <Modal
      onClose={appStore.closeOnlyLtdAllowedModal}
      isOpen={appStore.onlyLtdAllowedModal}
      title="В этой игре можно использовать только долгосрочные сделки"
      colorBg="red"
    >
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.closeOnlyLtdAllowedModal} />
      </div>
    </Modal>
  );
});
