import React, { useEffect, useRef } from 'react';
import { SliderArrow } from '../../ui/icons';
import Slider from 'react-slick';
import { observer } from 'mobx-react';
import classNames from '../../common/classNames';
import './style.scss';

const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => <span {...props}>{children}</span>;

function SliderComponent({
  sliderCustomClass,
  sliderItemCustomClass,
  labels,
  theme,
  buttons,
  icon,
  afterChange,
  currentSlideIndex,
  customSettings = {},
}) {
  const slider = useRef(null);

  useEffect(() => {
    if (slider.current && _.isNumber(currentSlideIndex)) {
      slider.current.slickGoTo(currentSlideIndex);
    }
  }, [currentSlideIndex]);

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentSlideIndex,
    arrows: true,
    focusOnSelect: false,
    afterChange: (current) => {
      afterChange(current);
    },
    nextArrow: (
      <SlickButtonFix>
        <SliderArrow />{' '}
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <SliderArrow position="left" />
      </SlickButtonFix>
    ),
    ...customSettings,
  };

  const slides = labels.map((label) => {
    let Img;
    if (icon && icon(labels.indexOf(label)).isVisible) {
      Img = icon(labels.indexOf(label)).isVisible() && icon(labels.indexOf(label)).img;
    } else if (icon) {
      Img = icon(labels.indexOf(label)).img;
    }
    return (
      <div
        key={Math.random()}
        className={classNames({
          slider__item: true,
          ['slider__item--with-icon']: !!icon || !!buttons,
          [sliderItemCustomClass]: !!sliderItemCustomClass,
        })}
      >
        {Img && Img}
        <p className="slider__item-label">{label}</p>

        {buttons &&
          buttons.map((buttonInfo) => {
            if (buttonInfo.isVisible()) {
              return (
                <div key={Math.random()} className="slider__item-button">
                  {buttonInfo.img}
                </div>
              );
            }
          })}
      </div>
    );
  });
  return (
    <Slider
      {...sliderSettings}
      ref={slider}
      className={classNames({
        slider: true,
        [`slider--${theme}-theme`]: !!theme,
        [sliderCustomClass]: !!sliderCustomClass,
      })}
    >
      {slides}
    </Slider>
  );
}

export default observer(SliderComponent);
