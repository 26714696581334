import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import * as teamMode from '../../store/teamMode';
import TextButton from '../buttons/TextButton/TextButton';
import { CatFaceIcon } from '../icons';

function CaptainTimedOutModal() {
  const { appStore } = RootStore;

  if (!appStore.captainTimedOutModalShowed) {
    return null;
  }

  return (
    <Modal
      isOpen={!!appStore.captainTimedOutModalShowed}
      onClose={appStore.captainTimedOutModalClose}
      title={`КАПИТАН ПОКИНУЛ ИГРУ`}
    >
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.captainTimedOutModalClose} />
      </div>
    </Modal>
  );
}

export default observer(CaptainTimedOutModal);
