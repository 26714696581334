import React, { useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import './style.scss';
import { LocalStorage } from '../../../common/localStorage';
import corpStore from '../../../store/CorpStore';
import ReactSelect from '../../Select/Select';
import classNames from '../../../common/classNames';
import TextButton from '../../buttons/TextButton/TextButton';
import { Checkbox } from '../Checkbox';
import NumberInput from '../../NumberInput/NumberInput';
import _ from 'lodash';
import { CAT_COLOR, CAT_GENDER, SICKNESS, SPECIALITIES } from '../../../common/constants';

const TestFieldsTypes = {
  PLAYERS: {
    title: 'Игроки',
    type: 'players',
    validate: () => RootStore.appStore.isTeamMode,
  },
  SPAWN_CATS: {
    title: 'Спаун котов',
    type: 'spawn_cats',
  },
  SELL_CATS_TO_CITY: {
    title: 'Продажа котят городу',
    type: 'sell_cats_to_city',
    validate: () => RootStore.appStore.isCorpMode,
  },
  BUY_CATS_FROM_CITY: {
    title: 'Покупка котят у города',
    type: 'buy_cats_from_city',
    validate: () => RootStore.appStore.isCorpMode,
  },
  REQUESTS_TO_SELL_CATS_TO_CITY: {
    title: 'Запросы на продажу котиков в город',
    type: 'requests_to_sell_cats_to_city',
  },
};

function TeamModeTester() {
  const { appStore } = RootStore;
  const [opened, setOpen] = useState(false);
  const [openedParameters, setOpenedParameters] = useState([TestFieldsTypes.PLAYERS.type]);
  const specialtyMass = Object.values(SPECIALITIES);
  const companies = appStore.players.filter((item) => item.ai_type === 'human');

  function getCurrentSpecialty() {
    return (
      appStore.company_players.find((playerInfo) => {
        return playerInfo.player_id === LocalStorage.USER_UID;
      })?.specialty || 'captain'
    );
  }

  function clearStoragePlayerInfo() {
    localStorage.removeItem('PLAYER_ROLE');
    localStorage.removeItem('IS_PLAYER_CEO');
  }

  function getSpecialtyLink(specialtyName) {
    const specialtyId =
      appStore.company_players.find((playerInfo) => {
        return playerInfo.specialty === specialtyName;
      })?.player_id || '00000000-0000-0000-0000-000000000001';
    return `${location.origin}/play/${LocalStorage.GAME_UID}/${specialtyId}?server=${LocalStorage.JOIN_URL}`;
  }

  const SpawnCat = () => {
    const age = [0, 1, 2, 3];
    const [parametersCat, setParametersCat] = useState({
      color: CAT_COLOR.BLACK,
      gender: CAT_GENDER.MALE,
      sickness: SICKNESS.NONE,
      age: age[0],
      quantity: 0,
    });

    const changeParametersCat = (parameter, value) => {
      if (parameter === 'color') {
        setParametersCat({ ...parametersCat, color: value });
      }
      if (parameter === 'gender') {
        setParametersCat({ ...parametersCat, gender: value });
      }
      if (parameter === 'sickness') {
        setParametersCat({ ...parametersCat, sickness: value });
      }
      if (parameter === 'age') {
        setParametersCat({ ...parametersCat, age: value });
      }
      if (parameter === 'quantity') {
        setParametersCat({ ...parametersCat, quantity: value });
      }
    };

    const getOptions = (parameter) => {
      return Object.values(
        parameter === 'color'
          ? CAT_COLOR
          : parameter === 'gender'
          ? CAT_GENDER
          : parameter === 'sickness'
          ? SICKNESS
          : parameter === 'age'
          ? age
          : [],
      ).map((cat) => {
        return {
          value: cat,
          key: cat,
          label: cat,
        };
      });
    };

    return (
      <div className="team-mode-tester__spawn-cat">
        <span className="team-mode-tester__text">Спаун котов</span>
        <div className="team-mode-tester__spawn-cat-selects">
          {Object.keys(parametersCat)
            .filter((parameter) => parameter !== 'quantity')
            .map((parameter) => {
              return (
                <div key={parameter}>
                  <span>{parameter}</span>
                  <ReactSelect
                    className="team-mode-tester__select"
                    value={{ value: parametersCat[parameter], label: parametersCat[parameter] }}
                    options={getOptions(parameter)}
                    onChange={(event) => changeParametersCat(parameter, event.value)}
                  />
                </div>
              );
            })}
          <div>
            quantity
            <NumberInput icon={false} onChange={(value) => changeParametersCat('quantity', value)} />
          </div>
        </div>
        <TextButton title="Добавить котенка" color="green" onClick={() => corpStore.addCatForDev(parametersCat)} />
      </div>
    );
  };

  const BuyCatsFromCity = () => {
    const [parameters, setParameters] = useState({
      color: CAT_COLOR.BLACK,
      gender: CAT_GENDER.MALE,
      count: 0,
    });

    const changeParametersCat = (parameter, value) => {
      setParameters({ ...parameters, [parameter]: value });
    };

    const getOptions = (parameter) => {
      return Object.values(
        parameter === 'color' ? CAT_COLOR : parameter === 'gender' ? CAT_GENDER : parameter === 'age' ? age : [],
      ).map((cat) => {
        return {
          value: cat,
          key: cat,
          label: cat,
        };
      });
    };

    const buyCats = () => {
      const price = _.find(appStore.cityQuota, { gender: parameters.gender, color: parameters.color }).sell_price;
      const contents = [{ price: price, color: parameters.color, gender: parameters.gender, count: parameters.count }];
      appStore.socket.send(
        JSON.stringify({
          msg_type: 'actions_on_task',
          task_id: null,
          actions: [
            {
              act_name: 'create_city_purchase_task',
              params: {
                request_type: 'buy',
                contents,
                visa: true,
                to_revision: false,
              },
            },
            {
              act_name: 'approve_city_buy_task_by_captain',
              params: {
                request_type: 'buy',
                contents,
                visa: true,
                to_revision: false,
              },
            },
            {
              act_name: 'approve_city_buy_task_by_ceo',
              params: {
                request_type: 'buy',
                contents,
                visa: true,
                to_revision: false,
              },
            },
          ],
        }),
      );
    };

    return (
      <div className="team-mode-tester__spawn-cat">
        <span className="team-mode-tester__text">Покупка котов у города</span>
        <div className="team-mode-tester__spawn-cat-selects">
          {Object.keys(parameters)
            .slice(0, 2)
            .map((parameter) => {
              return (
                <div key={parameter}>
                  <span>{parameter}</span>
                  <ReactSelect
                    className="team-mode-tester__select"
                    value={{ value: parameters[parameter], label: parameters[parameter] }}
                    options={getOptions(parameter)}
                    onChange={(event) => changeParametersCat(parameter, event.value)}
                  />
                </div>
              );
            })}
          <div>
            count
            <NumberInput icon={false} onChange={(value) => changeParametersCat('count', value)} />
          </div>
        </div>
        <TextButton title="Купить котов" color="green" onClick={buyCats} />
      </div>
    );
  };

  const SellCatsToCity = () => {
    const [parameters, setParameters] = useState({
      color: CAT_COLOR.BLACK,
      gender: CAT_GENDER.MALE,
      count: 0,
    });

    const changeParametersCat = (parameter, value) => {
      setParameters({ ...parameters, [parameter]: value });
    };

    const getOptions = (parameter) => {
      return Object.values(
        parameter === 'color' ? CAT_COLOR : parameter === 'gender' ? CAT_GENDER : parameter === 'age' ? age : [],
      ).map((cat) => {
        return {
          value: cat,
          key: cat,
          label: cat,
        };
      });
    };

    const sellCats = () => {
      const price = _.find(appStore.cityQuota, { gender: parameters.gender, color: parameters.color }).buy_price;
      const contents = [{ price: price, color: parameters.color, gender: parameters.gender, count: parameters.count }];
      appStore.socket.send(
        JSON.stringify({
          msg_type: 'actions_on_task',
          task_id: null,
          actions: [
            {
              act_name: 'create_city_sell_task',
              params: {
                request_type: 'sell',
                contents,
                visa: true,
                to_revision: false,
              },
            },
            {
              act_name: 'approve_city_trade_by_sales',
              params: {
                request_type: 'sell',
                contents,
                visa: true,
                to_revision: false,
              },
            },
            {
              act_name: 'city_sell_push_to_captain',
              params: {
                request_type: 'sell',
                contents,
                visa: true,
                to_revision: false,
              },
            },
            {
              act_name: 'approve_city_sell_task_by_captain',
              params: {
                request_type: 'sell',
                contents,
                visa: true,
                to_revision: false,
              },
            },
            {
              act_name: 'approve_city_sell_task_by_ceo',
              params: {
                request_type: 'sell',
                contents,
                visa: true,
                to_revision: false,
              },
            },
          ],
        }),
      );
    };

    return (
      <div className="team-mode-tester__spawn-cat">
        <span className="team-mode-tester__text">Продажа котов городу</span>
        <div className="team-mode-tester__spawn-cat-selects">
          {Object.keys(parameters)
            .slice(0, 2)
            .map((parameter) => {
              return (
                <div key={parameter}>
                  <span>{parameter}</span>
                  <ReactSelect
                    className="team-mode-tester__select"
                    value={{ value: parameters[parameter], label: parameters[parameter] }}
                    options={getOptions(parameter)}
                    onChange={(event) => changeParametersCat(parameter, event.value)}
                  />
                </div>
              );
            })}
          <div>
            count
            <NumberInput icon={false} onChange={(value) => changeParametersCat('count', value)} />
          </div>
        </div>
        <TextButton title="Продать котов" color="green" onClick={sellCats} />
      </div>
    );
  };

  const RequestsSellCatsToCity = () => {
    const [parameters, setParameters] = useState({
      color: CAT_COLOR.BLACK,
      gender: CAT_GENDER.MALE,
      delay: 0,
      count: 0,
    });

    const changeParametersCat = (parameter, value) => {
      setParameters({ ...parameters, [parameter]: value });
    };

    const getOptions = (parameter) => {
      return Object.values(
        parameter === 'color' ? CAT_COLOR : parameter === 'gender' ? CAT_GENDER : parameter === 'age' ? age : [],
      ).map((cat) => {
        return {
          value: cat,
          key: cat,
          label: cat,
        };
      });
    };

    const sellCats = () => {
      appStore.testSendRequestsToSellCatsToCity(
        parameters.gender,
        parameters.color,
        parameters.count,
        parameters.delay,
      );
    };

    return (
      <div className="team-mode-tester__spawn-cat">
        <span className="team-mode-tester__text">Отправка запросов на продажу котов городу</span>
        <div className="team-mode-tester__spawn-cat-selects">
          {Object.keys(parameters)
            .slice(0, 2)
            .map((parameter) => {
              return (
                <div key={parameter}>
                  <span>{parameter}</span>
                  <ReactSelect
                    className="team-mode-tester__select"
                    value={{ value: parameters[parameter], label: parameters[parameter] }}
                    options={getOptions(parameter)}
                    onChange={(event) => changeParametersCat(parameter, event.value)}
                  />
                </div>
              );
            })}
          <div>
            delay
            <NumberInput icon={false} onChange={(value) => changeParametersCat('delay', value)} />
          </div>
          <div>
            count
            <NumberInput icon={false} onChange={(value) => changeParametersCat('count', value)} />
          </div>
        </div>
        <TextButton title="Продать котов" color="green" onClick={sellCats} />
      </div>
    );
  };

  const Specialities = () =>
    specialtyMass.map((specialty, index) => {
      if (!appStore.isCorpMode && specialty === SPECIALITIES.CEO) {
        return;
      }
      return (
        <div
          className={classNames({
            'team-mode-tester__field-item': true,
            'team-mode-tester__field-item--background-me': specialty === getCurrentSpecialty(),
          })}
          key={index}
        >
          <a
            onClick={clearStoragePlayerInfo}
            style={{ pointerEvents: specialty === getCurrentSpecialty() ? 'none' : 'auto' }}
            href={getSpecialtyLink(specialty)}
          >
            {appStore.meIsCEO ? `Перейти на СЕО текущей компании` : specialty}
          </a>
        </div>
      );
    });

  const Companies = observer(() =>
    companies.map((company, index) => {
      return (
        <div
          className={classNames({
            'team-mode-tester__field-item': true,
            'team-mode-tester__field-item--background-me': company.uuid === appStore.me.uuid,
          })}
          key={index}
        >
          <a
            onClick={clearStoragePlayerInfo}
            style={{ pointerEvents: company.uuid === appStore.me.uuid ? 'none' : 'auto' }}
            href={`${location.origin}/play/${LocalStorage.GAME_UID}/${company.uuid}?server=${LocalStorage.JOIN_URL}`}
          >
            {appStore.isCorpMode && company.uuid} {company.role}
          </a>
        </div>
      );
    }),
  );

  const Content = () => {
    const _content = [];
    if (openedParameters.includes(TestFieldsTypes.PLAYERS.type)) {
      _content.push(
        <>
          <Companies />
          <div>============</div>
          <Specialities />
        </>,
      );
    }
    if (openedParameters.includes(TestFieldsTypes.SPAWN_CATS.type)) {
      _content.push(
        <>
          <div>============</div>
          <SpawnCat />
        </>,
      );
    }
    if (openedParameters.includes(TestFieldsTypes.SELL_CATS_TO_CITY.type)) {
      _content.push(
        <>
          <div>============</div>
          <SellCatsToCity />
        </>,
      );
    }
    if (openedParameters.includes(TestFieldsTypes.BUY_CATS_FROM_CITY.type)) {
      _content.push(
        <>
          <div>============</div>
          <BuyCatsFromCity />
        </>,
      );
    }

    if (openedParameters.includes(TestFieldsTypes.REQUESTS_TO_SELL_CATS_TO_CITY.type)) {
      _content.push(
        <>
          <div>============</div>
          <RequestsSellCatsToCity />
        </>,
      );
    }
    return _content;
  };

  return (
    !location.href.includes('hr.alabuga.ru') &&
    // !location.href.includes('pre-prod') &&
    appStore.isTeamMode &&
    appStore.ceo_uuid !== appStore.me?.uuid &&
    appStore.company_players && (
      <div id="team-mode-tester">
        <button
          className="team-mode-tester__button"
          onClick={() => {
            setOpen(!opened);
          }}
        >
          {opened ? '>>>>' : '<<<<'}
        </button>
        {opened ? (
          <div className="team-mode-tester__field" style={appStore.isCorpMode ? { width: '35vw' } : {}}>
            {Object.values(TestFieldsTypes)
              .filter((testField) => !testField.validate || testField.validate())
              .map((testField) => {
                return (
                  <div key={testField.type} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <Checkbox
                      value={openedParameters.includes(testField.type)}
                      onClick={() => {
                        if (openedParameters.includes(testField.type)) {
                          setOpenedParameters([
                            ...openedParameters.filter((fieldType) => fieldType !== testField.type),
                          ]);
                        } else {
                          setOpenedParameters([...openedParameters, testField.type]);
                        }
                      }}
                    />
                    <span>{testField.title}</span>
                  </div>
                );
              })}
            <Content />
          </div>
        ) : null}
      </div>
    )
  );
}

export default observer(TeamModeTester);
