import RootStore from '../store';

// 1й игровой месяц - ИЮНЬ
export const MONTH_NAME = [
  'ИЮНЬ',
  'ИЮЛЬ',
  'АВГУСТ',
  'СЕНТЯБРЬ',
  'ОКТЯБРЬ',
  'НОЯБРЬ',
  'ДЕКАБРЬ',
  'ЯНВАРЬ',
  'ФЕВРАЛЬ',
  'МАРТ',
  'АПРЕЛЬ',
  'МАЙ',
];

export const getGameUnitOfTime = () => {
  const { appStore } = RootStore;
  const startOfYear = 2025;
  const numberOfMonthInTimer = Math.floor(appStore.timerValue.$ms / 1000 / appStore.monthDuration);
  const month = numberOfMonthInTimer % 12;
  // + 5, т.к. игровой месяц начинается с июня (разница в 5)
  const year = startOfYear + Math.floor((numberOfMonthInTimer + 5) / 12);
  return {
    month: month,
    year: year,
  };
};
