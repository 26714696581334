import React from 'react';
import RootStore from '../../store';
import { observer } from 'mobx-react-lite';
function pauseIcon({ style }) {
  const { appStore } = RootStore;

  if (appStore.isGamePaused) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path
          d="M4.50624 12.1836C4.48238 6.5777 4.52908 1.87789 4.61005 1.74014C4.89563 1.25267 5.86054 1.50144 7.71376 2.54057C13.1894 5.61048 19.5 10.6824 19.5 12.0131C19.5 12.8904 17.4358 14.8891 13.6205 17.7056C10.3656 20.1085 5.90254 22.6358 5.16407 22.4943C4.58553 22.3837 4.54655 21.7755 4.50624 12.1836Z"
          fill="#FFFBE5"
        />
      </svg>
    );
  } else {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7388 3.5799L10.5658 2.35197L8.72589 2.2618C7.71392 2.21198 6.70853 2.3246 6.49168 2.51195C6.16444 2.79437 6.0835 4.21071 6.01463 10.8468C5.96916 15.2438 6.03329 19.4728 6.15709 20.2447L6.38208 21.648L8.21123 21.7382C9.21714 21.788 10.2175 21.6754 10.4344 21.4884C10.7643 21.2039 10.8355 19.8128 10.8702 12.9781C10.8928 8.48427 10.8336 4.25526 10.7388 3.5799ZM18.6138 3.5799L18.4408 2.35197L16.6009 2.2618C15.5889 2.21198 14.5835 2.3246 14.3667 2.51195C14.0394 2.79437 13.9585 4.21071 13.8896 10.8468C13.8442 15.2438 13.9083 19.4728 14.0321 20.2447L14.2571 21.648L16.0862 21.7382C17.0921 21.788 18.0925 21.6754 18.3094 21.4884C18.6393 21.2039 18.7105 19.8128 18.7452 12.9781C18.7678 8.48427 18.7086 4.25526 18.6138 3.5799Z"
          fill="#FFFBE5"
        />
      </svg>
    );
  }
}

export default observer(pauseIcon);
