import React from 'react';

function PoisoningModalIcon({ style, stylePath, className }) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <g clipPath="url(#poisoningModalIcon)">
        <path
          d="M73.7802 6.16675C65.5531 -2.05591 52.1707 -2.05591 43.9435 6.16675L26.5488 23.5614L46.2979 43.3105C50.3626 39.9257 55.5159 37.8063 61.206 37.8063C64.4698 37.8063 67.5755 38.4842 70.4015 39.6949L73.7802 36.0036C82.0051 27.7786 82.0051 14.3941 73.7802 6.16675Z"
          fill="#C6A788"
        />
        <path
          d="M43.0109 46.6526L23.2343 26.8762L6.20965 43.9176C-2.0152 52.1425 -2.0152 65.5272 6.20965 73.7543C14.4367 81.9765 27.9753 82.1334 36.2026 73.9104L39.6539 70.4426C38.4432 67.6167 37.7653 64.5109 37.7653 61.2472C37.7654 55.7031 39.7811 50.6704 43.0109 46.6526Z"
          fill="#C6A788"
        />
        <path
          d="M42.4531 61.2471C42.4531 70.8009 49.6239 78.5979 58.8617 79.7634V42.7307C49.6239 43.8962 42.4531 51.6929 42.4531 61.2471Z"
          fill="#C6A788"
        />
        <path
          d="M63.5498 42.7307V79.7634C72.7876 78.5979 79.9584 70.8009 79.9584 61.247C79.9584 51.6931 72.7878 43.8962 63.5498 42.7307Z"
          fill="#C6A788"
        />
      </g>
      <defs>
        <clipPath id="poisoningModalIcon">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PoisoningModalIcon;
