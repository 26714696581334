import React from 'react';

function PhoneIcon({ style }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="#FDF6EE" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M28.5928 20.6916C26.7561 20.6916 24.9527 20.4043 23.2437 19.8395C22.4062 19.5539 21.3768 19.8159 20.8657 20.3409L17.4925 22.8873C13.5805 20.7991 11.1709 18.3902 9.11117 14.5076L11.5827 11.2223C12.2248 10.581 12.4551 9.6443 12.1791 8.76539C11.612 7.04742 11.3238 5.24484 11.3238 3.40734C11.3239 2.07992 10.244 1 8.91664 1H3.40727C2.07992 1 1 2.07992 1 3.40727C1 18.6221 13.378 31 28.5928 31C29.9202 31 31.0001 29.9201 31.0001 28.5927V23.0988C31 21.7715 29.9201 20.6916 28.5928 20.6916Z"
        fill="inherit"
      />
    </svg>
  );
}

export default PhoneIcon;
