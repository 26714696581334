import React from 'react';
import './style.scss';
import classNames from '../../../common/classNames';
import SpecialityCircle from '../SpecialityCircle/SpecialityCircle';
import SpecialityIcon from '../SpecialityIcon/SpecialityIcon';

function TeamAvatar({ Logo, speciality, customClass }) {
  return (
    <span
      className={classNames({
        'team-avatar': true,
        [customClass]: customClass,
      })}
    >
      <SpecialityCircle speciality={speciality} />
      <Logo />
      <SpecialityIcon speciality={speciality} />
    </span>
  );
}

export default TeamAvatar;
