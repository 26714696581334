import classNames from '../../common/classNames';
import SwitchIcon from '../icons/SwitchIcon';
import React from 'react';

export const Switcher = ({ value, onClick, disabled }) => {
  return (
    <div
      onClick={onClick}
      className={classNames({
        switcher: true,
        disabled: disabled,
      })}
    >
      <SwitchIcon enabled={value} />
    </div>
  );
};
