import React from 'react';

function CatCalcIcon({ style, className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.49958 3.45125C1.38101 3.79559 1.1797 7.72366 1.05307 12.1796C0.926469 16.6357 0.767646 21.1911 0.699622 22.3025C0.580177 24.2658 0.639217 24.3662 2.74608 25.7867C5.66546 27.7555 8.7204 28.6408 13.5972 28.9316C18.7272 29.2372 23.0398 28.5193 26.6504 26.7589C29.9013 25.1737 31.0315 23.7892 30.5613 21.9685C30.3859 21.2884 30.1107 16.782 29.9501 11.9546L29.6584 3.17758L27.7077 3.0367C26.5154 2.95077 24.1669 3.37026 21.6676 4.11558C15.8365 5.85528 15.2557 5.87869 11.0505 4.54184C6.39518 3.06285 1.81411 2.53934 1.49958 3.45125ZM25.4908 10.5552C27.2715 12.5698 27.4486 12.6671 27.7757 11.806C27.9708 11.2925 28.0714 9.69011 27.9994 8.24524L27.8688 5.61759L23.5602 8.37085L25.4908 10.5552ZM7.52311 8.42354L6.2869 7.55212C5.60683 7.07323 4.59747 6.44264 4.04402 6.15143C3.04988 5.62792 3.03778 5.65633 3.06867 8.45052C3.11788 12.8769 3.38902 13.1037 5.61578 10.5827L7.52311 8.42354ZM9.65905 15.6455C10.4537 16.4448 10.3123 17.5602 9.29932 18.4829C8.00181 19.664 6.16923 18.7903 6.16923 16.9903C6.16923 16.2837 6.30345 15.5702 6.46766 15.4055C6.96204 14.9082 9.08501 15.0679 9.65905 15.6455ZM24.3194 18.7741C25.2595 18.3433 25.4393 17.9887 25.3243 16.7901C25.204 15.5351 24.9959 15.3119 23.8398 15.1976C21.4963 14.9662 20.5652 16.9237 22.2779 18.4829C23.0269 19.1648 23.3629 19.2125 24.3194 18.7741ZM18.4744 19.3268C19.2771 19.9174 19.2811 20.0042 18.5545 21.12C17.1957 23.2062 14.0446 23.1451 12.9091 21.0102C12.5458 20.3274 12.599 19.9538 13.1368 19.4123C14.0137 18.5306 17.3201 18.4775 18.4744 19.3268Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default CatCalcIcon;
