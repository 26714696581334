import React from 'react';

function MultiFreeshModalIcon({ style, stylePath, className, fill }) {
  return (
    <svg width="85" height="81" viewBox="0 0 85 81" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4634 1.10135C24.6236 1.73205 21.6759 3.19645 19.9133 4.35437C18.1507 5.51294 16.4118 6.46062 16.0487 6.46062C15.6863 6.46062 13.3605 7.38866 10.8809 8.52234C5.65178 10.9135 5.2504 12.0419 8.89517 14.1056L11.4084 15.5288L10.094 23.4148L7.02226 24.1463C-0.23954 25.874 -2.03385 28.8828 2.41103 31.8778C4.27731 33.1346 5.2933 33.369 7.69233 33.0965L10.6631 32.758V35.9344C10.6631 41.424 14.8181 45.0759 17.2231 41.7004C17.8153 40.8693 18.7567 38.6425 19.3145 36.7511C20.4176 33.0134 21.8918 32.2537 23.3983 34.6468C25.9419 38.687 32.6974 36.7609 32.7574 31.9779C32.772 30.8213 33.6902 29.5593 35.7757 27.8303C41.1619 23.363 44.002 17.1314 44 9.78243C43.9993 4.67595 42.4962 1.14849 40.0403 0.486355C36.859 -0.370291 29.8263 -0.0519791 26.4634 1.10135ZM33.9669 5.28174C36.2075 7.50457 33.5728 11.2082 30.7981 9.73525C28.4678 8.49809 29.4765 4.49582 32.1185 4.49582C32.6994 4.49582 33.5312 4.84949 33.9669 5.28174ZM27.2299 16.1647C28.6209 17.3023 30.9579 18.6554 32.4241 19.1721L35.0892 20.1106L32.1185 20.1597C27.1487 20.2423 23.5126 17.2722 22.5633 12.355L22.1203 10.0627L23.4103 12.0799C24.1199 13.1894 25.8383 15.0271 27.2299 16.1647Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.9307 44.7669C50.0176 44.7204 46.7914 45.0724 44.7619 45.5479C42.7321 46.0241 40.7991 46.3104 40.4658 46.1844C40.1331 46.0585 37.6683 46.1226 34.9892 46.3264C29.3392 46.7566 28.5698 47.6771 31.1823 50.8813L32.9837 53.0909L28.9749 60.0421L25.8951 59.6625C18.615 58.7636 15.8987 60.9667 18.9148 65.3236C20.1814 67.1522 21.0307 67.7253 23.3298 68.3025L26.1772 69.0161L25.0485 71.9998C23.0978 77.1563 25.6145 82.0296 29.0217 79.6941C29.8606 79.119 31.516 77.3543 32.7002 75.7713C35.041 72.6434 36.6643 72.4417 37.1968 75.2128C38.0961 79.8913 44.982 80.4279 46.7367 75.956C47.1611 74.8746 48.4525 74.0081 50.9813 73.1082C57.5132 70.7824 62.3347 65.915 64.9443 59.0112C66.7582 54.2143 66.6318 50.3789 64.6127 48.9041C61.9967 46.9946 55.4276 44.8514 51.9307 44.7669ZM57.3333 51.2994C58.6003 54.1655 54.8656 56.7295 52.8419 54.3823C51.1423 52.411 53.4905 49.0018 55.9158 49.9193C56.4491 50.121 57.087 50.7421 57.3333 51.2994ZM47.2817 59.1826C48.1543 60.7342 49.8189 62.8168 50.9812 63.8113L53.0942 65.6184L50.3497 64.6329C45.7582 62.9846 43.4757 58.932 44.3515 53.9834L44.7594 51.6764L45.2268 54.0192C45.484 55.3078 46.4084 57.6307 47.2817 59.1826Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.1414 5.34165C69.6784 5.81481 67.3256 6.93907 65.913 7.83903C64.5005 8.73952 63.1108 9.47035 62.8246 9.46332C62.5388 9.4563 60.6869 10.1599 58.7096 11.0265C54.5398 12.8541 54.201 13.7567 57.0335 15.492L58.9867 16.6888L57.7942 23.025L55.358 23.5556C49.5988 24.8088 48.1246 27.2012 51.5695 29.7033C53.0159 30.7533 53.8122 30.9621 55.709 30.7888L58.0577 30.5731L57.9948 33.1355C57.886 37.564 61.0894 40.5905 63.0523 37.914C63.5356 37.255 64.3218 35.4769 64.7991 33.9619C65.7428 30.9681 66.9201 30.3837 68.0603 32.3434C69.9856 35.6519 75.3495 34.2289 75.4916 30.3717C75.526 29.4389 76.2749 28.4386 77.9533 27.0842C82.2882 23.5848 84.6506 18.6127 84.7947 12.6842C84.8953 8.56482 83.7801 5.6901 81.8572 5.10841C79.3661 4.35575 73.8154 4.47637 71.1414 5.34165ZM76.9741 8.85926C78.6964 10.6958 76.546 13.6325 74.3877 12.3905C72.575 11.3474 73.4495 8.13831 75.5324 8.18946C75.9904 8.20071 76.6391 8.50212 76.9741 8.85926ZM71.4472 17.5081C72.5213 18.4527 74.3369 19.5895 75.4826 20.0348L77.5651 20.8434L75.222 20.8256C71.3024 20.7959 68.4946 18.3295 67.8437 14.3444L67.5398 12.4867L68.5169 14.1389C69.0544 15.0477 70.3727 16.5634 71.4472 17.5081Z"
      />
    </svg>
  );
}

export default MultiFreeshModalIcon;
