import React, { useEffect } from 'react';
import Modal from '../Modal/Modal';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import TextButton from '../../buttons/TextButton/TextButton';
import EatIcon from '../../icons/EatIcon';
import { CoinIcon, SendIcon } from '../../icons';
import Price from '../Cat/Price';
import './styles.scss';

function BuyEatModal() {
  const [eatCount, setEatCount] = React.useState(1);
  const { appStore } = RootStore;

  useEffect(() => {
    setEatCount(1);
  }, [appStore.buyEatModal]);

  if (!appStore.buyEatModal) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.buyEatModal}
      title="Покупка корма"
      exitIcon={true}
      customClass="buy-eat"
      onClose={appStore.hideBuyEatModal}
    >
      <div className="modal__body">
        <div className="action-content">
          <div className="action-icon__wrapper action-icon__wrapper--fish">
            <EatIcon stylePath={{ fill: '#FDF6EE' }} className="action-icon action-icon--fish" />
          </div>
          <Price
            price={eatCount}
            withCoin={false}
            withoutStopPropagation
            onMinus={() => {
              setEatCount(eatCount - 1);
            }}
            onPlus={() => {
              setEatCount(eatCount + 1);
            }}
            customClass="action-modal__price"
          />
        </div>

        <div className="modal__body-quantity-price">
          <div className="modal__body-quantity-price-item">
            <p className="modal__body-quantity-price-item--title">ЗА ЕДИНИЦУ:</p>
            <p className="modal__body-quantity-price-item--cost">
              <CoinIcon /> {appStore.gameCosts.food}
            </p>
          </div>
          <div className="modal__body-quantity-price-item">
            <p className="modal__body-quantity-price-item--title">ВСЕГО:</p>
            <p className="modal__body-quantity-price-item--cost">
              <CoinIcon /> {appStore.gameCosts.food * eatCount}
            </p>
          </div>
        </div>
      </div>
      <div className="modal__body-actions">
        <TextButton
          onClick={() => appStore.buyFood(eatCount)}
          color="green"
          title={<SendIcon />}
          isDisabled={appStore.isCurrentTurnPreliminary}
        />
      </div>
    </Modal>
  );
}

export default observer(BuyEatModal);
