import React from 'react';

function TeamBuyerIcon({ isActive = false }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 10 10"
      fill={isActive ? '#FDF6EE' : '#C0A88F'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.78796 1.67698C2.85414 1.28575 3.16883 1.00161 3.53591 1.00161H6.30311C6.66318 1.00161 6.9739 1.27525 7.04748 1.65715L7.34433 3.1979C7.3965 3.46865 7.64136 3.64232 7.89125 3.5858C8.14114 3.52928 8.30142 3.26397 8.24926 2.99322L7.9524 1.45247C7.78936 0.606287 7.10092 0 6.30311 0L3.53591 0C2.72256 0 2.02532 0.629568 1.87868 1.49639L1.58683 3.22149C1.54081 3.49355 1.70705 3.75452 1.95814 3.80439C2.20923 3.85426 2.45009 3.67414 2.49611 3.40208L2.78796 1.67698ZM6.99398 3.35787L6.77345 2.21326H3.07463L2.84803 3.55272C2.76637 4.0354 2.33904 4.35497 1.89356 4.26649C1.44807 4.17802 1.15313 3.715 1.23479 3.23232L1.40152 2.24679C0.491107 2.4355 -0.145143 3.39338 0.028671 4.42408L0.709756 8.46284C0.859897 9.35316 1.57585 10 2.41117 10H7.72329C8.58155 10 9.30999 9.31807 9.43456 8.39799L9.98135 4.35923C10.1256 3.29362 9.41577 2.33123 8.45085 2.2233L8.59948 2.99472C8.69204 3.47509 8.40766 3.94579 7.96431 4.04607C7.52096 4.14635 7.08653 3.83823 6.99398 3.35787Z"
        fill="inherit"
      />
    </svg>
  );
}

export default TeamBuyerIcon;
