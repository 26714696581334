import React from 'react';
import { useDrop } from 'react-dnd';
import RootStore from '../../store/index';

export const DropContainer = ({ acceptType = 'cat', type, element, dropData }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: acceptType,
    drop: (item) => {
      RootStore.appStore.dropItem({ ...item, to: type, dropData });
    },
    canDrop: (item, monitor) => {
      if (type === 'cat') {
        return true;
      }
      return RootStore.appStore.acceptedDropTypes.includes(type);
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });
  return element({ canDrop, isOver, drop });
};
