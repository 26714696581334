import React, { useState, useEffect } from 'react';
import PhoneTurnModalIcon from '../../icons/modal/PhoneTurnModalIcon';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import './style.scss';
import CloseIcon from '../../../ui/icons/CloseIcon';

function PhoneTurnModal() {
  const [isTurned, setIsTurned] = useState(true);
  const [isMessengerInMobileMode, setIsMessengerInMobileMode] = useState(false);

  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  useEffect(() => {
    setIsMessengerInMobileMode(isMobileDevice() && RootStore.appStore.messengerPlayer);
  }, [RootStore.appStore.messengerPlayer]);

  function resize() {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (width < height) {
      setIsTurned(false);
      adaptiveMessengerOnMobile();
    } else {
      setIsTurned(true);
      RootStore.appStore.setMessengerPlayerUuid(null);
    }
  }

  const adaptiveMessengerOnMobile = () => {
    if (window.innerWidth < 500) {
      const messenger = document.querySelector('.player-messenger');
      if (RootStore.appStore.messengerPlayer) {
        if (messenger && !messenger.classList.contains('player-messenger__mobile-vertical')) {
          messenger.classList.add('player-messenger__mobile-vertical');
        }
      } else {
        messenger?.classList.remove('player-messenger__mobile-vertical');
      }
    }
  };

  const isMobileDevice = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    return height < 500 && width > height;
  };

  return !isTurned || (isTurned && isMessengerInMobileMode) ? (
    <div className="phone-turn-modal__container">
      <div className="phone-turn-modal__wrap">
        <div className="phone-turn-modal__icon-wrap">
          {isTurned && RootStore.appStore.messengerPlayer ? (
            <>
              <span className="phone-turn-modal__close" onClick={() => RootStore.appStore.setMessengerPlayerUuid(null)}>
                <CloseIcon />
              </span>
              <PhoneTurnModalIcon toHorizontal={false} />
            </>
          ) : (
            <PhoneTurnModalIcon toHorizontal={true} />
          )}
        </div>
        <p className="phone-turn-modal__text">
          Пожалуйста, переверни меня
          {isMessengerInMobileMode ? ', чтобы открыть чат' : ''}
        </p>
      </div>
    </div>
  ) : null;
}

export default observer(PhoneTurnModal);
