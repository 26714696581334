import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarFishImage from '../../../assets/nurseryAvatars/avatarFish.png';

function AvatarFish({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <path
    //     d="M14.7218 14.9382C14.4714 14.2808 14.1198 13.7231 13.6749 13.278C12.3796 11.9827 10.4614 11.7891 9.43324 11.7891C9.14927 11.7891 8.96052 11.8048 8.92344 11.8081C8.44752 11.8524 8.06986 12.23 8.02561 12.706C8.01814 12.7873 7.95573 13.5275 8.10023 14.4569C8.2934 15.7 8.77604 16.7375 9.49549 17.4571C9.6905 17.6523 9.9087 17.8306 10.1463 17.9896C9.88185 18.1596 9.6406 18.3534 9.42713 18.567C8.70738 19.2866 8.28761 20.3241 8.09443 21.5672C7.96076 22.4269 8.0041 23.1245 8.01722 23.2889L8.01981 23.3183C8.06406 23.7942 8.37901 24.1717 8.85737 24.2163C8.89231 24.2195 9.08137 24.2351 9.36472 24.2351C10.3929 24.2351 12.3109 24.0414 13.6064 22.7461C14.0513 22.301 14.4029 21.7433 14.6533 21.0859C16.5179 22.4132 18.4645 23.1135 20.2958 23.1135C20.7693 23.1135 21.2417 23.067 21.7097 22.9754V13.0363C21.2648 12.9532 20.8154 12.9106 20.3643 12.9106C18.5331 12.9106 16.5864 13.611 14.7218 14.9382Z"
    //     fill="#02516C"
    //   />
    //   <path
    //     d="M27.865 17.4646L27.8456 17.4307C27.7701 17.3079 27.0708 16.1996 25.8207 15.1106C24.9018 14.3101 23.9104 13.7199 22.8813 13.3533V22.6488C23.893 22.2813 24.8712 21.7001 25.7831 20.9172C27.1097 19.7783 27.8385 18.6067 27.8456 18.5951L27.873 18.5509L27.9186 18.4351C28.0431 18.1189 28.0236 17.7652 27.865 17.4646Z"
    //     fill="#02516C"
    //   />
    // </svg>
    <Avatar style={style} avatarImage={AvatarFishImage} />
  );
}

export default AvatarFish;
