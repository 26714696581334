import React from 'react';
import RootStore from '../store';
import Header from '../ui/components/Header/Header';
import { observer } from 'mobx-react';
import MainLayout from '../ui/layouts/MainLayout/MainLayout';
import Shops from '../modules/main/Shops';
import Nurseries from '../modules/main/Nurseries';
import routes from '../routes';
import AreaIcon from '../ui/icons/AreaIcon';
import Loader from '../ui/components/Loader/Loader';

function Main() {
  const { appStore } = RootStore;
  if (!appStore.me) {
    return <Loader />;
  }
  return (
    <>
      <MainLayout>
        <Header
          extra={
            appStore.meIsCEO && (
              <span onClick={() => RootStore.goTo(routes.ceo)}>
                <AreaIcon />
              </span>
            )
          }
        />
        <Shops />
        <Nurseries />
      </MainLayout>
    </>
  );
}

export default observer(Main);
