import React from 'react';
import { observer } from 'mobx-react';
import Player from './PlayerComponent';
import './style.scss';
import PlayerObject from '../classes/Player';
import classNames from '../../../../../common/classNames';
import isFakeTeamPlayer from '../../../../../common/isFakePlayer';
import orderPlayersBySpeciality from '../../../../../common/orderPlayersBySpeciality';

const UserList = ({ players, areaNum = null, isBankrupt = false, useOrderingPlayersBySpeciality = true }) => {
  if (useOrderingPlayersBySpeciality) {
    players = orderPlayersBySpeciality(players);
  }
  const Players = observer(() => {
    return players
      .filter((player) => !isFakeTeamPlayer(player))
      .map((player, index) => {
        return (
          <Player
            key={`user-list-player--${index}`}
            player={
              new PlayerObject({
                speciality: player.specialty,
                active: !player.is_timed_out,
                fullName: player.name,
                competences: player.competences,
              })
            }
            topDividerIsNeeded={index !== 0}
          />
        );
      });
  });

  const AreaNum = () => {
    if (areaNum === null) {
      return null;
    }
    return (
      <div className="our-company-user-list__item-area-num">
        Город {areaNum} {isBankrupt && '- банкрот'}
      </div>
    );
  };

  return (
    <div
      className={classNames({
        'our-company-user-list__item': true,
        'our-company-user-list__item--inactive': isBankrupt,
      })}
    >
      <AreaNum />
      <Players />
    </div>
  );
};

export default observer(UserList);
