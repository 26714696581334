import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../store';
import Header from '../ui/components/Header/Header';
import HelpIcon from '../ui/icons/HelpIcon';
import NurseriesLayout from '../ui/layouts/NurseriesLayout/NurseriesLayout';
import TextButton from '../ui/buttons/TextButton/TextButton';
import { SEASON_TYPE, TASK_TYPES } from '../store/constants';
import { DropContainer } from '../modules/deal/DropContainer';
import routes from '../routes';
import classNames from '../common/classNames';
import HouseSlider from '../modules/deal/nursery/HouseSlider';
import CatsSwiper from '../modules/deal/nursery/CatsSwiper';
import FeedingModal from '../ui/components/FeedingModal';
import Modal from '../ui/components/Modal/Modal';
import NotEnoughBalanceModal from '../ui/components/NotEnoughBalanceModal';
import NurseriesWoodSummer from '../assets/basic/summer/nursery/decor/nurseries_wood.png';
import NurseriesWoodFall from '../assets/basic/fall/nursery/decor/nurseries_wood.png';
import NurseriesWoodWinter from '../assets/basic/winter/nursery/decor/nurseries_wood.png';
import NurseriesWoodSpring from '../assets/basic/spring/nursery/decor/nurseries_wood.png';
import { HouseIcon, NurseryIcon } from '../ui/icons';
import trainingStore from '../store/trainingStore';
import EatBtn from '../ui/components/EatBtn/EatBtn';
import MagnifierBtn from '../ui/components/MagnifierBtn/MagnifierBtn';
import * as teamMode from '../store/teamMode';
import corpStore from '../store/CorpStore';
import { DRAG_AREA, SPECIALITIES } from '../common/constants';
import MultiEatBtnIcon from '../ui/icons/MultiEatBtnIcon';

const Main = () => {
  const { appStore } = RootStore;
  const currentGroupedHouses = appStore.currentGroupedHouses;
  const [isTryFeedAllCats, setIsTryFeedAllCats] = useState(false);

  const checkActions = () => {
    if (!appStore.isTeamMode) {
      return !!appStore.ourCats.length;
    } else {
      return true;
    }
  };

  const catsWithoutHomeSwiper = useMemo(
    () => <CatsSwiper catsWithoutHome={appStore.catsWithoutHome} />,
    [appStore.catsWithoutHome],
  );

  const nurseryButtonsVision = appStore.hasSpecialities([SPECIALITIES.DOCTOR]);
  let insuredDisabled = !currentGroupedHouses || currentGroupedHouses.insured;
  if (appStore.isTeamMode && !insuredDisabled) {
    insuredDisabled = appStore.tasks.some(
      (task) =>
        task.type === TASK_TYPES.BUY_HOUSE_INSURANCE &&
        task.status === 'inprogress' &&
        task.content.house_ids.includes(currentGroupedHouses.left.data.house_id),
    );
  } else if (appStore.isRealTimeMode && currentGroupedHouses) {
    insuredDisabled = false;
  }

  let woodImage = NurseriesWoodSummer;
  if (appStore.currentSeasonType === SEASON_TYPE.FALL) {
    woodImage = NurseriesWoodFall;
  } else if (appStore.currentSeasonType === SEASON_TYPE.WINTER) {
    woodImage = NurseriesWoodWinter;
  } else if (appStore.currentSeasonType === SEASON_TYPE.SPRING) {
    woodImage = NurseriesWoodSpring;
  }

  const onOpenAllCatIsFullModal = (state = true) => {
    if (appStore.isCurrentTurnPreliminary) {
      return;
    }
    appStore.openAllCatIsFullModal();
    setIsTryFeedAllCats(state);
  };

  return (
    <>
      <NurseriesLayout>
        <Header
          extra={
            <span
              onClick={() => {
                if (appStore.meIsCEO) {
                  RootStore.goTo(routes.city, { areaNum: corpStore.currentAreaNum });
                } else {
                  RootStore.goTo(routes.main);
                }
              }}
            >
              <span className="nurseries-header-extra">
                <HouseIcon style={{ fill: '#FDF6EE' }} />
              </span>
            </span>
          }
        />
        <div className="own-nurseries">
          <img src={woodImage} className="own-nurseries__tree" />
          {nurseryButtonsVision && (
            <div className="own-nurseries__actions">
              <p
                className={classNames({
                  'own-nurseries__actions-item': true,
                  'own-nurseries__actions-item--disabled': appStore.isCurrentTurnPreliminary,
                })}
                onClick={appStore.checkBuyHouse}
              >
                <NurseryIcon fill={appStore.isCurrentTurnPreliminary ? '#C6A788' : '#fff'} />
              </p>
              <EatBtn
                onClick={appStore.openBuyEatModal}
                checkActions={checkActions}
                onOpenAllCatIsFullModal={onOpenAllCatIsFullModal}
              />
              {appStore.isCorpMode && (
                <p
                  className="own-nurseries__actions-item"
                  onClick={appStore.allMyHungryCats.length ? appStore.openFeedAllCatModal : onOpenAllCatIsFullModal}
                >
                  <MultiEatBtnIcon fill={'#FDF6EE'} />
                </p>
              )}
              <p
                className={classNames({
                  'own-nurseries__actions-item': true,
                  'own-nurseries__actions-item--insurance': true,
                  'own-nurseries__actions-item--hidden':
                    trainingStore.launchedChapter > 0 && trainingStore.launchedChapter < 12,
                  'own-nurseries__actions-item--disabled': insuredDisabled,
                })}
                onClick={appStore.checkHouseInsurance}
              >
                <HelpIcon fill={insuredDisabled ? '#c6a788' : '#fff'} />
              </p>
              <MagnifierBtn checkActions={checkActions} />
            </div>
          )}
          <HouseSlider />
          <section className="own-nurseries__cats">
            <DropContainer
              type={DRAG_AREA.NURSERY_CATS}
              element={({ canDrop, drop }) => <div ref={drop}>{catsWithoutHomeSwiper}</div>}
            />
          </section>
          <div className="own-nurseries__bush"></div>
        </div>
      </NurseriesLayout>
      <FeedingModal />
      <Modal
        title={isTryFeedAllCats ? 'Все котики покормлены' : 'В этом домике все котики покормлены'}
        isOpen={appStore.allCatIsFullModal}
      >
        <div className="modal__body-actions">
          <TextButton color="purple" title="понятно" onClick={appStore.hideAllCatIsFullModal} />
        </div>
      </Modal>
      <NotEnoughBalanceModal />
    </>
  );
};

Main.displayName = 'Main';

export default observer(Main);
