import classNames from '../../../common/classNames';
import { CatFaceIcon, CoinIcon } from '../../icons';
import SpoilerIcon from '../../icons/SpoilerIcon';
import React, { useEffect, useState } from 'react';
import CatUrlImage from '../CatUrlImage';
import CoinModalIcon from '../../icons/modal/CoinModalIcon';

const iconStyle = {
  verticalAlign: 'middle',
  marginLeft: '4px',
  marginRight: '14px',
  width: '24px',
  height: '24px',
};

function TeamHistoryItem({
  isCollapseOpen,
  teamHistoryTitle,
  teamHistoryDesc,
  teamHistoryPrice,
  setCollapseOpen,
  teamHistoryStyleCollapseTop,
  teamHistoryStyleCollapseBottom,
  isTeamHistorySpoilerExist,
  catsContents,
  priceVision,
  catCountVision,
  areaNum = null,
}) {
  const [isCollapseOpenForTrades, setIsCollapseOpenForTrades] = useState(false);
  useEffect(() => {
    setIsCollapseOpenForTrades(false);
  }, [areaNum]);
  if (!priceVision) {
    teamHistoryPrice = false;
  }
  const catCount = !catCountVision
    ? 0
    : catsContents.reduce((acc, cat) => {
        return acc + cat[0].contents.length;
      }, 0);
  return (
    <>
      <div
        className={classNames({
          'team-history__item': true,
          'team-history__item-collapse-top': teamHistoryStyleCollapseTop,
          'team-history__item-collapse-bottom': teamHistoryStyleCollapseBottom && !isCollapseOpenForTrades,
        })}
        // season={season}
        // isCollapseOpen={isCollapseOpen}
        // onClick={() => {
        //
        // }}
      >
        <div className="team-history__item--first-col">{teamHistoryTitle}</div>
        <div className="team-history__item--second-col">{teamHistoryDesc}</div>
        <div className="team-history__item--third-col">
          {!!teamHistoryPrice && teamHistoryPrice}
          {catCountVision && catCount && (
            <>
              {catCount}
              <CatFaceIcon className="team-history__cats-content-icon" fill="#9F795E" style={iconStyle} />
            </>
          )}
          {!!teamHistoryPrice && (
            <CoinIcon
              style={{
                verticalAlign: 'middle',
                marginLeft: '4px',
                marginRight: '14px',
                width: '24px',
                height: '24px',
              }}
            />
          )}
          {isTeamHistorySpoilerExist ? (
            <SpoilerIcon
              onClick={() => {
                setCollapseOpen
                  ? setCollapseOpen(!isCollapseOpen)
                  : setIsCollapseOpenForTrades(!isCollapseOpenForTrades);
              }}
              isOpen={isCollapseOpenForTrades}
            />
          ) : (
            <div style={{ width: '16px', height: '16px' }}></div>
          )}
        </div>
      </div>
      {(isCollapseOpenForTrades || isCollapseOpen) && catsContents.length > 0 && (
        <div
          // key={ind + 'collapse-cats'}
          className={classNames({
            'team-history__cats': true,
            'team-history__item-collapse-bottom': teamHistoryStyleCollapseBottom,
          })}
        >
          {catsContents.map((similarCats) => {
            const price = similarCats[0].totalPrice || similarCats[0].price * similarCats[0].count * similarCats.length;
            const count = similarCats[0].totalCount || similarCats[0].count * similarCats.length;
            return (
              <div key={similarCats[0].gender + similarCats[0].color} className="team-history__cats-item">
                {/* ToDo возможно поедет верстка из-за BCC-181, проверить, если так, то поправить */}
                <CatUrlImage
                  className="team-history__cats-img"
                  color={similarCats[0].color}
                  gender={similarCats[0].gender}
                />
                <div className="team-history__cats-content">
                  {priceVision && (
                    <>
                      <div className="team-history__cats-content--price">{price}</div>
                      <CoinModalIcon className="team-history__cats-content-icon team-history__cats-content-icon--coin" />
                    </>
                  )}
                  <div className="team-history__cats-content--count">×{count}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default TeamHistoryItem;
