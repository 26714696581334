import trainingStore from '../../trainingStore';
import RootStore from '../../index';
import { PLAYER_NAME } from '../../../common/constants';

export const INFO_MODAL_POSITION = {
  BOTTOM: 'bottom',
  LEFT: 'left',
  LEFT_MARGIN: 'left-margin',
  RIGHT: 'right',
  TOP: 'top',
};

/**
 * Proxy позволяет получить ID игрока по имени в TRAINING
 * @type {{MATROSKIN: string, MAU: string, SPHINX: string, SIMBA: string, FRIEND: string, LOYALTY: string, MOUSER: string, MATILDA: string, FORTUNE: string, ZOOCENTER: string, MAINE_COON: string, FAVORITE: string, FELIX: string, PREMIUM: string, MURZIK: string, GARFIELD: string, TOM: string, KITTEN_WOOF: string, STRIPED: string, BRITISH: string, KITTY: string, SIAMESE: string, LEOPOLD: string, BONIFACE: string, KOTOFEY: string}}
 */
export const TRAINING_PLAYER_ID = new Proxy(PLAYER_NAME, {
  get(names, key) {
    if (!trainingStore?.launched) {
      return null;
    }
    const player = RootStore.appStore.players.find((player) => names[key] === player.name);
    return player?.uuid || null;
  },
  set() {
    return;
  },
});
