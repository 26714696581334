import RootStore from '../../index';
import { LocalStorage } from '../../../common/localStorage';
import routes from '../../../routes';
import { TRAINING_CHAPTER_TYPE } from '../../constants';

class Training {
  #chaptersGenerators;
  constructor({ chapters = null, chaptersGenerators = null }) {
    this.chapters = chapters;
    this.#chaptersGenerators = chaptersGenerators;
  }

  generateChapters() {
    if (this.chapters) {
      return;
    }

    this.chapters = this.#chaptersGenerators.map((generator) => generator());
    this.chapters.forEach((chapter, index) => {
      chapter.nextChapter = this.chapters[index + 1] ?? null;
      chapter.previousChapter = this.chapters[index - 1] ?? null;
    });
  }

  resetChapters() {
    this.chapters = null;
  }

  checkTrainingProgress(launchingChapter) {
    if (launchingChapter.chapter > RootStore.appStore.tutorialProgress.chapter + 1) {
      RootStore.goTo(LocalStorage.USER_TOKEN_TRAINING ? routes.training : routes.startTraining, {
        training_type: TRAINING_CHAPTER_TYPE.BASIC,
      });
      return false;
    }
    return true;
  }
}

export default Training;
