import React from 'react';

function SicknessFracture({ style, className }) {
  return (
    <svg
      className={className}
      style={style}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M30.9071 23.5171C30.9071 23.5171 29.3458 21.758 29.3458 20.3946C29.3458 19.0313 30.7932 18.6307 30.9071 17.2721C31.0444 15.6341 29.346 14.7267 29.3458 13.3634C29.3456 11.9997 30.7548 11.3589 30.8685 10C31.0055 8.3623 29.346 7.69512 29.3458 6.33206C29.3456 4.96869 30.9071 3.20952 30.9071 3.20952C31.0436 2.93741 31.029 2.61385 30.8685 2.35463C30.708 2.09541 30.4256 1.9375 30.1209 1.9375H20.3882C22.4736 2.96553 23.5 4 23.8514 6.332C24.6309 13.8094 24.7684 17.8146 24.0928 24.789H30.1209C30.4256 24.789 30.708 24.6311 30.8685 24.3719C31.029 24.1127 31.0436 23.7892 30.9071 23.5171Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00013 9.65259C3.27012 11.5187 7.42192 12.4844 11.547 12.4844C15.6721 12.4844 19.8238 11.5187 22.0939 9.65259C22.6724 15.8524 22.5806 19.367 22.0939 25.6679C21.5 28 17.372 30.0624 11.547 30.0624C5.72205 30.0624 1.49999 28.5 1.00013 25.6679C0.237207 21.3449 0.50006 13 1.00013 9.65259ZM13.6271 16.9132L13.7291 18.6931L14.9655 18.6987C16.734 18.707 17.1024 19.0903 16.9779 20.7923C16.8715 22.2469 16.8648 22.2533 15.2995 22.3664L13.7274 22.4796L13.6264 24.24L13.525 26H12.0192H10.5134L10.4114 24.2201C10.3113 22.4721 10.2892 22.4402 9.18398 22.4402C7.35528 22.4402 6.99993 22.1352 6.99993 20.5667C6.99993 18.9944 7.35428 18.6931 9.20238 18.6931C10.3284 18.6931 10.3461 18.668 10.3461 17.0695C10.3461 16.1762 10.4535 15.3252 10.585 15.1783C10.7162 15.0311 11.4316 14.961 12.1745 15.0221L13.525 15.1334L13.6271 16.9132Z"
      />
      <path d="M11.5466 8.96875C8.43353 8.96875 6.39165 9.47002 5.63379 9.84766C5.9918 10.0261 6.64594 10.2308 7.55468 10.3985C8.78668 10.6087 10.1331 10.7266 11.5466 10.7266C12.9603 10.7266 14.3067 10.6087 15.5386 10.3985C16.4473 10.2308 17.1015 10.0261 17.4595 9.84766C16.7016 9.47002 14.6597 8.96875 11.5466 8.96875Z" />
      <path d="M11.5469 1.93774C5.72192 1.93774 1 3.90522 1 6.33224C1 7.47489 2.05551 8.51224 3.77103 9.29389C4.67327 7.32395 10.3319 7.21116 11.5469 7.21116C12.7618 7.21116 18.4205 7.32389 19.3227 9.29383C21.0384 8.51224 22.0938 7.47489 22.0938 6.33224C22.0937 3.90522 17.3718 1.93774 11.5469 1.93774Z" />
    </svg>
  );
}

export default SicknessFracture;
