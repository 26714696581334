import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import StartSessionParameters from './StartSessionParameters';
import corpStore from '../../store/CorpStore';
import trainingStore from '../../store/trainingStore';
import { GAME_TYPES } from '../../store/constants';

function IntroductionModal() {
  const { appStore } = RootStore;

  if (!appStore.introductionModalFlag) {
    return;
  }

  const seasonCount = appStore.turnDurationsSec.length;
  const teamsCount = Object.keys(corpStore.areas).length;
  const { randomGameEndBegin, withRandomGameEnd } = appStore;

  const seasonsMessage = withRandomGameEnd
    ? `Количество сезонов в игре случайно - от ${randomGameEndBegin} до ${seasonCount}`
    : `Количество сезонов в игре - ${seasonCount}`;

  const introductionMessages = {
    [GAME_TYPES.SOLO]: `Теперь ты играешь против других игроков. ${seasonsMessage}. Твоя цель набрать максимальное количество денег на конец последнего сезона. Удачи!`,
    [GAME_TYPES.SINGLE]: `Ты играешь против ботов, у ботов ограниченное количество денег, иногда придется продавать котят по одному, а не всех сразу. ${seasonsMessage}.`,
    [GAME_TYPES.TEAM]: `Теперь ты играешь в команде из 5 человек, в зависимости от роли у тебя будет определенный функционал. ${seasonsMessage}. Цель команды - набрать максимальное количество денег на конец последнего сезона. Удачи!`,
    [GAME_TYPES.CORP]: appStore.meIsCEO
      ? `Теперь у тебя появился шанс попробовать управлять не одной командной, а сразу ${teamsCount} командами одновременно. Не забудь настроить делегирование под свою стратегию, определить лимиты на траты и выделить бюджет на каждый город. ${seasonsMessage}. Помни, ты владеешь корпорацией и ваша цель - набрать максимальное количество денег на конец последнего сезона. Удачи!`
      : `Ты играешь в команде из 5 человек, но теперь у команды есть главный исполнительный директор, который выстраивает стратегию для всей корпорации. В зависимости от роли у тебя будет определенный функционал. ${seasonsMessage}. Цель команды - набрать максимальное количество денег на конец последнего сезона в рамках всей корпорации. Удачи!`,
    [GAME_TYPES.REALTIME]: `В данном режиме ты играешь без точных сезонов и теперь необходимо следить за всеми действиями в реальном времени. Удачи!`,
  };

  return (
    <Modal
      isOpen={appStore.introductionModalFlag}
      onClose={appStore.hideIntroductionModal}
      size="big"
      title={`Добро пожаловать`}
    >
      <div className="modal__body-actions">
        <div className="modal__start">{introductionMessages[appStore.gameType]}</div>
        <StartSessionParameters />
        <TextButton color="blue" title="Понятно" onClick={appStore.hideIntroductionModal} />
      </div>
    </Modal>
  );
}

export default observer(IntroductionModal);
