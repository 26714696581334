const formattedEstimationTimer = (estimationTimer) => {
  let timerMinutes = Math.floor(estimationTimer / 60);
  if (timerMinutes < 10) {
    timerMinutes = `0${timerMinutes}`;
  }
  let timerSeconds = estimationTimer % 60;
  if (timerSeconds < 10) {
    timerSeconds = `0${timerSeconds}`;
  }
  return `${timerMinutes}:${timerSeconds}`;
};

export default formattedEstimationTimer;
