import React from 'react';
import { observer } from 'mobx-react';
import corpStore from '../../../../store/CorpStore';
import city_trade_img from '../../../../assets/basic/trade/elements/city_trade_img.png';
import Cat, { CAT_STATE } from '../../../../ui/components/Cat/Cat';
import { DropContainer } from '../../../deal/DropContainer';
import { draggableOptionsDefault } from '../../../../ui/components/Cat/draggableOptionsDefault';
import { LockIcon } from '../../../../ui/icons';
import { DRAG_AREA } from '../../../../common/constants';
import RootStore from '../../../../store';
function ImportExportField() {
  if (!corpStore.myActiveCompanies.length) {
    return (
      <div className="lot-area lot-area--shop lot-area--shop-disallow">
        <LockIcon />
      </div>
    );
  }
  const fromType = DRAG_AREA.IMPORT_EXPORT_ZONE;
  const groupedCats = RootStore.appStore.cityQuota;

  return (
    <DropContainer
      type={fromType}
      element={({ canDrop, drop }) => (
        <div
          className="lot-area lot-area--city"
          ref={drop}
          style={
            canDrop
              ? {
                  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #abc1e2',
                  boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.1)',
                }
              : {}
          }
        >
          <div className="lot-area__info">
            <span style={{ backgroundImage: `url(${city_trade_img})` }} className="lot-area__image" />
            <p className="lot-area__info__city-text">ИМПОРТ / ЭКСПОРТ</p>
          </div>
          <div className="lot-area__cats">
            {groupedCats.map((item, index) => (
              <Cat
                from={fromType}
                dragAround={true}
                key={index}
                cat={item}
                state={CAT_STATE.DEFAULT}
                notShowCount={true}
                onDoubleClick={() => {
                  if (!draggableOptionsDefault.canDrag(item, fromType)) return;
                  corpStore.addCatToOperation(item, DRAG_AREA.IMPORT_EXPORT_ZONE, DRAG_AREA.TRANSFER_ZONE);
                }}
              />
            ))}
          </div>
        </div>
      )}
    />
  );
}

export default observer(ImportExportField);
