import { declOfNum } from '../declensionOfWords';
import RootStore from '../../store';

export function catCount(count, fullText = true) {
  /**
   * При переводе на другие языки есть необходимость убирать склонение для более корректного перевода
   */
  const inflectedWord = RootStore.appStore.currentPageLanguageIsRussian
    ? declOfNum(count, ['КОТИК', 'КОТИКА', 'КОТИКОВ'])
    : 'кот';
  if (fullText) {
    return `×${count} ${inflectedWord}`;
  } else {
    return inflectedWord;
  }
}
