import React from 'react';

function SicknessRingworm({ style, className }) {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3846 6.03366C27.3846 7.58258 26.7813 8.99281 25.7979 10.0415H4.69028C5.13438 7.24965 7.55234 5.10876 10.4615 5.10876H17.5477C20.6683 5.10876 23.6021 3.89104 25.8089 1.68018C26.0729 1.41551 26.4701 1.33649 26.8148 1.4796C27.1598 1.6227 27.3846 1.96003 27.3846 2.33405V6.03366ZM29.2307 13.7553V12.816C29.2307 12.4918 29.1746 12.1807 29.072 11.8911H2.92798C2.82544 12.1807 2.76929 12.4918 2.76929 12.816V13.7553C1.24219 13.7553 0 15.0002 0 16.53V27.9224C0 29.4525 1.24219 30.6971 2.76929 30.6971H29.2307C30.7578 30.6971 32 29.4525 32 27.9224V16.53C32 15.0002 30.7578 13.7553 29.2307 13.7553ZM17.5927 18.9134L17.6946 20.6932L18.9286 20.6989C20.6936 20.7071 21.0613 21.0904 20.937 22.7924C20.8308 24.247 20.8241 24.2534 19.2618 24.3665L17.6929 24.4797L17.592 26.2401L17.4908 28.0001H15.988H14.4852L14.3833 26.2202C14.2834 24.4722 14.2614 24.4404 13.1583 24.4404C11.3332 24.4404 10.9785 24.1353 10.9785 22.5668C10.9785 20.9945 11.3322 20.6932 13.1767 20.6932C14.3005 20.6932 14.3182 20.6681 14.3182 19.0696C14.3182 18.1763 14.4254 17.3253 14.5566 17.1784C14.6875 17.0312 15.4015 16.9611 16.143 17.0222L17.4908 17.1335L17.5927 18.9134Z"
      />
    </svg>
  );
}

export default SicknessRingworm;
