import React from 'react';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import './LTDTerminationModal.scss';
import Modal from '../Modal/Modal';
import TextButton from '../../buttons/TextButton/TextButton';
import NumberInput from '../../NumberInput/NumberInput';
import { RadioButton } from '../RadioButton';
import classNames from '../../../common/classNames';
import { EDITABLE_FIELD, LTD_FINES_PAYMENT } from '../../../common/constants';

function LTDTerminationModal() {
  const { appStore } = RootStore;

  if (!appStore.LTDTerminationData) {
    return <Modal isOpen={false} />;
  }

  const terminationDetail = appStore.LTDTerminationData;
  const termination = appStore.currentLTDTerminationConfirmed;

  const oneWayTerminationValue = termination.one_way_termination
    ? termination.one_way_termination.payment_type === LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT
      ? appStore.getFineForDealAmount(termination.one_way_termination.value, {
          ltd_info: terminationDetail.currentLTD,
        })
      : termination.one_way_termination.value
    : null;

  const Termination = observer((props) => {
    return (
      <>
        {(props.isShowOneWayCondition || props.fieldName === EDITABLE_FIELD.TWO_WAY_TERMINATION) && (
          <div className="modal-termination__body-element">
            {props.isShowOneWayCondition && !terminationDetail.isConfirmed && (
              <RadioButton
                value={
                  props.fieldName === EDITABLE_FIELD.ONE_WAY_TERMINATION
                    ? !termination.is_two_way
                    : termination.is_two_way
                }
                onClick={() => {
                  appStore.changeLTDTerminationConfirmed(
                    EDITABLE_FIELD.IS_TWO_WAY,
                    null,
                    props.fieldName !== EDITABLE_FIELD.ONE_WAY_TERMINATION,
                  );
                }}
              />
            )}
            <span className="modal-termination__text">{props.title}</span>
            <NumberInput
              readOnly={props.readOnly}
              value={
                props.fieldName === EDITABLE_FIELD.ONE_WAY_TERMINATION
                  ? oneWayTerminationValue
                  : termination.two_way_termination.value
              }
              oldValue={
                termination.two_way_termination.value !== termination.two_way_termination.old_value &&
                termination.two_way_termination.old_value
              }
              onChange={(newValue) => {
                appStore.changeLTDTerminationConfirmed(props.fieldName, EDITABLE_FIELD.VALUE, newValue);
              }}
            />
          </div>
        )}
      </>
    );
  });

  return (
    <Modal
      isOpen={!!appStore.LTDTerminationData}
      onClose={appStore.hideLTDTerminationModal}
      title={
        terminationDetail.isConfirmed
          ? `Контрагент ${terminationDetail.partner.name} хочет расторгнуть сделку по соглашению сторон`
          : `Расторгнуть сделку с зоомагазином ${terminationDetail.partner.name}?`
      }
      desc={
        terminationDetail.isConfirmed
          ? `Согласуй или установи свои условия расторжения:`
          : `${
              termination.one_way_termination && termination.one_way_termination.value > 0 ? 'Выберите' : ''
            } условия расторжения сделки:`
      }
      customClass="termination"
      size="very-big"
      exitIcon={true}
    >
      <div className="modal-termination__body">
        {!terminationDetail.isConfirmed && (
          <Termination
            title="Расторгнуть без указания причин и выплатить контрагенту"
            fieldName={EDITABLE_FIELD.ONE_WAY_TERMINATION}
            isShowOneWayCondition={termination.isShowOneWayCondition}
            readOnly={true}
          />
        )}
        <Termination
          title={
            !terminationDetail.payer || terminationDetail.payer === appStore.me.uuid
              ? `Расторгнуть по соглашению сторон и выплатить контрагенту`
              : `Расторгнуть по соглашению сторон и получить от контрагента`
          }
          fieldName={EDITABLE_FIELD.TWO_WAY_TERMINATION}
          isShowOneWayCondition={termination.isShowOneWayCondition}
        />
        <div
          className={classNames({
            'modal-termination__body-element--flex': terminationDetail.isConfirmed,
          })}
        >
          <TextButton
            customClass={'modal-termination__body-action'}
            color="red"
            title="Расторгнуть"
            onClick={() => {
              if (!termination.is_two_way && !terminationDetail.isConfirmed) {
                appStore.LTDOneWayTerminationRequest({
                  deal_id: terminationDetail.deal_id,
                  value: oneWayTerminationValue,
                });
              } else {
                appStore.LTDTwoWayTerminationRequest({
                  deal_id: terminationDetail.deal_id,
                  value: termination.two_way_termination.value,
                });
              }
            }}
          />
          {terminationDetail.isConfirmed && (
            <TextButton
              customClass={'modal-termination__body-action'}
              color="blue"
              title="Отмена расторжения"
              onClick={() => {
                appStore.LTDTerminationCancel(terminationDetail.deal_id);
                appStore.hideLTDTerminationModal();
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}

export default observer(LTDTerminationModal);
