import classNames from '../../common/classNames';
import CheckMarkIcon from '../icons/CheckMarkIcon';
import React from 'react';

export const Checkbox = ({ value, onClick, disabled }) => {
  return (
    <div
      className={classNames({
        checkbox: true,
        'checkbox--active': !!value,
        disabled: disabled,
      })}
      onClick={onClick}
    >
      {!!value && <CheckMarkIcon fill={'#FDF6EE'} width={18} height={19} />}
    </div>
  );
};
