import React from 'react';
import Modal from './Modal/Modal';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import MultiFreeshModalIcon from '../icons/modal/MultiFreeshModalIcon';

function FeedAllCats() {
  const { appStore } = RootStore;

  if (!appStore.feedAllCatsModal) {
    return null;
  }
  const foodPrice =
    appStore.allMyHungryCats.length - appStore.goods.food >= 0
      ? appStore.allMyHungryCats.length - appStore.goods.food
      : 0;

  return (
    <Modal
      isOpen={appStore.feedAllCatsModal}
      onClose={appStore.hideFeedAllCatModal}
      title="Ты уверен, что хочешь покормить всех котиков?"
    >
      <div className="modal__body-price">
        <MultiFreeshModalIcon fill={'rgba(198, 167, 136, 1)'} />
        <>
          <p className="modal__body-price-text">ЦЕНА корма</p>
          <p className="modal__body-price-coin">
            <CoinModalIcon className="modal__body-price-coin-icon" />
            <span className="modal__body-price-coin-count notranslate"> {foodPrice} </span>
          </p>
        </>
      </div>
      <div className="modal__body-actions">
        <TextButton
          color="green"
          title="покормить"
          onClick={() => appStore.feedAllCat(appStore.allMyHungryCats, foodPrice)}
        />
        <TextButton color="purple" title="отмена" onClick={appStore.hideFeedAllCatModal} />
      </div>
    </Modal>
  );
}

export default observer(FeedAllCats);
