import RootStore from '../../index';
import React from 'react';
import { LOT_STATUS, MESSAGE_TYPE } from '../../constants';
import trainingStore from '../../trainingStore';
import trainings from './index';
import api from '../../../api';
import { LocalStorage } from '../../../common/localStorage';
import routes from '../../../routes';
import CoinIcon from '../../../ui/icons/CoinIcon';
import CoinModalIcon from '../../../ui/icons/modal/CoinModalIcon';
import ChapterSixIcon from '../../../ui/icons/training/ChapterSixIcon';
import PlusModalIcon from '../../../ui/icons/modal/PlusModalIcon';
import EquallyModalIcon from '../../../ui/icons/modal/EquallyModalIcon';
import LikeModalIcon from '../../../ui/icons/modal/LikeModalIcon';
import _ from 'lodash';
import { CAT_COLOR, CAT_GENDER, MATING_STATUS } from '../../../common/constants';

export const chapter = 6;

export const stepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 5,
          sell_price: 5,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000003',
      name: 'bot 2',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 7,
          sell_price: 7,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: false,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 0,
      default_prices_override: {
        male_black: {
          buy_price: 8,
          sell_price: 8,
        },
      },
      cats: [
        {
          color: 'black',
          gender: 'male',
        },
      ],
    },
  ],
  turn_durations: [100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  city_quota_override: {
    male_gray: {
      buy_price: 2,
      sell_price: 2,
      buy_quantity: 100,
    },
  },
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
};

export const taskApiParams = {
  game_type: 'tutorial',
  chapter_num: 6,
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 10,
          sell_price: 10,
        },
        female_black: {
          buy_price: 11,
          sell_price: 11,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: false,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000003',
      name: 'bot 2',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 9,
          sell_price: 9,
        },
        female_black: {
          buy_price: 12,
          sell_price: 12,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: false,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 0,
      default_prices_override: {
        male_black: {
          buy_price: 8,
          sell_price: 8,
        },
        female_black: {
          buy_price: 10,
          sell_price: 10,
        },
      },
      cats: [
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'female',
        },
        {
          color: 'black',
          gender: 'female',
        },
      ],
    },
  ],
  turn_durations: [2 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  city_quota_override: {
    male_gray: {
      buy_price: 2,
      sell_price: 2,
      buy_quantity: 100,
    },
  },
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
};

export const steps = [
  {
    actionName: 'onNextButton',
    step: 179, // По вордовскому документу
    chapter: '6',
    chapterIcon: <ChapterSixIcon className="training-modal__chapter-icon" />,
    title: <div>ДОБРО ПОЖАЛОВАТЬ В ГЛАВУ «ЦЕНОВАЯ ПОЛИТИКА. ПИТОМНИК»!</div>,
    message: (
      <p className="training-modal__desc">
        Цены помогают покупателям понять, могут ли они позволить себе купить товар или же он слишком дорог для них. От
        цены зависит, купят товар у вас или у другого продавца. Надо уметь ориентироваться на клиента, в нашем случае –
        на зоомагазины.
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 180,
    title: 'ФОРМИРОВАНИЕ ЦЕНЫ',
    message: (
      <p className="training-modal__desc">
        В этой главе ты научишься формировать такую цену на свой товар, чтобы она была привлекательна для покупателя и
        выгодна для тебя, как для продавца.
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
    title: 'Зайди в зоомагазин.',
    message: <div></div>,
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--shop .cat--male-black .cat__price-wrapper'],
    disabledElements: ['.lot-area.lot-area--shop .cat--male-black .cat__price-wrapper'],
    title: <div>СРАВНЕНИЕ ЦЕН</div>,
    message: (
      <p className="training-modal__desc">
        <span>Зоомагазин «Бонифаций» покупает котят</span> <br /> <span>по</span>
        <span className="notranslate"> 5</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
      </p>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'setCurrentPlayer',
    elements: ['.slick-arrow.slick-next'],
    pulseElements: ['.slick-arrow.slick-next'],
    title: 'СРАВНЕНИЕ ЦЕН',
    message: <p className="training-modal__desc">Теперь посмотри, по какой цене покупает котят другой магазин.</p>,
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--shop .cat--male-black .cat__price-wrapper'],
    disabledElements: ['.lot-area.lot-area--shop .cat--male-black .cat__price-wrapper'],
    title: 'СРАВНЕНИЕ ЦЕН',
    message: (
      <p className="training-modal__desc">
        <span>Зоомагазин «ПОЛОСАТЫЙ» покупает котят</span> <br />
        <span>по</span> <span className="notranslate"> 7</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span>. Это самая высокая цена, поставь такую же цену на своего котенка.</span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'setOpenedGroupedCat',
    elements: ['.lot-area.lot-area--nursery .cat--male-black'],
    title: 'МЕНЯЕМ ЦЕНЫ',
    isDisabledDoubleClickCat: true,
    message: (
      <p className="training-modal__desc">
        <span className="notranslate"> 7</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span> </span>
        <span>
          – самая высокая цена, по которой магазины покупают котят. Нажми на кота и поставь такую же на своего котенка,
          потому что по
        </span>
        <span className="notranslate"> 8</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span> твоего котенка никто не купит.</span>
      </p>
    ),
  },
  {
    actionName: 'sellPriceMinus',
    elements: ['.training-price-sell'],
    disabledElements: ['.magnifier-button', '.training-price-sell .price-plus'],
    title: 'МЕНЯЕМ ЦЕНЫ',
    needOverlayClickModal: false,
    message: <p className="training-modal__desc">Нажми на кнопку «Минус», чтобы снизить цену.</p>,
  },
  {
    actionName: 'setOpenedGroupedCat',
    elements: ['.modal--grouped-cat .text_button--color-blue'],
    disabledElements: ['.magnifier-button'],
    title: 'УТВЕРДИ ЦЕНЫ',
    needOverlayClickModal: false,
    message: (
      <p className="training-modal__desc">Теперь нажми кнопку «СОХРАНИТЬ», чтобы магазины увидели твою новую цену.</p>
    ),
  },
  {
    actionName: 'onNextButton',
    title: 'МОЛОДЕЦ!',
    message: <p className="training-modal__desc">Изменив цену, ты сможешь получить заявку от зоомагазина.</p>,
    nextButton: true,
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000003`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({
              msg_type: 'trading_request',
              to: LocalStorage.USER_UID,
              request_type: 'buy',
              contents: [{ color: 'black', gender: 'male', price: 7, count: 1 }],
            }),
          );
        }, 50);
      };
    },
  },
  {
    actionName: 'onReceiveMessage__trading_lot_update',
  },
  {
    actionName: 'setCurrentLot',
    elements: ['.mail:last-child'],
    pulseElements: ['.mail:last-child'],
    step: 185,
    title: 'ЗАЯВКА!',
    message: <p className="training-modal__desc">Нажми на конверт, чтобы посмотреть предложение зоомагазина.</p>,
    position: 'left',
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area.lot-area--lot'],
    title: 'ЗАЯВКА!',
    message: (
      <p className="training-modal__desc">Зоомагазин хочет купить твоего котика по цене, которую мы выставили.</p>
    ),
    nextButton: true,
    position: 'top',
  },
  {
    actionName: 'acceptLot',
    elements: ['.lot-area__lot-panel-btn-wrapper .text_button--color-green'],
    pulseElements: ['.lot-area__lot-panel-btn-wrapper .text_button--color-green'],
    title: 'ОДОБРЯЕМ!',
    message: <div></div>,
    position: 'left',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000003`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({ msg_type: MESSAGE_TYPE.TRADING_CONFIRM, lot_id: RootStore.appStore.lots[0].lot_id }),
          );
        }, 300);
      };
    },
  },

  {
    actionName: 'notificationModalOpen',
    elements: ['.mail:last-child'],
    pulseElements: ['.mail:last-child'],
  },
  {
    actionName: 'notificationModalClose',
  },
  {
    actionName: 'onNextButton',
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isWide: true,
    message: (
      <div>
        <p className="training-modal__desc">
          Внимательно изучайте витрины зоомагазинов, чтобы продавать своих котят по самым выгодным ценам. Эта подсказка
          поможет пройти практическое задание:
        </p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">Было на счете</p>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">наибольшая цена продажи</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">стало на счете</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">0</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">7</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">7</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--center">
          <div className="training-modal__price">
            <p className="training-modal__price-name">ПРИБЫЛЬ</p>
            <div className="training-modal__price-value">
              <span className="training-modal__price-cost training-modal__price-cost--green notranslate">+7</span>
              <CoinModalIcon className="training-modal__price-coin" />
            </div>
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    title: 'ВНИМАНИЕ, ТАЙМЕР!',
    message: (
      <p className="training-modal__desc">
        Время на выполнение практического задания ограничено. Если твои цены будут привлекательны, зоомагазины будут
        присылать тебе предложения. Твоя задача – успеть установить нужные цены до поступления предложений.
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    colorBg: 'green',
    title: (
      <div>
        <span>ПОЗДРАВЛЯЕМ!</span>
        <br /> <span>ОБУЧЕНИЕ ПО</span> <span className="notranslate"> 6&nbsp;</span>
        <span>ГЛАВЕ ЗАВЕРШЕНО</span>
      </div>
    ),
    message: (
      <div>
        <p className="training-modal__desc">Чтобы перейти на следующий уровень, выполни практическое задание.</p>
        <div className="training-modal__icon-block">
          <LikeModalIcon className="training-modal__icon" />
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
];

export const task = {
  chapter: 6,
  data: { confirmedLotCount: 0, firstLaunch: true, currentLotGameUID: '' },
  watch: [
    {
      actionName: 'hideTaskModal',
      checkParams: (params, data) => {
        // через 1 минуту отправить заявку игроку, если его цена устраивает
        if (data.firstLaunch) {
          data.firstLaunch = false;
          data.currentLotGameUID = LocalStorage.GAME_UID;
          setTimeout(() => {
            const maleBlackPrice = _.find(RootStore.appStore.me.default_prices, {
              gender: CAT_GENDER.MALE,
              color: CAT_COLOR.BLACK,
            }).sell_price;
            const femaleBlackPrice = _.find(RootStore.appStore.me.default_prices, {
              gender: CAT_GENDER.FEMALE,
              color: CAT_COLOR.BLACK,
            }).sell_price;
            if (maleBlackPrice <= 10 || femaleBlackPrice <= 11) {
              const socket = new WebSocket(
                `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000002`,
              );
              socket.onopen = function (event) {
                if (LocalStorage.GAME_UID !== null && data.currentLotGameUID !== LocalStorage.GAME_UID) {
                  return;
                }
                setTimeout(() => {
                  socket.send(
                    JSON.stringify({
                      msg_type: 'trading_request',
                      to: LocalStorage.USER_UID,
                      request_type: 'buy',
                      contents: [
                        ...(maleBlackPrice <= 10
                          ? [{ color: 'black', gender: 'male', price: maleBlackPrice, count: 4 }]
                          : []),
                        ...(femaleBlackPrice <= 11
                          ? [{ color: 'black', gender: 'female', price: femaleBlackPrice, count: 2 }]
                          : []),
                      ],
                    }),
                  );
                }, 100);
              };
            }
            if (maleBlackPrice <= 9 || femaleBlackPrice <= 12) {
              const socket = new WebSocket(
                `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/00000000-0000-0000-0000-000000000003`,
              );
              socket.onopen = function (event) {
                if (LocalStorage.GAME_UID !== null && data.currentLotGameUID !== LocalStorage.GAME_UID) {
                  return;
                }
                setTimeout(() => {
                  socket.send(
                    JSON.stringify({
                      msg_type: 'trading_request',
                      to: LocalStorage.USER_UID,
                      request_type: 'buy',
                      contents: [
                        ...(maleBlackPrice <= 9
                          ? [{ color: 'black', gender: 'male', price: maleBlackPrice, count: 4 }]
                          : []),
                        ...(femaleBlackPrice <= 12
                          ? [{ color: 'black', gender: 'female', price: femaleBlackPrice, count: 2 }]
                          : []),
                      ],
                    }),
                  );
                }, 100);
              };
            }
          }, 60 * 1000);
        }
        return 'skip';
      },
    },
    {
      actionName: 'onReceiveMessage__balance',
      checkParams: (params) => {
        if (params[0].current_balance === 64) {
          return true;
        }
        return 'skip';
      },
    },
    {
      actionName: 'onReceiveMessage__game_over',
      checkParams: (params) => {
        return false;
      },
    },
    {
      actionName: 'onReceiveMessage__trading_lot_update',
      checkParams: (params) => {
        if (params[0].initiator !== LocalStorage.USER_UID && params[0].old_contents) {
          if (params[0].status === LOT_STATUS.IN_PROGRESS) {
            // бот соглашается на лайкнутую игроком заявку
            const socket = new WebSocket(
              `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/${params[0].initiator}`,
            );
            socket.onopen = function (event) {
              setTimeout(() => {
                socket.send(
                  JSON.stringify({
                    msg_type: MESSAGE_TYPE.TRADING_CONFIRM,
                    lot_id: params[0].lot_id,
                  }),
                );
              }, 100);
            };
          }
        }
        if (!RootStore.appStore.ourCats.length && RootStore.appStore.balance < 64) {
          return false;
        }
        return 'skip';
      },
    },
  ],
  title: (
    <div>
      <span className="notranslate">6 </span> <span>ГЛАВА «ЦЕНОВАЯ ПОЛИТИКА. ПИТОМНИК» ПРАКТИЧЕСКОЕ ЗАДАНИЕ</span>
    </div>
  ),
  message: (
    <p className="training-modal__desc">
      <span>У тебя</span> <span className="notranslate"> 0</span>
      <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span> и котята:</span>
      <span className="notranslate"> 4 </span> мальчика и <span className="notranslate"> 2 </span>
      <span>девочки. Твоя задача – заработать</span> <span className="notranslate"> 64</span>
      <CoinIcon style={{ position: 'relative', top: '2px' }} />
      <span>
        . Верно установи цены на продажу своих котят и увидишь, купят ли их зоомагазины. Время выполнения задания
        ограничено!
      </span>
    </p>
  ),
  wrongMessage: (
    <div className="training-modal__price training-modal__price--single">
      <p className="training-modal__price-name">
        <span>Не получилось</span>
        <br />
        <span>заработать</span>
      </p>
      <div className="training-modal__price-value">
        <CoinModalIcon className="training-modal__price-coin" />
        <span className="training-modal__price-cost training-modal__price-cost--white notranslate">64</span>
      </div>
    </div>
  ),
};
