import React from 'react';

function InsuranceModalIcon({ style, stylePath, className }) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        style={stylePath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1094 11.25C29.3147 11.25 26.25 13.9971 26.25 18.4375V22.5H53.75V18.4375C53.75 13.9971 50.6853 11.25 47.8906 11.25H32.1094ZM18.75 17.1094C18.75 9.7312 24.7312 3.75 32.1094 3.75H47.8906C55.2688 3.75 61.25 9.7312 61.25 17.1094V27.5H18.75V17.1094Z"
        fill="#C6A788"
      />
      <path
        style={stylePath}
        d="M44.323 45.8935L44.0678 41.8483L43.8127 37.8031L40.4364 37.5502C38.5792 37.4113 36.7907 37.5706 36.4628 37.9053C36.134 38.2391 35.8655 40.1732 35.8655 42.2034C35.8655 45.8364 35.8211 45.8935 33.0061 45.8935C28.3859 45.8935 27.5 46.5782 27.5 50.1515C27.5 53.7164 28.3884 54.4096 32.9601 54.4096C35.7232 54.4096 35.7785 54.482 36.0286 58.4548L36.2837 62.5H40.0482H43.8127L44.0661 58.5L44.3188 54.4991L48.2489 54.2419C52.1622 53.9847 52.179 53.9702 52.445 50.6642C52.7562 46.7962 51.8351 45.925 47.414 45.9062L44.323 45.8935Z"
        fill="#C6A788"
      />
      <path
        style={stylePath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 67.3148V32.6852C0 27.0601 4.47716 22.5 10 22.5H21.4634H57.8862H70C75.5229 22.5 80 27.0601 80 32.6852V67.3148C80 72.9399 75.5229 77.5 70 77.5H10C4.47715 77.5 0 72.9399 0 67.3148ZM40 70C51.0457 70 60 61.0457 60 50C60 38.9543 51.0457 30 40 30C28.9543 30 20 38.9543 20 50C20 61.0457 28.9543 70 40 70Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default InsuranceModalIcon;
