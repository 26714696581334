import React from 'react';
import classNames from '../../common/classNames';

function CloseIcon({ style, isDisabled = false, customClass, onClick }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
      className={classNames({
        'close-icon': true,
        'close-icon--disabled': isDisabled,
        [`${customClass}`]: !!customClass,
      })}
    >
      <g clipPath="url(#closeIcon0)">
        <path
          d="M2.98627 6.38476C2.20801 5.49627 1.53126 4.59642 1.48221 4.38486C1.42519 4.13769 1.85931 3.53424 2.69686 2.6967C3.5344 1.85916 4.13784 1.42504 4.38501 1.48205C4.59658 1.53111 5.49643 2.20785 6.38492 2.98611L8.00016 4.40133L9.61538 2.9861C10.5039 2.20784 11.4039 1.53093 11.6153 1.48206C11.8625 1.42505 12.4659 1.85916 13.3035 2.6967C14.141 3.53424 14.5751 4.13768 14.5181 4.38485C14.4692 4.59622 13.7923 5.49628 13.0141 6.38477L11.5988 8L13.0194 9.60993C13.8006 10.4954 14.4776 11.3954 14.5234 11.6098C14.577 11.8597 14.1382 12.4686 13.3035 13.3033C12.4659 14.1408 11.8625 14.575 11.6153 14.518C11.4039 14.4691 10.5039 13.7921 9.61539 13.0139L8.00016 11.5987L6.38493 13.0139C5.49644 13.7922 4.59657 14.4689 4.385 14.5179C4.13783 14.5749 3.5344 14.1408 2.69686 13.3033C1.85931 12.4658 1.42521 11.8623 1.48222 11.6152C1.53127 11.4036 2.208 10.5037 2.98626 9.61523L4.40149 8L2.98627 6.38476Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="closeIcon0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloseIcon;
