import React from 'react';
import Modal from './Modal/Modal';
import RootStore from '../../store';
import { observer } from 'mobx-react';
import WalletIcon from '../icons/modal/WalletIcon';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import TextButton from '../buttons/TextButton/TextButton';
import { ALLOCATION_NOTIFICATION_TYPES } from '../../store/constants';
import corpStore from '../../store/CorpStore';

export const ALLOCATION_TITLES = {
  [ALLOCATION_NOTIFICATION_TYPES.YOU_ALLOCATED_BUDGET]: 'Вы выделили бюджет',
  [ALLOCATION_NOTIFICATION_TYPES.CEO_ALLOCATED_YOU_BUDGET]: 'СЕО выделил вам бюджет',
  [ALLOCATION_NOTIFICATION_TYPES.YOU_DONT_HAVE_ENOUGH_MONEY]: 'У вас недостаточно средств',
  [ALLOCATION_NOTIFICATION_TYPES.YOU_WITHDRAWN_BUDGET]: 'Вы изъяли средства из бюджета',
  [ALLOCATION_NOTIFICATION_TYPES.CEO_WITHDREW_YOUR_BUDGET]: 'СЕО изъял средства из вашего бюджета',
  [ALLOCATION_NOTIFICATION_TYPES.CAPTAIN_DONT_HAVE_ENOUGH_MONEY]: 'недостаточно средств',
};

export const ALLOCATION_COLORS = {
  [ALLOCATION_NOTIFICATION_TYPES.YOU_ALLOCATED_BUDGET]: 'green',
  [ALLOCATION_NOTIFICATION_TYPES.CEO_ALLOCATED_YOU_BUDGET]: 'green',
  [ALLOCATION_NOTIFICATION_TYPES.YOU_DONT_HAVE_ENOUGH_MONEY]: 'red',
  [ALLOCATION_NOTIFICATION_TYPES.YOU_WITHDRAWN_BUDGET]: 'green',
  [ALLOCATION_NOTIFICATION_TYPES.CEO_WITHDREW_YOUR_BUDGET]: 'red',
  [ALLOCATION_NOTIFICATION_TYPES.CAPTAIN_DONT_HAVE_ENOUGH_MONEY]: 'red',
};

const BudgetAllocationNotificationModal = () => {
  const { appStore } = RootStore;
  const data = corpStore.budgetAllocationNotificationModal;

  if (!data) {
    return null;
  }

  let areaNum;
  if (RootStore.appStore.meIsCEO) {
    areaNum = corpStore.areaNumByCaptainId(data.captainId);
  } else {
    areaNum = appStore.areaNum;
  }

  let desc;

  if (data.type === ALLOCATION_NOTIFICATION_TYPES.YOU_ALLOCATED_BUDGET) {
    desc = 'В город ' + areaNum;
  } else if (
    data.type === ALLOCATION_NOTIFICATION_TYPES.YOU_WITHDRAWN_BUDGET ||
    data.type === ALLOCATION_NOTIFICATION_TYPES.CAPTAIN_DONT_HAVE_ENOUGH_MONEY
  ) {
    desc = 'В городе ' + areaNum;
  }

  return (
    <Modal
      isOpen={!!data}
      onClose={() => corpStore.hideBudgetAllocationNotificationModal()}
      title={ALLOCATION_TITLES[data.type]}
      colorBg={ALLOCATION_COLORS[data.type]}
      desc={desc}
    >
      <WalletIcon className="modal__body-icon" color="white" />

      <div className="modal__body-price">
        <p className="modal__body-price-text">итого сумма</p>
        <p className="modal__body-price-coin">
          <CoinModalIcon className="modal__body-price-coin-icon" />
          <span className="modal__body-price-coin-count">{data.money}</span>
        </p>
      </div>

      <div className="modal__body-actions">
        <TextButton title="Понятно" color="blue" onClick={() => corpStore.hideBudgetAllocationNotificationModal()} />
      </div>
    </Modal>
  );
};

export default observer(BudgetAllocationNotificationModal);
