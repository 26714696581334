import React, { useCallback, useMemo } from 'react';
import TextButton from '../../../../ui/buttons/TextButton/TextButton';
import { observer } from 'mobx-react';
import RootStore from '../../../../store';
import { DislikeIcon, LikeIcon, CloseIcon, CoinIcon, SendIcon, CatFaceIcon } from '../../../../ui/icons';
import { toJS } from 'mobx';
import LotPanelActions from './LotPanelActions';
import LotPanelSighting from './lotPanelSighting/LotPanelSighting';
import LotPanelPrice from './LotPanelPrice';

function LotPanel() {
  return (
    <div className="lot-area__lot-panel">
      <div className="lot-area__lot-panel__wrap">
        <LotPanelSighting />
        <LotPanelPrice />
        <LotPanelActions />
      </div>
    </div>
  );
}

export default observer(LotPanel);
