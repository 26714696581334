import React, { useState } from 'react';
import PlusIcon from '../../icons/PlusIcon';
import Modal from '../Modal/Modal';
import TextButton from '../../buttons/TextButton/TextButton';
import { Checkbox } from '../Checkbox';
import { SPECIALITIES_RUSSIAN_NAME, PRIVILEGES_I18N_RU } from '../../../common/constants';

function AddNewSpeciality({ specialties, privileges, addSpecialties, addPrivileges }) {
  const [isChangeSpecialityList, setIsChangeSpecialityList] = useState(false);
  const [checkedSpecialties, setCheckedSpecialties] = useState([]);
  const [checkedPrivileges, setCheckedPrivileges] = useState([]);

  return isChangeSpecialityList ? (
    <Modal isOpen={true} onClose={() => setIsChangeSpecialityList(false)} title="Выберите добавляемые полномочия">
      <div>
        {specialties.map((speciality) => {
          return (
            <div style={{ marginBottom: '16px', display: 'flex' }} key={speciality}>
              <Checkbox
                value={checkedSpecialties.includes(speciality)}
                onClick={() => {
                  if (checkedSpecialties.includes(speciality)) {
                    setCheckedSpecialties(checkedSpecialties.filter((val) => val !== speciality));
                  } else {
                    setCheckedSpecialties([...checkedSpecialties, speciality]);
                  }
                }}
              />
              <span style={{ textTransform: 'uppercase', alignSelf: 'center', color: '#7A6652', marginLeft: '6px' }}>
                {SPECIALITIES_RUSSIAN_NAME[speciality]}
              </span>
            </div>
          );
        })}
        {privileges.map((privilege) => {
          return (
            <div style={{ marginBottom: '16px', display: 'flex' }} key={privilege}>
              <Checkbox
                value={checkedPrivileges.includes(privilege)}
                onClick={() => {
                  if (checkedPrivileges.includes(privilege)) {
                    setCheckedPrivileges(checkedPrivileges.filter((val) => val !== privilege));
                  } else {
                    setCheckedPrivileges([...checkedPrivileges, privilege]);
                  }
                }}
              />
              <span style={{ textTransform: 'uppercase', alignSelf: 'center', color: '#7A6652', marginLeft: '6px' }}>
                {PRIVILEGES_I18N_RU[privilege]}
              </span>
            </div>
          );
        })}
      </div>
      <div className="modal__body-actions">
        <TextButton
          color="blue"
          title="Добавить"
          onClick={() => {
            addSpecialties(checkedSpecialties);
            addPrivileges(checkedPrivileges);
          }}
        />
      </div>
    </Modal>
  ) : (
    <div
      onClick={() => {
        setIsChangeSpecialityList(true);
      }}
      className="add-new-role"
    >
      <PlusIcon width={21} height={21} />
    </div>
  );
}

export default AddNewSpeciality;
