import React from 'react';

function EatCalcIcon({ style, className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.84986e-10 20.9581C9.14317e-05 14.915 2.90909 8.19807 2.90909 8.19807C6.19355 3.89523 26.0909 3.9729 29 8.19808C29 8.19808 32 15.4643 32 20.9581C32 23.705 29 28.867 13.5 29C5.49997 29.0687 -4.57153e-05 23.9796 2.84986e-10 20.9581ZM17.5 12.5C26.0847 12.5 28 11.0311 28 9.3742C28 7.71735 22.4959 6.5 15.9153 6.5C9.33466 6.5 4 7.84314 4 9.5C4 11.1569 8.9153 12.5 17.5 12.5ZM24.9999 10.5C24.9999 10.9418 20.3492 11.5 16 11.5C11.6508 11.5 6.99992 10.9418 6.99992 10.5C6.99992 10.0582 9.5 9 16 9C22.6778 9 24.9999 10.0582 24.9999 10.5ZM15.8225 16.9245C15.8225 17.6141 15.2617 18.1749 14.5721 18.1749C13.8825 18.1749 13.3217 17.6141 13.3217 16.9245C13.3217 16.3658 13.7597 15 14.5721 15C15.3845 15 15.8225 16.3658 15.8225 16.9245ZM12.8314 20.4746C13.4275 20.1301 13.6358 19.3669 13.289 18.7666C13.0093 18.2827 11.9255 17.3198 11.223 17.725C10.5197 18.1316 10.844 19.5334 11.123 20.017C11.4675 20.6128 12.2297 20.8216 12.8314 20.4746ZM18.9485 16.9245C18.9485 17.6141 18.3877 18.1749 17.6981 18.1749C17.0085 18.1749 16.4477 17.6141 16.4477 16.9245C16.4477 16.3658 16.8858 15 17.6981 15C18.5105 15 18.9485 16.3658 18.9485 16.9245ZM19.4328 20.4633C20.0349 20.8107 20.8182 20.6001 21.1617 20.0054C21.4906 19.4359 21.6933 18.0904 21.0411 17.7134C20.3362 17.3074 19.2542 18.2711 18.9752 18.755C18.6298 19.3528 18.8339 20.1171 19.4328 20.4633ZM13.9469 20.5433V20.3631C13.9469 18.8001 15.5938 18.8001 16.1351 18.8001C16.6764 18.8001 18.3233 18.8001 18.3233 20.3631V20.5433C18.3233 20.6712 18.3996 20.785 18.5181 20.833C19.1595 21.094 19.5738 21.6459 19.5738 22.2387C19.5738 23.1006 18.7324 23.8018 17.6981 23.8018C17.4914 23.8018 17.3092 23.781 17.141 23.7379C16.4822 23.5719 15.7871 23.571 15.1314 23.7377C14.9616 23.7807 14.7788 23.8018 14.5721 23.8018C13.5378 23.8018 12.6965 23.1005 12.6965 22.2387C12.6965 21.6459 13.1107 21.0939 13.7521 20.8332C13.8705 20.785 13.9469 20.6712 13.9469 20.5433Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default EatCalcIcon;
