import RootStore from '../store';
import { SPECIALITIES } from './constants';

const isVisibleLot = (lot) => {
  const { appStore } = RootStore;
  return (
    (appStore.hasSpecialities([SPECIALITIES.CAPTAIN]) && lot.initiator === appStore.me.uuid) ||
    (appStore.hasSpecialities([SPECIALITIES.CAPTAIN]) && lot.initiator !== appStore.me.uuid && lot.updater) ||
    (appStore.hasSpecialities([SPECIALITIES.SUPPLY]) && lot.buyer === appStore.me.uuid) ||
    (appStore.hasSpecialities([SPECIALITIES.SALES]) && lot.seller === appStore.me.uuid)
  );
};

export default isVisibleLot;
