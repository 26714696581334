import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import ExportModalIcon from '../icons/modal/ExportModalIcon';

function ExportOnMainScreenModal() {
  const { appStore } = RootStore;

  if (!appStore.exportOnMainScreenModal) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.exportOnMainScreenModal}
      onClose={appStore.hideExportOnMainScreenModal}
      title="Полноэкранный режим на твоём устройстве невозможен"
      desc="Попробуй добавить этот сайт на главный экран. в панели навигации нажми на кнопку Поделиться"
    >
      <ExportModalIcon className="modal__body-icon" />
      <p className="modal__body__desc">
        в появившемся меню выбери На экран «Домой». Нажми кнопку Добавить и на экране телефона появится необходимый
        ярлык.
      </p>
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.hideExportOnMainScreenModal} />
      </div>
    </Modal>
  );
}

export default observer(ExportOnMainScreenModal);
