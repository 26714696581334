import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import WatcherModalIcon from '../icons/modal/WatcherModalIcon';

function SpectatorModal() {
  const { appStore } = RootStore;

  if (!appStore.spectatorModalShow) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.spectatorModalShow}
      onClose={appStore.hideSpectatorModal}
      title="Режим наблюдателя"
      desc="текущее действие запрещено, так как вы являетесь наблюдателем"
      style={{ zIndex: 101 }}
    >
      <WatcherModalIcon className="modal__body-icon" />
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.hideSpectatorModal} />
      </div>
    </Modal>
  );
}

export default observer(SpectatorModal);
