import React from 'react';
import { observer } from 'mobx-react';
import TextButton from '../../buttons/TextButton/TextButton';
import '../../../pages/training.scss';
import TrainingLogo from '../../icons/TrainingLogo';
import Settings from '../Settings';
import AlabugaIcon from '../../icons/AlabugaIcon';
import { PLATFORM_URLS } from '../../../common/platformUrls';
import classNames from '../../../common/classNames';

const PageLayout = ({ children, isTrainingLTD = false }) => {
  return (
    <div
      className={classNames({
        training: true,
        'training--grid': isTrainingLTD,
      })}
    >
      <div className="training__header">
        <div className="training__header-avatar">
          <TrainingLogo />
          <div className="training__header-info">
            <p className="training__header-name">ОБУЧЕНИЕ</p>
          </div>
        </div>
        <div className="training__header-action">
          <AlabugaIcon />
        </div>
        <div className="training__header-action">
          <Settings />
          <TextButton color="red" title="ВЫЙТИ" onClick={() => (location.href = PLATFORM_URLS.CLIENT)} />
        </div>
      </div>
      {children}
    </div>
  );
};

export default observer(PageLayout);
