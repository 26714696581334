import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import '../training.scss';
import trainingStore from '../../store/trainingStore';
import { TRAINING_CHAPTER_TYPE } from '../../store/constants';
import ChapterTemplate from '../../ui/components/Trainings/ChapterTemplate';
import PageLayout from '../../ui/components/Trainings/PageLayout';
import ltdTraining from '../../store/trainings/LTDTrainings';

const LTDTraining = () => {
  const { appStore } = RootStore;
  const currentProgress = appStore.tutorialProgress.chapter;
  const chapters = ltdTraining.chapters;

  chapters.forEach((chapterInfo, index) => {
    chapterInfo.active = currentProgress >= index;
    chapterInfo.headerIsVisible = false;
    chapterInfo.clickStart = () =>
      trainingStore.launchChapter({ chapter: chapterInfo, type: TRAINING_CHAPTER_TYPE.LTD });
  });

  return (
    <PageLayout isTrainingLTD={true}>
      <div className="training__container training__container--ltd">
        <div className="training__lessons training__lessons--ltd">
          {chapters.slice(0, 4).map((chapter, index) => {
            return (
              <ChapterTemplate
                key={'ChapterTemplate__' + index + Math.random()}
                chapter={chapter}
                customClass="training__lesson--ltd"
              />
            );
          })}
        </div>
        <div className="training__lessons training__lessons--ltd">
          {chapters.slice(4).map((chapter, index) => {
            return (
              <ChapterTemplate
                key={'ChapterTemplate__' + index + Math.random()}
                chapter={chapter}
                customClass="training__lesson--ltd"
              />
            );
          })}
        </div>
      </div>
      <div className="training__container training__container--mobile"></div>
    </PageLayout>
  );
};

export default observer(LTDTraining);
