import React, { useState } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import IconButton from '../../buttons/IconButton/IconButton';
import ChatIcon from '../../icons/ChatIcon';
import classNames from '../../../common/classNames';
import { LocalStorage } from '../../../common/localStorage';
import trainingStore from '../../../store/trainingStore';

function HeaderChatButton() {
  const { appStore } = RootStore;
  const chatToggle = () => {
    if (appStore.messengerInited) {
      window.AlbChat.toggle();
    }
  };
  const [messageCount, setMessageCount] = useState(0);

  if (!appStore.messengerInited) {
    return null;
  }

  window.addEventListener('AlbChatCounters', (evt) => {
    let newMessageCount = 0;
    const countByMembersId = evt.detail.countByMembersId;
    for (let key in countByMembersId) {
      newMessageCount += countByMembersId[key];
    }
    setMessageCount(newMessageCount);
  });

  return (
    <IconButton customClass="header__chat-button" color="blue" onClick={chatToggle}>
      <ChatIcon style={{ width: '24px', height: '24px', display: 'block' }} />
      {messageCount > 0 && <span className="header__chat-button--message-count">{messageCount}</span>}
    </IconButton>
  );
}

export default observer(HeaderChatButton);
