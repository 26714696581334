import React from 'react';
import RootStore from '../../../store';
import classNames from '../../../common/classNames';
import { observer } from 'mobx-react';
import routes from '../../../routes';
import {
  ArrowRightIcon,
  ReplyIcon,
  ArrowLeftIcon,
  CloseIcon,
  AcceptIcon,
  RevisionIcon,
  PendingRevisionIcon,
  CatFaceIcon,
  TeamDoctorIcon,
  HeartIcon,
  CatPawUnionIcon,
  HouseIcon,
  EatIcon,
} from '../../icons';
import * as teamMode from '../../../store/teamMode';
import { LTD_CONTENT_TYPE, REQUEST_TYPE, SPECIALITIES } from '../../../common/constants';
import BankIcon from '../../icons/BankIcon';
import CarIcon from '../../icons/CarIcon';
import { MAIL_TYPE, TASK_TYPES, TEAM_TASK_STATUS } from '../../../store/constants';
import { FAKE_LTD_TEAM_TASK_STATUS_MAIL_TYPE } from '../../../store/constants';

function Mail({ lot = null, task, empty = false, size }) {
  const { appStore } = RootStore;

  const Indicators = {
    Treatment: TeamDoctorIcon,
    Showcase: CatPawUnionIcon,
    LotBuy: CatFaceIcon,
    BuyFood: EatIcon,
    House: HouseIcon,
    Mating: HeartIcon,
    Credit: BankIcon,
  };

  const getType = (lot) => {
    if (appStore.incomingLots.includes(lot)) {
      return MAIL_TYPE.INCOMING;
    } else if (appStore.outcomingLots.includes(lot)) {
      return MAIL_TYPE.OUTCOMING;
    } else if (appStore.revisionLots.includes(lot)) {
      return MAIL_TYPE.REVISION;
    } else if (appStore.pendingRevisionLots.includes(lot)) {
      return MAIL_TYPE.PENDING_REVISION;
    } else if (appStore.counterLots.includes(lot)) {
      return MAIL_TYPE.COUNTER;
    } else if (appStore.cancelledLots.includes(lot)) {
      return MAIL_TYPE.CANCELLED;
    } else if (appStore.confirmedLots.includes(lot)) {
      return MAIL_TYPE.CONFIRMED;
    } else if (appStore.isCorpMode) {
      return MAIL_TYPE.CANCELLED;
    }
  };

  let type = null;
  let Logo = null;
  let player = null;
  let indicator = null;
  let customMailClass = null;
  if (!empty) {
    if (
      !lot &&
      task.type !== TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST &&
      task.type !== TASK_TYPES.FAKE_DOCTOR_REVIEW_REQUEST &&
      task.type !== TASK_TYPES.FAKE_LTD_ACTION_TASK &&
      task.type !== TASK_TYPES.CREATE_CHANGE_LTD_REQUEST
    ) {
      if (task.type === TASK_TYPES.BUY_DRUGS) {
        indicator = <Indicators.Treatment />;
      } else if (task.type === TASK_TYPES.BUY_FOOD) {
        indicator = <Indicators.BuyFood />;
      } else if (task.type === TASK_TYPES.BUY_HOUSE) {
        indicator = <Indicators.House />;
      } else if (task.type === TASK_TYPES.CHANGE_SHOWCASE_PRICES) {
        indicator = <Indicators.Showcase />;
      } else if (task.type === TASK_TYPES.BUY_HOUSE_INSURANCE) {
        indicator = <Indicators.House />;
      } else if (task.type === TASK_TYPES.MATING) {
        indicator = <Indicators.Mating />;
      } else if (task.type === TASK_TYPES.GET_CREDIT || task.type === TASK_TYPES.CREDIT_REPAY) {
        indicator = <Indicators.Credit />;
      } else if (task.type === TASK_TYPES.FAKE_IMPORT_TASK) {
        indicator = <Indicators.LotBuy />;
        customMailClass = 'cat-icon';
      }
    } else {
      if (!lot && task && task.content.lot_id && TASK_TYPES.FAKE_LTD_ACTION_TASK !== task.type) {
        lot = appStore.lotById(task.content.lot_id);
      }
      if (
        appStore.hasSpecialities(SPECIALITIES.DOCTOR) &&
        task &&
        task.deal_id &&
        task.type === TASK_TYPES.FAKE_DOCTOR_REVIEW_REQUEST
      ) {
        player = task.partner_captain;
        type = 'counter';
        indicator = <CatFaceIcon className="mail__indicator-buy" />;
      } else if (task && TASK_TYPES.FAKE_LTD_ACTION_TASK === task.type && task.deal_id) {
        player = task.partner_captain;
        type = 'counter';
        indicator = <CatFaceIcon className="mail__indicator-buy" />;
        type = FAKE_LTD_TEAM_TASK_STATUS_MAIL_TYPE[task.status];
      } else if (task?.type === TASK_TYPES.CREATE_CHANGE_LTD_REQUEST) {
        type = 'outcoming';

        const revision =
          [SPECIALITIES.SALES, SPECIALITIES.SUPPLY].includes(task.stage) &&
          [SPECIALITIES.CAPTAIN, SPECIALITIES.MARKETEER].includes(task.specialty_by);

        if (!revision && task.content.deal_id) {
          type = MAIL_TYPE.INCOMING;
        }

        if (revision && task.stage === appStore.mySpeciality) {
          type = MAIL_TYPE.REVISION;
        }
        if (task.status === TEAM_TASK_STATUS.IN_PROGRESS && task.is_counter) {
          type = MAIL_TYPE.COUNTER;
        }

        if (
          task.status === TEAM_TASK_STATUS.IN_PROGRESS &&
          revision &&
          [SPECIALITIES.CAPTAIN, SPECIALITIES.MARKETEER].includes(appStore.mySpeciality)
        ) {
          type = MAIL_TYPE.PENDING_REVISION;
        }

        player = appStore.playerByUuid(task.content.to);
        Logo = player?.logo ?? null;

        indicator = <CatFaceIcon className="mail__indicator-buy" />;
      } else if (!lot) {
        player = appStore.playerByUuid(task.content.to);
        type = 'outcoming';
        indicator =
          task.content.request_type === REQUEST_TYPE.BUY ? (
            <CatFaceIcon className="mail__indicator-buy" />
          ) : (
            <span className="mail__indicator-sale" />
          );
        Logo = player?.logo ?? null;
      } else {
        player = appStore.playerByUuid(lot.seller === appStore.me.uuid ? lot.buyer : lot.seller);
        type = getType(lot);
        indicator =
          lot.buyer === appStore.me.uuid ? (
            <CatFaceIcon className="mail__indicator-buy" />
          ) : (
            <span className="mail__indicator-sale" />
          );
      }
      Logo = player?.logo ?? null;
    }
  }

  return (
    <div
      className={classNames({
        mail: true,
        'mail--current-notification': !empty && !task && lot.lot_id === appStore.currentPlayer?.lot?.lot_id,
        'mail--size-mini': size === 'mini',
      })}
      onClick={() => {
        if (task) {
          return;
        }
        if (
          ['incoming', 'counter', 'outcoming', 'revision', 'pendingRevision'].includes(type) ||
          appStore.isSpectatorModeOn
        ) {
          appStore.setCurrentLot(lot.lot_id);
          RootStore.router.goTo(routes.inside, { uuid: appStore.currentPlayer.uuid });
        } else {
          appStore.notificationModalOpen(lot);
          appStore.hideLot(lot.lot_id);
        }
      }}
    >
      <span className={`mail__icon mail__icon--${type}`}>
        {type === 'outcoming' && <ArrowRightIcon />}
        {type === 'counter' && <ReplyIcon />}
        {type === 'incoming' && <ArrowLeftIcon />}
        {type === 'cancelled' && <CloseIcon style={{ fill: '#FDF6EE' }} />}
        {type === 'confirmed' && <AcceptIcon />}
        {type === 'pendingRevision' && <PendingRevisionIcon />}
        {type === 'revision' && <RevisionIcon />}
      </span>
      {Logo && <Logo />}
      <div
        className={classNames({ mail__indicator: true, [`mail__indicator--${customMailClass}`]: !!customMailClass })}
      >
        {indicator}
      </div>
    </div>
  );
}

export default observer(Mail);
