import React from 'react';

function WalletIcon({ style, stylePath, className, color = '#FDF6EE' }) {
  return (
    <svg viewBox="0 0 80 80" fill={color} xmlns="http://www.w3.org/2000/svg" className={className} style={style}>
      <path
        d="M38.4375 9.84375C39.7319 9.84375 40.7812 8.79438 40.7812 7.5V2.34375C40.7812 1.04937 39.7319 0 38.4375 0C37.1431 0 36.0938 1.04937 36.0938 2.34375V7.5C36.0938 8.79438 37.1431 9.84375 38.4375 9.84375Z"
        fill={color}
        style={stylePath}
      />
      <path
        d="M22.4242 10.6853L26.3741 13.9996C27.3721 14.837 28.8493 14.6959 29.676 13.7107C30.508 12.7192 30.3788 11.2409 29.3871 10.4089L25.4372 7.09449C24.4453 6.2623 22.9672 6.39183 22.1353 7.38339C21.3033 8.3748 21.4325 9.85324 22.4242 10.6853Z"
        fill={color}
        style={stylePath}
      />
      <path
        d="M50.5007 13.9996L54.4506 10.6853C55.4423 9.85323 55.5715 8.37479 54.7395 7.38339C53.9076 6.39183 52.429 6.26245 51.4376 7.09448L47.4877 10.4089C46.496 11.2409 46.3668 12.7193 47.1988 13.7107C48.0283 14.6993 49.5063 14.834 50.5007 13.9996Z"
        fill={color}
        style={stylePath}
      />
      <path
        d="M17.5 60.5002C17.5 60.224 17.7239 60.0002 18 60.0002H27C27.2761 60.0002 27.5 60.224 27.5 60.5002V64.5C27.5 64.7761 27.2761 65 27 65H18C17.7239 65 17.5 64.7761 17.5 64.5V60.5002Z"
        fill={color}
        style={stylePath}
      />
      <path
        d="M59.6871 64.531C55.3402 63.2267 50.9987 56.8132 50.9987 56.8132C50.0834 55.8979 50.0834 54.4139 50.9987 53.4986C50.9987 53.4986 55.3142 46.995 59.6871 45.781C61.9294 45.1585 68.5536 45.3746 73.7496 45.781V38.906C73.7496 35.0289 70.5954 31.8747 66.7184 31.8747H8.51528C6.65653 31.8747 4.45459 31.8747 3.43715 30.4474C1.25011 38.75 0.00022912 55 3.43715 69.3747C4.51754 73.8934 7.6904 77.9908 12.8122 78.7497C21.2502 80 45.6649 80.504 66.7184 78.7497C70.0004 78.4763 73.7496 75.5224 73.7496 71.7663V64.531C68.2837 64.8826 61.2502 65 59.6871 64.531ZM31.5622 66.8747C31.5622 68.1691 30.5128 69.2185 29.2184 69.2185H15.1559C13.8615 69.2185 12.8122 68.1691 12.8122 66.8747V57.4997C12.8122 56.2054 13.8615 55.156 15.1559 55.156H29.2184C30.5128 55.156 31.5622 56.2054 31.5622 57.4997V66.8747Z"
        fill={color}
        style={stylePath}
      />
      <path
        d="M59.6875 50.5001C58.9211 51.1132 56.2751 53.8565 55.3348 54.8375C55.1388 55.042 55.1139 55.3531 55.2795 55.583C56.0804 56.6944 58.4079 59.7728 59.6875 59.8751C61.2156 59.9973 69.3759 60.754 75.4938 59.9331C75.7416 59.8998 75.9396 59.7125 75.99 59.4676C76.6105 56.4502 76.6712 54.4665 75.9861 50.9146C75.9382 50.6661 75.7407 50.4754 75.4894 50.4453C71.6303 49.9827 60.5901 49.7781 59.6875 50.5001Z"
        fill={color}
        style={stylePath}
      />
      <path
        d="M8.35938 28.4375H68.9062V25.3125C68.9062 21.4292 65.6892 19.0104 61.875 18.2812C51.25 16.25 17.5 16.25 8.35938 18.2812C5.62159 18.8896 3.28125 20.5548 3.28125 23.3594C3.28125 26.1639 5.55484 28.4375 8.35938 28.4375Z"
        fill={color}
        style={stylePath}
      />
    </svg>
  );
}

export default WalletIcon;
