import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarMilkImage from '../../../assets/nurseryAvatars/avatarMilk.png';

function AvatarMilk({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <path
    //     d="M21.3281 10.602C22.0167 10.5662 22.5653 10.0017 22.5653 9.30434C22.5653 8.58395 21.9813 8 21.261 8H14.7392C14.0188 8 13.4349 8.58395 13.4349 9.30434C13.4349 10.0017 13.9835 10.5662 14.6721 10.6019C14.2649 14.2844 12.7827 14.527 12.7827 18.231V26.0435C12.7827 27.1223 13.6604 28 14.7392 28H21.261C22.3399 28 23.2175 27.1223 23.2175 26.0435V18.231C23.2175 14.5207 21.7363 14.2936 21.3281 10.602ZM17.5742 16.0628C16.6708 15.4605 15.5995 15.2333 14.5825 15.3591C14.7006 15.0241 14.8378 14.6961 14.9965 14.3786C15.5271 13.3169 15.8571 11.8718 15.9837 10.6087H20.0165C20.1431 11.8718 20.4731 13.3169 21.0037 14.3786C21.2247 14.8207 21.4067 15.2825 21.5486 15.7564C21.0705 15.9936 19.5003 17.3468 17.5742 16.0628Z"
    //     fill="#02516C"
    //   />
    // </svg>
    <Avatar style={style} avatarImage={AvatarMilkImage} />
  );
}

export default AvatarMilk;
