import React from 'react';

function SicknessModalIcon({ style, stylePath, className }) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#sicknessModalIcon0)">
        <path
          d="M2.05938 67.9955C0.73125 69.3251 0 71.0908 0 72.9689C0 76.855 3.14531 80.0003 7.03281 80.0003C8.90938 80.0003 10.675 79.269 12.0031 77.9409L20.525 69.4173L10.5828 59.4719L2.05938 67.9955Z"
          fill="#FDF6EE"
          style={stylePath}
        />
        <path
          d="M77.4602 13.5398L66.457 2.53499C63.0805 -0.844696 57.582 -0.844696 54.2008 2.53343L24.5492 32.19C22.3383 34.3994 20.6227 36.9931 19.4508 39.9011L13.1914 55.4512L24.543 66.8092L40.0961 60.5466C42.9977 59.3778 45.5914 57.6637 47.807 55.4497L77.4602 25.7931C79.0976 24.1572 79.9992 21.9806 79.9992 19.6666C79.9992 17.3508 79.0976 15.1758 77.4602 13.5398ZM55.0711 34.8697L39.9539 49.987C39.043 50.898 37.5602 50.9073 36.6398 49.987L30.0102 43.3572C29.0945 42.4416 29.0945 40.9572 30.0102 40.0431L45.1273 24.9242C46.043 24.0086 47.5273 24.0101 48.443 24.9242L55.0711 31.5539C55.9867 32.4697 55.9867 33.9541 55.0711 34.8697ZM65.293 24.6244C62.6977 27.6322 57.9555 27.9072 55.0227 24.9728C52.2742 22.2291 52.2773 17.7742 55.0227 15.0289C57.768 12.2851 62.2352 12.2961 64.9664 15.0305C67.6305 17.6914 67.6773 21.893 65.293 24.6244Z"
          fill="#FDF6EE"
          style={stylePath}
        />
        <path
          d="M34.9756 41.6985L46.7769 29.8971L50.0921 33.2123L38.2907 45.0136L34.9756 41.6985Z"
          fill="#FDF6EE"
          style={stylePath}
        />
        <path
          d="M59.9924 17.6588C58.7111 17.6588 57.6533 18.6932 57.6533 20.0025C57.6533 21.3057 58.7049 22.3447 59.9955 22.3447C61.2955 22.3447 62.3393 21.2916 62.3393 20.0025C62.3393 18.69 61.2736 17.6588 59.9924 17.6588Z"
          fill="#FDF6EE"
          style={stylePath}
        />
      </g>
      <defs>
        <clipPath id="sicknessModalIcon0">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SicknessModalIcon;
