import React from 'react';
import { observer } from 'mobx-react';
import trainingStore from '../../store/trainingStore';
import TextButton from '../buttons/TextButton/TextButton';
import classNames from '../../common/classNames';
import ReactModal from 'react-modal';
import ErrorModalIcon from '../icons/modal/ErrorModalIcon';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import CalcIcon from '../icons/calc/CalcIcon';
import HideModalIcon from '../icons/training/HideModalIcon';
import ShowModalIcon from '../icons/training/ShowModalIcon';
import routes from '../../routes';
import RootStore from '../../store';

function Training() {
  if (!trainingStore.currentStep || [routes.startTraining, routes.training].includes(RootStore.router.currentRoute)) {
    return null;
  }
  return (
    <>
      <div className="training-wrapper" />
      <span
        className={classNames({
          'training-modal-show-button': true,
          'training-modal-show-button--hide': trainingStore.showedStepModal,
        })}
        onClick={() => {
          trainingStore.showStepModal();
          trainingStore.checkIsCorrectAction('showStepModalClick');
        }}
      >
        <ShowModalIcon />
      </span>
      {!!trainingStore.currentStep.message && (
        <div
          className={classNames({
            'training-modal': true,
            'training-modal--big': trainingStore.currentStep.isWide,
            'training-modal--very-big': trainingStore.currentStep.isVeryWide,
            'training-modal--max': trainingStore.currentStep.isMax,
            'training-modal--chapter': trainingStore.currentStep.chapter,
            'training-modal--emotion': trainingStore.currentStep.emotion,
            'training-modal--emotion--all': trainingStore.currentStep.isEmotionsAll,
            'training-modal--person-cats': trainingStore.currentStep.personCats,
            'training-modal--person-cats--only-cats': trainingStore.currentStep.isOnlyCats,
            'training-modal--hide': !trainingStore.showedStepModal || trainingStore.showErrorModal,
            [`training-modal--${trainingStore.currentStep.colorBg}`]: trainingStore.currentStep.colorBg,
          })}
        >
          <span className="training-modal__hide-button" onClick={() => trainingStore.hideStepModal()}>
            <HideModalIcon />
          </span>
          {trainingStore.currentStep.personCats}
          {!!trainingStore.currentStep.chapter && (
            <div className="training-modal__chapter">
              <p className="training-modal__chapter-text">Глава {trainingStore.currentStep.chapter}</p>
              {trainingStore.currentStep.chapterIcon}
            </div>
          )}
          {!!trainingStore.currentStep.emotion && (
            <div className="training-modal__emotion">{trainingStore.currentStep.emotion}</div>
          )}
          <div className="training-modal__body">
            <p className="training-modal__title">{trainingStore.currentStep.title}</p>
            <div className="training-modal__content">{trainingStore.currentStep.message}</div>
            {!!trainingStore.currentStep.nextButton && (
              <div
                className={classNames({
                  'training-modal__actions': true,
                  'training-modal__actions--showed': trainingStore.showedNextButton,
                })}
              >
                <TextButton
                  color="blue"
                  title={trainingStore.currentStep.buttonText ? trainingStore.currentStep.buttonText : 'Дальше'}
                  onClick={() => {
                    trainingStore.checkIsCorrectAction('onNextButton');
                  }}
                />
              </div>
            )}
            {trainingStore.currentStep.personCatsGinger}
          </div>
        </div>
      )}
      {!!trainingStore.calculator.headers.length && (
        <div
          className={classNames({
            'training-calc': true,
            'training-calc--rolled': trainingStore.isRolledCalc,
          })}
        >
          <div
            className="training-calc__block-arrow"
            onClick={() => trainingStore.setRolledCalc(!trainingStore.isRolledCalc)}
          >
            <svg
              className="training-calc__arrow"
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4954 7.89139C11.5129 3.88719 11.4787 0.530174 11.4193 0.431783C11.2099 0.0835906 10.5023 0.261279 9.14324 1.00352C5.12775 3.19631 0.5 6.81911 0.5 7.7696C0.5 8.39625 2.01376 9.82388 4.81164 11.8357C7.19857 13.552 10.4715 15.3572 11.013 15.2562C11.4373 15.1772 11.4659 14.7428 11.4954 7.89139Z"
                fill="#C6A788"
              />
            </svg>
          </div>
          <table>
            <tbody>
              <tr className="training-calc__header">
                <td className="training-calc__name">
                  <p>Калькулятор</p>
                  <CalcIcon className="training-calc__icon" />
                </td>
                {trainingStore.calculator.headers.map((item, index) => (
                  <td
                    colSpan={typeof item === 'object' ? item.col : 1}
                    className={classNames({
                      'training-calc__name': true,
                      [`training-calc__name--${item.color}`]: typeof item === 'object',
                    })}
                    key={'header-' + index}
                  >
                    <p>{typeof item === 'object' ? item.val : item}</p>
                    {item.icon}
                  </td>
                ))}
              </tr>
              {trainingStore.calculator.examples.map((example, index) => (
                <tr className="training-calc__example" key={'example-' + index}>
                  <td className="training-calc__name training-calc__name--center">{index + 1} Пример</td>
                  {example.map((item, index) => (
                    <td key={'example-el-' + index}>
                      <p
                        className={classNames({
                          'training-calc__val': true,
                          [`training-calc__val--${item.color}`]: typeof item === 'object',
                          notranslate: true,
                        })}
                      >
                        {typeof item === 'object' ? item.val : item}
                        {item !== '-' && <CoinModalIcon className="training-calc__coin" />}
                      </p>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <ReactModal
        isOpen={trainingStore.showErrorModal}
        shouldCloseOnOverlayClick={false}
        overlayClassName="modal-overlay"
        className={classNames({
          modal: true,
          'modal--color-red': true,
        })}
        // onRequestClose={onClose}
      >
        <p className="training-modal__title">ошибка!</p>
        <p className="training-modal__desc">
          Вы пытаетесь сделать что-то не по заданию, пожалуйста, следуйте инструкциям
        </p>
        <div className="training-modal__icon-block">
          <ErrorModalIcon className="training-modal__icon" />
        </div>
        <div
          className={classNames({
            'training-modal__actions': true,
            'training-modal__actions--showed': trainingStore.showedNextButton,
          })}
        >
          <TextButton
            color="blue"
            title="ПОНЯТНО"
            onClick={() => {
              trainingStore.hideErrorModal();
            }}
          />
        </div>
      </ReactModal>
      <ReactModal
        isOpen={trainingStore.isShowWaitingModal}
        shouldCloseOnOverlayClick={false}
        overlayClassName="modal-overlay"
        className="modal"
        style={{ overlay: { zIndex: 101 } }}
      >
        <p className="training-modal__title">Ожидайте...</p>
        <p className="training-modal__desc">Происходит фиксирование текущего прогресса</p>
      </ReactModal>
    </>
  );
}

export default observer(Training);
