import RootStore from '../../index';
import React from 'react';
import { LOT_STATUS } from '../../constants';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../../../ui/components/Cat/Cat';
import MinusModalIcon from '../../../ui/icons/modal/MinusModalIcon';
import PlusModalIcon from '../../../ui/icons/modal/PlusModalIcon';
import EquallyModalIcon from '../../../ui/icons/modal/EquallyModalIcon';
import CoinModalIcon from '../../../ui/icons/modal/CoinModalIcon';
import MultiplyModalIcon from '../../../ui/icons/modal/MultiplyModalIcon';
import CoinIcon from '../../../ui/icons/CoinIcon';
import LikeModalIcon from '../../../ui/icons/modal/LikeModalIcon';
import ChapterTwoIcon from '../../../ui/icons/training/ChapterTwoIcon';
import trainingStore from '../../trainingStore';
import CatCalcIcon from '../../../ui/icons/calc/CatCalcIcon';
import HouseCalcIcon from '../../../ui/icons/calc/HouseCalcIcon';
import { CAT_COLOR, CAT_GENDER } from '../../../common/constants';

export const chapter = 2;

export const stepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 1,
          sell_price: 2,
        },
        female_black: {
          buy_price: 1,
          sell_price: 3,
        },
        male_gray: {
          buy_price: 3,
          sell_price: 4,
        },
        female_gray: {
          buy_price: 4,
          sell_price: 5,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 12,
    },
  ],
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 0,
    nursery: 0,
  },
  base_quota: 567,
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  hunger_enabled: false,
  sickness_enabled: false,
};

export const taskApiParams = {
  game_type: 'tutorial',
  chapter_num: 2,
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 1,
          sell_price: 2,
        },
        female_black: {
          buy_price: 1,
          sell_price: 3,
        },
        male_gray: {
          buy_price: 3,
          sell_price: 4,
        },
        female_gray: {
          buy_price: 4,
          sell_price: 5,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 12,
    },
  ],
  turn_durations: [5 * 60, 5 * 60, 5 * 60, 5 * 60, 5 * 60, 5 * 60],
  household_cost: {
    shop: 0,
    nursery: 0,
  },
  base_quota: 567,
  city_quota_override: {
    male_black: {
      buy_price: 2,
      sell_price: 2,
      buy_quantity: 100,
    },
  },
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  hunger_enabled: false,
  sickness_enabled: false,
};

export const steps = [
  {
    before: () => {
      window.trainingPreventSetOpenedGroupedCat = true;
      trainingStore.setCalculateHeader([
        'На счете',
        { val: 'Расход', color: 'red', icon: <CatCalcIcon className="training-calc__icon" /> },
        { val: 'Расход', color: 'red', icon: <HouseCalcIcon className="training-calc__icon" /> },
        { val: 'доход', icon: <CatCalcIcon className="training-calc__icon" /> },
        'на счете',
      ]);
      trainingStore.setCalculateExamples([['12', '-', '-', '-', '-']]);
    },
    actionName: 'onNextButton',
    step: 36, // По вордовскому документу
    chapter: '2',
    chapterIcon: <ChapterTwoIcon className="training-modal__chapter-icon" />,
    title: <div>ДОБРО ПОЖАЛОВАТЬ В ГЛАВУ «ПРОИЗВОДСТВО»!</div>,
    message: (
      <div>
        <p className="training-modal__desc">
          <span>Теперь ты можешь не только покупать котиков, но и получать от них потомство — по</span>
          <span className="notranslate"> 6 </span> <span>маленьких котят от каждой пары!</span>
        </p>
        <div className="training-modal__cats">
          <Cat cat={{ gender: 'male', color: 'gray' }} readonly notShowCount={true} state={CAT_STATE.SINGLE} />
          <Cat cat={{ gender: 'female', color: 'black' }} readonly notShowCount={true} state={CAT_STATE.SINGLE} />
        </div>
      </div>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    elements: ['.header_container__main-left-side-container-content', '.avatar_container'],
    disabledElements: ['.header_container__main-left-side-container-content', '.avatar_container'],
    step: 37,
    title: 'МОНЕТЫ',
    message: (
      <p className="training-modal__desc">
        <span>В этой главе у тебя есть</span> <span className="notranslate"> 12</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Попробуем заработать</span>
        <span className="notranslate"> 15</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    position: 'bottom',
    nextButton: true,
  },
  {
    // кастомный actionName, он не из AppStore
    actionName: 'routerGoTo',
    // проверка параметров action-а. может возвращать true или 'success' - если это следующий шаг,
    // 'skip' - если ничего делать не нужно
    // false - если нужно запретить это действие и написать пользователю, что он сделал не верный шаг
    // checkParams: (params) => params[1] && params[1].uuid === '00000000-0000-0000-0000-000000000003',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
    step: 37,
    title: 'НАСТАЛО ВРЕМЯ ЗАРАБАТЫВАТЬ!',
    message: <p className="training-modal__desc">Для начала зайди в зоомагазин</p>,
  },
  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      if (contents.length !== 2) {
        return false;
      }
      if (contents[0].count !== 1 || contents[1].count !== 1) {
        return false;
      }
      if (
        (contents[0].color === CAT_COLOR.BLACK &&
          contents[0].gender === CAT_GENDER.FEMALE &&
          contents[1].color === CAT_COLOR.GRAY &&
          contents[1].gender === CAT_GENDER.MALE) ||
        (contents[1].color === CAT_COLOR.BLACK &&
          contents[1].gender === CAT_GENDER.FEMALE &&
          contents[0].color === CAT_COLOR.GRAY &&
          contents[0].gender === CAT_GENDER.MALE)
      ) {
        return true;
      }
      return false;
    },
    elements: ['.lot-area.lot-area--lot', '.lot-area.lot-area--shop', '.lot-area.lot-area--shop .lot-area__cats'],
    step: 40,
    title: 'ПОКУПКА КОТЯТ',
    message: (
      <div>
        <p className="training-modal__desc">
          Купи в магазине серого мальчика и черную девочку. Посмотрим, приведет ли нас это к победе
        </p>
        <div className="training-modal__cats">
          <Cat cat={{ gender: 'male', color: 'gray' }} readonly notShowCount={true} state={CAT_STATE.SINGLE} />
          <Cat cat={{ gender: 'female', color: 'black' }} readonly notShowCount={true} state={CAT_STATE.SINGLE} />
        </div>
      </div>
    ),
    position: 'right',
  },
  {
    actionName: 'lotProposalSentModalClose',
  },
  {
    actionName: 'notificationModalOpen',
    elements: ['.mail'],
    pulseElements: ['.mail'],
    step: 41,
    title: 'ПОЛУЧЕН ОТВЕТ',
    message: (
      <p className="training-modal__desc">
        Зоомагазин «БОНИФАЦИЙ» прислал ответ на твою сделку. Кликни на конверт, чтобы прочитать его.
      </p>
    ),
    position: 'left',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([['12', { val: '-7', color: 'red' }, '-', '-', '-']]);
    },
    actionName: 'notificationModalClose',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    step: 42,
    title: 'ЗДОРОВО!',
    message: (
      <p className="training-modal__desc">
        Теперь котиков надо поселить в домик. Давай его приобретем. Придется вернуться на главный экран.
      </p>
    ),
    position: 'right',
  },

  {
    actionName: 'routerGoTo',
    elements: ['.nurseries-container', '.nursery-item--own'],
    pulseElements: ['.nursery-item-wrapper'],
    step: 42,
    title: 'ПИТОМНИК',
    message: <p className="training-modal__desc">Зайди в свой личный кабинет.</p>,
    position: 'top',
  },
  {
    actionName: 'checkBuyHouse',
    elements: ['.own-nurseries__actions-item:first-child'],
    step: 44,
    title: 'КУПИ ДОМИК!',
    message: (
      <p className="training-modal__desc">
        <span>Чтобы купить домик, нажми на иконку домика. Домик стоит</span> <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    position: 'right',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([['12', { val: '-7', color: 'red' }, { val: '-3', color: 'red' }, '-', '-']]);
    },
    actionName: 'buyHouse',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'onNextButton',
    step: 45,
    title: 'ТЫ КУПИЛ СВОЙ ПЕРВЫЙ ДОМИК ДЛЯ КОТИКОВ!',
    message: <p className="training-modal__desc">Давай рассмотрим его подробнее.</p>,
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    elements: ['.own-nurseries__wood', '.own-nurseries__houses-slider'],
    step: 46,
    message: (
      <p className="training-modal__desc">
        <span>В каждом домике есть 4 большие окна для взрослых котов:</span> <span className="notranslate"> 2 </span>{' '}
        <span>слева и</span>
        <span className="notranslate"> 2 </span> <span>справа.</span>
      </p>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    elements: ['.own-nurseries__wood', '.own-nurseries__houses-slider'],
    step: 46,
    message: (
      <p className="training-modal__desc">
        <span>И</span> <span className="notranslate"> 12 </span>
        <span>
          маленьких окошек для будущих котят: 6 окошек слева и 6 окошек справа. В итоге от двух взрослых пар в домике
          родятся
        </span>
        <span className="notranslate"> 12 </span> <span>котят!</span>
      </p>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'onReceiveMessage__house_cats_update_event',
    checkParams: (params) => (params[0].left_cat_id && params[0].right_cat_id) || 'skip',
    elements: ['.own-nurseries__wood', '.own-nurseries__cats', '.own-nurseries__houses-slider'],
    disabledElements: ['.nursery-box__family:last-child .nursery-box__old-cats-window', '.nursery-box__kittens'],
    step: 47,
    title: 'ПОСЕЛИ КОТЯТ В СОСЕДНИЕ ОКОШКИ',
    message: <p className="training-modal__desc">Перетащи котенка в окно или кликни по котенку два раза.</p>,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    elements: ['.season_container'],
    step: 48,
    title: 'СЕЗОН ИГРЫ',
    message: <p className="training-modal__desc">Во второй главе появляются сезоны игры.</p>,
    position: 'bottom',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    elements: ['.season_container'],
    // step: новый step. Нет в документе (4365)
    title: 'СЛЕДИ ЗА КОТИКАМИ!',
    message: (
      <p className="training-modal__desc">
        Будь внимателен, котята убегают, если по завершению сезона их не поселить в домик.
      </p>
    ),
    position: 'bottom',
    nextButton: true,
  },
  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    step: 48,
    title: 'РАСТИМ КОТИКОВ',
    message: (
      <p className="training-modal__desc">
        <span>Котята еще маленькие, они станут взрослыми, когда им исполнится</span>
        <span className="notranslate"> 3 </span>
        <span>сезона. Нажми кнопку «Завершить сезон» и котята станут старше на 1 сезон.</span>
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
    elements: ['.modal'],
    step: 49,
    title: 'ИТОГИ СЕЗОНА',
    message: (
      <p className="training-modal__desc">При каждом завершении сезона ты будешь видеть общие итоги предыдущего.</p>
    ),
  },
  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    step: 50,
    title: 'ПРОДОЛЖАЕМ РАСТИТЬ КОТИКОВ!',
    message: (
      <p className="training-modal__desc">
        <span>Надо повзрослеть еще на </span>
        <span className="notranslate"> 2 </span> <span>сезона.</span>
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
  },
  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    step: 51,
    title: 'ПРОДОЛЖАЕМ РАСТИТЬ КОТИКОВ!',
    message: (
      <p className="training-modal__desc">
        <span>Надо повзрослеть еще на</span> <span className="notranslate"> 1 </span> <span>сезон.</span>
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
  },

  {
    actionName: 'catsMating',
    elements: ['.own-nurseries__wood', '.own-nurseries__houses-slider'],
    disabledElements: ['.nursery-box__old-cats-window'],
    step: 53,
    title: 'КОТЯТА ПОЯВЯТСЯ СОВСЕМ СКОРО!',
    message: <p className="training-modal__desc">Кнопка «Скрестить» на домике стала активной. Нажми ее.</p>,
    position: 'bottom',
  },

  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    step: 53,
    title: 'ПОЛУЧАЕМ ПОТОМСТВО',
    message: <p className="training-modal__desc">Осталось завершить сезон. Давай это сделаем! Нажми кнопку.</p>,
    position: 'bottom',
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
  },

  {
    actionName: 'onNextButton',
    elements: ['.own-nurseries__wood', '.own-nurseries__houses-slider'],
    disabledElements: ['.nursery-box__family:first-child .nursery-box__kittens'],
    step: 55,
    title: 'ЗАМЕЧАТЕЛЬНЫЕ МАЛЫШИ!',
    message: (
      <p className="training-modal__desc">
        <span>Всегда рождаются</span> <span className="notranslate"> 2 </span> <span>мальчика и</span>
        <span className="notranslate"> 1 </span>
        <span>девочка цвета мамы, а также</span> <span className="notranslate"> 2 </span> <span>мальчика и</span>
        <span className="notranslate"> 1 </span> <span>девочка цвета папы.</span>
      </p>
    ),
    nextButton: true,
  },

  {
    actionName: 'routerGoTo', // TODO переход из Nursery не через routerGoTo сделан!!!!!!!!!!!
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    step: 55,
    title: 'ВЫГОДНАЯ СДЕЛКА',
    message: (
      <p className="training-modal__desc">
        Теперь можно выгодно продать котят. Вернись на главный экран и зайди в зоомагазин.
      </p>
    ),
    position: 'right',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
  },

  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--nursery'],
    step: 56,
    title: 'В ВИТРИНЕ ТОЛЬКО МАЛЫШИ',
    message: (
      <p className="training-modal__desc">
        Посмотри, в твоей витрине видно только маленьких котят, потому что взрослых кошек продавать нельзя.
      </p>
    ),
    nextButton: true,
    position: 'top',
    buttonText: 'Понятно',
    disabledElements: ['.lot-area.lot-area--nursery'],
  },

  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      return contents.length === 6;
    },
    elements: ['.lot-area.lot-area--nursery', '.lot-area.lot-area--lot'],
    step: 57,
    title: 'ПРОДАЕМ КОТЯТ',
    message: (
      <p className="training-modal__desc">
        Перенеси всех котят из своей витрины в зеленую зону сделки и нажми кнопку «Отправить».
      </p>
    ),
    position: 'top',
  },

  {
    actionName: 'lotProposalSentModalClose',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        ['12', { val: '-7', color: 'red' }, { val: '-3', color: 'red' }, { val: '13', color: 'green' }, '15'],
      ]);
    },
    actionName: 'notificationModalOpen',
    elements: ['.mail'],
    pulseElements: ['.mail'],
  },
  {
    actionName: 'notificationModalClose',
  },

  {
    actionName: 'onNextButton',
    step: 59,
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isVeryWide: true,
    message: (
      <div>
        <p className="training-modal__desc">
          Мы научились разводить и продавать котиков. Купили свой первый домик. Чтобы решить практическое задание,
          посмотри, как мы считали:
        </p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">было на счете</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на домик</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на котиков</p>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">заработано на котиках</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">стало на счете</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">12</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">3</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">7</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">13</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">15</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <div className="training-modal__price training-modal__price--single">
          <p className="training-modal__price-name">прибыль</p>
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost training-modal__price-cost--green notranslate">+3</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 60,
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isWide: true,
    message: (
      <div>
        <p className="training-modal__desc">
          <span>Если бы ты купил</span> <span className="notranslate"> 2 </span>
          <span>домика, то деньги, потраченные на них, считал бы так:</span>
        </p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">Количество домиков</p>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">
            <span>цена за</span> <span className="notranslate"> 1 </span> <span>домик</span>
          </p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на домики</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">2</span>
          </div>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">3</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">6</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    colorBg: 'green',
    title: (
      <div>
        <span>ПОЗДРАВЛЯЕМ!</span>
        <br /> <span>ОБУЧЕНИЕ ПО</span> <span className="notranslate"> 2&nbsp;</span>
        <span>ГЛАВЕ ЗАВЕРШЕНО</span>
      </div>
    ),
    message: (
      <div>
        <p className="training-modal__desc">Чтобы перейти на следующий уровень, выполни практическое задание.</p>
        <div className="training-modal__icon-block">
          <LikeModalIcon className="training-modal__icon" />
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
];

export const task = {
  chapter: 2,
  trainingPreventSetOpenedGroupedCat: true,
  data: { confirmedLotCount: 0 },
  watch: [
    {
      actionName: 'onReceiveMessage__balance',
      checkParams: (params) => {
        if (params[0].current_balance === 20) {
          return true;
        }
        return 'skip';
      },
    },
    {
      actionName: 'acceptEndTurn',
      checkParams: () => {
        if (RootStore.appStore.currentSeason === 4) {
          return false;
        }
        return 'skip';
      },
    },
  ],
  title: (
    <div>
      <span className="notranslate">2 </span> <span>ГЛАВА «ПРОИЗВОДСТВО» ПРАКТИЧЕСКОЕ ЗАДАНИЕ</span>
    </div>
  ),
  message: (
    <div>
      <p className="training-modal__desc">
        <span>У тебя есть</span> <span className="notranslate"> 12</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Твоя задача — заработать</span>
        <span className="notranslate"> 20</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span> к </span>
        <span className="notranslate"> 5 </span>
        <span>сезону. Купи котят и получи от них потомство. Затем продай новорожденных котят зоомагазинам.</span>
      </p>
      <p className="training-modal__think-well">
        Не торопись сразу продавать купленных питомцев, ведь у тебя появилась возможность разводить котиков!
      </p>
    </div>
  ),
  wrongMessage: (
    <div className="training-modal__price training-modal__price--single">
      <p className="training-modal__price-name">
        <span>Не получилось</span>
        <br />
        <span>заработать</span>
      </p>
      <div className="training-modal__price-value">
        <CoinModalIcon className="training-modal__price-coin" />
        <span className="training-modal__price-cost training-modal__price-cost--white notranslate">20</span>
      </div>
    </div>
  ),
};
