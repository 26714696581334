import RootStore from '../../index';
import React from 'react';
import { LOT_STATUS } from '../../constants';
import trainingStore from '../../trainingStore';
import trainings from './index';
import api from '../../../api';
import { LocalStorage } from '../../../common/localStorage';
import routes from '../../../routes';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../../../ui/components/Cat/Cat';
import ChapterFourIcon from '../../../ui/icons/training/ChapterFourIcon';
import CoinModalIcon from '../../../ui/icons/modal/CoinModalIcon';
import CoinIcon from '../../../ui/icons/CoinIcon';
import MinusModalIcon from '../../../ui/icons/modal/MinusModalIcon';
import PlusModalIcon from '../../../ui/icons/modal/PlusModalIcon';
import EquallyModalIcon from '../../../ui/icons/modal/EquallyModalIcon';
import CatCalcIcon from '../../../ui/icons/calc/CatCalcIcon';
import { CAT_COLOR, CAT_GENDER, MATING_STATUS } from '../../../common/constants';
import scrollToTheEndOfElement from '../../../common/scrollToTheEndOfElement';

export const chapter = 4;

export const stepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 5,
          sell_price: 6,
        },
        female_black: {
          buy_price: 6,
          sell_price: 7,
        },
        male_gray: {
          buy_price: 3,
          sell_price: 5,
        },
        female_gray: {
          buy_price: 4,
          sell_price: 6,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 34,
      houses: [
        {
          left_cat: null,
          right_cat: null,
          children_cats: [],
        },
        {
          left_cat: null,
          right_cat: null,
          children_cats: [],
        },
      ],
    },
  ],
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 0,
    nursery: 3,
  },
  base_quota: 567,
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
  hunger_enabled: false,
};

export const taskApiParams = {
  game_type: 'tutorial',
  chapter_num: 4,
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 5,
          sell_price: 6,
        },
        female_black: {
          buy_price: 6,
          sell_price: 7,
        },
        male_gray: {
          buy_price: 7,
          sell_price: 10,
        },
        female_gray: {
          buy_price: 8,
          sell_price: 11,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 53,
    },
  ],
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 0,
    nursery: 3,
  },
  base_quota: 567,
  city_quota_override: {
    male_black: {
      buy_price: 2,
      sell_price: 2,
      buy_quantity: 100,
    },
  },
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
};

export const steps = [
  {
    before: () => {
      window.trainingPreventSetOpenedGroupedCat = true;
      trainingStore.setCalculateHeader([
        'На счете',
        { val: 'Расход', color: 'red', icon: <CatCalcIcon className="training-calc__icon" /> },
        { val: 'КОММУНАЛЬНЫЕ УСЛУГИ', color: 'red', col: 4 },
        'остаток',
        { val: 'доход', icon: <CatCalcIcon className="training-calc__icon" /> },
        'на счете',
      ]);
      trainingStore.setCalculateExamples([
        ['34', '-', '-', '-', '-', '-', '-', '-', '-'],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'onNextButton',
    step: 103, // По вордовскому документу
    chapter: '4',
    chapterIcon: <ChapterFourIcon className="training-modal__chapter-icon" />,
    title: (
      <div>
        <span>ДОБРО ПОЖАЛОВАТЬ В</span> <span className="notranslate"> 4 </span> <span>ГЛАВУ «ЭФФЕКТ МАСШТАБА»!</span>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>
          В каждом бизнесе есть свои затраты. Когда вы начинаете производить больше, а траты при этом не растут,
          начинает работать эффект масштаба. Изучим это на примере оплаты
        </span>
        <span className="tooltip">
          <span> коммунальных услуг</span>
          <span className="tooltip__text">
            Доставка в здания воды, тепла, газа и других необходимых для комфортной жизни услуг.
          </span>
        </span>
        <span>. Удачи!</span>
      </p>
    ),
    nextButton: true,
  },
  {
    // кастомный actionName, он не из AppStore
    actionName: 'routerGoTo',
    // проверка параметров action-а. может возвращать true или 'success' - если это следующий шаг,
    // 'skip' - если ничего делать не нужно
    // false - если нужно запретить это действие и написать пользователю, что он сделал не верный шаг
    // checkParams: (params) => params[1] && params[1].uuid === '00000000-0000-0000-0000-000000000003',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
    step: 105,
    title: 'ПРОДОЛЖАЕМ УЧИТЬСЯ',
    message: (
      <p className="training-modal__desc">
        <span>Каждый сезон питомник автоматически платит</span> <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span> </span>
        <span>
          за коммунальные услуги. Эта цена постоянна и не зависит от количества котиков или домиков в питомнике.
          Попробуем заработать
        </span>
        <span className="notranslate"> 40</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span> к концу пятого сезона. Пройди в зоомагазин.</span>
      </p>
    ),
  },
  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      if (contents.length !== 2) {
        return false;
      }
      if (contents[0].count !== 2 || contents[1].count !== 2) {
        return false;
      }
      if (
        (contents[0].color === CAT_COLOR.GRAY &&
          contents[0].gender === CAT_GENDER.MALE &&
          contents[1].color === CAT_COLOR.GRAY &&
          contents[1].gender === CAT_GENDER.FEMALE) ||
        (contents[1].color === CAT_COLOR.GRAY &&
          contents[1].gender === CAT_GENDER.MALE &&
          contents[0].color === CAT_COLOR.GRAY &&
          contents[0].gender === CAT_GENDER.FEMALE)
      ) {
        return true;
      }
      return false;
    },
    elements: ['.lot-area.lot-area--lot', '.lot-area.lot-area--shop', '.lot-area.lot-area--shop .lot-area__cats'],
    step: 105,
    title: 'ПОКУПАЕМ КОТЯТ',
    message: (
      <div>
        <p className="training-modal__desc">Купи в магазине двух серых мальчиков и двух серых девочек.</p>
        <div className="training-modal__cats">
          <Cat
            cat={{ gender: 'male', color: 'gray', count: 2 }}
            readonly
            realCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
          <Cat
            cat={{ gender: 'female', color: 'gray', count: 2 }}
            readonly
            realCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
        </div>
      </div>
    ),
    position: 'right',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        ['34', { val: '-22', color: 'red' }, '-', '-', '-', '-', '-', '-', '-'],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'lotProposalSentModalClose',
  },
  {
    actionName: 'openBalanceModal',
    elements: ['.header_container__main-left-side-container-content', '.avatar_container'],
    pulseElements: ['.avatar_container'],
    disabledElements: ['.header_container__main-left-side-container-content .header-extra'],
    step: 108,
    title: 'БАЛАНС: СЛЕДИМ ЗА ДЕНЬГАМИ',
    message: (
      <p className="training-modal__desc">
        Подробную информацию о твоих доходах и расходах можно посмотреть, нажав на кнопку баланса.
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onChangeOutcomeVisible',
    elements: ['.training-body-balance-row-outcome'],
    disabledElements: ['.body-balance-seasons'],
    pulseElements: ['.body-balance-row-title.body-balance-row-title--clickable'],
    needOverlayClickModal: false,
    step: 109,
    title: 'ДЕТАЛЬНЫЙ АНАЛИЗ',
    message: <p className="training-modal__desc">Кликни на строку «Расходы», чтобы развернуть ее.</p>,
    position: 'top',
  },
  {
    before: () => {
      const modalBody = document.querySelector('.modal__body');
      const outcome = document.querySelector('.training-body-balance-row-outcome');
      modalBody.scrollTo({ top: outcome.scrollHeight - 40, behavior: 'smooth' });
    },
    actionName: 'onChangeOutcomeVisible',
    elements: ['.training-body-balance-row-outcome'],
    pulseElements: ['.body-balance-row-title.body-balance-row-title--clickable'],
    needOverlayClickModal: false,
    step: 109,
    title: 'ИДЕМ ДАЛЬШЕ',
    message: <p className="training-modal__desc">Кликни на строку «Расходы», чтобы свернуть ее.</p>,
    position: 'top',
  },
  {
    before: () => {
      const modalBody = document.querySelector('.modal__body');
      scrollToTheEndOfElement(modalBody);
    },
    actionName: 'hideBalanceModal',
    elements: ['.training-balance-modal-btn'],
    step: 110,
    title: 'ИДЕМ ДАЛЬШЕ',
    message: <p className="training-modal__desc">Кнопка «Понятно» закроет окно баланса.</p>,
    position: 'top',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    step: 111,
    title: 'ВСЕ ПО ПЛАНУ',
    message: <p className="training-modal__desc">Давай поселим котят в домик. Вернись на главный экран.</p>,
    position: 'right',
  },

  {
    actionName: 'routerGoTo',
    elements: ['.nurseries-container', '.nursery-item--own'],
    pulseElements: ['.nursery-item--own'],
    title: 'Зайди в питомник',
    message: <div></div>,
    position: 'top',
  },
  {
    actionName: 'moveCatInHouse',
    checkParams: (params) => {
      if (
        RootStore.appStore.catsWithoutHome.length === 1 &&
        RootStore.appStore.catsWithoutHome[0].cat_id === params[0]
      ) {
        const leftCatInLeftHouse = RootStore.appStore.catById(
          RootStore.appStore.groupedHouses[0].left.left_cat?.cat_id ?? params[0],
        );
        const rightCatInLeftHouse = RootStore.appStore.catById(
          RootStore.appStore.groupedHouses[0].left.right_cat?.cat_id ?? params[0],
        );
        if (leftCatInLeftHouse.gender !== rightCatInLeftHouse.gender) {
          return true;
        }
      }
      return 'skip';
    },
    elements: ['.own-nurseries__wood', '.own-nurseries__cats', '.own-nurseries__houses-slider'],
    disabledElements: ['.nursery-box__kittens'],
    step: 111,
    title: 'ПОСЕЛИ КОТЯТ В ДОМИК',
    message: <div></div>,
    position: 'bottom',
  },
  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    step: 112,
    title: 'РАСТИМ КОТЯТ',
    message: (
      <p className="training-modal__desc">
        Осталось вырастить котиков и получить потомство. Вперед! Нажми кнопку «Завершить сезон».
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([
        ['34', { val: '-22', color: 'red' }, { val: '-3', color: 'red' }, '-', '-', '-', '-', '-', '-'],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    after: () => {
      trainingStore.setRolledCalc(false);
    },
    actionName: 'hideSeasonChangeModal',
    elements: ['.modal--size-very-big'],
    step: 113,
    title: 'НОВЫЕ РАСХОДЫ',
    message: (
      <p className="training-modal__desc">
        <span>Коммунальные услуги (внутри строки "Расходы") – новая строка расходов (по</span>
        <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span> за сезон).</span>
      </p>
    ),
    position: 'right',
  },

  {
    after: () => {
      if (window.innerWidth < 1201) {
        trainingStore.setRolledCalc(true);
      }
    },
    actionName: 'onNextButton',
    elements: ['.training-calc'],
    step: 113,
    title: 'КАЛЬКУЛЯТОР',
    message: (
      <p className="training-modal__desc">
        <span>Ты завершил сезон и</span> <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span> заплатил за коммунальные услуги.</span>
      </p>
    ),
    nextButton: true,
    position: 'top',
  },
  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    pulseElements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([
        [
          '34',
          { val: '-22', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '-',
          '-',
          '-',
          '-',
          '-',
        ],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'hideSeasonChangeModal',
  },
  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    pulseElements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([
        [
          '34',
          { val: '-22', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '-',
          '-',
          '-',
          '-',
        ],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'hideSeasonChangeModal',
  },

  {
    actionName: 'catsMating',
    checkParams: (params) => {
      return (
        RootStore.appStore.ourCats.filter((cat) => cat.mating_status === MATING_STATUS.MATING).length === 2 || 'skip'
      );
    },
    elements: ['.own-nurseries__wood', '.own-nurseries__houses-slider'],
    disabledElements: [
      '.nursery-box__family:last-child .nursery-box__old-cats-window',
      '.nursery-box__old-cats-window',
    ],
    step: 117,
    title: 'ПОЛУЧАЕМ ПОТОМСТВО',
    message: <p className="training-modal__desc">Скрести обе пары и пропусти сезон, чтобы родились котята.</p>,
    position: 'bottom',
  },

  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([
        [
          '34',
          { val: '-22', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '0',
          '-',
          '-',
        ],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'hideSeasonChangeModal',
  },

  {
    actionName: 'routerGoTo',
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    step: 119,
    title: 'КОТЯТА ПОЯВИЛИСЬ',
    message: (
      <p className="training-modal__desc">
        <span>Как их много!</span> <span className="notranslate"> 8 </span> <span>мальчиков и</span>
        <span className="notranslate"> 4 </span>
        <span>девочки. Давай их продадим. Вернись на главный экран и зайди в зоомагазин.</span>
      </p>
    ),
    position: 'right',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
  },

  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      return contents.length === 12;
    },
    elements: ['.lot-area.lot-area--nursery', '.lot-area.lot-area--lot'],
    step: 119,
    title: 'Продаем котят',
    message: (
      <p className="training-modal__desc">
        Перенеси всех котят из своей витрины в зону сделки и нажми кнопку «Отправить».
      </p>
    ),
    position: 'top',
  },

  {
    actionName: 'lotProposalSentModalClose',
  },
  {
    actionName: 'notificationModalOpen',
    elements: ['.mail'],
    pulseElements: ['.mail'],
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([
        [
          '34',
          { val: '-22', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '0',
          { val: '+40', color: 'green' },
          '40',
        ],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'notificationModalClose',
  },

  {
    actionName: 'openBalanceModal',
    elements: ['.header_container__main-left-side-container-content', '.avatar_container'],
    pulseElements: ['.avatar_container'],
    disabledElements: ['.header_container__main-left-side-container-content .header-extra'],
    step: 123,
    title: 'БАЛАНС: СЛЕДИМ ЗА ДЕНЬГАМИ',
    message: <p className="training-modal__desc">Теперь давай посмотрим на твои доходы.</p>,
    position: 'bottom',
  },
  {
    actionName: 'onChangeIncomeVisible',
    elements: ['.training-body-balance-row-income'],
    disabledElements: ['.body-balance-seasons'],
    pulseElements: ['.training-body-balance-row-income .body-balance-row-title.body-balance-row-title--clickable'],
    needOverlayClickModal: false,
    step: 124,
    title: 'ДЕТАЛЬНЫЙ АНАЛИЗ',
    message: <p className="training-modal__desc">Кликни на строку «Доход с продажи котиков», чтобы развернуть ее.</p>,
    position: 'top',
  },
  {
    before: () => {
      const modalBody = document.querySelector('.modal__body');
      const income = document.querySelector('.training-body-balance-row-income');
      modalBody.scrollTo({ top: income.scrollHeight - 40, behavior: 'smooth' });
    },
    actionName: 'onChangeIncomeVisible',
    elements: ['.training-body-balance-row-income'],
    pulseElements: ['.training-body-balance-row-income .body-balance-row-title.body-balance-row-title--clickable'],
    needOverlayClickModal: false,
    step: 125,
    title: 'ИДЕМ ДАЛЬШЕ',
    message: <p className="training-modal__desc">Кликни на строку «Доход с продажи котиков», чтобы свернуть ее.</p>,
    position: 'top',
  },
  {
    before: () => {
      const modalBody = document.querySelector('.modal__body');
      setTimeout(() => {
        scrollToTheEndOfElement(modalBody);
      }, 1);
    },
    after: () => {
      trainingStore.setRolledCalc(false);
    },
    actionName: 'hideBalanceModal',
    elements: ['.training-balance-modal-btn'],
  },

  {
    actionName: 'onNextButton',
    elements: ['.training-calc'],
    step: 126,
    title: 'КАЛЬКУЛЯТОР',
    message: (
      <p className="training-modal__desc">
        <span>В начале игры у тебя было</span> <span className="notranslate"> 34</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Ты потратил их на котят и коммунальные услуги. После продажи</span>
        <span className="notranslate"> 12 </span> <span>маленьких котят на счете</span> <br />
        <span>стало</span> <span className="notranslate"> 40</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>! Твоя прибыль составила:</span>
        <span className="notranslate"> 40 — 34 = 6</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
      </p>
    ),
    nextButton: true,
    position: 'top',
    after: async () => {
      if (window.innerWidth < 1201) {
        trainingStore.setRolledCalc(true);
      }
      const result = await api.common.createTutorial(stepsApiParams);
      LocalStorage.JOIN_URL = new URL(result.join_url).host.replace('develop', 'dev');
      // ToDo отойти от использования window
      window.runTraining = true;
      RootStore.goTo(
        routes.init,
        { gameUid: result.game_uuid, userUid: LocalStorage.USER_UID },
        { server: LocalStorage.JOIN_URL },
      );
      setTimeout(() => {
        trainingStore.checkIsCorrectAction('onNextButton');
      }, 200);
    },
  },
  {
    actionName: 'onNextButton',
  },

  /////////////// ВТОРОЙ ПРИМЕР ↓

  {
    before: () => {
      trainingStore.setCalculateExamples([
        [
          '34',
          { val: '-22', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '0',
          { val: '+40', color: 'green' },
          '40',
        ],
        ['34', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
    step: 126,
    title: 'ПРОВЕДЕМ ЭКСПЕРИМЕНТ',
    message: (
      <p className="training-modal__desc">
        <span>
          Ты выиграл, потому что заполнил домик полностью. А если заполнить его всего наполовину? Удастся ли заработать
        </span>
        <span className="notranslate"> 40</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>? Давай попробуем! Зайди в зоомагазин.</span>
      </p>
    ),
  },
  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      if (contents.length !== 2) {
        return false;
      }
      if (contents[0].count !== 1 || contents[1].count !== 1) {
        return false;
      }
      if (
        (contents[0].color === CAT_COLOR.GRAY &&
          contents[0].gender === CAT_GENDER.MALE &&
          contents[1].color === CAT_COLOR.GRAY &&
          contents[1].gender === CAT_GENDER.FEMALE) ||
        (contents[1].color === CAT_COLOR.GRAY &&
          contents[1].gender === CAT_GENDER.MALE &&
          contents[0].color === CAT_COLOR.GRAY &&
          contents[0].gender === CAT_GENDER.FEMALE)
      ) {
        return true;
      }
      return false;
    },
    elements: ['.lot-area.lot-area--lot', '.lot-area.lot-area--shop', '.lot-area.lot-area--shop .lot-area__cats'],
    step: 127,
    title: 'ПОКУПКА КОТЯТ',
    message: (
      <div>
        <p className="training-modal__desc">Купи в магазине серого мальчика и серую девочку.</p>
        <div className="training-modal__cats">
          <Cat
            cat={{ gender: 'male', color: 'gray' }}
            readonly
            notShowCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
          <Cat
            cat={{ gender: 'female', color: 'gray' }}
            readonly
            notShowCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
        </div>
      </div>
    ),
    position: 'right',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        [
          '34',
          { val: '-22', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '0',
          { val: '+40', color: 'green' },
          '40',
        ],
        ['34', { val: '-11', color: 'red' }, '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'lotProposalSentModalClose',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    step: 128,
    title: 'ПОСЕЛИМ КОТЯТ В ДОМИК',
    message: <p className="training-modal__desc">Вернись на главный экран и зайди в питомник.</p>,
    position: 'right',
  },

  {
    actionName: 'routerGoTo',
    elements: ['.nurseries-container', '.nursery-item--own'],
    pulseElements: ['.nursery-item--own'],
  },
  {
    actionName: 'onReceiveMessage__house_cats_update_event',
    checkParams: (params) => (params[0].left_cat_id && params[0].right_cat_id) || 'skip',
    elements: ['.own-nurseries__wood', '.own-nurseries__cats', '.own-nurseries__houses-slider'],
    disabledElements: ['.nursery-box__family:last-child .nursery-box__old-cats-window', '.nursery-box__kittens'],
    step: 129,
    title: 'ПОСЕЛИ КОТЯТ В СОСЕДНИЕ ОКОШКИ.',
    message: <div></div>,
    position: 'bottom',
  },
  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    step: 130,
    title: 'РАСТИМ КОТЯТ',
    message: (
      <p className="training-modal__desc">
        Осталось вырастить котиков и получить потомство. Вперед! Нажми кнопку «Завершить сезон» 3 раза.
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([
        [
          '34',
          { val: '-22', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '0',
          { val: '+40', color: 'green' },
          '40',
        ],
        ['34', { val: '-11', color: 'red' }, { val: '-3', color: 'red' }, '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'hideSeasonChangeModal',
  },

  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    pulseElements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([
        [
          '34',
          { val: '-22', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '0',
          { val: '+40', color: 'green' },
          '40',
        ],
        [
          '34',
          { val: '-11', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '-',
          '-',
          '-',
          '-',
          '-',
        ],
      ]);
    },
    actionName: 'hideSeasonChangeModal',
  },

  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    pulseElements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([
        [
          '34',
          { val: '-22', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '0',
          { val: '+40', color: 'green' },
          '40',
        ],
        [
          '34',
          { val: '-11', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '-',
          '-',
          '-',
          '-',
        ],
      ]);
    },
    actionName: 'hideSeasonChangeModal',
  },
  {
    actionName: 'catsMating',
    elements: ['.own-nurseries__wood', '.own-nurseries__houses-slider'],
    disabledElements: ['.nursery-box__old-cats-window'],
    step: 134,
    title: 'ПОЛУЧАЕМ ПОТОМСТВО',
    message: <p className="training-modal__desc">Скрести взрослых кошек, чтобы родились котята.</p>,
    position: 'bottom',
  },

  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([
        [
          '34',
          { val: '-22', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '0',
          { val: '+40', color: 'green' },
          '40',
        ],
        [
          '34',
          { val: '-11', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '11',
          '-',
          '-',
        ],
      ]);
    },
    actionName: 'hideSeasonChangeModal',
  },

  {
    actionName: 'routerGoTo',
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    step: 136,
    title: 'КОТЯТА ПОЯВИЛИСЬ!',
    message: (
      <p className="training-modal__desc">
        <span>Родились</span> <span className="notranslate"> 4 </span> <span>мальчика и</span>
        <span className="notranslate"> 2 </span>
        <span>девочки. Давай их продадим. Вернись на главный экран и зайди в зоомагазин.</span>
      </p>
    ),
    position: 'right',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
  },

  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      return contents.length === 6;
    },
    elements: ['.lot-area.lot-area--nursery', '.lot-area.lot-area--lot'],
    step: 137,
    title: 'ПРОДАЕМ ВСЕХ КОТЯТ',
    message: (
      <p className="training-modal__desc">
        Перенеси всех котят из своей витрины в зону сделки и нажми кнопку «Отправить».
      </p>
    ),
    position: 'top',
  },

  {
    actionName: 'lotProposalSentModalClose',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        [
          '34',
          { val: '-22', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '0',
          { val: '+40', color: 'green' },
          '40',
        ],
        [
          '34',
          { val: '-11', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-3', color: 'red' },
          '11',
          { val: '+20', color: 'green' },
          '31',
        ],
      ]);
    },
    actionName: 'notificationModalOpen',
    elements: ['.mail'],
    pulseElements: ['.mail'],
  },
  {
    actionName: 'notificationModalClose',
  },

  {
    after: () => {
      trainingStore.setRolledCalc(false);
    },
    actionName: 'onNextButton',
    step: 140,
    title: 'ЭКСПЕРИМЕНТ УДАЛСЯ',
    message: (
      <p className="training-modal__desc">
        <span>Без эффекта масштаба мы не заработали</span> <span className="notranslate"> 40</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Хотя сэкономили на покупке котиков и на счете </span>
        <span>оставалось</span> <span className="notranslate"> 11</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. После продажи котят на счете накопилась всего</span>
        <span className="notranslate"> 31</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },

  {
    after: () => {
      if (window.innerWidth < 1201) {
        trainingStore.setRolledCalc(true);
      }
    },
    actionName: 'onNextButton',
    elements: ['.training-calc'],
    step: 140,
    title: 'ПОСМОТРИ НА КАЛЬКУЛЯТОР',
    message: (
      <div>
        <p className="training-modal__desc">Ты заполнил домик наполовину</p>
        <p className="training-modal__desc training-modal__desc--left">
          <span className="notranslate"> 34</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
          <span> – у тебя было.</span>
          <br /> <span className="notranslate"> 11</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
          <span> – ты потратил на пару котиков.</span>
          <br />
          <span className="training-modal__desc--bg-red">
            <span className="notranslate"> 12</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
          </span>
          <span> – потрачено на коммунальные услуги за</span> <span className="notranslate"> 4 </span>
          <span>сезона.</span>
          <br /> <span className="notranslate"> 11</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
          <span> – осталось на счете.</span>
          <br /> <span className="notranslate"> 20</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
          <span> – заработано на продаже котят.</span>
          <br /> <span className="notranslate"> 11 + 20 = 31</span>
          <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span> стало в конце игры.</span>
        </p>
      </div>
    ),
    nextButton: true,
    position: 'top',
  },

  {
    actionName: 'onNextButton',
    step: 141,
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isWide: true,
    message: (
      <div>
        <p className="training-modal__desc">Посчитаем, сколько ты потратил за игру:</p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">Потрачено на котиков </p>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">коммунальные услуги </p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">расходы</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">11</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">12</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">23</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <p className="training-modal__desc training-modal__desc--with-margin">
          Посчитаем, сколько денег осталось на счете:
        </p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">Было на счете</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">расходы</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">осталось на счете</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">34</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">23</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">11</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <p className="training-modal__desc training-modal__desc--with-margin">
          Посчитаем, сколько денег стало на счете после продажи котят:
        </p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">Осталось на счете </p>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">заработано на котятах </p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">стало на счете</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">11</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">20</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">31</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <p className="training-modal__desc training-modal__desc--with-margin">Посчитаем, нашу прибыль (убыток):</p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">Осталось на счете </p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">было на счете </p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">Прибыль</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">31</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">34</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">-3</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <p className="training-modal__desc training-modal__desc--with-margin">
          Когда наша прибыль отрицательная, то это считается убытком.
        </p>
        <div className="training-modal__price training-modal__price--single">
          <p className="training-modal__price-name">убыток</p>
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost training-modal__price-cost--red notranslate">-3</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    before: () => {
      trainingStore.setRolledCalc(false);
    },
    after: () => {
      if (window.innerWidth < 1201) {
        trainingStore.setRolledCalc(true);
      }
    },
    actionName: 'onNextButton',
    elements: ['.training-calc'],
    step: 140,
    title: 'ПОСМОТРИ НА КАЛЬКУЛЯТОР',
    message: (
      <div>
        <p className="training-modal__desc">В первый раз ты заполнил домик полностью</p>
        <p className="training-modal__desc training-modal__desc--left">
          <span className="notranslate"> 34</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
          <span> – у тебя было.</span>
          <br /> <span className="notranslate"> 22</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
          <span> – ты потратил на две пары котиков.</span>
          <br />
          <span className="training-modal__desc--bg-red notranslate">
            <span className="notranslate"> 12</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
          </span>
          <span> – потрачено на коммунальные услуги за 4 сезона.</span>
          <br /> <span className="notranslate"> 0</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
          <span> – осталось на счете.</span>
          <br /> <span className="notranslate"> 40</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
          <span> – заработано на продаже котят.</span>
          <br /> <span className="notranslate"> 0 + 40 = 40</span>
          <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span> стало в конце игры.</span>
        </p>
      </div>
    ),
    nextButton: true,
    position: 'top',
  },
  {
    actionName: 'onNextButton',
    step: 142,
    title: 'ТЫ ХОРОШИЙ УЧЕНИК',
    message: (
      <p className="training-modal__desc">
        <span>Ты заработал</span> <span className="notranslate"> 40</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>, когда заполнил домик полностью, и всего</span>
        <span className="notranslate"> 31</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>, когда заполнил домик наполовину. Но за коммунальные услуги оба раза ты заплатил по</span>
        <span className="notranslate"> 12</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>! Это и есть эффект масштаба.</span>
      </p>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
  {
    actionName: 'onNextButton',
    step: 143,
    title: 'ВЫВОД',
    message: (
      <p className="training-modal__desc">
        <span>
          Выгоднее заполнять домик полностью, так как одни и те же коммунальные затраты распределяются на выручку от
        </span>
        <span className="notranslate"> 6 </span>
        <span>или от</span> <span className="notranslate"> 12 </span> котят! <br />
        <span>Чтобы двигаться дальше, выполни практическое задание.</span>
      </p>
    ),
    nextButton: true,
  },
];

export const task = {
  chapter: 4,
  trainingPreventSetOpenedGroupedCat: true,
  data: { confirmedLotCount: 0 },
  watch: [
    {
      actionName: 'onReceiveMessage__balance',
      checkParams: (params) => {
        if (params[0].current_balance === 64) {
          return true;
        }
        return 'skip';
      },
    },
    {
      actionName: 'acceptEndTurn',
      checkParams: () => {
        if (RootStore.appStore.currentSeason === 4) {
          return false;
        }
        return 'skip';
      },
    },
  ],
  title: (
    <div>
      <span className="notranslate">4 </span> <span>ГЛАВА «ЭФФЕКТ МАСШТАБА» ПРАКТИЧЕСКОЕ ЗАДАНИЕ</span>
    </div>
  ),
  message: (
    <div>
      <p className="training-modal__desc">
        <span>У тебя</span> <span className="notranslate"> 53</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Твоя задача – на</span>
        <span className="notranslate"> 5 </span> <span>сезоне заработать</span>
        <span className="notranslate"> 64</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Купи котят и получи от них потомство. Затем продай новорожденных малышей зоомагазину.</span>
      </p>
      <p className="training-modal__think-well">
        <span>
          Не забывай селить котят в домики, вовремя их кормить и оплачивать коммунальные услуги. Стоимость коммунальных
          услуг –
        </span>
        <span className="notranslate"> 3</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span> за сезон!</span>
      </p>
    </div>
  ),
  wrongMessage: (
    <div className="training-modal__price training-modal__price--single">
      <p className="training-modal__price-name">
        <span>Не получилось</span>
        <br />
        <span>заработать</span>
      </p>
      <div className="training-modal__price-value">
        <CoinModalIcon className="training-modal__price-coin" />
        <span className="training-modal__price-cost training-modal__price-cost--white notranslate">64</span>
      </div>
    </div>
  ),
};
