import RootStore from '../../index';
import React from 'react';
import { LOT_STATUS } from '../../constants';
import trainingStore from '../../trainingStore';
import trainings from './index';
import api from '../../../api';
import { LocalStorage } from '../../../common/localStorage';
import routes from '../../../routes';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../../../ui/components/Cat/Cat';
import LikeModalIcon from '../../../ui/icons/modal/LikeModalIcon';
import ChapterThreeIcon from '../../../ui/icons/training/ChapterThreeIcon';
import MinusModalIcon from '../../../ui/icons/modal/MinusModalIcon';
import PlusModalIcon from '../../../ui/icons/modal/PlusModalIcon';
import EquallyModalIcon from '../../../ui/icons/modal/EquallyModalIcon';
import CoinModalIcon from '../../../ui/icons/modal/CoinModalIcon';
import MultiplyModalIcon from '../../../ui/icons/modal/MultiplyModalIcon';
import CoinIcon from '../../../ui/icons/CoinIcon';
import CatCalcIcon from '../../../ui/icons/calc/CatCalcIcon';
import HouseCalcIcon from '../../../ui/icons/calc/HouseCalcIcon';
import EatCalcIcon from '../../../ui/icons/calc/EatCalcIcon';
import EatIconDefault from '../../../ui/icons/EatIconDefault';
import { CAT_COLOR, CAT_GENDER } from '../../../common/constants';

export const chapter = 3;

export const stepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 3,
          sell_price: 4,
        },
        female_black: {
          buy_price: 3,
          sell_price: 4,
        },
        male_gray: {
          buy_price: 4,
          sell_price: 5,
        },
        female_gray: {
          buy_price: 5,
          sell_price: 6,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 18,
    },
  ],
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 0,
    nursery: 0,
  },
  base_quota: 567,
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
};

export const taskApiParams = {
  game_type: 'tutorial',
  chapter_num: 3,
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 3,
          sell_price: 4,
        },
        female_black: {
          buy_price: 3,
          sell_price: 4,
        },
        male_gray: {
          buy_price: 4,
          sell_price: 5,
        },
        female_gray: {
          buy_price: 5,
          sell_price: 6,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 18,
    },
  ],
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 0,
    nursery: 0,
  },
  base_quota: 567,
  city_quota_override: {
    male_black: {
      buy_price: 2,
      sell_price: 2,
      buy_quantity: 100,
    },
  },
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
};

export const steps = [
  {
    before: () => {
      window.trainingPreventSetOpenedGroupedCat = true;
      trainingStore.setCalculateHeader([
        'На счете',
        { val: 'Расход', color: 'red', icon: <CatCalcIcon className="training-calc__icon" /> },
        { val: 'Расход', color: 'red', icon: <HouseCalcIcon className="training-calc__icon" /> },
        { val: 'Расход', color: 'red', icon: <EatCalcIcon className="training-calc__icon" /> },
        { val: 'Расход', color: 'red', icon: <EatCalcIcon className="training-calc__icon" /> },
        { val: 'Расход', color: 'red', icon: <EatCalcIcon className="training-calc__icon" /> },
        'остаток',
        { val: 'доход', icon: <CatCalcIcon className="training-calc__icon" /> },
        'на счете',
      ]);
      trainingStore.setCalculateExamples([
        ['18', '-', '-', '-', '-', '-', '-', '-', '-'],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'onNextButton',
    step: 64, // По вордовскому документу
    chapter: '3',
    chapterIcon: <ChapterThreeIcon className="training-modal__chapter-icon" />,
    title: (
      <div>
        <span>ДОБРО ПОЖАЛОВАТЬ В</span> <span className="notranslate"> 3 </span>
        <span>ГЛАВУ «ОПЕРАЦИОННЫЕ ЗАТРАТЫ»!</span>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>Ты познакомишься с понятием </span>
        <span className="tooltip">
          <span>ОПЕРАЦИОННЫЕ ЗАТРАТЫ</span>
          <span className="tooltip__text">«ОБЩАЯ ВЕЛИЧИНА ЗАТРАТ, КОТОРЫЕ ВЫНУЖДЕН НЕСТИ ВЛАДЕЛЕЦ»</span>
        </span>
        <span>. Котики нуждаются в пище. Ты должен кормить их каждый сезон. Питание для</span>
        <span className="notranslate"> 1 </span> <span>кота стоит</span> <span className="notranslate"> 1</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Будь внимателен: голодные котики сбегают при завершении сезона!</span>
      </p>
    ),
    nextButton: true,
  },
  {
    // кастомный actionName, он не из AppStore
    actionName: 'routerGoTo',
    // проверка параметров action-а. может возвращать true или 'success' - если это следующий шаг,
    // 'skip' - если ничего делать не нужно
    // false - если нужно запретить это действие и написать пользователю, что он сделал не верный шаг
    // checkParams: (params) => params[1] && params[1].uuid === '00000000-0000-0000-0000-000000000003',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
    step: 65,
    title: 'ВПЕРЕД, К НОВЫМ ЗНАНИЯМ!',
    message: <p className="training-modal__desc">Для начала зайди в зоомагазин</p>,
  },
  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      if (contents.length !== 2) {
        return false;
      }
      if (contents[0].count !== 1 || contents[1].count !== 1) {
        return false;
      }
      if (
        (contents[0].color === CAT_COLOR.BLACK &&
          contents[0].gender === CAT_GENDER.MALE &&
          contents[1].color === CAT_COLOR.BLACK &&
          contents[1].gender === CAT_GENDER.FEMALE) ||
        (contents[1].color === CAT_COLOR.BLACK &&
          contents[1].gender === CAT_GENDER.MALE &&
          contents[0].color === CAT_COLOR.BLACK &&
          contents[0].gender === CAT_GENDER.FEMALE)
      ) {
        return true;
      }
      return false;
    },
    elements: ['.lot-area.lot-area--lot', '.lot-area.lot-area--shop', '.lot-area.lot-area--shop .lot-area__cats'],
    step: 66,
    title: 'ПОКУПАЕМ КОТЯТ',
    message: (
      <div>
        <p className="training-modal__desc">
          <span>Наша цель — заработать</span> <span className="notranslate"> 19</span>
          <CoinIcon style={{ position: 'relative', top: '2px' }} />
          <span>! Купи в магазине черного мальчика и черную девочку.</span>
        </p>
        <div className="training-modal__cats">
          <Cat
            cat={{ gender: 'male', color: 'black' }}
            readonly
            notShowCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
          <Cat
            cat={{ gender: 'female', color: 'black' }}
            readonly
            notShowCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
        </div>
      </div>
    ),
    position: 'right',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        ['18', { val: '-8', color: 'red' }, '-', '-', '-', '-', '-', '-', '-'],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'lotProposalSentModalClose',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    step: 67,
    title: 'ИДЕМ ДАЛЬШЕ',
    message: (
      <p className="training-modal__desc">
        Сразу после покупки котики сыты и кормить их не надо. Пришло время купить для них домик. Вернись на главный
        экран.
      </p>
    ),
    position: 'right',
  },

  {
    actionName: 'routerGoTo',
    elements: ['.nurseries-container', '.nursery-item--own'],
    pulseElements: ['.nursery-item--own'],
    step: 68,
    title: 'Зайди в питомник',
    message: <div></div>,
    position: 'top',
  },
  {
    actionName: 'checkBuyHouse',
    elements: ['.own-nurseries__actions-item:first-child'],
    title: 'КУПИ ДОМИК!',
    message: (
      <p className="training-modal__desc">
        <span>Чтобы купить домик, нажми на иконку домика. Домик стоит</span> <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />.
      </p>
    ),
    position: 'right',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        ['18', { val: '-8', color: 'red' }, { val: '-3', color: 'red' }, '-', '-', '-', '-', '-', '-'],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'buyHouse',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'onReceiveMessage__house_cats_update_event',
    checkParams: (params) => (params[0].left_cat_id && params[0].right_cat_id) || 'skip',
    elements: ['.own-nurseries__wood', '.own-nurseries__cats', '.own-nurseries__houses-slider'],
    disabledElements: ['.nursery-box__family:last-child .nursery-box__old-cats-window', '.nursery-box__kittens'],
    step: 70,
    title: 'ПОСЕЛИ КОТЯТ В ДОМИК',
    message: <div></div>,
    position: 'bottom',
  },
  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    step: 70,
    title: 'НАЖМИ КНОПКУ «ЗАВЕРШИТЬ СЕЗОН»',
    message: <div></div>,
    position: 'bottom',
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
  },

  {
    after: () => {
      trainingStore.setCalculateExamples([
        [
          '18',
          { val: '-8', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          '-',
          '-',
          '-',
          '-',
          '-',
        ],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'onReceiveMessage__player_cats_event',
    checkParams: (params) => {
      if (RootStore.appStore.ourCats.filter((cat) => cat.hungry).length === 1) {
        return true;
      }
      return 'skip';
    },
    elements: ['.own-nurseries__wood', '.own-nurseries__actions-item--eat', '.own-nurseries__houses-slider'],
    step: 72,
    title: 'КОТЯТА ПРОГОЛОДАЛИСЬ!',
    isDisabledDoubleClickCat: true,
    message: (
      <div>
        <p className="training-modal__desc">
          <span>Перетащи иконку с кормом на каждого котенка или кликни по иконке два раза. Питание для</span>
          <span className="notranslate"> 1 </span> <span>котенка стоит</span>
          <span className="notranslate"> 1</span>
          <CoinIcon style={{ position: 'relative', top: '2px' }} />
          <span>.</span>
        </p>
        <div className="training-modal__icon-block">
          <EatIconDefault className="training-modal__icon" />
        </div>
      </div>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    step: 74,
    title: 'РАСТИМ КОТЯТ',
    message: (
      <p className="training-modal__desc">
        Вырасти котят и скрести их. Не забывай кормить питомцев после каждого завершения сезона.
      </p>
    ),
    nextButton: true,
  },

  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    pulseElements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
  },

  {
    after: () => {
      trainingStore.setCalculateExamples([
        [
          '18',
          { val: '-8', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          '-',
          '-',
          '-',
          '-',
        ],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'onReceiveMessage__player_cats_event',
    checkParams: (params) => {
      if (RootStore.appStore.ourCats.filter((cat) => cat.hungry).length === 1) {
        return true;
      }
      return 'skip';
    },
    elements: ['.own-nurseries__wood', '.own-nurseries__actions-item--eat', '.own-nurseries__houses-slider'],
    step: 75,
    title: 'ПРОДОЛЖАЕМ',
    isDisabledDoubleClickCat: true,
    message: (
      <div>
        <p className="training-modal__desc">Корми котят каждый сезон! Они могут сбежать, если останутся голодными.</p>
        <div className="training-modal__icon-block">
          <EatIconDefault className="training-modal__icon" />
        </div>
      </div>
    ),
    position: 'bottom',
  },

  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    pulseElements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
  },

  {
    after: () => {
      trainingStore.setCalculateExamples([
        [
          '18',
          { val: '-8', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          '-',
          '-',
          '-',
        ],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'onReceiveMessage__player_cats_event',
    checkParams: (params) => {
      if (RootStore.appStore.ourCats.filter((cat) => cat.hungry).length === 1) {
        return true;
      }
      return 'skip';
    },
    elements: ['.own-nurseries__wood', '.own-nurseries__actions-item--eat', '.own-nurseries__houses-slider'],
    step: 77,
    title: 'ПОЛУЧАЕМ ПОТОМСТВО',
    isDisabledDoubleClickCat: true,
    message: (
      <div>
        <p className="training-modal__desc">
          Накорми кошек. После этого их можно будет скрестить, чтобы появилось потомство. Голодные котики не
          скрещиваются.
        </p>
        <div className="training-modal__icon-block">
          <EatIconDefault className="training-modal__icon" />
        </div>
      </div>
    ),
    position: 'bottom',
  },

  {
    after: () => {
      trainingStore.setCalculateExamples([
        [
          '18',
          { val: '-8', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          '1',
          '-',
          '-',
        ],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'catsMating',
    elements: ['.own-nurseries__wood', '.own-nurseries__houses-slider'],
    disabledElements: ['.nursery-box__old-cats-window'],
    step: 79,
    title: 'ПОЛУЧАЕМ ПОТОМСТВО',
    isDisabledDoubleClickCat: true,
    message: (
      <p className="training-modal__desc">
        Кнопка «Скрестить» доступна, если котики накормлены и поселены вместе. Нажми кнопку «Скрестить» и заверши сезон,
        чтобы получить котят.
      </p>
    ),
    position: 'bottom',
  },

  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
  },

  {
    actionName: 'routerGoTo', // TODO переход из Nursery не через routerGoTo сделан!!!!!!!!!!!
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    step: 82,
    title: 'КОТЯТА ПОЯВИЛИСЬ!',
    message: <p className="training-modal__desc">Давай их продадим. Вернись на главный экран и зайди в зоомагазин.</p>,
    position: 'right',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
  },

  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      return contents.length === 6;
    },
    elements: ['.lot-area.lot-area--nursery', '.lot-area.lot-area--lot'],
    step: 83,
    title: 'ПРОДАЙ ВСЕХ КОТЯТ ЗООМАГАЗИНУ',
    message: (
      <p className="training-modal__desc">
        Перенеси всех котят из своей витрины в зону сделки и нажми кнопку «Отправить».
      </p>
    ),
    position: 'top',
  },

  {
    actionName: 'lotProposalSentModalClose',
  },
  {
    actionName: 'notificationModalOpen',
    elements: ['.mail'],
    pulseElements: ['.mail'],
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        [
          '18',
          { val: '-8', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          '1',
          { val: '+18', color: 'green' },
          '19',
        ],
        ['-', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'notificationModalClose',
  },

  {
    actionName: 'onNextButton',
    step: 86,
    title: 'ПОПРОБУЕМ ИНАЧЕ',
    message: (
      <p className="training-modal__desc">
        <span className="notranslate"> 19</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>
          {' '}
          заработано! Но, если бы мы купили пару дорогих серых котят, то не смогли бы их прокормить. Давай попробуем.
          Купи в магазине серого мальчика и серую девочку.
        </span>
      </p>
    ),
    before: () => {
      trainingStore.setCalculateExamples([
        [
          '18',
          { val: '-8', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          '1',
          { val: '+18', color: 'green' },
          '19',
        ],
        ['18', '-', '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    after: async () => {
      // TODO создать игру заново
      const result = await api.common.createTutorial(stepsApiParams);
      LocalStorage.JOIN_URL = new URL(result.join_url).host.replace('develop', 'dev');
      // ToDo отойти от использования window
      window.runTraining = true;
      RootStore.goTo(
        routes.init,
        { gameUid: result.game_uuid, userUid: LocalStorage.USER_UID },
        { server: LocalStorage.JOIN_URL },
      );
      setTimeout(() => {
        trainingStore.checkIsCorrectAction('onNextButton');
      }, 200);
    },
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
  },

  {
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
    step: 87,
    title: 'ИДЕМ ДАЛЬШЕ',
    message: <p className="training-modal__desc">Для начала зайди в зоомагазин</p>,
  },
  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      if (contents.length !== 2) {
        return false;
      }
      if (contents[0].count !== 1 || contents[1].count !== 1) {
        return false;
      }
      if (
        (contents[0].color === CAT_COLOR.GRAY &&
          contents[0].gender === CAT_GENDER.MALE &&
          contents[1].color === CAT_COLOR.GRAY &&
          contents[1].gender === CAT_GENDER.FEMALE) ||
        (contents[1].color === CAT_COLOR.GRAY &&
          contents[1].gender === CAT_GENDER.MALE &&
          contents[0].color === CAT_COLOR.GRAY &&
          contents[0].gender === CAT_GENDER.FEMALE)
      ) {
        return true;
      }
      return false;
    },
    elements: ['.lot-area.lot-area--lot', '.lot-area.lot-area--shop', '.lot-area.lot-area--shop .lot-area__cats'],
    step: 88,
    title: 'ПОКУПАЕМ КОТЯТ',
    message: (
      <div>
        <p className="training-modal__desc">Купи в магазине серого мальчика и серую девочку.</p>
        <div className="training-modal__cats">
          <Cat
            cat={{ gender: 'male', color: 'gray' }}
            readonly
            notShowCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
          <Cat
            cat={{ gender: 'female', color: 'gray' }}
            readonly
            notShowCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
        </div>
      </div>
    ),
    position: 'right',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        [
          '18',
          { val: '-8', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          '1',
          { val: '+18', color: 'green' },
          '19',
        ],
        ['18', { val: '-11', color: 'red' }, '-', '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'lotProposalSentModalClose',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    step: 90,
    title: 'ИДЕМ ДАЛЬШЕ',
    message: <p className="training-modal__desc">Теперь поселим котят в домик. Вернись на главный экран.</p>,
    position: 'right',
  },

  {
    actionName: 'routerGoTo',
    elements: ['.nurseries-container', '.nursery-item--own'],
    pulseElements: ['.nursery-item--own'],
    step: 91,
    title: 'Зайди в питомник',
    message: <div></div>,
    position: 'top',
  },
  {
    actionName: 'checkBuyHouse',
    elements: ['.own-nurseries__actions-item:first-child'],
    pulseElements: ['.own-nurseries__actions-item:first-child'],
    position: 'right',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        [
          '18',
          { val: '-8', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          '1',
          { val: '+18', color: 'green' },
          '19',
        ],
        ['18', { val: '-11', color: 'red' }, { val: '-3', color: 'red' }, '-', '-', '-', '-', '-', '-'],
      ]);
    },
    actionName: 'buyHouse',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'onReceiveMessage__house_cats_update_event',
    checkParams: (params) => (params[0].left_cat_id && params[0].right_cat_id) || 'skip',
    elements: ['.own-nurseries__wood', '.own-nurseries__cats', '.own-nurseries__houses-slider'],
    disabledElements: ['.nursery-box__family:last-child .nursery-box__old-cats-window', '.nursery-box__kittens'],
    step: 92,
    title: 'ЗАСЕЛИ КОТЯТ',
    message: <p className="training-modal__desc">Посели котят рядом в соседние окошки.</p>,
    position: 'bottom',
  },
  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
  },

  {
    after: () => {
      trainingStore.setCalculateExamples([
        [
          '18',
          { val: '-8', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          '1',
          { val: '+18', color: 'green' },
          '19',
        ],
        [
          '18',
          { val: '-11', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          '-',
          '-',
          '-',
          '-',
          '-',
        ],
      ]);
    },
    actionName: 'onReceiveMessage__player_cats_event',
    isDisabledDoubleClickCat: true,
    checkParams: (params) => {
      if (RootStore.appStore.ourCats.filter((cat) => cat.hungry).length === 1) {
        return true;
      }
      return 'skip';
    },
    elements: ['.own-nurseries__wood', '.own-nurseries__actions-item--eat', '.own-nurseries__houses-slider'],
  },
  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    pulseElements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
  },

  {
    after: () => {
      trainingStore.setCalculateExamples([
        [
          '18',
          { val: '-8', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          '1',
          { val: '+18', color: 'green' },
          '19',
        ],
        [
          '18',
          { val: '-11', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          '-',
          '-',
          '-',
          '-',
        ],
      ]);
    },
    actionName: 'onReceiveMessage__player_cats_event',
    isDisabledDoubleClickCat: true,
    checkParams: (params) => {
      if (RootStore.appStore.ourCats.filter((cat) => cat.hungry).length === 1) {
        return true;
      }
      return 'skip';
    },
    elements: ['.own-nurseries__wood', '.own-nurseries__actions-item--eat', '.own-nurseries__houses-slider'],
  },
  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    pulseElements: ['.end-turn-button'],
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([
        [
          '18',
          { val: '-8', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          '1',
          { val: '+18', color: 'green' },
          '19',
        ],
        [
          '18',
          { val: '-11', color: 'red' },
          { val: '-3', color: 'red' },
          { val: '-2', color: 'red' },
          { val: '-2', color: 'red' },
          '-',
          '0',
          '-',
          '-',
        ],
      ]);
    },
    actionName: 'onNextButton',
    isDisabledDoubleClickCat: true,
    elements: ['.own-nurseries__wood', '.own-nurseries__houses-slider'],
    step: 97,
    title: 'ДЕНЬГИ КОНЧИЛИСЬ',
    message: (
      <p className="training-modal__desc">
        <span>Кошки голодные, но ты не можешь купить корм для них, у тебя больше нет </span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    position: 'bottom',
    nextButton: true,
  },

  {
    actionName: 'exactlyAcceptEndTurn',
    elements: ['.end-turn-button'],
    step: 97,
    title: 'ПРОПУСКАЕМ СЕЗОН',
    message: <p className="training-modal__desc">Нажми кнопку «Завершить сезон», чтобы двигаться дальше.</p>,
    position: 'bottom',
  },
  {
    actionName: 'onReceiveMessage__start_turn',
    elements: ['.modal__body-actions .text_button:first-child'],
  },
  {
    actionName: 'hideSeasonChangeModal',
  },
  {
    actionName: 'hideCatAwayModal',
  },
  {
    actionName: 'onNextButton',
    step: 98,
    title: 'КОТИКИ СБЕЖАЛИ',
    message: (
      <p className="training-modal__desc">
        К сожалению, голодные котики сбежали и не принесли потомства. Этот пример показал, как важно заранее посчитать
        расходы.
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 99,
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isVeryWide: true,
    message: (
      <div>
        <p className="training-modal__desc">
          <span>Чтобы котики не сбежали, их надо кормить каждый сезон. Корм для одного котенка стоит</span>
          <span className="notranslate"> 1</span>
          <CoinIcon style={{ position: 'relative', top: '2px' }} />
          <span>. Чтобы решить практическое задание, посмотри, как мы считали, когда продавали черных котят.</span>
        </p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">было на счете</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на домик</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на котиков</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на корм</p>
          <PlusModalIcon className="training-modal__blocks-icon" />
        </div>
        <div className="training-modal__blocks training-modal__blocks--min-margin training-modal__blocks--center">
          <PlusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">заработано на котиках</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">стало на счете</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">18</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">3</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">8</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">6</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <PlusModalIcon className="training-modal__blocks-icon" />
        </div>
        <div className="training-modal__blocks training-modal__blocks--min-margin training-modal__blocks--center">
          <PlusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">18</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">19</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <div className="training-modal__price training-modal__price--single">
          <p className="training-modal__price-name">прибыль</p>
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost training-modal__price-cost--green notranslate">+1</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 99,
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isWide: true,
    message: (
      <div>
        <p className="training-modal__desc">
          Если бы ты купил две пары котят, то деньги, потраченные на котиков, считал бы так:
        </p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">Количество котят</p>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">
            <span>цена за</span> <span className="notranslate"> 1 </span> <span>корм</span>
          </p>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">
            <span>( количество сезонов –</span>
            <span className="notranslate"> 2 )</span>
          </p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">
            <span>стоит корм для</span> <span className="notranslate"> 4 </span> <span>котят</span>
          </p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">4</span>
          </div>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">1</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">( 5 - 2 )</span>
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">12</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    colorBg: 'green',
    title: (
      <div>
        <span>ПОЗДРАВЛЯЕМ!</span>
        <br /> <span>ОБУЧЕНИЕ ПО</span> <span className="notranslate"> 3&nbsp;</span>
        <span>ГЛАВЕ ЗАВЕРШЕНО</span>
      </div>
    ),
    message: (
      <div>
        <p className="training-modal__desc">Чтобы перейти на следующий уровень, выполни практическое задание.</p>
        <div className="training-modal__icon-block">
          <LikeModalIcon className="training-modal__icon" />
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
];

export const task = {
  chapter: 3,
  trainingPreventSetOpenedGroupedCat: true,
  data: { confirmedLotCount: 0 },
  watch: [
    {
      actionName: 'onReceiveMessage__balance',
      checkParams: (params) => {
        if (params[0].current_balance === 22) {
          return true;
        }
        return 'skip';
      },
    },
    {
      actionName: 'acceptEndTurn',
      checkParams: () => {
        if (RootStore.appStore.currentSeason === 4) {
          return false;
        }
        return 'skip';
      },
    },
  ],
  title: (
    <div>
      <span className="notranslate">3 </span>
      <span>ГЛАВА «ОПЕРАЦИОННЫЕ ЗАТРАТЫ» ПРАКТИЧЕСКОЕ ЗАДАНИЕ</span>
    </div>
  ),
  message: (
    <div>
      <p className="training-modal__desc">
        <span>У тебя есть 18</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Попробуй заработать</span>
        <span className="notranslate"> 22</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} /> к <span className="notranslate"> 5 </span>
        <span>
          сезону. Купи и вырасти котят, получи от них потомство. Затем продай новорожденных котят зоомагазинам.
        </span>
      </p>
      <p className="training-modal__think-well">Не забывай кормить котиков каждый сезон!</p>
    </div>
  ),
  wrongMessage: (
    <div className="training-modal__price training-modal__price--single">
      <p className="training-modal__price-name">
        <span>Не получилось</span>
        <br />
        <span>заработать</span>
      </p>
      <div className="training-modal__price-value">
        <CoinModalIcon className="training-modal__price-coin" />
        <span className="training-modal__price-cost training-modal__price-cost--white notranslate">22</span>
      </div>
    </div>
  ),
};
