import React, { useMemo } from 'react';
import Select, { components } from 'react-select';
import './Select.scss';
import dropdown from '../../assets/dropdown.png';
import classNames from '../../common/classNames';
import trainingStore from '../../store/trainingStore';
import { observer } from 'mobx-react';
import { CUSTOM_ACTION_NAMES } from '../../common/constants';
import createCustomActionForTraining from '../../common/createCustomActionForTraining';

const ReactSelect = ({
  className,
  value,
  onChange,
  options,
  withoutDropdown,
  readOnly,
  highlight,
  id,
  menuPlacement = 'bottom',
  maxMenuHeight = 120,
}) => {
  const _menuPlacement = useMemo(() => {
    if (
      id &&
      trainingStore?.launched &&
      trainingStore.currentStep &&
      trainingStore.currentStep.selectMenuPosition &&
      trainingStore.currentStep.selectMenuPosition[id]
    ) {
      return trainingStore.currentStep.selectMenuPosition[id];
    } else {
      return menuPlacement;
    }
  }, [
    id,
    menuPlacement,
    trainingStore.launched,
    trainingStore.currentStep,
    trainingStore.currentStep?.selectMenuPosition,
  ]);

  // INFO Ратмир: react-select должен самостоятельно прокручивать страницу
  // Но в данном проекте почему-то не рабоает данная функция
  // Перепробовал множество способов, в том числе react-select без props
  // В итоге пришел к данному решению
  function scrollIntoView() {
    const selectedElement = document.querySelector('.react-select__menu');
    if (selectedElement) {
      selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }

  return (
    <Select
      noOptionsMessage={() => 'Нет возможных значений'}
      classNamePrefix="react-select"
      className={classNames({
        'react-select': true,
        notranslate: true,
        [className]: !!className,
        // 'react-select-empty': !options?.length && !readOnly,
        'react-select-readonly': readOnly,
        'react-select-highlight': highlight,
      })}
      value={value}
      onChange={onChange}
      options={options}
      onMenuOpen={() => {
        createCustomActionForTraining({
          actionName: CUSTOM_ACTION_NAMES.ON_SELECT_OPEN,
          params: [id],
        });
        setTimeout(scrollIntoView, 15);
      }}
      maxMenuHeight={maxMenuHeight}
      placeholder={'Выберите...'}
      isSearchable={false}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: withoutDropdown || readOnly ? null : DropdownIndicator,
      }}
      menuPlacement={_menuPlacement}
      id={id}
    />
  );
};

export default observer(ReactSelect);

const DropdownIcon = () => {
  return <img src={dropdown} />;
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <DropdownIcon />
      </components.DropdownIndicator>
    )
  );
};
