import React from 'react';
import './index.scss';
import './style.scss';
import RootStore from '../store';
import { observer } from 'mobx-react';

const Messenger = () => {
  return <div id="alb-chat"></div>;
};

export default observer(Messenger);
