import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import Img from '../../../assets/shopAvatars/10.png';

function AvatarWater({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="#FDF6EE" stroke="#02516C" strokeWidth="3" />
    //   <path
    //     d="M22.1361 12.7295C20.4622 10.3315 18.815 8.42122 18.7457 8.34111C18.5585 8.12448 18.2862 8 17.9999 8C17.7135 8 17.4413 8.12448 17.2541 8.34111C17.1848 8.42122 15.5375 10.3315 13.8636 12.7295C11.505 16.1085 10.3584 18.604 10.3584 20.3585C10.3584 24.572 13.7863 28 17.9999 28C22.2134 28 25.6414 24.572 25.6414 20.3585C25.6414 18.604 24.4948 16.1085 22.1361 12.7295ZM21.5896 24.2139C21.003 24.8004 20.2748 25.2551 19.4835 25.5288C18.7024 25.799 17.8572 25.8941 17.0394 25.8038C16.8468 25.7826 16.6953 25.6294 16.6761 25.4366C16.657 25.2437 16.7754 25.0637 16.9601 25.005C18.1793 24.6175 19.343 23.8935 20.3255 22.9111C21.2824 21.9541 21.9968 20.8196 22.3915 19.6302C22.4523 19.4468 22.6329 19.3307 22.8249 19.3515C23.017 19.3722 23.1686 19.5241 23.1889 19.7162C23.3651 21.382 22.7822 23.0213 21.5896 24.2139Z"
    //     fill="#02516C"
    //   />
    // </svg>
    <Avatar style={style} avatarImage={Img} />
  );
}

export default AvatarWater;
