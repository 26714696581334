import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';

function UnacceptableTreatmentModal() {
  const { appStore } = RootStore;

  if (!appStore.unacceptableTreatmentModal) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.unacceptableTreatmentModal}
      onClose={appStore.hideUnacceptableTreatmentModal}
      title="Невозможно применить этот вид лечения"
      style={{ zIndex: 1000 }}
    >
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.hideUnacceptableTreatmentModal} />
      </div>
    </Modal>
  );
}

export default observer(UnacceptableTreatmentModal);
