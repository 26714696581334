import React from 'react';

function TriangleIcon({ style, width = 16, height = 16, color = '#C6A788', top = true, className }) {
  return !top ? (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M8.13114 2.26483C4.12693 2.24733 0.76992 2.28158 0.671529 2.34096C0.323337 2.55038 0.501025 3.25798 1.24326 4.61701C3.43606 8.63251 7.05886 13.2603 8.00934 13.2603C8.63599 13.2603 10.0636 11.7465 12.0754 8.94861C13.7918 6.56169 15.597 3.28878 15.4959 2.74724C15.4169 2.32297 14.9825 2.29439 8.13114 2.26483Z"
        fill="#C6A788"
      />
    </svg>
  ) : (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M7.86886 13.2557C11.8731 13.2732 15.2301 13.2389 15.3285 13.1795C15.6767 12.9701 15.499 12.2625 14.7567 10.9035C12.5639 6.888 8.94114 2.26025 7.99066 2.26025C7.36401 2.26025 5.93637 3.77401 3.9246 6.5719C2.20824 8.95882 0.403034 12.2317 0.504053 12.7733C0.583101 13.1975 1.0175 13.2261 7.86886 13.2557Z"
        fill={color}
      />
    </svg>
  );
}

export default TriangleIcon;
