import RootStore from '../../index';
import React from 'react';
import { LOT_STATUS, MESSAGE_TYPE } from '../../constants';
import trainingStore from '../../trainingStore';
import trainings from './index';
import api from '../../../api';
import { LocalStorage } from '../../../common/localStorage';
import routes from '../../../routes';
import CoinModalIcon from '../../../ui/icons/modal/CoinModalIcon';
import ChapterFiveIcon from '../../../ui/icons/training/ChapterFiveIcon';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../../../ui/components/Cat/Cat';
import PlusModalIcon from '../../../ui/icons/modal/PlusModalIcon';
import EquallyModalIcon from '../../../ui/icons/modal/EquallyModalIcon';
import MinusModalIcon from '../../../ui/icons/modal/MinusModalIcon';
import CoinIcon from '../../../ui/icons/CoinIcon';
import LikeModalIcon from '../../../ui/icons/modal/LikeModalIcon';
import _ from 'lodash';
import HighIncomePersonModalIcon from '../../../ui/icons/modal/HighIncomePersonModalIcon';
import HighIncomeCatModalIcon from '../../../assets/cats/modal/cat_female_ginger.png';
import MiddleIncomePersonModalIcon from '../../../ui/icons/modal/MiddleIncomePersonModalIcon';
import MiddleIncomeCatModalIcon from '../../../assets/cats/modal/cat_female_white.png';
import LowIncomePersonModalIcon from '../../../ui/icons/modal/LowIncomePersonModalIcon';
import LowIncomeCatModalIcon from '../../../assets/cats/gray_big_girl_01.png';
import CatCalcIcon from '../../../ui/icons/calc/CatCalcIcon';
import { CAT_COLOR, CAT_GENDER, MATING_STATUS } from '../../../common/constants';

export const chapter = 5;

export const stepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: 'b27ea52e-864b-11eb-88bb-0242ac120002',
      name: 'bot 1',
      role: 'nursery',
      ai_type: 'nursery_bot',
      ai_features: {
        ai_enabled: false,
      },
      default_prices_override: {
        female_gray: {
          buy_price: 1,
          sell_price: 1,
        },
        female_ginger: {
          buy_price: 2,
          sell_price: 2,
        },
      },
      cats: [
        {
          color: 'ginger',
          gender: 'female',
        },
        {
          color: 'ginger',
          gender: 'female',
        },
        {
          color: 'gray',
          gender: 'female',
        },
        {
          color: 'gray',
          gender: 'female',
        },
      ],
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'shop',
      initial_money: 4,
    },
  ],
  city_quota_override: {
    female_gray: {
      buy_price: 2,
      sell_price: 2,
      buy_quantity: 2,
    },
    female_ginger: {
      buy_price: 3,
      sell_price: 3,
      buy_quantity: 1,
    },
    female_black: {
      buy_price: 1,
      sell_price: 1,
      buy_quantity: null,
    },
  },
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 1,
    nursery: 3,
  },
  base_quota: 567,
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
  hunger_enabled: false,
};

export const taskApiParams = {
  game_type: 'tutorial',
  chapter_num: 5,
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: 'b27ea52e-864b-11eb-88bb-0242ac120002',
      name: 'bot 1',
      role: 'nursery',
      ai_type: 'nursery_bot',
      ai_features: {
        ai_enabled: false,
      },
      default_prices_override: {
        female_gray: {
          buy_price: 2,
          sell_price: 2,
        },
        female_ginger: {
          buy_price: 1,
          sell_price: 1,
        },
      },
      cats: [
        {
          color: 'ginger',
          gender: 'female',
        },
        {
          color: 'ginger',
          gender: 'female',
        },
        {
          color: 'gray',
          gender: 'female',
        },
        {
          color: 'gray',
          gender: 'female',
        },
        {
          color: 'ginger',
          gender: 'female',
        },
        {
          color: 'ginger',
          gender: 'female',
        },
        {
          color: 'gray',
          gender: 'female',
        },
        {
          color: 'gray',
          gender: 'female',
        },
        {
          color: 'ginger',
          gender: 'female',
        },
        {
          color: 'ginger',
          gender: 'female',
        },
        {
          color: 'gray',
          gender: 'female',
        },
        {
          color: 'gray',
          gender: 'female',
        },
        {
          color: 'ginger',
          gender: 'female',
        },
        {
          color: 'ginger',
          gender: 'female',
        },
        {
          color: 'gray',
          gender: 'female',
        },
        {
          color: 'gray',
          gender: 'female',
        },
      ],
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'shop',
      default_prices_override: {
        female_gray: {
          buy_price: 3,
          sell_price: 4,
        },
        female_ginger: {
          buy_price: 4,
          sell_price: 4,
        },
      },
      initial_money: 6,
    },
  ],
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 1,
    nursery: 3,
  },
  base_quota: 567,
  city_quota_override: {
    female_gray: {
      buy_price: 2,
      sell_price: 4,
      buy_quantity: 4,
    },
    female_ginger: {
      buy_price: 4,
      sell_price: 5,
      buy_quantity: 2,
    },
    male_gray: {
      buy_price: 2,
      sell_price: 9,
      buy_quantity: null,
    },
    male_ginger: {
      buy_price: 4,
      sell_price: 9,
      buy_quantity: null,
    },
    female_black: {
      buy_price: 2,
      sell_price: 9,
      buy_quantity: null,
    },
    female_white: {
      buy_price: 4,
      sell_price: 9,
      buy_quantity: null,
    },
    male_black: {
      buy_price: 1,
      sell_price: 1,
      buy_quantity: null,
    },
    male_white: {
      buy_price: 4,
      sell_price: 9,
      buy_quantity: null,
    },
  },
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
  sickness_enabled: false,
};

export const steps = [
  {
    before: () => {
      LocalStorage.SHOWED_BUY_FROM_CITY = true;
      LocalStorage.SHOWED_SELL_TO_CITY = true;
      window.trainingPreventSetOpenedGroupedCat = true;
    },
    actionName: 'onNextButton',
    step: 148, // По вордовскому документу
    chapter: '5',
    chapterIcon: <ChapterFiveIcon className="training-modal__chapter-icon" />,
    title: (
      <div>
        <span>ДОБРО ПОЖАЛОВАТЬ В</span> <span className="notranslate"> 5 </span> <span>ГЛАВУ «СЕГМЕНТАЦИЯ РЫНКА»</span>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>В данной главе ты играешь за зоомагазин. Поговорим про </span>
        <span className="tooltip">
          <span>сегментацию рынка</span>
          <span className="tooltip__text">
            Деление рынка на четкие группы покупателей, чтобы каждой группе предложить подходящий товар.
          </span>
        </span>
        <span>
          . Купят товар по низкой или высокой цене во многом зависит от того, насколько хорошо продавец знает своих
          покупателей.
        </span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 149,
    title: 'РАЗНЫЙ ДОСТАТОК',
    personCats: (
      <div className="training-modal__persons-cats">
        <div className="training-modal__person-cats training-modal__person-cats--high">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <HighIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        В городе живут люди с разным достатком: богатые, средний класс и бедные. Богатых людей мало.
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 149,
    title: 'РАЗНЫЙ ДОСТАТОК',
    personCats: (
      <div className="training-modal__persons-cats training-modal__persons-cats--margin">
        <div className="training-modal__person-cats training-modal__person-cats--high">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <HighIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--middle">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
      </div>
    ),
    message: <p className="training-modal__desc">Людей со средним достатком больше.</p>,
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 149,
    title: 'РАЗНЫЙ ДОСТАТОК',
    personCats: (
      <div className="training-modal__persons-cats">
        <div className="training-modal__person-cats training-modal__person-cats--high">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <HighIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--middle">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--low">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
      </div>
    ),
    message: <p className="training-modal__desc">Людей с низким достатком еще больше.</p>,
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 149,
    title: 'РЕДКАЯ И ДОРОГАЯ КОШКА',
    isOnlyCats: true,
    personCats: (
      <div className="training-modal__persons-cats">
        <div className="training-modal__person-cats training-modal__person-cats--high">
          <div className="training-modal__person-cats-icon-block">
            <img src={HighIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
        </div>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>Генетически рождается мало рыжих кошечек</span> <span className="notranslate">(1)</span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 149,
    title: 'РЕДКАЯ И ДОРОГАЯ КОШКА',
    isOnlyCats: true,
    personCats: (
      <div className="training-modal__persons-cats training-modal__persons-cats--margin">
        <div className="training-modal__person-cats training-modal__person-cats--high">
          <div className="training-modal__person-cats-icon-block">
            <img src={HighIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--middle">
          <div className="training-modal__person-cats-icon-block">
            <img src={MiddleIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block">
            <img src={MiddleIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
        </div>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>Чуть больше белых кошек</span> <span className="notranslate">(2).</span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 149,
    title: 'РЕДКАЯ И ДОРОГАЯ КОШКА',
    isOnlyCats: true,
    personCats: (
      <div className="training-modal__persons-cats">
        <div className="training-modal__person-cats training-modal__person-cats--high">
          <div className="training-modal__person-cats-icon-block">
            <img src={HighIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--middle">
          <div className="training-modal__person-cats-icon-block">
            <img src={MiddleIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block">
            <img src={MiddleIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--low training-modal__person-cats--low--only--cats">
          <div className="training-modal__person-cats-icon-block">
            <img src={LowIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block">
            <img src={LowIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block">
            <img src={LowIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
        </div>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>Еще больше серых кошек</span> <span className="notranslate">(3).</span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 149,
    title: 'РЕДКАЯ И ДОРОГАЯ КОШКА',
    isOnlyCats: true,
    personCats: (
      <div className="training-modal__persons-cats">
        <div className="training-modal__person-cats training-modal__person-cats--high">
          <div className="training-modal__person-cats-icon-block">
            <img src={HighIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--middle">
          <div className="training-modal__person-cats-icon-block">
            <img src={MiddleIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block">
            <img src={MiddleIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--low training-modal__person-cats--low--only--cats">
          <div className="training-modal__person-cats-icon-block">
            <img src={LowIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block">
            <img src={LowIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block">
            <img src={LowIncomeCatModalIcon} className="training-modal__person-cats-icon" />
          </div>
        </div>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>Рыжая кошка самая редкая – купить ее хотят все, поэтому продавец продает ее дорого – за</span>
        <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 149,
    title: 'РЕДКАЯ И ДОРОГАЯ КОШКА',
    personCats: (
      <div className="training-modal__persons-cats">
        <div className="training-modal__person-cats training-modal__person-cats--high">
          <div className="training-modal__person-cats-icon-block">
            <HighIncomePersonModalIcon className="training-modal__person-cats-icon" />
            <img
              src={HighIncomeCatModalIcon}
              className="training-modal__person-cats-icon training-modal__person-cats-icon--small"
            />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--middle">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--low">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>Рыжая кошка самая редкая – купить ее хотят все, поэтому продавец продает ее дорого – за</span>
        <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 149,
    title: 'СПРОС ПРЕВЫШАЕТ ПРЕДЛОЖЕНИЕ',
    personCats: (
      <div className="training-modal__persons-cats">
        <div className="training-modal__person-cats training-modal__person-cats--high">
          <div className="training-modal__person-cats-icon-block">
            <HighIncomePersonModalIcon className="training-modal__person-cats-icon" />
            <img
              src={HighIncomeCatModalIcon}
              className="training-modal__person-cats-icon training-modal__person-cats-icon--small"
            />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--middle">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--low">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>Купить рыжую кошечку хотят</span> <span className="notranslate"> 6 </span>
        <span>человек, но заплатить</span>
        <span className="notranslate"> 3</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span> </span>
        <span>
          за нее сможет только богатый покупатель, поскольку лишь у него есть такие деньги. Это ситуация, когда спрос
          превышает предложение.
        </span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 149,
    title: 'БЫВАЕТ И ПО-ДРУГОМУ',
    personCats: (
      <div className="training-modal__persons-cats">
        <div className="training-modal__person-cats training-modal__person-cats--high">
          <div className="training-modal__person-cats-icon-block">
            <HighIncomePersonModalIcon className="training-modal__person-cats-icon" />
            <img
              src={HighIncomeCatModalIcon}
              className="training-modal__person-cats-icon training-modal__person-cats-icon--small"
            />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--middle">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--low">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
      </div>
    ),
    personCatsGinger: (
      <div className="training-modal__cats">
        <img src={HighIncomeCatModalIcon} className="training-modal__cats--bottom-img" />
        <img src={HighIncomeCatModalIcon} className="training-modal__cats--bottom-img" />
        <img src={HighIncomeCatModalIcon} className="training-modal__cats--bottom-img" />
        <img src={HighIncomeCatModalIcon} className="training-modal__cats--bottom-img" />
        <img src={HighIncomeCatModalIcon} className="training-modal__cats--bottom-img" />
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>Представим, что родилось</span> <span className="notranslate"> 6 </span> <span>рыжих кошек.</span>
        <span className="notranslate"> 6 </span>
        <span>человек с разным достатком хотели бы ее купить, но заплатить за кошку</span>
        <span className="notranslate"> 3</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span> сможет только богатый человек.</span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 149,
    title: 'БЫВАЕТ И ПО-ДРУГОМУ',
    personCats: (
      <div className="training-modal__persons-cats">
        <div className="training-modal__person-cats training-modal__person-cats--high">
          <div className="training-modal__person-cats-icon-block">
            <HighIncomePersonModalIcon className="training-modal__person-cats-icon" />
            <img
              src={HighIncomeCatModalIcon}
              className="training-modal__person-cats-icon training-modal__person-cats-icon--small"
            />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--middle">
          <div className="training-modal__person-cats-icon-block">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
            <img
              src={HighIncomeCatModalIcon}
              className="training-modal__person-cats-icon training-modal__person-cats-icon--small"
            />
          </div>
          <div className="training-modal__person-cats-icon-block">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
            <img
              src={HighIncomeCatModalIcon}
              className="training-modal__person-cats-icon training-modal__person-cats-icon--small"
            />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--low">
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
          <div className="training-modal__person-cats-icon-block training-modal__person-cats-icon-block--only-person">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
          </div>
        </div>
      </div>
    ),
    personCatsGinger: (
      <div className="training-modal__cats">
        <img src={HighIncomeCatModalIcon} className="training-modal__cats--bottom-img" />
        <img src={HighIncomeCatModalIcon} className="training-modal__cats--bottom-img" />
        <img src={HighIncomeCatModalIcon} className="training-modal__cats--bottom-img" />
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>Продавец ждет покупателей и надеется продать</span> <br />
        <span>по</span> <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span> и остальных кошек, но покупателей нет. Чтобы не потерять доход, он снижает цену до</span>
        <span className="notranslate"> 2</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span> и</span>
        <span className="notranslate"> 2 </span> <span>кошечки покупают люди среднего достатка.</span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 149,
    title: 'ПРЕДЛОЖЕНИЕ ПРЕВЫШАЕТ СПРОС',
    personCats: (
      <div className="training-modal__persons-cats">
        <div className="training-modal__person-cats training-modal__person-cats--high">
          <div className="training-modal__person-cats-icon-block">
            <HighIncomePersonModalIcon className="training-modal__person-cats-icon" />
            <img
              src={HighIncomeCatModalIcon}
              className="training-modal__person-cats-icon training-modal__person-cats-icon--small"
            />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--middle">
          <div className="training-modal__person-cats-icon-block">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
            <img
              src={HighIncomeCatModalIcon}
              className="training-modal__person-cats-icon training-modal__person-cats-icon--small"
            />
          </div>
          <div className="training-modal__person-cats-icon-block">
            <MiddleIncomePersonModalIcon className="training-modal__person-cats-icon" />
            <img
              src={HighIncomeCatModalIcon}
              className="training-modal__person-cats-icon training-modal__person-cats-icon--small"
            />
          </div>
        </div>
        <div className="training-modal__person-cats training-modal__person-cats--low">
          <div className="training-modal__person-cats-icon-block">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
            <img
              src={HighIncomeCatModalIcon}
              className="training-modal__person-cats-icon training-modal__person-cats-icon--small"
            />
          </div>
          <div className="training-modal__person-cats-icon-block">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
            <img
              src={HighIncomeCatModalIcon}
              className="training-modal__person-cats-icon training-modal__person-cats-icon--small"
            />
          </div>
          <div className="training-modal__person-cats-icon-block">
            <LowIncomePersonModalIcon className="training-modal__person-cats-icon" />
            <img
              src={HighIncomeCatModalIcon}
              className="training-modal__person-cats-icon training-modal__person-cats-icon--small"
            />
          </div>
        </div>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>Продавец очень хочет продать оставшихся кошек</span> <br />
        <span>по</span> <span className="notranslate"> 2</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>
          , но спроса среди среднего класса на них больше нет. Цена снова снижается, и последних трех кошечек продают
          людям с низким достатком по
        </span>
        <span className="notranslate"> 1</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. В этой ситуации предложение превышает спрос, и цена на товар падает.</span>
      </p>
    ),
    nextButton: true,
  },
  {
    before: () => {
      trainingStore.setCalculateHeader([
        'На счете',
        { val: 'Расход', color: 'red', icon: <CatCalcIcon className="training-calc__icon" /> },
        { val: 'доход', icon: <CatCalcIcon className="training-calc__icon" /> },
        'на счете',
      ]);
      trainingStore.setCalculateExamples([['4', '-', '-', '-']]);
    },
    actionName: 'onNextButton',
    step: 155,
    title: 'ИЗМЕНЕНИЕ РОЛИ',
    message: <p className="training-modal__desc">Для обучения в этой главе ты играешь за зоомагазин.</p>,
    nextButton: true,
  },
  {
    actionName: 'openBalanceModal',
    elements: ['.header_container__main-left-side-container-content', '.avatar_container'],
    pulseElements: ['.avatar_container'],
    disabledElements: ['.header_container__main-left-side-container-content .header-extra'],
    step: 155,
    title: 'ИЗМЕНЕНИЕ РОЛИ',
    message: (
      <p className="training-modal__desc">
        Меню баланса зоомагазина отличается от меню питомника. Давай посмотрим, нажми на кнопку баланса.
      </p>
    ),
    position: 'bottom',
  },
  {
    before: () => {
      if (window.innerHeight < 270) {
        document.querySelectorAll('.modal__body')[0].scrollTo(0, 90);
      }
    },
    actionName: 'onChangeOutcomeVisible',
    elements: ['.training-body-balance-row-outcome'],
    pulseElements: ['.body-balance-row-title.body-balance-row-title--clickable'],
    needOverlayClickModal: false,
    step: 156,
    title: 'ДЕТАЛЬНЫЙ АНАЛИЗ РАСХОДОВ',
    message: <p className="training-modal__desc">Нажми на строку «Расходы», чтобы развернуть ее.</p>,
    position: 'top',
  },
  {
    actionName: 'onNextButton',
    elements: ['.training-body-balance-row-outcome'],
    disabledElements: ['.body-balance-row-title.body-balance-row-title--clickable'],
    needOverlayClickModal: false,
    step: 157,
    title: 'ДЕТАЛЬНЫЙ АНАЛИЗ РАСХОДОВ',
    message: (
      <p className="training-modal__desc">
        <span>Коммунальные услуги для зоомагазина</span> <br />
        <span>стоят</span> <span className="notranslate"> 1</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span> за сезон. Также зоомагазины не могут кормить и скрещивать питомцев.</span>
      </p>
    ),
    position: 'top',
    nextButton: true,
  },
  {
    before: () => {
      const outcomeContent = document.querySelector('.body-balance-row-content');
      if (outcomeContent) {
        const outcomeRow = document.querySelector('.body-balance-row-title.body-balance-row-title--clickable');
        outcomeRow.click();
      }
      document
        .querySelectorAll('.modal__body')[0]
        .scrollTo(0, document.querySelectorAll('.modal__body')[0].scrollHeight);
    },
    actionName: 'hideBalanceModal',
    elements: ['.training-balance-modal-btn'],
    step: 158,
    title: 'ДАВАЙ ПРОДОЛЖИМ',
    message: <p className="training-modal__desc">Нажми кнопку «Понятно», чтобы закрыть окошко баланса.</p>,
    position: 'right',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.nurseries-container', '.nursery-item-wrapper'],
    pulseElements: ['.nursery-item-wrapper'],
    step: 158,
    title: 'ПРОДОЛЖАЕМ ЗНАКОМСТВО',
    message: (
      <p className="training-modal__desc">Настало время зайти в питомник и познакомиться с новым интерфейсом.</p>
    ),
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--shop'],
    disabledElements: ['.lot-area.lot-area--shop'],
    step: 159,
    title: 'ТВОЯ ВИТРИНА',
    message: (
      <p className="training-modal__desc">В игре за зоомагазин твоя витрина находится в верхней части экрана.</p>
    ),
    position: 'bottom',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--city'],
    disabledElements: ['.lot-area.lot-area--city'],
    title: 'ВИТРИНА ГОРОДА',
    message: (
      <div>
        <p className="training-modal__desc">
          Над твоей витриной расположена витрина города. Здесь видно, сколько котят город купит по текущей цене.
        </p>
        <div className="training-modal__cats">
          <Cat
            cat={{ gender: 'female', color: 'ginger' }}
            readonly
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
        </div>
        <div className="training-modal__price training-modal__price--single">
          <p className="training-modal__price-name">цена</p>
          <div className="training-modal__price-value">
            <CoinModalIcon className="training-modal__price-coin" />
            <span className="training-modal__price-cost notranslate">3</span>
          </div>
        </div>
        <p className="training-modal__desc training-modal__desc--with-margin">
          <span>Например, по</span> <span className="notranslate"> 3</span>
          <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span> город купит только</span>
          <span className="notranslate"> 1 </span>
          <span>рыжую кошечку. Ты можешь продавать котят городу. Непроданные котята сбегают из зоомагазина!</span>
        </p>
      </div>
    ),
    position: 'bottom',
    nextButton: true,
  },
  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      if (contents.length !== 2) {
        return false;
      }
      if (
        (contents[0].color === CAT_COLOR.GINGER &&
          contents[0].gender === CAT_GENDER.FEMALE &&
          contents[0].count === 1 &&
          contents[1].color === CAT_COLOR.GRAY &&
          contents[1].gender === CAT_GENDER.FEMALE &&
          contents[1].count === 2) ||
        (contents[1].color === CAT_COLOR.GINGER &&
          contents[1].gender === CAT_GENDER.FEMALE &&
          contents[1].count === 1 &&
          contents[0].color === CAT_COLOR.GRAY &&
          contents[0].gender === CAT_GENDER.FEMALE &&
          contents[0].count === 2)
      ) {
        return true;
      }
      return false;
    },
    elements: ['.lot-area.lot-area--nursery', '.lot-area.lot-area--lot'],
    title: 'ПОКУПАЕМ КОТЯТ',
    isWide: true,
    message: (
      <div>
        <p className="training-modal__desc">
          Купи у питомника два серых котенка и одного рыжего. Перенеси котят из витрины питомника в зону сделки и
          отправь предложение.
        </p>
        <div className="training-modal__cats">
          <Cat
            cat={{ gender: 'female', color: 'gray', count: 2 }}
            readonly
            realCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
          <Cat
            cat={{ gender: 'female', color: 'ginger' }}
            readonly
            notShowCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
        </div>
      </div>
    ),
    position: 'top',
  },
  {
    actionName: 'lotProposalSentModalClose',
  },
  {
    actionName: 'onReceiveMessage__trading_lot_update',
    before: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/b27ea52e-864b-11eb-88bb-0242ac120002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          console.log({ msg_type: MESSAGE_TYPE.TRADING_CONFIRM, lot_id: RootStore.appStore.lots[0].lot_id });
          socket.send(
            JSON.stringify({ msg_type: MESSAGE_TYPE.TRADING_CONFIRM, lot_id: RootStore.appStore.lots[0].lot_id }),
          );
        }, 100);
      };
      trainingStore.setCalculateExamples([['4', { val: '-4', color: 'red' }, '-', '-']]);
    },
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([['4', { val: '-4', color: 'red' }, { val: '+3', color: 'green' }, '-']]);
    },
    actionName: 'sellToCity',
    elements: ['.lot-area.lot-area--city', '.lot-area.lot-area--shop .cat--female-ginger'],
    disabledElements: [
      '.lot-area.lot-area--city .cat--female-gray',
      '.lot-area.lot-area--city .cat--female-ginger',
      '.lot-area.lot-area--shop .cat--female-gray',
    ],
    step: 163,
    title: 'КОТЯТА ТВОИ!',
    message: (
      <p className="training-modal__desc">
        <span>Посмотри, город готов купить</span> <span className="notranslate"> 1 </span>
        <span>рыжего котенка по</span>
        <span className="notranslate"> 3</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Продай рыжего котенка в город. Просто перетащи котенка из своей витрины в витрину города.</span>
      </p>
    ),
    isDisabledDoubleClickCat: true,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--city .cat--female-ginger .cat__price-wrapper'],
    disabledElements: ['.lot-area.lot-area--city .cat--female-ginger .cat__price-wrapper'],
    step: 164,
    title: 'ГОРОД ИЗМЕНИЛ СПРОС',
    message: (
      <p className="training-modal__desc">
        <span>Твоего котика горожане купили. Посмотри, что произошло. Спрос на рыжих котят по</span>
        <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span> закончился, и теперь город покупает их</span>
        <br />
        <span>по 2</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    nextButton: true,
    buttonText: 'Понятно',
    position: 'bottom',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([['4', { val: '-4', color: 'red' }, { val: '+5', color: 'green' }, '-']]);
    },
    actionName: 'sellToCity',
    elements: ['.lot-area.lot-area--city', '.lot-area.lot-area--shop .cat--female-gray'],
    disabledElements: [
      '.lot-area.lot-area--city .cat--female-gray',
      '.lot-area.lot-area--city .cat--female-ginger',
      '.lot-area.lot-area--shop .cat--female-ginger',
    ],
    step: 164,
    title: 'ПРОДАЕМ СЕРОГО',
    message: (
      <p className="training-modal__desc">
        <span>Продай городу</span> <span className="notranslate"> 1 </span>
        <span>серого котика и посмотри, как изменится спрос на серых котят.</span>
      </p>
    ),
    isDisabledDoubleClickCat: true,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--city .cat--female-gray .cat__price-wrapper'],
    disabledElements: ['.lot-area.lot-area--city .cat--female-gray .cat__price-wrapper'],
    step: 165,
    title: 'СПРОС',
    message: (
      <p className="training-modal__desc">
        <span>После продажи одной серой кошечки спрос и цена на этих кошек не изменились, потому что по цене</span>
        <span className="notranslate"> 2</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span> город был готов купить двух кошечек.</span>
      </p>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([['4', { val: '-4', color: 'red' }, { val: '+7', color: 'green' }, '7']]);
    },
    actionName: 'sellToCity',
    elements: ['.lot-area.lot-area--city', '.lot-area.lot-area--shop .cat--female-gray'],
    disabledElements: [
      '.lot-area.lot-area--city .cat--female-gray',
      '.lot-area.lot-area--city .cat--female-ginger',
      '.lot-area.lot-area--shop .cat--female-ginger',
    ],
    step: 165,
    message: <p className="training-modal__desc">У тебя остался один серый котенок. Продай его городу</p>,
    isDisabledDoubleClickCat: true,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isWide: true,
    message: (
      <div>
        <p className="training-modal__desc">Теперь мы знаем, что такое спрос и как вести торговлю с городом.</p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">Было на счете</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на котят</p>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">заработано на котятах</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">стало на счете</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">4</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">4</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">7</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">7</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--center">
          <div className="training-modal__price">
            <p className="training-modal__price-name">МАРЖА</p>
            <div className="training-modal__price-value">
              <span className="training-modal__price-cost training-modal__price-cost--green notranslate">+1</span>
              <CoinModalIcon className="training-modal__price-coin" />
            </div>
          </div>
          <div className="training-modal__price">
            <p className="training-modal__price-name">ПРИБЫЛЬ</p>
            <div className="training-modal__price-value">
              <span className="training-modal__price-cost training-modal__price-cost--green notranslate">+3</span>
              <CoinModalIcon className="training-modal__price-coin" />
            </div>
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
    after: async () => {
      const result = await api.common.createTutorial(stepsApiParams);
      LocalStorage.JOIN_URL = new URL(result.join_url).host.replace('develop', 'dev');
      // ToDo отойти от использования window
      window.runTraining = true;
      RootStore.goTo(
        routes.init,
        { gameUid: result.game_uuid, userUid: LocalStorage.USER_UID },
        { server: LocalStorage.JOIN_URL },
      );
      setTimeout(() => {
        trainingStore.checkIsCorrectAction('onNextButton');
        LocalStorage.SHOWED_BUY_FROM_CITY = true;
        LocalStorage.SHOWED_SELL_TO_CITY = true;
      }, 200);
    },
  },
  {
    actionName: 'onNextButton',
  },

  ///////////////// ВТОРОЙ ПРИМЕР ↓

  {
    before: () => {
      trainingStore.setCalculateHeader([
        'На счете',
        { val: 'Расход', color: 'red', icon: <CatCalcIcon className="training-calc__icon" /> },
        { val: 'доход', icon: <CatCalcIcon className="training-calc__icon" /> },
        { val: 'Расход', color: 'red', icon: <CatCalcIcon className="training-calc__icon" /> },
        { val: 'доход', icon: <CatCalcIcon className="training-calc__icon" /> },
        'на счете',
      ]);
      trainingStore.setCalculateExamples([['4', '-', '-', '-', '-', '-']]);
    },
    actionName: 'routerGoTo',
    elements: ['.nurseries-container', '.nursery-item-wrapper'],
    pulseElements: ['.nursery-item-wrapper'],
    step: 166,
    title: 'ИНОГДА ПЛАНЫ МЕНЯЮТСЯ',
    message: (
      <p className="training-modal__desc">
        Может получиться так, что город купит котят не по той цене, по которой ты планировал. Посмотри, как это бывает.
        Купи двух рыжих котят в питомнике.
      </p>
    ),
  },
  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      if (contents.length !== 1) {
        return false;
      }
      if (
        contents[0].color === CAT_COLOR.GINGER &&
        contents[0].gender === CAT_GENDER.FEMALE &&
        contents[0].count === 2
      ) {
        return true;
      }
      return false;
    },
    elements: ['.lot-area.lot-area--nursery', '.lot-area.lot-area--lot'],
    title: 'ПОКУПАЕМ КОТЯТ',
    message: (
      <div>
        <p className="training-modal__desc">Купи у питомника двух рыжих котят.</p>
        <div className="training-modal__cats">
          <Cat
            cat={{ gender: 'female', color: 'ginger', count: 2 }}
            readonly
            realCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
        </div>
      </div>
    ),
    position: 'top',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/b27ea52e-864b-11eb-88bb-0242ac120002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({ msg_type: MESSAGE_TYPE.TRADING_CONFIRM, lot_id: RootStore.appStore.lots[0].lot_id }),
          );
        }, 100);
      };
    },
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([['4', { val: '-4', color: 'red' }, '-', '-', '-', '-']]);
    },
    actionName: 'lotProposalSentModalClose',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        ['4', { val: '-4', color: 'red' }, { val: '+5', color: 'green' }, '-', '-', '-'],
      ]);
    },
    actionName: 'sellToCity',
    checkParams: (params) => {
      const ourCats = RootStore.appStore.ourCats;
      if (ourCats.length === 1) {
        return true;
      }
      return 'skip';
    },
    elements: ['.lot-area.lot-area--city', '.lot-area.lot-area--shop .cat--female-ginger'],
    disabledElements: [
      '.lot-area.lot-area--city .cat--female-gray',
      '.lot-area.lot-area--city .cat--female-ginger',
      '.lot-area.lot-area--shop .cat--female-gray',
    ],
    step: 169,
    title: 'ИДЕМ ДАЛЬШЕ',
    message: (
      <p className="training-modal__desc">
        <span>На витрине города видно, что он купит одного рыжего котенка по</span>
        <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Попробуй продать городу двух рыжих котят.</span>
      </p>
    ),
    // ToDo отключить модалки о купли и продаже городу(и один шаг убрать нужно будет)
    isDisabledDoubleClickCat: true,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--city .cat--female-ginger'],
    disabledElements: ['.lot-area.lot-area--city .cat--female-ginger'],
    step: 170,
    title: 'ТАК БЫВАЕТ',
    message: (
      <p className="training-modal__desc">
        <span>Город купил первого котенка по</span> <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>, и спрос на дорогого рыжего котенка кончился. Появились люди, готовые заплатить за второго котенка</span>
        <span className="notranslate"> 2</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span> </span>
        <span>
          — он продался в город по этой цене. Перед покупкой котят изучай спрос города и его цены, чтобы не потерять
          деньги.
        </span>
      </p>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    title: 'ПОКУПКА У ГОРОДА',
    message: (
      <p className="training-modal__desc">
        У города можно покупать котят. Он продает их без ограничений, а вот продать ему получится только тех котят, на
        которых есть спрос.
      </p>
    ),
    nextButton: true,
    position: 'bottom',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/b27ea52e-864b-11eb-88bb-0242ac120002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({
              msg_type: 'trading_request',
              to: LocalStorage.USER_UID,
              request_type: 'buy',
              contents: [
                { color: 'gray', gender: 'female', price: 4, count: 1 },
                { color: 'ginger', gender: 'female', price: 4, count: 1 },
              ],
            }),
          );
        }, 100);
      };
    },
  },
  {
    actionName: 'onReceiveMessage__trading_lot_update',
  },
  {
    actionName: 'setCurrentLot',
    elements: ['.mail:last-child'],
    pulseElements: ['.mail:last-child'],
    step: 171,
    message: <p className="training-modal__desc">Тебе пришло предложение от питомника. Открой конверт.</p>,
    position: 'left',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        ['4', { val: '-4', color: 'red' }, { val: '+5', color: 'green' }, { val: '-5', color: 'red' }, '-', '-'],
      ]);
    },
    actionName: 'buyFromCity',
    checkParams: (params) => {
      const ourCats = RootStore.appStore.ourCats;
      if (ourCats.length === 1) {
        return params[0].color !== ourCats[0].color;
      }
      return 'skip';
    },
    elements: ['.lot-area.lot-area--city', '.lot-area.lot-area--shop'],
    disabledElements: ['.lot-area.lot-area--shop .cat--female-ginger', '.lot-area.lot-area--shop .cat--female-gray'],
    step: 172,
    title: 'ПОКУПКА У ГОРОДА',
    message: (
      <div>
        <p className="training-modal__desc">
          Питомник хочет купить у тебя серого и рыжего котенка. Таких котят у тебя пока нет. Купи их у города. Перетащи
          мордочки котят из витрины города в свою витрину.
        </p>
        <div className="training-modal__cats">
          <Cat
            cat={{ gender: 'female', color: 'gray' }}
            readonly
            notShowCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
          <Cat
            cat={{ gender: 'female', color: 'ginger' }}
            readonly
            notShowCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
        </div>
      </div>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--shop'],
    disabledElements: ['.lot-area.lot-area--shop'],
    title: 'ПОКУПКА У ГОРОДА',
    message: <p className="training-modal__desc">Котята куплены. Теперь можно продать их питомнику.</p>,
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'acceptLot',
    elements: ['.lot-area__lot-panel-btn-wrapper .text_button--color-green'],
    pulseElements: ['.lot-area__lot-panel-btn-wrapper .text_button--color-green'],
    title: 'Одобри сделку',
    message: <div></div>,
    position: 'left',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/b27ea52e-864b-11eb-88bb-0242ac120002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({ msg_type: MESSAGE_TYPE.TRADING_CONFIRM, lot_id: RootStore.appStore.lots[1].lot_id }),
          );
        }, 300);
      };
    },
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([
        [
          '4',
          { val: '-4', color: 'red' },
          { val: '+5', color: 'green' },
          { val: '-5', color: 'red' },
          { val: '+8', color: 'green' },
          '8',
        ],
      ]);
    },
    actionName: 'notificationModalOpen',
    elements: ['.mail:last-child'],
    pulseElements: ['.mail:last-child'],
  },
  {
    actionName: 'notificationModalClose',
  },
  {
    actionName: 'onNextButton',
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isWide: true,
    message: (
      <div>
        <p className="training-modal__desc">Перед выполнением практического задания посмотри, как мы считали:</p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">Было на счете</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на котят</p>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">заработано на котятах</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">стало на счете</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">4</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />

          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">(4+5)</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">(5+8)</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">8</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--center">
          <div className="training-modal__price">
            <p className="training-modal__price-name">МАРЖА</p>
            <div className="training-modal__price-value">
              <span className="training-modal__price-cost training-modal__price-cost--green notranslate">+1</span>
              <CoinModalIcon className="training-modal__price-coin" />
            </div>
          </div>
          <div className="training-modal__price">
            <p className="training-modal__price-name">ПРИБЫЛЬ</p>
            <div className="training-modal__price-value">
              <span className="training-modal__price-cost training-modal__price-cost--green notranslate">+4</span>
              <CoinModalIcon className="training-modal__price-coin" />
            </div>
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    colorBg: 'green',
    title: (
      <div>
        <span>ПОЗДРАВЛЯЕМ!</span>
        <br /> <span>ОБУЧЕНИЕ ПО</span> <span className="notranslate"> 5&nbsp;</span>
        <span>ГЛАВЕ ЗАВЕРШЕНО</span>
      </div>
    ),
    message: (
      <div>
        <p className="training-modal__desc">Чтобы перейти на следующий уровень, выполни практическое задание.</p>
        <div className="training-modal__icon-block">
          <LikeModalIcon className="training-modal__icon" />
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
];

export const task = {
  chapter: 5,
  trainingPreventSetOpenedGroupedCat: true,
  data: {},
  watch: [
    {
      actionName: 'onReceiveMessage__balance',
      checkParams: (params, data) => {
        if (
          _.find(RootStore.appStore.cityQuota, { gender: CAT_GENDER.FEMALE, color: CAT_COLOR.GINGER }).quantity === null
        ) {
          return params[0].current_balance === 16;
        }
        return 'skip';
      },
    },
    {
      actionName: 'lotProposalSentModalClose',
      checkParams: () => {
        // бот соглашается на созданную игроком заявку
        const socket = new WebSocket(
          `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/b27ea52e-864b-11eb-88bb-0242ac120002`,
        );
        socket.onopen = function (event) {
          setTimeout(() => {
            socket.send(
              JSON.stringify({
                msg_type: MESSAGE_TYPE.TRADING_CONFIRM,
                lot_id: RootStore.appStore.lots[RootStore.appStore.lots.length - 1].lot_id,
              }),
            );
          }, 100);
        };
        return 'skip';
      },
    },
  ],
  title: (
    <div>
      <span className="notranslate">5 </span> <span>ГЛАВА «СЕГМЕНТАЦИЯ РЫНКА» ПРАКТИЧЕСКОЕ ЗАДАНИЕ</span>
    </div>
  ),
  message: (
    <div>
      <p className="training-modal__desc">
        <span>У тебя</span> <span className="notranslate"> 6</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Твоя задача – за</span>
        <span className="notranslate"> 1 </span> <span>сезон заработать</span> <span className="notranslate"> 16</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
      <p className="training-modal__think-well">
        Помни, что богатых покупателей меньше, чем со средним и низким достатком!
      </p>
    </div>
  ),
  wrongMessage: (
    <div className="training-modal__price training-modal__price--single">
      <p className="training-modal__price-name">
        <span>Не получилось</span>
        <br />
        <span>заработать</span>
      </p>
      <div className="training-modal__price-value">
        <CoinModalIcon className="training-modal__price-coin" />
        <span className="training-modal__price-cost training-modal__price-cost--white notranslate">16</span>
      </div>
    </div>
  ),
};
