// Код для исправления бага с белым экраном при использовании Google Translate

// Удаляет дочерний элемент из DOM.
if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
        if (child.parentNode !== this) {
            if (console) {
                console.warn('Cannot remove a child from a different parent', child, this);
            }
            return child;
        }
        return originalRemoveChild.apply(this, arguments);
    };
// Добавляет элемент в список дочерних элементов родителя перед указанным элементом.
    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
            if (console) {
                console.warn(
                    'Cannot insert before a reference node from a different parent',
                    referenceNode,
                    this
                );
            }
            return newNode;
        }
        return originalInsertBefore.apply(this, arguments);
    };
}