import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarCandyImage from '../../../assets/nurseryAvatars/avatarCandy.png';

function AvatarCandy({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <g clipPath="url(#avatarCandy)">
    //     <path
    //       d="M27.4832 11.6775C27.3046 11.4988 27.098 11.3666 26.8794 11.2804C27.0616 10.707 26.9118 10.0368 26.4375 9.56258C25.9632 9.08829 25.2931 8.93852 24.7197 9.12075C24.6335 8.90215 24.5013 8.69555 24.3226 8.51692C23.7132 7.90747 22.7804 7.83391 22.1451 8.31227C22.1355 8.30153 21.7851 9.74649 21.3989 11.3542C22.0083 11.6294 22.5692 12.0154 23.0561 12.5023L23.4978 12.944C23.9847 13.4309 24.3706 13.9918 24.6458 14.6011C26.2535 14.215 27.6985 13.8645 27.6878 13.8549C28.1662 13.2197 28.0926 12.2869 27.4832 11.6775Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M12.944 23.4977L12.5023 23.0561C12.0154 22.5692 11.6294 22.0083 11.3542 21.3989C9.74652 21.785 8.30156 22.1355 8.3123 22.1451C7.8339 22.7804 7.90746 23.7131 8.51695 24.3226C8.69558 24.5013 8.90218 24.6335 9.12078 24.7197C8.93855 25.2931 9.08832 25.9633 9.56261 26.4375C10.0369 26.9118 10.7071 27.0616 11.2804 26.8793C11.3666 27.0979 11.4988 27.3045 11.6775 27.4832C12.2869 28.0926 13.2198 28.1662 13.855 27.6878C13.8646 27.6986 14.2151 26.2536 14.6012 24.6459C13.9918 24.3707 13.4309 23.9847 12.944 23.4977Z"
    //       fill="#02516C"
    //     />
    //     <rect
    //       x="18.9014"
    //       y="10.0273"
    //       width="10"
    //       height="12.6739"
    //       rx="5"
    //       transform="rotate(45 18.9014 10.0273)"
    //       fill="#02516C"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="avatarCandy">
    //       <rect width="20" height="20" fill="white" transform="translate(8 8)" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <Avatar style={style} avatarImage={AvatarCandyImage} />
  );
}

export default AvatarCandy;
