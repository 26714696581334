import React from 'react';

function TrainingReloadIcon({ style, width = "40" }) {
  return (
    <svg width={width} height="24" viewBox="0 0 24 24" fill="#C6A788" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path 
        d="M10.213 3.35147C11.6628 3.1197 11.6721 3.10789 11.6721 1.55893C11.6721 0.612222 11.8347 0 12.0864 0C13.2756 0 19.8694 4.75709 19.8719 5.61715C19.8747 6.60095 13.4421 11.4061 12.1178 11.4093C11.8272 11.41 11.6721 10.8524 11.6721 9.80583C11.6721 8.84914 11.5091 8.20124 11.2684 8.20124C10.4558 8.20124 8.47928 9.69494 7.7672 10.8474C5.10536 15.1545 8.03714 20.4913 13.0649 20.4913C16.9152 20.4913 19.3192 17.995 19.3317 13.9842C19.3381 11.8355 19.3894 11.706 20.5123 10.995C21.1577 10.586 21.7995 10.3657 21.9386 10.5051C22.0773 10.6445 22.1903 12.0687 22.1896 13.6697C22.1882 16.2242 22.0591 16.8136 21.1363 18.4824C19.986 20.5616 18.7786 21.7251 16.5272 22.9228C15.3487 23.55 14.3044 24 11.8704 24C9.43638 24 8.70859 23.55 7.5301 22.9228C5.2794 21.7254 4.07166 20.5619 2.91956 18.4824C1.98319 16.7919 1.4961 16.028 1.50002 13.3333C1.50359 10.8826 2.02815 10.2305 2.67533 9.01315C4.25139 6.04751 7.19953 3.8332 10.213 3.35147Z"
        fill="inherit"
      />
    </svg>
  );
}

export default TrainingReloadIcon;
