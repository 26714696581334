const numberInputKeyDown = (event) => {
  if (
    /\D/.test(event.key) &&
    event.key !== 'Backspace' &&
    event.key !== 'Delete' &&
    event.key !== 'ArrowLeft' &&
    event.key !== 'ArrowRight'
  ) {
    event.preventDefault();
  }
};

export default numberInputKeyDown;
