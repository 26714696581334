import React from 'react';
import Modal from './Modal/Modal';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import { observer } from 'mobx-react';
import './Cat/style.scss';
import Cat, { BOTTOM_TEXT, CAT_STATE } from './Cat/Cat';
import './SellCatFromParentWindowModal.scss';
import trainingStore from '../../store/trainingStore';
import { runInAction } from 'mobx';
import { DRAG_AREA } from '../../common/constants';

const ACTION_TEXT = {
  [DRAG_AREA.LOT]: {
    text: 'продать',
    appendEnter: false,
  },
  [DRAG_AREA.STORAGE_MY_CATS]: {
    text: 'отправить на склад',
    appendEnter: true,
  },
  [DRAG_AREA.TRANSFER_ZONE]: {
    text: 'отправить на экспорт',
    appendEnter: true,
  },
  [DRAG_AREA.TRANSPORTATION_ZONE]: {
    text: 'перевезти',
    appendEnter: false,
  },
};

const SellCatFromParentWindowModal = () => {
  const { appStore } = RootStore;
  const actionData = appStore.sellCatFromParentWindowModalData;
  if (!actionData) {
    return null;
  }
  const catsFromHouseBigWindows = _.cloneDeep(actionData.groupedCats).filter((catGroup) => {
    if (catGroup.contents?.length) {
      catGroup.contents = catGroup.contents.filter(
        (cat) => appStore.catsInHouseBigWindows[cat.detail?.cat_id || cat.cat_id],
      );
      catGroup.count = catGroup.contents.length;
      return catGroup.count !== 0;
    }
    return false;
  });
  if (!catsFromHouseBigWindows.length) {
    // При открытии модального окна мы передаем один контент и его не меняем, расчет котиков из домика в контенте
    // происходит прямо в модальном окне, это нужно для кейсов, когда один
    // член команды открыл эту модалку и доктор решил попересаживать котиков, которые находились в этой сделке,
    // из домика, и модалка:
    // 1. Будет динамически менять котиков из домика при пересаживании котиков, будет менять их количество в модалке и тд
    // 2. Если всех котиков из сделки, которые находились в домике, выселят, то модалка автоматически закроется.
    appStore.hideSellCatFromParentWindowModal();
    return null;
  }

  const catCount = catsFromHouseBigWindows.length;
  const catText = catCount > 1 ? 'котиков' : 'котика';

  return (
    <Modal
      isOpen={!!actionData}
      title={
        <span>
          Вы уверены, что хотите <br /> {ACTION_TEXT[actionData.catType].text || ACTION_TEXT[DRAG_AREA.LOT].text}
          {ACTION_TEXT[actionData.catType].appendEnter && <br />} {catText} из домика?
        </span>
      }
      size="cats"
      onClose={() => {
        if (!trainingStore.launched) {
          appStore.hideSellCatFromParentWindowModal();
        }
      }}
    >
      <div className="modal__body-wrapper">
        <div className="modal__body-cats">
          {catsFromHouseBigWindows.map((catGroup) => {
            return (
              <Cat
                key={catGroup.contents[0].detail ? catGroup.contents[0].detail.cat_id : catGroup.contents[0].cat_id}
                readonly={true}
                cat={catGroup}
                state={CAT_STATE.SINGLE}
                bottomText={BOTTOM_TEXT.KIND}
                from={actionData.catType}
                forceHouseIconView={true}
                realCount={true}
                className={{ 'modal__cat-from-parent-window': true }}
              />
            );
          })}
        </div>
      </div>

      <div className="modal__body-actions">
        <TextButton
          onClick={() => {
            actionData.callback();
            appStore.hideSellCatFromParentWindowModal();
          }}
          title="Подтвердить"
          color="green"
        />
        {!trainingStore.launched && (
          <TextButton onClick={appStore.hideSellCatFromParentWindowModal} title="Отмена" color="grey" />
        )}
      </div>
    </Modal>
  );
};

export default observer(SellCatFromParentWindowModal);
