import React from 'react';

function CarIcon({ style, className, fill = '#C6A788' }) {
  return (
    <svg
      width="50"
      height="37"
      viewBox="0 0 50 37"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <path
        d="M3.22581 0C1.44424 0 0 1.44048 0 3.21739V28.9565C0 30.7334 1.44424 32.1739 3.22581 32.1739H7.25806C7.25806 29.0643 9.78549 26.5435 12.9032 26.5435C16.021 26.5435 18.5484 29.0643 18.5484 32.1739H25C26.7816 32.1739 28.2258 30.7334 28.2258 28.9565V3.21739C28.2258 1.44048 26.7816 0 25 0H3.22581Z"
        fill={fill}
      />
      <path
        d="M39.7679 7.23913C41.1721 7.23913 42.5357 7.70952 43.6399 8.57485C46.2214 10.5979 48.2226 13.2638 49.4419 16.3041L49.6092 16.7211C49.8673 17.3649 50 18.0519 50 18.7453V28.9565C50 30.7334 48.5558 32.1739 46.7742 32.1739H45.9677C45.9677 29.0643 43.4403 26.5435 40.3226 26.5435C37.2048 26.5435 34.6774 29.0643 34.6774 32.1739H33.0645C31.283 32.1739 29.8387 30.7334 29.8387 28.9565V10.4565C29.8387 8.67961 31.283 7.23913 33.0645 7.23913H39.7679Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.3226 37C42.9949 37 45.1613 34.8393 45.1613 32.1739C45.1613 29.5085 42.9949 27.3478 40.3226 27.3478C37.6502 27.3478 35.4839 29.5085 35.4839 32.1739C35.4839 34.8393 37.6502 37 40.3226 37ZM40.3226 34.587C41.6588 34.587 42.7419 33.5066 42.7419 32.1739C42.7419 30.8412 41.6588 29.7609 40.3226 29.7609C38.9864 29.7609 37.9032 30.8412 37.9032 32.1739C37.9032 33.5066 38.9864 34.587 40.3226 34.587Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9032 37C15.5756 37 17.7419 34.8393 17.7419 32.1739C17.7419 29.5085 15.5756 27.3478 12.9032 27.3478C10.2309 27.3478 8.06452 29.5085 8.06452 32.1739C8.06452 34.8393 10.2309 37 12.9032 37ZM12.9032 34.587C14.2394 34.587 15.3226 33.5066 15.3226 32.1739C15.3226 30.8412 14.2394 29.7609 12.9032 29.7609C11.5671 29.7609 10.4839 30.8412 10.4839 32.1739C10.4839 33.5066 11.5671 34.587 12.9032 34.587Z"
        fill={fill}
      />
    </svg>
  );
}

export default CarIcon;
