import { observer } from 'mobx-react';
import React from 'react';
import { CAT_COLOR, CAT_GENDER } from '../../common/constants';

function CatUrlImage({ color, gender, width = 'auto', height = '100%', className }) {
  const catColor = color === CAT_COLOR.GINGER ? 'orange' : color;
  const catGender = gender === CAT_GENDER.MALE ? 'boy' : 'girl';
  return (
    <img
      src={require(`../../assets/kittens/${catColor}_small_${catGender}_01.png`).default}
      width={width}
      height={height}
      className={className}
    />
  );
}

export default observer(CatUrlImage);
