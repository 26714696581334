import React from 'react';

function QuestionIcon({ style, stylePath, className }) {
  return (
    <svg
      className={className}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M55.8435 8.80743C64.6879 13.4739 68.0558 30.6463 61.7641 38.9878C59.984 41.3469 55.3433 45.3924 51.4513 47.9788C47.5576 50.5649 44.3733 53.7572 44.3733 55.0747C44.3733 56.7543 42.3431 57.4689 37.5661 57.4689C30.9391 57.4689 30.7588 57.3168 30.7588 51.7036C30.7588 47.4093 31.8719 45.1721 35.1189 42.9381C47.0093 34.7585 49.4788 32.0571 49.4788 27.2319C49.4788 16.6414 33.1582 16.4626 29.981 27.0164C28.5038 31.9234 27.7347 32.4244 21.6762 32.4244H15L15.8866 24.7074C17.8232 7.85073 38.4645 -0.363929 55.8435 8.80743Z"
        fill="#C6A788"
        style={stylePath}
      />
      <path
        d="M45.2242 69.1563C45.2242 74.8614 45.0626 75 38.417 75C32.59 75 31.5297 74.4424 31.0499 71.1215C30.0508 64.2057 31.8956 62.2305 38.8287 62.7933C44.9024 63.2857 45.2242 63.6065 45.2242 69.1563Z"
        fill="#C6A788"
        style={stylePath}
      />
    </svg>
  );
}

export default QuestionIcon;
