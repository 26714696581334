import React from 'react';

function TeamSellerIcon({ isActive = false }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 10 10"
      fill={isActive ? '#FDF6EE' : '#C0A88F'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 2.37456H0.9375V2.05684L7.8125 1.49764V2.05684H8.75V1.10366C8.75 0.404658 8.19312 -0.0859115 7.51312 0.0125837L1.2375 0.923823C0.556875 1.02295 0 1.67556 0 2.37456L0 8.72909C0 9.06616 0.131696 9.38942 0.366116 9.62776C0.600537 9.8661 0.918479 10 1.25 10H8.75C9.08152 10 9.39946 9.8661 9.63388 9.62776C9.8683 9.38942 10 9.06616 10 8.72909V3.64547C10 3.3084 9.8683 2.98514 9.63388 2.7468C9.39946 2.50846 9.08152 2.37456 8.75 2.37456ZM7.8125 6.82655C7.68934 6.82651 7.5674 6.8018 7.45364 6.75385C7.33987 6.70589 7.23651 6.63562 7.14946 6.54705C7.0624 6.45848 6.99336 6.35334 6.94627 6.23764C6.89918 6.12194 6.87496 5.99795 6.875 5.87273C6.87504 5.74752 6.89934 5.62354 6.94651 5.50787C6.99367 5.3922 7.06279 5.28711 7.1499 5.1986C7.23701 5.11009 7.34042 5.03989 7.45422 4.99201C7.56801 4.94413 7.68997 4.91951 7.81313 4.91955C8.06185 4.91964 8.30035 5.02017 8.47617 5.19905C8.65198 5.37793 8.75071 5.62048 8.75062 5.87337C8.75054 6.12625 8.65166 6.36874 8.47573 6.5475C8.29979 6.72625 8.06122 6.82663 7.8125 6.82655Z"
        fill="inherit"
      />
    </svg>
  );
}

export default TeamSellerIcon;
