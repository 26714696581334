import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import IconButton from '../../buttons/IconButton/IconButton';
import classNames from '../../../common/classNames';
import { TeamHistoryIcon } from '../../icons/index';

function HeaderTeamHistoryButton() {
  const { appStore } = RootStore;
  return (
    <IconButton
      customClass={classNames({
        'header__team-history-button': true,
      })}
      color="blue"
      onClick={appStore.openTeamHistoryModal}
    >
      <TeamHistoryIcon style={{ width: '24px', height: '24px', display: 'block' }} fill="#fff" />
    </IconButton>
  );
}

export default observer(HeaderTeamHistoryButton);
