import React from 'react';
import SpecialityIcon from '../../../SpecialityIcon/SpecialityIcon';

class Player {
  constructor({ speciality, fullName, competences, active }) {
    this._speciality = speciality;
    this._fullName = fullName;
    this._competences = competences;
    this._active = active;
  }

  get icon() {
    return <SpecialityIcon speciality={this._speciality} />;
  }

  get disabled() {
    return !this._active;
  }

  get fullName() {
    return this._fullName;
  }

  get competences() {
    // ToDo: {BCC-938} сделать прием с платформы
    return this._competences;
  }
}

export default Player;
