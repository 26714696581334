import RootStore from '../../../store';
import Modal from '../Modal/Modal';
import { WalletTitleIcon } from '../../icons';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from '../../../common/classNames';
import TextButton from '../../buttons/TextButton/TextButton';
import './limitModal.scss';
import NumberInput from '../../NumberInput/NumberInput';
import { LIMITS_TITLE, LIMITS_TYPE } from '../../../store/constants';
import corpStore, { LIMITS_INFO } from '../../../store/CorpStore';

function LimitModal() {
  const { appStore } = RootStore;
  const modalData = corpStore.limitModalData;

  const [currentArea, setCurrentArea] = useState(null);

  const [editing, setEditing] = useState(false);
  const [seasonHistory, setSeasonHistory] = useState(appStore.currentSeason);

  useEffect(() => {
    setSeasonHistory(appStore.currentSeason);
  }, [modalData]);

  useEffect(() => {
    if (appStore.currentSeason > seasonHistory) {
      setEditing(false);
    }
  }, [seasonHistory, editing]);

  const seasonsCount = [];
  for (let i = 0; i < appStore.turnDurationsSec.length; i++) {
    seasonsCount.push(i);
  }

  if (!appStore.me || !modalData?.isOpen) {
    return null;
  }

  if (modalData.onOpen) {
    modalData.onOpen();
  }

  const { noActiveLimitsOfAllAreaNums } = corpStore;

  function onClose() {
    corpStore.hideLimitModal();
    setEditing(false);
    corpStore.resetLocalLimitCheckBox();
    if (modalData.onClose) {
      modalData.onClose();
    }
  }

  return (
    <Modal
      isOpen={modalData.isOpen}
      onClose={onClose}
      title="Настройка лимитов"
      titleIcon={<WalletTitleIcon className="modal-title-icon" />}
      customClass="limits"
    >
      <div className="modal__body-seasons">
        <Swiper
          touchStartPreventDefault={false}
          threshold={0}
          touchAngle={40}
          freeModeMomentumRatio={0.2}
          freeModeMomentumVelocityRatio={0.2}
          slidesPerView={'auto'}
          freeMode={true}
          grabCursor={true}
          centerInsufficientSlides={true}
          initialSlide={seasonHistory}
          onClick={(season) => {
            if (season.clickedIndex !== undefined) {
              setTimeout(() => {
                setSeasonHistory(season.clickedIndex);
              }, 0);
            }
          }}
          className="body-balance-seasons"
          refs="swiperSeasons"
        >
          {seasonsCount.map((season) => (
            <SwiperSlide
              key={season}
              className={classNames({
                'body-balance-season': true,
                'body-balance-season--active': seasonHistory === season,
              })}
            >
              <p className="body-balance-season-text">{season + 1} сезон</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        className={classNames({
          'modal__body-limits-cities': true,
          'modal__body-limits-cities--no-active-limits': noActiveLimitsOfAllAreaNums,
        })}
      >
        <div className="modal__body_limit-title" />
        {corpStore.allActiveAreaNums.map((areaNum) => {
          return <span key={areaNum}>ГОРОД {areaNum}</span>;
        })}
      </div>

      {noActiveLimitsOfAllAreaNums ? (
        <div className="modal__body-limits-none">
          <span>Лимиты не выставлены</span>
        </div>
      ) : (
        <div className="modal__body-limits-wrapper">
          {Object.keys(LIMITS_INFO).map((checkBoxesType) => {
            const checkBox = LIMITS_INFO[checkBoxesType];
            if (
              (checkBox.visibled && !checkBox.visibled()) ||
              !corpStore.companiesOfAllAreasHasTeamLimit(checkBox.limitType, false)
            ) {
              return;
            }
            return (
              <div key={checkBoxesType} className="modal__body_limit">
                <div className="modal__body_limit-title">{LIMITS_TITLE[checkBoxesType]}</div>
                {corpStore.allActiveAreaNums.map((areaNum) => {
                  const disabled = !corpStore.companyHasTeamLimitLocally(areaNum, checkBox.limitType);
                  let preValueSymbol;
                  if (LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_BUY === checkBoxesType) {
                    preValueSymbol = '+';
                  } else if (LIMITS_TYPE.DEVIATION_FROM_SHOWCASE_PRICES_SELL === checkBoxesType) {
                    preValueSymbol = '-';
                  }
                  return (
                    <NumberInput
                      key={areaNum + checkBox.limitType}
                      value={corpStore.teamLimitsByAreaNums[areaNum][seasonHistory][checkBox.limitType].localValue ?? 0}
                      preValueSymbol={preValueSymbol}
                      className={classNames({ 'limit-input': !editing || disabled })}
                      onChange={(newValue) => {
                        if (newValue <= 99999) {
                          corpStore.setLocalLimitValue(areaNum, checkBox.limitType, newValue, seasonHistory);
                          if (editing && !disabled) setCurrentArea(areaNum);
                        }
                      }}
                      readOnly={!editing}
                      disabled={disabled}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      <div className="modal__body-actions limit-actions">
        <TextButton
          title={'Копировать'}
          color="yellow"
          onClick={() => {
            corpStore.sendCorpLimits(null, seasonHistory);
            setEditing(false);
          }}
        />
        <TextButton title={modalData.closeButtonText || 'Закрыть'} color="red" onClick={onClose} />
        <TextButton
          title={editing && !noActiveLimitsOfAllAreaNums ? 'Подтвердить' : 'Редактировать'}
          color="green"
          isDisabled={noActiveLimitsOfAllAreaNums}
          onClick={() => {
            if (noActiveLimitsOfAllAreaNums) {
              return;
            }
            if (editing) {
              corpStore.sendCorpLimits();
              setEditing(false);
            } else {
              setEditing(true);
            }
          }}
        />
      </div>
    </Modal>
  );
}

export default observer(LimitModal);
