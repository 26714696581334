import Modal from '../../Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import corpStore from '../../../../store/CorpStore';
import TextButton from '../../../buttons/TextButton/TextButton';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../../Cat/Cat';

function MovingCatsModal() {
  const { movingCatsModalData } = corpStore;

  if (!movingCatsModalData) {
    return null;
  }

  return (
    <Modal
      isOpen={!!movingCatsModalData}
      onClose={() => corpStore.hideMovingCatsModal()}
      title={movingCatsModalData.title}
      desc={movingCatsModalData.desc}
      colorBg={movingCatsModalData.colorBg}
      size="cats"
    >
      <div className="modal__body-wrapper">
        <div className="modal__body-cats">
          {movingCatsModalData.cats.map((cat) => {
            return (
              <Cat
                key={cat[0].gender + cat[0].color}
                readonly={true}
                cat={cat[0]}
                bottomText={BOTTOM_TEXT.KIND}
                state={CAT_STATE.SINGLE}
                realCount={true}
                customCount={cat.length}
              />
            );
          })}
        </div>
      </div>
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={() => corpStore.hideMovingCatsModal()} />
      </div>
    </Modal>
  );
}

export default observer(MovingCatsModal);
