import React from 'react';

function BankLogo({ background, color, colorBg, size, style, className }) {
  let svgPath = null;
  switch (size) {
    case 'small':
      svgPath =
        'M42.1825 17.5588C41.6966 17.196 41.1064 17 40.5 17C39.8936 17 39.3034 17.196 38.8175 17.5588L18.8875 32.4363C17.2 33.695 18.0913 36.3763 20.195 36.3763H60.8062C62.9112 36.3763 63.8013 33.695 62.115 32.4363L42.1825 17.5588ZM40.5 29.5C39.837 29.5 39.2011 29.2366 38.7322 28.7678C38.2634 28.2989 38 27.663 38 27C38 26.337 38.2634 25.7011 38.7322 25.2322C39.2011 24.7634 39.837 24.5 40.5 24.5C41.163 24.5 41.7989 24.7634 42.2678 25.2322C42.7366 25.7011 43 26.337 43 27C43 27.663 42.7366 28.2989 42.2678 28.7678C41.7989 29.2366 41.163 29.5 40.5 29.5ZM18 59.8138C18 56.1875 20.9375 53.25 24.5625 53.25H56.4375C60.0625 53.25 63 56.1875 63 59.8125V61.6875C63 62.55 62.3 63.2488 61.4375 63.2488H19.5625C18.7 63.25 18 62.55 18 61.6875V59.8125V59.8138ZM28 49.75C28 50.3023 27.5523 50.75 27 50.75H24C23.4477 50.75 23 50.3023 23 49.75V39.875C23 39.3227 23.4477 38.875 24 38.875H27C27.5523 38.875 28 39.3227 28 39.875V49.75ZM34 38.875C33.4477 38.875 33 39.3227 33 39.875V49.75C33 50.3023 33.4477 50.75 34 50.75H37C37.5523 50.75 38 50.3023 38 49.75V39.875C38 39.3227 37.5523 38.875 37 38.875H34ZM44 38.875C43.4477 38.875 43 39.3227 43 39.875V49.75C43 50.3023 43.4477 50.75 44 50.75H47C47.5523 50.75 48 50.3023 48 49.75V39.875C48 39.3227 47.5523 38.875 47 38.875H44ZM54 38.875C53.4477 38.875 53 39.3227 53 39.875V49.75C53 50.3023 53.4477 50.75 54 50.75H57C57.5523 50.75 58 50.3023 58 49.75V39.875C58 39.3227 57.5523 38.875 57 38.875H54Z';
      break;
    case 'huge':
      svgPath =
        'M42.9911 0.966527C42.1272 0.339025 41.0781 0 40 0C38.9219 0 37.8728 0.339025 37.0089 0.966527L1.57778 26.7013C-1.42222 28.8786 0.162222 33.5166 3.90222 33.5166H76.1C79.8422 33.5166 81.4244 28.8786 78.4267 26.7013L42.9911 0.966527ZM40 21.6222C38.8213 21.6222 37.6908 21.1666 36.8573 20.3556C36.0238 19.5446 35.5556 18.4447 35.5556 17.2978C35.5556 16.1509 36.0238 15.0509 36.8573 14.2399C37.6908 13.4289 38.8213 12.9733 40 12.9733C41.1787 12.9733 42.3092 13.4289 43.1427 14.2399C43.9762 15.0509 44.4444 16.1509 44.4444 17.2978C44.4444 18.4447 43.9762 19.5446 43.1427 20.3556C42.3092 21.1666 41.1787 21.6222 40 21.6222ZM0 74.0582C0 67.7856 5.22222 62.7044 11.6667 62.7044H68.3333C74.7778 62.7044 80 67.7856 80 74.0561V77.2994C80 78.7913 78.7556 80 77.2222 80H2.77778C1.24444 80.0022 0 78.7913 0 77.2994V74.0561V74.0582ZM17.7778 57.38C17.7778 57.9322 17.3301 58.38 16.7778 58.38H9.88889C9.33661 58.38 8.88889 57.9322 8.88889 57.38V38.8389C8.88889 38.2866 9.33661 37.8389 9.88889 37.8389H16.7778C17.3301 37.8389 17.7778 38.2866 17.7778 38.8389V57.38ZM27.6667 37.8389C27.1144 37.8389 26.6667 38.2866 26.6667 38.8389V57.38C26.6667 57.9322 27.1144 58.38 27.6667 58.38H34.5556C35.1078 58.38 35.5556 57.9322 35.5556 57.38V38.8389C35.5556 38.2866 35.1078 37.8389 34.5556 37.8389H27.6667ZM45.4444 37.8389C44.8922 37.8389 44.4444 38.2866 44.4444 38.8389V57.38C44.4444 57.9322 44.8922 58.38 45.4444 58.38H52.3333C52.8856 58.38 53.3333 57.9322 53.3333 57.38V38.8389C53.3333 38.2866 52.8856 37.8389 52.3333 37.8389H45.4444ZM63.2222 37.8389C62.6699 37.8389 62.2222 38.2866 62.2222 38.8389V57.38C62.2222 57.9322 62.6699 58.38 63.2222 58.38H70.1111C70.6634 58.38 71.1111 57.9322 71.1111 57.38V38.8389C71.1111 38.2866 70.6634 37.8389 70.1111 37.8389H63.2222Z';
  }

  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      {background && (
        <rect x="1.5" y="1.5" width="77" height="77" rx="4.5" fill={colorBg} stroke="#C6A788" strokeWidth="3" />
      )}
      <path d={svgPath} fill={color} />
    </svg>
  );
}

export default BankLogo;
