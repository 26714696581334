import RootStore from '../../index';
import React from 'react';
import { LOT_STATUS } from '../../constants';
import CoinIcon from '../../../ui/icons/CoinIcon';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../../../ui/components/Cat/Cat';
import CoinModalIcon from '../../../ui/icons/modal/CoinModalIcon';
import MinusModalIcon from '../../../ui/icons/modal/MinusModalIcon';
import PlusModalIcon from '../../../ui/icons/modal/PlusModalIcon';
import EquallyModalIcon from '../../../ui/icons/modal/EquallyModalIcon';
import MultiplyModalIcon from '../../../ui/icons/modal/MultiplyModalIcon';
import LikeModalIcon from '../../../ui/icons/modal/LikeModalIcon';
import ChapterOneIcon from '../../../ui/icons/training/ChapterOneIcon';
import trainingStore from '../../trainingStore';
import CatCalcIcon from '../../../ui/icons/calc/CatCalcIcon';
import HideModalIcon from '../../../ui/icons/training/HideModalIcon';

export const chapter = 1;

export const stepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 3,
          sell_price: 3,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000003',
      name: 'bot 2',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 1,
          sell_price: 2,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 2,
    },
  ],
  turn_durations: [100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  city_quota_override: {
    male_gray: {
      buy_price: 2,
      sell_price: 2,
      buy_quantity: 100,
    },
  },
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
};

export const taskApiParams = {
  game_type: 'tutorial',
  chapter_num: 1,
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 4,
          sell_price: 6,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000003',
      name: 'bot 2',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 1,
          sell_price: 2,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 4,
    },
  ],
  turn_durations: [100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  city_quota_override: {
    male_black: {
      buy_price: 2,
      sell_price: 2,
      buy_quantity: 100,
    },
  },
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
};

export const steps = [
  {
    actionName: 'showStepModalClick',
    title: <div>Добро пожаловать в обучение</div>,
    isWide: true,
    message: (
      <p className="training-modal__desc">
        <span>для начала, обрати внимание, что в правом верхнем углу этого оповещения есть значок </span>
        <span>
          <HideModalIcon />
        </span>
        <span>
          . При нажатии на него, оповещение будет сворачиваться и тебе будет доступен весь экран для взаимодействия и
          выполнения заданий. Нажми на значок и сверни оповещение. После этого, разверни его нажав на появившийся значок
          в левом нижнем углу экрана.
        </span>
      </p>
    ),
  },
  {
    before: () => {
      window.trainingPreventSetOpenedGroupedCat = true;
      trainingStore.setCalculateHeader([
        'На счете',
        { val: 'Расход', color: 'red', icon: <CatCalcIcon className="training-calc__icon" /> },
        { val: 'доход', icon: <CatCalcIcon className="training-calc__icon" /> },
        'на счете',
      ]);
      trainingStore.setCalculateExamples([['2', '-', '-', '-']]);
    },
    actionName: 'onNextButton',
    step: 1, // по EXCEL документу в notion
    chapter: '1',
    chapterIcon: <ChapterOneIcon className="training-modal__chapter-icon" />,
    title: (
      <div>
        <span>Добро пожаловать в главу</span>
        <br />
        <span>"Ориентация на клиента"!</span>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        в нашем городе работают питомники и зоомагазины. питомники разводят и выращивают котят. Зоомагазины активно
        торгуют с питомниками и горожанами. все хотят получить прибыль!
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 23,
    title: 'ГЛАВНОЕ МЕНЮ',
    message: (
      <p className="training-modal__desc">У тебя тоже получится! Давай посмотрим на главное меню вверху экрана.</p>
    ),
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    elements: ['.header_container__main-left-side-container-content', '.avatar_container'],
    pulseElements: ['.avatar_container'],
    title: 'МОНЕТЫ НА РАСХОДЫ',
    step: 24,
    message: (
      <p className="training-modal__desc">
        Здесь можно посмотреть, сколько ты потратил и заработал за один ход (баланс).
      </p>
    ),
    nextButton: true,
    disabledElements: ['.header_container__main-left-side-container-content', '.avatar_container'],
  },
  {
    actionName: 'openExtra',
    elements: ['.icon_button.button_bg_blue.training-extra'],
    pulseElements: ['.icon_button.button_bg_blue.training-extra'],
    title: 'ДОПОЛНИТЕЛЬНОЕ МЕНЮ',
    step: 26,
    message: (
      <p className="training-modal__desc">
        Кнопка помогает перемещаться по игре. Нажми на кнопку, чтобы познакомиться с ней подробнее.
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    elements: ['.header-extra__item:nth-child(1)'],
    pulseElements: ['.header-extra__item:nth-child(1)'],
    title: 'Стартовые параметры',
    message: (
      <p className="training-modal__desc">
        Первым пунктом идут стартовые параметры, здесь можно посмотреть информацию о текущей сессии.
      </p>
    ),
    position: 'left-margin',
    nextButton: true,
    menuClickDisabled: true,
    disabledElements: [
      '.ReactModal__Overlay',
      '.header-extra__item:nth-child(1)',
      '.header-extra__item:nth-child(2)',
      '.header-extra__item:nth-child(3)',
      '.header-extra__item:nth-child(4)',
      '.header-extra__item:nth-child(5)',
      '.header-extra__item:nth-child(6)',
      '.header-extra__item:nth-child(7)',
    ],
  },
  {
    actionName: 'onNextButton',
    elements: ['.header-extra__item:nth-child(2)'],
    pulseElements: ['.header-extra__item:nth-child(2)'],
    title: 'Цель задания',
    message: (
      <p className="training-modal__desc">Кнопка «Цель задания» напомнит, что нужно сделать для прохождения главы.</p>
    ),
    position: 'left-margin',
    nextButton: true,
    disabledElements: [
      '.ReactModal__Overlay',
      '.header-extra__item:nth-child(1)',
      '.header-extra__item:nth-child(2)',
      '.header-extra__item:nth-child(3)',
      '.header-extra__item:nth-child(4)',
      '.header-extra__item:nth-child(5)',
      '.header-extra__item:nth-child(6)',
      '.header-extra__item:nth-child(7)',
    ],
  },
  {
    actionName: 'onNextButton',
    elements: ['.header-extra__item:nth-child(3)'],
    pulseElements: ['.header-extra__item:nth-child(3)'],
    title: 'ДОПОЛНИТЕЛЬНОЕ МЕНЮ',
    message: (
      <p className="training-modal__desc">
        Кнопка «Начать задание заново» запустит задание с начала, если захочешь переделать его.
      </p>
    ),
    position: 'left-margin',
    nextButton: true,
    disabledElements: [
      '.ReactModal__Overlay',
      '.header-extra__item:nth-child(1)',
      '.header-extra__item:nth-child(2)',
      '.header-extra__item:nth-child(3)',
      '.header-extra__item:nth-child(4)',
      '.header-extra__item:nth-child(5)',
      '.header-extra__item:nth-child(6)',
      '.header-extra__item:nth-child(7)',
    ],
  },
  {
    actionName: 'onNextButton',
    elements: ['.header-extra__item:nth-child(4)'],
    pulseElements: ['.header-extra__item:nth-child(4)'],
    title: 'ДОПОЛНИТЕЛЬНОЕ МЕНЮ',
    step: 28,
    message: <p className="training-modal__desc">Эта кнопка позволяет выйти в главное меню обучения.</p>,
    position: 'left-margin',
    nextButton: true,
    disabledElements: [
      '.ReactModal__Overlay',
      '.header-extra__item:nth-child(1)',
      '.header-extra__item:nth-child(2)',
      '.header-extra__item:nth-child(3)',
      '.header-extra__item:nth-child(4)',
      '.header-extra__item:nth-child(5)',
      '.header-extra__item:nth-child(6)',
      '.header-extra__item:nth-child(7)',
    ],
  },
  {
    actionName: 'onNextButton',
    elements: ['.header-extra__item:nth-child(5)'],
    pulseElements: ['.header-extra__item:nth-child(5)', '.header-extra__item:nth-child(6)'],
    title: 'ДОПОЛНИТЕЛЬНОЕ МЕНЮ',
    step: 30,
    message: <p className="training-modal__desc">Можно отключить звуки и музыку.</p>,
    position: 'left-margin',
    nextButton: true,
    disabledElements: [
      '.ReactModal__Overlay',
      '.header-extra__item:nth-child(1)',
      '.header-extra__item:nth-child(2)',
      '.header-extra__item:nth-child(3)',
      '.header-extra__item:nth-child(4)',
      '.header-extra__item:nth-child(5)',
      '.header-extra__item:nth-child(6)',
      '.header-extra__item:nth-child(7)',
    ],
  },
  {
    actionName: 'onNextButton',
    elements: ['.header-extra__item:nth-child(7)'],
    pulseElements: ['.header-extra__item:nth-child(7)'],
    title: 'Полноэкранный режим',
    message: <p className="training-modal__desc">Можно включить полноэкранный режим.</p>,
    position: 'left-margin',
    nextButton: true,
    disabledElements: [
      '.ReactModal__Overlay',
      '.header-extra__item:nth-child(1)',
      '.header-extra__item:nth-child(2)',
      '.header-extra__item:nth-child(3)',
      '.header-extra__item:nth-child(4)',
      '.header-extra__item:nth-child(5)',
      '.header-extra__item:nth-child(6)',
      '.header-extra__item:nth-child(7)',
    ],
  },
  {
    before: () => {
      RootStore.appStore.hideExtra();
    },
    // кастомный actionName, он не из AppStore
    actionName: 'routerGoTo',
    // проверка параметров action-а. может возвращать true или 'success' - если это следующий шаг,
    // 'skip' - если ничего делать не нужно
    // false - если нужно запретить это действие и написать пользователю, что он сделал не верный шаг
    // checkParams: (params) => params[1] && params[1].uuid === '00000000-0000-0000-0000-000000000003',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
    step: 10,
    title: 'ЭТО ЗООМАГАЗИН',
    message: <p className="training-modal__desc">Нажми на него, чтобы пройти в окно совершения сделок.</p>,
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--shop'],
    disabledElements: ['.lot-area.lot-area--shop'],
    step: 16,
    title: (
      <div>
        <span>ЭТО ВИТРИНА</span>
        <br />
        <span>ЗООМАГАЗИНА</span>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>Тут можно покупать и продавать котят. Например, черный котенок стоит</span>
        <br />
        <span>здесь</span> <span className="notranslate"> 3</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'setCurrentPlayer',
    elements: ['.slick-arrow.slick-next'],
    pulseElements: ['.slick-arrow.slick-next'],
    step: 17,
    title: 'СРАВНЕНИЕ ЦЕН',
    message: (
      <p className="training-modal__desc">
        <span>В игре несколько зоомагазинов. Чтобы посмотреть цены другого магазина, нажми подсвеченную кнопку.</span>
        <br />
        <span>Если ты её не видишь, то попробуй свернуть это оповещение.</span>
      </p>
    ),
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--shop .cat--male-black .cat__price-wrapper .cat__price:last-child'],
    disabledElements: ['.lot-area.lot-area--shop .cat--male-black .cat__price-wrapper'],
    step: 18,
    title: 'СОСЕДНИЙ ЗООМАГАЗИН',
    message: (
      <p className="training-modal__desc">
        <span>Тут котенок стоит</span> <span className="notranslate"> 2</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span>. Этот магазин продает котят по самой низкой цене.</span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'getLotPrice',
    checkParams: () => RootStore.appStore.currentPlayer.lot?.contents[0]?.count === 2 || 'skip',
    elements: ['.lot-area.lot-area--shop', '.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area__lot-panel', '.lot-area__players-slider'],
    // elementForDrop: ['.lot-area--lot'],
    step: 19,
    title: 'ВОЗМОЖНОСТИ',
    message: (
      <div>
        <p className="training-modal__desc">
          За один раз можно купить сразу несколько котиков. Помести двух котят в зону сделки: для этого перетащи котенка
          в сделку или кликни по нему два раза.
        </p>
        <div className="training-modal__cats">
          <Cat
            cat={{ gender: 'male', color: 'black', count: 2 }}
            readonly
            realCount={true}
            state={CAT_STATE.SINGLE}
            bottomText={BOTTOM_TEXT.KIND}
          />
        </div>
      </div>
    ),
    position: 'top',
  },
  {
    actionName: 'getLotPrice',
    checkParams: () => RootStore.appStore.currentPlayer.lot?.contents[0]?.count === 1 || 'skip',
    elements: ['.lot-area.lot-area--shop', '.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area--shop .cat', '.lot-area__lot-panel', '.lot-area__players-slider'],
    isDisabledAllDeleteCat: true,
    step: 21,
    title: 'ПОКУПАЕМ КОТЕНКА',
    message: (
      <p className="training-modal__desc">
        <span>Сейчас на счете</span> <span className="notranslate"> 2</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>, давай попробуем увеличить их количество до</span>
        <span className="notranslate"> 3</span> <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Но сначала убери одного котенка из сделки. Перетащи одного котенка обратно в витрину магазина.</span>
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'onReceiveMessage__trading_lot_update',
    elements: ['.training-create-lot-button'],
    pulseElements: ['.training-create-lot-button'],
    step: 22,
    title: 'ПОКУПАЕМ КОТЕНКА',
    message: <p className="training-modal__desc">Осталось одобрить сделку, нажми кнопку «Отправить».</p>,
    position: 'left',
  },
  {
    actionName: 'lotProposalSentModalClose',
    stopHandleMessages: true,
  },
  {
    actionName: 'onNextButton',
    elements: ['.mail'],
    disabledElements: ['.mail'],
    step: 23,
    title: 'ОТПРАВЛЕНО ПРЕДЛОЖЕНИЕ',
    message: (
      <p className="training-modal__desc">
        Зоомагазину «Полосатый» отправлено письмо о том, что ты хочешь купить у него котенка. Сейчас ты видишь сиреневый
        конверт. Так выглядят твои исходящие предложения зоомагазинам.
      </p>
    ),
    nextButton: true,
    position: 'left',
  },
  {
    actionName: 'onNextButton',
    elements: ['.mail__indicator'],
    disabledElements: ['.mail'],
    step: 23,
    title: 'КОНВЕРТ С КОТЁНКОМ',
    message: (
      <p className="training-modal__desc">
        Если по итогам сделки ты получаешь котенка, то на конверте тоже будет изображен котенок.
      </p>
    ),
    nextButton: true,
    position: 'left',
  },
  {
    actionName: 'notificationModalOpen', // TODO у нас "питомник Леопольд" в дизайне ИЗ ЗООМАГАЗИНА “ЗООЦЕНТР”
    startHandleMessages: true,
    elements: ['.mail'],
    pulseElements: ['.mail'],
    step: 24,
    title: 'ПОЛУЧЕН ОТВЕТ',
    message: (
      <p className="training-modal__desc">
        Зеленый конверт – хорошая новость. Так зоомагазины соглашаются продать тебе или купить у тебя котенка. Кликни на
        конверт, чтобы прочитать сообщение.
      </p>
    ),
    position: 'left',
  },
  {
    after: () => {
      trainingStore.setRolledCalc(false);
      trainingStore.setCalculateExamples([['2', { val: '-2', color: 'red' }, '-', '-']]);
    },
    step: 25,
    actionName: 'notificationModalClose',
  },
  {
    after: () => {
      if (window.innerWidth < 1201) {
        trainingStore.setRolledCalc(true);
      }
    },
    actionName: 'onNextButton',
    elements: ['.training-calc'],
    step: 26,
    title: 'ЭТО КАЛЬКУЛЯТОР',
    message: (
      <p className="training-modal__desc">
        <span>У тебя было</span> <span className="notranslate"> 2</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>, ты потратил их на котенка. Калькулятор записал эти действия.</span>
      </p>
    ),
    nextButton: true,
    position: 'top',
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area--nursery .cat--male-black'],
    disabledElements: ['.lot-area--nursery .cat--male-black'],
    step: 27,
    title: (
      <div>
        <span>ТЕПЕРЬ У ТЕБЯ ЕСТЬ</span>
        <br />
        <span className="notranslate"> 1 </span> <span>КОТЕНОК</span>
      </div>
    ),
    message: (
      <p className="training-modal__desc">
        <span>Рядом с котенком ты видишь число </span> <span className="notranslate">( ×1 ).</span>
        <span> Это означает, что у тебя есть</span>
        <span className="notranslate"> 1 </span>
        <span>черный котенок.</span>
      </p>
    ),
    nextButton: true,
    position: 'top',
  },
  {
    actionName: 'setCurrentPlayer',
    elements: ['.slick-arrow.slick-prev'],
    pulseElements: ['.slick-arrow.slick-prev'],
    step: 28,
    title: 'КОТИК КУПЛЕН – ПРОДАЕМ',
    message: (
      <p className="training-modal__desc">
        Надо дорого продать котенка. Чтобы посмотреть цены другого магазина, нажми подсвеченную кнопку.
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'getLotPrice',
    checkParams: () => RootStore.appStore.currentPlayer.lot?.contents[0] || 'skip',
    elements: ['.lot-area.lot-area--nursery', '.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area__lot-panel'],
    step: 29,
    title: 'ПРОДАЕМ КОТЕНКА',
    message: (
      <p className="training-modal__desc">
        Перетащи котенка из своей витрины в зону сделки или кликни по котенку два раза.
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'onReceiveMessage__trading_lot_update',
    elements: ['.training-create-lot-button'],
    step: 30,
    title: 'ПРОДАЕМ КОТЕНКА',
    message: (
      <p className="training-modal__desc">Нажми на кнопку «Отправить», чтобы отправить предложение зоомагазину.</p>
    ),
    position: 'left',
  },
  {
    actionName: 'lotProposalSentModalClose',
    stopHandleMessages: true,
  },
  {
    actionName: 'onNextButton',
    elements: ['.mail'],
    disabledElements: ['.mail'],
    step: 31,
    title: (
      <div>
        <span>ОТПРАВЛЕНО</span>
        <br />
        <span>ПРЕДЛОЖЕНИЕ</span>
      </div>
    ),
    message: <p className="training-modal__desc">Зоомагазину «БОНИФАЦИЙ» отправлено предложение.</p>,
    nextButton: true,
    position: 'left',
  },
  {
    actionName: 'onNextButton',
    elements: ['.mail__indicator'],
    disabledElements: ['.mail'],
    step: 23,
    title: 'КОНВЕРТ С МОНЕТКОЙ',
    message: (
      <p className="training-modal__desc">
        А если по итогу сделки ты получишь монеты, то и на конверте будет изображена монета.
      </p>
    ),
    nextButton: true,
    position: 'left',
  },
  {
    actionName: 'notificationModalOpen', // TODO тут текст отличается от дизайна сильно, надо уточнить
    startHandleMessages: true,
    elements: ['.mail'],
    step: 32,
    title: 'ПОЛУЧЕН ОТВЕТ',
    message: (
      <p className="training-modal__desc">
        Магазин прислал тебе ответ. Кликни на конверт, чтобы узнать, купил ли он у тебя котенка.
      </p>
    ),
    position: 'left',
  },
  {
    after: () => {
      trainingStore.setCalculateExamples([['2', { val: '-2', color: 'red' }, { val: '+3', color: 'green' }, '3']]);
    },
    step: 33,
    actionName: 'notificationModalClose',
  },
  {
    actionName: 'onNextButton',
    step: 34,
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    message: (
      <div>
        <p className="training-modal__desc">
          <span>У НАС БЫЛО</span>
          <span className="notranslate"> 2</span>
          <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
          <span>
            . МЫ СРАВНИЛИ ЦЕНЫ ДВУХ МАГАЗИНОВ И КУПИЛИ КОТЕНКА ПО САМОЙ НИЗКОЙ&nbsp;ЦЕНЕ - В МАГАЗИНЕ
            <span className="notranslate"> №2 </span>
          </span>
        </p>
        <div className="training-modal__cats training-modal__cats--with-price">
          <div className="training-modal__cat-with-price">
            <Cat
              cat={{ gender: 'male', color: 'black' }}
              readonly
              notShowCount={true}
              state={CAT_STATE.SINGLE}
              bottomText={BOTTOM_TEXT.KIND}
            />
            <div className="training-modal__price">
              <p className="training-modal__price-name">
                <span>магазин</span> <span className="notranslate"> №1</span>
              </p>
              <div className="training-modal__price-value">
                <CoinModalIcon className="training-modal__price-coin" />
                <span className="training-modal__price-cost notranslate">3</span>
              </div>
            </div>
          </div>
          <div className="training-modal__cat-with-price">
            <Cat
              cat={{ gender: 'male', color: 'black' }}
              readonly
              notShowCount={true}
              state={CAT_STATE.SINGLE}
              bottomText={BOTTOM_TEXT.KIND}
            />
            <div className="training-modal__price">
              <p className="training-modal__price-name">
                <span>магазин</span> <span className="notranslate"> №2</span>
              </p>
              <div className="training-modal__price-value">
                <CoinModalIcon className="training-modal__price-coin" />
                <span className="training-modal__price-cost notranslate">2</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
  {
    actionName: 'onNextButton',
    step: 35,
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    message: (
      <div>
        <p className="training-modal__desc">
          <span>
            После покупки мы хотели заработать и нашли покупателя с самой большой ценой покупки. Им стал магазин
          </span>
          <span className="notranslate"> №1.</span>
        </p>
        <div className="training-modal__cats training-modal__cats--big training-modal__cats--with-price">
          <div className="training-modal__cat-with-price">
            <Cat
              cat={{ gender: 'male', color: 'black' }}
              readonly
              notShowCount={true}
              state={CAT_STATE.SINGLE}
              bottomText={BOTTOM_TEXT.KIND}
            />
            <div className="training-modal__price">
              <p className="training-modal__price-name">
                <span>магазин</span> <span className="notranslate"> №1</span>
              </p>
              <div className="training-modal__price-value">
                <CoinModalIcon className="training-modal__price-coin" />
                <span className="training-modal__price-cost notranslate">3</span>
              </div>
            </div>
          </div>
          <div className="training-modal__cat-with-price">
            <Cat
              cat={{ gender: 'male', color: 'black' }}
              readonly
              notShowCount={true}
              state={CAT_STATE.SINGLE}
              bottomText={BOTTOM_TEXT.KIND}
            />
            <div className="training-modal__price">
              <p className="training-modal__price-name">
                <span>магазин</span> <span className="notranslate"> №2</span>
              </p>
              <div className="training-modal__price-value">
                <CoinModalIcon className="training-modal__price-coin" />
                <span className="training-modal__price-cost notranslate">2</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
  {
    actionName: 'onNextButton',
    step: 36,
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isWide: true,
    message: (
      <div>
        <div className="training-modal__desc">
          <span>Мы потратили на котика</span>
          <span className="notranslate"> 2 </span> <span>монеты, потом продали его за</span>
          <span className="notranslate"> 3 </span> <span>монеты. Наш заработок составил</span>
          <span className="notranslate"> 1 </span> <span>монету.</span>
          <br />
          <span>Это прибыль (разница между заработком и расходами):</span>
        </div>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">было на счете</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на котиков</p>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">заработано на котиках</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">стало на счете</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">2</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">2</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">3</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">3</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <div className="training-modal__price training-modal__price--single">
          <p className="training-modal__price-name">прибыль</p>
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost training-modal__price-cost--green notranslate">+1</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 37,
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isWide: true,
    message: (
      <div>
        <div className="training-modal__desc">
          Если бы ты купил двух котят, то деньги, потраченные на котиков, считал бы так:
        </div>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">количество котиков</p>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">цена покупки</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на котиков</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">2</span>
          </div>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">2</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">4</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <p className="training-modal__desc training-modal__desc--with-margin">
          Деньги, заработанные на котиках, считал бы так:
        </p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">количество котиков</p>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">цена продажи</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">заработано на котиках</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">2</span>
          </div>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">3</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">6</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    step: 38,
    colorBg: 'green',
    title: (
      <div>
        <span>ПОЗДРАВЛЯЕМ!</span>
        <br /> ОБУЧЕНИЕ ПО <span className="notranslate"> 1&nbsp;</span>
        <span>ГЛАВЕ ЗАВЕРШЕНО</span>
      </div>
    ),
    message: (
      <div>
        <p className="training-modal__desc">Чтобы перейти на следующий уровень, выполни практическое задание.</p>
        <div className="training-modal__icon-block">
          <LikeModalIcon className="training-modal__icon" />
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
];

export const task = {
  chapter: 1,
  trainingPreventSetOpenedGroupedCat: true,
  data: { confirmedLotCount: 0 },
  watch: [
    {
      actionName: 'onReceiveMessage__trading_lot_update',
      checkParams: (params, data) => {
        if (params[0].status === LOT_STATUS.CONFIRMED) {
          if (data.confirmedLotCount === 1) {
            return RootStore.appStore.balance === 8 ? true : false;
          }
          data.confirmedLotCount++;
        }
        return 'skip';
      },
    },
  ],
  title: (
    <span>
      <span className="notranslate">1 </span> <span>ГЛАВА «ОРИЕНТАЦИЯ НА КЛИЕНТА» ПРАКТИЧЕСКОЕ ЗАДАНИЕ</span>
    </span>
  ),
  message: (
    <div>
      <p className="training-modal__desc">
        <span>У тебя есть</span> <span className="notranslate"> 4</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Постарайся заработать</span> <span className="notranslate"> 8</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>
          . В первой сделке купи котят по самой низкой цене. Во второй сделке продай их зоомагазину по самой высокой
          цене
        </span>
      </p>
      <p className="training-modal__think-well">
        <span>Хорошо подумай, все внимательно посчитай.</span>
        <br /> <span>Помни, что тебе надо извлечь выгоду!</span>
      </p>
    </div>
  ),
  wrongMessage: (
    <div className="training-modal__price training-modal__price--single">
      <p className="training-modal__price-name">
        <span>Не получилось выполнить задание, попробуй заработать</span> <span className="notranslate"> 8</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span> за два действия. Сначала купи, а потом продай.</span>
      </p>
    </div>
  ),
};
