import React from 'react';

function DeleteCatsIcon({ style, stylePath, className }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <g clipPath="url(#deleteCats0)">
        <path
          d="M2.23946 4.78857C1.65576 4.1222 1.1482 3.44732 1.11141 3.28864C1.06865 3.10326 1.39424 2.65068 2.0224 2.02252C2.65055 1.39437 3.10314 1.06878 3.28852 1.11154C3.44719 1.14833 4.12208 1.65589 4.78845 2.23958L5.99987 3.301L7.21129 2.23957C7.87766 1.65588 8.55271 1.14819 8.71124 1.11155C8.89662 1.06879 9.34919 1.39437 9.97735 2.02252C10.6055 2.65068 10.9311 3.10326 10.8883 3.28864C10.8517 3.44717 10.344 4.12221 9.7603 4.78858L8.69887 6L9.76427 7.20745C10.3502 7.87154 10.8579 8.54658 10.8923 8.70739C10.9325 8.89475 10.6034 9.35145 9.97735 9.97748C9.34919 10.6056 8.89661 10.9312 8.71123 10.8885C8.5527 10.8518 7.87767 10.3441 7.2113 9.76042L5.99987 8.699L4.78845 9.76043C4.12208 10.3441 3.44718 10.8517 3.28851 10.8885C3.10313 10.9312 2.65055 10.6056 2.0224 9.97748C1.39424 9.34932 1.06866 8.89674 1.11142 8.71137C1.14821 8.55269 1.65575 7.87779 2.23945 7.21142L3.30087 6L2.23946 4.78857Z"
          fill="black"
          style={stylePath}
        />
      </g>
      <defs>
        <clipPath id="deleteCats0">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DeleteCatsIcon;
