import RootStore from '../../../store';
import routes from '../../../routes';

class Chapter {
  #stepsGeneratorInfo;

  constructor({
    title = null,
    userIdentifierForSocketInitialization = null,
    beforeStepsInitializationStep = null,
    onChapterInitializationActionsNames = [],
    chapter = null,
    stepsApiParams = null,
    taskApiParams = null,
    steps = null,
    initialRouteParams = null,
    seamlessStartOfNextChapter = null,
    nextChapter = null,
    callback = null,
    defaultStepSettings = null,
    disableTimers = false,
    stepsGeneratorInfo = null,
    withLTD = false,
  }) {
    this.title = title;
    this.withLTD = withLTD;
    this.beforeStepsInitializationStep = beforeStepsInitializationStep;
    this._userIdentifierForSocketInitialization = userIdentifierForSocketInitialization;
    this.onChapterInitializationActionsNames = onChapterInitializationActionsNames;
    this.chapter = chapter;
    this.stepsApiParams = stepsApiParams;
    this.taskApiParams = taskApiParams;
    this.steps = steps;
    this.initialRouteParams = initialRouteParams;
    this.seamlessStartOfNextChapter = seamlessStartOfNextChapter;
    this.nextChapter = nextChapter;
    this.callback = callback;
    this.defaultStepSettings = defaultStepSettings;
    this.disableTimers = disableTimers;
    this.#stepsGeneratorInfo = stepsGeneratorInfo;

    const generateStepsOnInitializationIsNeeded =
      stepsGeneratorInfo &&
      (stepsGeneratorInfo.generateOnChapterInitialization ||
        !stepsGeneratorInfo.hasOwnProperty('generateOnChapterInitialization'));
    if (generateStepsOnInitializationIsNeeded) {
      this.generateSteps();
    }

    this.setDefaultStepSettings();
  }

  resetOnChapterInitializationBlockedActionsNames() {
    this.onChapterInitializationActionsNames = [];
  }

  actionIsBlocked(actionName) {
    return this.onChapterInitializationActionsNames.includes(actionName);
  }

  clearUserIdentifierForSocketInitialization() {
    this._userIdentifierForSocketInitialization = null;
  }

  getUserIdentifierForSocketInitialization(clear) {
    const userIdentifier = this._userIdentifierForSocketInitialization;
    if (clear) {
      this.clearUserIdentifierForSocketInitialization();
    }
    return userIdentifier;
  }

  generateSteps() {
    if (this.steps) {
      return;
    }

    this.steps = this.#stepsGeneratorInfo.generator();
    this.setDefaultStepSettings();
  }

  setDefaultStepSettings() {
    if (!this.defaultStepSettings) {
      return;
    }

    const setSettings = (step) => {
      Object.entries(this.defaultStepSettings).forEach(([property, value]) => {
        if (step[property] === null) {
          step[property] = value;
        }
      });
    };

    if (this.beforeStepsInitializationStep) {
      setSettings(this.beforeStepsInitializationStep);
    }

    if (this.steps) {
      this.steps.forEach((step, index, steps) => {
        setSettings(step);

        step.setNextStep(steps[index + 1] ?? null);
        step.setPreviousStep(steps[index - 1] ?? null);
      });
    }
  }

  async onLaunch() {
    if (this.initialRouteParams) {
      const { routeName, params, queryParams } = this.initialRouteParams;
      const route = routes[routeName];
      await RootStore.goTo(route, params, queryParams);
    }
    if (this.callback) {
      this.callback();
    }
  }
}

export default Chapter;
