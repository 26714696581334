import Modal from './Modal/Modal';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import TextButton from '../buttons/TextButton/TextButton';
import classNames from '../../common/classNames';
import { FirstPedestal, SecondPedestal, TeamHistoryIcon, ThirdPedestal } from '../icons';
import { AUDIO_LIST, SPECIALITIES } from '../../common/constants';
import { ROLE_NAME } from '../../store/constants';
import GameOverIcon from '../icons/modal/GameOverIcon';
import EstimationTimerIcon from '../icons/EstimationTimerIcon';
import corpStore from '../../store/CorpStore';
import _ from 'lodash';
import formattedEstimationTimer from '../../common/formattedEstimationTimer';

function GameOverModal() {
  const { appStore } = RootStore;

  const rating = appStore.rating;

  const pedestalPlacesIsUnique = useMemo(() => {
    const pedestalPlaces = rating.filter((item) => {
      return item.rank === 1 || item.rank === 2 || item.rank === 3;
    });

    if (pedestalPlaces.length === 3) {
      return true;
    }
    return false;
  }, [rating]);

  const isPedestal = useMemo(() => {
    if (rating.length && rating.length > 2) {
      if (rating[0].rank === 1 && rating[1].rank === 2 && rating[2].rank === 3 && pedestalPlacesIsUnique) {
        return true;
      }
    }
    return false;
  }, [rating]);

  if (!appStore.gameOverModalShow) {
    return null;
  }

  const estimationTimer = appStore.estimationTimer;
  let formattedTimer = '';
  if (appStore.isTeamMode) {
    formattedTimer = formattedEstimationTimer(estimationTimer);
  }

  let customAudio = null;
  if (!!appStore.gameOverModalShow) {
    const isMedalPlace = rating.some((ratingItem) => {
      let myArea = appStore.me.uuid;
      if (appStore.isCorpMode && !appStore.meIsCEO) {
        myArea = _.find(appStore.company_players, { specialty: SPECIALITIES.CEO }).player_id;
      }
      return (
        ratingItem.player_id === myArea && (ratingItem.rank === 1 || ratingItem.rank === 2 || ratingItem.rank === 3)
      );
    });
    isMedalPlace ? (customAudio = AUDIO_LIST.MEDAL_PLACE) : (customAudio = AUDIO_LIST.NON_MEDAL_PLACE);
  }

  const getPlayer = (uuid) => {
    if (appStore.isCorpMode && !appStore.meIsCEO) {
      return appStore.playerByUuid(corpStore.captainUuidByCEOUuid(uuid));
    } else {
      return appStore.playerByUuid(uuid);
    }
  };

  const row = (player, price, rank, index) => {
    if (('' + rank).length === 1) {
      rank = '0' + rank;
    }
    //TODO убрать, когда бекенд будет присылать только rating компаний, а не всех игроков (командная игра)
    if (!player) {
      //  TODO🠕
      return null;
    }
    const isBankruptcy = appStore.isCorpMode ? price < 0 : price < 0 || !player.active;
    return (
      <div key={player.name} className="body-balance-row">
        <div
          className={classNames({
            'body-balance-row-header': true,
            'body-balance-row-header--good': rank < 4,
            'body-balance-row-header--bad': rank > 3 && rank > appStore.rating[appStore.rating.length - 1].rank - 3,
          })}
        >
          <p className="body-balance-row-title">
            {rank} {player.name}
          </p>
          <p className="body-balance-row-coin">
            <span
              className={classNames({
                'body-balance-coin': true,
                'body-balance-coin--not': !isBankruptcy,
                'body-balance-coin--bankruptcy': isBankruptcy,
              })}
            >
              {!isBankruptcy ? <span className="notranslate">{price}</span> : 'Банкрот'}
            </span>
            {!isBankruptcy && <CoinModalIcon className="body-balance-coin-icon" />}
          </p>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={!!appStore.gameOverModalShow}
      shouldCloseOnOverlayClick={false}
      size="very-big"
      title={'Результаты игры'}
      customAudio={customAudio}
      titleIcon={<GameOverIcon />}
    >
      <div className="body-balance">
        {isPedestal && !!rating.length && (
          <div className="body-balance-pedestal">
            <div className="body-balance-pedestal-item body-balance-pedestal-item--left">
              <div className="body-balance-pedestal-item-block">
                <div className="body-balance-pedestal-item-block-left">
                  <SecondPedestal className="body-balance-pedestal-icon" />
                  <p className="body-balance-pedestal-item-block-balance">
                    <span className="body-balance-coin body-balance-coin--not">{rating[1].balance}</span>
                    <CoinModalIcon className="body-balance-coin-icon" />
                  </p>
                </div>
                <div className="body-balance-pedestal-item-block-right">
                  <p className="body-balance-row-title">{ROLE_NAME[getPlayer(rating[1]?.player_id)?.role]}</p>
                  <p className="body-balance-row-title">{getPlayer(rating[1]?.player_id)?.name}</p>
                </div>
              </div>
              <span className="body-balance-pedestal-item-border" />
            </div>
            <div className="body-balance-pedestal-item body-balance-pedestal-item--middle">
              <div className="body-balance-pedestal-item-block">
                <div className="body-balance-pedestal-item-block-left">
                  <FirstPedestal className="body-balance-pedestal-icon" />
                  <p className="body-balance-pedestal-item-block-balance">
                    <span className="body-balance-coin body-balance-coin--not">{rating[0].balance}</span>
                    <CoinModalIcon className="body-balance-coin-icon" />
                  </p>
                </div>
                <div className="body-balance-pedestal-item-block-right">
                  <p className="body-balance-row-title">{ROLE_NAME[getPlayer(rating[0]?.player_id)?.role]}</p>
                  <p className="body-balance-row-title">{getPlayer(rating[0]?.player_id)?.name}</p>
                </div>
              </div>
              <span className="body-balance-pedestal-item-border" />
            </div>
            <div className="body-balance-pedestal-item body-balance-pedestal-item--right">
              <div className="body-balance-pedestal-item-block">
                <div className="body-balance-pedestal-item-block-left">
                  <ThirdPedestal className="body-balance-pedestal-icon" />
                  <p className="body-balance-pedestal-item-block-balance">
                    <span className="body-balance-coin body-balance-coin--not">{rating[2].balance}</span>
                    <CoinModalIcon className="body-balance-coin-icon" />
                  </p>
                </div>
                <div className="body-balance-pedestal-item-block-right">
                  <p className="body-balance-row-title">{ROLE_NAME[getPlayer(rating[2]?.player_id)?.role]}</p>
                  <p className="body-balance-row-title">{getPlayer(rating[2]?.player_id)?.name}</p>
                </div>
              </div>
              <span className="body-balance-pedestal-item-border" />
            </div>
          </div>
        )}
        {!!rating.length && (
          <div className="body-balance-rows">
            {rating.map((r, index) => {
              if (isPedestal && (index === 0 || index === 1 || index === 2)) {
                return null;
              }
              return row(getPlayer(r.player_id), r.balance, r.rank);
            })}
          </div>
        )}
      </div>
      {appStore.isTeamMode && (
        <>
          <div className="estimation-timer">
            <EstimationTimerIcon />
            <div className="estimation-timer__value">{estimationTimer ? formattedTimer : '00:00'}</div>
          </div>
          {estimationTimer ? (
            <div className="estimation-timer-desc">У Вас есть 5 минут, чтобы оценить игроков своей команды</div>
          ) : (
            <div className="estimation-timer-desc">Время для оценки игроков своей команды вышло</div>
          )}
        </>
      )}
      <div className="modal__body-actions">
        {appStore.isTeamMode && !!estimationTimer && (
          <TextButton color="green" title="Оценить игроков" onClick={() => appStore.openEstimationModal()} />
        )}
        <TextButton color="blue" title="Понятно" onClick={() => appStore.closeSession()} />
      </div>
    </Modal>
  );
}

export default observer(GameOverModal);
