import { observer } from 'mobx-react';
import TextButton from '../../../../../ui/buttons/TextButton/TextButton';
import { SendIcon } from '../../../../../ui/icons';
import { OPERATION_TYPE } from '../../../../../store/constants';
import corpStore from '../../../../../store/CorpStore';
import React from 'react';
import RootStore from '../../../../../store';

export function OperationPanelActions() {
  const { appStore } = RootStore;
  const { currentOperationType, operationContent, detailInfoCatsForOperation, totalCountPlacesInMyCars } = corpStore;
  const currentSeason = appStore.currentSeason + 1;
  const seasonsCount = appStore.turnDurationsSec.length;

  return (
    <TextButton
      title={
        currentOperationType === OPERATION_TYPE.TRANSPORTATION ? (
          <SendIcon style={{ fill: '#FFFBE5' }} />
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SendIcon style={{ fill: '#FFFBE5', marginRight: '10px' }} /> 30%
          </div>
        )
      }
      color="green"
      customClass={'training-create-lot-button'}
      isDisabled={!operationContent || appStore.isCurrentTurnPreliminary}
      onClick={() => {
        if (currentOperationType === OPERATION_TYPE.TRANSPORTATION) {
          if (currentSeason === seasonsCount) {
            corpStore.openNotEnoughSeasonForTransportationModal();
            return;
          }
          if (detailInfoCatsForOperation.count > totalCountPlacesInMyCars) {
            corpStore.openReceivingCarsModal();
            return;
          }
        }
        corpStore.sendCurrentOperation();
      }}
    />
  );
}

export default observer(OperationPanelActions);
