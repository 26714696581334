const LOCAL_STORAGE_KEYS = {
  USER_UID: 'USER_UID',
  ORIGINAL_TRAINING_USER_UID: 'ORIGINAL_TRAINING_USER_UID',
  GAME_UID: 'GAME_UID',
  JOIN_URL: 'JOIN_URL',
  NEED_KEYCLOAK: 'NEED_KEYCLOAK',
  USER_TOKEN: 'USER_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  USER_TOKEN_TRAINING: 'USER_TOKEN_TRAINING',
  SHOWED_BUY_FROM_CITY: 'SHOWED_BUY_FROM_CITY',
  SHOWED_SELL_TO_CITY: 'SHOWED_SELL_TO_CITY',
  WAS_TRAINING: 'WAS_TRAINING',
  TRAINING_TYPE: 'TRAINING_TYPE',
  RUN_TRAINING_TASK: 'RUN_TRAINING_TASK',
  SOUNDS_ON: 'SOUNDS_ON',
  MODAL_NOTIFICATION_ON: 'MODAL_NOTIFICATION_ON',
  IS_FIRST_TIME_GAME_RUNNING: 'IS_FIRST_TIME_GAME_RUNNING',
  BLOCKED_COMPANIES: 'BLOCKED_COMPANIES',
  BLOCKED_BY_COMPANIES: 'BLOCKED_BY_COMPANIES',
  LTD_ARCHIVE: 'LTD_ARCHIVE',
  IS_AUTOTEST_PIPELINE_RUNNING: 'IS_AUTOTEST_PIPELINE_RUNNING',
  MESSENGER_IS_ENABLED: 'MESSENGER_IS_ENABLED',
};

const NotFreezedLocalStorage = {
  remove(key) {
    localStorage.removeItem(key);
  },
};
Object.keys(LOCAL_STORAGE_KEYS).map((key) => {
  Object.defineProperty(NotFreezedLocalStorage, key, {
    get() {
      return localStorage.getItem(LOCAL_STORAGE_KEYS[key]);
    },
    set(value) {
      localStorage.setItem(LOCAL_STORAGE_KEYS[key], value);
    },
    configurable: true,
    enumerable: true,
  });
  return true;
});

const LocalStorage = Object.freeze(NotFreezedLocalStorage);

export { LocalStorage, LOCAL_STORAGE_KEYS };
