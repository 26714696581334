import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import Modal from './Modal/Modal';
import ExpectationIcon from '../icons/modal/ExpectationIcon';

function WaitEndTurnModal() {
  const { appStore } = RootStore;

  return (
    <Modal isOpen={appStore.waitEndTurnModalVisible} head={true} title="Ожидается завершение хода">
      <ExpectationIcon className="modal__body-icon" />
    </Modal>
  );
}

export default observer(WaitEndTurnModal);
