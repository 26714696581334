import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import ExpectationIcon from '../icons/modal/ExpectationIcon';
import TextButton from '../buttons/TextButton/TextButton';
import { GAME_OVER } from '../../common/constants';

function NetworkTimeoutModal() {
  const { appStore } = RootStore;

  if (!appStore.networkTimeoutModalShowed) {
    return null;
  }

  return (
    <Modal
      isOpen={!!appStore.networkTimeoutModalShowed}
      shouldCloseOnOverlayClick={false}
      colorBg="red"
      title="Вы отсутствовали слишком долго"
      desc={
        appStore.networkTimeoutModalShowed === GAME_OVER.PLAYER_TIMEOUT ? null : 'Мы вынуждены объявить вас банкротом'
      }
      style={{ zIndex: 101 }}
    >
      <ExpectationIcon className="modal__body-icon" />
      <div className="modal__body-actions">
        {appStore.isSpectatorModeOn ? (
          <TextButton
            title="Понятно"
            color="purple"
            onClick={() => {
              appStore.networkTimeoutModalShowed = false;
              appStore.openUserListModal();
            }}
          />
        ) : (
          <TextButton title="Выйти из игры" color="purple" onClick={() => appStore.closeSession()} />
        )}
      </div>
    </Modal>
  );
}

export default observer(NetworkTimeoutModal);
