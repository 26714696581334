import React from 'react';

function ParametersModalIcon({ style, className }) {
  return (
    <svg width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.6916 26.9974L38.0102 25.7082C38.0315 25.5094 38.0498 25.277 38.0498 25.0311C38.0498 24.7852 38.0323 24.5528 38.0102 24.354L39.6932 23.0641C40.0057 22.822 40.091 22.3922 39.8921 22.0343L38.1436 19.068C37.9568 18.7332 37.5475 18.5613 37.1321 18.7115L35.148 19.4925C34.7677 19.2235 34.369 18.9963 33.9567 18.8147L33.6549 16.7513C33.6053 16.3596 33.2577 16.0637 32.8469 16.0637H29.3407C28.9299 16.0637 28.5831 16.3596 28.5343 16.7453L28.2317 18.8162C27.8323 18.9926 27.4397 19.2168 27.0426 19.494L25.0532 18.7108C24.6805 18.5696 24.2346 18.728 24.0494 19.0613L22.2986 22.0313C22.092 22.3736 22.1774 22.8182 22.4967 23.0664L24.1782 24.3555C24.1515 24.6074 24.1386 24.8264 24.1386 25.0319C24.1386 25.2374 24.1515 25.4564 24.1782 25.709L22.4952 26.9989C22.1827 27.2418 22.0981 27.6715 22.297 28.0287L24.0456 30.9949C24.2323 31.329 24.6378 31.5024 25.057 31.3514L27.0411 30.5705C27.4207 30.8388 27.8193 31.0659 28.2317 31.2483L28.5335 33.311C28.583 33.7041 28.9299 34 29.3415 34H32.8477C33.2585 34 33.6061 33.7041 33.6549 33.3184L33.9574 31.2483C34.3569 31.0712 34.7486 30.8477 35.1465 30.5697L37.1359 31.3529C37.2319 31.3895 37.3318 31.4082 37.4347 31.4082C37.7304 31.4082 38.0025 31.2498 38.1397 31.0031L39.8959 28.0212C40.091 27.6715 40.0056 27.2418 39.6916 26.9974ZM31.0938 28.0212C29.4123 28.0212 28.0449 26.6805 28.0449 25.0318C28.0449 23.3832 29.4123 22.0425 31.0938 22.0425C32.7752 22.0425 34.1427 23.3832 34.1427 25.0318C34.1427 26.6805 32.7752 28.0212 31.0938 28.0212Z"
        fill="#C6A788"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5897 31.2906C22.4578 31.2127 22.3477 31.1006 22.2713 30.9638L20.3638 27.7279C20.1467 27.3382 20.239 26.8694 20.58 26.6045L22.416 25.1973C22.3869 24.9217 22.3727 24.6829 22.3727 24.4586C22.3727 24.2344 22.3868 23.9955 22.416 23.7208L20.5817 22.3144C20.2332 22.0437 20.1401 21.5587 20.3654 21.1852L22.2754 17.9453C22.4775 17.5816 22.9639 17.4088 23.3705 17.5629L25.5408 18.4173C25.974 18.1149 26.4022 17.8703 26.8379 17.6779L27.168 15.4187C27.2213 14.998 27.5996 14.6752 28.0478 14.6752H31.8727C32.0348 14.6752 32.1879 14.7174 32.3198 14.7915C31.5611 6.496 24.6311 0 16.1943 0C7.25045 0 0 7.30032 0 16.3057C0 25.3111 7.25045 32.6115 16.1943 32.6115C18.4657 32.6115 20.6278 32.1406 22.5897 31.2906ZM3.35107 16.8659C3.35107 18.108 4.35113 19.115 5.58477 19.115H16.6886C16.7101 19.1156 16.7317 19.1159 16.7533 19.1159C17.987 19.1159 18.987 18.109 18.987 16.8668L18.987 5.62151C18.987 4.37939 17.987 3.37244 16.7533 3.37244C15.5197 3.37244 14.5196 4.37938 14.5196 5.62151V14.6169H5.58477C4.35113 14.6169 3.35107 15.6238 3.35107 16.8659Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default ParametersModalIcon;
