import React from 'react';
import { observer } from 'mobx-react';
import Modal from '../Modal/Modal';
import RootStore from '../../../store';
import ListModalIcon from '../../icons/modal/ListModalIcon';
import { CatFaceIcon } from '../../icons';
import Divider from '../Divider/Divider';
import './style.scss';

const ITEMS = [
  {
    key: 'OUR_COMPANY_USER_LIST',
    title: 'Участники команды',
    icon: <CatFaceIcon fill="#C6A788" />,
    visible: () => true,
    onClick: () => RootStore.appStore.openOurCompanyUserListModal(),
  },
  {
    key: 'USER_BLACK_LIST',
    title: 'Черный список',
    icon: <ListModalIcon />,
    visible: () => true,
    onClick: () => RootStore.appStore.openUserListModal(),
  },
  {
    key: 'SESSION_USERS_LIST',
    title: 'Список всех игроков',
    icon: <ListModalIcon />,
    visible: () => RootStore.appStore.isSpectatorModeOn && RootStore.appStore.isCorpMode,
    onClick: () => RootStore.appStore.openSessionUsersListModal(),
  },
];

const SelectUserListModal = () => {
  const { appStore } = RootStore;

  const Content = () => {
    return (
      <div className="select-user-list__content">
        {ITEMS.map((item, index) => {
          const dividerIsNeeded = index !== 0;
          return !item.visible() ? null : (
            <React.Fragment key={item.key}>
              {dividerIsNeeded && <Divider />}
              <div
                className="select-user-list__item"
                onClick={() => {
                  item.onClick();
                  appStore.closeSelectUserListModal();
                }}
              >
                <div className="select-user-list__item-icon">{item.icon}</div>
                <div className="select-user-list__item-title">{item.title}</div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      isOpen={appStore.selectUserListModalFlag}
      onClose={appStore.closeSelectUserListModal}
      transparentOverlay={true}
      customClass="select-user-list"
    >
      <Content />
    </Modal>
  );
};

export default observer(SelectUserListModal);
