import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import SicknessModalIcon from '../icons/modal/SicknessModalIcon';
import EatIcon from '../icons/EatIcon';

function CatIsSicknessOrHungryModal() {
  const { appStore } = RootStore;
  const modalData = appStore.catIsSicknessOrHungryModalData;

  if (!modalData) {
    return null;
  }

  let title;
  let problemOfTheCat;
  let Icon;
  if (modalData.isStorage) {
    title = 'отправить на склад';
  } else if (modalData.isExport) {
    title = 'отправить на экспорт';
  } else if (modalData.isTransportation) {
    title = 'транспортировать';
  } else {
    title = 'продать';
  }

  if (modalData.isHungry) {
    problemOfTheCat = 'голодного котика';
    Icon = <EatIcon className="modal__body-icon" />;
  } else {
    problemOfTheCat = 'больного котика';
    Icon = <SicknessModalIcon className="modal__body-icon" />;
  }
  return (
    <Modal
      isOpen={modalData.isOpen}
      onClose={appStore.hideCatIsSicknessOrHungryModal}
      colorBg="red"
      title={`Вы не можете ${title} ${problemOfTheCat}!`}
    >
      {Icon}
      <div className="modal__body-actions">
        <TextButton title="Понятно" color="blue" onClick={appStore.hideCatIsSicknessOrHungryModal} />
      </div>
    </Modal>
  );
}

export default observer(CatIsSicknessOrHungryModal);
