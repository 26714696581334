import RootStore from '../../index';
import React from 'react';
import { LOT_STATUS, MESSAGE_TYPE } from '../../constants';
import CoinIcon from '../../../ui/icons/CoinIcon';
import CoinModalIcon from '../../../ui/icons/modal/CoinModalIcon';
import PlusModalIcon from '../../../ui/icons/modal/PlusModalIcon';
import EquallyModalIcon from '../../../ui/icons/modal/EquallyModalIcon';
import LikeModalIcon from '../../../ui/icons/modal/LikeModalIcon';
import ChapterEightIcon from '../../../ui/icons/training/ChapterEightIcon';
import Cat, { BOTTOM_TEXT, CAT_STATE } from '../../../ui/components/Cat/Cat';
import MinusModalIcon from '../../../ui/icons/modal/MinusModalIcon';
import MultiplyModalIcon from '../../../ui/icons/modal/MultiplyModalIcon';
import SicknessPoisoning from '../../../ui/icons/SicknessPoisoning';
import SicknessRingworm from '../../../ui/icons/SicknessRingworm';
import SicknessFracture from '../../../ui/icons/SicknessFracture';
import SicknessFleas from '../../../ui/icons/SicknessFleas';
import { LocalStorage } from '../../../common/localStorage';
import trainingStore from '../../trainingStore';
import HelpCalcIcon from '../../../ui/icons/calc/HelpCalcIcon';
import CatCalcIcon from '../../../ui/icons/calc/CatCalcIcon';
import { CAT_COLOR, CAT_GENDER, MATING_STATUS, SICKNESS } from '../../../common/constants';

export const chapter = 9;

export const stepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 4,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 2,
          sell_price: 2,
        },
        female_black: {
          buy_price: 3,
          sell_price: 3,
        },
        male_gray: {
          buy_price: 5,
          sell_price: 5,
        },
        female_gray: {
          buy_price: 6,
          sell_price: 6,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 2,
      houses: [
        {
          left_cat: {
            color: 'gray',
            gender: 'male',
            season_birthdate: 0,
            hungry: true,
          },
          right_cat: {
            color: 'black',
            gender: 'female',
            season_birthdate: 0,
            hungry: true,
          },
          children_cats: [
            {
              color: 'gray',
              gender: 'male',
              sickness: 'fracture',
            },
            {
              color: 'black',
              gender: 'male',
            },
            {
              color: 'gray',
              gender: 'male',
            },
            {
              color: 'black',
              gender: 'male',
            },
            {
              color: 'gray',
              gender: 'female',
            },
            {
              color: 'black',
              gender: 'female',
            },
          ],
        },
        {
          left_cat: null,
          right_cat: null,
          children_cats: [],
        },
      ],
    },
  ],
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  city_quota_override: {
    male_gray: {
      buy_price: 2,
      sell_price: 2,
      buy_quantity: 100,
    },
  },
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
};

export const taskApiParams = {
  game_type: 'tutorial',
  chapter_num: 9,
  initial_money: 1000,
  initial_turn: 4,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 2,
          sell_price: 2,
        },
        female_black: {
          buy_price: 3,
          sell_price: 3,
        },
        male_gray: {
          buy_price: 5,
          sell_price: 5,
        },
        female_gray: {
          buy_price: 6,
          sell_price: 6,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'nursery',
      initial_money: 0,
      houses: [
        {
          left_cat: {
            color: 'black',
            gender: 'male',
            season_birthdate: 0,
          },
          right_cat: {
            color: 'black',
            gender: 'female',
            season_birthdate: 0,
          },
          children_cats: [
            {
              color: 'black',
              gender: 'male',
            },
            {
              color: 'black',
              gender: 'male',
              sickness: 'fracture',
            },
            {
              color: 'black',
              gender: 'male',
            },
            {
              color: 'black',
              gender: 'male',
            },
            {
              color: 'black',
              gender: 'female',
            },
            {
              color: 'black',
              gender: 'female',
            },
          ],
        },
        {
          left_cat: {
            color: 'gray',
            gender: 'male',
            season_birthdate: 0,
          },
          right_cat: {
            color: 'black',
            gender: 'female',
            season_birthdate: 0,
          },
          children_cats: [
            {
              color: 'gray',
              gender: 'male',
            },
            {
              color: 'black',
              gender: 'male',
            },
            {
              color: 'gray',
              gender: 'male',
              sickness: 'poisoning',
            },
            {
              color: 'black',
              gender: 'male',
            },
            {
              color: 'gray',
              gender: 'female',
            },
            {
              color: 'black',
              gender: 'female',
            },
          ],
        },
      ],
    },
  ],
  turn_durations: [100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 100 * 60, 10 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  city_quota_override: {
    male_gray: {
      buy_price: 2,
      sell_price: 2,
      buy_quantity: 100,
    },
  },
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
};

export const steps = [
  {
    before: () => {
      trainingStore.setCalculateHeader([
        'На счете',
        { val: 'Расход', color: 'red', icon: <HelpCalcIcon className="training-calc__icon" /> },
        { val: 'доход', icon: <CatCalcIcon className="training-calc__icon" /> },
        'на счете',
      ]);
      trainingStore.setCalculateExamples([['2', '-', '-', '-']]);
    },
    actionName: 'onNextButton',
    chapter: '9',
    chapterIcon: <ChapterEightIcon className="training-modal__chapter-icon" />,
    title: <div>ДОБРО ПОЖАЛОВАТЬ В ГЛАВУ «БОЛЕЗНИ. ПИТОМНИК»!</div>,
    message: (
      <p className="training-modal__desc">
        <span>Ты в</span> <span className="notranslate"> 9 </span>
        <span>
          главе! Помимо повседневных затрат в жизни случаются экстренные траты. В этой игре такими случаями становятся
          болезни котиков. Если больных котиков не лечить, они сбегут при завершении сезона.
        </span>
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'routerGoTo',
    elements: ['.nurseries-container', '.nursery-item--own'],
    pulseElements: ['.nursery-item--own'],
    title: 'МЕДОСМОТР',
    message: <p className="training-modal__desc">Давай проверим, здоровы ли твои котята. Зайди в питомник</p>,
  },
  {
    actionName: 'addShowedSicknessCatId',
    checkParams: (params) => {
      if (params[0].sickness !== SICKNESS.NONE) {
        return true;
      }
      return 'skip';
    },
    elements: ['.own-nurseries__wood', '.own-nurseries__actions-item--magnifier', '.own-nurseries__houses-slider'],
    disabledElements: ['.nursery-box__family:last-child'],
    title: 'МЕДОСМОТР',
    message: <p className="training-modal__desc">Наведи лупу на малышей и узнаешь, здоровы ли они.</p>,
    isDisabledDoubleClickCat: true,
    position: 'bottom',
  },
  {
    actionName: 'catUnderMagnifierModalClose',
    elements: ['.modal'],
    isDisabledCatUnderMagnifierButtons: true,
    disabledElements: ['.cat-under-magnifier__buttons'],
    title: 'КАКАЯ ЖАЛОСТЬ!',
    message: (
      <p className="training-modal__desc">
        Смотри, у котенка болит лапка. Давай проверим, что будет, если мы продадим больного котенка. Закрой лупу и
        перейди в зоомагазин.
      </p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    elements: ['.modal'],
    disabledElements: ['.cat-under-magnifier__buttons'],
    title: 'БОЛЬНОЙ',
    message: (
      <p className="training-modal__desc">После того, как ты нашел больного котенка, его будет видно даже без лупы.</p>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    position: 'right',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
  },

  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      return contents.length === 6;
    },
    elements: ['.lot-area.lot-area--nursery', '.lot-area.lot-area--lot'],
    title: 'ПРОДАЖА КОТЯТ',
    message: (
      <p className="training-modal__desc">
        Перенеси всех котят из своей витрины в зону сделки и нажми кнопку «Отправить».
      </p>
    ),
    position: 'top',
  },
  {
    actionName: 'lotProposalSentModalClose',
  },
  {
    actionName: 'notificationModalOpen',
    elements: ['.mail'],
    pulseElements: ['.mail'],
  },
  {
    actionName: 'notificationModalClose',
  },
  {
    actionName: 'onNextButton',
    title: '',
    colorBg: 'red',
    message: <p className="training-modal__desc">Тебе отказали в продаже больной кошечки!</p>,
    position: 'bottom',
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    title: 'ОТКАЗ НЕ СЛУЧАЕН',
    message: (
      <p className="training-modal__desc">
        Зоомагазин не купил больного котенка. Если его не вылечить, котенок сбежит от тебя при завершении сезона. В
        реальной игре игрок-зоомагазин может не заметить и купить больного питомца, но продать его не сможет никому.
        Чтобы вылечить котенка, вернись в питомник.
      </p>
    ),
    position: 'top',
    nextButton: true,
  },

  {
    actionName: 'routerGoTo',
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    position: 'right',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.nurseries-container', '.nursery-item--own'],
    pulseElements: ['.nursery-item--own'],
  },
  {
    actionName: 'addShowedSicknessCatId',
    checkParams: (params) => {
      if (params[0].sickness !== SICKNESS.NONE) {
        return true;
      }
      return 'skip';
    },
    elements: ['.own-nurseries__wood', '.own-nurseries__actions-item--magnifier', '.own-nurseries__houses-slider'],
    disabledElements: ['.nursery-box__family:last-child'],
    title: 'ЛЕЧЕНИЕ',
    message: <p className="training-modal__desc">Перетащи лупу на больного котенка.</p>,
    isDisabledDoubleClickCat: true,
    position: 'bottom',
  },
  {
    actionName: 'catTreatmentSend',
    elements: [
      '.own-nurseries__wood',
      '.own-nurseries__actions-item--magnifier',
      '.own-nurseries__houses-slider',
      '.modal',
    ],
    disabledElements: ['.nursery-box__family:last-child'],
    isWide: true,
    isDisabledDoubleClickCat: true,
    title: 'ЛЕЧЕНИЕ',
    message: (
      <div>
        <p className="training-modal__desc">
          В игре могут быть разные заболевания. Чем лечить, ты поймешь по активной пиктограмме на лупе. Забинтуй котенку
          лапу.
        </p>
        <div className="training-modal__cats">
          <div>
            <Cat
              cat={{ gender: 'female', color: 'gray', sickness: 'fleas' }}
              readonly
              forceViewSickness={true}
              notShowCount={true}
              state={CAT_STATE.SINGLE}
              bottomText={BOTTOM_TEXT.KIND}
            />
            <div className="training-modal__sickness">
              <span className="cat-under-magnifier__button training-modal__sickness-button">
                {/*блохи*/}
                <SicknessFleas />
              </span>
              <p className="training-modal__sickness-name">Блохи</p>
            </div>
          </div>
          <div>
            <Cat
              cat={{ gender: 'female', color: 'gray', sickness: 'fracture' }}
              readonly
              forceViewSickness={true}
              notShowCount={true}
              state={CAT_STATE.SINGLE}
              bottomText={BOTTOM_TEXT.KIND}
            />
            <div className="training-modal__sickness">
              <span className="cat-under-magnifier__button training-modal__sickness-button">
                {/*Сломана лапка*/}
                <SicknessFracture />
              </span>
              <p className="training-modal__sickness-name">Сломана лапка</p>
            </div>
          </div>
          <div>
            <Cat
              cat={{ gender: 'female', color: 'gray', sickness: 'ringworm' }}
              forceViewSickness={true}
              readonly
              notShowCount={true}
              state={CAT_STATE.SINGLE}
              bottomText={BOTTOM_TEXT.KIND}
            />
            <div className="training-modal__sickness">
              <span className="cat-under-magnifier__button training-modal__sickness-button">
                {/*Лишай*/}
                <SicknessRingworm />
              </span>
              <p className="training-modal__sickness-name">Лишай</p>
            </div>
          </div>
          <div>
            <Cat
              cat={{ gender: 'female', color: 'gray', sickness: 'poisoning' }}
              readonly
              forceViewSickness={true}
              notShowCount={true}
              state={CAT_STATE.SINGLE}
              bottomText={BOTTOM_TEXT.KIND}
            />
            <div className="training-modal__sickness">
              <span className="cat-under-magnifier__button training-modal__sickness-button">
                {/*Отравление*/}
                <SicknessPoisoning />
              </span>
              <p className="training-modal__sickness-name">Отравление</p>
            </div>
          </div>
        </div>
      </div>
    ),
    position: 'right',
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([['2', { val: '-2', color: 'red' }, '-', '-']]);
    },
    actionName: 'catUnderMagnifierModalClose',
    elements: ['.modal'],
    title: 'СТОИМОСТЬ ЛЕЧЕНИЯ',
    message: (
      <p className="training-modal__desc">
        <span>Ты потратил на лечение</span> <span className="notranslate"> 2</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>. Зато теперь заработаешь</span>
        <span className="notranslate"> 23</span>
        <CoinIcon style={{ position: 'relative', top: '2px' }} />
        <span>
          , ведь котята здоровы и магазины от них не откажутся! Давай попробуем продать всех котят. Закрой лупу и
          вернись на главный экран.
        </span>
      </p>
    ),
    position: 'right',
  },

  {
    actionName: 'routerGoTo',
    elements: ['.header_container__main-left-side-container-content', '.header-extra'],
    disabledElements: ['.header_container__main-left-side-container-content .avatar_container'],
    pulseElements: ['.header-extra'],
    position: 'right',
  },
  {
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
  },

  {
    actionName: 'acceptLot',
    checkParams: () => {
      const contents = RootStore.appStore.currentPlayer.lot.contents;
      return contents.length === 6;
    },
    elements: ['.lot-area.lot-area--nursery', '.lot-area.lot-area--lot'],
    title: 'ПРОДАЖА КОТЯТ',
    message: (
      <p className="training-modal__desc">
        Перенеси всех котят из своей витрины в зону сделки и нажми кнопку «Отправить».
      </p>
    ),
    position: 'top',
  },
  {
    before: () => {
      trainingStore.setCalculateExamples([['2', { val: '-2', color: 'red' }, { val: '+23', color: 'green' }, '23']]);
    },
    actionName: 'lotProposalSentModalClose',
  },
  {
    actionName: 'onNextButton',
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isWide: true,
    message: (
      <div>
        <p className="training-modal__desc">
          В этой главе мы узнали, как лечить наших котят. Чтобы решить практическое задание, посмотри, как мы считали:
        </p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">Было на счете</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на лечение</p>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">заработано на котиках</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">стало на счете</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">2</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">2</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">23</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">23</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--center">
          <div className="training-modal__price">
            <p className="training-modal__price-name">ПРИБЫЛЬ</p>
            <div className="training-modal__price-value">
              <span className="training-modal__price-cost training-modal__price-cost--green notranslate">+21</span>
              <CoinModalIcon className="training-modal__price-coin" />
            </div>
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isWide: true,
    message: (
      <div>
        <p className="training-modal__desc">
          Если бы у тебя заболело двое котят, то стоимость лечения ты бы считал так:
        </p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">
            <span className="notranslate">2 </span>
            <span>котенка</span>
          </p>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">
            <span>цена</span> <span className="notranslate"> 1 </span> <span>лечения</span>
          </p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на лечение</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">2</span>
          </div>
          <MultiplyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">2</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">4</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    colorBg: 'green',
    title: (
      <div>
        <span>ПОЗДРАВЛЯЕМ!</span>
        <br /> <span>ОБУЧЕНИЕ ПО</span>
        <span className="notranslate"> 9&nbsp;</span>
        <span>ГЛАВЕ ЗАВЕРШЕНО</span>
      </div>
    ),
    message: (
      <div>
        <p className="training-modal__desc">Чтобы перейти на следующий уровень, выполни практическое задание.</p>
        <div className="training-modal__icon-block">
          <LikeModalIcon className="training-modal__icon" />
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
];

export const task = {
  chapter: 9,
  data: { confirmedLotCount: 0 },
  initRouteName: 'nursery',
  watch: [
    {
      actionName: 'onReceiveMessage__game_over',
      checkParams: (params) => {
        return false;
      },
    },
    {
      actionName: 'onReceiveMessage__trading_lot_update',
      checkParams: (params) => {
        const appStore = RootStore.appStore;
        if (
          params[0].seller === appStore.me.uuid &&
          params[0].status === 'confirmed' &&
          appStore.ourCats.length === 4
        ) {
          return appStore.balance === 33;
        }
        return 'skip';
      },
    },
  ],
  title: (
    <div>
      <span className="notranslate">9 </span> <span>ГЛАВА «БОЛЕЗНИ. ПИТОМНИК» ПРАКТИЧЕСКОЕ ЗАДАНИЕ</span>
    </div>
  ),
  message: (
    <p className="training-modal__desc">
      <span>У тебя</span> <span className="notranslate"> 0</span>
      <CoinIcon style={{ position: 'relative', top: '2px' }} />
      <span>.</span>
      <br />
      <span>Твоя задача — за</span>
      <span className="notranslate"> 1 </span> <span>сезон заработать</span> <span className="notranslate"> 33</span>
      <CoinIcon style={{ position: 'relative', top: '2px' }} />
      <span>.</span>
      <br /> <span>Среди твоих котят есть больные, но всех надо продать по самой высокой цене. Удачи!</span>
    </p>
  ),
  wrongMessage: (
    <div className="training-modal__price training-modal__price--single">
      <p className="training-modal__price-name">
        <span>Не получилось</span>
        <br />
        <span>заработать</span>
      </p>
      <div className="training-modal__price-value">
        <CoinModalIcon className="training-modal__price-coin" />
        <span className="training-modal__price-cost training-modal__price-cost--white notranslate">33</span>
      </div>
    </div>
  ),
};
