import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import InsuranceModalIcon from '../icons/modal/InsuranceModalIcon';
import trainingStore from '../../store/trainingStore';

function BuyHouseInsuranceModal() {
  const { appStore } = RootStore;
  if (!appStore.buyHouseInsuranceFlag) {
    return null;
  }
  let title = 'хочешь застраховать домик?';
  let desc = '';
  if (appStore.isRealTimeMode && appStore.currentGroupedHouses.insured) {
    title = 'Ты точно хочешь обновить страховку?';
    desc = 'Страховка будет обновлена';
  }

  return (
    <Modal
      isOpen={appStore.buyHouseInsuranceFlag}
      onClose={() => appStore.houseInsurance(false)}
      shouldCloseOnOverlayClick={trainingStore.currentStep?.needOverlayClickModal ?? true}
      title={title}
      desc={desc}
    >
      <InsuranceModalIcon className="modal__body-icon" />
      <div className="modal__body-price">
        <p className="modal__body-price-text">цена страховки</p>
        <p className="modal__body-price-coin">
          <CoinModalIcon className="modal__body-price-coin-icon" />
          <span className="modal__body-price-coin-count notranslate"> 3 </span>
        </p>
      </div>
      <div className="modal__body-actions">
        <TextButton color="green" title="застраховать" onClick={() => appStore.houseInsurance(true)} />
        <TextButton
          isDisabled={trainingStore.currentStep?.needOverlayClickModal === false}
          color="purple"
          title="отмена"
          onClick={() => appStore.houseInsurance(false)}
        />
      </div>
    </Modal>
  );
}

export default observer(BuyHouseInsuranceModal);
