import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import corpStore from '../../../store/CorpStore';
import { AREA_POSITION, OPERATION_TYPE } from '../../../store/constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import RootStore from '../../../store';
import LockIcon from '../../../ui/icons/LockIcon';
import Cat from '../../../ui/components/Cat/Cat';
import { DropContainer } from '../../deal/DropContainer';
import OperationPanel from './components/OperationPanel/OperationPanel';
import OperationAreaInfo from './components/OperationAreaInfo/OperationAreaInfo';
import { DRAG_AREA } from '../../../common/constants';

function OperationField() {
  const { appStore } = RootStore;
  const { currentOperationType } = corpStore;
  const groupedCats = corpStore.groupedOperationCats;
  let fromType = DRAG_AREA.TRANSFER_ZONE;
  if (currentOperationType === OPERATION_TYPE.TRANSPORTATION) {
    fromType = DRAG_AREA.TRANSPORTATION_ZONE;
  }

  const [dragAroundForCatsInOperationField, setDragAroundForCatsInOperationField] = useState(null);
  useEffect(() => {
    const el = document.querySelectorAll('.lot-area__cats .swiper-wrapper')[0];
    if (el) {
      setDragAroundForCatsInOperationField(el.offsetWidth >= el.scrollWidth);
    }
  });

  if (corpStore.currentAreaNumField[AREA_POSITION.TOP] === corpStore.currentAreaNumField[AREA_POSITION.BOTTOM]) {
    // Если выбраны одинаковые города
    return (
      <div className="lot-area lot-area--lot lot-area--lot-disallow">
        <LockIcon />
      </div>
    );
  }

  return (
    <DropContainer
      type={fromType}
      element={({ canDrop, drop }) => (
        <div
          className="lot-area lot-area--lot"
          ref={drop}
          style={
            canDrop
              ? {
                  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #a4c3ae',
                  boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.1)',
                }
              : {}
          }
        >
          <OperationAreaInfo />

          {!groupedCats.length && (
            <div className="lot-area--lot__text">
              {currentOperationType === OPERATION_TYPE.TRANSPORTATION ? (
                <p style={{ width: '400px' }}>
                  перетащи котиков, <br /> чтобы отправить в другой город
                </p>
              ) : (
                <p style={{ width: '500px' }}>
                  перетащи котиков, <br /> чтобы импортировать или экспортировать их
                </p>
              )}
            </div>
          )}

          {!!groupedCats.length && (
            <Swiper
              watchOverflow={!dragAroundForCatsInOperationField}
              touchStartPreventDefault={false}
              threshold={0}
              touchAngle={40}
              freeModeMomentumRatio={0.2}
              freeModeMomentumVelocityRatio={0.2}
              slidesPerView={'auto'}
              freeMode={true}
              grabCursor={true}
              centerInsufficientSlides={true}
              className="lot-area__cats"
            >
              {groupedCats.map((item, index) => (
                <SwiperSlide key={item.gender + item.color} className="lot-area__cats-slide">
                  <Cat
                    from={fromType}
                    dragAround={true}
                    key={index}
                    cat={item}
                    state={currentOperationType}
                    onClick={() => {
                      appStore.setOpenedGroupedCat({ gender: item.gender, color: item.color, fromType });
                    }}
                    onDoubleClick={() => {
                      corpStore.removeCatFromOperationField(item.contents ? item.contents[0] : item);
                    }}
                    onDeleteClick={() => corpStore.removeCatsFromOperationField(item)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          <OperationPanel />
        </div>
      )}
    />
  );
}

export default observer(OperationField);
