import React from 'react';

function PhoneTurnModalIcon({ toHorizontal, className, style }) {
  return toHorizontal ? (
    <svg
      width="84"
      height="70"
      viewBox="0 0 84 70"
      fill="#C6A687"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M4.86316 9.85056H32.5389V27.5467H37.4021V3.66127C37.4021 1.56912 35.7221 0 33.6884 0H3.71368C1.59158 0 0 1.65629 0 3.66127V61.1083C0 63.2005 1.68 64.7696 3.71368 64.7696H12.6442V50.5604H4.86316V9.85056ZM14.3242 3.66127H22.9011C23.6084 3.66127 24.1389 4.18431 24.1389 4.88169C24.1389 5.57908 23.6084 6.10212 22.9011 6.10212H14.3242C13.6168 6.10212 13.0863 5.57908 13.0863 4.88169C13.0863 4.18431 13.7053 3.66127 14.3242 3.66127Z"
        fill="inherit"
      />
      <path
        d="M80.198 33.0386H22.0169C19.8948 33.0386 18.2148 34.6949 18.2148 36.787V66.2516C18.2148 68.3437 19.8948 70 22.0169 70H80.198C82.2317 70 84.0001 68.3437 84.0001 66.2516V36.787C84.0001 34.7821 82.3201 33.0386 80.198 33.0386ZM25.4654 54.6575C23.6969 54.6575 22.3706 53.2628 22.3706 51.6065C22.3706 49.863 23.7854 48.4682 25.4654 48.4682C27.2338 48.4682 28.5601 49.863 28.5601 51.6065C28.5601 53.2628 27.1454 54.6575 25.4654 54.6575ZM73.9201 65.1183H32.6275V37.8331H73.9201V65.1183ZM80.198 55.7036C80.198 56.401 79.6675 56.924 78.9601 56.924C78.2527 56.924 77.7222 56.401 77.7222 55.7036V47.2478C77.7222 46.5504 78.2527 46.0274 78.9601 46.0274C79.6675 46.0274 80.198 46.5504 80.198 47.2478V55.7036Z"
        fill="inherit"
      />
      <path
        d="M52.7873 20.8344C52.3452 20.3985 52.1684 19.8755 52.1684 19.3524C52.1684 18.8294 52.3452 18.2192 52.7873 17.8705C53.6715 16.9988 54.9979 16.9988 55.8821 17.8705L57.7389 19.7011C57.12 14.0349 52.2568 9.50187 46.3326 9.50187C45.0947 9.50187 44.1221 8.54297 44.1221 7.32255C44.1221 6.10212 45.0947 5.14322 46.3326 5.14322C54.7326 5.14322 61.6294 11.6812 62.16 19.7883L64.2821 17.6961C65.1663 16.8244 66.4926 16.8244 67.3768 17.6961C68.261 18.5679 68.261 19.8755 67.3768 20.7472L61.6294 26.4135C60.7452 27.2852 59.4189 27.2852 58.5347 26.4135L52.7873 20.8344Z"
        fill="inherit"
      />
    </svg>
  ) : (
    <svg
      width="84"
      height="74"
      viewBox="0 0 84 74"
      fill="#C6A687"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M73.919 68.5389V40.8632H41.2228V36H79.1083C81.2004 36 82.7695 37.68 82.7695 39.7137V69.6884C82.7695 71.8105 81.1132 73.4021 79.1083 73.4021H22.6612C20.569 73.4021 18.9999 71.7221 18.9999 69.6884V67H33.2091V68.5389H73.919ZM79.1083 59.0779V50.501C79.1083 49.7937 78.5852 49.2632 77.8878 49.2632C77.1905 49.2632 76.6674 49.7937 76.6674 50.501V59.0779C76.6674 59.7853 77.1905 60.3158 77.8878 60.3158C78.5852 60.3158 79.1083 59.6968 79.1083 59.0779Z"
        fill="inherit"
      />
      <path
        d="M0 3.80212L0 59.9832C0 62.1053 1.65629 63.7853 3.74844 63.7853H33.213C35.3051 63.7853 36.9614 62.1053 36.9614 59.9832L36.9614 3.80212C36.9614 1.76844 35.3051 1.52588e-05 33.213 1.52588e-05H3.74844C1.74346 1.52588e-05 0 1.68002 0 3.80212ZM21.6189 56.5348C21.6189 58.3032 20.2242 59.6295 18.5679 59.6295C16.8244 59.6295 15.4296 58.2148 15.4296 56.5348C15.4296 54.7663 16.8244 53.44 18.5679 53.44C20.2242 53.44 21.6189 54.8548 21.6189 56.5348ZM32.0797 9.08001V50.3726H4.79452L4.79452 9.08001H32.0797ZM22.665 3.80212C23.3624 3.80212 23.8854 4.33265 23.8854 5.04001C23.8854 5.74738 23.3624 6.27791 22.665 6.27791H14.2092C13.5118 6.27791 12.9888 5.74738 12.9888 5.04001C12.9888 4.33265 13.5118 3.80212 14.2092 3.80212H22.665Z"
        fill="inherit"
      />
      <path
        d="M52.2329 22.2526C52.6688 22.6947 53.1918 22.8716 53.7149 22.8716C54.2379 22.8716 54.8481 22.6947 55.1968 22.2526C56.0685 21.3684 56.0685 20.0421 55.1968 19.1579L53.3662 17.3011C59.0324 17.92 63.5654 22.7832 63.5654 28.7074C63.5654 29.9453 64.5243 30.9179 65.7447 30.9179C66.9652 30.9179 67.9241 29.9453 67.9241 28.7074C67.9241 20.3074 61.3861 13.4105 53.279 12.88L55.3711 10.7579C56.2429 9.8737 56.2429 8.54738 55.3711 7.66317C54.4994 6.77896 53.1918 6.77896 52.3201 7.66317L46.6538 13.4105C45.7821 14.2948 45.7821 15.6211 46.6538 16.5053L52.2329 22.2526Z"
        fill="inherit"
      />
    </svg>
  );
}
export default PhoneTurnModalIcon;
