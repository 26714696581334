import React from 'react';

function SwitchIcon({ style, enabled = false }) {
  return enabled ? (
    <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <rect width="40" height="24" rx="12" fill="#5AA122" />
      <rect x="20" y="4" width="16" height="16" rx="8" fill="#FDF6EE" />
    </svg>
  ) : (
    <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <rect width="40" height="24" rx="12" fill="#C6A788" />
      <rect x="4" y="4" width="16" height="16" rx="8" fill="#FDF6EE" />
    </svg>
  );
}

export default SwitchIcon;
