import RootStore from '../../store';
import { LTD_STATUS } from '../constants';

export const checkIfHaveLtdWithCurrentPlayer = () => {
  const { appStore } = RootStore;

  const deals = appStore.longTermsDeals?.ltd_info ?? [];

  for (const deal of deals) {
    const haveDeal = [deal.initiator, deal.partner].includes(appStore.currentPlayer?.uuid);
    const isDealActive = deal.status === LTD_STATUS.ACTIONS_PHASE;

    if (haveDeal && isDealActive) {
      return true;
    }
  }

  return false;
};
