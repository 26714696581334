import React from 'react';
import './style.scss';
import classNames from '../../../common/classNames';

function TextButton({ title, color, style, customClass, onClick, isDisabled = false }) {
  // color принимает только purple blue green red yellow
  return (
    <div
      className={classNames({
        text_button: true,
        'text_button--disabled': isDisabled,
        [`text_button--color-${color}`]: color,
        [`${customClass}`]: !!customClass,
      })}
      style={style}
      onClick={onClick}
    >
      {title}
    </div>
  );
}

export default TextButton;
