import React from 'react';

function SupplyIcon({ style }) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g filter="url(#filter0_di_30_78)">
        <rect width="16" height="16" rx="8" fill="#ADF512" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.51785 5.17119C6.60322 4.86546 6.87735 4.66571 7.16941 4.6964L9.37104 4.9278C9.65752 4.95792 9.88185 5.20161 9.90845 5.51161L10.0158 6.76228C10.0347 6.98206 10.215 7.1407 10.4185 7.11663C10.622 7.09256 10.7717 6.89488 10.7529 6.6751L10.6455 5.42443C10.5866 4.73756 10.0895 4.19762 9.45479 4.1309L7.25317 3.8995C6.60605 3.83149 5.99867 4.27408 5.80951 4.95147L5.43305 6.29959C5.37369 6.5122 5.48413 6.73373 5.67973 6.7944C5.87533 6.85508 6.08203 6.73191 6.14139 6.51931L6.51785 5.17119ZM9.72369 6.86025L9.64394 5.93114L6.7011 5.62184L6.4088 6.66859C6.30347 7.04579 5.93676 7.2643 5.58972 7.15666C5.24268 7.04902 5.04674 6.65597 5.15207 6.27877L5.36714 5.5086C4.62702 5.5826 4.0407 6.29151 4.0928 7.12609L4.29695 10.3964C4.34195 11.1173 4.85749 11.6918 5.52208 11.7616L9.7485 12.2058C10.4313 12.2776 11.0679 11.796 11.244 11.0744L12.0167 7.90676C12.2206 7.07101 11.7363 6.24597 10.9777 6.0794L11.0314 6.70559C11.0649 7.09551 10.7993 7.44623 10.4382 7.48894C10.077 7.53165 9.75715 7.25018 9.72369 6.86025Z"
        fill="#649105"
      />
      <defs>
        <filter
          id="filter0_di_30_78"
          x="0"
          y="0"
          width="17"
          height="17"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_30_78" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_30_78" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_30_78" />
        </filter>
      </defs>
    </svg>
  );
}

export default SupplyIcon;
