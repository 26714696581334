import React from 'react';

function ExpectationIcon({ style, stylePath, className }) {
  return (
    <svg
      className={className}
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M66.6669 71.2498H63.3331V61.0217C63.3331 55.9342 61.0236 51.0659 56.9897 47.6658L51.3464 42.9062C50.4565 42.1532 49.9664 41.1221 49.9664 40C49.9664 38.8779 50.4565 37.8468 51.3464 37.0966L56.9897 32.3376C61.0236 28.9313 63.3331 24.0658 63.3331 18.9783V8.75019H66.6669C68.5101 8.75019 70 7.35001 70 5.62481C70 3.90018 68.5101 2.5 66.6669 2.5H13.3331C11.4899 2.5 10 3.90018 10 5.62481C10 7.35001 11.4899 8.75019 13.3331 8.75019H16.6669V18.9783C16.6669 24.0658 18.9764 28.9341 23.0103 32.3342L28.6536 37.0938C29.5435 37.8468 30.0336 38.8779 30.0336 40C30.0336 41.1221 29.5435 42.1532 28.6536 42.9034L23.0103 47.6624C18.9764 51.0687 16.6669 55.9342 16.6669 61.0217V71.2498H13.3331C11.4899 71.2498 10 72.65 10 74.3752C10 76.0998 11.4899 77.5 13.3331 77.5H66.6669C68.5101 77.5 70 76.0998 70 74.3752C70 72.65 68.5101 71.2498 66.6669 71.2498ZM26.6669 17.3436C26.6669 16.0498 27.7869 14.9998 29.1669 14.9998H50.8331C52.2131 14.9998 53.3331 16.0498 53.3331 17.3436C53.3331 20.4873 52.0264 23.4438 49.6533 25.6686L41.767 33.0626C41.2799 33.5221 40.6403 33.7498 40 33.7498C39.3597 33.7498 38.7201 33.5221 38.233 33.0626L30.3467 25.6686C27.9736 23.4438 26.6669 20.4873 26.6669 17.3436ZM26.6669 69.9343C26.6669 67.6375 27.6202 65.4814 29.3536 63.8564L38.233 55.5313C39.2102 54.6158 40.7935 54.6158 41.77 55.5313L50.65 63.8564C52.3834 65.4814 53.3368 67.6375 53.3368 69.9343V71.2498H26.6669V69.9343Z"
        fill="#C6A788"
        style={stylePath}
      />
    </svg>
  );
}

export default ExpectationIcon;
