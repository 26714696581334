import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import QuestionIcon from '../icons/modal/QuestionIcon';

function ExactlyEndTurnModal() {
  const { appStore } = RootStore;

  if (!appStore.exactlyEndTurnFlag) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.exactlyEndTurnFlag}
      shouldCloseOnOverlayClick={false}
      customClass="end-turn-confirm"
      title={
        <div>
          точно хочешь
          <br />
          завершить сезон?
        </div>
      }
    >
      <QuestionIcon className="modal__body-icon" />
      <div className="modal__body-actions">
        <TextButton color="green" title="завершить" onClick={appStore.acceptEndTurn} />
        <TextButton color="purple" title="отмена" onClick={appStore.exactlyCancelEndTurnFlag} />
      </div>
    </Modal>
  );
}

export default observer(ExactlyEndTurnModal);
