import React, { useMemo } from 'react';
import city_trade_img from '../../../assets/basic/trade/elements/city_trade_img.png';
import RootStore from '../../../store/index';
import { DropContainer } from '../DropContainer';
import Cat, { CAT_STATE } from '../../../ui/components/Cat/Cat';
import classNames from '../../../common/classNames';
import PreloaderTrade from '../../../ui/components/PreloaderTrade/PreloaderTrade';
import trainingStore from '../../../store/trainingStore';
import { DRAG_AREA, PLAYER_ROLE, SPECIALITIES } from '../../../common/constants';
import { observer } from 'mobx-react';

const CatMemo = React.memo(Cat, (prevProps, nextProps) => {
  return _.isEqual(
    _.pick(prevProps, ['cat', 'notShowCount', 'notShowPrices']),
    _.pick(nextProps, ['cat', 'notShowCount', 'notShowPrices']),
  );
});

function CityField() {
  const { appStore } = RootStore;
  if (appStore.me.role === PLAYER_ROLE.NURSERY) {
    return <div className="lot-area lot-area--city lot-area--city-disallow" />;
  }

  let notShowPrices = false;
  let notShowCount = false;

  if (appStore.withHideInformationMechanics) {
    notShowCount = appStore.withHideInformation;
    notShowPrices = appStore.withHideInformation;
  } else if (appStore.isTeamMode && !appStore.hasSpecialities([SPECIALITIES.CAPTAIN, SPECIALITIES.MARKETEER])) {
    notShowCount = true;
  }

  const groupedCats = RootStore.appStore.sortedCityQuota;
  const isAllowTrading = appStore.isAllowTrading;

  const content = useMemo(() => {
    return (
      <>
        <div className="lot-area__info">
          <span style={{ backgroundImage: `url(${city_trade_img})` }} className="lot-area__image" />
          <p className="lot-area__info__city-text">ГОРОД</p>
        </div>
        <div className="lot-area__cats">
          {groupedCats.map((item, index) => (
            <CatMemo
              from={DRAG_AREA.CITY}
              dragAround={true}
              key={index}
              cat={item}
              notShowCount={notShowCount}
              notShowPrices={notShowPrices}
              state={CAT_STATE.DEFAULT}
              onDoubleClick={() => {
                appStore.buyFromCity(item);
              }}
              readonly={appStore.meIsCEO || appStore.isCurrentTurnPreliminary}
            />
          ))}
        </div>
      </>
    );
  }, [groupedCats, notShowPrices, notShowCount]);

  return (
    <DropContainer
      type={DRAG_AREA.CITY}
      element={({ canDrop, drop }) => (
        <div
          className={classNames({
            'lot-area': true,
            'lot-area--city': true,
            'lot-area--pending': !isAllowTrading && !trainingStore.launched,
          })}
          ref={drop}
          style={
            canDrop
              ? {
                  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #abc1e2',
                  boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.1)',
                }
              : {}
          }
        >
          {content}
          {!isAllowTrading && <PreloaderTrade />}
        </div>
      )}
    />
  );
}

export default observer(CityField);
