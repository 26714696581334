import React from 'react';

function OrientationModalIcon({ style, stylePath, className }) {
  return (
    <svg
      className={className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.9751 22.0698C33.3359 21.9429 32.6758 21.875 32 21.875C26.417 21.875 21.875 26.417 21.875 32C21.875 37.583 26.417 42.125 32 42.125C37.583 42.125 42.125 37.583 42.125 32C42.125 31.3242 42.0571 30.6641 41.9302 30.0249L35.9775 35.9775C34.915 37.04 33.5024 37.625 32 37.625C30.4976 37.625 29.085 37.04 28.0225 35.9775C26.96 34.915 26.375 33.5024 26.375 32C26.375 30.4976 26.96 29.085 28.0225 28.0225L33.9751 22.0698Z"
        fill="#C6A788"
      />
      <path
        d="M61.1362 18.7734L57.1021 22.8076C56.6982 23.2114 56.2432 23.5464 55.752 23.8062C56.6406 26.376 57.125 29.1323 57.125 32C57.125 45.854 45.854 57.125 32 57.125C18.146 57.125 6.875 45.854 6.875 32C6.875 18.146 18.146 6.875 32 6.875C34.8687 6.875 37.6265 7.35986 40.1968 8.24902C40.457 7.75732 40.7896 7.30029 41.1929 6.89746L45.2266 2.86377C41.1929 1.02539 36.7144 0 32 0C14.355 0 0 14.355 0 32C0 49.645 14.355 64 32 64C49.645 64 64 49.645 64 32C64 27.2856 62.9746 22.8071 61.1362 18.7734Z"
        fill="#C6A788"
      />
      <path
        d="M39.9639 16.0811L39.6221 12.0303C37.2534 11.1226 34.6841 10.625 32 10.625C20.2139 10.625 10.625 20.2139 10.625 32C10.625 43.7861 20.2139 53.375 32 53.375C43.7861 53.375 53.375 43.7861 53.375 32C53.375 29.3159 52.8774 26.7466 51.9697 24.3779L47.9189 24.0361L44.9458 27.0088C45.5454 28.5586 45.875 30.2412 45.875 32C45.875 39.6509 39.6509 45.875 32 45.875C24.3491 45.875 18.125 39.6509 18.125 32C18.125 24.3491 24.3491 18.125 32 18.125C33.7588 18.125 35.4414 18.4546 36.9912 19.0542L39.9639 16.0811Z"
        fill="#C6A788"
      />
      <path
        d="M43.3013 11.0327L43.8472 17.5014L30.6743 30.6743C29.9419 31.4062 29.9419 32.5937 30.6743 33.3256C31.04 33.6918 31.52 33.875 32 33.875C32.48 33.875 32.96 33.6918 33.3257 33.3256L46.4985 20.1528L52.9673 20.6982C53.02 20.7026 53.0723 20.705 53.125 20.705C53.6201 20.705 54.0977 20.5087 54.4507 20.1557L62.4053 12.2006C62.9214 11.685 63.0913 10.9165 62.8408 10.2309C62.5898 9.54536 61.9644 9.06782 61.2373 9.00679L55.4785 8.52095L54.9932 2.76265C54.9316 2.03511 54.4541 1.40962 53.769 1.15913C53.0835 0.908153 52.3149 1.07856 51.7988 1.59419L43.8442 9.54927C43.4536 9.93989 43.2549 10.4824 43.3013 11.0327Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default OrientationModalIcon;
