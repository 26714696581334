import Modal from '../../Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import { TRANSFER_TYPE_NAME } from '../../../../store/constants';
import corpStore from '../../../../store/CorpStore';
import TextButton from '../../../buttons/TextButton/TextButton';
import { CatFaceIcon } from '../../../icons';

function NotEnoughSeasonForTransfer() {
  const { notEnoughSeasonForTransferModalData } = corpStore;

  if (!notEnoughSeasonForTransferModalData) {
    return null;
  }

  return (
    <Modal
      colorBg="red"
      isOpen={!!notEnoughSeasonForTransferModalData}
      onClose={() => corpStore.hideNotEnoughSeasonForTransferModal()}
      title={`${TRANSFER_TYPE_NAME[notEnoughSeasonForTransferModalData]} не доступен на текущий сезон`}
    >
      <CatFaceIcon className="modal__body-icon" />
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={() => corpStore.hideNotEnoughSeasonForTransferModal()} />
      </div>
    </Modal>
  );
}

export default observer(NotEnoughSeasonForTransfer);
