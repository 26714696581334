import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import Cat, { BOTTOM_TEXT, CAT_STATE } from './Cat/Cat';
import TextButton from '../buttons/TextButton/TextButton';
import CoinModalIcon from '../icons/modal/CoinModalIcon';

function BuyFromCityModal() {
  const { appStore } = RootStore;

  if (!appStore.buyFromCityCat) {
    return null;
  }

  return (
    <Modal isOpen={!!appStore.buyFromCityCat} onClose={appStore.hideBuyFromCity} title="Ты купил у города">
      <div className="modal__body-wrapper">
        {appStore.buyFromCityCat && (
          <Cat cat={appStore.buyFromCityCat} readonly state={CAT_STATE.SINGLE} bottomText={BOTTOM_TEXT.KIND} />
        )}
        <div className="modal__body-price">
          <p className="modal__body-price-text">цена покупки</p>
          <p className="modal__body-price-coin">
            <CoinModalIcon className="modal__body-price-coin-icon" />
            <span className="modal__body-price-coin-count notranslate">
              {appStore.buyFromCityCat && appStore.buyFromCityCat.price}
            </span>
          </p>
        </div>
      </div>
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.hideBuyFromCity} />
      </div>
    </Modal>
  );
}

export default observer(BuyFromCityModal);
