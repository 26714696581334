import React from 'react';
import CreditLogo from '../Credit/CreditLogo';
import './rightPanel.scss';
import NotificationBoard from '../NotificationBoard/NotificationBoard';
import MyTasksLogo from '../MyTasksModal/MyTasksLogo';

function RightPanel() {
  return (
    <div className="right-panel">
      <div className="right-panel__icons">
        <MyTasksLogo />
        <CreditLogo />
      </div>
      <div className="right-panel__notifications">
        <NotificationBoard />
      </div>
    </div>
  );
}

export default RightPanel;
