import React from 'react';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import WalletIcon from '../../icons/modal/WalletIcon';
import Modal from '../Modal/Modal';
import TextButton from '../../buttons/TextButton/TextButton';
import routes from '../../../routes';
import { LTD_NOTIFICATION, ROLE_NAME } from '../../../store/constants';

function LTDCancelModal() {
  const { appStore } = RootStore;

  const LTDCancelModalData = appStore.LTDCancelModalData;

  if (!LTDCancelModalData) {
    return null;
  }

  let desc;

  if (appStore.meIsCEO) {
    desc = `СЕО ${LTDCancelModalData.partnerInfo.name} город ${appStore.ltdAreaNumById(LTDCancelModalData.deal_id)}`;
  } else {
    desc = `С ${ROLE_NAME[LTDCancelModalData.partnerInfo.role]}ом ${LTDCancelModalData.partnerInfo.name}`;
  }

  return (
    <Modal
      isOpen={!!LTDCancelModalData}
      customClass="ltd--modal-cancel"
      onClose={appStore.hideLTDModalCancel}
      title={
        <>
          хочешь{' '}
          {LTDCancelModalData.notification === LTD_NOTIFICATION.REJECTION_LTD
            ? 'отказаться от предложения'
            : 'отменить'}
          <br />
          {LTDCancelModalData.notification === LTD_NOTIFICATION.REJECTION_LTD
            ? 'долгосрочной сделки'
            : 'долгосрочную сделку'}
          ?
        </>
      }
      desc={desc}
    >
      <WalletIcon stylePath={{ fill: '#C6A788' }} className="modal__body-icon" />
      <div className="modal__body-actions">
        <TextButton
          color={LTDCancelModalData.notification === LTD_NOTIFICATION.REJECTION_LTD ? 'red' : 'green'}
          title={LTDCancelModalData.notification === LTD_NOTIFICATION.REJECTION_LTD ? 'Отказать' : 'Подтвердить'}
          onClick={() => {
            appStore.LTDCancelRequest({
              deal_id: LTDCancelModalData.deal_id,
              notification: LTDCancelModalData.notification,
            });
            appStore.hideLTDModalCancel();
            RootStore.goTo(routes.main);
          }}
        />
        <TextButton
          color="blue"
          title="отмена"
          onClick={() => {
            appStore.hideLTDModalCancel();
          }}
        />
      </div>
    </Modal>
  );
}

export default observer(LTDCancelModal);
