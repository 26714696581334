import React from 'react';

function HouseModalIcon({ style, stylePath, className }) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <g clipPath="url(#houseModal0)">
        <path
          d="M0.61835 28.1018C1.88605 30.3253 4.75287 31.1512 7.01362 29.8456L40.0001 10.0824L72.9866 29.8456C75.0801 31.045 78.1538 30.2545 79.3812 28.1018C80.6587 25.8631 79.9006 23.003 77.6375 21.7066L42.3231 0.613404C41.623 0.210571 40.8192 0 40.0001 0C39.1804 0 38.3772 0.210571 37.6747 0.613404L2.36274 21.7066C0.0995504 23.003 -0.658507 25.8631 0.61835 28.1018Z"
          fill="#C6A788"
          style={stylePath}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.3125 35.0776C76.2355 52.1502 76.251 63.6008 75.4518 77.5222C75.3768 78.83 74.2987 79.8545 72.9888 79.8683L61.0944 79.994H23.5943H7.03245C5.73789 79.994 4.68869 78.9448 4.68869 77.6509C3.74937 62.3438 3.74983 51.0938 4.68869 35.1545L4.69785 35.1557C6.32993 35.1557 7.93882 34.7236 9.43113 33.863L40.0006 15.5524L70.6568 33.913C72.0075 34.6863 73.573 35.094 75.1868 35.094C75.2085 35.094 75.2294 35.0899 75.2504 35.0858C75.2708 35.0819 75.2912 35.0779 75.3125 35.0776ZM25.5329 53.9212C27.9322 51.7347 51.9141 51.9027 54.3126 53.9212C55.8588 55.2224 55 80 55 80H25C25 80 24.3335 55.0143 25.5329 53.9212ZM39.7023 31.0615C39.7023 32.3937 38.6189 33.4771 37.2867 33.4771C35.9545 33.4771 34.8711 32.3937 34.8711 31.0615C34.8711 29.9822 35.7174 27.3438 37.2867 27.3438C38.8561 27.3438 39.7023 29.9822 39.7023 31.0615ZM33.9241 37.9195C35.0758 37.2542 35.4781 35.7798 34.8081 34.62C34.2679 33.6852 32.1741 31.8251 30.817 32.6078C29.4583 33.3933 30.0848 36.1014 30.6238 37.0356C31.2893 38.1866 32.7618 38.59 33.9241 37.9195ZM45.7411 31.0615C45.7411 32.3937 44.6577 33.4771 43.3255 33.4771C41.9933 33.4771 40.9099 32.3937 40.9099 31.0615C40.9099 29.9822 41.7562 27.3438 43.3255 27.3438C44.8949 27.3438 45.7411 29.9822 45.7411 31.0615ZM46.6772 37.8978C47.8403 38.5688 49.3535 38.162 50.017 37.0131C50.6525 35.9129 51.044 33.3136 49.7841 32.5853C48.4223 31.8011 46.3321 33.6628 45.793 34.5975C45.1259 35.7525 45.5202 37.2289 46.6772 37.8978ZM36.079 38.0525V37.7045C36.079 34.685 39.2606 34.685 40.3062 34.685C41.3519 34.685 44.5335 34.685 44.5335 37.7045V38.0525C44.5335 38.2996 44.6809 38.5196 44.9099 38.6122C46.1488 39.1164 46.9491 40.1827 46.9491 41.3279C46.9491 42.9928 45.3238 44.3473 43.3257 44.3473C42.9264 44.3473 42.5743 44.3072 42.2494 44.224C40.9768 43.9032 39.6339 43.9015 38.3672 44.2236C38.0393 44.3066 37.6861 44.3473 37.2868 44.3473C35.2887 44.3473 33.6634 42.9927 33.6634 41.3279C33.6634 40.1827 34.4637 39.1162 35.7026 38.6127C35.9314 38.5196 36.079 38.2996 36.079 38.0525Z"
          fill="#C6A788"
          style={stylePath}
        />
      </g>
      <defs>
        <clipPath id="houseModal0">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HouseModalIcon;
