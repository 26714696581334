import React from 'react';
import LTDStepsApiParams from '../base/LTDStepsApiParams';
import LTDChapter from '../base/LTDChapter';
import LTDStep from '../base/LTDStep';
import {
  CAT_COLOR,
  CAT_GENDER,
  CUSTOM_ACTION_NAMES,
  EDITABLE_FIELD,
  LTD_CONTENT_TYPE,
  LTD_FINES_PAYMENT,
  TIMER_ID,
} from '../../../../common/constants';
import { INFO_MODAL_POSITION } from '../../base/constants';
import RootStore from '../../../index';
import checkObjectParams from '../../../../common/checkObjectParams';
import { LTD_TERMINATION, RECEIVE_MESSAGE_TYPE } from '../../../constants';
import { LocalStorage } from '../../../../common/localStorage';
import api from '../../../../api';
import generateLTDChapterName from '../base/methods/generateLTDChapterName';

const steps = () => [
  new LTDStep({
    selectedLTDTab: LTD_CONTENT_TYPE.SELL,
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Расторжение сделки</div>,
    message: (
      <p className="training-modal__desc">
        Расторжение долгосрочной сделки возможно в 3 случаях: одностороннее расторжение при оплате зафиксированной в
        сделке суммы, расторжение по соглашению сторон, расторжение вследствие назначения штрафа, включая автоматическое
        расторжение в конце сезона
      </p>
    ),
    buttonText: 'Дальше',
    nextButton: true,
    elements: ['.ltd__terminate'],
    disabledElements: ['.ltd__terminate'],
    position: INFO_MODAL_POSITION.TOP,
    selectorIntoView: '.ltd__terminate',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Расторжение сделки</div>,
    message: (
      <p className="training-modal__desc">
        Если в условиях расторжения при формировании сделки вы поставите «без условий расторжения», то одностороннего
        расторжения не будет
      </p>
    ),
    buttonText: 'Дальше',
    nextButton: true,
    elements: ['.ltd__terminate'],
    disabledElements: ['.ltd__terminate'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_SELECT_OPEN,
    checkParams([selectId]) {
      return selectId === 'one_way_termination__payment_type';
    },
    title: <div>Расторжение сделки</div>,
    message: (
      <p className="training-modal__desc">
        В условиях одностороннего расторжения можно выбрать фиксированную выплату или процент от общей суммы сделки.
        Раскройте выпадающий список
      </p>
    ),
    elements: ['.ltd__terminate'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: 'changeLTDTermination',
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams() {
      return checkObjectParams({
        object: RootStore.appStore.currentLTD,
        params: {
          [`${EDITABLE_FIELD.ONE_WAY_TERMINATION}.${EDITABLE_FIELD.PAYMENT_TYPE}`]: LTD_FINES_PAYMENT.FIXED,
          [`${EDITABLE_FIELD.ONE_WAY_TERMINATION}.${EDITABLE_FIELD.VALUE}`]: 20,
        },
      });
    },
    removeFocusFromElements: true,
    title: <div>Расторжение сделки</div>,
    message: <p className="training-modal__desc">выбираем фиксированную сумму и указываем 20 монет</p>,
    elements: ['.ltd__terminate'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: RECEIVE_MESSAGE_TYPE.LTD_UPDATE,
    blockedActionsNames: ['setLTDNotificationsModal'],
    title: <div>Отправка долгосрочной сделки</div>,
    message: (
      <p className="training-modal__desc">
        Отлично, теперь мы заполнили все поля и можем отправлять условия нашей долгосрочной сделки контрагенту
      </p>
    ),
    elements: ['.ltd__accept'],
    position: INFO_MODAL_POSITION.TOP,
    after() {
      RootStore.appStore.tutorialProgress.chapter++;
    },
  }),
];

const chapter5 = () =>
  new LTDChapter({
    title: 'Условия расторжения',
    chapter: generateLTDChapterName(5),
    stepsApiParams: LTDStepsApiParams,
    defaultStepSettings: {
      hiddenTimersIds: [TIMER_ID.HEADER.SESSION_TIMER],
    },
    onChapterInitializationActionsNames: ['setLTDNotificationsModal'],
    callback() {
      RootStore.appStore.hideIntroductionModal();
      RootStore.appStore.forceSetCurrentLTD({
        initiator: LocalStorage.ORIGINAL_TRAINING_USER_UID,
        partner: '00000000-0000-0000-0000-000000000021',
        type_ltd: LTD_CONTENT_TYPE.BUY_SELL,
        status: null,
        updater: null,
        contents: [
          {
            buyer: LocalStorage.ORIGINAL_TRAINING_USER_UID,
            seller: '00000000-0000-0000-0000-000000000021',
            prices_isDefaultValue: false,
            area_num: null,
            shipping_season: 2,
            storage_timer: 10,
            shipping_timer: 5,
            abstract_contents: [
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.WHITE,
                price: 10,
                count: 2,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.WHITE,
                price: 11,
                count: 2,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
            ],
            prepaid: {
              season: 1,
              money: 12,
              timer: 15,
            },
            payment_before_shipment: {
              season: 2,
              money: 0,
              timer: 8,
            },
            payment_after_shipment: {
              season: 2,
              money: 30,
              timer: 3,
            },
            shipping_season_isDefaultValue: true,
          },
          {
            seller: LocalStorage.ORIGINAL_TRAINING_USER_UID,
            buyer: '00000000-0000-0000-0000-000000000021',
            prices_isDefaultValue: true,
            shipping_season: 6,
            storage_timer: 15,
            shipping_timer: 10,
            abstract_contents: [
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.WHITE,
                price: 8,
                count: 8,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.WHITE,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
            ],
            prepaid: {
              season: 6,
              money: 19,
              timer: 20,
            },
            payment_before_shipment: {
              season: 6,
              money: 38,
              timer: 13,
            },
            payment_after_shipment: {
              season: 6,
              money: 7,
              timer: 5,
            },
          },
        ],
        overdue_payment_liability: {
          payment_type: LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
          value: 120,
        },
        overdue_delivery_liability: {
          payment_type: LTD_FINES_PAYMENT.PCT_OF_MISSED_CATS,
          value: 120,
        },
        low_quality_liability: {
          payment_type: LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
          value: 80,
        },
        initiator_bank_guarantee: {
          amount: 30,
          last_payment_season: 2,
          isPeriodicPayment: false,
        },
        partner_bank_guarantee: {
          amount: 30,
          last_payment_season: 2,
          isPeriodicPayment: false,
        },
        offer_bank_guarantee_amount: {
          amount: 0,
        },
        one_way_termination: {
          payment_type: LTD_TERMINATION.EMPTY,
          value: 0,
        },
        two_way_termination: {
          payment_type: LTD_TERMINATION.EMPTY,
          value: 0,
        },
      });
    },
    userIdentifierForSocketInitialization: LocalStorage.ORIGINAL_TRAINING_USER_UID,
    initialRouteParams: {
      routeName: 'longTermsDealsNew',
      params: {
        type_ltd: LTD_CONTENT_TYPE.BUY_SELL,
        uuid: '00000000-0000-0000-0000-000000000021',
      },
    },
    beforeStepsInitializationStep: new LTDStep({
      title: <div>Ожидание старта главы</div>,
      message: <p className="training-modal__desc">Ожидание...</p>,
      blockedActionsNames: ['setLTDNotificationsModal'],
    }),
    seamlessStartOfNextChapter: true,
    stepsGeneratorInfo: {
      generateOnChapterInitialization: false,
      generator: steps,
    },
  });

export default chapter5;
