import React from 'react';

function CatPawUnionIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10.2117 7.18292C10.2117 8.04296 9.60599 8.7405 8.85916 8.7405C8.11253 8.7405 7.50684 8.04296 7.50684 7.18292C7.50684 6.3225 8.11253 5.625 8.85916 5.625C9.60599 5.625 10.2117 6.32252 10.2117 7.18292ZM6.60726 9.93546C7.18062 9.71936 7.43527 9.00872 7.17631 8.34887C6.91718 7.68916 6.2425 7.32927 5.66978 7.54571C5.09688 7.76179 4.8426 8.47229 5.10108 9.13228C5.36033 9.79179 6.03506 10.1519 6.60726 9.93546ZM11.7604 10.1195C11.3073 9.42612 10.5255 9.00674 9.68602 9.00674C8.84666 9.00674 8.06482 9.4261 7.61177 10.1195L6.36451 12.028C6.02293 12.5507 6.07063 13.2313 6.48169 13.7032C6.8933 14.1752 7.57127 14.3272 8.15106 14.0772C9.129 13.6556 10.2428 13.6553 11.221 14.0772C11.8005 14.3272 12.4785 14.1753 12.8901 13.7034C13.3015 13.2314 13.3492 12.5507 13.0076 12.028L11.7604 10.1195ZM11.3808 9.20345C12.0895 9.43445 12.8887 8.95977 13.1653 8.14361C13.4421 7.32732 13.0913 6.47798 12.383 6.24699C11.6743 6.016 10.8751 6.49066 10.5988 7.30697C10.3222 8.12327 10.6725 8.97246 11.3808 9.20345ZM12.9224 9.3498C13.3515 8.78134 14.0991 8.61126 14.5931 8.96921C15.0859 9.3272 15.1386 10.0786 14.7101 10.6472C14.2808 11.2155 13.5323 11.3858 13.0395 11.0276C12.5465 10.6697 12.4939 9.91843 12.9224 9.3498Z"
        fill="#EDB260"
      />
    </svg>
  );
}

export default CatPawUnionIcon;
