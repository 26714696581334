import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';

function TechnicalWorkInProgressModal() {
  const { appStore } = RootStore;

  if (!appStore.technicalWorkInProgressModalFlag) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.technicalWorkInProgressModalFlag}
      shouldCloseOnOverlayClick={false}
      colorBg="red"
      title="Ведутся технические работы"
      desc="Пожалуйста, ожидайте и не перезагружайте страницу! Предполагаемое время - 5-10 минут"
    >
      <div className="modal__body-actions">
        <TextButton title="Переподключиться" color="purple" onClick={() => location.reload()} />
      </div>
    </Modal>
  );
}

export default observer(TechnicalWorkInProgressModal);
