import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarCoffeeImage from '../../../assets/shopAvatars/7.png';

function AvatarCoffee({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="#FDF6EE" stroke="#02516C" strokeWidth="3" />
    //   <g clipPath="url(#avatarCoffee)">
    //     <path
    //       d="M15.7842 11.0957C15.5996 11.2805 15.4346 11.4788 15.2637 11.6732C16.9743 12.0481 18.6584 12.9256 20.1566 14.1798C21.0003 12.0787 22.6531 10.4718 24.8353 9.72911L26.2142 9.25966C23.6346 7.05977 19.0895 7.78989 15.7842 11.0957Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M27.0651 10.208L25.213 10.8386C23.2204 11.5171 21.7382 13.0354 21.0889 15.009C22.7369 16.6751 23.8609 18.6885 24.3084 20.7524C24.5091 20.5766 24.7133 20.406 24.904 20.2155C28.0386 17.0805 28.8574 12.8452 27.0651 10.208Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M20.6016 25.3685C19.8504 23.0825 17.9183 21.4272 15.559 20.9606C12.819 20.4167 10.6275 18.4365 9.72746 15.7921L9.25804 14.4131C7.05816 16.9927 7.78882 21.5999 11.0941 24.9057C13.1516 26.9632 15.7153 27.9985 17.9807 27.9985C19.1636 27.9985 20.2579 27.6897 21.1631 27.0768L20.6016 25.3685Z"
    //       fill="#02516C"
    //     />
    //     <path
    //       d="M20.2141 15.7857C17.0795 12.6512 12.8438 11.7694 10.2065 13.562L10.8371 15.4142C11.6031 17.6635 13.4563 19.3478 15.7869 19.8104C18.5607 20.3594 20.8329 22.3147 21.7157 25.002L22.1181 26.2262C24.3209 23.6472 23.5205 19.0927 20.2141 15.7857Z"
    //       fill="#02516C"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="avatarCoffee">
    //       <rect width="20" height="20" fill="white" transform="translate(8 8)" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <Avatar style={style} avatarImage={AvatarCoffeeImage} />
  );
}

export default AvatarCoffee;
