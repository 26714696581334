import React from 'react';

function ParametersIcon({ style, width = '40' }) {
  return (
    <svg width="40" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.4846 19.3746L27.2779 18.4494C27.2932 18.3067 27.3063 18.14 27.3063 17.9635C27.3063 17.787 27.2937 17.6203 27.2779 17.4776L28.4857 16.5519C28.71 16.3781 28.7712 16.0697 28.6285 15.8128L27.3736 13.6841C27.2396 13.4438 26.9459 13.3205 26.6477 13.4283L25.2239 13.9887C24.9509 13.7957 24.6648 13.6326 24.3689 13.5023L24.1523 12.0215C24.1167 11.7404 23.8673 11.5281 23.5725 11.5281H21.0563C20.7614 11.5281 20.5126 11.7404 20.4775 12.0172L20.2604 13.5034C19.9737 13.63 19.6921 13.7909 19.4071 13.9898L17.9794 13.4278C17.7119 13.3264 17.3919 13.4401 17.2589 13.6793L16.0025 15.8107C15.8543 16.0563 15.9155 16.3754 16.1447 16.5535L17.3514 17.4787C17.3323 17.6594 17.323 17.8166 17.323 17.9641C17.323 18.1116 17.3323 18.2687 17.3514 18.45L16.1436 19.3757C15.9193 19.55 15.8586 19.8584 16.0014 20.1147L17.2562 22.2434C17.3903 22.4832 17.6813 22.6076 17.9821 22.4993L19.406 21.9388C19.6784 22.1313 19.9644 22.2944 20.2604 22.4252L20.477 23.9055C20.5125 24.1876 20.7614 24.4 21.0568 24.4H23.573C23.8679 24.4 24.1173 24.1876 24.1523 23.9109L24.3695 22.4252C24.6561 22.2981 24.9373 22.1378 25.2228 21.9382L26.6505 22.5003C26.7194 22.5266 26.7911 22.54 26.8649 22.54C27.0771 22.54 27.2724 22.4263 27.3709 22.2493L28.6312 20.1093C28.7712 19.8583 28.7099 19.55 28.4846 19.3746ZM22.3144 20.1093C21.1077 20.1093 20.1264 19.1472 20.1264 17.964C20.1264 16.7809 21.1077 15.8187 22.3144 15.8187C23.5211 15.8187 24.5024 16.7809 24.5024 17.964C24.5024 19.1472 23.5211 20.1093 22.3144 20.1093Z"
        fill="#C6A788"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2115 22.4556C16.1168 22.3997 16.0378 22.3192 15.9829 22.2211L14.614 19.8989C14.4583 19.6192 14.5245 19.2828 14.7692 19.0926L16.0867 18.0828C16.0659 17.885 16.0557 17.7136 16.0557 17.5527C16.0557 17.3918 16.0659 17.2203 16.0867 17.0232L14.7704 16.0139C14.5203 15.8196 14.4535 15.4715 14.6152 15.2035L15.9859 12.8784C16.1309 12.6174 16.48 12.4934 16.7718 12.604L18.3293 13.2171C18.6402 13.0001 18.9475 12.8246 19.2602 12.6865L19.4971 11.0652C19.5353 10.7633 19.8068 10.5316 20.1284 10.5316H22.8734C22.9897 10.5316 23.0995 10.5619 23.1942 10.6151C22.6497 4.66183 17.6765 0 11.6218 0C5.20326 0 0 5.23906 0 11.7018C0 18.1645 5.20326 23.4035 11.6218 23.4035C13.2518 23.4035 14.8035 23.0656 16.2115 22.4556ZM2.40488 12.1038C2.40488 12.9952 3.12257 13.7178 4.00789 13.7178H11.9766C11.992 13.7183 12.0075 13.7185 12.023 13.7185C12.9083 13.7185 13.626 12.9958 13.626 12.1044L13.626 4.03426C13.626 3.14285 12.9083 2.42022 12.023 2.42022C11.1377 2.42022 10.42 3.14285 10.42 4.03426V10.4897H4.00789C3.12258 10.4897 2.40488 11.2124 2.40488 12.1038Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default ParametersIcon;
