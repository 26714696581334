import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import catAgeCalculation from '../../../common/catAgeCalculation';

function CatDescription({ cat }) {
  const { appStore } = RootStore;
  const currentCat = cat.detail ? cat.detail : cat;
  let description = appStore.currentSeason - currentCat.season_birthdate + ` сезон`;

  if (appStore.isRealTimeMode) {
    description = catAgeCalculation({
      cat: currentCat,
      timerInSeconds: appStore.timerValue.$ms / 1000,
      startSessionInSeconds: appStore.sessionStartTime,
      monthDuration: appStore.monthDuration,
    });
  }

  return <div className="cat__description cat__description--season">{description}</div>;
}

export default observer(CatDescription);
