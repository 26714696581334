import React from 'react';

function WalletTitleIcon({ style, fill = '#C6A788', className }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M19.2188 4.92188C19.8659 4.92188 20.3906 4.39719 20.3906 3.75V1.17188C20.3906 0.524687 19.8659 0 19.2188 0C18.5716 0 18.0469 0.524687 18.0469 1.17188V3.75C18.0469 4.39719 18.5716 4.92188 19.2188 4.92188Z"
        fill={fill}
      />
      <path
        d="M11.2126 5.34268L13.1875 6.99987C13.6865 7.41854 14.4251 7.34799 14.8385 6.85541C15.2545 6.35963 15.1899 5.62049 14.694 5.20448L12.7191 3.54729C12.2232 3.13119 11.4841 3.19596 11.0682 3.69174C10.6521 4.18745 10.7167 4.92666 11.2126 5.34268Z"
        fill={fill}
      />
      <path
        d="M25.2503 6.99983L27.2253 5.34264C27.7211 4.92663 27.7857 4.18741 27.3697 3.69171C26.9538 3.19593 26.2145 3.13124 25.7188 3.54726L23.7439 5.20444C23.248 5.62046 23.1834 6.35967 23.5994 6.85538C24.0142 7.34967 24.7532 7.41702 25.2503 6.99983Z"
        fill={fill}
      />
      <path
        d="M8.75 30.5001C8.75 30.224 8.97386 30.0001 9.25 30.0001H13.25C13.5261 30.0001 13.75 30.224 13.75 30.5001V32C13.75 32.2761 13.5261 32.5 13.25 32.5H9.25C8.97386 32.5 8.75 32.2761 8.75 32V30.5001Z"
        fill={fill}
      />
      <path
        d="M29.8436 32.2654C27.6701 31.6133 25.4994 28.4065 25.4994 28.4065C25.0417 27.9489 25.0417 27.2069 25.4994 26.7493C25.4994 26.7493 27.6571 23.4974 29.8436 22.8904C30.9647 22.5792 34.2768 22.6872 36.8748 22.8904V19.4529C36.8748 17.5144 35.2977 15.9373 33.3592 15.9373H4.25764C3.32827 15.9373 2.2273 15.9373 1.71858 15.2236C0.625055 19.3749 0.00011456 27.4999 1.71858 34.6873C2.25877 36.9466 3.8452 38.9954 6.40608 39.3748C10.6251 39.9999 22.8325 40.252 33.3592 39.3748C35.0002 39.2381 36.8748 37.7611 36.8748 35.8831V32.2654C34.1419 32.4412 30.6251 32.4999 29.8436 32.2654ZM15.7811 33.4373C15.7811 34.0845 15.2564 34.6092 14.6092 34.6092H7.57795C6.93077 34.6092 6.40608 34.0845 6.40608 33.4373V28.7498C6.40608 28.1026 6.93077 27.5779 7.57795 27.5779H14.6092C15.2564 27.5779 15.7811 28.1026 15.7811 28.7498V33.4373Z"
        fill={fill}
      />
      <path
        d="M29.8437 25.25C29.4995 25.5253 28.397 26.6601 27.8355 27.2437C27.6391 27.4478 27.6142 27.7599 27.7832 27.9874C28.27 28.6429 29.2686 29.8915 29.8437 29.9375C30.5907 29.9972 34.5076 30.3602 37.5408 29.9928C37.789 29.9627 37.9881 29.7754 38.0358 29.53C38.296 28.1907 38.3185 27.2342 38.032 25.6648C37.9866 25.4158 37.787 25.2252 37.5352 25.1994C35.4447 24.9848 30.2797 24.9013 29.8437 25.25Z"
        fill={fill}
      />
      <path
        d="M4.17969 14.2187H34.4531V12.6562C34.4531 10.7146 32.8446 9.50521 30.9375 9.14062C25.625 8.125 8.75 8.12502 4.17969 9.14062C2.81079 9.44481 1.64062 10.2774 1.64062 11.6797C1.64062 13.0819 2.77742 14.2187 4.17969 14.2187Z"
        fill={fill}
      />
    </svg>
  );
}

export default WalletTitleIcon;
