import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarCat from '../../../assets/shopAvatars/6.png';

function AvatarCar({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="#FDF6EE" stroke="#02516C" strokeWidth="3" />
    //   <g clipPath="url(#avatarCar)">
    //     <path
    //       d="M26.4925 15.7078L25.0898 11.0322C24.77 9.96594 23.8075 9.25 22.6948 9.25H13.3052C12.1925 9.25 11.23 9.96594 10.9102 11.0322L9.50749 15.7078C8.62179 16.0927 8 16.9741 8 18V23C8 23.6903 8.55969 24.25 9.25 24.25H9.25008L9.25 25.4999C9.25 26.1903 9.80962 26.75 10.5 26.75H11.7501C12.4404 26.75 13.0001 26.1903 13.0001 25.5L12.9995 24.25H23L22.9999 25.4999C22.9999 26.1903 23.5595 26.75 24.2499 26.75H25.5C26.1904 26.75 26.75 26.1903 26.75 25.5V24.2501L26.7495 24.25H26.75C27.4403 24.25 28 23.6903 28 23V18C28 16.9741 27.3782 16.0926 26.4925 15.7077L26.4925 15.7078ZM13.3051 11.75H22.6948L23.8198 15.5H12.1801L13.3051 11.75H13.3051ZM13 21.125C12.3096 21.125 11.75 20.5653 11.75 19.875C11.75 19.1846 12.3096 18.625 13 18.625C13.6903 18.625 14.25 19.1846 14.25 19.875C14.25 20.5653 13.6903 21.125 13 21.125ZM23 21.125C22.3096 21.125 21.75 20.5653 21.75 19.875C21.75 19.1846 22.3096 18.625 23 18.625C23.6903 18.625 24.25 19.1846 24.25 19.875C24.25 20.5653 23.6903 21.125 23 21.125Z"
    //       fill="#02516C"
    //     />
    //   </g>
    //   <defs>
    //     <clipPath id="avatarCar">
    //       <rect width="20" height="20" fill="white" transform="translate(8 8)" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <Avatar style={style} avatarImage={AvatarCat} />
  );
}

export default AvatarCar;
