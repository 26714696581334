import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import CatFaceIcon from '../icons/CatFaceIcon';

function PullingCatOutHouseModal() {
  const { appStore } = RootStore;

  if (!appStore.pullingCatOutHouseFlag) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.pullingCatOutHouseFlag}
      customClass="cat-out-house-confirm"
      onClose={() => appStore.pullingCatOutHouseClose()}
      title={
        <div>
          хочешь вытащить
          <br />
          котика из дома?
        </div>
      }
    >
      <CatFaceIcon className="modal__body-icon" fill="#c6a788" />
      <div className="modal__body-actions">
        <TextButton color="green" title="Подтвердить" onClick={() => appStore.pullingCatOutHouseAccept()} />
        <TextButton color="purple" title="отмена" onClick={() => appStore.pullingCatOutHouseClose()} />
      </div>
    </Modal>
  );
}

export default observer(PullingCatOutHouseModal);
