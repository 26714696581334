import SliderComponent from '../../slider/SliderComponent';
import React from 'react';
import { observer } from 'mobx-react';
import corpStore from '../../../store/CorpStore';

function CompaniesSlider({ position = '', areaNum }) {
  if (!areaNum) {
    return;
  }
  const companies = corpStore.myActiveCompanies;
  const labels = companies.map((company) => `Город ${company.areaNum}`);

  return (
    <SliderComponent
      sliderCustomClass="lot-area__players-slider"
      sliderItemCustomClass="lot-area__players-slider-item"
      labels={labels}
      afterChange={(current) => {
        corpStore.changeAreaNumField(position, companies[current].areaNum);
        corpStore.clearContentOperationField();
      }}
      icon={(option) => {
        const Img = companies[option].logo;
        return {
          img: <Img />,
        };
      }}
      currentSlideIndex={companies.indexOf(corpStore.currentCompanyByAreaNum(areaNum))}
    />
  );
}

export default observer(CompaniesSlider);
