import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import Cat, { BOTTOM_TEXT, CAT_STATE } from './Cat/Cat';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import TextButton from '../buttons/TextButton/TextButton';

function SellToCityModal() {
  const { appStore } = RootStore;

  if (!appStore.sellToCityCat) {
    return null;
  }

  return (
    <Modal isOpen={!!appStore.sellToCityCat} onClose={appStore.hideSellToCity} title="Ты продал городу">
      <div className="modal__body-wrapper">
        {appStore.sellToCityCat && (
          <Cat cat={appStore.sellToCityCat} readonly state={CAT_STATE.SINGLE} bottomText={BOTTOM_TEXT.KIND} />
        )}
        <div className="modal__body-price">
          <p className="modal__body-price-text">цена продажи</p>
          <p className="modal__body-price-coin">
            <CoinModalIcon className="modal__body-price-coin-icon" />
            <span className="modal__body-price-coin-count notranslate">
              {appStore.sellToCityCat && appStore.sellToCityCat.price}
            </span>
          </p>
        </div>
      </div>
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.hideSellToCity} />
      </div>
    </Modal>
  );
}

export default observer(SellToCityModal);
