import React from 'react';

function TeamCaptainIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="FDF6EE" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9812 9.11374L22.1178 19.9354C22.0115 20.5506 21.4766 21 20.8509 21H3.1491C2.52338 21 1.98852 20.5506 1.88223 19.9354L0.0187957 9.11374C-0.0634904 8.64041 0.128511 8.16024 0.514227 7.87146C0.899943 7.58267 1.41594 7.53483 1.84966 7.74501L6.81083 10.1527L10.8771 2.86132C11.3297 2.04623 12.672 2.04623 13.1246 2.86132L17.1909 10.1527L22.152 7.74501C22.5858 7.53483 23.1001 7.58267 23.4875 7.87146C23.8715 8.16024 24.0635 8.64041 23.9812 9.11374Z"
        fill="inherit"
      />
    </svg>
  );
}

export default TeamCaptainIcon;
