const currentEvn = location.href.includes('hr.alabuga.ru')
  ? 'prod'
  : location.href.includes('pre-prod.cats.alabuga.space')
  ? 'stage'
  : 'dev';

const _PLATFORM_URLS = {
  prod: {
    CLIENT: process.env.PROD_CLIENT_URL,
    HR: process.env.PROD_HR_URL,
    AUTH: process.env.PROD_AUTH_URL,
  },
  stage: {
    CLIENT: process.env.STAGE_CLIENT_URL,
    HR: process.env.STAGE_HR_URL,
    AUTH: process.env.STAGE_AUTH_URL,
  },
  dev: {
    CLIENT: process.env.DEV_CLIENT_URL,
    HR: process.env.DEV_HR_URL,
    AUTH: process.env.DEV_AUTH_URL,
  },
};

export const PLATFORM_URLS = _PLATFORM_URLS[currentEvn];
