import checkLTDAction from './checkLTDAction';

const checkLTDActions = ({ actions, neededActionType, neededStatus, neededSentLiabilities }) => {
  return !!actions.find((action) =>
    checkLTDAction({
      action,
      neededActionType,
      neededStatus,
      neededSentLiabilities,
    }),
  );
};

export default checkLTDActions;
