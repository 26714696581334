import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarCrownImage from '../../../assets/nurseryAvatars/avatarCrown.png';

function AvatarCrown({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <path
    //     d="M27.2131 15.502C27.2161 15.5571 27.2121 15.6131 27.1983 15.6687L25.9752 20.8798C25.913 21.1284 25.6902 21.3032 25.4341 21.3045L18.0322 21.3419H18.0294H10.6274C10.3699 21.3419 10.1455 21.1665 10.0833 20.9166L8.8391 15.6868C8.8249 15.6297 8.82078 15.5722 8.82437 15.5157C8.34714 15.3654 8 14.9188 8 14.3925C8 13.7431 8.52822 13.2149 9.17757 13.2149C9.82692 13.2149 10.3551 13.7431 10.3551 14.3925C10.3551 14.7582 10.1876 15.0854 9.92523 15.3015L11.4698 16.8577C11.8601 17.251 12.4018 17.4765 12.956 17.4765C13.6112 17.4765 14.236 17.1654 14.6294 16.644L17.1677 13.2807C16.9545 13.0676 16.8224 12.7732 16.8224 12.4486C16.8224 11.7992 17.3507 11.271 18 11.271C18.6493 11.271 19.1776 11.7992 19.1776 12.4486C19.1776 12.7635 19.0526 13.0493 18.8505 13.2608L18.8526 13.2634L21.3726 16.636C21.7658 17.1624 22.3928 17.4766 23.0499 17.4766C23.6092 17.4766 24.1351 17.2588 24.5305 16.8633L26.0848 15.3091C25.8169 15.093 25.6449 14.7627 25.6449 14.3925C25.6449 13.7431 26.1731 13.2149 26.8224 13.2149C27.4718 13.2149 28 13.7431 28 14.3925C28 14.9047 27.6707 15.3404 27.2131 15.502ZM25.8443 22.8224C25.8443 22.5127 25.5932 22.2617 25.2835 22.2617H10.7788C10.4692 22.2617 10.2181 22.5127 10.2181 22.8224V24.9392C10.2181 25.2489 10.4692 25.5 10.7788 25.5H25.2835C25.5932 25.5 25.8443 25.2489 25.8443 24.9392V22.8224Z"
    //     fill="#02516C"
    //   />
    // </svg>
    <Avatar style={style} avatarImage={AvatarCrownImage} />
  );
}

export default AvatarCrown;
