import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';

function CatsAwayModal() {
  const { appStore } = RootStore;

  if (!appStore.catAwayModalShow) {
    return null;
  }

  return (
    <Modal
      isOpen={!!appStore.catAwayModalShow}
      onClose={appStore.hideCatAwayModal}
      title="У тебя сбежали котики"
      style={{ zIndex: '96' }}
    >
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.hideCatAwayModal} />
      </div>
    </Modal>
  );
}

export default observer(CatsAwayModal);
