import React from 'react';

function HouseIcon({ style }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M14.8121 7.31855C15.084 7.00966 15.0577 6.53621 14.7534 6.26135L8.04976 0.202419C7.74548 -0.0724363 7.25764 -0.0666786 6.95977 0.215399L0.233292 6.58439C-0.064582 6.86647 -0.0792019 7.33931 0.200923 7.64007L0.369566 7.82149C0.649349 8.12226 1.10159 8.15812 1.37902 7.90134L1.88172 7.43633V14.2442C1.88172 14.6619 2.20938 15 2.61345 15H5.23545C5.63953 15 5.96718 14.6619 5.96718 14.2442V9.48143H9.31157V14.2442C9.30575 14.6616 9.59487 14.9997 9.99895 14.9997H12.7776C13.1817 14.9997 13.5093 14.6616 13.5093 14.2439V7.53219C13.5093 7.53219 13.6482 7.65785 13.8195 7.81336C13.9905 7.96857 14.3496 7.84412 14.6215 7.53492L14.8121 7.31855Z"
        fill="inherit"
      />
    </svg>
  );
}

export default HouseIcon;
