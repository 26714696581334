import _ from 'lodash';
import RootStore from '../store';
import { PLAYER_ROLE, SICKNESS } from './constants';

const sortCatContents = (cat_contents) => {
  const { appStore } = RootStore;
  const catBirthDateKey = appStore.isRealTimeMode ? 'created_at' : 'season_birthdate';
  const iterates = [catBirthDateKey, (c) => c.sickness !== SICKNESS.NONE];
  if (appStore.me.role === PLAYER_ROLE.NURSERY) {
    iterates.push((cat) => !appStore.catsInHouseBigWindows[cat.cat_id]);
  }
  const orders = appStore.me.role === PLAYER_ROLE.NURSERY ? ['desc', 'asc', 'desc'] : ['desc', 'asc'];

  return _.orderBy(cat_contents, iterates, orders);
};

export default sortCatContents;
