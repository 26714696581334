import React from 'react';
import Modal from './Modal/Modal';
import { observer } from 'mobx-react';
import TextButton from '../buttons/TextButton/TextButton';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import { TRANSFER_STATUS, TRANSFER_TYPE } from '../../store/constants';
import corpStore from '../../store/CorpStore';
import RootStore from '../../store';
import Cat, { BOTTOM_TEXT, CAT_STATE } from './Cat/Cat';
import { SPECIALITIES } from '../../common/constants';

export const TITLES_FOR_CEO = {
  [TRANSFER_TYPE.IMPORT]: 'Импорт одобрен',
  [TRANSFER_TYPE.EXPORT]: 'Экспорт одобрен',
};

export const DESCRIPTIONS_FOR_CEO = {
  [TRANSFER_TYPE.IMPORT]: 'Котики импортированы в город',
  [TRANSFER_TYPE.EXPORT]: 'Котики экспортированы из города',
};

export const TITLES_FOR_CAPTAIN = {
  [TRANSFER_TYPE.IMPORT]: 'СЕО импортировал вам котиков',
  [TRANSFER_TYPE.EXPORT]: 'СЕО экспортировал ваших котиков',
};

const ImportExportNotificationModal = () => {
  const { appStore } = RootStore;
  const data = corpStore.importExportNotificationModalData;

  if (!data) {
    return;
  }

  const areaNum = corpStore.areaNumByCaptainId(data.captainId);

  let title;
  let description;
  let content;
  let modalColor;
  let priceVisibleFlag = false;

  if (
    appStore.meIsCEO ||
    (appStore.hasSpecialities(SPECIALITIES.CAPTAIN) &&
      data.type === TRANSFER_TYPE.IMPORT &&
      data.status === TRANSFER_STATUS.AWAITING_PAYMENT)
  ) {
    modalColor = 'green';
  }

  if (appStore.meIsCEO) {
    title = TITLES_FOR_CEO[data.type];
    description = `${DESCRIPTIONS_FOR_CEO[data.type]} ${areaNum}`;
    priceVisibleFlag =
      (data.type === TRANSFER_TYPE.IMPORT && data.status === TRANSFER_STATUS.AWAITING_DELIVERY) ||
      (data.type === TRANSFER_TYPE.EXPORT && data.status === TRANSFER_STATUS.AWAITING_PAYMENT);
  } else if (appStore.hasSpecialities(SPECIALITIES.CAPTAIN)) {
    title = TITLES_FOR_CAPTAIN[data.type];
    if (data.type === TRANSFER_TYPE.IMPORT) {
      modalColor = 'green';
    }
  }

  content = (
    <div className="modal__body-wrapper">
      <div className="modal__body-cats">
        {data.contents.map((cat) => {
          return (
            <Cat
              key={cat.gender + cat.color}
              readonly={true}
              cat={cat}
              bottomText={BOTTOM_TEXT.KIND}
              state={CAT_STATE.SINGLE}
              realCount={true}
              customCount={cat.count > 1 ? cat.count : 0}
            />
          );
        })}
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={!!data}
      onClose={() => corpStore.hideImportExportNotificationModal()}
      title={title}
      desc={description}
      colorBg={modalColor}
    >
      {content}
      {priceVisibleFlag && (
        <div className="modal__body-price">
          <p className="modal__body-price-text">Аванс</p>
          <p className="modal__body-price-coin">
            <CoinModalIcon className="modal__body-price-coin-icon" />
            <span className="modal__body-price-coin-count">{data.price}</span>
          </p>
        </div>
      )}
      <div className="modal__body-actions">
        <TextButton title="понятно" color="blue" onClick={() => corpStore.hideImportExportNotificationModal()} />
      </div>
    </Modal>
  );
};

export default observer(ImportExportNotificationModal);
