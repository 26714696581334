import React from 'react';

function TeamReworkIcon({}) {
  return (
    <svg width="24" height="24" viewBox="0 0 14 14" fill="#F29406" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.7972 5.28175C7.79471 3.34312 7.77281 1.92338 7.74025 1.87007C7.5918 1.62634 7.09024 1.75072 6.12683 2.27028C3.28044 3.80524 0 6.3412 0 7.00654C0 7.44519 1.07304 8.44453 3.05633 9.85277C4.74833 11.0542 7.06839 12.3179 7.45226 12.2472C7.71375 12.199 7.76324 11.9629 7.78546 8.74924C8.19973 9.08133 8.69215 9.45036 9.25887 9.85277C10.9509 11.0542 13.2709 12.3179 13.6548 12.2472C13.9556 12.1918 13.9758 11.8877 13.9968 7.09179C14.0092 4.28885 13.9849 1.93895 13.9428 1.87007C13.7943 1.62634 13.2928 1.75072 12.3294 2.27028C10.738 3.12848 9.0108 4.2996 7.7972 5.28175Z"
        fill="inherit"
      />
    </svg>
  );
}

export default TeamReworkIcon;
