import React from 'react';
import CoinModalIcon from '../../icons/modal/CoinModalIcon';
import DividingArrow from '../../icons/modal/DividingArrow';
import { observer } from 'mobx-react';
import '../EarlyRepaymentModal.scss';

const EarlyRepaymentComponent = ({ repaymentAmount, prePaymentAmount }) => {
  return (
    <div className="modal__body-repayment__wrapper">
      <div className="modal__body-repayment__wrapper-item">
        <p className="modal__body-price-text">Осталось выплатить всего</p>
        <p className="modal__body-price-coin">
          <CoinModalIcon className="modal__body-price-coin-icon" />
          <span className="modal__body-price-coin-count notranslate"> {repaymentAmount} </span>
        </p>
      </div>
      <DividingArrow />
      <div className="modal__body-repayment__wrapper-item">
        <p className="modal__body-price-text">При досрочном погашении</p>
        <p className="modal__body-price-coin">
          <CoinModalIcon className="modal__body-price-coin-icon" />
          <span className="modal__body-price-coin-count notranslate"> {prePaymentAmount} </span>
        </p>
      </div>
    </div>
  );
};

export default observer(EarlyRepaymentComponent);
