import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { CatFaceIcon, TeamBuyerIcon, TeamDoctorIcon, TeamMarketIcon, TeamSellerIcon } from '../../../../../ui/icons';
import SightConfirmation from './SightConfirmation';
import RootStore from '../../../../../store';
import { LTD_DOCTOR_REQUEST_STATUSES } from '../../../../../store/constants';
import {
  LTD_ACTIONS,
  PLAYER_ROLE,
  PRIVILEGES,
  REQUEST_TYPE,
  ROOT_PATHS,
  SPECIALITIES,
} from '../../../../../common/constants';

function LotPanelSighting() {
  const { appStore } = RootStore;

  if (!appStore.isTeamMode && !appStore.meIsCEO) {
    return (
      <div className="lot-panel__sighting">
        <div key={'CatFaceIcons'} className="lot-panel__cat-icons">
          <CatFaceIcon style={{ fill: '#EEDBC7', marginRight: '10px', width: '2.4vh', height: '2.4vh' }} />{' '}
          <CatFaceIcon style={{ fill: '#EEDBC7', marginRight: '10px', width: '3.2vh', height: '3.2vh' }} />{' '}
          <CatFaceIcon style={{ fill: '#EEDBC7', width: '2.4vh', height: '2.4vh' }} />
        </div>
      </div>
    );
  }
  const task = appStore.taskById(appStore.currentPlayer.lot?.task_id);
  const isNotMarketeerNotDoctor = appStore.hasSpecialities([
    SPECIALITIES.SUPPLY,
    SPECIALITIES.SALES,
    SPECIALITIES.CAPTAIN,
    SPECIALITIES.CEO,
  ]);

  let sightConfirmations = [];
  const visas = task?.visas;
  if (task && (isNotMarketeerNotDoctor || appStore.withPrivilegesChange)) {
    const specialitiesWithoutCaptain = { ...SPECIALITIES };
    delete specialitiesWithoutCaptain.CAPTAIN;
    sightConfirmations = Object.values(specialitiesWithoutCaptain).map((speciality, index) => {
      const isActive = true;
      const isConfirmed = visas && visas[speciality];
      if (speciality !== SPECIALITIES.DOCTOR && speciality !== SPECIALITIES.CEO) {
        if (!visas?.hasOwnProperty(speciality)) {
          return null;
        }
        return (
          <SightConfirmation key={index} isActive={isActive} confirmed={isConfirmed}>
            {speciality === SPECIALITIES.MARKETEER && <TeamMarketIcon isActive={isActive} />}
            {speciality === SPECIALITIES.SUPPLY && <TeamBuyerIcon isActive={isActive} />}
            {speciality === SPECIALITIES.SALES && <TeamSellerIcon isActive={isActive} />}
          </SightConfirmation>
        );
      } else {
        return null;
      }
    });
  }

  sightConfirmations = _.compact(sightConfirmations);

  if (!sightConfirmations.length) {
    sightConfirmations.push(
      <div key={'CatFaceIcons'} className="lot-panel__cat-icons">
        <CatFaceIcon style={{ fill: '#EEDBC7', marginRight: '10px', width: '2.4vh', height: '2.4vh' }} />{' '}
        <CatFaceIcon style={{ fill: '#EEDBC7', marginRight: '10px', width: '3.2vh', height: '3.2vh' }} />{' '}
        <CatFaceIcon style={{ fill: '#EEDBC7', width: '2.4vh', height: '2.4vh' }} />
      </div>,
    );
  }

  const meIsNurseryOnToStorageAction =
    appStore.me.role === PLAYER_ROLE.NURSERY &&
    appStore.me.actionLTD?.actionDetail.action_type === LTD_ACTIONS.TO_STORAGE;
  const LTDHasLowQualityLiability =
    appStore.me.actionLTD?.ltd_info.low_quality_liability &&
    (appStore.me.actionLTD?.actionDetail.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT ||
      appStore.me.actionLTD?.actionDetail.action_type === LTD_ACTIONS.TO_STORAGE);
  // Возвращается true только при соблюдении всех условий:
  // 1. Это корпоративный режим и я сео или это командная игра и я капитан
  // 2. Если сейчас отгрузка на склад и я питомник
  // 3. Присутствуют статусы отправки доктору котиков на осмотр в контенте долгосрочной сделки
  // 4. Если сейчас осмотр склада и в сделке указаны штрафы за качество, либо сейчас просто отгрузка на склад
  const needDoctorForCurrentLTDInCorpMode = (() => {
    const hasNeededSpeciality = appStore.isCorpMode
      ? appStore.meIsCEO ||
        (appStore.hasSpecialities(SPECIALITIES.CAPTAIN) &&
          appStore.myPrivileges.find((priv) => priv === PRIVILEGES.CREATE_LTD_TASK) === PRIVILEGES.CREATE_LTD_TASK)
      : appStore.isRealTeamMode
      ? appStore.hasSpecialities(SPECIALITIES.CAPTAIN) ||
        ((appStore.hasSpecialities(SPECIALITIES.SUPPLY) || appStore.hasSpecialities(SPECIALITIES.SALES)) &&
          appStore.myPrivileges.find((privilege) => privilege === PRIVILEGES.APPROVE_LTD_TASK))
      : false;
    return hasNeededSpeciality && appStore.statusOfDoctorLTDStorageReviewingRequest;
  })();

  const needDoctorForCurrentTask =
    appStore.currentPlayer.lot && isNotMarketeerNotDoctor && appStore.needDoctorForCurrentTask;
  const isMarketeerWithPrivilegesChange =
    appStore.currentPlayer.lot &&
    appStore.withPrivilegesChange &&
    appStore.me.role === PLAYER_ROLE.SHOP &&
    task &&
    task.content.request_type === REQUEST_TYPE.BUY &&
    task.message_owner === appStore.me.uuid;

  if (needDoctorForCurrentTask || needDoctorForCurrentLTDInCorpMode || isMarketeerWithPrivilegesChange) {
    const doctorAlreadyRequest = task?.visible_to.find((spec) => spec === SPECIALITIES.DOCTOR);
    const doctorVisa = visas && visas[SPECIALITIES.DOCTOR];

    let isActive;
    let isPending;
    let confirmed;
    if (RootStore.router.currentRoute.rootPath === ROOT_PATHS.STORAGE) {
      const statusOfDoctorLTDStorageReviewingRequest = appStore.statusOfDoctorLTDStorageReviewingRequest;
      isActive =
        statusOfDoctorLTDStorageReviewingRequest === LTD_DOCTOR_REQUEST_STATUSES.APPROVED ||
        statusOfDoctorLTDStorageReviewingRequest === LTD_DOCTOR_REQUEST_STATUSES.DECLINED;
      isPending = statusOfDoctorLTDStorageReviewingRequest === LTD_DOCTOR_REQUEST_STATUSES.REQUESTED;
      confirmed = statusOfDoctorLTDStorageReviewingRequest === LTD_DOCTOR_REQUEST_STATUSES.APPROVED;
    } else {
      isActive = doctorVisa !== undefined;
      confirmed = doctorVisa;
      isPending = doctorAlreadyRequest && task.on_approve_by_doctor && doctorVisa === undefined;
    }

    sightConfirmations.push(
      <SightConfirmation key={'doctorVisa'} isActive={isActive} confirmed={confirmed}>
        <TeamDoctorIcon isActive={isActive} isPending={isPending} width="15" height="15" />
      </SightConfirmation>,
    );
  }

  return <div className="lot-panel__sighting">{sightConfirmations} </div>;
}

export default observer(LotPanelSighting);
