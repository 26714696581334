import classNames from '../../common/classNames';
import React from 'react';

export const RadioButton = ({ value, onClick, disabled }) => {
  return (
    <div
      className={classNames({
        radio: true,
        'radio--active-border': !!value,
        disabled: disabled,
      })}
      onClick={onClick}
    >
      <div
        className={classNames({
          'radio--active': !!value,
        })}
      />
    </div>
  );
};
