import React from 'react';

function MinusModalIcon({ style, fill, stylePath, className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0.867656 8.72618L2.2845 8.45983C3.06376 8.31369 7.94338 8.22261 13.1286 8.25742C21.0148 8.31085 22.6198 8.42054 22.9481 8.92858C23.1639 9.26255 23.2939 10.8033 23.2364 12.3525L23.1323 15.1696L21.5131 15.5161C20.6225 15.7067 15.7429 15.8055 10.6694 15.7355C3.01236 15.6294 1.37812 15.5048 1.05225 15.0008C0.836078 14.6668 0.706128 13.1184 0.763612 11.5599L0.867656 8.72618Z"
        fill={fill ? fill : '#C6A788'}
      />
    </svg>
  );
}

export default MinusModalIcon;
