import React from 'react';
import classNames from '../../../../../common/classNames';
import './style.scss';

function SightConfirmation({ isActive = false, confirmed = false, children }) {
  return (
    <div
      className={classNames({
        'sight-confirmation': true,
        'sight-confirmation--disabled': !isActive,
        'sight-confirmation--confirmed': isActive && confirmed,
        'sight-confirmation--rejected': isActive && !confirmed,
      })}
    >
      {children}
    </div>
  );
}

export default SightConfirmation;
