import * as Sentry from '@sentry/react';

export function sentryInit() {
  const ThisIsProd = location.href.includes('hr.alabuga.ru');

  if (!ThisIsProd) {
    return;
  }

  Sentry.init({
    dsn: 'https://5cae41d861d5d10c757b407e16fb5300@sentry.hr.alabuga.ru/3',
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
  });
}
