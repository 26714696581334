import React, { useMemo } from 'react';
import RootStore from '../../store';
import routes from '../../routes';
import classNames from '../../common/classNames';
import { observer } from 'mobx-react';
import { PLAYER_ROLE, SPECIALITIES } from '../../common/constants';
import getStaticsForPlayers from '../../common/getStaticsForPlayers';

function Shops() {
  const { appStore } = RootStore;
  const shops = useMemo(
    () => getStaticsForPlayers({ players: appStore.shops }),
    [appStore.shops, appStore.currentSeasonType],
  );

  return (
    <div className="pet-shops-road">
      <div className="pet-shops-wrapper">
        {shops.map((player, index) => {
          return (
            <div
              key={player.uuid}
              className={classNames({
                'pet-shops-item-wrapper': true,
                [`pet-shops-item-wrapper--${index + 1}`]: true,
              })}
            >
              <span
                onClick={() => {
                  if (appStore.isActiveOnShopClick) {
                    RootStore.goTo(routes.inside, { uuid: player.uuid });
                  }
                }}
                className={classNames({
                  'pet-shops-item': true,
                  'pet-shops-item--bankrupt': !appStore.playerByUuid(player.uuid).active,
                  'pet-shops-item--own': appStore.me.uuid === player.uuid,
                  [`pet-shops-item--relation-${player.relation_to_me}`]:
                    appStore.me.role === PLAYER_ROLE.NURSERY &&
                    player.ai_type !== 'human' &&
                    (!appStore.isTeamMode ||
                      appStore.hasSpecialities([SPECIALITIES.CAPTAIN, SPECIALITIES.SUPPLY, SPECIALITIES.SALES])),
                })}
              >
                <img src={player.src} srcSet={player.srcSet} width={'80'} height={'100'} alt={player.name} />
                <span className="shop-font">{player.name}</span>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default observer(Shops);
