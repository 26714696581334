import { CatFaceIcon } from '../../../../../ui/icons';
import React from 'react';
import { observer } from 'mobx-react';

export function OperationPanelSighting() {
  return (
    <div key={'CatFaceIcons'} className="lot-panel__cat-icons">
      <CatFaceIcon style={{ fill: '#EEDBC7', marginRight: '10px', width: '2.4vh', height: '2.4vh' }} />{' '}
      <CatFaceIcon style={{ fill: '#EEDBC7', marginRight: '10px', width: '3.2vh', height: '3.2vh' }} />{' '}
      <CatFaceIcon style={{ fill: '#EEDBC7', width: '2.4vh', height: '2.4vh' }} />
    </div>
  );
}

export default observer(OperationPanelSighting);
