import React from 'react';
import LTDStepsApiParams from '../base/LTDStepsApiParams';
import LTDChapter from '../base/LTDChapter';
import LTDStep from '../base/LTDStep';
import {
  CAT_COLOR,
  CAT_GENDER,
  CUSTOM_ACTION_NAMES,
  EDITABLE_FIELD,
  LTD_CONTENT_TYPE,
  LTD_FINES_PAYMENT,
  TIMER_ID,
} from '../../../../common/constants';
import { INFO_MODAL_POSITION } from '../../base/constants';
import RootStore from '../../../index';
import generateLTDFineInputId from '../../../../common/LTD/generateLTDFineInputId';
import checkObjectParams from '../../../../common/checkObjectParams';
import { LTD_TERMINATION } from '../../../constants';
import { LocalStorage } from '../../../../common/localStorage';
import api from '../../../../api';
import generateLTDChapterName from '../base/methods/generateLTDChapterName';

const steps = () => [
  new LTDStep({
    selectedLTDTab: LTD_CONTENT_TYPE.SELL,
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Дополнительные возможности</div>,
    message: (
      <p className="training-modal__desc">
        Теперь перейдем к заполнению полей, которые являются Дополнительными и могут оставаться пустыми, но мы крайне не
        советуем вам так делать
      </p>
    ),
    elements: [
      '.ltd__payment-fine',
      '.ltd__terminate',
      '.ltd__quality-fine',
      '.ltd__bank',
      '.ltd__terminate',
      '.ltd__delivery-fine',
    ],
    disabledElements: [
      '.ltd__payment-fine',
      '.ltd__terminate',
      '.ltd__delivery-fine',
      '.ltd__quality-fine',
      '.ltd__bank',
      '.ltd__terminate',
    ],
    position: INFO_MODAL_POSITION.LEFT,
    nextButton: true,
    buttonText: 'Дальше',
    selectorIntoView: '.ltd__payment-fine',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Дополнительные возможности</div>,
    message: (
      <p className="training-modal__desc">
        Данные поля являются едиными как для покупки, так и для продажи, поэтому мы их можем заполнять в любой из форм.
        Дополнительные поля выделены фиолетовым цветом
      </p>
    ),
    elements: [
      '.ltd__payment-fine',
      '.ltd__terminate',
      '.ltd__quality-fine',
      '.ltd__bank',
      '.ltd__terminate',
      '.ltd__delivery-fine',
    ],
    disabledElements: [
      '.ltd__payment-fine',
      '.ltd__terminate',
      '.ltd__delivery-fine',
      '.ltd__quality-fine',
      '.ltd__bank',
      '.ltd__terminate',
    ],
    position: INFO_MODAL_POSITION.LEFT,
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Ответственность</div>,
    message: (
      <p className="training-modal__desc">
        Основания для претензий и штрафов включают в себя несколько видов: штраф за просрочку оплаты, штраф за
        недопоставку или просрочку поставки и штраф за качество. Заполним их для нашей сделки
      </p>
    ),
    elements: ['.ltd__payment-fine', '.ltd__quality-fine', '.ltd__delivery-fine'],
    disabledElements: ['.ltd__payment-fine', '.ltd__delivery-fine', '.ltd__quality-fine'],
    position: INFO_MODAL_POSITION.LEFT,
    nextButton: true,
    buttonText: 'Дальше',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Штраф за просрочку оплаты</div>,
    message: (
      <p className="training-modal__desc">
        Выставляется в том случае, если контрагент не успел в оговоренных временных рамках провести одну из оплат
      </p>
    ),
    elements: ['.ltd__payment-fine'],
    disabledElements: ['.ltd__payment-fine'],
    nextButton: true,
    buttonText: 'Дальше',
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Штраф за просрочку оплаты</div>,
    message: (
      <p className="training-modal__desc">
        Важно понимать, чем больше штраф за просрочку оплаты, тем меньше рисков у продавца.В случае, невыполнения
        условий и выставления штрафа, дальнейшая сделка в этой игре полностью разрывается
      </p>
    ),
    elements: ['.ltd__payment-fine'],
    disabledElements: ['.ltd__payment-fine'],
    nextButton: true,
    buttonText: 'Дальше',
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Штраф за просрочку оплаты</div>,
    message: (
      <p className="training-modal__desc">
        Если у вас многоступенчатый план, предполагающий много промежуточных точек, срыв любой из которых приведет к
        краху стратегии, и цена в сделке близка к рыночной, рационально использовать процент от суммы сделки
      </p>
    ),
    elements: ['.ltd__payment-fine'],
    disabledElements: ['.ltd__payment-fine'],
    nextButton: true,
    buttonText: 'Дальше',
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Штраф за просрочку оплаты</div>,
    message: (
      <p className="training-modal__desc">
        Если цена в сделке сильно ниже рыночной, рационально использовать фиксированный размер штрафа, потому что его
        максимальный размер рассчитывается от рыночных цен города на продажу, а не от суммы сделки как все остальные
        штрафы
      </p>
    ),
    elements: ['.ltd__payment-fine'],
    disabledElements: ['.ltd__payment-fine'],
    nextButton: true,
    buttonText: 'Дальше',
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_SELECT_OPEN,
    checkParams([selectId]) {
      return (
        selectId ===
        generateLTDFineInputId({
          fineType: EDITABLE_FIELD.OVERDUE_PAYMENT_LIABILITY,
          editableField: EDITABLE_FIELD.PAYMENT_TYPE,
        })
      );
    },
    title: <div>Штраф за просрочку оплаты</div>,
    message: (
      <p className="training-modal__desc">
        Если Вам кажется, что данная сделка не сильно повлияет на конечный результат, то легким решением будет поставить
        % от просроченной оплаты. Раскройте выпадающий список
      </p>
    ),
    elements: ['.ltd__payment-fine'],
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: 'changeLTDConditions',
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams() {
      return checkObjectParams({
        object: RootStore.appStore.currentLTD,
        params: {
          [`${EDITABLE_FIELD.OVERDUE_PAYMENT_LIABILITY}.${EDITABLE_FIELD.PAYMENT_TYPE}`]:
            LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
          [`${EDITABLE_FIELD.OVERDUE_PAYMENT_LIABILITY}.${EDITABLE_FIELD.VALUE}`]: 120,
        },
      });
    },
    removeFocusFromElements: true,
    title: <div>Штраф за просрочку оплаты</div>,
    message: (
      <p className="training-modal__desc">В рамках нашей сделки установим штраф от суммы сделки в размере 120%</p>
    ),
    elements: ['.ltd__payment-fine'],
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Штраф за просрочку поставки и недопоставку</div>,
    message: (
      <p className="training-modal__desc">
        Если контрагент не успел отправить в оговоренный срок котиков или доставил меньше котиков, чем должно быть, то
        ему может быть назначен данный штраф
      </p>
    ),
    elements: ['.ltd__delivery-fine'],
    disabledElements: ['.ltd__delivery-fine'],
    nextButton: true,
    buttonText: 'Дальше',
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_SELECT_OPEN,
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams([selectId]) {
      return (
        selectId ===
        generateLTDFineInputId({
          fineType: EDITABLE_FIELD.OVERDUE_DELIVERY_LIABILITY,
          editableField: EDITABLE_FIELD.PAYMENT_TYPE,
        })
      );
    },
    title: <div>Штраф за просрочку поставки и недопоставку</div>,
    message: (
      <p className="training-modal__desc">
        Данный штраф может быть установлен фиксированной суммой, процентом от суммы сделки или процентом от
        недопоставки. Раскройте выпадающий список
      </p>
    ),
    elements: ['.ltd__delivery-fine'],
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: 'changeLTDConditions',
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams() {
      return checkObjectParams({
        object: RootStore.appStore.currentLTD,
        params: {
          [`${EDITABLE_FIELD.OVERDUE_DELIVERY_LIABILITY}.${EDITABLE_FIELD.PAYMENT_TYPE}`]:
            LTD_FINES_PAYMENT.PCT_OF_MISSED_CATS,
          [`${EDITABLE_FIELD.OVERDUE_DELIVERY_LIABILITY}.${EDITABLE_FIELD.VALUE}`]: 120,
        },
      });
    },
    removeFocusFromElements: true,
    title: <div>Штраф за просрочку поставки и недопоставку</div>,
    message: (
      <p className="training-modal__desc">Укажем процент от недопоставленных котиков и установим размер равный 120%</p>
    ),
    elements: ['.ltd__delivery-fine'],
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Штраф за качество</div>,
    message: (
      <p className="training-modal__desc">
        Данный штраф предназначен для того, чтобы контрагент не отправлял нам больных котиков
      </p>
    ),
    elements: ['.ltd__quality-fine'],
    disabledElements: ['.ltd__quality-fine'],
    nextButton: true,
    buttonText: 'Дальше',
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_SELECT_OPEN,
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams([selectId]) {
      return (
        selectId ===
        generateLTDFineInputId({
          fineType: EDITABLE_FIELD.LOW_QUALITY_LIABILITY,
          editableField: EDITABLE_FIELD.PAYMENT_TYPE,
        })
      );
    },
    title: <div>Штраф за качество</div>,
    message: (
      <p className="training-modal__desc">
        Данный штраф может быть установлен фиксированной суммой, процентом от суммы сделки или процентом от суммы
        больных котиков. Раскройте выпадающий список
      </p>
    ),
    elements: ['.ltd__quality-fine'],
    position: INFO_MODAL_POSITION.LEFT,
  }),
  new LTDStep({
    actionName: 'changeLTDConditions',
    showingErrorModalIsNeeded: false,
    acceptActionsWithInvalidCheckParams: true,
    checkParams() {
      return checkObjectParams({
        object: RootStore.appStore.currentLTD,
        params: {
          [`${EDITABLE_FIELD.LOW_QUALITY_LIABILITY}.${EDITABLE_FIELD.PAYMENT_TYPE}`]:
            LTD_FINES_PAYMENT.PCT_OF_DEAL_AMOUNT,
          [`${EDITABLE_FIELD.LOW_QUALITY_LIABILITY}.${EDITABLE_FIELD.VALUE}`]: 80,
        },
      });
    },
    removeFocusFromElements: true,
    title: <div>Штраф за качество</div>,
    message: <p className="training-modal__desc">в качестве штрафа выберем процент от суммы сделки и укажем 80%</p>,
    elements: ['.ltd__quality-fine'],
    position: INFO_MODAL_POSITION.LEFT,
    after() {
      RootStore.appStore.tutorialProgress.chapter++;
    },
  }),
];

const chapter3 = () =>
  new LTDChapter({
    title: 'ответственность',
    chapter: generateLTDChapterName(3),
    stepsApiParams: LTDStepsApiParams,
    defaultStepSettings: {
      hiddenTimersIds: [TIMER_ID.HEADER.SESSION_TIMER],
    },
    callback() {
      RootStore.appStore.hideIntroductionModal();
      RootStore.appStore.forceSetCurrentLTD({
        initiator: LocalStorage.ORIGINAL_TRAINING_USER_UID,
        partner: '00000000-0000-0000-0000-000000000021',
        type_ltd: LTD_CONTENT_TYPE.BUY_SELL,
        status: null,
        updater: null,
        contents: [
          {
            buyer: LocalStorage.ORIGINAL_TRAINING_USER_UID,
            seller: '00000000-0000-0000-0000-000000000021',
            prices_isDefaultValue: false,
            area_num: null,
            shipping_season: 2,
            storage_timer: 10,
            shipping_timer: 5,
            abstract_contents: [
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.WHITE,
                price: 10,
                count: 2,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.WHITE,
                price: 11,
                count: 2,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
            ],
            prepaid: {
              season: 1,
              money: 12,
              timer: 15,
            },
            payment_before_shipment: {
              season: 2,
              money: 0,
              timer: 8,
            },
            payment_after_shipment: {
              season: 2,
              money: 30,
              timer: 3,
            },
            shipping_season_isDefaultValue: true,
          },
          {
            seller: LocalStorage.ORIGINAL_TRAINING_USER_UID,
            buyer: '00000000-0000-0000-0000-000000000021',
            prices_isDefaultValue: true,
            shipping_season: 6,
            storage_timer: 15,
            shipping_timer: 10,
            abstract_contents: [
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.BLACK,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GRAY,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.WHITE,
                price: 8,
                count: 8,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.WHITE,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.MALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
              {
                gender: CAT_GENDER.FEMALE,
                color: CAT_COLOR.GINGER,
                price: 0,
                count: 0,
              },
            ],
            prepaid: {
              season: 6,
              money: 19,
              timer: 20,
            },
            payment_before_shipment: {
              season: 6,
              money: 38,
              timer: 13,
            },
            payment_after_shipment: {
              season: 6,
              money: 7,
              timer: 5,
            },
          },
        ],
        overdue_payment_liability: {
          payment_type: LTD_FINES_PAYMENT.EMPTY,
          value: 0,
        },
        overdue_delivery_liability: {
          payment_type: LTD_FINES_PAYMENT.EMPTY,
          value: 0,
        },
        low_quality_liability: {
          payment_type: LTD_FINES_PAYMENT.EMPTY,
          value: 0,
        },
        initiator_bank_guarantee: {
          amount: 0,
          last_payment_season: 2,
          isPeriodicPayment: false,
        },
        partner_bank_guarantee: {
          amount: 0,
          last_payment_season: 2,
          isPeriodicPayment: false,
        },
        offer_bank_guarantee_amount: {
          amount: 0,
        },
        one_way_termination: {
          payment_type: LTD_TERMINATION.EMPTY,
          value: 0,
        },
        two_way_termination: {
          payment_type: LTD_TERMINATION.EMPTY,
          value: 0,
        },
      });
    },
    userIdentifierForSocketInitialization: LocalStorage.ORIGINAL_TRAINING_USER_UID,
    initialRouteParams: {
      routeName: 'longTermsDealsNew',
      params: {
        type_ltd: LTD_CONTENT_TYPE.BUY_SELL,
        uuid: '00000000-0000-0000-0000-000000000021',
      },
    },
    beforeStepsInitializationStep: new LTDStep({
      title: <div>Ожидание старта главы</div>,
      message: <p className="training-modal__desc">Ожидание...</p>,
    }),
    seamlessStartOfNextChapter: true,
    stepsGeneratorInfo: {
      generateOnChapterInitialization: false,
      generator: steps,
    },
  });

export default chapter3;
