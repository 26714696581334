import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import HouseModalIcon from '../icons/modal/HouseModalIcon';

function MissingHouseModal() {
  const { appStore } = RootStore;
  if (!appStore.missingHouseModalShowed) {
    return null;
  }
  return (
    <Modal
      isOpen={appStore.missingHouseModalShowed}
      onClose={appStore.missingHouseModalClose}
      title="Сначала нужно купить дом"
    >
      <HouseModalIcon className="modal__body-icon" />
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.missingHouseModalClose} />
      </div>
    </Modal>
  );
}

export default observer(MissingHouseModal);
