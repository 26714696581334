import React from 'react';

function SendIcon({ style }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#C0A88F" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M17.8391 2.73078C19.7049 2.21 21.3979 1.72742 21.601 1.6588C21.8041 1.5898 22.427 1.52043 22.9857 1.50448C23.9793 1.47592 24.0011 1.52118 24 3.60802C23.997 9.74243 21.7 22.0187 20.4659 22.4946C20.0129 22.6693 13.8903 18.5965 10.9205 16.1454L9.84082 15.254L14.613 10.1375C17.2379 7.32325 18.9701 5.29018 18.4624 5.61919C17.9547 5.94784 15.0549 7.88374 12.0186 9.9205L6.49767 13.6238L3.57727 12.3679C0.0454133 10.8497 0 10.8226 0 10.2417C0 9.41714 12.0246 4.35434 17.8391 2.73078Z"
        fill="inherit"
      />
      <path
        d="M10.7281 20.177C8.83497 22.1533 8.49269 21.9589 8.49269 18.9088C8.49269 17.4807 8.6175 16.3149 8.76963 16.3179C8.92176 16.3208 9.71121 16.8917 10.5236 17.5861L12.0005 18.8491L10.7281 20.177Z"
        fill="inherit"
      />
    </svg>
  );
}

export default SendIcon;
