import React, { useState, useEffect } from 'react';
import Cat, { CAT_STATE } from '../../../ui/components/Cat/Cat';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import useWindow from '../../../ui/hooks/useWindow';
import { DRAG_AREA, MATING_STATUS } from '../../../common/constants';

const CatMemo = React.memo(Cat, (prevProps, nextProps) => {
  return _.isEqual(_.pick(prevProps, ['cat', 'dragAround']), _.pick(nextProps, ['cat', 'dragAround']));
});

function CatsSwiper({ catsWithoutHome }) {
  const { appStore } = RootStore;
  const { isMobile } = useWindow();
  const draggableOptions = {
    canDrag: (data, from, element) => {
      return data.mating_status !== MATING_STATUS.MATING && !appStore.meIsCEO;
    },
  };
  const [dragAroundForWithoutHomeCats, setDragAroundForWithoutHomeCats] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const el = document.querySelectorAll('.own-nurseries__cats-slider .swiper-wrapper')[0];
      if (!el) {
        return;
      }
      const dragAroundForWithoutHomeCatsVal = el.offsetWidth >= el.scrollWidth;
      if (dragAroundForWithoutHomeCats !== dragAroundForWithoutHomeCatsVal) {
        setDragAroundForWithoutHomeCats(dragAroundForWithoutHomeCatsVal);
      }
    });
  }, [catsWithoutHome]);

  const doubleClick = (item) => {
    let side = '';
    let toLeft = true;
    if (appStore.currentGroupedHouses) {
      if (!appStore.currentGroupedHouses.left.left_cat) {
        side = 'left';
      } else if (!appStore.currentGroupedHouses.left.right_cat) {
        side = 'left';
        toLeft = false;
      } else if (!appStore.currentGroupedHouses.right.left_cat) {
        side = 'right';
      } else if (!appStore.currentGroupedHouses.right.right_cat) {
        side = 'right';
        toLeft = false;
      } else {
        appStore.notEnoughSpaceHouseModalOpen();
        return false;
      }
    } else {
      appStore.missingHouseModalOpen();
      return false;
    }
    appStore.moveCatInHouse(item.cat_id, appStore.currentGroupedHouses[side].data.house_id, toLeft);
  };

  return (
    <Swiper
      watchOverflow={!dragAroundForWithoutHomeCats}
      touchStartPreventDefault={false}
      threshold={0}
      touchAngle={40}
      freeModeMomentumRatio={0.2}
      freeModeMomentumVelocityRatio={0.2}
      slidesPerView={isMobile ? 9 : 8}
      freeMode={true}
      grabCursor={true}
      className="own-nurseries__cats-slider"
      virtual
      lazy
    >
      {catsWithoutHome.map((item, index) => (
        <SwiperSlide key={item.cat_id} virtualIndex={index}>
          <CatMemo
            dragAround={dragAroundForWithoutHomeCats}
            from={DRAG_AREA.NURSERY_CATS}
            onDoubleClick={() => doubleClick(item)}
            cat={item}
            state={CAT_STATE.NURSERY}
            draggableOptions={draggableOptions}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default observer(CatsSwiper);
