import React from 'react';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import Modal from '../Modal/Modal';
import routes from '../../../routes';
import { ROLE_NAME } from '../../../store/constants';
import WalletTitleIcon from '../../icons/WalletTitleIcon';
import CoinModalIcon from '../../icons/modal/CoinModalIcon';
import TextButton from '../../buttons/TextButton/TextButton';
import NumberInput from '../../NumberInput/NumberInput';
import { RadioButton } from '../RadioButton';
import './LTDLiabilityModal.scss';
import { LTD_FINES_TYPE, LTD_FINES_TYPE_NAME } from '../../../common/constants';

function LTDLiabilityModal() {
  const { appStore } = RootStore;
  if (!appStore.LTDLiabilityModalData) {
    return null;
  }

  const liabilityData = appStore.LTDLiabilityModalData;

  const Title = observer(({ isFine, liability }) => {
    if (isFine) {
      if (liability.length === 1) {
        return `Хочешь расторгнуть сделку за ${LTD_FINES_TYPE_NAME[liability[0].liability_type]}?`;
      } else {
        return `Хочешь расторгнуть сделку?`;
      }
    } else {
      if (liability.length === 1) {
        return `Хочешь отправить претензию за ${LTD_FINES_TYPE_NAME[liability[0].liability_type]}?`;
      } else {
        return `Хочешь отправить претензию за недопоставку и качество котиков?`;
      }
    }
  });

  const Desk = observer(({ isFine, liability }) => {
    const ltd = appStore.ltdById(liabilityData.deal_id);
    const partnerUuid = ltd.ltd_info.initiator === appStore.me.uuid ? ltd.ltd_info.partner : ltd.ltd_info.initiator;
    const partner = appStore.ltdPartner(partnerUuid, ltd.ltd_info);
    const areaTitle = appStore.meIsCEO ? `в городе ${ltd.ltd_info.contents[0].area_num}` : '';
    if (isFine && liability.length > 1) {
      return `выбери штраф, который хочешь отправить В ${ROLE_NAME[partner.role]} "${partner.name}" ${areaTitle}`;
    } else if (isFine) {
      return `с ${ROLE_NAME[partner.role]}ом "${partner.name}" ${areaTitle}`;
    } else {
      return `в ${ROLE_NAME[partner.role]} "${partner.name}" ${areaTitle}`;
    }
  });

  const Content = observer(({ isFine, liability, selected_liability }) => {
    if (liability.length === 1 || !isFine) {
      return (
        <>
          <WalletTitleIcon className="modal-ltd-liability__body-wallet" />
          {isFine && (
            <div className="modal-ltd-liability__body-info">
              <div className="modal-ltd-liability__body-info-text">сумма штрафа</div>
              <div className="modal-ltd-liability__body-info-coin">
                <CoinModalIcon className="modal__body-price-coin-icon" />
                <span className="modal__body-price-coin-count">{liability[0].value}</span>
              </div>
            </div>
          )}
        </>
      );
    } else {
      let priceOverdueDelivery = 0;
      let priceLowQuality = 0;
      liability.forEach((item) => {
        if (item.liability_type === LTD_FINES_TYPE.OVERDUE_DELIVERY) {
          priceOverdueDelivery = item.value;
        } else if (item.liability_type === LTD_FINES_TYPE.LOW_QUALITY) {
          priceLowQuality = item.value;
        }
      });
      return (
        <div className="modal-ltd-liability__body-fines--wrapper">
          <div className="modal-ltd-liability__body-fines-item">
            <RadioButton
              value={selected_liability === LTD_FINES_TYPE.OVERDUE_DELIVERY}
              onClick={() => appStore.changeLTDSelectedLiability(LTD_FINES_TYPE.OVERDUE_DELIVERY)}
            />
            <span className="modal-ltd-liability__body-fines-info">Штраф за недопоставку котиков</span>
            <NumberInput readOnly={true} value={priceOverdueDelivery} />
          </div>
          <div className="modal-ltd-liability__body-fines-item">
            <RadioButton
              value={selected_liability === LTD_FINES_TYPE.LOW_QUALITY}
              onClick={() => appStore.changeLTDSelectedLiability(LTD_FINES_TYPE.LOW_QUALITY)}
            />
            <span className="modal-ltd-liability__body-fines-info">Штраф за качество</span>
            <NumberInput readOnly={true} value={priceLowQuality} />
          </div>
        </div>
      );
    }
  });

  return (
    <Modal
      isOpen={!!appStore.LTDLiabilityModalData}
      onClose={appStore.hideLTDLiabilityModal}
      title={<Title isFine={liabilityData.is_fine} liability={liabilityData.liability} />}
      desc={<Desk isFine={liabilityData.is_fine} liability={liabilityData.liability} />}
      customClass="ltd-liability"
      size="big"
      style={{ zIndex: 101 }}
    >
      <div className="modal-ltd-liability__body--wrapper">
        <Content
          isFine={liabilityData.is_fine}
          liability={liabilityData.liability}
          selected_liability={liabilityData.selected_liability}
        />
      </div>

      <div className="modal__body-actions">
        <TextButton
          color="green"
          title="Отправить"
          isDisabled={liabilityData.is_fine && liabilityData.liability.length > 1 && !liabilityData.selected_liability}
          onClick={() => {
            appStore.LTDLiabilityRequest(liabilityData);
            RootStore.goTo(routes.main);
          }}
        />
        <TextButton color="purple" title="Отменить" onClick={() => appStore.hideLTDLiabilityModal()} />
      </div>
    </Modal>
  );
}

export default observer(LTDLiabilityModal);
