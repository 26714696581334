import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import Cat, { BOTTOM_TEXT, CAT_STATE } from './Cat/Cat';
import TextButton from '../buttons/TextButton/TextButton';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import { ROLE_NAME } from '../../store/constants';

function LotProposalSentModal() {
  const { appStore } = RootStore;
  if (appStore.lotModalData) {
    const desc = `в ${ROLE_NAME[appStore.currentPlayer.role]} "${appStore.currentPlayer.name}"`;
    const [cats, price] = appStore.groupCats(appStore.lotModalData, price);
    const textPrice = appStore.lotModalData.buyer === appStore.me.uuid ? 'цена покупки' : 'цена продажи';
    return (
      <Modal
        isOpen={!!appStore.lotModalData}
        onClose={appStore.lotModalData && appStore.lotProposalSentModalClose}
        title={
          <div>
            предложение
            <br />
            отправлено
          </div>
        }
        desc={desc}
        size="cats"
      >
        <div className="modal__body-wrapper">
          <div className="modal__body-cats">
            {cats.map((item, index) => (
              <Cat
                key={index}
                cat={item}
                readonly
                state={CAT_STATE.SINGLE}
                bottomText={BOTTOM_TEXT.KIND}
                realCount={true}
              />
            ))}
          </div>
          <div className="modal__body-price">
            <p className="modal__body-price-text">{textPrice}</p>
            <p className="modal__body-price-coin">
              <CoinModalIcon className="modal__body-price-coin-icon" />
              <span className="modal__body-price-coin-count notranslate">{price}</span>
            </p>
          </div>
        </div>
        <div className="modal__body-actions">
          <TextButton color="blue" title="Понятно" onClick={appStore.lotProposalSentModalClose} />
        </div>
      </Modal>
    );
  }
}

export default observer(LotProposalSentModal);
