const getDifferenceOfObjects = (object, object2) => {
  return _.reduce(
    object,
    function (result, value, key) {
      return _.isEqual(value, object2[key]) ? result : result.concat(key);
    },
    [],
  );
};

export default getDifferenceOfObjects;
