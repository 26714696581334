import React from 'react';

function PlusModalIcon({ style, fill, stylePath, className }) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M8.39518 4.96875C8.5121 3.20089 8.74874 1.52866 8.92111 1.25223C9.1228 0.929598 10.2233 0.75 12 0.75C13.7767 0.75 14.8772 0.929598 15.0789 1.25223C15.2513 1.52866 15.4879 3.20089 15.6048 4.96875L15.817 8.18304L19.0312 8.39516C20.7991 8.51208 22.4717 8.74876 22.7478 8.92113C23.0704 9.12283 23.25 10.2233 23.25 12C23.25 13.7767 23.0704 14.8772 22.7478 15.0789C22.4717 15.2512 20.7991 15.4879 19.0312 15.6048L15.817 15.817L15.6161 19.0312C15.5056 20.7991 15.2689 22.4717 15.0901 22.7478C14.882 23.0696 13.7707 23.25 12 23.25C10.2233 23.25 9.1228 23.0704 8.92111 22.7478C8.74874 22.4717 8.5121 20.7991 8.39518 19.0312L8.18304 15.817L4.96875 15.6048C3.20089 15.4879 1.52866 15.2512 1.25223 15.0789C0.929598 14.8772 0.75 13.7767 0.75 12C0.75 10.2233 0.929598 9.12283 1.25223 8.92113C1.52866 8.74876 3.20089 8.51208 4.96875 8.39516L8.18304 8.18304L8.39518 4.96875Z"
        fill={fill ? fill : '#C6A788'}
      />
    </svg>
  );
}

export default PlusModalIcon;
