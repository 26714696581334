import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';

function LTDNotificationsErrorModal() {
  const { appStore } = RootStore;

  const LTD_ERROR = {
    NOT_CORRECT_DATA: 'Произошла ошибка, попробуйте создать сделку заново',
    BANK_GUARANTEE_SEASON: 'Неверный сезон банковской гарантии',
    BANK_GUARANTEE_AMOUNT: 'Банковская гарантия превышает доступную сумму',
    OFFER_BANK_GUARANTEE_AMOUNT: 'Банковская гарантия для оппонента превышает доступную сумму ',
    CONTENT_AMOUNT: 'Сумма всех платежей не совпадает с общей суммой за котят',
    TIMELINE: 'Неверные сезоны или таймеры для действий',
    TIMELINE_SEASON: 'Неверный сезон для действия',
    TIMELINE_TIMER: 'Неверное время для действия',
    TIMELINE_INTERVAL: 'Неверный интервал между действиями',
    FINE_AMOUNT: 'Неверная сумма для штрафа',
  };

  return (
    <Modal title={LTD_ERROR[appStore.LTDServerErrorText]} isOpen={!!appStore.LTDServerErrorText}>
      <div className="modal__body-actions">
        <TextButton color="purple" title="понятно" onClick={() => appStore.setLTDServerErrorText('')} />
      </div>
    </Modal>
  );
}

export default observer(LTDNotificationsErrorModal);
