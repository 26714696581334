import Step from '../../base/Step';

// ToDo: возможно не понадобиться
class LTDStep extends Step {
  constructor({
    actionName,
    timeoutBeforeNextStep,
    isDisabledDoubleClickCat,
    hideModalsOnStartTurn,
    blockedActionsNames,
    title,
    isWide,
    message,
    elements,
    pulseElements,
    position,
    menuClickDisabled,
    nextButton,
    buttonText,
    disabledElements,
    isMax,
    after,
    before,
    shadedElements,
    needOverlayClickModal,
    checkParams,
    actionNames,
    showingErrorModalIsNeeded,
    acceptActionsWithInvalidCheckParams,
    removeFocusFromElements,
    selectMenuPosition,
    elementsForChangeZIndex,
    methodsProxy,
    headerDisabled,
    hiddenTimersIds,
    forceTimerValue,
    acceptedDropTypesOverride,
    draggableSettingsOverride,
    observeHealthExaminationsOfCats,
    openedLTDRows = null,
    selectedLTDTab = null,
    selectorIntoView,
  }) {
    super({
      actionName,
      timeoutBeforeNextStep,
      isDisabledDoubleClickCat,
      hideModalsOnStartTurn,
      blockedActionsNames,
      after,
      before,
      buttonText,
      disabledElements,
      elements,
      isMax,
      isWide,
      menuClickDisabled,
      message,
      nextButton,
      position,
      pulseElements,
      title,
      shadedElements,
      needOverlayClickModal,
      checkParams,
      actionNames,
      showingErrorModalIsNeeded,
      acceptActionsWithInvalidCheckParams,
      removeFocusFromElements,
      selectMenuPosition,
      elementsForChangeZIndex,
      methodsProxy,
      headerDisabled,
      hiddenTimersIds,
      forceTimerValue,
      acceptedDropTypesOverride,
      draggableSettingsOverride,
      observeHealthExaminationsOfCats,
      selectorIntoView,
    });
    this.openedLTDRows = openedLTDRows;
    this.selectedLTDTab = selectedLTDTab;
  }
}

export default LTDStep;
