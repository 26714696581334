import React from 'react';

function LockIcon({ style }) {
  return (
    <svg width="84" height="84" viewBox="0 0 84 84" fill="#C0A88F" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3736 27.4475C20.3893 17.4002 24.952 8.72707 32.2222 4.92248C41.5043 0.0647058 53.7652 3.16329 59.5545 11.8318C61.6434 14.959 62.7808 18.6956 63.2752 24.0563C63.7409 29.1168 65.2556 34.3413 67.6714 39.2192C70.9338 45.8082 71.2862 47.5277 70.7445 54.2112C70.0487 62.8091 67.9622 67.4863 62.3393 73.0571C51.1456 84.1476 33.0061 84.1476 21.8124 73.0571C16.1933 67.4898 14.1033 62.8103 13.4085 54.2363C12.867 47.5458 13.2179 45.8692 16.5829 39.0985C18.6634 34.9102 20.37 29.6676 20.3736 27.4475ZM51.6402 21.3279C51.0383 19.2499 49.8552 16.8649 49.0085 16.027C46.5457 13.5869 40.5768 13.0828 37.0779 15.0185C34.4692 16.462 30.3781 23.5828 31.5167 24.6967C31.6856 24.8615 34.2292 24.5604 37.1697 24.0263C40.1089 23.4932 43.0939 23.2246 43.8031 23.4299C44.511 23.6342 46.8111 24.0957 48.9121 24.4539C52.7029 25.1004 52.7245 25.0742 51.6402 21.3279ZM47.4502 43.3013C50.3882 45.592 50.6536 46.3867 49.9432 50.7698C49.5054 53.4682 49.1556 57.9254 49.1676 60.6739C49.1869 65.2422 48.8831 65.737 45.6326 66.4442C43.6764 66.8699 40.4755 66.8699 38.5192 66.4442C35.2531 65.7332 34.9708 65.2638 35.0528 60.6739C35.1023 57.9254 34.7417 54.2077 34.2521 52.4118C33 47.8279 34.3111 43.7447 37.5265 42.2093C41.6502 40.2398 43.8307 40.4799 47.4502 43.3013Z"
        fill="inherit"
      />
    </svg>
  );
}

export default LockIcon;
