import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import { CatFaceIcon } from '../icons';

function NotEnoughCatsForMagnifierModal() {
  const { appStore } = RootStore;

  if (!appStore.notEnoughCatsForMagnifierModalFlag) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.notEnoughCatsForMagnifierModalFlag}
      onClose={appStore.notEnoughCatsForMagnifierModalClose}
      title="Невозможно использовать лупу"
      desc="заявка с котиками была отменена"
    >
      <CatFaceIcon className="modal__body-icon not-enough-cats-modal-icon" />
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.notEnoughCatsForMagnifierModalClose} />
      </div>
    </Modal>
  );
}

export default observer(NotEnoughCatsForMagnifierModal);
