import Training from '../../base/Training';
import RootStore from '../../../index';
import { LocalStorage } from '../../../../common/localStorage';
import routes from '../../../../routes';
import { TRAINING_CHAPTER_TYPE } from '../../../constants';

class LTDTraining extends Training {
  constructor({ chapters, chaptersGenerators }) {
    super({ chapters, chaptersGenerators });
  }

  checkTrainingProgress(launchingChapter) {
    if (launchingChapter.chapter > RootStore.appStore.tutorialProgress.chapter + 1) {
      RootStore.goTo(LocalStorage.USER_TOKEN_TRAINING ? routes.training : routes.startTraining, {
        training_type: TRAINING_CHAPTER_TYPE.LTD,
      });
      return false;
    }
    return true;
  }
}

export default LTDTraining;
