import React from 'react';
import Modal from './Modal/Modal';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import FleasModalIcon from '../icons/modal/FleasModalIcon';
import CoinModalIcon from '../icons/modal/CoinModalIcon';

function FleasModal() {
  const { appStore } = RootStore;

  if (!appStore.fleasData) {
    return null;
  }

  return (
    <Modal isOpen={!!appStore.fleasData} title="хочешь использовать шампунь против клещей?" style={{ zIndex: 1000 }}>
      <div className="modal__body-price">
        <FleasModalIcon />
        {!appStore.isTeamMode && (
          <>
            <p className="modal__body-price-text">ЦЕНА шампуня</p>
            <p className="modal__body-price-coin">
              <CoinModalIcon className="modal__body-price-coin-icon" />
              <span className="modal__body-price-coin-count notranslate"> {appStore.fleasData?.insured ? 0 : 2} </span>
            </p>
          </>
        )}
      </div>
      <div className="modal__body-actions">
        <TextButton color="green" title="Использовать" onClick={() => appStore.catTreatmentSend(appStore.fleasData)} />
        <TextButton color="purple" title="отмена" onClick={appStore.hideTreatmentActionModal} />
      </div>
    </Modal>
  );
}

export default observer(FleasModal);
