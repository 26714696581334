import React from 'react';
import LTDStepsApiParams from '../base/LTDStepsApiParams';
import LTDChapter from '../base/LTDChapter';
import LTDStep from '../base/LTDStep';
import {
  CUSTOM_ACTION_NAMES,
  LTD_ACTIONS,
  LTD_ACTIONS_STATUS,
  LTD_CONTENT_TYPE,
  LTD_FINES_TYPE,
  TIMER_ID,
} from '../../../../common/constants';
import { INFO_MODAL_POSITION } from '../../base/constants';
import RootStore from '../../../index';
import { LTD_OBJECT_BY_STAGE } from '../base/methods/generateLTDInfo';
import { RECEIVE_MESSAGE_TYPE } from '../../../constants';
import checkLTDActions from '../base/methods/checkLTDActions';
import generateTrainingDealId from '../base/methods/generateTrainingDealId';
import generateLTDActionRowId from '../../../../common/LTD/generateLTDActionRowId';
import { LocalStorage } from '../../../../common/localStorage';
import api from '../../../../api';
import generateLTDChapterName from '../base/methods/generateLTDChapterName';
import { PLATFORM_URLS } from '../../../../common/platformUrls';

const steps = () => [
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    title: <div>Просрочка</div>,
    nextButton: true,
    blockedActionsNames: [
      'openIntroductionModal',
      'toggleSeasonChangeModal',
      'toggleEndTurnFlag',
      'setLTDNotificationsModal',
    ],
    needOverlayClickModal: false,
    buttonText: 'Дальше',
    message: (
      <p className="training-modal__desc">
        Рассмотрим случай, когда наш контрагент не успевает выполнить свои обязательства в установленный срок
      </p>
    ),
    openedLTDRows: [generateTrainingDealId(1)],
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
  }),
  new LTDStep({
    actionName: 'checkSendLTDLiability',
    title: <div>Претензия и расторжение</div>,
    message: (
      <p className="training-modal__desc">
        В таком случае, мы можем отправить претензию или штраф, который расторгнет сделку. Для начала отправим претензию
        контрагенту
      </p>
    ),
    callback() {
      RootStore.appStore.hideIntroductionModal();
    },
    needOverlayClickModal: false,
    openedLTDRows: [generateTrainingDealId(1)],
    elements: [
      `#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PREPAID,
        contentType: LTD_CONTENT_TYPE.SELL,
      })}`,
    ],
    pulseElements: ['.ltd-action__detailed-btn--pretension'],
    disabledElements: ['.ltd-action__detailed-btn--fine'],
    elementsForChangeZIndex: {
      [`#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PREPAID,
        contentType: LTD_CONTENT_TYPE.SELL,
      })}`]: {
        newZIndex: 10,
        returnOriginalZIndexIsNeeded: true,
      },
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    position: INFO_MODAL_POSITION.TOP,
    selectorIntoView: '#ltd-action-row--prepaid-sell',
  }),
  new LTDStep({
    actionName: RECEIVE_MESSAGE_TYPE.LTD_UPDATE,
    title: <div>Претензия и расторжение</div>,
    blockedActionsNames: ['setLTDNotificationsModal'],
    checkParams([ltdUpdate]) {
      return checkLTDActions({
        actions: RootStore.appStore.LTDContentByType(ltdUpdate.contents, LTD_CONTENT_TYPE.SELL).actions,
        neededActionType: LTD_ACTIONS.PREPAID,
        neededStatus: LTD_ACTIONS_STATUS.PENDING,
        neededSentLiabilities: [
          {
            liability_type: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            is_fine: false,
            is_closed: false,
          },
        ],
      });
    },
    message: (
      <p className="training-modal__desc">
        В таком случае, мы можем отправить претензию или штраф, который расторгнет сделку. Для начала отправим претензию
        контрагенту
      </p>
    ),
    pulseElements: ['.modal__body-actions .text_button--color-green'],
    disabledElements: ['.modal__body-actions .text_button--color-purple'],
    needOverlayClickModal: false,
    openedLTDRows: [generateTrainingDealId(1)],
    elements: [`.modal--send-ltd-overdue-fine`],
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    nextButton: true,
    buttonText: 'Дальше',
    title: <div>Претензия</div>,
    blockedActionsNames: ['setLTDNotificationsModal'],
    message: (
      <p className="training-modal__desc">
        Претензия не разрывает сделку, в отличие от штрафа, и несет в себе более уведомительный характер. Тем самым мы
        подсвечиваем для нашего контрагента просрочку по сделке
      </p>
    ),
    needOverlayClickModal: false,
    openedLTDRows: [generateTrainingDealId(1)],
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    nextButton: true,
    buttonText: 'Дальше',
    title: <div>Претензия</div>,
    blockedActionsNames: ['setLTDNotificationsModal'],
    message: (
      <p className="training-modal__desc">
        После того, как мы отправили претензию нашему контрагенту, поле подсвечивается желтым и появляется новое
        уведомление. Повторно направить претензию нельзя
      </p>
    ),
    needOverlayClickModal: false,
    openedLTDRows: [generateTrainingDealId(1)],
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    nextButton: true,
    buttonText: 'Дальше',
    title: <div>Расторжение</div>,
    message: (
      <p className="training-modal__desc">
        Мы также можем назначить штраф, минуя претензию, если с контрагентом не получилось договориться об
        урегулировании конфликта, но это расторгнет дальнейшую сделку
      </p>
    ),
    needOverlayClickModal: false,
    openedLTDRows: [generateTrainingDealId(1)],
    elements: [
      `#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PREPAID,
        contentType: LTD_CONTENT_TYPE.SELL,
      })}`,
    ],
    disabledElements: ['.ltd-action__detailed-btn--pretension', '.ltd-action__detailed-btn--fine'],
    elementsForChangeZIndex: {
      [`#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PREPAID,
        contentType: LTD_CONTENT_TYPE.SELL,
      })}`]: {
        newZIndex: 10,
        returnOriginalZIndexIsNeeded: true,
      },
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: 'checkSendLTDLiability',
    title: <div>Расторжение</div>,
    message: (
      <p className="training-modal__desc">
        Либо же, если мы уже отправили претензию, но наш контрагент никак на нее не отвечает, то мы можем отправить ему
        расторжение
      </p>
    ),
    needOverlayClickModal: false,
    openedLTDRows: [generateTrainingDealId(1)],
    elements: [
      `#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PREPAID,
        contentType: LTD_CONTENT_TYPE.SELL,
      })}`,
      '.ltd-action__detailed-btn--fine',
    ],
    pulseElements: ['.ltd-action__detailed-btn--fine'],
    disabledElements: ['.ltd-action__detailed-btn--pretension'],
    elementsForChangeZIndex: {
      [`#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PREPAID,
        contentType: LTD_CONTENT_TYPE.SELL,
      })}`]: {
        newZIndex: 10,
        returnOriginalZIndexIsNeeded: true,
      },
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: RECEIVE_MESSAGE_TYPE.LTD_UPDATE,
    blockedActionsNames: ['setLTDNotificationsModal'],
    checkParams([ltdUpdate]) {
      return checkLTDActions({
        actions: RootStore.appStore.LTDContentByType(ltdUpdate.contents, LTD_CONTENT_TYPE.SELL).actions,
        neededActionType: LTD_ACTIONS.PREPAID,
        neededStatus: LTD_ACTIONS_STATUS.PENDING,
        neededSentLiabilities: [
          {
            liability_type: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            is_fine: false,
            is_closed: false,
          },
          {
            liability_type: [LTD_FINES_TYPE.OVERDUE_PAYMENT],
            is_fine: true,
            is_closed: false,
          },
        ],
      });
    },
    title: <div>Расторжение</div>,
    message: (
      <p className="training-modal__desc">
        Либо же, если мы уже отправили претензию, но наш контрагент никак на нее не отвечает, то мы можем отправить ему
        расторжение
      </p>
    ),
    needOverlayClickModal: false,
    openedLTDRows: [generateTrainingDealId(1)],
    elements: [
      `#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PREPAID,
        contentType: LTD_CONTENT_TYPE.SELL,
      })}`,
      '.modal--send-ltd-overdue-fine',
    ],
    pulseElements: ['.modal__body-actions .text_button--color-green'],
    disabledElements: ['.modal__body-actions .text_button--color-purple'],
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 0,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    position: INFO_MODAL_POSITION.TOP,
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    nextButton: true,
    buttonText: 'Дальше',
    blockedActionsNames: ['setLTDNotificationsModal'],
    title: <div>Расторжение</div>,
    message: (
      <p className="training-modal__desc">
        При выставлении штрафа сделка автоматически расторгается. Сторона, нарушившая условия сделки, будет нести
        издержки, указанные в договоре
      </p>
    ),
    needOverlayClickModal: false,
    openedLTDRows: [generateTrainingDealId(1)],
    elements: [
      '.ltd-action__detailed--terminating',
      '.ltd-action__container--fine',
      `#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PAYMENT_OF_FINE,
        contentType: LTD_CONTENT_TYPE.SELL,
      })}`,
    ],
    pulseElements: ['.modal__body-actions .text_button--color-green'],
    disabledElements: ['.modal__body-actions .text_button--color-purple'],
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 10,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    position: INFO_MODAL_POSITION.BOTTOM,
    selectorIntoView: '.ltd-action__container--pretension',
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    nextButton: true,
    buttonText: 'Дальше',
    blockedActionsNames: ['setLTDNotificationsModal'],
    title: <div>Расторжение</div>,
    message: (
      <p className="training-modal__desc">
        В случае, если одна из сторон не оплатит штраф в течение сезона, то он будет автоматически оплачен в конце
        сезона
      </p>
    ),
    needOverlayClickModal: false,
    openedLTDRows: [generateTrainingDealId(1)],
    elements: [
      '.ltd-action__detailed--terminating',
      '.ltd-action__container--fine',
      `#${generateLTDActionRowId({
        actionType: LTD_ACTIONS.PAYMENT_OF_FINE,
        contentType: LTD_CONTENT_TYPE.SELL,
      })}`,
    ],
    pulseElements: ['.modal__body-actions .text_button--color-green'],
    disabledElements: ['.modal__body-actions .text_button--color-purple'],
    elementsForChangeZIndex: {
      '.ltd-action__container': {
        newZIndex: 10,
        returnOriginalZIndexIsNeeded: true,
      },
    },
    shadedElements: ['.modal--team-tasks__modal.my-tasks__modal'],
    position: INFO_MODAL_POSITION.BOTTOM,
    after() {
      RootStore.appStore.hideMyTasksModal();
    },
  }),
  new LTDStep({
    actionName: CUSTOM_ACTION_NAMES.ON_NEXT_BUTTON,
    nextButton: true,
    buttonText: 'Дальше',
    title: <div>Завершение</div>,
    message: (
      <p className="training-modal__desc">
        Теперь вы знаете, как работают долгосрочные сделки. Пора приступать к заключению долгосрочных сделок с другими
        игроками. Удачи!
      </p>
    ),
    after() {
      RootStore.appStore.hideMyTasksModal();
      location.href = PLATFORM_URLS.CLIENT;
    },
  }),
];

const chapter8 = () =>
  new LTDChapter({
    title: 'Претензии и штрафы',
    chapter: generateLTDChapterName(8),
    defaultStepSettings: {
      hiddenTimersIds: [TIMER_ID.HEADER.SESSION_TIMER],
    },
    userIdentifierForSocketInitialization: LocalStorage.ORIGINAL_TRAINING_USER_UID,
    onChapterInitializationActionsNames: ['openIntroductionModal', 'toggleSeasonChangeModal', 'toggleEndTurnFlag'],
    stepsApiParams: {
      ...LTDStepsApiParams,
      initial_turn: 5,
      override_deals: [LTD_OBJECT_BY_STAGE.NURSERY_PAYMENT_AFTER_SHIPPING],
    },
    callback() {
      RootStore.appStore.openMyTasksModal();
    },
    initialRouteParams: {
      routeName: 'main',
    },
    beforeStepsInitializationStep: new LTDStep({
      title: <div>Ожидание старта главы</div>,
      message: <p className="training-modal__desc">Ожидание...</p>,
    }),
    disableTimers: true,
    stepsGeneratorInfo: {
      generateOnChapterInitialization: false,
      generator: steps,
    },
  });

export default chapter8;
