import city1fall from '../assets/corpStore/cities/fall/city_1.png';
import city1winter from '../assets/corpStore/cities/winter/city_1.png';
import city1spring from '../assets/corpStore/cities/spring/city_1.png';
import city1summer from '../assets/corpStore/cities/summer/city_1.png';
import city2fall from '../assets/corpStore/cities/fall/city_2.png';
import city2winter from '../assets/corpStore/cities/winter/city_2.png';
import city2spring from '../assets/corpStore/cities/spring/city_2.png';
import city2summer from '../assets/corpStore/cities/summer/city_2.png';
import city3fall from '../assets/corpStore/cities/fall/city_3.png';
import city3winter from '../assets/corpStore/cities/winter/city_3.png';
import city3spring from '../assets/corpStore/cities/spring/city_3.png';
import city3summer from '../assets/corpStore/cities/summer/city_3.png';
import city4fall from '../assets/corpStore/cities/fall/city_4.png';
import city4winter from '../assets/corpStore/cities/winter/city_4.png';
import city4spring from '../assets/corpStore/cities/spring/city_4.png';
import city4summer from '../assets/corpStore/cities/summer/city_4.png';
import city5fall from '../assets/corpStore/cities/fall/city_5.png';
import city5winter from '../assets/corpStore/cities/winter/city_5.png';
import city5spring from '../assets/corpStore/cities/spring/city_5.png';
import city5summer from '../assets/corpStore/cities/summer/city_5.png';

export default [
  {
    fall: city1fall,
    winter: city1winter,
    spring: city1spring,
    summer: city1summer,
  },
  {
    fall: city2fall,
    winter: city2winter,
    spring: city2spring,
    summer: city2summer,
  },
  {
    fall: city3fall,
    winter: city3winter,
    spring: city3spring,
    summer: city3summer,
  },
  {
    fall: city4fall,
    winter: city4winter,
    spring: city4spring,
    summer: city4summer,
  },
  {
    fall: city5fall,
    winter: city5winter,
    spring: city5spring,
    summer: city5summer,
  },
];
