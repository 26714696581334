import React from 'react';
import Divider from '../../../Divider/Divider';
import './style.scss';
import classNames from '../../../../../common/classNames';
import { observer } from 'mobx-react';

const PlayerComponent = ({ player, topDividerIsNeeded, bottomDividerIsNeeded }) => {
  const FullName = () => {
    if (!player.fullName) {
      return null;
    }
    return <div className="our-company-user-list__player-full-name">{player.fullName}</div>;
  };

  const Competences = () => {
    if (!player.competences) {
      return null;
    }
    return <div className="our-company-user-list__player-competences">{player.competences}</div>;
  };

  const SpecialityIconBlock = () => {
    return <div className="our-company-user-list__player-icon">{player.icon}</div>;
  };

  const DividerLine = () => {
    return <Divider customClass="user-list" />;
  };

  return (
    <div className="our-company-user-list__player-wrapper">
      {topDividerIsNeeded && <DividerLine />}
      <div
        className={classNames({
          'our-company-user-list__player': true,
          'our-company-user-list__player--without-one-item': !player.fullName || !player.competences,
          'our-company-user-list__player--disabled': player.disabled,
        })}
      >
        <SpecialityIconBlock />
        <FullName />
        <Competences />
      </div>
      {bottomDividerIsNeeded && <DividerLine />}
    </div>
  );
};

export default observer(PlayerComponent);
