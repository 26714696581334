import React from 'react';
import RootStore from '../../../store';
import { observer } from 'mobx-react';
import Modal from '../Modal/Modal';
import TextButton from '../../buttons/TextButton/TextButton';
import routes from '../../../routes';
import { LTD_ARCHIVE_ACTION_TYPE, ROLE_NAME } from '../../../store/constants';
import { ContractSheet } from '../../icons';

const ACTION_TEXT = {
  [LTD_ARCHIVE_ACTION_TYPE.UPDATE]: 'Хочешь сохранить шаблон сделки в архиве?',
  [LTD_ARCHIVE_ACTION_TYPE.SAVE]: 'Хочешь сохранить новую сделку в архиве?',
  [LTD_ARCHIVE_ACTION_TYPE.LOAD]: 'Хочешь вернуться к шаблону или создать новую сделку?',
};

function LTDArchiveModal() {
  const { appStore } = RootStore;
  if (!appStore.LTDArchiveModalData) {
    return null;
  }
  const modalData = appStore.LTDArchiveModalData;
  const LTDArchive = appStore.LTDArchive(modalData.partnerUuid, modalData.type_ltd);
  const partner = appStore.playerByUuid(modalData.partnerUuid);
  const isLoadAction = LTDArchive && modalData.actionType === LTD_ARCHIVE_ACTION_TYPE.LOAD;
  const saveButtonTitle = isLoadAction ? 'К шаблону' : 'Сохранить';
  const newDealTitle = isLoadAction ? 'Новая сделка' : 'Не сохранять';

  function handleSaveClick() {
    if (isLoadAction) {
      appStore.setArchiveParameters(partner.uuid, modalData.type_ltd);
      appStore.setCurrentLTDLoadedFromArchive(true);
      appStore.hideLTDArchiveModal();
    } else {
      appStore.createLTDArchive(partner.uuid, modalData.type_ltd);
      appStore.hideLTDArchiveModal();
      RootStore.goTo(routes.main);
    }
  }

  function handleNewDealClick() {
    if (isLoadAction) {
      appStore.hideLTDArchiveModal();
    } else {
      RootStore.goTo(routes.main);
      appStore.hideLTDArchiveModal();
    }
  }

  return (
    <Modal
      isOpen={!!modalData}
      customClass="ltd--modal-cancel"
      onClose={appStore.hideLTDArchiveModal}
      title={ACTION_TEXT[modalData.actionType]}
      desc={`С ${ROLE_NAME[partner.role]}ом "${partner.name}"`}
    >
      <ContractSheet fill={'#C6A788'} className="modal__body-icon" />
      <div className="modal__body-actions">
        <TextButton color="green" title={saveButtonTitle} onClick={handleSaveClick} />
        <TextButton color="blue" title={newDealTitle} onClick={handleNewDealClick} />
      </div>
    </Modal>
  );
}

export default observer(LTDArchiveModal);
