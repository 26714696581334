import { observer } from 'mobx-react';
import RootStore from '../../../../store';
import React from 'react';
import TimerIcon from '../../../../ui/icons/TimerIcon';
import { TIMER_ID } from '../../../../common/constants';
import Timer from '../../../../ui/components/Timer';
import dayjs from 'dayjs';

function LotTimer({ timer }) {
  const { appStore } = RootStore;
  const duration = dayjs.duration(timer, 'seconds');
  let value = appStore.isRealTimeMode
    ? (value = duration.subtract(appStore.timerValue))
    : (value = appStore.timerValue.subtract(timer, 'seconds'));

  let fail = false;
  if (value.$ms < 0) {
    fail = true;
    value = value.subtract(value).subtract(value);
  }
  return (
    <>
      <Timer customClassName="lot-panel__timer" id={TIMER_ID.LOT} style={{ color: fail ? 'red' : null }}>
        <TimerIcon style={{ width: '30px', height: '30px', marginRight: '10px' }} fill={'#AD967E'} />
        {value.format('m:ss')}
      </Timer>
    </>
  );
}

export default observer(LotTimer);
