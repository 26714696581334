import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarBallImage from '../../../assets/nurseryAvatars/avatarBall.png';

function AvatarBall({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <path
    //     d="M24.5288 11.3473C24.113 11.5481 23.7155 11.7865 23.3659 12.1052C22.3453 13.0354 21.7599 14.3667 21.7599 15.7587V16.7246C21.7599 18.8291 20.0731 20.5416 18.0002 20.5416C15.9273 20.5416 14.2404 18.8291 14.2404 16.7246V15.7587C14.2404 14.248 13.537 12.7953 12.359 11.8724C12.0847 11.6576 11.7903 11.4816 11.4842 11.3333C9.86925 13.0062 8.8335 15.2932 8.8335 17.8152C8.8335 21.5712 11.085 24.8785 14.2558 26.3333C14.4014 24.2779 16.0927 22.7954 18.0002 22.7954C20.0029 22.7954 21.6114 24.379 21.7534 26.329C24.9195 24.8722 27.1668 21.5678 27.1668 17.8152C27.1668 15.3001 26.136 13.0191 24.5288 11.3473Z"
    //     fill="#02516C"
    //   />
    //   <path
    //     d="M18.0014 19.6666C19.5276 19.6666 20.7695 18.4515 20.7695 16.9583V15.9988C20.7695 14.3092 21.5069 12.6926 22.7924 11.5633C23.1155 11.2796 23.4644 11.0348 23.8332 10.8278C22.228 9.58298 20.204 8.83325 18.0014 8.83325C15.7972 8.83325 13.7721 9.58383 12.1665 10.8302C12.4061 10.9649 12.6401 11.1119 12.8633 11.2803C14.3473 12.4006 15.2334 14.1647 15.2334 15.9988V16.9583C15.2334 18.4515 16.4753 19.6666 18.0014 19.6666Z"
    //     fill="#02516C"
    //   />
    //   <path
    //     d="M17.9988 23.8333C16.6454 23.8333 15.4261 25.0585 15.5035 26.7527C16.2933 27.0205 17.1308 27.1666 17.9988 27.1666C18.8677 27.1666 19.7061 27.0203 20.4965 26.7521C20.573 25.0776 19.3718 23.8333 17.9988 23.8333Z"
    //     fill="#02516C"
    //   />
    // </svg>
    <Avatar style={style} avatarImage={AvatarBallImage} />
  );
}

export default AvatarBall;
