import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import WalletIcon from '../icons/modal/WalletIcon';
import { CatFaceIcon } from '../icons';

function NotEnoughCatsModal() {
  const { appStore } = RootStore;

  if (!appStore.notEnoughCatsModalFlag) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.notEnoughCatsModalFlag}
      onClose={appStore.notEnoughCatsModalClose}
      title="Недостаточно котят"
      desc="у тебя не хватает котят"
    >
      <CatFaceIcon className="modal__body-icon not-enough-cats-modal-icon" />
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.notEnoughCatsModalClose} />
      </div>
    </Modal>
  );
}

export default observer(NotEnoughCatsModal);
