import React, { useState } from 'react';
import { observer } from 'mobx-react';
import TriangleIcon from '../../../icons/TriangleIcon';
import Divider from '../../Divider/Divider';
import WatcherModalIcon from '../../../icons/modal/WatcherModalIcon';
import RootStore from '../../../../store';
import './style.scss';

const findKeyByValue = (obj, value) => {
  const entry = Object.entries(obj).find(([key, val]) => val === value);
  return entry ? entry[0] : undefined;
};

const Captains = ({ captains, appStore }) => (
  <div className="captains">
    {Object.entries(captains).map(([areaNum, captain]) => {
      const handleCaptainClick = () => {
        appStore.meIsCEO = false;
        localStorage.removeItem('PLAYER_ROLE');
        localStorage.removeItem('IS_PLAYER_CEO');
        appStore.changePlayerSpectator(captain.player_id);
      };

      return (
        <React.Fragment key={captain.player_id}>
          <div className="captain-info">
            <span className="captain-name">
              <b>Город {areaNum}</b> - {captain.name}
            </span>
            {captain.is_bankrupt && (
              <span>
                <b> - Банкрот</b>
              </span>
            )}
            {!captain.is_bankrupt && (
              <div onClick={handleCaptainClick} className="user-list_item__watcher">
                <WatcherModalIcon fill={appStore.spectatorPlayerUid === captain.player_id ? '#C6A788' : '#000'} />
              </div>
            )}
          </div>
          <Divider customClass="user-list-divider" />
        </React.Fragment>
      );
    })}
  </div>
);

const AllCEOsAndCaptains = ({ player, isNotSpectator }) => {
  const { appStore, router } = RootStore;
  const [captainsSeen, setCaptainsSeen] = useState(false);
  const ceoId = findKeyByValue(appStore.otherTeamsCEO, player.ceo_id);
  const localPlayer = appStore.playerByUuid(ceoId);
  const Logo = localPlayer && localPlayer.logo;

  const handleCeoClick = () => {
    appStore.changePlayerSpectator(player.ceo_id);
  };

  return (
    <div className="our-company-user-list__main-player">
      <div className="corporations">
        <div onClick={() => setCaptainsSeen((prevState) => !prevState)}>
          <TriangleIcon top={!captainsSeen} />
        </div>
        {localPlayer && <Logo />}
        <div className="our-company-user-list__player-full-name">{localPlayer && localPlayer.name}</div>
        {player.is_bankrupt && <span> - Банкрот</span>}
        {!isNotSpectator && !player.is_bankrupt && (
          <div className="user-list_item__watcher" onClick={handleCeoClick}>
            <WatcherModalIcon fill={appStore.spectatorPlayerUid === player.ceo_id ? '#C6A788' : '#000'} />
          </div>
        )}
      </div>
      {captainsSeen && <Captains captains={player.captains} appStore={appStore} />}
      <Divider customClass="user-list" />
    </div>
  );
};

export default observer(AllCEOsAndCaptains);
