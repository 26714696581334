import React, { useState } from 'react';
import './corporationModal.scss';
import Modal from '../Modal/Modal';
import { observer } from 'mobx-react';
import TextButton from '../../buttons/TextButton/TextButton';
import classNames from '../../../common/classNames';
import { BudgetAllocationTab, TransactionsTab, TransportationTab } from './CorporationModalComponents';
import corpStore from '../../../store/CorpStore';
import RootStore from '../../../store';
import routes from '../../../routes';

export const TAB_NAMES = {
  BUDGET_ALLOCATION: 'budget_allocation',
  TRANSACTIONS: 'transactions',
  TRANSPORTATION: 'transportation',
};

let corporationModalTabs = {
  [TAB_NAMES.BUDGET_ALLOCATION]: {
    title: 'Бюджет',
    component: BudgetAllocationTab,
  },
  [TAB_NAMES.TRANSACTIONS]: {
    title: 'Сделки',
    component: TransactionsTab,
  },
  [TAB_NAMES.TRANSPORTATION]: {
    title: 'Транспортировка',
    component: TransportationTab,
  },
};

const CorporationModal = () => {
  const [currentTab, setCurrentTab] = useState(TAB_NAMES.BUDGET_ALLOCATION);

  if (!corpStore.corporationModal) {
    return null;
  }

  const TabComponent = corporationModalTabs[currentTab].component;

  function close() {
    corpStore.hideCorporationModal();
    setCurrentTab(TAB_NAMES.BUDGET_ALLOCATION);
  }

  return (
    <Modal
      isOpen={corpStore.corporationModal}
      onClose={close}
      title={
        <div className="corporation-modal__tabs">
          {Object.values(TAB_NAMES).map((tab) => (
            <div
              key={corporationModalTabs[tab].title}
              className={classNames({
                'corporation-modal__tab': true,
                'corporation-modal__tab--active': tab === currentTab,
              })}
              onClick={() => {
                setCurrentTab(tab);
              }}
            >
              {corporationModalTabs[tab].title}
            </div>
          ))}
        </div>
      }
      size="big"
      head={true}
      customClass="corporation-modal"
    >
      <TabComponent onClose={close} />
    </Modal>
  );
};

export default observer(CorporationModal);
