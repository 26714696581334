import RootStore from '../../../../index';

/**
 * Функция проверяет правильность введенной информации в контент LTD
 * @param catsNeededParams:
 *    [CAT_COLOR.WHITE]: {
 *       [CAT_GENDER.MALE]: {
 *         count: 2,
 *         realCount: xxx,
 *         price: 10,
 *         realPrice: xxx,
 *       },
 *       [CAT_GENDER.FEMALE]: {
 *         count: 2,
 *         realCount: xxx,
 *         price: 11,
 *         realPrice: xxx,
 *       },
 *     },
 * @param contentType - тип контента долгосрочной сделки
 * @return {boolean}
 */
const checkCatInfoForMakingLTD = ({ catsNeededParams, contentType }) => {
  const abstractContents = RootStore.appStore.LTDContentByType(
    RootStore.appStore.currentLTD.contents,
    contentType,
  ).abstract_contents;

  return Object.entries(catsNeededParams).every(([color, genders]) => {
    return Object.entries(genders).every(([gender, catInfo]) => {
      const cat = abstractContents.find((cat) => cat.color === color && cat.gender === gender);
      return catInfo.count === cat.count && catInfo.price === cat.price;
    });
  });
};

export default checkCatInfoForMakingLTD;
