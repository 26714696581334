import React from 'react';
import RootStore from './../../../store';
import { observer } from 'mobx-react';
import LTDRow from './LTDActionsModal/LTDTasksDependencies/LTDRow';
import './LTDActionsModal/LTDActions.scss';
import { CoinIcon, TeamHistoryIcon } from '../../icons';
import './style.scss';
import IconButton from '../../buttons/IconButton/IconButton';
import classNames from '../../../common/classNames';
import { LTD_CONTENT_TYPE, REQUEST_TYPE, ROOT_PATHS, SPECIALITIES } from '../../../common/constants';
import routes from '../../../routes';
import * as teamMode from '../../../store/teamMode';
import { getCatTypeText } from '../../../common/texts/catType';
import Mail from '../Mail/Mail';
import { CREDIT_TYPE_NAME, TASK_TYPES, TRANSFER_STATUS, TEAM_TASK_STATUS } from '../../../store/constants';
import corpStore from '../../../store/CorpStore';
import { catCount } from '../../../common/texts/catCount';

const TEAM_TASK_STATUSES = {
  CANCELLED: 'отменена',
  REWORK: 'доработка',
  QUEUE: 'в очереди',
  DOCTOR_EXAMINE_REQUEST: 'на осмотр',
  DOCTOR_EXAMINE_COMPLETE: 'осмотрено',
  APPROVED: 'согласовано',
  NOT_APPROVED: 'не согласовано',
  PAID: 'оплачено',
  EMPTY: '',
};

function getTaskQueue(taskId) {
  const { appStore } = RootStore;
  const task = appStore.taskById(taskId);
  const isVisibleForDoctor = task.visible_to.find((spec) => spec === SPECIALITIES.DOCTOR);
  // ToDo feed {BCC-125} закомментировано Жилиным
  // if (appStore.hasSpecialities([task.specialty_by])) {
  // }
  // ToDo feed {BCC-125} удалено на мастере (было на проверке 64 строка task.specialty_by === task.stage && isVisibleForDoctor)
  // const isItTraderSpecialities = (specStage, specBy) => {
  //   return (
  //     (specStage === teamMode.SPECIALITIES.SALES || specStage === teamMode.SPECIALITIES.SUPPLY) &&
  //     (specBy === teamMode.SPECIALITIES.SALES || specBy === teamMode.SPECIALITIES.SUPPLY)
  //   );
  // };
  const meIsDoctorInCorpOrTeamModeAndFakeDoctorReviewRequest =
    (appStore.isCorpMode || appStore.isRealTeamMode) &&
    appStore.hasSpecialities(SPECIALITIES.DOCTOR) &&
    !appStore.meIsCEO &&
    task.type === TASK_TYPES.FAKE_DOCTOR_REVIEW_REQUEST;
  let visa;
  if (!meIsDoctorInCorpOrTeamModeAndFakeDoctorReviewRequest) {
    visa = task.visas[task.specialty_by];
  }

  if (task.status === TEAM_TASK_STATUS.CONFIRMED || task.status === TEAM_TASK_STATUS.CANCELLED) {
    return task.visas[task.stage] !== undefined
      ? task.visas[task.stage]
        ? TEAM_TASK_STATUSES.APPROVED
        : TEAM_TASK_STATUSES.NOT_APPROVED
      : TEAM_TASK_STATUSES.CANCELLED;
  } else {
    if (visa === undefined) {
      if (task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST || task.type === TASK_TYPES.CITY_TRADE) {
        if (task.specialty_by === task.stage && isVisibleForDoctor) {
          return task.visas[SPECIALITIES.DOCTOR] !== undefined
            ? task.specialty_by === SPECIALITIES.DOCTOR
              ? TEAM_TASK_STATUSES.DOCTOR_EXAMINE_COMPLETE
              : TEAM_TASK_STATUSES.QUEUE
            : TEAM_TASK_STATUSES.DOCTOR_EXAMINE_REQUEST;
        }
      }
      if (appStore.hasSpecialities([task.stage])) {
        return TEAM_TASK_STATUSES.EMPTY;
      } else {
        return TEAM_TASK_STATUSES.REWORK;
      }
    } else {
      if (
        task.stage === SPECIALITIES.CAPTAIN &&
        isVisibleForDoctor &&
        (task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST || task.type === TASK_TYPES.CITY_TRADE)
      ) {
        return task.visas[SPECIALITIES.DOCTOR] !== undefined
          ? task.specialty_by === SPECIALITIES.DOCTOR
            ? TEAM_TASK_STATUSES.DOCTOR_EXAMINE_COMPLETE
            : TEAM_TASK_STATUSES.QUEUE
          : TEAM_TASK_STATUSES.DOCTOR_EXAMINE_REQUEST;
      } else {
        if (task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST || task.type === TASK_TYPES.CITY_TRADE) {
          if (task.specialty_by === SPECIALITIES.DOCTOR) {
            return TEAM_TASK_STATUSES.DOCTOR_EXAMINE_COMPLETE;
          }
        }
        if (appStore.hasSpecialities([task.stage])) {
          return TEAM_TASK_STATUSES.EMPTY;
        } else {
          return TEAM_TASK_STATUSES.QUEUE;
        }
      }
    }
  }
  // ToDo feed {BCC-125} закомментировано Жилиным
  // if (appStore.hasSpecialities([teamMode.SPECIALITIES.DOCTOR])) {
  //   if (task.visas[teamMode.SPECIALITIES.DOCTOR] !== undefined) {
  //     if (
  //       task.type === teamMode.TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST ||
  //       task.type === teamMode.TASK_TYPES.CITY_TRADE
  //     ) {
  //       return TEAM_TASK_STATUSES.DOCTOR_EXAMINE_COMPLETE;
  //     } else {
  //       return TEAM_TASK_STATUSES.QUEUE;
  //     }
  //   } else {
  //     return TEAM_TASK_STATUSES.EMPTY;
  //   }
  // }
  // return TEAM_TASK_STATUSES.QUEUE;
}

export const LTDActions = observer(() => {
  const { appStore } = RootStore;
  const sortedLTD = appStore.visibleActionsLtd;
  return (
    <>
      <div className="ltd-actions__wrapper">
        {!sortedLTD?.length ? (
          <span className="ltd-actions__body-text">Долгосрочные сделки отсутствуют</span>
        ) : (
          sortedLTD.map((ltd) => <LTDRow key={ltd.ltd_info.deal_id} ltd={ltd} />)
        )}
      </div>
    </>
  );
});

const getTaskData = (task) => {
  const { appStore } = RootStore;
  if (task.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST) {
    const catsCount = task.content.contents.reduce((sum, catData) => sum + catData.count, 0);
    const catsPrice = task.content.contents.reduce((sum, catData) => sum + catData.price * catData.count, 0);
    return {
      type: TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST,
      taskId: task.task_id,
      title: task.content.request_type === REQUEST_TYPE.BUY ? 'покупка' : 'продажа',
      desc: catCount(catsCount),
      stage: task.stage,
      price: catsPrice,
      new: appStore.openedTasks.has(task.task_id),
    };
  }
  if (task.type === TASK_TYPES.GET_CREDIT) {
    return {
      type: TASK_TYPES.GET_CREDIT,
      taskId: task.task_id,
      title: 'взятие кредита',
      stage: task.stage,
      desc: CREDIT_TYPE_NAME[task.content.credit_type],
      price: task.content.money_quantity,
      new: appStore.openedTasks.has(task.task_id),
    };
  }
  if (task.type === TASK_TYPES.CREDIT_REPAY) {
    // Так как в данном месте может как кредиты СЕО, так и команды, то мы
    // должны получить абсолютно все кредиты, которые принадлежат команде
    // Наши кредиты мы получаем через appStore.credits.credits_info,
    // Если мы являемся СЕО, то кредиты наших городов мы получаем с помощью corpStore.getInfoFromAllAreasByProperty
    // и добавляем их в список кредитов, по которому ищем информацию о кредите
    const credits = [...appStore.credits.credits_info];
    if (appStore.meIsCEO) {
      corpStore.getInfoFromAllAreasByProperty('credits').forEach((credit) => credits.push(...credit.credits_info));
    }
    const creditForRepay = credits.find((credit) => credit.credit_id === task.content.credit_id);
    return {
      type: TASK_TYPES.CREDIT_REPAY,
      taskId: task.task_id,
      title: 'погашение кредита',
      desc: CREDIT_TYPE_NAME[creditForRepay.credit_type],
      stage: task.stage,
      price: creditForRepay.early_repayment_body + creditForRepay.early_repayment_percent,
      new: appStore.openedTasks.has(task.task_id),
    };
  }
  if (task.type === TASK_TYPES.CANCEL_OWN_TRADE_REQUEST) {
    const lot = appStore.lotById(task.content.lot_id);
    if (lot) {
      const catsCount = lot.contents.reduce((sum, catData) => sum + catData.count, 0);
      const catsPrice = lot.contents.reduce((sum, catData) => sum + catData.price * catData.count, 0);
      return {
        type: TASK_TYPES.CANCEL_OWN_TRADE_REQUEST,
        taskId: task.task_id,
        title: 'Отмена собственной сделки',
        desc: catCount(catsCount),
        stage: task.stage,
        price: catsPrice,
        new: appStore.openedTasks.has(task.task_id),
      };
    } else {
      return null;
    }
  }
  if (task.type === TASK_TYPES.CHANGE_SHOWCASE_PRICES) {
    const contents = task.content.contents[0];
    const defaultShowcasePrices = task.content.showcase_prices[`${contents.color}_${contents.gender}`];
    const isBuyChange = contents.buy_price !== defaultShowcasePrices.buy_price;
    const isSellChange = contents.sell_price !== defaultShowcasePrices.sell_price;
    const kindOfChange = () => {
      if (isBuyChange && isSellChange) {
        return 'покупка и продажа';
      } else {
        return isBuyChange ? 'покупка' : 'продажа';
      }
    };
    const catType = getCatTypeText({ gender: contents.gender, color: contents.color }, ' ');
    return {
      taskId: task.task_id,
      title: 'витринные цены',
      desc: `${kindOfChange()}, ${catType}`,
      stage: task.stage,
      new: appStore.openedTasks.has(task.task_id),
    };
  }
  if (task.type === TASK_TYPES.BUY_FOOD) {
    return {
      taskId: task.task_id,
      title: 'корм',
      desc: `×${task.content.quantity}`,
      stage: task.stage,
      price: appStore.gameCosts.food * task.content.quantity,
      new: appStore.openedTasks.has(task.task_id),
    };
  }
  if (task.type === TASK_TYPES.FEED_ALL_CATS) {
    return {
      taskId: task.task_id,
      title: 'корм',
      desc: `×${
        task.content.cat_ids.length - appStore.goods.food >= 0 ? task.content.cat_ids.length - appStore.goods.food : 0
      }`,
      stage: task.stage,
      price:
        appStore.gameCosts.food * task.content.cat_ids.length - appStore.goods.food >= 0
          ? task.content.cat_ids.length - appStore.goods.food
          : 0,
      new: appStore.openedTasks.has(task.task_id),
    };
  }
  if (task.type === TASK_TYPES.BUY_HOUSE) {
    return {
      taskId: task.task_id,
      title: 'покупка домика',
      desc: `×1`,
      stage: task.stage,
      price: appStore.gameCosts.house,
      new: appStore.openedTasks.has(task.task_id),
    };
  }
  if (task.type === TASK_TYPES.BUY_HOUSE_INSURANCE) {
    return {
      taskId: task.task_id,
      title: 'страхование домика',
      desc: `×1`,
      stage: task.stage,
      price: appStore.gameCosts.insurance,
      new: appStore.openedTasks.has(task.task_id),
    };
  }
  if (task.type === TASK_TYPES.CITY_TRADE) {
    const catType = getCatTypeText(
      { gender: task.content.contents[0].gender, color: task.content.contents[0].color },
      ' ',
    );
    return {
      taskId: task.task_id,
      title: task.content.request_type === REQUEST_TYPE.BUY ? 'покупка у города' : 'продажа городу',
      desc: catType,
      stage: task.stage,
      price: task.content.contents[0].price,
      new: appStore.openedTasks.has(task.task_id),
    };
  }
  if (task.type === TASK_TYPES.BUY_DRUGS) {
    const drugCount = Object.values(task.content.drugs).reduce((acc, drug) => {
      return acc + drug;
    }, 0);
    return {
      taskId: task.task_id,
      title: 'лекарства',
      desc: `×${drugCount}`,
      stage: task.stage,
      price: appStore.gameCosts.treatment * drugCount,
      new: appStore.openedTasks.has(task.task_id),
    };
  }
  if (task.type === TASK_TYPES.MATING) {
    if (task.status === TEAM_TASK_STATUS.IN_PROGRESS) {
      const houseId = task.content.house_id;
      let houses;
      if (appStore.meIsCEO) {
        houses = corpStore.allHousesOfCorporation;
      } else {
        houses = appStore.houses;
      }
      const currentHouse = houses.find((house) => house.house_id === houseId);
      const firstCat = appStore.catById(currentHouse.left_cat_id);
      const secondCat = appStore.catById(currentHouse.right_cat_id);
      // const catTypeFirst = getCatTypeText({ gender: firstCat.gender, color: firstCat.color }, ' ');
      let matingDesc = '';
      if (firstCat.gender === 'male') {
        const catTypeFirst = getCatTypeText({ gender: firstCat.gender, color: firstCat.color }, ' ');
        const catTypeSecond = getCatTypeText({ gender: secondCat.gender, color: secondCat.color }, ' ');
        matingDesc = `котик ${catTypeFirst.split('  ')[1]} и ${catTypeSecond}`;
      } else {
        const catTypeFirst = getCatTypeText({ gender: secondCat.gender, color: secondCat.color }, ' ');
        const catTypeSecond = getCatTypeText({ gender: firstCat.gender, color: firstCat.color }, ' ');
        matingDesc = `котик ${catTypeFirst.split('  ')[1]} и ${catTypeSecond}`;
      }
      return {
        taskId: task.task_id,
        title: 'скрещивание',
        desc: matingDesc,
        stage: task.stage,
        new: appStore.openedTasks.has(task.task_id),
      };
    } else {
      return {
        taskId: task.task_id,
        title: 'скрещивание',
        desc: ``,
        stage: task.stage,
        new: appStore.openedTasks.has(task.task_id),
      };
    }
  }
  if (task.type === TASK_TYPES.FAKE_IMPORT_TASK) {
    return {
      // ToDo Ждем 2ю итерацию в release(3.1.0) необходимо добавить taskId feel BCC-466
      lotId: task.lot_id,
      type: task.type,
      content: task.content,
      title: 'постоплата импорта',
      desc: catCount(task.count),
      queue:
        task.import_status === TRANSFER_STATUS.AWAITING_PAYMENT ? TEAM_TASK_STATUSES.EMPTY : TEAM_TASK_STATUSES.PAID,
      stage: task.stage,
      price: task.post_payment_price,
      buyer: task.buyer,
      new: appStore.openedTasks.has(task.lot_id),
    };
  }

  if (task.type === TASK_TYPES.FAKE_DOCTOR_REVIEW_REQUEST) {
    let cats_count = 0;
    task.content.contents.forEach((cat) => {
      cats_count += cat.factCount;
    });
    return {
      // ToDo Ждем 2ю итерацию в release(3.1.0) необходимо добавить taskId feel BCC-466
      dealId: task.deal_id,
      type: task.type,
      ltd_action: task.ltd_action,
      partner: task.partner,
      ltd_type: task.ltd_type,
      partner_captain: task.partner_captain,
      title: 'отправка котиков на склад',
      desc: catCount(cats_count),
      queue: TEAM_TASK_STATUSES.DOCTOR_EXAMINE_REQUEST,
      stage: task.stage,
      new: appStore.openedTasks.has(task.deal_id),
    };
  }

  if (task.type === TASK_TYPES.CREATE_CHANGE_LTD_REQUEST) {
    const price = task.content.contents.reduce(
      (dealSum, deal) => dealSum + deal.abstract_contents.reduce((catSum, cat) => catSum + cat.price * cat.count, 0),
      0,
    );

    if (
      task.content.contents.length === 2 &&
      !Object.keys(task.visas).length &&
      !task.content.deal_id &&
      appStore.mySpeciality === SPECIALITIES.CAPTAIN
    ) {
      return {
        type: task.type,
        title: 'создание сделки на покупку и продажу',
        taskId: task.task_id,
        dealId: task.content.deal_id,
        stage: task.stage,
        new: appStore.openedTasks.has(task.taskId),
        price,
      };
    }

    return {
      type: task.type,
      title: 'долгосрочная сделка',
      taskId: task.task_id,
      dealId: task.content.deal_id,
      stage: task.stage,
      new: appStore.openedTasks.has(task.taskId),
      price,
    };
  }

  return {
    taskId: task.task_id,
    title: 'Необработанный тип',
    desc: task.type,
    stage: task.stage,
    price: 0,
    new: appStore.openedTasks.has(task.task_id),
  };
};

function getTasksData(tasks) {
  const tasksData = [];
  tasks.forEach((task) => {
    const taskData = getTaskData(task);
    if (taskData) {
      tasksData.push(taskData);
    }
  });
  return tasksData;
}

export function renderTasks(tasks, taskIsClickable) {
  const tasksData = getTasksData(tasks);
  const { appStore } = RootStore;
  return tasksData.map((taskData) => {
    let taskTitle;
    if (appStore.meIsCEO) {
      let companyId;
      if (taskData.type === TASK_TYPES.FAKE_IMPORT_TASK) {
        companyId = taskData.buyer;
      } else {
        companyId = appStore.taskById(taskData.taskId)?.company_id;
      }
      const areaNum = corpStore.areaNumByPlayerId(companyId);
      taskTitle = `${taskData.title}, город ${areaNum}`;
    } else {
      taskTitle = taskData.title;
    }
    return (
      <div
        className={classNames({
          'team-tasks__item': true,
          'team-tasks__item--opened': !taskData.new,
        })}
        // ToDo Ждем 2ю итерацию в release(3.1.0) необходимо обработать taskData.taskId feel BCC-466
        key={taskData.taskId}
        onClick={() => {
          if (!taskIsClickable) {
            return;
          }
          // ToDo Ждем 2ю итерацию в release(3.1.0) необходимо убрать условие и оставить только taskById feel BCC-466
          const task =
            taskData.type === TASK_TYPES.FAKE_IMPORT_TASK
              ? corpStore.taskByLotId(taskData.lotId)
              : appStore.taskById(taskData.taskId);

          if (task.type === TASK_TYPES.CREATE_CHANGE_LTD_REQUEST) {
            if (
              task.content.contents.length === 2 &&
              !task.content.deal_id &&
              task.visas.supply === undefined &&
              task.visas.sales === undefined &&
              !appStore.meIsCEO
            ) {
              if (appStore.mySpeciality === SPECIALITIES.CAPTAIN) {
                appStore.setCurrentTask(task.task_id);
              } else {
                RootStore.goTo(routes.longTermsDealsNew, {
                  uuid: task.content.to,
                  type_ltd: LTD_CONTENT_TYPE.BUY_SELL,
                  taskId: task.task_id,
                });
              }

              appStore.hideMyTasksModal();
              return;
            }

            if (window.location.pathname === `${ROOT_PATHS.LONG_DEAL}/edit`) {
              appStore.init();
              appStore.changeBackgroundMusic();
              appStore.setCurrentTaskId(task?.task_id);

              if (!task?.content.deal_id || task?.content.updated) {
                appStore.setCurrentLTD(null, task.content, task);
              } else {
                appStore.setCurrentLTD(task.content.deal_id);
              }
              if (appStore.meIsCEO) {
                corpStore.setCurrentAreaNum(null);
              }
            } else {
              RootStore.goTo(routes.longTermsDealsEdit, { task });
            }
            appStore.hideMyTasksModal();
            return;
          }

          if (
            (appStore.isCorpMode || appStore.isRealTeamMode) &&
            appStore.hasSpecialities(SPECIALITIES.DOCTOR) &&
            taskData.type === TASK_TYPES.FAKE_DOCTOR_REVIEW_REQUEST
          ) {
            RootStore.goTo(routes.storage, { uuid: taskData.partner_captain.uuid });
            appStore.setCurrentDoctorReviewingLTD(taskData.dealId, taskData.ltd_type);
            appStore.hideMyTasksModal();
            return;
          }
          // ToDo Ждем 2ю итерацию в release(3.1.0) необходимо обработать убрать условие ![TASK_TYPES.FAKE_IMPORT_TASK...] feel BCC-466
          if (
            (task.status === TEAM_TASK_STATUS.CONFIRMED || task.status === TEAM_TASK_STATUS.CANCELLED) &&
            ![TASK_TYPES.FAKE_IMPORT_TASK, TASK_TYPES.FAKE_DOCTOR_REVIEW_REQUEST].includes(taskData.type)
          ) {
            if (task.content) {
              const relatedLotId = task.content.lot_id;
              if (relatedLotId && appStore.lotById(relatedLotId)) {
                appStore.hideLot(relatedLotId);
              }
            }
          }
          // ToDo Ждем 2ю итерацию в release(3.1.0) необходимо убрать условие и сделать только appStore.setCurrentTask(taskData.taskId) feel BCC-466
          if (taskData.type === TASK_TYPES.FAKE_IMPORT_TASK) {
            appStore.setCurrentTask(taskData.lotId, true);
          } else {
            appStore.setCurrentTask(taskData.taskId);
          }
          if (
            taskData.type === TASK_TYPES.CREATE_CHANGE_TRADE_REQUEST ||
            taskData.type === TASK_TYPES.CANCEL_OWN_TRADE_REQUEST
          ) {
            RootStore.router.goTo(routes.inside, { uuid: appStore.currentPlayer.uuid });
          }
        }}
      >
        <div className="team-tasks__item--first-col">
          <Mail
            task={
              taskData.taskId
                ? appStore.taskById(taskData.taskId)
                : taskData.dealId
                ? appStore.taskByDealId(taskData.dealId)
                : corpStore.taskByLotId(taskData.lotId)
            }
          />
          <div className="team-tasks__item-title">{taskTitle}</div>
        </div>
        <div className="team-tasks__item--second-col">
          {taskData.desc}
          <span className="team-tasks__queue">
            {taskData.hasOwnProperty('queue') ? taskData.queue : getTaskQueue(taskData.taskId)}
          </span>
        </div>
        <div className="team-tasks__item--third-col">
          {!!taskData.price && (
            <>
              <span>{taskData.price}</span>
              <CoinIcon
                style={{
                  verticalAlign: 'middle',
                  marginLeft: '4px',
                  marginRight: '14px',
                  width: '24px',
                  height: '24px',
                }}
              />
            </>
          )}

          {taskData.type !== TASK_TYPES.FAKE_DOCTOR_REVIEW_REQUEST && taskData.type !== TASK_TYPES.FAKE_IMPORT_TASK && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                appStore.openTeamHistoryModal();
                appStore.hideMyTasksModal();
              }}
              color="purple"
              className="team-tasks__history-btn"
            >
              <TeamHistoryIcon props={{ style: { fill: '#FFF' } }} />
            </IconButton>
          )}
        </div>
      </div>
    );
  });
}
