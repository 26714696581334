import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import HouseModalIcon from '../icons/modal/HouseModalIcon';

function CantTreatmentModal() {
  const { appStore } = RootStore;

  if (!appStore.treatmentWithoutHomeModal) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.treatmentWithoutHomeModal}
      onClose={appStore.hideTreatmentWithoutHomeModal}
      title="Посади котика в домик"
      desc="не удобно лечить котиков на улице"
      style={{ zIndex: 101 }}
    >
      <HouseModalIcon className="modal__body-icon" />
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.hideTreatmentWithoutHomeModal} />
      </div>
    </Modal>
  );
}

export default observer(CantTreatmentModal);
