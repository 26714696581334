import Modal from '../Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import ExpectationIcon from '../../icons/modal/ExpectationIcon';
import TextButton from '../../buttons/TextButton/TextButton';

function PauseGameModal() {
  const { appStore } = RootStore;

  return (
    <Modal isOpen={appStore.isGamePaused} head={true} title="Игра приостановлена">
      <ExpectationIcon className="modal__body-icon" />
      {appStore.isSpectatorModeOn && (
        <div className="modal__body-actions modal__body-actions--btn-with-icon">
          <TextButton title="ОТМЕНА" color="purple" onClick={appStore.cancelPauseGame} />
        </div>
      )}
    </Modal>
  );
}

export default observer(PauseGameModal);
