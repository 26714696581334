import React from 'react';

function CalcIcon({ style, className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.8125 0C1.26169 0 0 1.26169 0 2.8125V14.25H14.25V0H2.8125ZM9.3125 8.0625H8.0625V9.3125C8.0625 9.83025 7.64275 10.25 7.125 10.25C6.60725 10.25 6.1875 9.83025 6.1875 9.3125V8.0625H4.9375C4.41975 8.0625 4 7.64275 4 7.125C4 6.60725 4.41975 6.1875 4.9375 6.1875H6.1875V4.9375C6.1875 4.41975 6.60725 4 7.125 4C7.64275 4 8.0625 4.41975 8.0625 4.9375V6.1875H9.3125C9.83025 6.1875 10.25 6.60725 10.25 7.125C10.25 7.64275 9.83025 8.0625 9.3125 8.0625Z"
        fill="#C6A788"
      />
      <path
        d="M29.8646 14.25H30.375V2.8125C30.375 1.26169 29.1133 0 27.5625 0H16.125V13.9119C17.9804 12.5159 20.2858 11.6875 22.7812 11.6875C25.472 11.6875 27.9418 12.6507 29.8646 14.25ZM20.125 7.125C20.125 6.60725 20.5448 6.1875 21.0625 6.1875H25.4375C25.9552 6.1875 26.375 6.60725 26.375 7.125C26.375 7.64275 25.9552 8.0625 25.4375 8.0625H21.0625C20.5448 8.0625 20.125 7.64275 20.125 7.125Z"
        fill="#C6A788"
      />
      <path
        d="M13.9119 16.125H0V27.5625C0 29.1133 1.26169 30.375 2.8125 30.375H14.25V29.8646C12.6507 27.9418 11.6875 25.4719 11.6875 22.7812C11.6875 20.2858 12.5159 17.9804 13.9119 16.125ZM9.35038 24.1496C9.7165 24.5158 9.7165 25.1093 9.35038 25.4754C9.16738 25.6585 8.92744 25.75 8.6875 25.75C8.44756 25.75 8.20762 25.6585 8.02462 25.4754L7.125 24.5758L6.22538 25.4754C6.04238 25.6585 5.80244 25.75 5.5625 25.75C5.32256 25.75 5.08262 25.6585 4.89962 25.4754C4.5335 25.1092 4.5335 24.5157 4.89962 24.1496L5.79919 23.25L4.89956 22.3504C4.53344 21.9842 4.53344 21.3907 4.89956 21.0246C5.26562 20.6584 5.85925 20.6584 6.22538 21.0246L7.125 21.9242L8.02462 21.0246C8.39069 20.6584 8.98431 20.6584 9.35044 21.0246C9.71656 21.3907 9.71656 21.9842 9.35044 22.3504L8.45081 23.25L9.35038 24.1496Z"
        fill="#C6A788"
      />
      <path
        d="M22.7812 13.5625C17.698 13.5625 13.5625 17.698 13.5625 22.7812C13.5625 27.8645 17.698 32 22.7812 32C27.8645 32 32 27.8645 32 22.7812C32 17.698 27.8645 13.5625 22.7812 13.5625ZM24.9688 25.2812H20.5938C20.076 25.2812 19.6562 24.8615 19.6562 24.3438C19.6562 23.826 20.076 23.4062 20.5938 23.4062H24.9688C25.4865 23.4062 25.9062 23.826 25.9062 24.3438C25.9062 24.8615 25.4865 25.2812 24.9688 25.2812ZM24.9688 22.1562H20.5938C20.076 22.1562 19.6562 21.7365 19.6562 21.2188C19.6562 20.701 20.076 20.2812 20.5938 20.2812H24.9688C25.4865 20.2812 25.9062 20.701 25.9062 21.2188C25.9062 21.7365 25.4865 22.1562 24.9688 22.1562Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default CalcIcon;
