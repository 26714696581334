import React from 'react';
import Modal from './Modal/Modal';
import { observer } from 'mobx-react';
import TextButton from '../buttons/TextButton/TextButton';
import WalletIcon from '../icons/modal/WalletIcon';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import { ALLOCATION_TYPES } from '../../store/constants';
import corpStore from '../../store/CorpStore';

export const ALLOCATION_CONFIRMATION_TITLES = {
  [ALLOCATION_TYPES.ALLOCATION]: 'Хотите выделить бюджет?',
  [ALLOCATION_TYPES.WITHDRAWAL]: 'Хотите изъять средства из бюджета?',
};

export const ALLOCATION_BUTTONS = {
  [ALLOCATION_TYPES.ALLOCATION]: 'Выделить',
  [ALLOCATION_TYPES.WITHDRAWAL]: 'Забрать',
};

const BudgetAllocationConfirmationModal = () => {
  const data = corpStore.budgetAllocationConfirmationModalData;

  if (!data) {
    return null;
  }

  let areaNum = corpStore.areaNumByCaptainId(data.captainId);

  let desc;
  if (data.type === ALLOCATION_TYPES.ALLOCATION) {
    desc = 'В город ' + areaNum;
  } else if (data.type === ALLOCATION_TYPES.WITHDRAWAL) {
    desc = 'В городе ' + areaNum;
  }

  return (
    <Modal
      isOpen={!!data}
      onClose={() => corpStore.hideBudgetAllocationConfirmationModal()}
      title={ALLOCATION_CONFIRMATION_TITLES[data.type]}
      desc={desc}
      customClass="budget-allocation-confirmation"
    >
      <WalletIcon className="modal__body-icon" color="#C6A788" />

      <div className="modal__body-price">
        <p className="modal__body-price-text">итого сумма</p>
        <p className="modal__body-price-coin">
          <CoinModalIcon className="modal__body-price-coin-icon" />
          <span className="modal__body-price-coin-count">{data.money}</span>
        </p>
      </div>

      <div className="modal__body-actions">
        <TextButton
          title={ALLOCATION_BUTTONS[data.type]}
          color="green"
          onClick={() => {
            corpStore.budgetAllocation(data.captainId, data.money, data.type === ALLOCATION_TYPES.ALLOCATION);
            if (data.callbackFn) {
              data.callbackFn();
            }
            corpStore.hideBudgetAllocationConfirmationModal();
          }}
        />
        <TextButton title="Отмена" color="blue" onClick={() => corpStore.hideBudgetAllocationConfirmationModal()} />
      </div>
    </Modal>
  );
};

export default observer(BudgetAllocationConfirmationModal);
