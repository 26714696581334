import React from 'react';

function EatIconDefault({ style, stylePath, className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0433 1.76838C17.7888 2.2084 15.7791 3.23008 14.5773 4.03793C13.3755 4.84623 12.1899 5.50741 11.9423 5.50741C11.6952 5.50741 10.1094 6.15487 8.41882 6.94581C4.85349 8.61405 4.57982 9.40132 7.06489 10.8411L8.77847 11.834L7.88228 17.3359L5.78791 17.8463C0.836677 19.0516 -0.38672 21.1508 2.64388 23.2403C3.91635 24.1171 4.60907 24.2807 6.24478 24.0906L8.27028 23.8544V26.0705C8.27028 29.9004 11.1033 32.4483 12.743 30.0933C13.1468 29.5134 13.7886 27.9599 14.169 26.6403C14.9211 24.0326 15.9262 23.5025 16.9534 25.1721C18.6877 27.9909 23.2937 26.6471 23.3346 23.3102C23.3445 22.5032 23.9706 21.6228 25.3925 20.4165C29.065 17.2998 31.0014 12.9521 31 7.82495C30.9996 4.26229 29.9747 1.80127 28.3003 1.33932C26.1312 0.741657 21.3361 0.963736 19.0433 1.76838ZM24.1593 4.68493C25.687 6.23574 23.8906 8.81966 21.9987 7.79203C20.4098 6.92889 21.0976 4.13662 22.899 4.13662C23.2951 4.13662 23.8622 4.38336 24.1593 4.68493ZM19.5659 12.2777C20.5143 13.0714 22.1077 14.0154 23.1074 14.3759L24.9245 15.0306L22.899 15.0649C19.5105 15.1225 17.0313 13.0503 16.384 9.61973L16.082 8.02049L16.9615 9.42783C17.4454 10.2019 18.617 11.484 19.5659 12.2777Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default EatIconDefault;
