import React from 'react';
import './style.scss';
import classNames from '../../../common/classNames';
function Avatar({ props, avatarImage, style, lotArea = false, my = false }) {
  return (
    <span
      className={classNames({
        avatar: true,
        'avatar--lot-area': lotArea,
        'avatar--lot-area--my': my,
      })}
      style={{ background: `url(${avatarImage})`, ...style }}
      {...props}
    />
  );
}

export default Avatar;
