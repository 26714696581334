import _ from 'lodash';
import RootStore from '../store';

const STATIC_TYPE = {
  PET_SHOP: 'pet-shop',
  NURSERY: 'nursery',
};
const SUB_FOLDER_TYPE = {
  PET_SHOPS: 'pet-shops',
  NURSERIES: 'nurseries',
};
const seasons = ['summer', 'fall', 'winter', 'spring'];
const prefix = {
  '1x': '1x.png',
  '2x': '2x.png',
};

const onLoadStatic = async ({ path, subFolder }) => {
  const src = await import(`../assets/${subFolder}/${path}`);
  return src.default;
};

const petShopsImagesLoad = async () => {
  let result = {};
  const seasonPromises = seasons.map(async (season) => {
    result[season] = await Promise.all(
      _.range(0, 5, 1).map(async (index) => {
        return {
          '1x': await onLoadStatic({
            path: `${season}/${STATIC_TYPE.PET_SHOP}-${index + 1}-${prefix['1x']}`,
            subFolder: SUB_FOLDER_TYPE.PET_SHOPS,
          }),
          '2x': await onLoadStatic({
            path: `${season}/${STATIC_TYPE.PET_SHOP}-${index + 1}-${prefix['2x']}`,
            subFolder: SUB_FOLDER_TYPE.PET_SHOPS,
          }),
        };
      }),
    );
  });
  await Promise.all(seasonPromises);
  return result;
};

const nurseriesImagesLoad = async () => {
  let result = {};
  const seasonPromises = seasons.map(async (season) => {
    result[season] = await Promise.all(
      _.range(0, 20, 1).map(async (index) => {
        return {
          '1x': await onLoadStatic({
            path: `${season}/nursery-${index + 1}-${prefix['1x']}`,
            subFolder: SUB_FOLDER_TYPE.NURSERIES,
          }),
          '2x': await onLoadStatic({
            path: `${season}/nursery-${index + 1}-${prefix['2x']}`,
            subFolder: SUB_FOLDER_TYPE.NURSERIES,
          }),
        };
      }),
    );
  });
  await Promise.all(seasonPromises);
  return result;
};

let petShopsImagesPromise = null;
let houseStatic = null;
export const getPetShopsImages = () => {
  const { appStore } = RootStore;
  return houseStatic[appStore.currentSeasonType];
};

let nurseriesImagesPromise = null;
let nurseriesImages = null;
export const getNurseriesImages = () => {
  const { appStore } = RootStore;
  return nurseriesImages[appStore.currentSeasonType];
};

export const houseStaticLoad = async () => {
  if (petShopsImagesPromise && nurseriesImagesPromise) {
    await Promise.all([petShopsImagesPromise, nurseriesImagesPromise]);
  }
  petShopsImagesPromise = petShopsImagesLoad();
  nurseriesImagesPromise = nurseriesImagesLoad();
  houseStatic = await petShopsImagesPromise;
  nurseriesImages = await nurseriesImagesPromise;
};
