import React from 'react';
import Modal from './Modal/Modal';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import RingwormModalIcon from '../icons/modal/RingwormModalIcon';
import CoinModalIcon from '../icons/modal/CoinModalIcon';

function RingwormModal() {
  const { appStore } = RootStore;

  if (!appStore.ringwormData) {
    return null;
  }

  return (
    <Modal isOpen={!!appStore.ringwormData} title="хочешь намазать котика мазью?" style={{ zIndex: 1000 }}>
      <div className="modal__body-price">
        <RingwormModalIcon />
        {!appStore.isTeamMode && (
          <>
            <p className="modal__body-price-text">ЦЕНА мази</p>
            <p className="modal__body-price-coin">
              <CoinModalIcon className="modal__body-price-coin-icon" />
              <span className="modal__body-price-coin-count notranslate">{appStore.ringwormData?.insured ? 0 : 2}</span>
            </p>
          </>
        )}
      </div>
      <div className="modal__body-actions">
        <TextButton color="green" title="намазать" onClick={() => appStore.catTreatmentSend(appStore.ringwormData)} />
        <TextButton color="purple" title="отмена" onClick={appStore.hideTreatmentActionModal} />
      </div>
    </Modal>
  );
}

export default observer(RingwormModal);
