import Modal from './Modal/Modal';
import React from 'react';
import WarningIcon from '../icons/modal/WarningModalIcon';

function UnsupportedBrowserModal() {
  return (
    <Modal isOpen shouldCloseOnOverlayClick={true} customClass="end-turn-confirm">
      <WarningIcon className="modal__body-icon" />
      <div className="modal__header">
        <div className="modal__title">Внимание!</div>
        <div className="modal__desc">
          Наша игра не поддерживается браузером internet explorer. <br /> Необходимо изменить браузер для начала игры
        </div>
      </div>
    </Modal>
  );
}

export default UnsupportedBrowserModal;
