import React from 'react';
import corpStore from '../../../store/CorpStore';
import classNames from '../../../common/classNames';
import CurrentTaskModalContentPrice from './CurrentTaskModalContentPrice';
import { currentTaskModalPriceLimitTextsByLimitType } from './CurrentTaskModalFunctions';

const CurrentTaskModalPriceLimitExceeded = ({
  taskConstructor,
  currentTask,
  exceededLimit,
  withDeviationShowcaseLimit,
}) => {
  if (!corpStore.currentTaskExceededLimits || !corpStore.currentTaskExceededLimits.length) {
    return null;
  }
  const Content = () => {
    const limitType = exceededLimit[0];
    const limitInfo = exceededLimit[1];
    return currentTaskModalPriceLimitTextsByLimitType(limitType).map((text, priceElementIndex) => {
      let currentPrice;
      if (priceElementIndex === 0) {
        currentPrice = limitInfo.limit_value;
      } else if (priceElementIndex === 1) {
        currentPrice = limitInfo.expenses;
      } else {
        currentPrice = taskConstructor.price;
      }
      return <CurrentTaskModalContentPrice key={priceElementIndex} text={text} price={currentPrice} />;
    });
  };
  return (
    <div
      className={classNames({
        'current-task-price': true,
        'current-task-price--limits': true,
        'current-task-price--limits-with-deviation-showcase-limits': withDeviationShowcaseLimit,
      })}
    >
      <Content />
    </div>
  );
};

export default CurrentTaskModalPriceLimitExceeded;
