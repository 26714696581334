import React from 'react';
import { observer } from 'mobx-react';
import { OperationPanelSighting, OperationPanelInfo, OperationPanelActions } from './index';

function OperationPanel() {
  return (
    <div className="lot-area__lot-panel">
      <div className="lot-area__lot-panel__wrap">
        <OperationPanelSighting />
        <OperationPanelInfo />
        <OperationPanelActions />
      </div>
    </div>
  );
}

export default observer(OperationPanel);
