import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import { REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT, ROLE_NAME } from '../../store/constants';
import Cat, { BOTTOM_TEXT, CAT_STATE } from './Cat/Cat';
import TextButton from '../buttons/TextButton/TextButton';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import { SPECIALITIES } from '../../common/constants';

const TRADE_WITH_BOT_ERROR_TEXT = {
  [REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT.BAD_RELATIONSHIP]: <div>отношения с ботом испорчены!</div>,
  [REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT.SICK_CATS]: (
    <div>
      бот не покупает больных <br /> котиков!
    </div>
  ),
  [REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT.NOT_ENOUGH_MONEY]: (
    <div>
      у бота недостаточно <br /> средств для сделки!
    </div>
  ),
  [REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT.NOT_ENOUGH_CATS]: (
    <div>
      у бота недостаточно <br />
      котиков!
    </div>
  ),
  [REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT.ALREADY_BOUGHT]: (
    <div>
      бот уже приобрел <br />
      нужных котиков!
    </div>
  ),
  [REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT.UNINTERESTING_CATS]: (
    <div>
      бот не заинтересован <br />в этих котиках!
    </div>
  ),
  [REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT.TOO_MANY_CATS]: (
    <div>
      бот не продает котиков <br /> в таком количестве!
    </div>
  ),
  [REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT.DEVIATION_PRICE]: <div>Отклонено от витриной цены</div>,
  [REASONS_FOR_REFUSING_TO_SELL_TO_A_BOT.DEVIATION_CITY_PRICE]: (
    <div>
      ваше предложение <br /> не соответствует <br /> текущим рыночным <br /> условиям города!
    </div>
  ),
};

function NotificationModal() {
  const { appStore } = RootStore;

  if (!appStore.notificationModalData) {
    return;
  }

  const opponent =
    appStore.me.uuid === appStore.notificationModalData.seller
      ? appStore.playerByUuid(appStore.notificationModalData.buyer)
      : appStore.playerByUuid(appStore.notificationModalData.seller);
  const Logo = opponent.logo;

  let title;
  // переворачиваем массив, т.к. он берет первый попавшийся элемент подходящий по условию,
  // поэтому модальное окно отображается только у первого открывшего конверт, также создаём копию, избегая изменений исходного массива
  let tasksReversed = appStore.tasks.slice().reverse();
  let task = tasksReversed.find((task) => task.content?.lot_id === appStore.notificationModalData?.lot_id);
  if (appStore.notificationModalData.cancel_reason) {
    title = TRADE_WITH_BOT_ERROR_TEXT[appStore.notificationModalData.cancel_reason];
  } else if (!appStore.isTeamMode || (task && appStore.isTeamMode && task.visible_to.includes(appStore.mySpeciality))) {
    const dealType = appStore.notificationModalData.buyer === appStore.me.uuid ? 'Покупка' : 'Продажа';
    const dealStatus = appStore.notificationModalData.status === 'cancelled' ? 'Отменена' : 'Одобрена';
    title = `${dealType} ${dealStatus}`;
  }

  if (!title) {
    return;
  }

  const color = appStore.notificationModalData.status === 'cancelled' ? 'red' : 'green';
  const desc = `в ${ROLE_NAME[opponent.role]} "${opponent.name}"`;
  const [cats, price] = appStore.groupCats(appStore.notificationModalData, price);

  return (
    <Modal
      isOpen={!!appStore.notificationModalData}
      onClose={appStore.notificationModalClose}
      title={title}
      desc={desc}
      size="cats"
      colorBg={color}
      Logo={Logo}
    >
      <div className="modal__body-wrapper">
        <div className="modal__body-cats">
          {cats.map((item, index) => (
            <Cat
              key={index}
              cat={item}
              readonly
              state={CAT_STATE.SINGLE}
              bottomText={BOTTOM_TEXT.KIND}
              realCount={true}
            />
          ))}
        </div>
        <div className="modal__body-price">
          <p className="modal__body-price-text">итого цена</p>
          <p className="modal__body-price-coin">
            <CoinModalIcon className="modal__body-price-coin-icon" />
            <span className="modal__body-price-coin-count notranslate">{price}</span>
          </p>
        </div>
      </div>
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={appStore.notificationModalClose} />
      </div>
    </Modal>
  );
}

export default observer(NotificationModal);
