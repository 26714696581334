import React from 'react';

function TeamDoctorIcon({ isActive = false, isPending = false, width = '24', height = '24' }) {
  return (
    <>
      <svg
        style={{ position: 'relative' }}
        width={width}
        height={height}
        viewBox="0 0 10 10"
        fill={isActive ? '#FDF6EE' : '#C0A88F'}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 0.857864C5.54928 0.308583 6.29427 0 7.07107 0C7.84787 0 8.59285 0.308583 9.14213 0.857864C9.69142 1.40715 10 2.15213 10 2.92893C10 3.70573 9.69142 4.45072 9.14213 5L5 9.14213C4.72802 9.41411 4.40514 9.62986 4.04979 9.77705C3.69443 9.92424 3.31357 10 2.92893 10C2.15213 10 1.40715 9.69142 0.857864 9.14213C0.308583 8.59285 0 7.84787 0 7.07107C0 6.29427 0.308583 5.54928 0.857864 5L5 0.857864ZM6.77568 6.18323L8.54968 4.40839C8.93923 4.01553 9.15729 3.48437 9.15616 2.93112C9.15502 2.37788 8.93479 1.84761 8.54364 1.45636C8.15249 1.0651 7.62229 0.844713 7.06905 0.843424C6.51581 0.842135 5.98458 1.06005 5.59161 1.44948L3.81677 3.22599L6.77568 6.18406V6.18323ZM4.87783 7.38821C4.95639 7.30965 5.00053 7.20309 5.00053 7.09199C5.00053 6.98088 4.95639 6.87433 4.87783 6.79576C4.79926 6.7172 4.69271 6.67306 4.5816 6.67306C4.4705 6.67306 4.36394 6.7172 4.28538 6.79576L3.03018 8.05095C2.99128 8.08986 2.96043 8.13604 2.93937 8.18686C2.91832 8.23769 2.90748 8.29217 2.90748 8.34718C2.90748 8.40219 2.91832 8.45667 2.93937 8.5075C2.96043 8.55832 2.99128 8.6045 3.03018 8.6434C3.10875 8.72197 3.2153 8.76611 3.32641 8.76611C3.38142 8.76611 3.4359 8.75527 3.48673 8.73422C3.53755 8.71316 3.58373 8.68231 3.62264 8.6434L4.87783 7.38821Z"
          fill="inherit"
        />
      </svg>
      {isPending && (
        <svg
          className="team-doctor__pending"
          width="17"
          height="17"
          viewBox="0 0 12 12"
          fill="#33748B"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="6" cy="6" r="6" fill="#FDF6EE" />
          <path
            d="M10 6C10 3.7944 8.2056 2 6 2C3.7944 2 2 3.7944 2 6C2 8.2056 3.7944 10 6 10C8.2056 10 10 8.2056 10 6ZM7.07227 7.0707C6.96069 7.18229 6.81444 7.2381 6.66823 7.2381C6.52202 7.2381 6.37573 7.18232 6.26419 7.0707L5.59752 6.40404C5.49036 6.29688 5.43013 6.15154 5.43013 6V5.02857C5.43013 4.71299 5.68598 4.45714 6.00156 4.45714C6.31714 4.45714 6.57299 4.71299 6.57299 5.02857V5.76331L7.07227 6.26263C7.29547 6.48575 7.29547 6.84758 7.07227 7.0707Z"
            fill="#33748B"
          />
        </svg>
      )}
    </>
  );
}

export default TeamDoctorIcon;
