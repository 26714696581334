import React from 'react';
import '../../layouts/NurseriesLayout/style.scss';
import DragElement from '../../../modules/DragElement';
import classNames from '../../../common/classNames';
import RootStore from '../../../store';
import trainingStore from '../../../store/trainingStore';

function NurseriesLayout({ children }) {
  const { appStore } = RootStore;
  return (
    <>
      <div
        className={classNames({
          'nurseries-layout nurseries-layout--bg': true,
          [`season--${appStore.currentSeasonType}`]: !trainingStore.launched,
        })}
      >
        {children}
      </div>
      <DragElement />
    </>
  );
}

export default NurseriesLayout;
