import Modal from './Modal/Modal';
import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import trainingStore from '../../store/trainingStore';
import Cat, { BOTTOM_TEXT, CAT_STATE } from './Cat/Cat';
import './catUnderMagnifierModal.scss';
import classNames from '../../common/classNames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getCatTypeText } from '../../common/texts/catType';
import SicknessFleas from '../icons/SicknessFleas';
import SicknessPoisoning from '../icons/SicknessPoisoning';
import SicknessFracture from '../icons/SicknessFracture';
import SicknessRingworm from '../icons/SicknessRingworm';
import CheckMarkIcon from '../icons/CheckMarkIcon';
import {
  LOW_QUALITY_CRITERIES,
  LTD_ACTIONS,
  PLAYER_ROLE,
  ROOT_PATHS,
  SICKNESS,
  SPECIALITIES,
} from '../../common/constants';

const text = {
  [LOW_QUALITY_CRITERIES.HUNGRY_CATS]: 'Голодный котик',
  [LOW_QUALITY_CRITERIES.SICK_CATS]: 'Больной котик',
  [LOW_QUALITY_CRITERIES.SICK_AND_HUNGRY_CATS]: 'Голодный и больной котик',
};

const InsideCat = observer(({ cat }) => {
  const { appStore } = RootStore;
  return (
    <Cat
      readonly={true}
      forceViewSickness={true}
      notShowCount={true}
      hideBackground={true}
      pinkBackground={
        appStore.catForceViewSicknessInLTD(cat) ||
        (appStore.isCatExamined(cat.cat_id) && cat.sickness !== SICKNESS.NONE)
      }
      cat={cat}
      state={CAT_STATE.SINGLE}
      showHouseIcon={appStore.me.role === PLAYER_ROLE.NURSERY}
      bottomText={BOTTOM_TEXT.AGE}
    />
  );
});

const InsideCatMemo = React.memo(InsideCat, (prevProps, nextProps) => {
  return _.isEqual(_.pick(prevProps, ['cat']), _.pick(nextProps, ['cat']));
});

function CatUnderMagnifierModal() {
  const swiperRef = useRef(null);
  const { appStore } = RootStore;
  const cats = appStore.magnifierCats;

  if (!cats) {
    return null;
  }
  const catUnderMagnifier = cats[appStore.catUnderMagnifierIndex];

  if (!catUnderMagnifier) {
    return null;
  }

  const isCatBeenExamined = (cat_id) => {
    if (meIsDoctorAndIAmReviewingStorageInCorpOrTeamMode) {
      const isOurStorageReviewing =
        appStore.currentDoctorReviewingLTD.ltd_action.action_type === LTD_ACTIONS.TO_STORAGE;
      if (isOurStorageReviewing) {
        return appStore.isCatExamined(cat_id);
      } else {
        return appStore.isCatExaminationInLTD(cat_id);
      }
    } else if (actionType) {
      return appStore.isCatExaminationInLTD(cat_id);
    } else {
      return appStore.isCatExamined(cat_id);
    }
  };

  const isTeamMode = appStore.isTeamMode;

  const actionLTD = appStore.me.actionLTD && appStore.me.actionLTD;
  const actionType = actionLTD && actionLTD.actionDetail.action_type;
  const ltd_ID = actionLTD && actionLTD.ltd_info.deal_id;
  const isLiabilityLowQualityLTD = actionLTD && appStore.me.actionLTD.ltd_info.low_quality_liability;
  const lowQualityCriteries = actionLTD && appStore.me.actionLTD.ltd_info.low_quality_type;

  const Sickness = () => {
    if (isStorageReviewing) {
      if (
        [currentDoctorReviewingLTDActionType, actionType].includes(LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT) ||
        appStore.me.role === PLAYER_ROLE.SHOP ||
        ((lowQualityCriteries === LOW_QUALITY_CRITERIES.SICK_AND_HUNGRY_CATS ||
          lowQualityCriteries === LOW_QUALITY_CRITERIES.HUNGRY_CATS) &&
          catUnderMagnifier.sickness === SICKNESS.NONE)
      ) {
        return null;
      }
    }

    return (
      <div
        className={classNames({
          'cat-under-magnifier__buttons': true,
          'training-disabled': trainingStore.launched && trainingStore.currentStep?.isDisabledCatUnderMagnifierButtons,
        })}
      >
        <span
          className={classNames({
            'cat-under-magnifier__button': true,
            'cat-under-magnifier__button--active': catUnderMagnifier.sickness === SICKNESS.RINGWORM,
          })}
          onClick={() => {
            appStore.catTreatment(catUnderMagnifier.cat_id, SICKNESS.RINGWORM);
          }}
        >
          {/*RINGWORM === salve*/}
          {/*лишай*/}
          <SicknessRingworm />
        </span>
        <span
          className={classNames({
            'cat-under-magnifier__button': true,
            'cat-under-magnifier__button--active': catUnderMagnifier.sickness === SICKNESS.FRACTURE,
          })}
          onClick={() => {
            appStore.catTreatment(catUnderMagnifier.cat_id, SICKNESS.FRACTURE);
          }}
        >
          {/*FRACTURE === bandage*/}
          {/*перелом*/}
          <SicknessFracture />
        </span>
        <span
          className={classNames({
            'cat-under-magnifier__button': true,
            'cat-under-magnifier__button--active': catUnderMagnifier.sickness === SICKNESS.POISONING,
          })}
          onClick={() => {
            appStore.catTreatment(catUnderMagnifier.cat_id, SICKNESS.POISONING);
          }}
        >
          {/*POISONING === pills*/}
          {/*отравление*/}
          <SicknessPoisoning />
        </span>
        <span
          className={classNames({
            'cat-under-magnifier__button': true,
            'cat-under-magnifier__button--active': catUnderMagnifier.sickness === SICKNESS.FLEAS,
          })}
          onClick={() => {
            appStore.catTreatment(catUnderMagnifier.cat_id, SICKNESS.FLEAS);
          }}
        >
          {/*FLEAS === shampoo*/}
          {/*блохи*/}
          <SicknessFleas />
        </span>
      </div>
    );
  };

  const currentDoctorReviewingLTD = appStore.currentDoctorReviewingLTD;
  const meIsDoctorAndIAmReviewingStorageInCorpOrTeamMode =
    (appStore.isCorpMode || appStore.isRealTeamMode) &&
    appStore.hasSpecialities(SPECIALITIES.DOCTOR) &&
    currentDoctorReviewingLTD;
  const currentDoctorReviewingLTDAction = meIsDoctorAndIAmReviewingStorageInCorpOrTeamMode
    ? currentDoctorReviewingLTD.ltd_action
    : null;
  const currentDoctorReviewingLTDActionType = currentDoctorReviewingLTDAction
    ? currentDoctorReviewingLTDAction.action_type
    : null;
  const isStorageReviewing = RootStore.router.currentRoute.rootPath === ROOT_PATHS.STORAGE;
  const isOurNurseryReviewing =
    !appStore.currentPlayer &&
    appStore.me.role === PLAYER_ROLE.NURSERY &&
    RootStore.router.currentRoute.rootPath === ROOT_PATHS.NURSERY;
  const meIsDoctorInTeamOrCorpMode = isTeamMode && appStore.hasSpecialities(SPECIALITIES.DOCTOR);
  const isSoloModeStorageReviewingWithLowQualityLiability =
    (appStore.isSoloMode || appStore.isRealTimeMode) &&
    isLiabilityLowQualityLTD &&
    actionType === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT;
  const isSoloLTD = (appStore.isSoloMode || appStore.isRealTimeMode) && isStorageReviewing && actionType;

  const lowQualityRealtimeInspection = () => {
    if (lowQualityCriteries === LOW_QUALITY_CRITERIES.HUNGRY_CATS) {
      return catUnderMagnifier.hungry === true;
    }
    if (lowQualityCriteries === LOW_QUALITY_CRITERIES.SICK_CATS) {
      return catUnderMagnifier.sickness !== SICKNESS.NONE;
    }
    if (lowQualityCriteries === LOW_QUALITY_CRITERIES.SICK_AND_HUNGRY_CATS) {
      return catUnderMagnifier.sickness !== SICKNESS.NONE || catUnderMagnifier.hungry === true;
    }
  };
  const lowQualityCatText = () => {
    if (isTeamMode) return LOW_QUALITY_CRITERIES.SICK_CATS;
    if (lowQualityCriteries === LOW_QUALITY_CRITERIES.HUNGRY_CATS && catUnderMagnifier.hungry === true) {
      return LOW_QUALITY_CRITERIES.HUNGRY_CATS;
    }
    if (lowQualityCriteries === LOW_QUALITY_CRITERIES.SICK_CATS && catUnderMagnifier.sickness !== SICKNESS.NONE) {
      return LOW_QUALITY_CRITERIES.SICK_CATS;
    }
    if (lowQualityCriteries === LOW_QUALITY_CRITERIES.SICK_AND_HUNGRY_CATS && catUnderMagnifier) {
      return catUnderMagnifier.sickness !== SICKNESS.NONE && catUnderMagnifier.hungry === true
        ? LOW_QUALITY_CRITERIES.SICK_AND_HUNGRY_CATS
        : catUnderMagnifier.sickness !== SICKNESS.NONE
        ? LOW_QUALITY_CRITERIES.SICK_CATS
        : LOW_QUALITY_CRITERIES.HUNGRY_CATS;
    }
  };

  return (
    <Modal
      isOpen={!!catUnderMagnifier}
      onClose={appStore.catUnderMagnifierModalClose}
      title={getCatTypeText(catUnderMagnifier)}
      shouldCloseOnOverlayClick={trainingStore.currentStep?.needOverlayClickModal ?? true}
    >
      <span
        className={classNames({
          'cat-under-magnifier-arrow': true,
          'cat-under-magnifier-arrow--disabled': appStore.catUnderMagnifierIndex === 0,
        })}
        onClick={() => {
          swiperRef.current.slidePrev();
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M24.9909 15.7828C25.0258 7.77437 24.9573 1.06035 24.8386 0.863565C24.4197 0.167181 23.0045 0.522558 20.2865 2.00703C12.2555 6.39263 3 13.6382 3 15.5392C3 16.7925 6.02751 19.6478 11.6233 23.6713C16.3971 27.104 22.9429 30.7144 24.026 30.5124C24.8746 30.3543 24.9317 29.4855 24.9909 15.7828Z"
            fill="#FDF6EE"
          />
        </svg>
      </span>
      <span
        className={classNames({
          'cat-under-magnifier-arrow cat-under-magnifier-arrow--right': true,
          'cat-under-magnifier-arrow--disabled': appStore.catUnderMagnifierIndex === cats.length - 1,
        })}
        onClick={() => {
          swiperRef.current.slideNext();
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M24.9909 15.7828C25.0258 7.77437 24.9573 1.06035 24.8386 0.863565C24.4197 0.167181 23.0045 0.522558 20.2865 2.00703C12.2555 6.39263 3 13.6382 3 15.5392C3 16.7925 6.02751 19.6478 11.6233 23.6713C16.3971 27.104 22.9429 30.7144 24.026 30.5124C24.8746 30.3543 24.9317 29.4855 24.9909 15.7828Z"
            fill="#FDF6EE"
          />
        </svg>
      </span>
      {catUnderMagnifier && (
        <div className="cat-under-magnifier">
          <span className="cat-under-magnifier__arrow" />
          <span className="cat-under-magnifier__arrow cat-under-magnifier__arrow--right" />
          <Swiper
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            touchStartPreventDefault={false}
            threshold={0}
            touchAngle={40}
            onSlideChange={(data) => appStore.setCatUnderMagnifierIndex(data.activeIndex)}
            initialSlide={appStore.catUnderMagnifierIndex}
            freeModeMomentumRatio={0.2}
            freeModeMomentumVelocityRatio={0.2}
            slidesPerView={1}
            grabCursor={true}
            centerInsufficientSlides={true}
            virtual
            lazy
          >
            {cats.map(
              (cat, index) =>
                cat && (
                  <SwiperSlide key={cat.detail ? cat.detail.cat_id : cat.cat_id} virtualIndex={index}>
                    <InsideCatMemo cat={cat} isCurrent={cat === catUnderMagnifier} />
                  </SwiperSlide>
                ),
            )}
          </Swiper>
          {(appStore.isRealTimeMode && isStorageReviewing
            ? lowQualityRealtimeInspection()
            : catUnderMagnifier.sickness !== SICKNESS.NONE) &&
            (isStorageReviewing || isOurNurseryReviewing) && (
              <>
                {(meIsDoctorInTeamOrCorpMode || isSoloModeStorageReviewingWithLowQualityLiability) && (
                  <div className="cat-under-magnifier__examine">
                    <div
                      className="cat-under-magnifier__examine-check"
                      onClick={() => {
                        if (meIsDoctorAndIAmReviewingStorageInCorpOrTeamMode) {
                          if (
                            currentDoctorReviewingLTD.ltd_action.action_type === LTD_ACTIONS.PAYMENT_BEFORE_SHIPMENT
                          ) {
                            appStore.examineStoragePartnerCatUnderMagnifier(catUnderMagnifier);
                          } else {
                            appStore.examineCatUnderMagnifier(catUnderMagnifier);
                          }
                        } else if (isSoloLTD) {
                          appStore.addCatExaminationInLTD(catUnderMagnifier, ltd_ID);
                        } else {
                          appStore.examineCatUnderMagnifier(catUnderMagnifier);
                        }
                      }}
                    >
                      {isCatBeenExamined(catUnderMagnifier.cat_id) && <CheckMarkIcon width={18} height={19} />}
                    </div>
                    {text[lowQualityCatText()]}
                  </div>
                )}
                <Sickness />
              </>
            )}
        </div>
      )}
    </Modal>
  );
}

export default observer(CatUnderMagnifierModal);
