import React from 'react';

function ArrowRightIcon({ style, fill, className }) {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill={fill ? fill : '#FDF6EE'}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M23.3333 12.7697C23.3333 11.7029 18.7965 7.61512 14.1094 4.45836C12.6189 3.45466 12.4013 3.65685 12.4013 6.04463L12.4013 8.24188L11.1202 8.43104C10.4155 8.53487 8.37871 8.784 6.59373 8.98446C4.80875 9.18493 2.84886 9.42893 2.23804 9.52659L1.12777 9.70408L1.12777 12.574C1.12777 15.9162 0.499863 15.507 6.93535 16.3592C12.9103 17.1508 12.4013 16.8634 12.4013 19.4458C12.4013 20.7442 12.5479 21.6667 12.7539 21.6667C14.2191 21.6667 23.3333 14.0016 23.3333 12.7697Z"
        fill="inherit"
      />
    </svg>
  );
}

export default ArrowRightIcon;
