import React from 'react';

function SeasonResultIcon() {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clipSeasonResult)">
        <path
          d="M17.6699 7.19817H23.5045C25.1293 7.19647 26.7499 6.25 26.7469 2.96832H24.2516C23.8925 2.96832 23.5817 2.71815 23.5045 2.36723C23.2031 0.99556 22.9999 1.69405e-10 20.4879 0C17.9999 -1.67789e-10 17.7723 0.99556 17.4713 2.36766C17.3945 2.71858 17.0833 2.96875 16.7242 2.96875H14.2285C14.2499 6.25 16.0455 7.19647 17.6699 7.19817Z"
          fill="#C6A788"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.8828 2.96844H32.0682C33.6931 2.97058 35.0097 4.28675 35.0114 5.91158C35.5523 16.6929 35.3806 23.1157 35.1331 32.3786C35.0936 33.8561 35.0521 35.4058 35.0114 37.057C34.875 38.75 33.6931 39.9984 32.0682 40.0001H8.90779C7.28296 39.9984 6.125 38.75 5.96466 37.057C5.95181 36.5476 5.93913 36.0475 5.92667 35.556C5.6416 24.313 5.47051 17.5655 5.96466 5.91158C5.96679 4.28675 7.28296 2.97058 8.90779 2.96844H12.0637C11.75 6.875 14.7027 8.72579 17.172 8.72835H23.8041C26.2733 8.72579 29.25 7.5 28.8828 2.96844ZM22.1548 18.0453C20.8818 19.7202 19.6762 21.3235 19.4757 21.6079C19.1385 22.0862 18.992 21.9846 17.5332 20.2608C16.6653 19.2354 15.8165 18.3962 15.6474 18.3962C15.2016 18.3962 13.0001 20.7062 13.0001 21.174C13.0001 21.8728 19.0435 30 19.563 30C20.0582 30 28.0108 18.9844 28.0001 18.313C27.9922 17.8042 25.3488 15 24.8771 15C24.6528 15 23.4279 16.3704 22.1548 18.0453Z"
          fill="#C6A788"
        />
      </g>
      <defs>
        <clipPath id="clipSeasonResult">
          <rect width="40" height="40" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SeasonResultIcon;
