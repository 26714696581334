import React from 'react';
import { observer } from 'mobx-react';
import classNames from '../../../common/classNames';
import RootStore from '../../../store';
import corpStore from '../../../store/CorpStore';
import { draggableOptionsDefault } from '../../../ui/components/Cat/draggableOptionsDefault';
import { DropContainer } from '../../deal/DropContainer';
import LotAreaInfo from '../../deal/LotAreaInfo';
import Cat, { CAT_STATE } from '../../../ui/components/Cat/Cat';
import CompaniesSlider from './CompaniesSlider';
import { LockIcon } from '../../../ui/icons';
import './style.scss';
import { AREA_POSITION, OPERATION_TYPE } from '../../../store/constants';
import { DRAG_AREA } from '../../../common/constants';

function CompanyField({ position }) {
  const { appStore } = RootStore;
  const { currentOperationType } = corpStore;
  const areaNum = corpStore.currentAreaNumField[position];
  const company = corpStore.currentCompanyByAreaNum(areaNum);
  const groupedCats = corpStore.corpOurCats[areaNum];
  let fromType = DRAG_AREA.COMPANY_ZONE;
  let toType = DRAG_AREA.TRANSFER_ZONE;
  if (currentOperationType === OPERATION_TYPE.TRANSPORTATION) {
    fromType = position === AREA_POSITION.TOP ? DRAG_AREA.TRANSPORTATION_TOP : DRAG_AREA.TRANSPORTATION_BOTTOM;
    toType = DRAG_AREA.TRANSPORTATION_ZONE;
  }

  if (!company || !groupedCats) {
    return (
      <div className="lot-area lot-area--shop lot-area--shop-disallow">
        <LockIcon />
      </div>
    );
  }

  return (
    <DropContainer
      type={fromType}
      element={({ canDrop, drop }) => (
        <div
          className={classNames({
            'lot-area': true,
            'lot-area--nursery': true,
            'lot-area--opponent': true,
            'lot-area--transportation': currentOperationType === OPERATION_TYPE.TRANSPORTATION,
          })}
          ref={drop}
          style={
            canDrop
              ? {
                  background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #B8C9D3',
                  boxShadow: ' 0px 4px 10px rgba(0, 0, 0, 0.1)',
                }
              : {}
          }
        >
          <LotAreaInfo player={company} color="#617C8B" />
          <div className="lot-area__content">
            <CompaniesSlider state={currentOperationType} position={position} areaNum={areaNum} />
            <div className="lot-area__cats">
              {groupedCats.map((item, index) => (
                <Cat
                  from={fromType}
                  dragAround={true}
                  key={index}
                  cat={item}
                  state={CAT_STATE.DEFAULT}
                  onClick={() => {
                    if (!draggableOptionsDefault.canDrag(item, fromType)) return;
                    if (item.contents) {
                      appStore.setOpenedGroupedCat({ gender: item.gender, color: item.color, fromType });
                    }
                  }}
                  onDoubleClick={() => {
                    if (!draggableOptionsDefault.canDrag(item, fromType)) return;
                    if (item.count > 0) {
                      appStore.dropItem({
                        from: fromType,
                        data: item,
                        to: toType,
                      });
                    } else {
                        RootStore.appStore.notEnoughCatsModalOpen()
                    }
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    />
  );
}

export default observer(CompanyField);
