import RootStore from '../../index';
import React from 'react';
import { LOT_STATUS, MESSAGE_TYPE } from '../../constants';
import trainingStore from '../../trainingStore';
import trainings from './index';
import api from '../../../api';
import { LocalStorage } from '../../../common/localStorage';
import routes from '../../../routes';
import CoinIcon from '../../../ui/icons/CoinIcon';
import _ from 'lodash';
import CoinModalIcon from '../../../ui/icons/modal/CoinModalIcon';
import ChapterSevenIcon from '../../../ui/icons/training/ChapterSevenIcon';
import PlusModalIcon from '../../../ui/icons/modal/PlusModalIcon';
import EquallyModalIcon from '../../../ui/icons/modal/EquallyModalIcon';
import MinusModalIcon from '../../../ui/icons/modal/MinusModalIcon';
import LikeModalIcon from '../../../ui/icons/modal/LikeModalIcon';
import CatCalcIcon from '../../../ui/icons/calc/CatCalcIcon';
import { CAT_COLOR, CAT_GENDER, MATING_STATUS } from '../../../common/constants';

export const chapter = 7;

export const stepsApiParams = {
  game_type: 'tutorial',
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 6,
          sell_price: 6,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
      cats: [
        {
          color: 'black',
          gender: 'male',
        },
      ],
    },
    {
      uid: 'b27ea52e-864b-11eb-88bb-0242ac120002',
      name: 'bot 2',
      role: 'nursery',
      default_prices_override: {
        male_black: {
          buy_price: 7,
          sell_price: 7,
        },
      },
      cats: [
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
      ],
      ai_type: 'nursery_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: false,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'shop',
      initial_money: 36,
      default_prices_override: {
        male_black: {
          buy_price: 5,
          sell_price: 5,
        },
      },
    },
  ],
  turn_durations: [100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  city_quota_override: {
    male_black: {
      buy_price: 8,
      sell_price: 8,
      buy_quantity: 100,
    },
  },
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
};

export const taskApiParams = {
  game_type: 'tutorial',
  chapter_num: 7,
  initial_money: 1000,
  initial_turn: 0,
  with_credit: false,
  players: [
    {
      uid: '00000000-0000-0000-0000-000000000002',
      name: 'bot 1',
      role: 'shop',
      default_prices_override: {
        male_black: {
          buy_price: 6,
          sell_price: 6,
        },
        female_black: {
          buy_price: 7,
          sell_price: 7,
        },
        male_gray: {
          buy_price: 8,
          sell_price: 8,
        },
        female_gray: {
          buy_price: 10,
          sell_price: 10,
        },
      },
      ai_type: 'normal_shop_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: true,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: 'b27ea52e-864b-11eb-88bb-0242ac120002',
      name: 'bot 2',
      role: 'nursery',
      default_prices_override: {
        male_black: {
          buy_price: 6,
          sell_price: 6,
        },
        female_black: {
          buy_price: 7,
          sell_price: 7,
        },
        male_gray: {
          buy_price: 8,
          sell_price: 8,
        },
        female_gray: {
          buy_price: 10,
          sell_price: 10,
        },
      },
      cats: [
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'male',
        },
        {
          color: 'black',
          gender: 'female',
        },
        {
          color: 'gray',
          gender: 'male',
        },
        {
          color: 'gray',
          gender: 'male',
        },
        {
          color: 'gray',
          gender: 'female',
        },
      ],
      ai_type: 'nursery_bot',
      ai_features: {
        shop_spread_override: [0, 0, 0, 0, 0, 0, 0, 0],
        price_correction_enabled: false,
        ai_enabled: false,
        fav_color_override: 'black',
        non_fav_color_override: 'black',
      },
    },
    {
      uid: '00000000-0000-0000-0000-000000000001',
      name: 'player 1',
      role: 'shop',
      initial_money: 45,
      default_prices_override: {
        male_black: {
          buy_price: 5,
          sell_price: 5,
        },
        female_black: {
          buy_price: 6,
          sell_price: 6,
        },
        male_gray: {
          buy_price: 7,
          sell_price: 7,
        },
        female_gray: {
          buy_price: 9,
          sell_price: 9,
        },
      },
    },
  ],
  turn_durations: [100 * 60],
  household_cost: {
    shop: 5,
    nursery: 3,
  },
  base_quota: 567,
  city_quota_override: {
    male_black: {
      buy_price: 8,
      sell_price: 8,
      buy_quantity: null,
    },
    female_black: {
      buy_price: 9,
      sell_price: 9,
      buy_quantity: 13,
    },
    male_gray: {
      buy_price: 12,
      sell_price: 12,
      buy_quantity: 31,
    },
    female_gray: {
      buy_price: 13,
      sell_price: 13,
      buy_quantity: 11,
    },
  },
  absence_timeout: 10 * 60,
  randomize: {
    colors: false,
  },
};

export const steps = [
  {
    before: () => {
      LocalStorage.SHOWED_BUY_FROM_CITY = true;
      LocalStorage.SHOWED_SELL_TO_CITY = true;
      trainingStore.setCalculateHeader([
        'На счете',
        { val: 'Расход', color: 'red', icon: <CatCalcIcon className="training-calc__icon" /> },
        'остаток',
        { val: 'доход', icon: <CatCalcIcon className="training-calc__icon" /> },
        'на счете',
      ]);
      trainingStore.setCalculateExamples([['36', '-', '-', '-', '-']]);
    },
    actionName: 'onNextButton',
    step: 192, // По вордовскому документу
    chapter: '7',
    chapterIcon: <ChapterSevenIcon className="training-modal__chapter-icon" />,
    title: <div>ДОБРО ПОЖАЛОВАТЬ В ГЛАВУ «ЦЕНОВАЯ ПОЛИТИКА. ЗООМАГАЗИН»</div>,
    message: (
      <p className="training-modal__desc">
        В этой части вы станете зоомагазином. У вас будет много конкурентов. Следите за их ценами, чтобы не проиграть..
      </p>
    ),
    nextButton: true,
  },
  {
    actionName: 'routerGoTo',
    elements: ['.pet-shops-item-wrapper:first-child'],
    pulseElements: ['.pet-shops-item-wrapper:first-child'],
    title: 'ПРОДОЛЖАЕМ!',
    message: (
      <p className="training-modal__desc">
        <span>На счете</span> <span className="notranslate"> 36</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span>, увеличим эти монетки</span> <br /> <span>до</span> <span className="notranslate"> 48</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span>. Для начала зайди в зоомагазин конкурента.</span>
      </p>
    ),
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--nursery .cat--male-black'],
    disabledElements: ['.lot-area.lot-area--nursery .cat--male-black'],
    title: <div>СРАВНЕНИЕ ЦЕН</div>,
    message: (
      <p className="training-modal__desc">
        <span>Зоомагазин «Бонифаций» готов купить черных мальчиков по</span> <span className="notranslate"> 6</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    nextButton: true,
    position: 'top',
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--city .cat--male-black .cat__price-wrapper'],
    disabledElements: ['.lot-area.lot-area--city .cat--male-black .cat__price-wrapper'],
    title: <div>СРАВНЕНИЕ ЦЕН</div>,
    message: (
      <p className="training-modal__desc">
        <span>Надо заметить, что город покупает таких же котят по</span> <span className="notranslate"> 8</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    nextButton: true,
    position: 'bottom',
  },
  {
    actionName: 'setOpenedGroupedCat',
    elements: ['.lot-area.lot-area--shop .cat--male-black'],
    isDisabledDoubleClickCat: true,
    title: 'МЕНЯЕМ ЦЕНЫ',
    message: (
      <p className="training-modal__desc">
        Нам надо купить котят у питомников. Затем подороже продать котят городу. Чтобы питомники продали питомцев тебе,
        а не другому игроку, покупай котят дороже, чем другие зоомагазины. Но не ставь цену выше цены города. Нажми на
        котика.
      </p>
    ),
  },
  {
    actionName: 'buyPricePlus',
    elements: ['.training-price-buy'],
    disabledElements: ['.training-price-buy .price-minus'],
    title: 'МЕНЯЕМ ЦЕНЫ',
    needOverlayClickModal: false,
    message: (
      <p className="training-modal__desc">
        <span>Мы видели, что твой конкурент покупает котиков по</span> <span className="notranslate"> 6</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span>. Ты можешь покупать их дороже или по такой же цене. Но твоя цена не должна быть больше</span>
        <span className="notranslate"> 8</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span> – по такой цене ты продашь котят городу, ведь это и есть твой заработок. Выстави на своего котенка</span>
        <br /> <span>цену</span>
        <span className="notranslate"> 6</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
  },
  {
    actionName: 'setOpenedGroupedCat',
    elements: ['.modal--grouped-cat .text_button--color-blue'],
    needOverlayClickModal: false,
    title: 'УТВЕРДИ ЦЕНЫ',
    message: (
      <p className="training-modal__desc">Теперь нажми кнопку «СОХРАНИТЬ», чтобы питомники увидели твою новую цену.</p>
    ),
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/b27ea52e-864b-11eb-88bb-0242ac120002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({
              msg_type: 'trading_request',
              to: LocalStorage.USER_UID,
              request_type: 'sell',
              contents: [{ color: 'black', gender: 'male', price: 6, count: 6 }],
            }),
          );
        }, 50);
      };
    },
  },
  {
    actionName: 'onReceiveMessage__trading_lot_update',
  },
  {
    actionName: 'setCurrentLot',
    elements: ['.mail:last-child'],
    pulseElements: ['.mail:last-child'],
    title: 'СДЕЛКА!',
    message: <p className="training-modal__desc">Твоя цена на котенка понравилась питомнику. Кликни на конверт.</p>,
    position: 'left',
  },
  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--lot'],
    disabledElements: ['.lot-area.lot-area--lot'],
    title: 'ПРЕДЛОЖЕНИЕ',
    message: (
      <p className="training-modal__desc">
        <span>Питомник «Леопольд» хочет продать тебе</span> <span className="notranslate"> 6 </span>
        <span>черных котят по</span>
        <span className="notranslate"> 6</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span>. Эта покупка будет стоить тебе</span> <span className="notranslate"> 36</span>
        <CoinIcon className="training-modal__desc-coin" style={{ position: 'relative', top: '2px' }} />
        <span>.</span>
      </p>
    ),
    nextButton: true,
    position: 'top',
  },
  {
    actionName: 'acceptLot',
    elements: ['.lot-area__lot-panel-btn-wrapper .text_button--color-green'],
    pulseElements: ['.lot-area__lot-panel-btn-wrapper .text_button--color-green'],
    title: 'ОДОБРИ СДЕЛКУ',
    message: <div></div>,
    position: 'left',
    after: () => {
      const socket = new WebSocket(
        `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/b27ea52e-864b-11eb-88bb-0242ac120002`,
      );
      socket.onopen = function (event) {
        setTimeout(() => {
          socket.send(
            JSON.stringify({ msg_type: MESSAGE_TYPE.TRADING_CONFIRM, lot_id: RootStore.appStore.lots[0].lot_id }),
          );
        }, 300);
      };
    },
  },

  {
    after: () => {
      trainingStore.setCalculateExamples([['36', { val: '-36', color: 'red' }, '0', '-', '-']]);
    },
    actionName: 'notificationModalOpen',
    elements: ['.mail:last-child'],
    pulseElements: ['.mail:last-child'],
  },
  {
    actionName: 'notificationModalClose',
  },

  {
    actionName: 'onNextButton',
    elements: ['.lot-area.lot-area--shop .cat--male-black'],
    disabledElements: ['.lot-area.lot-area--shop .cat--male-black'],
    title: <div>ОТЛИЧНО!</div>,
    message: (
      <p className="training-modal__desc">
        <span>Теперь у тебя есть</span> <span className="notranslate"> 6 </span> <span>черных котят.</span>
      </p>
    ),
    nextButton: true,
    position: 'top',
  },

  {
    after: () => {
      trainingStore.setCalculateExamples([
        ['36', { val: '-36', color: 'red' }, '0', { val: '+48', color: 'green' }, '48'],
      ]);
    },
    actionName: 'sellToCity',
    checkParams: (params) => {
      const ourCats = RootStore.appStore.ourCats;
      if (ourCats.length === 1) {
        return true;
      }
      return 'skip';
    },
    elements: ['.lot-area.lot-area--city', '.lot-area.lot-area--shop'],
    isDisabledDoubleClickCat: true,
    step: 163,
    title: 'ПРОДАЕМ!',
    message: (
      <p className="training-modal__desc">Чтобы продать котят городу, перетащи всех своих питомцев в зону города.</p>
    ),
    position: 'bottom',
  },
  {
    actionName: 'onNextButton',
    title: 'РАЗБЕРЕМ РЕШЕНИЕ',
    isWide: true,
    message: (
      <div>
        <p className="training-modal__desc">
          Цены играют большую роль в нашем деле! Ориентируясь на клиента, не забывай о конкурентах! Чтобы решить
          практическое задание, посмотри, как мы считали.
        </p>
        <div className="training-modal__blocks">
          <p className="training-modal__block-text">Было на счете</p>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">потрачено на котят</p>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">цена продажи котят городу</p>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <p className="training-modal__block-text">стало на счете</p>
        </div>
        <div className="training-modal__blocks">
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">36</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <MinusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">36</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <PlusModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">48</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
          <EquallyModalIcon className="training-modal__blocks-icon" />
          <div className="training-modal__price-value">
            <span className="training-modal__price-cost notranslate">48</span>
            <CoinModalIcon className="training-modal__price-coin" />
          </div>
        </div>
        <div className="training-modal__blocks training-modal__blocks--center">
          <div className="training-modal__price">
            <p className="training-modal__price-name">МАРЖА</p>
            <div className="training-modal__price-value">
              <span className="training-modal__price-cost training-modal__price-cost--green notranslate">+2</span>
              <CoinModalIcon className="training-modal__price-coin" />
            </div>
          </div>
          <div className="training-modal__price">
            <p className="training-modal__price-name">ПРИБЫЛЬ</p>
            <div className="training-modal__price-value">
              <span className="training-modal__price-cost training-modal__price-cost--green notranslate">+12</span>
              <CoinModalIcon className="training-modal__price-coin" />
            </div>
          </div>
        </div>
      </div>
    ),
    buttonText: 'Понятно',
    nextButton: true,
  },
  {
    actionName: 'onNextButton',
    colorBg: 'green',
    title: (
      <div>
        <span>ПОЗДРАВЛЯЕМ!</span>
        <br /> <span>ОБУЧЕНИЕ ПО</span> <span className="notranslate"> 7&nbsp;</span>
        <span>ГЛАВЕ ЗАВЕРШЕНО</span>
      </div>
    ),
    message: (
      <div>
        <p className="training-modal__desc">Чтобы перейти на следующий уровень, выполни практическое задание.</p>
        <div className="training-modal__icon-block">
          <LikeModalIcon className="training-modal__icon" />
        </div>
      </div>
    ),
    nextButton: true,
    buttonText: 'Понятно',
  },
];

export const task = {
  chapter: 7,
  data: { confirmedLotCount: 0, firstLaunch: true, currentLotGameUID: '' },
  watch: [
    {
      actionName: 'hideTaskModal',
      checkParams: (params, data) => {
        // через 1 минуту отправить заявку игроку, если его цена устраивает
        if (data.firstLaunch) {
          data.firstLaunch = false;
          data.currentLotGameUID = LocalStorage.GAME_UID;
          setTimeout(() => {
            const maleBlackPrice = _.find(RootStore.appStore.me.default_prices, {
              gender: CAT_GENDER.MALE,
              color: CAT_COLOR.BLACK,
            }).buy_price;
            const femaleBlackPrice = _.find(RootStore.appStore.me.default_prices, {
              gender: CAT_GENDER.FEMALE,
              color: CAT_COLOR.BLACK,
            }).buy_price;
            const maleGrayPrice = _.find(RootStore.appStore.me.default_prices, {
              gender: CAT_GENDER.MALE,
              color: CAT_COLOR.GRAY,
            }).buy_price;
            const femaleGrayPrice = _.find(RootStore.appStore.me.default_prices, {
              gender: CAT_GENDER.FEMALE,
              color: CAT_COLOR.GRAY,
            }).buy_price;
            if (maleBlackPrice >= 6 || femaleBlackPrice >= 7 || maleGrayPrice >= 8 || femaleGrayPrice >= 10) {
              const socket = new WebSocket(
                `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/b27ea52e-864b-11eb-88bb-0242ac120002`,
              );
              socket.onopen = function (event) {
                if (LocalStorage.GAME_UID !== null && data.currentLotGameUID !== LocalStorage.GAME_UID) {
                  return;
                }
                setTimeout(() => {
                  socket.send(
                    JSON.stringify({
                      msg_type: 'trading_request',
                      to: LocalStorage.USER_UID,
                      request_type: 'sell',
                      contents: [
                        ...(maleBlackPrice >= 6
                          ? [{ color: 'black', gender: 'male', price: maleBlackPrice, count: 2 }]
                          : []),
                        ...(femaleBlackPrice >= 7
                          ? [{ color: 'black', gender: 'female', price: femaleBlackPrice, count: 1 }]
                          : []),
                        ...(maleGrayPrice >= 8
                          ? [{ color: 'gray', gender: 'male', price: maleGrayPrice, count: 2 }]
                          : []),
                        ...(femaleGrayPrice >= 10
                          ? [{ color: 'gray', gender: 'female', price: femaleGrayPrice, count: 1 }]
                          : []),
                      ],
                    }),
                  );
                }, 100);
              };
            }
          }, 60 * 1000);
        }
        return 'skip';
      },
    },
    {
      actionName: 'onReceiveMessage__balance',
      checkParams: (params) => {
        if (params[0].current_balance === 62) {
          return true;
        }
        return 'skip';
      },
    },
    {
      actionName: 'onReceiveMessage__game_over',
      checkParams: (params) => {
        return false;
      },
    },
    {
      actionName: 'onReceiveMessage__trading_lot_update',
      checkParams: (params) => {
        if (params[0].initiator !== LocalStorage.USER_UID && params[0].old_contents) {
          if (params[0].status === LOT_STATUS.IN_PROGRESS) {
            // бот соглашается на лайкнутую игроком заявку
            const socket = new WebSocket(
              `wss://${LocalStorage.JOIN_URL}/api/join/${LocalStorage.GAME_UID}/${params[0].initiator}`,
            );
            socket.onopen = function (event) {
              setTimeout(() => {
                socket.send(
                  JSON.stringify({
                    msg_type: MESSAGE_TYPE.TRADING_CONFIRM,
                    lot_id: params[0].lot_id,
                  }),
                );
              }, 100);
            };
          }
        }
        return 'skip';
      },
    },
  ],
  title: (
    <div>
      <span className="notranslate">7 </span> <span>ГЛАВА «ЦЕНОВАЯ ПОЛИТИКА. ЗООМАГАЗИН» ПРАКТИЧЕСКОЕ ЗАДАНИЕ</span>
    </div>
  ),
  message: (
    <p className="training-modal__desc">
      <span>У тебя</span> <span className="notranslate"> 45</span>
      <CoinIcon style={{ position: 'relative', top: '2px' }} /> <span>. Твоя задача - заработать</span>
      <span className="notranslate"> 62</span>
      <CoinIcon style={{ position: 'relative', top: '2px' }} />
      <span>
        . Узнай цены магазина-конкурента и предложи питомникам выгодную цену на покупку их котят. После продай котят
        городу. Учитывай цены конкурентов и города! Время на прохождение задания ограничено.
      </span>
    </p>
  ),
  wrongMessage: (
    <div>
      <div className="training-modal__price training-modal__price--single">
        <p className="training-modal__price-name">
          <span>Не получилось</span>
          <br />
          <span>заработать</span>
        </p>
        <div className="training-modal__price-value">
          <CoinModalIcon className="training-modal__price-coin" />
          <span className="training-modal__price-cost training-modal__price-cost--white notranslate">62</span>
        </div>
      </div>
    </div>
  ),
};
