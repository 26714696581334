(function (window) {
  //Get Browser Type
  var browser = {
    /** Define Browser Type*/
    /** Whether we are using a IE Browser or not. */
    type:
      typeof window.attachEvent === 'function' && !(Object.prototype.toString.call(window.opera) == '[object Opera]')
        ? 'IE'
        : /** Whether we are using a Opera Browser or not. */
        Object.prototype.toString.call(window.opera) == '[object Opera]' ||
          navigator.userAgent.indexOf('Opera Mini') > -1
        ? 'Opera'
        : /** Whether we are using a WebKit Type Browser or not. */
        navigator.userAgent.indexOf('AppleWebKit/') > -1
        ? 'WebKit'
        : /** Whether we are using a Gecko Type Browser or not. */
        navigator.userAgent.indexOf('Gecko') > -1 && navigator.userAgent.indexOf('KHTML') === -1
        ? 'Gecko'
        : /** Whether we are using a Apple Browser or not. */
        /Apple.*Mobile/.test(navigator.userAgent)
        ? 'MobileSafari'
        : undefined,
  };
  //Get Browser Version
  browser.version = function () {
    let ua;
    ua = navigator.userAgent;
    return this.type === 'Gecko'
      ? /** Whether this browser type is Gecko*/ (function (a) {
          let rv = -1,
            re = new RegExp('rv:([0-9]{1,}[.0-9]{0,})');
          re.exec(ua) !== null ? (rv = parseFloat(RegExp.$1)) : '';
          if (ua.indexOf('Firefox/') > 0) {
            a.name = 'Firefox';
            return rv;
          }
          if (ua.indexOf('Trident/') > 0) {
            /** IE > 10*/ a.name = 'IE';
            return rv;
          }
          return rv;
        })(this)
      : this.type === 'WebKit'
      ? /** Whether this browser type is WebKit*/ (function (a, b) {
          let rv = -1,
            re;
          re = ua.match(/Opera|OPR\/([0-9]+)\./);
          if (null !== re) {
            rv = parseInt(re[1], 10);
            a.name = 'Opera';
            return rv;
          }
          re = ua.match(/Chrom(e|ium)\/([0-9]+)\./);
          if (null !== re) {
            rv = parseInt(re[2], 10);
            a.name = 'Chrome';
            return rv;
          }
          re = /AppleWebKit\/([\d.]+)/.exec(navigator.userAgent);
          if (null !== re) {
            rv = parseFloat(re[1]);
            a.name = 'Safari';
            return rv;
          }
          return rv;
        })(this)
      : this.type === 'IE'
      ? /** Whether this browser type is IE (IE version < 9)*/ (function (a) {
          let rv = -1,
            re;
          re = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
          return re.exec(ua) !== null ? ((rv = parseFloat(RegExp.$1)), (a.name = 'IE'), rv) : rv;
        })(this)
      : this.type === 'Opera'
      ? /** Whether this browser type is Opera*/ (function (a) {
          let rv = -1;
          try {
            rv = navigator.userAgent.match(/Version\/([1-9]+\.[0-9]{2})/)[1];
            a.name = 'Opera';
            return rv;
          } catch (ex) {
            return rv;
          }
        })(this)
      : /** Undefined browser type define*/ -1;
  }.call(browser);

  window.browser = browser;
})(window);
