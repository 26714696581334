import RootStore from '../../store';
import routes from '../../routes';
import trainingStore from '../../store/trainingStore';
import { PhoneIcon } from '../../ui/icons';
import LongDealsIcon from '../../ui/icons/LongDealsIcon';
import SliderComponent from '../slider/SliderComponent';
import React from 'react';
import { observer } from 'mobx-react';
import { AI_TYPE, SPECIALITIES } from '../../common/constants';
import { PRIVILEGES } from '../../store/teamMode';

function OpponentsSlider() {
  const { appStore } = RootStore;
  const labels = appStore.opponentPlayers.map((player) => player.name);

  function handleCallClick() {
    trainingStore.checkIsCorrectAction('onCall');
    if (!trainingStore.launched && appStore.messengerInited) {
      window.AlbChat.audioCall(appStore.currentPlayerUuid);
    }
  }

  const buttons = [
    {
      img: (
        <span className="lot-area__players-slider-icon lot-area__players-slider-icon--call" onClick={handleCallClick}>
          <PhoneIcon />
        </span>
      ),
      isVisible: () => {
        return (
          appStore.messengerInited &&
          appStore.currentPlayer.ai_type === 'human' &&
          appStore.hasSpecialities([SPECIALITIES.CAPTAIN, SPECIALITIES.SUPPLY, SPECIALITIES.SALES])
        );
      },
    },
    {
      img: (
        <span
          className="lot-area__players-slider-icon lot-area__players-slider-icon--ltd"
          style={{ background: '#02516C', marginLeft: '5px' }}
          onClick={() => appStore.openMakingLTDModal(appStore.currentPlayer)}
        >
          <LongDealsIcon />
        </span>
      ),
      isVisible: () => {
        return (
          !appStore.currentCompanyBlockingInfo &&
          !appStore.blockedByCurrentCompanyInfo &&
          !appStore.meIsCEO &&
          appStore.with_ltd &&
          appStore.currentPlayer.role !== appStore.me.role &&
          appStore.currentPlayer.ai_type !== AI_TYPE.FIFTH_SHOP &&
          ((!appStore.isCorpMode && appStore.mySpeciality === SPECIALITIES.CAPTAIN) ||
            appStore.myPrivileges.includes(PRIVILEGES.CREATE_LTD_TASK))
        );
      },
    },
  ];

  return (
    <SliderComponent
      sliderCustomClass="lot-area__players-slider"
      sliderItemCustomClass="lot-area__players-slider-item"
      labels={labels}
      customSettings={{ infinite: true }}
      afterChange={(current) => {
        RootStore.router.goTo(routes.inside, {
          uuid: appStore.opponentPlayers[current].uuid,
        });
      }}
      buttons={buttons}
      icon={(option) => {
        const Img = appStore.opponentPlayers[option].logo;
        return {
          img: <Img />,
        };
      }}
      currentSlideIndex={appStore.opponentPlayers.indexOf(appStore.currentPlayer)}
    />
  );
}

export default observer(OpponentsSlider);
