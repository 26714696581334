const checkObjectParams = ({ object, params, allIsNeeded = true }) => {
  const checkParams = ([path, validate]) => {
    const value = _.get(object, path);
    if (_.isFunction(validate)) {
      return validate(value);
    } else {
      return value === validate;
    }
  };
  if (allIsNeeded) {
    return Object.entries(params).every(checkParams);
  } else {
    return Object.entries(params).some(checkParams);
  }
};

export default checkObjectParams;
