import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';

function SocketConnectingModal() {
  const { appStore } = RootStore;

  if (!appStore.socketConnectingModalShow) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.socketConnectingModalShow}
      shouldCloseOnOverlayClick={false}
      colorBg="red"
      title="Идет автоматическое переподключение к сессии"
    >
      <div className="modal__body-actions">
        <TextButton title="Переподключиться" color="purple" onClick={() => location.reload()} />
      </div>
    </Modal>
  );
}

export default observer(SocketConnectingModal);
