import React from 'react';

function HouseCalcIcon({ style, className }) {
  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.6241 15.6371C31.168 15.0193 31.1155 14.0724 30.5069 13.5227L17.0995 1.40484C16.491 0.855127 15.5153 0.866643 14.9195 1.4308L1.46658 14.1688C0.870836 14.7329 0.841596 15.6786 1.40185 16.2801L1.73913 16.643C2.2987 17.2445 3.20318 17.3162 3.75805 16.8027L4.76345 15.8727V29.4883C4.76345 30.3237 5.41875 31 6.2269 31H11.4709C12.2791 31 12.9344 30.3237 12.9344 29.4883V19.9629H19.6231V29.4883C19.6115 30.3231 20.1897 30.9994 20.9979 30.9994H26.5552C27.3634 30.9994 28.0187 30.3231 28.0187 29.4877V16.0644C28.0187 16.0644 28.2964 16.3157 28.639 16.6267C28.9809 16.9371 29.6992 16.6882 30.2431 16.0698L30.6241 15.6371Z"
        fill="#C6A788"
      />
    </svg>
  );
}

export default HouseCalcIcon;
