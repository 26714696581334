import { declOfNum } from './declensionOfWords';

const declension_for_years = ['год', 'года', 'лет'];
const declension_for_month = ['месяц', 'месяца', 'месяцев'];

const catAgeCalculation = ({ cat, timerInSeconds, startSessionInSeconds, monthDuration }) => {
  const catBirthdayInSeconds = cat.created_at - startSessionInSeconds;
  const catAgeInSeconds = timerInSeconds - catBirthdayInSeconds;
  const catAgeInMonths = Math.floor(catAgeInSeconds / monthDuration);
  const months = catAgeInMonths % 12;
  const years = Math.floor(catAgeInMonths / 12);
  return [
    ...(years ? [`${years} ${declOfNum(years, declension_for_years)}`] : []),
    ...[`${months} ${declOfNum(months, declension_for_month)}`],
  ].join(' ');
};

export default catAgeCalculation;
