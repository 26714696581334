import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../../ui/buttons/TextButton/TextButton';
import ReactModal from 'react-modal';
import '../training.scss';
import classNames from '../../common/classNames';
import ChapterOneIcon from '../../ui/icons/training/ChapterOneIcon';
import ChapterTwoIcon from '../../ui/icons/training/ChapterTwoIcon';
import ChapterThreeIcon from '../../ui/icons/training/ChapterThreeIcon';
import ChapterFourIcon from '../../ui/icons/training/ChapterFourIcon';
import ChapterFiveIcon from '../../ui/icons/training/ChapterFiveIcon';
import ChapterSixIcon from '../../ui/icons/training/ChapterSixIcon';
import ChapterSevenIcon from '../../ui/icons/training/ChapterSevenIcon';
import ChapterCreditIcon from '../../ui/icons/training/ChapterCreditIcon';
import ChapterEightIcon from '../../ui/icons/training/ChapterEightIcon';
import ChapterNineIcon from '../../ui/icons/training/ChapterNineIcon';
import ChapterTenIcon from '../../ui/icons/training/ChapterTenIcon';
import { Swiper, SwiperSlide } from 'swiper/react';
import trainingStore from '../../store/trainingStore';
import trainings from '../../store/trainings/basicTrainings';
import ChapterTemplate from '../../ui/components/Trainings/ChapterTemplate';
import PageLayout from '../../ui/components/Trainings/PageLayout';

@observer
class BasicTraining extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { appStore } = RootStore;
    const currentProgress = appStore.tutorialProgress.chapter;
    const chapters = [
      {
        title: 'ориентация на клиента',
        header: 'ГЛАВА 1',
        icon: <ChapterOneIcon />,
        clickStart: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter1 });
        },
        clickTask: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter1, isTask: true });
        },
      },
      {
        title: 'производство',
        header: 'ГЛАВА 2',
        icon: <ChapterTwoIcon />,
        clickStart: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter2 });
        },
        clickTask: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter2, isTask: true });
        },
      },
      {
        title: 'операционные затраты',
        header: 'ГЛАВА 3',
        icon: <ChapterThreeIcon />,
        clickStart: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter3 });
        },
        clickTask: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter3, isTask: true });
        },
      },
      {
        title: 'эффект масштаба',
        header: 'ГЛАВА 4',
        icon: <ChapterFourIcon />,
        clickStart: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter4 });
        },
        clickTask: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter4, isTask: true });
        },
      },
      {
        title: 'сегментация рынка',
        header: 'ГЛАВА 5',
        icon: <ChapterFiveIcon />,
        clickStart: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter5 });
        },
        clickTask: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter5, isTask: true });
        },
      },
      {
        title: 'ценовая политика. Питомник',
        header: 'ГЛАВА 6',
        icon: <ChapterSixIcon />,
        clickStart: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter6 });
        },
        clickTask: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter6, isTask: true });
        },
      },
      {
        title: 'ценовая политика. зоомагазин',
        header: 'ГЛАВА 7',
        icon: <ChapterSevenIcon />,
        clickStart: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter7 });
        },
        clickTask: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter7, isTask: true });
        },
      },
      {
        title: 'кредиты и прибыль',
        header: 'ГЛАВА 8',
        icon: <ChapterCreditIcon />,
        clickStart: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter8 });
        },
        clickTask: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter8, isTask: true });
        },
      },
      {
        title: 'болезни. питомник',
        header: 'ГЛАВА 9',
        icon: <ChapterEightIcon />,
        clickStart: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter9 });
        },
        clickTask: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter9, isTask: true });
        },
      },
      {
        title: 'болезни. зоомагазин',
        header: 'ГЛАВА 10',
        icon: <ChapterNineIcon />,
        clickStart: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter10 });
        },
        clickTask: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter10, isTask: true });
        },
      },
      {
        title: 'переговоры о цене',
        header: 'ГЛАВА 11',
        icon: <ChapterTenIcon />,
        clickStart: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter11 });
        },
        clickTask: () => {
          trainingStore.launchChapter({ chapter: trainings.chapter11, isTask: true });
        },
      },
    ];

    chapters.forEach((chapter, index) => {
      chapter.active = currentProgress >= index;
      chapter.headerIsVisible = true;
    });
    const chaptersTwoColumn = [];
    let column = [];
    chapters.forEach((chapter, index) => {
      column.push(chapter);
      if (column.length === 2) {
        chaptersTwoColumn.push(column);
        column = [];
      } else {
        if (index === chapters.length - 1) {
          chaptersTwoColumn.push(column);
        }
      }
    });

    return (
      <PageLayout>
        <div className="training__container">
          <div className="training__lessons">
            <ChapterTemplate chapter={chapters[0]} />
            <ChapterTemplate chapter={chapters[1]} />
            <ChapterTemplate chapter={chapters[2]} />
            <ChapterTemplate chapter={chapters[3]} />
          </div>
          <div className="training__lessons">
            <ChapterTemplate chapter={chapters[4]} />
            <ChapterTemplate chapter={chapters[5]} />
            <ChapterTemplate chapter={chapters[6]} />
            <ChapterTemplate chapter={chapters[7]} />
          </div>
          <div className="training__lessons">
            <ChapterTemplate chapter={chapters[8]} />
            <ChapterTemplate chapter={chapters[9]} />
            <ChapterTemplate chapter={chapters[10]} />
          </div>
          <div className="training__lessons">
            <div
              className={classNames({
                training__lesson: true,
                'training__lesson--not-header': true,
                'training__lesson--disabled': currentProgress < 11,
              })}
            >
              <p className="training__lesson-title">финал обучения</p>
              <div className="training__lesson-actions">
                <TextButton
                  onClick={() => {
                    trainingStore.launchChapter({ chapter: trainings.chapter12 });
                  }}
                  isDisabled={currentProgress < 11}
                  title="старт"
                  color="green"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="training__container training__container--mobile">
          <Swiper slidesPerView={'auto'} spaceBetween={30}>
            {chaptersTwoColumn.map((chapters, index) => {
              const isSingleItemInTwoColumn = !chapters[1];
              return (
                <SwiperSlide key={index} className={isSingleItemInTwoColumn ? 'is-single-item-in-two-column' : ''}>
                  <ChapterTemplate
                    chapter={chapters[0]}
                    mobile="-mobile"
                    isSingleItemInTwoColumn={isSingleItemInTwoColumn}
                  />
                  {!isSingleItemInTwoColumn ? <ChapterTemplate chapter={chapters[1]} mobile="-mobile" /> : null}
                </SwiperSlide>
              );
            })}
            <SwiperSlide>
              <div
                className={classNames({
                  training__lesson: true,
                  'training__lesson--not-header': true,
                  'training__lesson--disabled': currentProgress < 11,
                })}
              >
                <p className="training__lesson-title">финал обучения</p>
                <div className="training__lesson-actions">
                  <TextButton
                    onClick={() => {
                      trainingStore.launchChapter({ chapter: trainings.chapter12 });
                    }}
                    isDisabled={currentProgress < 11}
                    title="старт"
                    color="green"
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <ReactModal
          isOpen={appStore.trainingIntroModalShow}
          shouldCloseOnOverlayClick={false}
          overlayClassName="modal-overlay"
          className={classNames({
            modal: true,
            'modal--size-very-big': true,
          })}
        >
          <p className="training-modal__title">Обучение</p>
          <p className="training-modal__desc">
            Добро пожаловать в обучающий блок многопользовательской бизнес-стратегии Business Cats! Обучение состоит из
            12 глав. Кнопка "Старт" рядом с каждой главой открывает теоретическую часть. Кнопка "Задание" - практическую
            часть. Ваша цель - успешно выполнить задания всех глав. Для наиболее комфортной игры, рекомендуем зайти в
            настройки и включить полноэкранный режим. Удачи!
          </p>
          <div
            className={classNames({
              'training-modal__actions': true,
              'training-modal__actions--showed': true,
            })}
          >
            <TextButton
              color="blue"
              title="ПОНЯТНО"
              onClick={() => {
                appStore.hideTrainingIntroModal();
                const settings = document.getElementsByClassName('training-extra')[0];
                settings.classList.add('training-pulse');
              }}
            />
          </div>
        </ReactModal>
      </PageLayout>
    );
  }
}

export default BasicTraining;
