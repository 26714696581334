import Modal from './Modal/Modal';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import trainingStore from '../../store/trainingStore';
import { TRAINING_TASK_STATUS } from '../../store/constants';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import classNames from '../../common/classNames';
import ReactModal from 'react-modal';
import OrientationModalIcon from '../icons/modal/OrientationModalIcon';
import LikeModalIcon from '../icons/modal/LikeModalIcon';
import routes from '../../routes';

function TrainingTask() {
  const { appStore } = RootStore;
  const [disabledRelaunch, setDisabledRelaunch] = useState(false);
  if (!trainingStore.currentTask) {
    return null;
  }
  return (
    <>
      {trainingStore.currentTask && (
        <>
          <ReactModal
            isOpen={trainingStore.currentTask.openedTask}
            shouldCloseOnOverlayClick={false}
            overlayClassName="modal-overlay"
            className={classNames({
              modal: true,
              'modal--size-big': true,
            })}
            // onRequestClose={onClose}
          >
            <div className="training-modal__icon-block">
              <OrientationModalIcon className="training-modal__icon-header" />
            </div>
            <p className="training-modal__title">{trainingStore.currentTask.title}</p>
            {trainingStore.currentTask.message}
            <div
              className={classNames({
                'training-modal__actions': true,
                'training-modal__actions--showed': trainingStore.showedNextButton,
              })}
            >
              <TextButton
                color="blue"
                title="Начать"
                onClick={() => {
                  trainingStore.hideTask();
                }}
              />
            </div>
          </ReactModal>
          <ReactModal
            isOpen={trainingStore.currentTask.status === TRAINING_TASK_STATUS.COMPLETED}
            shouldCloseOnOverlayClick={false}
            overlayClassName="modal-overlay"
            className={classNames({
              modal: true,
              'modal--color-green': true,
            })}
            // onRequestClose={onClose}
          >
            <p className="training-modal__title">МОЛОДЧИНА!</p>
            <p className="training-modal__desc">
              Ты идешь на следующий уровень!
              <br />
              Продолжай в том же духе!
            </p>
            <div className="training-modal__icon-block">
              <LikeModalIcon className="training-modal__icon" />
            </div>
            <div
              className={classNames({
                'training-modal__actions': true,
                'training-modal__actions--showed': trainingStore.showedNextButton,
              })}
            >
              <TextButton
                color="blue"
                title="ПОНЯТНО"
                onClick={() => {
                  trainingStore.stopTraining();
                }}
              />
            </div>
          </ReactModal>
          <ReactModal
            isOpen={trainingStore.currentTask.status === TRAINING_TASK_STATUS.WRONG}
            shouldCloseOnOverlayClick={false}
            overlayClassName="modal-overlay"
            className={classNames({
              modal: true,
              'modal--color-red': true,
            })}
            onAfterClose={() => setDisabledRelaunch(false)}
          >
            <p className="training-modal__title">Увы! ты ошибся</p>
            <p className="training-modal__desc">Придется вернуться и сыграть лучше.</p>
            {trainingStore.currentTask.wrongMessage}
            <div
              className={classNames({
                'training-modal__actions': true,
                'training-modal__actions--showed': trainingStore.showedNextButton,
              })}
            >
              <TextButton
                color="blue"
                title="ЗАНОВО"
                isDisabled={disabledRelaunch}
                onClick={() => {
                  setDisabledRelaunch(true);
                  trainingStore.relaunchTask(false);
                }}
              />
            </div>
          </ReactModal>
        </>
      )}
    </>
  );
}

export default observer(TrainingTask);
