import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarDogImage from '../../../assets/nurseryAvatars/avatarDog.png';

function AvatarDog({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <path
    //     d="M24.1691 14.996C23.7233 13.5124 23.1797 11.9773 22.2321 10.8021C21.1637 9.47746 19.7795 8.83325 17.9997 8.83325C16.2204 8.83325 14.8358 9.47746 13.7674 10.8021C12.8202 11.9768 12.2766 13.5119 11.8304 14.996C11.6691 15.5327 11.5124 15.9939 11.3612 16.4404C10.8989 17.8017 10.5 18.9774 10.5 20.8397C10.5 22.1633 10.7785 23.3136 11.3281 24.2575C11.8161 25.0959 12.5123 25.7635 13.397 26.2421C14.68 26.9362 16.145 27.1228 17.3104 27.1666V22.3254C16.9405 22.1526 16.6496 21.8779 16.4763 21.6857C16.2048 21.3856 15.7494 20.7658 15.7494 19.9866C15.7494 19.2537 16.1096 18.6487 16.713 18.3674C17.0366 18.217 17.4092 18.1554 17.9998 18.1554C18.5903 18.1554 18.9634 18.217 19.287 18.3674C19.8903 18.6487 20.2502 19.2537 20.2502 19.9866C20.2502 20.7658 19.7952 21.3856 19.5237 21.6857C19.3504 21.8779 19.059 22.1526 18.6891 22.3254V27.1666C19.855 27.1228 21.32 26.9362 22.6026 26.2421C23.4877 25.7635 24.1839 25.0959 24.6719 24.2575C25.2211 23.3136 25.5 22.1638 25.5 20.8397C25.5 18.9774 25.1007 17.8017 24.6379 16.4378C24.4866 15.9914 24.3299 15.5301 24.1691 14.996ZM16.3785 16.0235C16.3785 16.4455 16.0701 16.788 15.6892 16.788C15.3087 16.788 14.9999 16.4455 14.9999 16.0235V14.8732C14.9999 14.4507 15.3087 14.1087 15.6892 14.1087C16.0701 14.1087 16.3785 14.4507 16.3785 14.8732V16.0235ZM20.9812 16.0235C20.9812 16.4455 20.6729 16.788 20.2919 16.788C19.9114 16.788 19.6026 16.4455 19.6026 16.0235V14.8732C19.6026 14.4507 19.9114 14.1087 20.2919 14.1087C20.6729 14.1087 20.9812 14.4507 20.9812 14.8732V16.0235Z"
    //     fill="#02516C"
    //   />
    //   <path
    //     d="M13.605 9.9688C13.6796 9.88704 13.756 9.80709 13.8333 9.72986C13.8324 9.72986 13.8315 9.72941 13.8306 9.72941C13.2487 9.60158 12.0726 9.56003 10.7322 10.7231C10.1831 11.1992 9.37199 12.1034 8.79801 13.0506C7.9363 14.4707 7.77153 15.6672 8.30823 16.6067C8.80261 17.4725 9.517 17.842 10.1315 18.0001C10.3069 17.0199 10.6135 16.2205 10.932 15.3916L10.9421 15.3641C11.0857 14.9887 11.2344 14.6008 11.384 14.1604C11.8779 12.7073 12.4888 11.1937 13.605 9.9688Z"
    //     fill="#02516C"
    //   />
    //   <path
    //     d="M27.3212 13.0509C26.8324 12.1041 26.1422 11.1993 25.6753 10.7231C24.5347 9.56039 23.5339 9.6015 23.0384 9.72934C23.0255 9.7325 23.0125 9.73612 23 9.73973C23.0838 9.8355 23.1657 9.93533 23.246 10.0383C24.1966 11.2643 24.7164 12.7781 25.1359 14.2308C25.2625 14.6672 25.3886 15.0548 25.5108 15.4297L25.521 15.4605L25.5214 15.4618C25.7854 16.2713 26.0403 17.0524 26.1912 18.0001C26.7129 17.8411 27.3185 17.4707 27.738 16.6074C28.1943 15.6678 28.0541 14.4716 27.3212 13.0509Z"
    //     fill="#02516C"
    //   />
    // </svg>
    <Avatar style={style} avatarImage={AvatarDogImage} />
  );
}

export default AvatarDog;
