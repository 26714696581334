import React from 'react';
import RootStore from '../../../store';
import routes from '../../../routes';
import { observer } from 'mobx-react';
import Modal from './../Modal/Modal';
import TextButton from '../../buttons/TextButton/TextButton';
import './LTDMakingModal.scss';
import { LTD_ARCHIVE_ACTION_TYPE, TASK_TYPES } from '../../../store/constants';
import trainingStore from '../../../store/trainingStore';
import { LTD_CONTENT_TYPE, SPECIALITIES } from '../../../common/constants';
import { PRIVILEGES } from '../../../store/teamMode';

function LTDMakingModal() {
  const { appStore } = RootStore;

  if (!appStore.LTDPartnerInfo) {
    return null;
  }

  const partnerInfo = appStore.LTDPartnerInfo;
  const Logo = partnerInfo.logo;

  const Deal = ({ type }) => {
    const goToDealCreation = () => {
      appStore.hideMakingLTDModal();
      if (appStore.LTDArchive(partnerInfo.uuid, type)) {
        appStore.openLTDArchiveModal({
          partnerUuid: partnerInfo.uuid,
          actionType: LTD_ARCHIVE_ACTION_TYPE.LOAD,
          type_ltd: type,
        });
      }
      RootStore.goTo(routes.longTermsDealsNew, {
        uuid: partnerInfo.uuid,
        type_ltd: type,
      });
    };

    const createBuySellTask = () => {
      appStore.hideMakingLTDModal();

      appStore.sendMessageActionsOnTask(
        null,
        {
          to: partnerInfo.uuid,
          contents: [
            {
              buyer: appStore.me.uuid,
              seller: partnerInfo.uuid,
            },
            {
              seller: appStore.me.uuid,
              buyer: partnerInfo.uuid,
            },
          ],
        },
        true,
        false,
        TASK_TYPES.CREATE_CHANGE_LTD_REQUEST,
      );
    };

    const traderCreatingBuySell =
      type === LTD_CONTENT_TYPE.BUY_SELL && [SPECIALITIES.SUPPLY, SPECIALITIES.SALES].includes(appStore.mySpeciality);

    return (
      <div className="modal-ltd-making__body">
        <span>
          {type === LTD_CONTENT_TYPE.BUY
            ? 'Заключить долгосрочную сделку на покупку'
            : type === LTD_CONTENT_TYPE.SELL
            ? 'Заключить долгосрочную сделку на продажу'
            : type === LTD_CONTENT_TYPE.BUY_SELL
            ? 'Заключить долгосрочную сделку на покупку и продажу'
            : null}
        </span>
        <TextButton
          color="green"
          title="Начать"
          onClick={traderCreatingBuySell ? createBuySellTask : goToDealCreation}
        />
      </div>
    );
  };

  return (
    <Modal
      customClass="ltd-making"
      size="big"
      shouldCloseOnOverlayClick={trainingStore.currentStep?.needOverlayClickModal ?? true}
      isOpen={!!appStore.LTDPartnerInfo}
      onClose={() => {
        appStore.hideMakingLTDModal();
      }}
      title={
        <>
          {Logo && <Logo />}
          {partnerInfo.name}
        </>
      }
    >
      <>
        {appStore.mySpeciality !== SPECIALITIES.SALES && <Deal type={LTD_CONTENT_TYPE.BUY} />}
        {appStore.mySpeciality !== SPECIALITIES.SUPPLY && <Deal type={LTD_CONTENT_TYPE.SELL} />}
        <Deal type={LTD_CONTENT_TYPE.BUY_SELL} />
      </>
      <TextButton
        customClass={'modal-ltd-making__body-action'}
        color="blue"
        title="Вернуться"
        onClick={appStore.hideMakingLTDModal}
      />
    </Modal>
  );
}

export default observer(LTDMakingModal);
