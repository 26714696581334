import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarClaspImage from '../../../assets/nurseryAvatars/avatarClasp.png';

function AvatarClasp({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M18 8C12.4772 8 8 12.4769 8 18C8 23.5226 12.4772 27.9999 18 27.9999C23.5229 27.9999 28 23.5225 28 18C28 12.4769 23.5229 8 18 8ZM17.0909 15.2727C17.0909 16.2769 16.2769 17.0909 15.2727 17.0909C14.2686 17.0909 13.4546 16.2769 13.4546 15.2727C13.4546 14.2686 14.2686 13.4545 15.2727 13.4545C16.2769 13.4545 17.0909 14.2686 17.0909 15.2727ZM15.2727 22.5455C16.2769 22.5455 17.0909 21.7314 17.0909 20.7273C17.0909 19.7231 16.2769 18.9091 15.2727 18.9091C14.2686 18.9091 13.4546 19.7231 13.4546 20.7273C13.4546 21.7314 14.2686 22.5455 15.2727 22.5455ZM22.5455 15.2727C22.5455 16.2769 21.7314 17.0909 20.7273 17.0909C19.7231 17.0909 18.9091 16.2769 18.9091 15.2727C18.9091 14.2686 19.7231 13.4545 20.7273 13.4545C21.7314 13.4545 22.5455 14.2686 22.5455 15.2727ZM20.7273 22.5455C21.7314 22.5455 22.5455 21.7314 22.5455 20.7273C22.5455 19.7231 21.7314 18.9091 20.7273 18.9091C19.7231 18.9091 18.9091 19.7231 18.9091 20.7273C18.9091 21.7314 19.7231 22.5455 20.7273 22.5455Z"
    //     fill="#02516C"
    //   />
    // </svg>
    <Avatar style={style} avatarImage={AvatarClaspImage} />
  );
}

export default AvatarClasp;
