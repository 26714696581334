import React from 'react';
import './style.scss';

function PreloaderTrade() {
  return (
    <div className="preloader-trade">
      <div className="preloader-trade__items">
        <div className="preloader-trade__item preloader-trade__item--one" />
        <div className="preloader-trade__item preloader-trade__item--two" />
        <div className="preloader-trade__item preloader-trade__item--three" />
      </div>
    </div>
  );
}

export default PreloaderTrade;
