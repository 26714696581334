import AvatarBall from './AvatarBall';
import AvatarBone from './AvatarBone';
import AvatarButterfly from './AvatarButterfly';
import AvatarCandy from './AvatarCandy';
import AvatarCatFace from './AvatarCatFace';
import AvatarCatPaw from './AvatarCatPaw';
import AvatarClasp from './AvatarClasp';
import AvatarCloud from './AvatarCloud';
import AvatarCrown from './AvatarCrown';
import AvatarDog from './AvatarDog';
import AvatarFish from './AvatarFish';
import AvatarFlower from './AvatarFlower';
import AvatarHat from './AvatarHat';
import AvatarLeaf from './AvatarLeaf';
import AvatarMilk from './AvatarMilk';
import AvatarMiniMouse from './AvatarMiniMouse';
import AvatarMoon from './AvatarMoon';
import AvatarMouse from './AvatarMouse';
import AvatarStar from './AvatarStar';
import AvatarSun from './AvatarSun';

export default [
  AvatarBall,
  AvatarBone,
  AvatarButterfly,
  AvatarCandy,
  AvatarCatFace,
  AvatarCatPaw,
  AvatarClasp,
  AvatarCloud,
  AvatarCrown,
  AvatarDog,
  AvatarFish,
  AvatarFlower,
  AvatarHat,
  AvatarLeaf,
  AvatarMilk,
  AvatarMiniMouse,
  AvatarMoon,
  AvatarMouse,
  AvatarStar,
  AvatarSun,
];
