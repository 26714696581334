import React from 'react';

function PlusIcon({ width = '16', height = '16' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.59679 3.3125C5.67474 2.13393 5.83249 1.01911 5.9474 0.834821C6.08187 0.619732 6.81554 0.5 8 0.5C9.18446 0.5 9.91813 0.619732 10.0526 0.834821C10.1675 1.01911 10.3253 2.13393 10.4032 3.3125L10.5446 5.45536L12.6875 5.59677C13.8661 5.67472 14.9812 5.83251 15.1652 5.94742C15.3803 6.08188 15.5 6.81554 15.5 8C15.5 9.18446 15.3803 9.91811 15.1652 10.0526C14.9812 10.1675 13.8661 10.3253 12.6875 10.4032L10.5446 10.5446L10.4107 12.6875C10.3371 13.8661 10.1793 14.9812 10.0601 15.1652C9.92133 15.3797 9.18045 15.5 8 15.5C6.81554 15.5 6.08187 15.3803 5.9474 15.1652C5.83249 14.9812 5.67474 13.8661 5.59679 12.6875L5.45536 10.5446L3.3125 10.4032C2.13393 10.3253 1.01911 10.1675 0.834821 10.0526C0.619732 9.91811 0.5 9.18446 0.5 8C0.5 6.81554 0.619732 6.08188 0.834821 5.94742C1.01911 5.83251 2.13393 5.67472 3.3125 5.59677L5.45536 5.45536L5.59679 3.3125Z"
        fill="#FDF6EE"
      />
    </svg>
  );
}

export default PlusIcon;
