import React from 'react';

function PendingRevisionIcon({ style }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0001 21.3749H18.9999V18.3065C18.9999 16.7803 18.3071 15.3198 17.0969 14.2997L15.4039 12.8719C15.137 12.646 14.9899 12.3366 14.9899 12C14.9899 11.6634 15.137 11.354 15.4039 11.129L17.0969 9.70128C18.3071 8.67938 18.9999 7.21975 18.9999 5.6935V2.62506H20.0001C20.553 2.62506 21 2.205 21 1.68744C21 1.17006 20.553 0.75 20.0001 0.75H3.99994C3.44696 0.75 3 1.17006 3 1.68744C3 2.205 3.44696 2.62506 3.99994 2.62506H5.00006V5.6935C5.00006 7.21975 5.69293 8.68024 6.90308 9.70025L8.59607 11.1281C8.86304 11.354 9.01007 11.6634 9.01007 12C9.01007 12.3366 8.86304 12.646 8.59607 12.871L6.90308 14.2987C5.69293 15.3206 5.00006 16.7803 5.00006 18.3065V21.3749H3.99994C3.44696 21.3749 3 21.795 3 22.3126C3 22.8299 3.44696 23.25 3.99994 23.25H20.0001C20.553 23.25 21 22.8299 21 22.3126C21 21.795 20.553 21.3749 20.0001 21.3749ZM8.00006 5.20307C8.00006 4.81494 8.33606 4.49994 8.75006 4.49994H15.2499C15.6639 4.49994 15.9999 4.81494 15.9999 5.20307C15.9999 6.14618 15.6079 7.03315 14.896 7.70057L12.5301 9.91878C12.384 10.0566 12.1921 10.1249 12 10.1249C11.8079 10.1249 11.616 10.0566 11.4699 9.91878L9.104 7.70057C8.39209 7.03315 8.00006 6.14618 8.00006 5.20307ZM8.00006 20.9803C8.00006 20.2912 8.28607 19.6444 8.80609 19.1569L11.4699 16.6594C11.7631 16.3847 12.238 16.3847 12.531 16.6594L15.195 19.1569C15.715 19.6444 16.001 20.2912 16.001 20.9803V21.3749H8.00006V20.9803Z"
        fill="inherit"
      />
    </svg>
  );
}

export default PendingRevisionIcon;
