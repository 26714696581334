import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import AvatarMoonImage from '../../../assets/nurseryAvatars/avatarMoon.png';

function AvatarMoon({ style }) {
  return (
    // <svg
    //   width="36"
    //   height="36"
    //   viewBox="0 0 36 36"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="avatar"
    //   style={style}
    // >
    //   <circle cx="18" cy="18" r="16.5" fill="white" stroke="#02516C" strokeWidth="3" />
    //   <path
    //     d="M20.7854 9.01621C20.584 9.2035 20.5147 9.49163 20.6091 9.75012C20.9447 10.6685 21.1149 11.639 21.1149 12.6346C21.1149 14.9002 20.2326 17.0305 18.6306 18.633C17.0284 20.2358 14.8975 21.1184 12.631 21.1184C11.6378 21.1184 10.6686 20.9483 9.75033 20.6126C9.49198 20.5182 9.20398 20.5873 9.01655 20.7888C8.82912 20.9904 8.78087 21.2829 8.89388 21.5339C9.62766 23.1655 10.8039 24.5562 12.295 25.5553C13.8233 26.5791 15.6066 27.1358 17.4523 27.1653C18.7652 27.1863 20.0429 26.9427 21.25 26.4412C22.4131 25.9581 23.4577 25.2589 24.3547 24.3631C25.2515 23.4674 25.952 22.4239 26.4368 21.2616C26.9215 20.0991 27.167 18.8708 27.1668 17.6083C27.1668 17.5607 27.1665 17.5129 27.1658 17.4652C27.1389 15.6173 26.5824 13.831 25.557 12.2997C24.5569 10.8063 23.1645 9.62843 21.5305 8.89368C21.2794 8.78066 20.9869 8.82878 20.7854 9.01621Z"
    //     fill="#02516C"
    //   />
    // </svg>
    <Avatar style={style} avatarImage={AvatarMoonImage} />
  );
}

export default AvatarMoon;
