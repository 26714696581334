import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../../store';
import Modal from '../Modal/Modal';
import TextButton from '../../buttons/TextButton/TextButton';
import QuestionIcon from '../../icons/modal/QuestionIcon';

function ExactlyEndTurnModal() {
  const { appStore } = RootStore;

  if (!appStore.exactlyPauseGame) {
    return null;
  }

  return (
    <Modal
      isOpen={appStore.exactlyPauseGame}
      shouldCloseOnOverlayClick={false}
      customClass="end-turn-confirm"
      title={<div>Подтвердите остановку сессии</div>}
    >
      <QuestionIcon className="modal__body-icon" />
      <div className="modal__body-actions">
        <TextButton color="green" title="Подтвердить" onClick={appStore.acceptPauseGame} />
        <TextButton color="purple" title="Отмена" onClick={appStore.exactlyCancelPauseGame} />
      </div>
    </Modal>
  );
}

export default observer(ExactlyEndTurnModal);
