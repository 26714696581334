import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import { CatFaceIcon } from '../icons';

function DragCatMomModal() {
  const { appStore } = RootStore;

  if (!appStore.dragCatMomId) {
    return null;
  }

  return (
    <Modal
      isOpen={!!appStore.dragCatMomId}
      shouldCloseOnOverlayClick={false}
      title={
        <div>
          точно хочешь
          <br />
          переместить кошку?
        </div>
      }
      desc="при перемещении мамы от котят, котята перемещаются на площадку "
    >
      <CatFaceIcon className="modal__body-icon not-enough-cats-modal-icon" />
      <div className="modal__body-actions">
        <TextButton color="green" title="переместить" onClick={appStore.acceptDragMomCat} />
        <TextButton color="purple" title="отмена" onClick={appStore.hideDragCatMomModal} />
      </div>
    </Modal>
  );
}

export default observer(DragCatMomModal);
