import React from 'react';
import RootStore from '../../store';
import { observer } from 'mobx-react-lite';
function hideInformationIcon({ style, fill = '#FFFBE5' }) {
  const { appStore } = RootStore;

  if (appStore.withHideInformation) {
    return (
      <svg width="26" height="10" viewBox="0 0 26 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path
          d="M1 1C1 1 5.2 5.8 13 5.8C20.8 5.8 25 1 25 1M3.4 2.974L1 5.8M25 5.8L22.6048 2.9776M9.2968 5.416L8.2 8.8M16.6756 5.4256L17.8 8.8"
          stroke="#FDF6EE"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path
          d="M15.5969 7.99863C15.5969 9.90525 13.9836 11.4509 11.9935 11.4509C10.0034 11.4509 8.39012 9.90525 8.39012 7.99863C8.39012 6.09199 10.0034 4.54639 11.9935 4.54639C13.9836 4.54637 15.5969 6.09201 15.5969 7.99863ZM12 0.810547C9.9395 0.819687 7.80372 1.32131 5.78192 2.28169C4.28076 3.02413 2.8178 4.07161 1.54788 5.36451C0.92416 6.02449 0.12862 6.98013 0 7.99975C0.0152 8.88297 0.9626 9.97313 1.54788 10.635C2.73874 11.8771 4.1636 12.8954 5.78192 13.7186C7.66734 14.6336 9.75424 15.1604 12 15.1897C14.0625 15.1804 16.1978 14.673 18.2173 13.7186C19.7185 12.9761 21.1822 11.9279 22.4521 10.635C23.0758 9.97503 23.8714 9.01937 24 7.99975C23.9848 7.11653 23.0374 6.02633 22.4521 5.36447C21.2613 4.12235 19.8356 3.10489 18.2173 2.28165C16.3329 1.36733 14.2408 0.844127 12 0.810547ZM11.9985 2.59551C15.1209 2.59551 17.652 5.01539 17.652 8.00053C17.652 10.9856 15.1209 13.4055 11.9985 13.4055C8.8761 13.4055 6.34498 10.9856 6.34498 8.00053C6.34498 5.01539 8.8761 2.59551 11.9985 2.59551Z"
          fill="#FDF6EE"
        />
      </svg>
    );
  }
}

export default observer(hideInformationIcon);
