import Modal from '../../Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import corpStore from '../../../../store/CorpStore';
import TextButton from '../../../buttons/TextButton/TextButton';
import CoinModalIcon from '../../../icons/modal/CoinModalIcon';
import CarIcon from '../../../icons/CarIcon';

function ReceivingCarsModal() {
  const { receivingCarsModalData } = corpStore;

  if (!receivingCarsModalData) {
    return null;
  }

  return (
    <Modal
      isOpen={!!receivingCarsModalData}
      onClose={() => corpStore.hideReceivingCarsModal()}
      title={receivingCarsModalData.title}
      desc={receivingCarsModalData.desc}
      colorBg={receivingCarsModalData.colorBg}
    >
      <div className="modal__body-wrapper">
        <CarIcon className="modal__body-icon" fill="#FDF6EE" />
        {receivingCarsModalData.totalPrice && (
          <div className="modal__body-price">
            <p className="modal__body-price-text">итого цена</p>
            <p className="modal__body-price-coin">
              <CoinModalIcon className="modal__body-price-coin-icon" />
              <span className="modal__body-price-coin-count">{receivingCarsModalData.totalPrice}</span>
            </p>
          </div>
        )}
      </div>
      <div className="modal__body-actions">
        <TextButton color="blue" title="Понятно" onClick={() => corpStore.hideReceivingCarsModal()} />
      </div>
    </Modal>
  );
}

export default observer(ReceivingCarsModal);
