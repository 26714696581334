import CorpStore from '../../../../store/CorpStore';
import corpStore from '../../../../store/CorpStore';
import { playerHasPrivileges } from '../../../../store/teamMode';
import instanceOfEnum from '../../../../common/instanceOfEnum';
import RootStore from '../../../../store';
import isDictionary from '../../../../common/isDictionary';
import { TEAM_AND_CORP_PRIVILEGES_MIXIN } from './constants';
import DelegatingSwitcherCheckboxForReadonly from './DelegatingSwitcherCheckboxForReadonly';
import { CORP_DELEGATING_TYPES, DELEGATING_STATES, FAKE, LIMITS_TYPE } from '../../../../store/constants';
import { SPECIALITIES } from '../../../../common/constants';
import _ from 'lodash';

export const DELEGATING_SWITCHER_PROPERTY = {
  TYPE: 'type',
  LABEL: 'label',
  RELATED_LIMIT_TYPES: 'related_limit_types',
  PRIVILEGES: 'privileges',
  NON_CEO_GIVES_PRIVILEGES: 'non_ceo_gives_privileges',
  NON_CEO_DEPRIVE_PRIVILEGES: 'non_ceo_deprive_privileges',
  CALLBACK: 'callback',
  VISIBLE: 'visible',
  CHECK_BOX_FOR_READONLY: 'check_box_for_readonly',
};
Object.entries(DELEGATING_SWITCHER_PROPERTY).forEach(([key, value]) => {
  DELEGATING_SWITCHER_PROPERTY[key] = `delegating_switcher_property__${value}`;
});
Object.freeze(DELEGATING_SWITCHER_PROPERTY);

const DELEGATING_SWITCH_PROPERTY_VALIDATES = {
  [DELEGATING_SWITCHER_PROPERTY.TYPE]: (type) => {
    return instanceOfEnum(CORP_DELEGATING_TYPES, type);
  },
  [DELEGATING_SWITCHER_PROPERTY.LABEL]: (label) => {
    return _.isString(label);
  },
  [DELEGATING_SWITCHER_PROPERTY.RELATED_LIMIT_TYPES]: (relatedLimitTypes) => {
    return Object.entries(relatedLimitTypes).every(([key, value]) => {
      return instanceOfEnum(LIMITS_TYPE, key) && (_.isBoolean(value) || _.isFunction(value));
    });
  },
  [DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]: (privileges) => {
    return Object.entries(privileges).every(([delegatingState, privilegesBySpecialities]) => {
      const valueIsValid =
        isDictionary(privilegesBySpecialities) &&
        Object.entries(privilegesBySpecialities).every(([speciality, privileges]) => {
          return (
            instanceOfEnum(SPECIALITIES, speciality) &&
            privileges.every((privilege) => instanceOfEnum(TEAM_AND_CORP_PRIVILEGES_MIXIN, privilege))
          );
        });
      return instanceOfEnum(DELEGATING_STATES, delegatingState) && valueIsValid;
    });
  },
  [DELEGATING_SWITCHER_PROPERTY.NON_CEO_GIVES_PRIVILEGES]: (non_ceo_gives_privileges) => {
    return DELEGATING_SWITCH_PROPERTY_VALIDATES[DELEGATING_SWITCHER_PROPERTY.PRIVILEGES](non_ceo_gives_privileges);
  },
  [DELEGATING_SWITCHER_PROPERTY.NON_CEO_DEPRIVE_PRIVILEGES]: (non_ceo_deprive_privileges) => {
    return DELEGATING_SWITCH_PROPERTY_VALIDATES[DELEGATING_SWITCHER_PROPERTY.PRIVILEGES](non_ceo_deprive_privileges);
  },
  [DELEGATING_SWITCHER_PROPERTY.CALLBACK]: (callback) => {
    return _.isFunction(callback);
  },
  [DELEGATING_SWITCHER_PROPERTY.VISIBLE]: (visible) => {
    return visible === undefined || _.isBoolean(visible) || _.isFunction(visible);
  },
  [DELEGATING_SWITCHER_PROPERTY.CHECK_BOX_FOR_READONLY]: (checkBoxForReadonly) => {
    return checkBoxForReadonly instanceof DelegatingSwitcherCheckboxForReadonly;
  },
};

class DelegatingSwitcher {
  constructor(properties) {
    Object.entries(properties).forEach(([property, value]) => {
      if (
        instanceOfEnum(DELEGATING_SWITCHER_PROPERTY, property) &&
        DELEGATING_SWITCH_PROPERTY_VALIDATES[property](value)
      ) {
        this[property] = value || null;
      } else {
        console.error(`Свойство ${property} заполнено с ошибкой у поля}`);
        debugger;
      }
    });
  }

  /**
   * Функция собирает добавляемые привилегии по переданным ей привилегиям для ролей по статусам делегирования
   * @param privileges - передается в формате:
   * {
   *  [<DELEGATING_STATES>]: {
   *    [<SPECIALITIES>]: [<TEAM_AND_CORP_PRIVILEGES_MIXIN>],
   *  },
   * }
   * @returns {Object} в формате:
   * {
   *  [<DELEGATING_STATES>]: {
   *    [<SPECIALITIES>]: [<TEAM_AND_CORP_PRIVILEGES_MIXIN>],
   *  },
   * }
   */
  #generateAddingPrivileges(privileges) {
    const addingPrivilegesByDelegatingStatesAndSpecialities = {};

    Object.entries(privileges).forEach(([delegatingState, specialitiesAndPrivileges], index, delegatingStatesArray) => {
      const addingPrivilegesBySpecialities = {};

      const previousDelegatingStates = delegatingStatesArray.slice(0, index + 1);
      previousDelegatingStates.forEach(([delegatingState, specialitiesAndPrivileges]) => {
        Object.entries(specialitiesAndPrivileges).forEach(([speciality, addingPrivileges]) => {
          if (addingPrivilegesBySpecialities[speciality]) {
            addingPrivilegesBySpecialities[speciality] = _.uniq(
              _.concat(addingPrivilegesBySpecialities[speciality], addingPrivileges),
            );
          } else {
            addingPrivilegesBySpecialities[speciality] = addingPrivileges;
          }
        });
      });

      addingPrivilegesByDelegatingStatesAndSpecialities[delegatingState] = addingPrivilegesBySpecialities;
    });
    return addingPrivilegesByDelegatingStatesAndSpecialities;
  }

  /**
   * Функция собирает удаляемые привилегии по переданным ей привилегиям для ролей по статусам делегирования
   * @param privileges - передается в формате:
   * {
   *  [<DELEGATING_STATES>]: {
   *    [<SPECIALITIES>]: [<TEAM_AND_CORP_PRIVILEGES_MIXIN>],
   *  },
   * }
   * @returns {Object} в формате:
   * {
   *  [<DELEGATING_STATES>]: {
   *    [<SPECIALITIES>]: [<TEAM_AND_CORP_PRIVILEGES_MIXIN>],
   *  },
   * }
   */
  #generateRemovingPrivileges(privileges) {
    const removingPrivilegesByDelegatingStatesAndSpecialities = {};

    Object.entries(privileges).forEach(([delegatingState, specialitiesAndPrivileges], index, delegatingStatesArray) => {
      const removingPrivilegesBySpecialities = {};

      const nextDelegatingStates = delegatingStatesArray.slice(index + 1);

      nextDelegatingStates.forEach(([delegatingState, specialitiesAndPrivileges]) => {
        Object.entries(specialitiesAndPrivileges).forEach(([speciality, addingPrivileges]) => {
          if (removingPrivilegesBySpecialities[speciality]) {
            removingPrivilegesBySpecialities[speciality] = _.uniq(
              _.concat(removingPrivilegesBySpecialities[speciality], addingPrivileges),
            );
          } else {
            removingPrivilegesBySpecialities[speciality] = addingPrivileges;
          }
        });
      });

      removingPrivilegesByDelegatingStatesAndSpecialities[delegatingState] = removingPrivilegesBySpecialities;
    });
    return removingPrivilegesByDelegatingStatesAndSpecialities;
  }

  /**
   * Функция генерирует добавляемые привилегии на основании this[DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]
   * @returns {Object} в формате:
   * {
   *  [<DELEGATING_STATES>]: {
   *    [<SPECIALITIES>]: [<TEAM_AND_CORP_PRIVILEGES_MIXIN>],
   *  },
   * }
   */
  get #addingPrivilegesByDelegatingStatesAndSpeciality() {
    return this.#generateAddingPrivileges(this[DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]);
  }

  /**
   * Функция генерирует удаляемые привилегии на основании this[DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]
   * @returns {Object} в формате:
   * {
   *  [<DELEGATING_STATES>]: {
   *    [<SPECIALITIES>]: [<TEAM_AND_CORP_PRIVILEGES_MIXIN>],
   *  },
   * }
   */
  get #removingPrivilegesByDelegatingStatesAndSpeciality() {
    return this.#generateRemovingPrivileges(this[DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]);
  }

  /**
   * Функция возвращает добавляемые привилегии для определенных роли и состояния делегирования
   * @param speciality - Элемент enum'а <SPECIALITIES>
   * @param delegatingState - Элемент enum'а <DELEGATING_STATES>
   * @returns {Array} в формате: [<TEAM_AND_CORP_PRIVILEGES_MIXIN>]
   */
  addingPrivilegesForSpecialtyAndDelegatingState({ speciality, delegatingState }) {
    return this.#addingPrivilegesByDelegatingStatesAndSpeciality[delegatingState]?.[speciality];
  }

  /**
   * Функция возвращает удаляемые привилегии для определенных роли и состояния делегирования
   * @param speciality - Элемент enum'а <SPECIALITIES>
   * @param delegatingState - Элемент enum'а <DELEGATING_STATES>
   * @returns {Array} в формате: [<TEAM_AND_CORP_PRIVILEGES_MIXIN>]
   */
  removingPrivilegesForSpecialtyAndDelegatingState({ speciality, delegatingState }) {
    return this.#removingPrivilegesByDelegatingStatesAndSpeciality[delegatingState]?.[speciality] || null;
  }

  /**
   * Функция генерирует добавляемые и удаляемые привилегии для игрока.
   * @param speciality - Элемент enum'а <SPECIALITIES>@param delegatingState - Элемент enum'а <DELEGATING_STATES>
   * @param delegatingState - Элемент enum'а <DELEGATING_STATES>
   * @param playerId - id игрока
   * @returns {Array} в формате: [addingPrivileges, removingPrivileges]
   */
  generatePrivilegesForPlayer({ delegatingState, speciality, playerId }) {
    const generatePrivileges = ({ delegatingState, speciality, playerId, isAddingPrivileges = true }) => {
      let privileges;
      if (isAddingPrivileges) {
        privileges = this.addingPrivilegesForSpecialtyAndDelegatingState({ speciality, delegatingState });
      } else {
        privileges = this.removingPrivilegesForSpecialtyAndDelegatingState({ speciality, delegatingState });
      }
      if (!privileges) {
        return [];
      }
      return privileges.map((privilege) => {
        return [playerId, privilege];
      });
    };

    const getUniqFromAddingAndRemovingPrivileges = ({ addingPrivileges, removingPrivileges }) => {
      const uniqAdding = [];
      const uniqRemoving = [];

      addingPrivileges.forEach(([playerId, privilege]) => {
        if (
          !_.find(removingPrivileges, ([_playerId, _privilege]) => privilege === _privilege && playerId === _playerId)
        ) {
          uniqAdding.push([playerId, privilege]);
        }
      });
      removingPrivileges.forEach(([playerId, privilege]) => {
        if (
          !_.find(addingPrivileges, ([_playerId, _privilege]) => privilege === _privilege && playerId === _playerId)
        ) {
          uniqRemoving.push([playerId, privilege]);
        }
      });

      return {
        addingPrivileges: uniqAdding,
        removingPrivileges: uniqRemoving,
      };
    };

    const { addingPrivileges, removingPrivileges } = getUniqFromAddingAndRemovingPrivileges({
      addingPrivileges: generatePrivileges({
        delegatingState,
        speciality,
        playerId,
        isAddingPrivileges: true,
      }),
      removingPrivileges: generatePrivileges({
        delegatingState,
        speciality,
        playerId,
        isAddingPrivileges: false,
      }),
    });

    return [addingPrivileges, removingPrivileges];
  }

  /**
   * Функция видим ли switcher
   * @returns {boolean}
   */
  get visible() {
    const visible = this[DELEGATING_SWITCHER_PROPERTY.VISIBLE];
    if (visible === undefined) {
      return true;
    } else if (_.isFunction(visible)) {
      return visible();
    } else {
      return visible;
    }
  }

  /**
   * Функция меняет привилегии и лимиты игрока. Также в ней выполняется передаваемый в switcher callback
   * @param state - Элемент enum'а <DELEGATING_STATE>
   * @param areaNum - номер конкретного города или 'all_areas' в случае изменения для всех городов
   * @returns {void}
   */
  changePrivileges({ state, areaNum }) {
    const addingPrivileges = [];
    const removingPrivileges = [];
    const forAreaNums = areaNum === 'all_areas' ? CorpStore.allActiveAreaNums : [areaNum];
    const relatedLimitTypes = this[DELEGATING_SWITCHER_PROPERTY.RELATED_LIMIT_TYPES];

    const states = Object.keys(this[DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]);
    const reverseStateIndex = _.findIndex(states.reverse(), (_state) => state === _state);
    const stateIndex = _.findIndex(states.reverse(), (_state) => state === _state);
    const nextStates = _.isNumber(stateIndex) ? states.slice(stateIndex + 1) : null;
    const reverseStates = _.isNumber(reverseStateIndex) ? states.slice(reverseStateIndex) : null;

    forAreaNums.forEach((areaNum) => {
      Object.entries(this[DELEGATING_SWITCHER_PROPERTY.PRIVILEGES][state]).forEach(([speciality, privileges]) => {
        const player = RootStore.appStore.playerByRealSpeciality(speciality, areaNum);
        const playerId = player.player_id;
        if (player.is_timed_out === true || playerId === FAKE.FAKE) {
          return;
        }
        const [_addingPrivileges, _removingPrivileges] = this.generatePrivilegesForPlayer({
          delegatingState: state,
          speciality,
          playerId,
        });

        addingPrivileges.push(..._addingPrivileges);
        removingPrivileges.push(..._removingPrivileges);
      });

      const nonCEOGivesPrivileges = this[DELEGATING_SWITCHER_PROPERTY.NON_CEO_GIVES_PRIVILEGES];
      if (nextStates && nonCEOGivesPrivileges) {
        nextStates.forEach((state) => {
          if (nonCEOGivesPrivileges[state]) {
            Object.entries(nonCEOGivesPrivileges[state]).forEach(([speciality, privileges]) => {
              const player = RootStore.appStore.playerByRealSpeciality(speciality, areaNum);
              const playerId = player.player_id;
              if (player.is_timed_out === true || playerId === FAKE.FAKE) {
                return;
              }
              privileges.forEach((privilege) => {
                removingPrivileges.push([playerId, privilege]);
              });
            });
          }
        });
      }
      const nonCEODeprivePrivileges = this[DELEGATING_SWITCHER_PROPERTY.NON_CEO_DEPRIVE_PRIVILEGES];
      if (reverseStates && nonCEODeprivePrivileges) {
        reverseStates.forEach((state) => {
          if (nonCEODeprivePrivileges[state]) {
            Object.entries(nonCEODeprivePrivileges[state]).forEach(([speciality, privileges]) => {
              const player = RootStore.appStore.playerByRealSpeciality(speciality, areaNum);
              const playerId = player.player_id;
              if (player.is_timed_out === true || playerId === FAKE.FAKE) {
                return;
              }
              privileges.forEach((privilege) => {
                removingPrivileges.push([playerId, privilege]);
                let cloneIndex = addingPrivileges.findIndex((i) => i[0] === playerId && i[1] === privilege);
                addingPrivileges.splice(cloneIndex, 1);
              });
            });
          }
        });
      }

      if (state === DELEGATING_STATES.CEO && relatedLimitTypes) {
        Object.entries(relatedLimitTypes).forEach(([limitType, isNeeded]) => {
          if (!isNeeded || (_.isFunction(isNeeded) && !isNeeded({ areaNum }))) {
            return;
          }
          corpStore.setLocalLimitCheckboxValue(areaNum, limitType, true);
        });
      }

      const callback = this[DELEGATING_SWITCHER_PROPERTY.CALLBACK];
      if (callback) {
        callback({
          state,
          privileges: this[DELEGATING_SWITCHER_PROPERTY.PRIVILEGES],
          areaNum,
        });
      }
    });
    RootStore.appStore.modifyPrivileges(addingPrivileges, removingPrivileges);
  }

  /**
   * Функция возвращает состояние делегирования для конкретного города или для всех городов.
   * Если у городов отличаются state'ы, то возвращается null
   * @param areaNum - номер конкретного города или 'all_areas' в случае изменения для всех городов
   * @returns {string | null} - если string, то элемент enum'а <DELEGATING_STATE>
   */
  getState({ areaNum }) {
    const everyPlayerHasEveryPrivileges = ([speciality, privileges], _areaNum) => {
      const player = CorpStore.playerBySpecialityAndAreaNum(speciality, _areaNum);
      const allPrivileges = true;
      return playerHasPrivileges(player.privileges, privileges, allPrivileges);
    };

    const allAreaNums = areaNum === 'all_areas';
    const statesInfo = {};
    Object.entries(this[DELEGATING_SWITCHER_PROPERTY.PRIVILEGES]).forEach(
      ([delegatingState, privilegesBySpecialities]) => {
        if (allAreaNums) {
          statesInfo[delegatingState] = [];
          CorpStore.allActiveAreaNums.forEach((areaNum) => {
            statesInfo[delegatingState].push(
              Object.entries(privilegesBySpecialities).every(([speciality, privileges]) =>
                everyPlayerHasEveryPrivileges([speciality, privileges], areaNum),
              ),
            );
          });
        } else {
          statesInfo[delegatingState] = Object.entries(privilegesBySpecialities).every(([speciality, privileges]) =>
            everyPlayerHasEveryPrivileges([speciality, privileges], areaNum),
          );
        }
      },
    );

    let state;
    if (allAreaNums) {
      const areaNumsPrivilegesAreaEqual = Object.values(statesInfo).every((hasPrivilegesByAreaNums) => {
        return hasPrivilegesByAreaNums.every((value) => value === _.first(hasPrivilegesByAreaNums));
      });
      state = areaNumsPrivilegesAreaEqual
        ? _.findLast(Object.keys(statesInfo), (state) => statesInfo[state].every((hasPrivilege) => hasPrivilege))
        : null;
    } else {
      state = _.findLast(Object.keys(statesInfo), (state) => statesInfo[state]);
    }

    return state;
  }
}

export default DelegatingSwitcher;
