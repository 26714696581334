import React from 'react';
import { observer } from 'mobx-react';
import routes from '../routes';
import RootStore from '../store';

import CityField from '../modules/deal/city/CityField';
import ShopField from '../modules/deal/shop/ShopField';
import LotField from '../modules/deal/lot/LotField';
import NurseryField from '../modules/deal/nursery/NurseryField';

import Header from '../ui/components/Header/Header';
import containerBG from '../assets/basic/trade/background/trade_bg.png';

import './inside.scss';

import MagnifierIcon from '../ui/icons/MagnifierIcon';
import classNames from '../common/classNames';
import DragableItem from '../modules/deal/DragableItem';
import { HouseIcon } from '../ui/icons';
import DragElement from '../modules/DragElement';
import corpStore from '../store/CorpStore';
import Loader from '../ui/components/Loader/Loader';
import { DRAG_AREA, DRAG_BUTTON_TYPE, DRAGGABLE_ITEM_TYPE, SPECIALITIES } from '../common/constants';

function Inside() {
  const { appStore } = RootStore;

  if (!RootStore.appStore.me) {
    return <Loader />;
  }
  if (!RootStore.appStore.currentPlayer) {
      return <Loader />
  }

  const openedGroupedCat = appStore.openedGroupedCat;
  const magnifierVision = appStore.hasSpecialities([SPECIALITIES.DOCTOR]);

  return (
    <>
      <Header
        background
        extra={
          <span
            onClick={() => {
              if (appStore.meIsCEO) {
                RootStore.goTo(routes.city, { areaNum: corpStore.currentAreaNum });
              } else {
                RootStore.goTo(routes.main);
              }
            }}
          >
            <HouseIcon style={{ fill: '#C6A788' }} />
          </span>
        }
      />

      <div
        style={{
          background: `url(${containerBG}) center`,
        }}
        className="inside__container"
      >
        <div className="inside__wrap">
          <CityField />
          <ShopField />
          <LotField />
          <NurseryField />
        </div>
      </div>

      <DragElement />
      {magnifierVision && openedGroupedCat && openedGroupedCat.contents?.some((c) => c.detail?.cat_id || c.cat_id) && (
        <DragableItem
          type={DRAGGABLE_ITEM_TYPE.BUTTON}
          from={DRAG_AREA.BUTTONS}
          data={{ buttonType: DRAG_BUTTON_TYPE.MAGNIFIER }}
          draggableOptions={{
            canDrag: (data, from, element) => {
              return true;
            },
          }}
          element={(isDragging, dragRef) => {
            return (
              <p
                ref={dragRef}
                onClick={() => {
                  if (appStore.magnifierCats.length) {
                    appStore.setCatUnderMagnifierIndex(0);
                  }
                }}
                className={classNames({
                  'magnifier-button': true,
                })}
              >
                <MagnifierIcon size={58} />
              </p>
            );
          }}
        />
      )}
    </>
  );
}

export default observer(Inside);
