import Modal from './Modal/Modal';
import React from 'react';
import { observer } from 'mobx-react';
import RootStore from '../../store';
import TextButton from '../buttons/TextButton/TextButton';
import CoinModalIcon from '../icons/modal/CoinModalIcon';
import { ROLE_NAME } from '../../store/constants';
import { CatFaceIcon } from '../icons';
import WalletTitleIcon from '../icons/WalletTitleIcon';
import { LTD_ACTIONS_NAME, LTD_ACTIONS_STATUS, LTD_FINES_PAYMENT, LTD_FINES_TYPE } from '../../common/constants';

function SendLTDOverdueFineModal() {
  const { appStore } = RootStore;
  if (!appStore.sendLTDLiabilityModalData) {
    return null;
  }
  const dealId = appStore.sendLTDLiabilityModalData.deal_id;
  const ltd = appStore.ltdById(dealId);
  const partnerUuid = ltd.ltd_info.initiator === appStore.me.uuid ? ltd.ltd_info.partner : ltd.ltd_info.initiator;
  const partner = appStore.ltdPartner(partnerUuid, ltd.ltd_info);

  const myUuid = appStore.meIsCEO ? appStore.ceo_uuid : appStore.me.uuid;
  const actualModalLTDActionInfo = ltd.actions.find(
    (action) =>
      action.action_type === appStore.sendLTDLiabilityModalData.action.action_type && action.executor !== myUuid,
  );
  if (actualModalLTDActionInfo.status !== LTD_ACTIONS_STATUS.PENDING) {
    appStore.hideSendLTDLiabilityModal();
    return;
  }

  const isFine = appStore.sendLTDLiabilityModalData.is_fine;
  const liability_type = appStore.sendLTDLiabilityModalData.liability[0].liability_type;

  const areaTitle = appStore.meIsCEO ? `в городе ${ltd.ltd_info.contents[0].area_num}` : '';
  const desc = isFine
    ? `с ${ROLE_NAME[partner.role]}ом "${partner.name}" ${areaTitle}`
    : `в ${ROLE_NAME[partner.role]} "${partner.name}" ${areaTitle}`;

  const icon =
    isFine || liability_type === LTD_FINES_TYPE.OVERDUE_PAYMENT ? (
      <WalletTitleIcon className="modal__body-icon" />
    ) : (
      <CatFaceIcon fill="#EEDBC7" className="modal__body-icon" />
    );

  const liabilityInfo = ltd.ltd_info[`${liability_type}_liability`];

  const price = appStore.LTDFinePrice(ltd, liabilityInfo.payment_type, liabilityInfo.value);

  return (
    <Modal
      isOpen={!!appStore.sendLTDLiabilityModalData}
      shouldCloseOnOverlayClick={false}
      customClass="send-ltd-overdue-fine"
      onClose={() => appStore.hideSendLTDLiabilityModal()}
      title={isFine ? 'Хочешь расторгнуть сделку?' : 'Хочешь отправить претензию?'}
      desc={desc}
    >
      {icon}
      <div className="modal__body-price">
        <p className="modal__body-price-text">
          {isFine ? (
            <div>сумма штрафа</div>
          ) : (
            `за просрочку "${LTD_ACTIONS_NAME[appStore.sendLTDLiabilityModalData.action_type]}"`
          )}
        </p>
        {isFine && (
          <p className="modal__body-price-coin">
            <CoinModalIcon className="modal__body-price-coin-icon" />
            <span className="modal__body-price-coin-count notranslate"> {price} </span>
          </p>
        )}
        {!isFine && liability_type === LTD_FINES_TYPE.OVERDUE_PAYMENT && (
          <p className="modal__body-price-coin">
            <CoinModalIcon className="modal__body-price-coin-icon" />
            <span className="modal__body-price-coin-count notranslate">
              {appStore.sendLTDLiabilityModalData.action[appStore.sendLTDLiabilityModalData.action_type].money}
            </span>
          </p>
        )}
      </div>
      <div className="modal__body-actions">
        <TextButton
          color="green"
          title="Отправить"
          onClick={() => appStore.LTDLiabilityRequest(appStore.sendLTDLiabilityModalData)}
        />
        <TextButton color="purple" title="отмена" onClick={() => appStore.hideSendLTDLiabilityModal()} />
      </div>
    </Modal>
  );
}

export default observer(SendLTDOverdueFineModal);
