import trainingStore from '../store/trainingStore';

const createCustomActionForTraining = ({ actionName, params }) => {
  console.log(actionName, params);
  if (
    trainingStore.launched &&
    trainingStore.currentStep &&
    trainingStore.currentStep.checkParams &&
    (trainingStore.currentStep.actionName === actionName || trainingStore.currentStep.actionNames?.includes(actionName))
  ) {
    trainingStore.checkIsCorrectAction(actionName, params);
  }
};

export default createCustomActionForTraining;
