import React from 'react';

function AreaIcon({ fill = '#FDF6EE' }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.64843 4.3709L15.2497 0.0476427C15.6027 -0.111301 16.0021 0.146892 16.0021 0.534013V30.9324H11.7349V8.85223C11.7349 8.48902 11.3798 8.23203 11.0347 8.34556L5.33403 10.2214V4.85727C5.33403 4.64741 5.45708 4.45706 5.64843 4.3709Z"
        fill={fill}
      />
      <path
        d="M0 31.4666C0 31.7612 0.238813 32 0.533403 32H31.4708C31.7654 32 32.0042 31.7612 32.0042 31.4666C32.0042 31.172 31.7654 30.9332 31.4708 30.9332H29.8706V14.3977C29.8706 14.1031 29.6317 13.8643 29.3372 13.8643H20.8027C20.5081 13.8643 20.2693 14.1031 20.2693 14.3977V30.9332H19.2025V13.2824C19.2025 12.9878 19.4413 12.749 19.7359 12.749H25.6033V4.79646C25.6033 4.50187 25.3645 4.26306 25.0699 4.26306H17.0689V30.9332H10.6681V10.3432C10.6681 9.97736 10.3082 9.7201 9.9621 9.83844L1.42765 12.7562C1.21183 12.83 1.06681 13.0328 1.06681 13.2609V30.9332H0.533402C0.238812 30.9332 0 31.172 0 31.4666Z"
        fill={fill}
      />
    </svg>
  );
}

export default AreaIcon;
