import React from 'react';

export function getCatTypeText(cat, splitter = false) {
  if (!cat) {
    return '';
  }
  const gender = cat.gender === 'male' ? 'мальчик' : 'девочка';
  let color = '';
  if (cat.color === 'white') {
    color = cat.gender === 'male' ? 'белый' : 'белая';
  } else if (cat.color === 'black') {
    color = cat.gender === 'male' ? 'черный' : 'черная';
  } else if (cat.color === 'gray') {
    color = cat.gender === 'male' ? 'серый' : 'серая';
  } else if (cat.color === 'ginger') {
    color = cat.gender === 'male' ? 'рыжий' : 'рыжая';
  }
  if (splitter === '\n') {
    return (
      <>
        <span>{gender}</span>
        <br />
        <span>{color}</span>
      </>
    );
  }
  return gender + (!!splitter ? ' ' + splitter + ' ' : ' ') + color;
}
