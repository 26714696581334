import React from 'react';

function MultiEatBtnIcon({ fill = none }) {
  return (
    <svg viewBox="0 0 64 64" fill={fill} xmlns="http://www.w3.org/2000/svg" style={{ height: '64px', width: '64' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9528 16.868C23.1478 17.1504 21.8582 17.806 21.0871 18.3243C20.3159 18.843 19.5552 19.2673 19.3963 19.2673C19.2378 19.2673 18.2202 19.6827 17.1354 20.1902C14.8477 21.2607 14.6721 21.7659 16.2666 22.6897L17.3662 23.3268L16.7911 26.8572L15.4472 27.1847C12.2702 27.9581 11.4852 29.3051 13.4298 30.6459C14.2463 31.2085 14.6908 31.3134 15.7404 31.1915L17.0401 31.0399V32.4619C17.0401 34.9195 18.8579 36.5543 19.9101 35.0432C20.1692 34.6711 20.5811 33.6743 20.8251 32.8275C21.3077 31.1542 21.9527 30.8141 22.6118 31.8855C23.7246 33.6942 26.6801 32.8319 26.7064 30.6907C26.7128 30.1729 27.1145 29.6079 28.0269 28.8339C30.3834 26.834 31.6259 24.0443 31.625 20.7543C31.6247 18.4683 30.9671 16.8891 29.8927 16.5927C28.5008 16.2092 25.424 16.3517 23.9528 16.868ZM27.2355 18.7395C28.2158 19.7346 27.0631 21.3926 25.8492 20.7332C24.8297 20.1794 25.271 18.3877 26.4268 18.3877C26.681 18.3877 27.0449 18.546 27.2355 18.7395ZM24.2881 23.6115C24.8967 24.1208 25.9191 24.7265 26.5606 24.9579L27.7265 25.378L26.4268 25.4C24.2526 25.4369 22.6618 24.1073 22.2464 21.906L22.0526 20.8798L22.617 21.7829C22.9275 22.2795 23.6793 23.1022 24.2881 23.6115Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.2371 36.3302C34.3844 36.3095 32.9462 36.4664 32.0416 36.6784C31.1368 36.8906 30.2751 37.0182 30.1265 36.962C29.9782 36.9059 28.8795 36.9345 27.6853 37.0254C25.1668 37.2171 24.8238 37.6275 25.9884 39.0558L26.7914 40.0407L25.0044 43.1392L23.6316 42.9701C20.3864 42.5694 19.1756 43.5514 20.52 45.4935C21.0846 46.3087 21.4632 46.5641 22.4881 46.8214L23.7573 47.1395L23.2542 48.4695C22.3846 50.7681 23.5065 52.9404 25.0252 51.8993C25.3992 51.6429 26.1371 50.8563 26.665 50.1507C27.7084 48.7564 28.432 48.6665 28.6694 49.9017C29.0703 51.9872 32.1397 52.2264 32.9219 50.233C33.111 49.751 33.6867 49.3647 34.8139 48.9636C37.7256 47.9268 39.8748 45.7572 41.038 42.6797C41.8466 40.5415 41.7903 38.8318 40.8902 38.1744C39.7241 37.3232 36.7959 36.3679 35.2371 36.3302ZM37.6454 39.2421C38.2102 40.5197 36.5454 41.6626 35.6433 40.6164C34.8857 39.7376 35.9324 38.2179 37.0135 38.6269C37.2512 38.7168 37.5356 38.9937 37.6454 39.2421ZM33.1648 42.7561C33.5538 43.4478 34.2958 44.3761 34.8139 44.8194L35.7558 45.6249L34.5324 45.1856C32.4857 44.4509 31.4682 42.6444 31.8587 40.4385L32.0405 39.4102L32.2488 40.4545C32.3635 41.0289 32.7755 42.0644 33.1648 42.7561Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.8011 18.756C43.1489 18.9669 42.1001 19.4681 41.4705 19.8693C40.8408 20.2707 40.2214 20.5964 40.0938 20.5933C39.9664 20.5902 39.1409 20.9038 38.2595 21.2901C36.4008 22.1048 36.2497 22.5071 37.5124 23.2806L38.383 23.8141L37.8515 26.6385L36.7655 26.8751C34.1983 27.4337 33.5412 28.5001 35.0767 29.6154C35.7215 30.0835 36.0765 30.1766 36.9219 30.0993L37.9689 30.0032L37.9409 31.1454C37.8924 33.1194 39.3203 34.4685 40.1953 33.2754C40.4107 32.9817 40.7612 32.1891 40.9739 31.5137C41.3946 30.1792 41.9194 29.9187 42.4277 30.7923C43.2859 32.2671 45.6769 31.6328 45.7402 29.9134C45.7556 29.4976 46.0894 29.0517 46.8375 28.448C48.7699 26.8881 49.8229 24.6717 49.8871 22.029C49.932 20.1928 49.4349 18.9114 48.5777 18.6521C47.4673 18.3166 44.9931 18.3703 43.8011 18.756ZM46.401 20.324C47.1688 21.1427 46.2102 22.4517 45.2481 21.8981C44.4401 21.4332 44.83 20.0027 45.7584 20.0255C45.9626 20.0305 46.2517 20.1648 46.401 20.324ZM43.9374 24.1793C44.4162 24.6004 45.2255 25.1071 45.7362 25.3056L46.6645 25.6661L45.6201 25.6581C43.8729 25.6449 42.6213 24.5455 42.3311 22.7691L42.1957 21.941L42.6312 22.6775C42.8708 23.0826 43.4584 23.7582 43.9374 24.1793Z"
      />
    </svg>
  );
}

export default MultiEatBtnIcon;
