import dayjs from 'dayjs';
import { TIME_DECLINATION } from '../store/constants';
import { declOfNum } from './declensionOfWords';

const getFormattedTimeFromSecond = (seconds) => {
  const duration = dayjs.duration(seconds, 'seconds').$d;
  return Object.entries(duration)
    .reduce((acc, [timeKey, timeValue]) => {
      if (timeValue) {
        return acc.concat(`${timeValue} ${declOfNum(timeValue, TIME_DECLINATION[timeKey])}`);
      }
      return acc;
    }, [])
    .join(' ');
};

export default getFormattedTimeFromSecond;
